import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import useQuery from "@Hooks/useQuery";
import { useNavigate } from "react-router-dom";
import Stepper from "@Molecule/Stepper";
import Images from "@Theme/Images";

export default function PipelineOrderDetailsNavController({
  isCompleted,
  stepsOptions,
  currentStep,
  contractType,
  handleClickStep,
}) {
  const query = useQuery()
  const name = query.get('n')
  // const code = query.get('c')
  const navigate = useNavigate()

  const handleNavigateToList = () => {
    navigate("/pipeline");
  };

  const handleClickBack = () => {
    // navigate(`/pipeline/order?n=${name}&c=${code}`)
    navigate(-1)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <div className={Styles.breadcrumbWrapper}>
          <button onClick={handleClickBack}>
            <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
          </button>
          <CustomBreadCrumb>
            <span onClick={handleNavigateToList} className={Styles.breadcrumbText}>Pipeline</span>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>{name}</span>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>Order</span>
            <span className={Styles.breadcrumbTextBold}>Detail</span>
          </CustomBreadCrumb>

        </div>
        {
          contractType && (
            <div className={Styles.typeWrapper}>
              <Icon icon="file" size={27} className={Styles.contractIcon} />
              <div className={Styles.descWrapper}>
                <span>{contractType?.type}</span>
                <span>{contractType?.label}</span>
              </div>
            </div>

          )
        }
      </div>

      <div className={Styles.stepperWrapper}>
        <Stepper
          type="filled"
          options={stepsOptions}
          currentStep={currentStep}
          onClick={handleClickStep}
          noDoneIcon
        />
      </div>
      <div className={`${Styles.infoWrapper} ${isCompleted ? Styles.complete : Styles.incomplete}`}>
        <img src={isCompleted ? Images.CIRCLE_TICK : Images.RED_LIST} alt="info" />
        <div>
          <h3>Info</h3>
          <span>{isCompleted ? 'Data telah dilengkapi' : 'Lengkapi data yang masih belum terisi'}</span>
        </div>
      </div>
    </div>
  );
}
