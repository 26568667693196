import Icon from "@Atom/Icon";
import ToggleButtonPIC from "@Atom/ToggleButtonPIC";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";
import Styles from "./profileLabel.module.scss";

const ProfileLabel = ({
  removeLabel,
  label,
  type,
  handleIsPIC,
  handleNotPIC,
  ...props
}) => {
  const initial = label?.name[0];

  if (type === "pic") {
    return (
      <div className={Styles.container}>
        <div className={Styles.profileSection}>
          {label?.profilePicture ? (
            <img
              src={
                label?.profilePicture
                  ? label?.profilePicture?.toLowerCase()?.includes("http")
                    ? label.profilePicture?.replace(
                        "https://myworkspace",
                        "https://api1.myworkspace"
                      )
                    : fileBaseUrl + label?.profilePicture
                  : Images?.AVA_DEFAULT
              }
              className={Styles.picture}
              alt="profile"
            />
          ) : (
            <div className={Styles.pictureWrapper}>{initial}</div>
          )}
          <div className={Styles.fontName}>{label?.name}</div>
        </div>
        <div className={Styles.left}>
          <div className={Styles.toggleButton}>
            <span className={Styles.setPic}>Set as PIC:</span>
            <div className={Styles.toggle}>
              <ToggleButtonPIC
                isPIC={label?.isPIC}
                handleIsPIC={handleIsPIC}
                handleNotPIC={handleNotPIC}
              />
            </div>
          </div>
          <div className={Styles.icon} onClick={() => removeLabel(label?.name)}>
            <Icon
              className={Styles.crossCircleIcon}
              icon="cross-circle"
              size={20}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.profileSection}>
        {label?.profilePicture ? (
          <img
            src={
              label?.profilePicture
                ? label?.profilePicture?.toLowerCase()?.includes("http")
                  ? label.profilePicture?.replace(
                      "https://myworkspace",
                      "https://api1.myworkspace"
                    )
                  : fileBaseUrl + label?.profilePicture
                : Images?.AVA_DEFAULT
            }
            className={Styles.picture}
            alt="profile"
          />
        ) : (
          <div className={Styles.pictureWrapper}>{initial}</div>
        )}
        <div className={Styles.fontName}>{label?.name}</div>
      </div>
      <div className={Styles.icon} onClick={() => removeLabel(label?.name)}>
        <Icon
          className={Styles.crossCircleIcon}
          icon="cross-circle"
          size={20}
        />
      </div>
    </div>
  );
};

export default ProfileLabel;
