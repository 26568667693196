import { useState } from 'react';
import Styles from './styles.module.scss';
import { makeRandomString } from '@Helpers/makeRandomString';
import ModalWrapper from '@Atom/ModalWrapper';
import Icon from '@Atom/Icon';
import { candidateReject } from '@Services/manager/approval';
import Rotation from '@Atom/Rotation';

export default function RejectCandidateModal({ defaultData, show, onClose = () => {}, setTrigger = () => {}, setIsDone }) {
    const [isRejecting, setIsRejecting] = useState(false);

    const handleReject = async () => {
        try {
            setIsRejecting(true);
            await candidateReject(defaultData?.id, 'Declined');
            setIsDone({
                name: defaultData?.name,
                mode: 'reject'
            })
            setTrigger(makeRandomString(5));
            setIsRejecting(false);
            onClose();
        } catch (error) {
            setIsRejecting(false);
            console.log(error);
        }
    };

    return (
        <ModalWrapper show={show} handleClose={onClose}>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <div className={Styles.title}>
                        <Icon icon={'question-mark'} color={'#1571de'} size={'20'} />
                        <span>Reject Kandidat</span>
                    </div>
                    <div className={Styles.text}>Apakah kamu yakin ingin melakukan reject pada kandidat ini?</div>
                </div>
                <div className={Styles.buttons}>
                    <button className={Styles.primaryButton} disabled={isRejecting} onClick={onClose}>
                        <span>No</span>
                    </button>
                    <button className={Styles.primaryButton} disabled={isRejecting} onClick={handleReject}>
                        {isRejecting ? <Rotation width={'20px'} type="blue" /> : <span>Yes</span>}
                    </button>
                </div>
            </div>
        </ModalWrapper>
    );
}
