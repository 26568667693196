import AddressSection from "./Address";
import ContactInfoSection from "./ContactInfo";
import LastEducationSection from "./LastEducation";
import OthersSection from "./Others";
import PersonalDataSection from "./PersonalData";
import Styles from "./style.module.scss";

export default function FirstStep({
  personalData,
  setPersonalData,
  address,
  setAddress,
  lastEducation,
  setLastEducation,
  contactInfo,
  setContactInfo,
  otherData,
  setOtherData,

  trigger,
  setTrigger,
  lifetimeIdCard,
  setLifetimeIdCard,
  noTaxNumber,
  setNoTaxNumber,

  isDomicileTheSame,
  setIsDomicileTheSame,

  options,

  disableInput,
  disablePhoneVerification,
  loadingPersonId,
  isPhoneVerified,
  setIsPhoneVerified,
  loadingPhoneVerify,
  onClickPhoneVerification,
  errorField,
  setErrorDate,
  errorDate,
}) {
  return (
    <div className={Styles.container}>
      <PersonalDataSection
        form={personalData}
        setForm={setPersonalData}
        trigger={trigger}
        setTrigger={setTrigger}
        lifetimeIdCard={lifetimeIdCard}
        setLifetimeIdCard={setLifetimeIdCard}
        noTaxNumber={noTaxNumber}
        setNoTaxNumber={setNoTaxNumber}
        options={options}
        disableInput={disableInput}
        disablePhoneVerification={disablePhoneVerification}
        loadingPersonId={loadingPersonId}
        isPhoneVerified={isPhoneVerified}
        setIsPhoneVerified={setIsPhoneVerified}
        loadingPhoneVerify={loadingPhoneVerify}
        onClickPhoneVerification={onClickPhoneVerification}
        errorField={errorField}
        setErrorDate={setErrorDate}
        errorDate={errorDate}
      />
      <AddressSection
        form={address}
        setForm={setAddress}
        isDomicileTheSame={isDomicileTheSame}
        setIsDomicileTheSame={setIsDomicileTheSame}
        options={options}
        disableInput={disableInput}
        errorField={errorField}
      />
      <LastEducationSection
        form={lastEducation}
        setForm={setLastEducation}
        options={options}
        disableInput={disableInput}
        errorField={errorField}
      />
      <ContactInfoSection
        form={contactInfo}
        setForm={setContactInfo}
        options={options}
        disableInput={disableInput}
        errorField={errorField}
      />
      <OthersSection
        form={otherData}
        setForm={setOtherData}
        options={options}
        disableInput={disableInput}
        errorField={errorField}
      />
    </div>
  );
}
