import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

const Avatar = ({ imageUrl, name, size, initialsSize = 20 }) => {
    const styles = StyleSheet.create({
      avatarContainer: {
          width: size,
          height: size,
          borderRadius: 25,
          backgroundColor: '#ccc',
          alignItems: 'center',
          justifyContent: 'center',
      },
      avatarImage: {
          width: '100%',
          height: '100%',
          borderRadius: 25,
      },
      avatarInitials: {
          color: '#fff',
          fontSize: initialsSize,
          fontWeight: 'bold',
      },
    });
    const getInitials = (name) => {
        const initials = name
            .split(' ')
            .map((n) => n[0])
        if (initials.length > 2){
            const length = initials.length
            for (let index = 2; index < length; index++) {
                initials.pop()
            }
        }
        return initials.join('').toUpperCase();
    };

    return (
        <View style={styles.avatarContainer}>
            {imageUrl ? (
                <Image src={imageUrl} style={styles.avatarImage} />
            ) : (
                <Text style={styles.avatarInitials}>{getInitials(name)}</Text>
            )}
        </View>
    );
};

export default Avatar;
