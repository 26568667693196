import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function ViewDetailModal({ data, setData }) {
  const {pathname} = useLocation()
  const navigate=useNavigate()

  const handleSeeDetails = () => {
    const orders = data?.orders
    let orderIds = []
    for (let i = 0; i < orders.length; i++) {
      orderIds.push(orders[i]?.id)
    }
    const stringIds = orderIds?.join('-')
    const detailsQuery = `${pathname}/order?n=${data?.name}&c=${data?.code}&o=${stringIds}`
    navigate(detailsQuery)
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Client Information</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Location</h4>
          <p>{data?.province}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Email</h4>
          <p>{data?.email || "no email"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Phone</h4>
          <p>{data?.phoneNumber}</p>
        </div>
      </div>
      <button onClick={handleSeeDetails}>See Detail</button>
    </div>
  );
}
