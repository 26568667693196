import Styles from "./style.module.scss";
import loadingAnimationData from "@Assets/Lotties/loading.json";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import Lottie from "lottie-react";
import { useEffect } from "react";

export default function SendingModal({
  handleDone,
  isDone,
  show,
  setShow,
  error,
  setError,
  isJobPosting,
  handleClose,
  errorData,
}) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [show]);

  return (
    <div className={Styles.background}>
      <div
        className={`${Styles.container} ${
          isJobPosting && Styles.containerJobPost
        }`}
      >
        {isDone ? (
          <div className={Styles.content}>
            <div className={Styles.iconWrapper}>
              <Icon icon={"check-circle"} size={80} className={Styles.icon} />
            </div>
            <p>Sent</p>
            <span>The form has been sent</span>
            <button onClick={handleDone}>Done</button>
          </div>
        ) : error && !isJobPosting ? (
          <div className={Styles.errorWrapper}>
            <div className={Styles.imagesError}>
              <img src={Images.ERROR_STATUS} alt="" />
            </div>
            <div>{error}</div>
            <button
              onClick={() => {
                setError(null);
                setShow(false);
              }}
            >
              Back
            </button>
          </div>
        ) : error && isJobPosting ? (
          <div className={Styles.errorJobPost}>
            <div className={Styles.imageBox}>
              <img src={Images.ERROR_PORTAL} alt="" />
            </div>

            <div className={Styles.errorText}>
              <span>Oopss!</span>
              {error?.includes("Job Posting already exists") ? (
                <span>
                  Job posting untuk posisi {errorData?.title || "-"} berlokasi
                  di {errorData?.placement || "-"} sudah dibuat oleh{" "}
                  <div className={Styles.recruiter}>
                    {errorData?.recruiter || "-"}
                  </div>
                </span>
              ) : (
                <span>{error || "-"}</span>
              )}
            </div>
            <div onClick={handleClose} className={Styles.closeButton}>
              <span>Tutup</span>
            </div>
          </div>
        ) : (
          <div className={Styles.animationWrapper}>
            <Lottie
              animationData={loadingAnimationData}
              height={30}
              width={30}
            />
          </div>
        )}
      </div>
    </div>
  );
}
