import AutocompleteV2 from "@Atom/AutocompleteV2";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useRef, useState } from "react";
import Styles from "./styles.module.scss";

export default function FilterModal({
  handleDone = () => {},
  valueSearchCompany,
  setValueSearchCompany,
  valueSearchLocation,
  setValueSearchLocation,
  valueSearchPosition,
  setValueSearchPosition,
  searchName,
  setSearchName,
  valueIdCandiate,
  valueName,
  setSelectedButton,
  setSelectedButtonStepContract,
  setSelectedButtonStepRecruitment,
  setSelectedButtonTypeContract,
  searchIdCandidate,
  setSearchIdCandidate,
  setValueIdCandidate,
  setValueName,
  dataFilter,
  setSelectedDataCompany,
  selectedDataCompany,
  setSelectedDataLocation,
  selectedDataLocation,
  navigate,
  // refetchData,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const [valueCompany, setValueCompany] = useState(valueSearchCompany || "");
  const [valueLocation, setValueLocation] = useState(valueSearchLocation || "");
  const [selectedCompany, setSelectedCompany] = useState(
    selectedDataCompany || null
  );
  const [selectedLocation, setSelectedLocation] = useState(
    selectedDataLocation || null
  );
  const [valuePositionName, setValuePositionName] = useState(
    valueSearchPosition || ""
  );

  useEffect(() => {
    if (valueName) {
      setSearchName(valueName);
    } else {
      setSearchName("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueName]);

  useEffect(() => {
    if (searchIdCandidate) {
      setValueCompany(
        searchIdCandidate?.ExpectedPosition?.OrderScheme?.Client?.name
      );
      setSelectedCompany((prev) => ({
        ...prev,
        name: searchIdCandidate?.ExpectedPosition?.OrderScheme?.Client?.name,
        code: searchIdCandidate?.ExpectedPosition?.OrderScheme?.Client?.code,
      }));
      setValueLocation(searchIdCandidate?.ExpectedPosition?.City?.name);
      setSelectedLocation((prev) => ({
        ...prev,
        name: searchIdCandidate?.ExpectedPosition?.City?.name,
        code: searchIdCandidate?.ExpectedPosition?.City?.code,
      }));
      setValuePositionName(searchIdCandidate?.ExpectedPosition?.name);

      //
    }
  }, [searchIdCandidate]);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  const onClickFilter = () => {
    if (
      searchIdCandidate?.status === "RO-INVITE" ||
      searchIdCandidate?.status === "RO-QUALIFIED" ||
      searchIdCandidate?.status === "RO-DISQUALIFIED" ||
      searchIdCandidate?.status === "OFFER-INVITE" ||
      searchIdCandidate?.status === "OFFER-DISQUALIFIED" ||
      searchIdCandidate?.status === "OFFER-QUALIFIED" ||
      searchIdCandidate?.status === "CLIENT-DISQUALIFIED" ||
      searchIdCandidate?.status === "CLIENT-QUALIFIED" ||
      searchIdCandidate?.status === "CLIENT-INVITE" ||
      searchIdCandidate?.status?.includes("RO-INVITE-HOLD") ||
      searchIdCandidate?.status?.includes("RO-QUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("RO-DISQUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("OFFER-INVITE-HOLD") ||
      searchIdCandidate?.status?.includes("OFFER-DISQUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("OFFER-QUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("CLIENT-DISQUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("CLIENT-QUALIFIED-HOLD") ||
      searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") ||
      searchIdCandidate?.status?.includes("RO-INVITE-CONTRACTED") ||
      searchIdCandidate?.status?.includes("RO-QUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("RO-DISQUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("OFFER-INVITE-CONTRACTED") ||
      searchIdCandidate?.status?.includes("OFFER-DISQUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("OFFER-QUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("CLIENT-DISQUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("CLIENT-QUALIFIED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED")
    ) {
      setSelectedButton("Rekrutmen");
      if (
        (searchIdCandidate?.status === "RO-INVITE" &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status === "RO-INVITE" &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status === "RO-INVITE" &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-HOLD") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("RO-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("RO-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Undang Interview");
      } else if (
        (searchIdCandidate?.status === "RO-INVITE" &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status === "RO-DISQUALIFIED" &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("RO-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("RO-DISQUALIFIED-HOLD") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("RO-DISQUALIFIED-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Penilaian Interview");
      } else if (
        (searchIdCandidate?.status === "OFFER-INVITE" &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status === "OFFER-INVITE" &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status === "OFFER-INVITE" &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-HOLD") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("OFFER-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Invited")
      ) {
        setSelectedButtonStepRecruitment("Kirim Offering");
      } else if (
        (searchIdCandidate?.status === "CLIENT-INVITE" &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status === "CLIENT-INVITE" &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status === "CLIENT-INVITE" &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === null) ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Invited")
      ) {
        setSelectedButtonStepRecruitment("Undang Interview Klien");
      } else if (
        (searchIdCandidate?.status === "CLIENT-INVITE" &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status === "CLIENT-INVITE" &&
          searchIdCandidate?.tag === "Recommended") ||
        (searchIdCandidate?.status === "CLIENT-DISQUALIFIED" &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-HOLD") &&
          searchIdCandidate?.tag === "Recommended") ||
        (searchIdCandidate?.status?.includes("CLIENT-DISQUALIFIED-HOLD") &&
          searchIdCandidate?.tag === "Declined") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("CLIENT-INVITE-CONTRACTED") &&
          searchIdCandidate?.tag === "Recommended") ||
        (searchIdCandidate?.status?.includes(
          "CLIENT-DISQUALIFIED-CONTRACTED"
        ) &&
          searchIdCandidate?.tag === "Declined")
      ) {
        setSelectedButtonStepRecruitment("Kirim Rekomendasi");
      }
    } else if (
      searchIdCandidate?.status === "BS-REGISTERED" ||
      searchIdCandidate?.status === "BS-PRE-CONTRACT" ||
      searchIdCandidate?.status === "BS-CONTRACT" ||
      searchIdCandidate?.status?.includes("BS-REGISTERED-CONTRACTED") ||
      searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") ||
      searchIdCandidate?.status?.includes("BS-CONTRACT-CONTRACTED") ||
      searchIdCandidate?.status?.includes("BS-REGISTERED-HOLD") ||
      searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") ||
      searchIdCandidate?.status?.includes("BS-CONTRACT-HOLD")
    ) {
      setSelectedButton("Kontrak");
      if (searchIdCandidate?.portal === "Job Post Peralihan") {
        setSelectedButtonTypeContract("Peralihan");
      } else {
        setSelectedButtonTypeContract("Reguler");
      }

      if (
        (searchIdCandidate?.status === "BS-REGISTERED" &&
          searchIdCandidate?.stage === "REGISTER") ||
        (searchIdCandidate?.status === "BS-REGISTERED" &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "FIRST-VERIFICATION") ||
        (searchIdCandidate?.status?.includes("BS-REGISTERED-HOLD") &&
          searchIdCandidate?.stage === "REGISTER") ||
        (searchIdCandidate?.status?.includes("BS-REGISTERED-HOLD") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "FIRST-VERIFICATION") ||
        (searchIdCandidate?.status?.includes("BS-REGISTERED-CONTRACTED") &&
          searchIdCandidate?.stage === "REGISTER") ||
        (searchIdCandidate?.status?.includes("BS-REGISTERED-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "FIRST-VERIFICATION")
      ) {
        setSelectedButtonStepContract("Verifikasi Pertama");
      } else if (
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT" &&
          searchIdCandidate?.tag !== "Declined") ||
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT") ||
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.stage === "APPROVE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.stage === "APPROVE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "GENERATE-CONTRACT") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.stage === "APPROVE-CONTRACT")
      ) {
        setSelectedButtonStepContract("Generate Kontrak");
      } else if (
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Invited") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Invited")
      ) {
        setSelectedButtonStepContract("TTD Kontrak");
      } else if (
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status === "BS-PRE-CONTRACT" &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "SECOND-VERIFICATION") ||
        (searchIdCandidate?.status === "BS-CONTRACT" &&
          searchIdCandidate?.stage === "DONE") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-HOLD") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "SECOND-VERIFICATION") ||
        (searchIdCandidate?.status?.includes("BS-CONTRACT-HOLD") &&
          searchIdCandidate?.stage === "DONE") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.stage === "SIGN-CONTRACT" &&
          searchIdCandidate?.tag === "Accepted") ||
        (searchIdCandidate?.status?.includes("BS-PRE-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.tag === "Declined" &&
          searchIdCandidate?.stage === "SECOND-VERIFICATION") ||
        (searchIdCandidate?.status?.includes("BS-CONTRACT-CONTRACTED") &&
          searchIdCandidate?.stage === "DONE")
      ) {
        setSelectedButtonStepContract("Verifikasi Kedua");
      } else if (searchIdCandidate?.stage === "IOS-SUCCESS") {
        setSelectedButtonStepContract("Joined");
      }
    }
    handleDone();
    setValueIdCandidate(searchIdCandidate || null);
    setValueSearchCompany(valueCompany || "");
    setValueSearchLocation(valueLocation || "");
    setValueSearchPosition(valuePositionName || "");
    setValueName(searchName || "");
    setSelectedDataCompany(selectedCompany || null);
    setSelectedDataLocation(selectedLocation || null);
  };

  return (
    <div className={Styles.bodyFilter}>
      <div ref={boxRef}>
        <div>
          <div className={Styles.header}>
            <span>Filter</span>
            {searchIdCandidate ||
            (valueSearchCompany && valueSearchCompany !== "-") ||
            (valueSearchLocation && valueSearchLocation !== "-") ||
            valueSearchPosition ? (
              <div
                onClick={() => {
                  handleDone();
                  setSearchIdCandidate(null);
                  setValueIdCandidate(null);
                  setSelectedDataCompany(null);
                  setSelectedDataLocation(null);
                  setValueSearchPosition("");
                  setValueSearchCompany("");
                  setValueSearchLocation("");
                  setSearchName("");
                  setValueName("");
                  navigate(`/rekrutmen`);
                }}
              >
                Reset
              </div>
            ) : null}
          </div>

          <div className={Styles.inputBox}>
            <span>Nama Kandidat</span>
            <div className={Styles.searchBox}>
              <SearchBar
                placeholder={"Cari Nama Kandidat"}
                value={searchName}
                onChange={setSearchName}
                isRecruitment={true}
                options={dataFilter?.candidates || []}
                // isLoadingFilter={isLoadingFilter}
                onClickOption={() => {}}
                setValueIdCandidate={setSearchIdCandidate}
                valueIdCandidate={searchIdCandidate}
              />
            </div>
          </div>

          <div className={`${Styles.inputBox} ${Styles.border}`}>
            <span>Perusahaan</span>
            <div className={Styles.searchBox}>
              <AutocompleteV2
                value={valueCompany}
                setValue={setValueCompany}
                // isLoading={isLoadingPksList}
                placeholder={"Perusahaan"}
                options={dataFilter?.clients || []}
                type={"recruitment"}
                setSelected={setSelectedCompany}
                // disabled={verificationsearchIdCandidate?.Status !== "succeeded"}
              />
            </div>
          </div>

          <div className={`${Styles.inputBox} ${Styles.border}`}>
            <span>Lokasi Kerja</span>
            <div className={Styles.searchBox}>
              <AutocompleteV2
                value={valueLocation}
                setValue={setValueLocation}
                // isLoading={isLoadingPksList}
                placeholder={"Lokasi Kerja"}
                options={dataFilter?.cities || []}
                type={"recruitment"}
                setSelected={setSelectedLocation}
                // disabled={verificationsearchIdCandidate?.Status !== "succeeded"}
              />
            </div>
          </div>

          <div className={`${Styles.inputBox} ${Styles.border}`}>
            <span>Posisi</span>
            <div className={Styles.searchBox}>
              <div className={Styles.iconSearch}>
                <Icon icon={"contracted"} size={20} color={"#6F6F70"} />
              </div>
              <input
                placeholder="Posisi"
                className={Styles.inputSearch}
                value={valuePositionName}
                onChange={(e) => setValuePositionName(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={Styles.buttonSection} onClick={onClickFilter}>
          <span>Terapkan</span>
        </div>
      </div>
    </div>
  );
}
