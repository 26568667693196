import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useCallback, useEffect, useRef } from 'react'

export default function MenuListPopup({
  title = 'title goes here',
  options = [],
  withIcon = false,
  minWidth = '191px',
  onClose = () => { },
  isMultipleSection = false
}) {

  const ref = useRef()

  const handleClickOutside = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);


  if (!isMultipleSection) {
    return (
      <div ref={ref} className={Styles.container} style={{ minWidth: minWidth }}>
        <div className={Styles.title}>
          {title}
        </div>
        <div className={Styles.content}>
          {options?.map((option, i) => {
            const defaultAction = () => {
              onClose()
            }
            return (
              <button key={i} onClick={() => {
                if (option?.action) {
                  option?.action()
                  onClose()
                } else {
                  defaultAction()

                }
              }} disabled={option?.disabled}>
                {withIcon && <Icon
                  icon={option?.icon}
                  size={20}
                  color={option?.iconColor || '#000'}
                />}
                <span style={{ color: option?.textColor || '#000' }}>{option?.title}</span>
              </button>
            )
          })}

        </div>
      </div>
    )
  }
  return (
    <div ref={ref} className={`${Styles.container} ${Styles.multiple}`} style={{ minWidth: minWidth }}>
      {
        options?.map((option, i) => (
          <div key={i}>
            <div className={Styles.title}>
              {option?.title}
            </div>
            <div className={Styles.content}>
              {option?.options?.map((o, io) => {
                const defaultAction = () => {
                  onClose()
                }
                return (
                  <button key={io} onClick={() => {
                    if (o?.action) {
                      o?.action()
                      onClose()
                    } else {
                      defaultAction()

                    }
                  }}
                    disabled={o?.disabled}
                  >
                    {withIcon && <Icon
                      icon={o?.icon}
                      size={20}
                      color={o?.iconColor || '#000'}
                    />}
                    <span style={{ color: o?.textColor || '#000' }}>{o?.title}</span>
                  </button>
                )
              })}

            </div>
          </div>
        ))
      }
    </div>
  )
}