import Button from '@Atom/Button';
import Icon from '@Atom/Icon';
import UseOutsideClick from '@Hooks/useOutsideClick';
import DinamicDecorativeInputField from '@Molecule/DinamicDecorativeInputField';
import Slider from '@Molecule/Slider';
import { useEffect, useRef, useState } from 'react';
import AddManPowerModal from './AddManPowerModal';
import AreaEquipmentsModal from './AreaEquipmentsModal';
import DefaultManPower from './Default';
import OnManPowerProgress from './OnManPowerProgress';
import AddQualificationModal from './OnManPowerProgress/ThirdSubStep/AddQualificationModal';
import Styles from './style.module.scss';


export default function WithLocation(props) {
  const {
    form,
    setForm,
    setShowAddLocation,
    setShowAddLocationMobile,
    cities,
    qualifications,
    options,
    trigger,
    setTrigger,
    debounceTrigger
  } = props;
  const [showAdd, setShowAdd] = useState(false)
  const [showAddMobile, setShowAddMobile] = useState(false)
  const [showAddQualification, setShowAddQualification] = useState(false)
  const [showAddQualificationMobile, setShowAddQualificationMobile] = useState(false)
  const [showAddAreaEquipments, setShowAddAreaEquipments] = useState(false)
  const [showAddAreaEquipmentsMobile, setShowAddAreaEquipmentsMobile] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedManPower, setSelectedManPower] = useState(null)

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);


  useEffect(() => {
    if (showAddMobile !== false) {
      if (boxOutsideClick === false) {
        setShowAddMobile(false);
      }
    }
    if (showAddAreaEquipmentsMobile !== false) {
      if (boxOutsideClick === false) {
        setShowAddAreaEquipmentsMobile(false);
      }
    }
    if (showAddQualificationMobile !== false) {
      if (boxOutsideClick === false) {
        setShowAddQualificationMobile(false);
      }
    }
  }, [boxOutsideClick, showAddMobile, showAddAreaEquipmentsMobile, showAddQualificationMobile]);


  const handleAddSamePosition = (locationIndex, indexToAppend, title) => {
    const newForm = [...form]
    const jobArr = [...newForm[locationIndex]?.jobs]
    jobArr?.splice(indexToAppend + 1, 0, {
      title: title,
      contractType: null,
      salary: null,
      personalEquipments: [],
      allowances: [],
      demand: 1,
      fulfillmentDate: null,
      qualifications: []
    })
    form[locationIndex].jobs = jobArr
    setTrigger(Math.random())
  }

  const handleDeleteLocation = (locationIndex) => {
    const newForm = [...form]
    newForm?.splice(locationIndex, 1)
    form.splice(locationIndex, 1)
    setTrigger(Math.random())
  }

  const handleAddManPower = (locationIndex, title, demand) => {
    const newForm = [...form]
    const jobArr = [...newForm[locationIndex]?.jobs]
    jobArr?.push({
      title: title,
      contractType: null,
      salary: null,
      personalEquipments: [],
      allowances: [],
      demand: Number(demand),
      fulfillmentDate: null,
      qualifications: []
    })
    form[locationIndex].jobs = jobArr
  }

  const handleShowAddManPower = (i, type) => {
    setSelectedLocation(i)
    if (type === "mobile") {
      setShowAddMobile(true)
    } else {
      setShowAdd(true)
    }
  }

  const getLocationName = (locationCode) => {
    return cities?.find(c => c.code === locationCode)?.name
  }

  const handleShowAddQualifications = (locationIndex, manPowerIndex) => {
    setSelectedLocation(locationIndex)
    setSelectedManPower(manPowerIndex)
    setShowAddQualification(true)
  }

  const handleShowAddQualificationsMobile = (locationIndex, manPowerIndex) => {
    setSelectedLocation(locationIndex)
    setSelectedManPower(manPowerIndex)
    setShowAddQualificationMobile(true)
  }

  const handleChangeEquipments = (name, targetValue, equipmentIndex, locationIndex) => {
    const datas = form[locationIndex]?.areaWorkEquipments
    const newDatas = datas.map((obj) => {
      if (obj.name === name) {
        return { ...obj, value: targetValue };
      }
      return obj
    })
    form[locationIndex].areaWorkEquipments = newDatas
    debounceTrigger()
  }

  // const [trigger, setTrigger] = useState(null)


  const handleDeleteEquipment = (name, equipmentIndex, locationIndex) => {
    const data = [...form[locationIndex]?.areaWorkEquipments]
    const filtered = data.filter((el) => el.name !== name)
    form[locationIndex].areaWorkEquipments = filtered
    setTrigger(Math.random())
  }



  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {

  }, [trigger, windowSize])

  return (
    <div className={Styles.container}>
      {
        form?.map((each, i) => {
          const locationName = getLocationName(each?.location)
          return (
            <div key={i} className={Styles.each}>
              <div className={Styles.header}>
                <h3>{locationName}</h3>
                <div className={Styles.buttonsWrapper}>
                  {
                    each?.jobs?.length > 0 &&
                    <>
                      <button className={`${Styles.addButton} ${Styles.desktop}`} onClick={() => handleShowAddManPower(i, "desktop")}>
                        <Icon icon="circle-plus" size={17} />
                        <span>Man Power</span>
                      </button>
                      <button className={`${Styles.addButton} ${Styles.mobile}`} onClick={() => handleShowAddManPower(i, "mobile")}>
                        <Icon icon="circle-plus" size={17} />
                        {/* <span>Man Power</span> */}
                      </button>
                    </>
                  }
                  {!form?.[i]?.id && (
                    <button
                     onClick={() => handleDeleteLocation(i)}
                     className={`${Styles.deleteButton} ${Styles.desktop}`}
                     >
                      <span>Hapus</span>
                    </button>
                  )}
                  {!form?.[i]?.id && (
                    <button 
                    onClick={() => handleDeleteLocation(i)}
                    className={`${Styles.deleteButton} ${Styles.mobile}`}
                    >
                  <Icon icon="trash" size={17} />
                    </button>
                  )}

                </div>
              </div>
              <div className={Styles.content}>
                {
                  each?.jobs?.length === 0
                    ? <DefaultManPower locationIndex={i} handleShowAddManPower={handleShowAddManPower} setShowAdd={setShowAdd} setShowAddMobile={setShowAddMobile} />
                    : (
                      <>
                        <div className={Styles.positionsWrapperWeb}>
                          {each?.jobs?.map((eachJ, iJ) => {
                            return (
                              <OnManPowerProgress
                                key={iJ}
                                data={eachJ}
                                qualifications={qualifications}
                                form={form}
                                setForm={setForm}
                                locationIndex={i}
                                indexToAppend={iJ}
                                handleAddSamePosition={handleAddSamePosition}
                                handleShowAddQualifications={handleShowAddQualifications}
                                handleShowAddQualificationsMobile={handleShowAddQualificationsMobile}
                                options={options}
                                trigger={trigger}
                                setTrigger={setTrigger}
                                debounceTrigger={debounceTrigger}
                              />
                            )
                          })}
                        </div>
                        <div className={Styles.positionsWrapperMobile}>
                          {each?.jobs?.length > 1 && (
                            <Slider>
                              {each?.jobs?.map((eachJ, iJ) => {
                                return (
                                  <div key={iJ} className={Styles.eachJobWrapper}>
                                    <OnManPowerProgress
                                      data={eachJ}
                                      qualifications={qualifications}
                                      form={form}
                                      setForm={setForm}
                                      locationIndex={i}
                                      indexToAppend={iJ}
                                      handleAddSamePosition={handleAddSamePosition}
                                      handleShowAddQualifications={handleShowAddQualifications}
                                      handleShowAddQualificationsMobile={handleShowAddQualificationsMobile}
                                      options={options}
                                      trigger={trigger}
                                      setTrigger={setTrigger}
                                      debounceTrigger={debounceTrigger}
                                    />
                                  </div>
                                )
                              })}
                            </Slider>
                          )}
                          {each?.jobs?.length === 1 && (
                            <div className={Styles.SingleJobWrapper}>
                              <OnManPowerProgress
                                data={each?.jobs[0]}
                                qualifications={qualifications}
                                form={form}
                                setForm={setForm}
                                locationIndex={i}
                                indexToAppend={0}
                                handleAddSamePosition={handleAddSamePosition}
                                handleShowAddQualifications={handleShowAddQualifications}
                                handleShowAddQualificationsMobile={handleShowAddQualificationsMobile}
                                options={options}
                                trigger={trigger}
                                setTrigger={setTrigger}
                                debounceTrigger={debounceTrigger}
                              />
                            </div>
                          )}
                        </div>
                        <div className={`${Styles.areaWorkEquipmentWrapper} ${each?.jobs?.length === 1 && Styles.Single}`}>
                          <div className={Styles.boxHeader}>
                            <p>
                              Work Equipment
                            </p>
                            <div className={Styles.addButtonWrapper}>
                              <button className={Styles.desktop} onClick={() => {
                                setSelectedLocation(i)
                                setShowAddAreaEquipments(true)
                              }}>
                                <Icon icon="circle-plus" size={18} />
                                <span>Work Equipment</span>
                              </button>
                              <button className={Styles.mobile} onClick={() => {
                                setSelectedLocation(i)
                                setShowAddAreaEquipmentsMobile(true)
                              }}>
                                <Icon icon="circle-plus" size={18} />
                                <span>Work Equipment</span>
                              </button>
                            </div>

                          </div>
                          {form[i]?.areaWorkEquipments.length > 0 && (
                            <div className={Styles.equipmentsWrapper}>
                              {
                                form[i]?.areaWorkEquipments?.map((eachE, iE) => {
                                  return (
                                    <div key={iE} className={Styles.InputWrapper}>
                                      <DinamicDecorativeInputField
                                        onChange={handleChangeEquipments}
                                        onDelete={() => handleDeleteEquipment(eachE?.name, iE, i)}
                                        name={eachE?.name}
                                        value={eachE?.value || ''}
                                        index={iE}
                                        locationIndex={i}
                                        title={eachE?.name}
                                        adornment="Rp"
                                        type="currency"
                                        preventDelete={eachE?.id ? true : false}
                                      />
                                    </div>
                                  )
                                })
                              }
                            </div>
                          )}
                        </div>
                      </>
                    )
                }

              </div>
            </div>
          )
        })
      }


      <div className={`${Styles.buttonWrapper} ${Styles.desktop}`} onClick={() => setShowAddLocation(true)}>
        <Button type="primary" title={<><Icon icon="add" size={14} /><span className={Styles.buttonText}>Location</span></>} />
      </div>
      <div className={`${Styles.buttonWrapper} ${Styles.mobile}`} onClick={() => setShowAddLocationMobile(true)}>
        <Button type="primary" title={<><Icon icon="add" size={14} /><span className={Styles.buttonText}>Location</span></>} />
      </div>
      {showAdd &&
        <AddManPowerModal
          handleAddManPower={handleAddManPower}
          locationIndex={selectedLocation}
          setShowAdd={setShowAdd}
        />
      }
      {showAddQualification &&
        <AddQualificationModal
          qualifications={qualifications}
          setShowAdd={setShowAddQualification}
          form={form}
          setForm={setForm}
          locationIndex={selectedLocation}
          manPowerIndex={selectedManPower}
          trigger={trigger}
          setTrigger={setTrigger}
          preventDeleteIndicator={"id"}
        />
      }
      {showAddAreaEquipments &&
        <AreaEquipmentsModal
          options={options?.areaWorkEquipment}
          setShowAdd={setShowAddAreaEquipments}
          form={form}
          setForm={setForm}
          locationIndex={selectedLocation}
          manPowerIndex={selectedManPower}
          trigger={trigger}
          setTrigger={setTrigger}
          preventDeleteIndicator={"id"}
        />
      }
      <div className={`${Styles.modalWrapper} ${showAddMobile ? Styles.ShowModal : Styles.hideModal}`}>
        <AddManPowerModal
          type="mobile"
          handleAddManPower={handleAddManPower}
          locationIndex={selectedLocation}
          setShowAdd={setShowAddMobile}
        />
      </div>
      <div className={`${Styles.modalWrapper} ${showAddAreaEquipmentsMobile ? Styles.ShowModal : Styles.hideModal}`}>
        <AreaEquipmentsModal
          type="mobile"
          options={options?.areaWorkEquipment}
          setShowAdd={setShowAddAreaEquipmentsMobile}
          form={form}
          setForm={setForm}
          locationIndex={selectedLocation}
          manPowerIndex={selectedManPower}
          trigger={trigger}
          setTrigger={setTrigger}
          preventDeleteIndicator={"id"}
        />
      </div>
      <div className={`${Styles.modalWrapper} ${showAddQualificationMobile ? Styles.ShowModal : Styles.hideModal}`}>
        <AddQualificationModal
          type="mobile"
          qualifications={qualifications}
          setShowAdd={setShowAddQualificationMobile}
          form={form}
          setForm={setForm}
          locationIndex={selectedLocation}
          manPowerIndex={selectedManPower}
          trigger={trigger}
          setTrigger={setTrigger}
          preventDeleteIndicator={"id"}
        />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${showAddMobile || showAddAreaEquipmentsMobile || showAddQualificationMobile ? Styles.visible : ""}`}
      />
    </div>
  )
}