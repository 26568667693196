import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import { responseInvitation } from "@Services/officer/recruitment";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Spinner from "@Atom/Spinner";

export default function InvitationConfirmationModal({
  type,
  isSuccess,
  setIsSuccess,
  setShow,
  id,
  ordinal,
}) {
  const [isSure, setIsSure] = useState(false);
  const [remark, setRemark] = useState("");
  // const [newSchedule, setNewSchedule] = useState('')
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const successTemplate = useMemo(() => {
    switch (type) {
      case "accept":
        return {
          successTitle: "Konfirmasi Terkirim",
          successDescription:
            "Konfirmasi berhasil dikirimkan ke recruitment officer bersangkutan",
        };
      case "reject":
        return {
          successTitle: "Konfirmasi Terkirim",
          successDescription:
            "Konfirmasi berhasil dikirimkan ke recruitment officer bersangkutan",
        };
      case "reschedule":
        return {
          successTitle: "Catatan Terkirim!",
          successDescription:
            "Catatan telah terkirim ke Recruitment Officer, mohon tunggu pemberitahuan selanjutnya",
        };
      default:
        return {
          successTitle: "Konfirmasi Terkirim",
          successDescription:
            "Konfirmasi berhasil dikirimkan ke recruitment officer bersangkutan",
        };
    }
  }, [type]);

  const confirmationTemplate = useMemo(() => {
    switch (type) {
      case "reschedule":
        return {
          title: "Jadwal Ulang",
          subTitle: "Tulis catatan alasan kamu tidak bisa hadir dibawah ini",
          cancelButtonText: "Kembali",
          submitButtonText: isSubmitting ? <Spinner /> : "Kirim Catatan",
          onCancel: () => {
            setShow(false);
          },
          onSubmit: async (e) => {
            try {
              if (!remark) {
                setError("Wajib menuliskan tanggal dan alasan.");
              } else {
                setIsSubmitting(true);
                await responseInvitation(
                  {
                    status: "Accepted",
                    remark: remark,
                    ordinal,
                    // reschedule: moment(new Date(newSchedule))?.format('YYYY-MM-DD')
                  },
                  id
                );
                setIsSubmitting(false);
                setIsSuccess(true);
              }
            } catch (err) {
              setIsSubmitting(false);
              if (
                err?.response?.data?.error
                  ?.toLowerCase()
                  ?.includes("remark is required")
              ) {
                setError("Wajib menuliskan alasan");
              } else {
                setError(err?.response?.data?.error || "Something went wrong");
              }
            }
          },
          renderExtra: () => (
            <div className={Styles.inputWrapper}>
              {/* <InputDateField
                title='Tanggal'
                value={newSchedule}
                setValue={setNewSchedule}
              /> */}
              <InputField
                value={remark}
                onChange={(e) => setRemark(e?.target?.value)}
                title={"Catatan"}
                placeholder={"Alasan tidak hadir"}
                type={"desc"}
              />
            </div>
          ),
        };
      case "accept":
        return {
          title: "Konfirmasi",
          subTitle: "Apa kamu bisa hadir untuk undangan interview kali ini?",
          cancelButtonText: "Kembali",
          submitButtonText: isSubmitting ? <Spinner /> : "Bisa hadir",
          onCancel: () => setShow(false),
          onSubmit: async (e) => {
            try {
              // await responseInvitation({
              //   status: 'Declined',
              //   // remark: remark
              // }, id)
              // setIsSure(true)
              // setIsSuccess(true)
              setIsSubmitting(true);
              await responseInvitation(
                {
                  status: "Accepted",
                  ordinal,
                },
                id
              );
              setIsSubmitting(false);
              setIsSuccess(true);
            } catch (err) {
              setIsSubmitting(false);
              setError(err?.response?.data?.error || "Something went wrong");
            }
          },
          renderExtra: () => {},
        };
      default:
        if (!isSure) {
          return {
            title: "Konfirmasi",
            subTitle:
              "Apa kamu yakin belum bisa hadir untuk undangan interview kali ini?",
            cancelButtonText: "Kembali",
            submitButtonText: isSubmitting ? <Spinner /> : "Belum bisa hadir",
            onCancel: () => setShow(false),
            onSubmit: async (e) => {
              try {
                // await responseInvitation({
                //   status: 'Declined',
                //   // remark: remark
                // }, id)
                setIsSure(true);
                // setIsSuccess(true)
              } catch (err) {
                if (
                  err?.response?.data?.error
                    ?.toLowerCase()
                    ?.includes("remark is required")
                ) {
                  setError("Wajib menuliskan alasan");
                } else {
                  setError(
                    err?.response?.data?.error || "Something went wrong"
                  );
                }
              }
            },
            renderExtra: () => {},
          };
        } else {
          return {
            title: "Belum Bisa Hadir",
            // subTitle: 'Tuliskan catatan berisikan alasan kamu tidak bisa hadir dibawah ini',
            cancelButtonText: "Kembali",
            submitButtonText: isSubmitting ? <Spinner /> : "Kirim",
            onCancel: () => {
              setIsSure(false);
              setShow(false);
            },
            onSubmit: async (e) => {
              try {
                // if (!remark) {
                //   setError('Wajib menuliskan alasan.')
                // } else {
                setIsSubmitting(true);
                await responseInvitation(
                  {
                    status: "Declined",
                    remark: remark,
                    ordinal,
                    // reschedule: moment(new Date(newSchedule))?.format('YYYY-MM-DD')
                  },
                  id
                );
                setIsSubmitting(false);
                setIsSuccess(true);
                // }
              } catch (err) {
                setIsSubmitting(false);
                if (
                  err?.response?.data?.error
                    ?.toLowerCase()
                    ?.includes("remark is required")
                ) {
                  setError("Wajib menuliskan alasan");
                } else {
                  setError(
                    err?.response?.data?.error || "Something went wrong"
                  );
                }
              }
            },
            renderExtra: () => (
              <div className={Styles.inputWrapper}>
                <InputField
                  value={remark}
                  onChange={(e) => setRemark(e?.target?.value)}
                  title={"Catatan Tidak Hadir (optional)"}
                  placeholder={"Tulis alasan tidak hadir"}
                  type={"desc"}
                />
              </div>
            ),
          };
        }
    }
  }, [type, isSubmitting, isSure, setShow, remark, ordinal, id, setIsSuccess]);

  return (
    <div className={`${Styles.container} ${isSuccess ? Styles.success : ""}`}>
      {!isSuccess ? (
        <div className={Styles.confirmationWrapper}>
          <span className={Styles.title}>{confirmationTemplate?.title}</span>
          <span className={Styles.subTitle}>
            {confirmationTemplate?.subTitle}
          </span>
          {confirmationTemplate?.renderExtra()}
          <div
            className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}
          >
            {error || "error placeholder"}*
          </div>
          <div className={Styles.buttonsWrapper}>
            <button onClick={confirmationTemplate?.onCancel}>
              {confirmationTemplate?.cancelButtonText}
            </button>
            <button
              disabled={
                isSubmitting ||
                (type === "reschedule" ? (!remark ? true : false) : false)
              }
              onClick={confirmationTemplate?.onSubmit}
            >
              {confirmationTemplate?.submitButtonText}
            </button>
          </div>
        </div>
      ) : (
        <div className={Styles.successWrapper}>
          <div>
            {/* <img src={checkCircleGifUrl} alt="" /> */}
            <Icon icon={"check-circle"} size={86} color={"#1571DE"} />
          </div>
          <span className={Styles.title}>{successTemplate?.successTitle}</span>
          <span className={Styles.subTitle}>
            {successTemplate?.successDescription}
          </span>
          <button onClick={() => setShow(false)}>Ok</button>
        </div>
      )}
    </div>
  );
}
