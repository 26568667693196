import Icon from "@Atom/Icon";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import Styles from "./style.module.scss";

export default function SchemeDetailNavigator() {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleClickBack = () => {
    // navigate(`/clients/orders?n=${name}&c=${code}`);
    navigate(-1);
  };

  return (
    <div className={Styles.container}>
      <button onClick={handleClickBack}>
        <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
      </button>
      <CustomBreadCrumb>
        <button onClick={handleClickBack} className={Styles.inactive}>
          Job Orders
        </button>
        <button onClick={handleClickBack} className={Styles.inactive}>
          All List
        </button>
        <button disabled className={Styles.active}>
          Detail
        </button>
      </CustomBreadCrumb>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() =>
            window.open(
              `/clients/orders/pdf-preview/${id}`,
              "_blank",
              "rel=noopener noreferrer"
            )
          }
          className={Styles.previewButton}
        >
          <span>Preview PDF</span>
          <Icon icon={"eye-visible"} size={20} />
        </button>
      </div>
    </div>
  );
}
