import MultiSelectProfileLabel from '@Molecule/MultiSelectProfileLabel'
import { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'

export default function SixthStep(props) {
  const {
    form,
    cities,
    delegations,
    setDelegations,
    users,
    onNext,
    onPrevious,
    setTrigger,
    isDone
  } = props

  // const [businessManagers, setBusinessManagers] = useState(Array(form?.length).fill([]))
  const [businessManagers, setBusinessManagers] = useState(delegations?.businessManagers)
  const [accountManagers, setAccountManagers] = useState(delegations?.accountManagers)
  const [admins, setAdmins] = useState(delegations?.admins)
  const [recruiters, setRecruiters] = useState(delegations?.recruiters)


  const handleAddBM = (value, id, index) => {
    const newBusinessManagers = [...businessManagers]
    const newVal = { ...value }
    const isExist = newBusinessManagers?.some(b => b?.locationId === id && b.name === value?.name)
    newVal.locationId = id
    if (!isExist) {
      newBusinessManagers.push(newVal)
      setBusinessManagers(newBusinessManagers)
    }
  }
  
  const handleRemoveBM = (name, id, index) => {
    const newBusinessManagers = [...businessManagers]

    const exactIndex = newBusinessManagers.findIndex(element => element.locationId === id && element.name === name);

    newBusinessManagers.splice(exactIndex, 1)
    setBusinessManagers(newBusinessManagers)
  }

  const handleAddPIC = (value, id, index) => {
    const newAccountManagers = [...accountManagers]
    const newVal = { ...value }
    const isExist = newAccountManagers?.some(b => b?.locationId === id && b.name === value?.name)
    newVal.locationId = id
    if (!isExist) {
      newAccountManagers.push(newVal)
      setAccountManagers(newAccountManagers)
    }
  }

  const handleRemovePIC = (name, id, index) => {
    const newAccountManagers = [...accountManagers]

    const exactIndex = newAccountManagers.findIndex(element => element.locationId === id && element.name === name);

    newAccountManagers.splice(exactIndex, 1)
    setAccountManagers(newAccountManagers)
  }

  const handleAddAdmin = (value, id, index) => {
    const newAdmins = [...admins]
    const newVal = { ...value }
    const isExist = newAdmins?.some(b => b?.locationId === id && b.name === value?.name)
    newVal.locationId = id
    if (!isExist) {
      newAdmins.push(newVal)
      setAdmins(newAdmins)
    }
  }

  const handleRemoveAdmin = (name, id, index) => {
    const newAdmins = [...admins]

    const exactIndex = newAdmins.findIndex(element => element.locationId === id && element.name === name);

    newAdmins.splice(exactIndex, 1)
    setAdmins(newAdmins)
  }

  const handleAddRecruiter = (value, id, index) => {
    const newRecruiters = [...recruiters]
    const newVal = { ...value }
    const isExist = newRecruiters?.some(b => b?.locationId === id && b.name === value?.name)
    newVal.locationId = id
    if (!isExist) {
      newRecruiters.push(newVal)
      setRecruiters(newRecruiters)
    }
  }

  const handleRemoveRecruiter = (name, id, index) => {
    const newRecruiters = [...recruiters]

    const exactIndex = newRecruiters.findIndex(element => element.locationId === id && element.name === name);

    newRecruiters.splice(exactIndex, 1)
    setRecruiters(newRecruiters)
  }

  const getBmPerLocation = (locationId) => {
    return businessManagers?.filter((label) => label?.locationId === locationId);
  }
  const getPicPerLocation = (locationId) => {
    return accountManagers?.filter((label) => label?.locationId === locationId);
  }
  const getAdminPerLocation = (locationId) => {
    return admins?.filter((label) => label?.locationId === locationId);
  }
  const getRecruitersPerLocation = (locationId) => {
    return recruiters?.filter((label) => label?.locationId === locationId);
  }

  const getLocationName = (locationCode) => {
    return cities?.find(c => c.code === locationCode)?.name
  }

  useEffect(() => {
    const newDelegations = delegations
    newDelegations.businessManagers = businessManagers
    newDelegations.accountManagers = accountManagers
    newDelegations.admins = admins
    newDelegations.recruiters = recruiters
    setDelegations(newDelegations)
    setTrigger(Math.random())
  }, [delegations, businessManagers, accountManagers, admins, recruiters, setDelegations, setTrigger])
  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);



  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {form?.map((eachL, iL) => {
        const locationName = getLocationName(eachL?.location)
        const managers = getBmPerLocation(eachL?.location)
        const accountManagers = getPicPerLocation(eachL?.location)
        const accountOfficers = getAdminPerLocation(eachL?.location)
        const recruitmentOfficers = getRecruitersPerLocation(eachL?.location)
        return (
          <div key={iL} className={Styles.each}>
            <h4>{locationName}</h4>
            <div className={Styles.cardsWrapper}>
              <div className={Styles.selectWrapper}>
                <MultiSelectProfileLabel
                  title={"Business Manager"}
                  options={users?.businessManagers}
                  labels={managers}
                  setLabels={setBusinessManagers}
                  onAdd={handleAddBM}
                  onRemove={handleRemoveBM}
                  index={iL}
                  id={eachL?.location}
                />
              </div>
              <div className={Styles.selectWrapper}>
                <MultiSelectProfileLabel
                  title={"Account Manager/PIC"}
                  options={users?.accountManagerOrPIC}
                  labels={accountManagers}
                  setLabels={setAccountManagers}
                  onAdd={handleAddPIC}
                  onRemove={handleRemovePIC}
                  index={iL}
                  id={eachL?.location}
                />
              </div>
              <div className={Styles.selectWrapper}>
                <MultiSelectProfileLabel
                  title={"Account Officer/Admin"}
                  options={users?.accountOfficerOrAdmin}
                  labels={accountOfficers}
                  setLabels={setAdmins}
                  onAdd={handleAddAdmin}
                  onRemove={handleRemoveAdmin}
                  index={iL}
                  id={eachL?.location}
                />
              </div>
              <div className={Styles.selectWrapper}>
                <MultiSelectProfileLabel
                  title={"Recruitment Officer"}
                  options={users?.recruitmentOfficers}
                  labels={recruitmentOfficers}
                  setLabels={setRecruiters}
                  onAdd={handleAddRecruiter}
                  onRemove={handleRemoveRecruiter}
                  index={iL}
                  id={eachL?.location}
                />
              </div>
            </div>

          </div>
        )
      })}

      {
        form?.length === 0 && (
          <div className={Styles.noData}>No Data Yet</div>
        )
      }

      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button disabled={!isDone} onClick={onNext}>Next Step</button>
      </div>
    </div>
  )
}