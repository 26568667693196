import AutocompleteV2 from '@Atom/AutocompleteV2';
import { allFilledObjChecker } from '@Helpers/allFilledObjChecker';
import { omitKeys } from '@Helpers/omitKey';
import { validateEmail } from '@Helpers/validateEmail';
import useQuery from '@Hooks/useQuery';
import InputField from '@Molecule/InputField';
import { updateClientInfoClient } from '@Services/client/dashboard';
import { updateClientInfo } from '@Services/manager/client';
import { useEffect, useState } from 'react';
import Styles from './style.module.scss';

export default function EditCompanyInfoModal({
  data,
  setShow,
  setTrigger,
  industries
}) {

  const [form, setForm] = useState({
    name: data?.client?.name || '',
    taxNumber: data?.client?.taxNumber || '',
    legalName: data?.client?.legalName || '',
    brandName: data?.client?.brandName || '',
    groupName: data?.client?.groupName || '',
    industry: data?.client?.industry || '',
    province: data?.client?.province || '',
    postal: data?.client?.postal || '',
    address: data?.client?.address || '',
    phoneNumber: data?.client?.phoneNumber || '',
    email: data?.client?.email || '',
    website: data?.client?.website || ''
  })
  const query = useQuery()
  const code = query.get('c') || sessionStorage.getItem("clientCode")

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [errorField, setErrorField] = useState(false)

  const handleChange = (e, name) => {
    // eslint-disable-next-line
    const re = /^[0-9\b]+$/;

    let targetValue;
    if (name === 'phoneNumber') {
      if ((e.target.value === "" || re.test(e.target.value))) {
        targetValue = e.target.value
      } else {
        targetValue = form?.phoneNumber
      }
    } else {
      targetValue = e?.target ? e?.target?.value : e
    }

    setForm((prev) => {
      return {
        ...prev,
        [name]: targetValue
      }

    })
  }

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (
        !allFilledObjChecker(omitKeys(['website'], form)) ||
        !validateEmail(form?.email)
      ) {
        setErrorField(true)
      } else {
        setErrorField(false)
        setSubmitting(true)
        if (localStorage.getItem("isClient") === 'true') {
          await updateClientInfoClient(code, form)
        } else {
          await updateClientInfo(code, form)
        }
        setTrigger(Math.random())
        setSubmitting(false)
        setShow(false)
      }
    } catch (err) {
      setSubmitting(false)
      setError(err?.response?.data?.error || 'Something went wrong')
    }

  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError])

  return (
    <div className={`${Styles.container} ${((windowSize[0] <= 768) ? Styles.mobile : '')}`}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Edit Company Information</h3>
        </div>

      </div>
      <form className={Styles.form} onSubmit={handleSubmit}>
        <InputField
          title="Nama Perusahaan"
          placeholder="Berdasarkan pendirian perusahaan"
          value={form?.name}
          onChange={(e) => handleChange(e, 'name')}
          required
          isError={errorField && !form?.name}
          isErrorV2
          errorText='Nama Perusahaan belum diisi'
        />
        <InputField
          title="Nama Resmi Perusahaan (terdaftar secara legal)"
          placeholder={"Nama resmi perusahaan (terdaftar secara legal)"}
          value={form?.legalName}
          onChange={(e) => handleChange(e, 'legalName')}
          required
          isError={errorField && !form?.legalName}
          isErrorV2
          errorText='Nama Resmi Perusahaan belum diisi'
        />
        <InputField
          title={'Nama Grup'}
          placeholder={'Nama grup'}
          value={form?.groupName}
          onChange={(e) => handleChange(e, 'groupName')}
          required
          isError={errorField && !form?.groupName}
          isErrorV2
          errorText='Nama Grup belum diisi'
        />
        <div className={Styles.stacked}>

          <InputField
            title={'Nama Brand'}
            placeholder={'Nama brand'}
            value={form?.brandName}
            onChange={(e) => handleChange(e, 'brandName')}
            required
            isError={errorField && !form?.brandName}
            isErrorV2
            errorText='Nama Brand belum diisi'
          />
          {/* <InputField
            title={'Industri'}
            placeholder={'Industri'}
            value={form?.industry}
            onChange={(e) => handleChange(e, 'industry')}
            required
            isError={errorField && !form?.industry}
            isErrorV2
            errorText='Industri belum diisi'
          /> */}
          <div className={Styles.autocomplete}>
            <p>
              Industri Perusahaan
              <span className={Styles.asterisk}>&#x2a;</span>
            </p>
            <AutocompleteV2
              // value={type === 'NEW' ? form?.taxNumber || "" : ''}
              value={form?.industry}
              setValue={(newVal) =>
                handleChange(newVal, 'industry')
              }
              options={industries?.map((obj) => {
                return {
                  ...obj,
                  name: obj?.bisnis_name,
                };
              })}
              placeholder="Industri Perusahaan"
              isError={errorField && !form?.industry}
              isErrorV2
              errorText='Industri belum diisi'  
            />
          </div>
        </div>
        <InputField
          title="NPWP Perusahaan"
          placeholder={"NPWP perusahaan"}
          value={form?.taxNumber}
          onChange={(e) => handleChange(e, 'taxNumber')}
          required
          isError={errorField && !form?.taxNumber}
          isErrorV2
          errorText='NPWP Perusahaan belum diisi'
        />

        <div className={Styles.stacked}>
          <InputField
            title="Provinsi Perusahaan"
            placeholder={"Provinsi perusahaan"}
            value={form?.province}
            onChange={(e) => handleChange(e, 'province')}
            required
            isError={errorField && !form?.province}
            isErrorV2
            errorText='Provinsi Perusahaan belum diisi'
          />
          <InputField
            title="Kode Pos"
            placeholder={"Kode pos daerah perusahaan"}
            value={form?.postal}
            onChange={(e) => handleChange(e, 'postal')}
            required
            isError={errorField && !form?.postal}
            isErrorV2
            errorText='Kode Pos belum diisi'
            onKeyDown={(evt) => ["e", "E", "+", "-", '.'].includes(evt.key) && evt.preventDefault()}
            type='number'
          />
        </div>
        <InputField
          type={"address"}
          placeholder={"Alamat lengkap perusahaan anda"}
          hideIcon
          title="Alamat Perusahaan"
          value={form?.address}
          onChange={(e) => handleChange(e, 'address')}
          required
          isError={errorField && !form?.address}
          isErrorV2
          errorText='Alamat belum diisi'
        />

        <div className={Styles.stacked}>
          <InputField
            title="Nomor Telepon Perusahaan"
            placeholder={"628*** atau 021****"}
            value={form?.phoneNumber}
            onChange={(e) => handleChange(e, 'phoneNumber')}
            required
            isError={errorField && !form?.phoneNumber}
            isErrorV2
            errorText='Nomor Telepon belum diisi'
          />
          <InputField
            title="Email Perusahaan"
            placeholder={"example@mail.com"}
            value={form?.email}
            onChange={(e) => handleChange(e, 'email')}
            required
            isError={errorField && (!form?.email || !validateEmail(form?.email))}
            isErrorV2
            errorText={!form?.email ? 'Email belum diisi' : 'Email tidak sesuai'}
          />
        </div>
        <InputField
          title="Website Perusahaan"
          placeholder={"Website resmi perusahaan"}
          value={form?.website}
          onChange={(e) => handleChange(e, 'website')}
        // required
        // isError={errorField && !form?.website}
        // isErrorV2
        // errorText='Website Perusahaan belum diisi'
        />
        <div className={`${Styles.ErrorWrapper} ${error ? Styles.Visible : Styles.hide}`}>
          <span>{error || 'error placeholder'}</span>
        </div>
        <div className={Styles.buttonsWrapper}>
          <button type="button" onClick={() => setShow(false)}>
            Cancel
          </button>
          <button disabled={submitting || error} type="submit">
            {submitting ? 'Saving changes...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  )
}