import Images from "@Theme/Images";
import Styles from "./styles.module.scss";

export default function Agreement({ data }) {
  return !data?.workMethod && !data?.workType && !data?.workSystem ? (
    <div className={Styles.containerDataNull}>
      <div>
        <div>
          <img src={Images.AMICO3} alt="" />
        </div>
        <span>
          Tidak ada data yang dapat ditampilkan, silahkan lengkapi data job
          posting terlebih dahulu.
        </span>
      </div>
    </div>
  ) : (
    <div className={Styles.container}>
      <div>
        <span>Metode Kerja</span>
        <span>{data?.workMethod || "-"}</span>
      </div>

      <div>
        <span>Tipe Pekerjaan</span>
        <span>{data?.workType || "-"}</span>
      </div>

      <div>
        <span>Sistem Kerja</span>
        <span>{data?.workSystem || "-"}</span>
      </div>
    </div>
  );
}
