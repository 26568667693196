/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Button from "@Atom/Button";
import Checkbox from "@Atom/Checkbox";
import LoadingAnimation from "@Atom/LoadingAnimation";
import React, { useCallback, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import Spinner from "@Atom/Spinner";

export default function PdfView({ pdf, images, setStep, data }) {
  const [check, setCheck] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [isLoading, setIsloading] = useState(false);

  const handleClick = () => {
    if (pdf) {
      setIsloading(false);
      window.open(pdf);
    } else {
      setIsloading(true);
    }
  };

  const RenderPdf = useCallback(() => {
    if (windowSize[0] > 768) {
      if (pdf) {
        return (
          <div className={Styles.PdfWrapper}>
            <embed src={`${pdf}#navpanes=0`} type="application/pdf"></embed>
          </div>
        );
      } else {
        return (
          <div className={Styles.PdfWrapper}>
            <div style={{ width: "250px", aspectRatio: 1 }}>
              <LoadingAnimation />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={Styles.contractCardMobile}>
          <div className={Styles.cardBox}>
            <div className={Styles.topCard}>
              <div className={Styles.imagesPermata}>
                <img src={Images.PERMATA_LOGO} alt="" />
              </div>
              <span className={Styles.permata}>Permata Indo Sejahtera</span>
            </div>
            <div className={Styles.bottomCard}>
              <div className={Styles.nameCandidateBox}>
                <span className={Styles.name}>
                  Hai {data?.response?.candidate?.name}
                </span>
                <span className={Styles.desc}>
                  Untuk melihat dan mereview kontrak kerja anda silahkan
                  download dan lanjutkan ke proses tanda tangan kontrak.
                </span>
              </div>
              <div className={Styles.buttonBox} onClick={handleClick}>
                <div className={Styles.leftButton}>
                  <Icon icon={"doc"} size={24} color={"#000000"} />
                  <span className={Styles.download}>
                    Download dokumen kontrak kerja
                  </span>
                </div>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Icon icon={"arrow-down-tray"} color={"#1571DE"} size={24} />
                )}
              </div>
              <div className={Styles.contactText}>Kontak Kami</div>
              <div className={Styles.infoContact}>
                <div className={Styles.infoBox}>
                  <Icon icon={"phone"} color={"#C2C2C2"} size={16} />
                  <span>021 726 5364</span>
                </div>
                <div className={Styles.infoBox}>
                  <Icon icon={"email"} color={"#C2C2C2"} size={16} />
                  <span>info@permataindonesia.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      // if (images.length > 0) {
      //   return (
      //     <div className={`${Styles.ImagesPdfWrapper}`}>
      //       {images.map((el) => (
      //         <img src={el} alt="pdf" />
      //       ))}
      //     </div>
      //   );
      // } else {
      //   return (
      //     <div className={Styles.PdfWrapper}>
      //       <div style={{ width: "250px", aspectRatio: 1 }}>
      //         <LoadingAnimation />
      //       </div>
      //     </div>
      //   );
      // }
    }
  }, [windowSize, pdf]);

  return (
    <div className={Styles.PdfContainer}>
      <RenderPdf />

      <div className={Styles.Footer}>
        <div className={Styles.LeftSection}>
          <p>Persetujuan Kontrak Kerja</p>
          <div className={Styles.ContractText}>
            <Checkbox isChecked={check} setIsChecked={setCheck} />
            <div className={Styles.ContractDesc}>
              <p>
                Dengan ini saya menyetujui segala hal yang menyangkut perjanjian
                kontrak kerja tersebut
              </p>
              <p>
                klik lanjut untuk memasukan tanda tangan dan paraf kontrak kerja
                anda
              </p>
            </div>
          </div>
        </div>
        <div className={`${Styles.RightSection}`}>
          <Button
            type={"button-icon"}
            title={"Lanjutkan"}
            icon={"arrow-right-stick"}
            onClick={() => setStep(2)}
            disabled={!check}
          />
        </div>
      </div>
    </div>
  );
}
