import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import { fileBaseUrl } from "@Config/api";

export default function SuccessSign({ data, pdf }) {
  // eslint-disable-next-line no-unused-vars
  const [pdfUrl, setPdfUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoading(true);
      const convertedHtml = await convertHtmlToPdf(
        data.candidateContract?.template,
        "contract",
        {
          ...data.candidate,
          image: fileBaseUrl + data.candidateContract.image,
          initials: fileBaseUrl + data.candidateContract.initials,
          signature: fileBaseUrl + data.candidateContract.signature,
          latitude:data.candidateContract.latitude,
          longitude:data.candidateContract.longitude,
          businessManagerID: data.candidateContract.businessManagerID,
          contractType: data?.expectedPosition?.contractType,
        }
      );
      setLoading(false);
      setPdfUrl(convertedHtml);
    };
    getPdfUrl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.candidateContract]);

  return (
    <div className={Styles.Main}>
      <div className={Styles.Container}>
        <div className={Styles.Headers}>
          <div className={Styles.Logo}>
            <img src={Images.PERMATA_LOGO} alt="permata" />
          </div>
          <p>Permata Indo Sejahtera</p>
        </div>
        <div className={Styles.Content}>
          <div className={Styles.ContentText}>
            <p>Hi {data.candidate.name}</p>
            <p>
              Anda telah berhasil mengirim kontrak kerja yang sudah ditanda
              tangani, kami akan segera meninjau dokumen anda. Mohon untuk
              menunggu beberapa waktu dan kami akan segera menghubungi anda
              kembali. Terima kasih
            </p>
          </div>
          {/* <div className={Styles.ContentFile}>
            <div className={Styles.FileWrapper}>
              <Icon icon={"document"} size={24} />
              <p>Download dokumen kontrak kerja</p>
            </div>
            <div onClick={() => window.open(pdfUrl)}>
              <Icon icon={"arrow-down-tray"} size={24} color="#1571DE" />
            </div>
          </div> */}
          <div className={Styles.ContentFooter}>
            <p>Kontak Kami</p>
            <div className={Styles.ContactWrapper}>
              <div className={Styles.Contact}>
                <Icon icon={"phone"} size="20px" color={"#C2C2C2"} />
                <p>021 726 5364</p>
              </div>
              <div className={Styles.Contact}>
                <Icon icon={"email"} size="20px" color={"#C2C2C2"} />
                <p>info@permataindonesia.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
