import { useMemo } from "react";
import Styles from "./styles.module.scss";
import { Tooltip } from "@mui/material";
import moment from "moment";

export default function DetailJobStep({ data }) {
  const startIndex = 0;
  const endIndex = 1;

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function changeFormatPlacement(str) {
    return str.replaceAll("::", ", ");
  }

  const slicedItems = useMemo(() => {
    if (data?.placement?.length > 1) {
      return data?.placement?.slice(endIndex);
    }
  }, [data?.placement]);

  const selectedItems = useMemo(() => {
    if (data?.placement?.length > 0) {
      return data?.placement?.slice(startIndex, endIndex);
    }
    return [];
  }, [data?.placement]);

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  return (
    <div className={Styles.container}>
      <div>
        <div>
          <div>
            <span>Posisi</span>
            <span>{data?.title?.toUpperCase() || "-"}</span>
          </div>

          <div>
            <span>Range Gaji FLB</span>
            <span>
              {" "}
              Rp
              {data?.salary?.split("-")?.map((s, is, arr) => (
                <span key={is}>
                  {formatNumber(
                    +s
                      ?.replace(".", ",")
                      .replace(/\d{3}(?=(\d{3})*,)/g, function (s) {
                        return "." + s;
                      })
                  )}{" "}
                  {arr.length > 1 && is === 0
                    ? " - "
                    : is !== 0
                    ? " /bln"
                    : "/bln"}
                </span>
              ))}
            </span>
          </div>
        </div>

        <div>
          <div>
            <span>Lokasi</span>
            {Array.isArray(data?.placement) ? (
              <div>
                <span>
                  {" "}
                  {selectedItems?.length > 0
                    ? toTitleCase(
                        changeFormatPlacement(
                          selectedItems.map((item) => item.city).join(", ")
                        )
                      )
                    : "-"}
                </span>
                {data?.placement?.length > 1 ? (
                  <Tooltip
                    title={
                      <span>
                        {data?.placement?.map((item) => item.city).join(", ")}
                      </span>
                    }
                  >
                    <span>+{slicedItems?.length}</span>
                  </Tooltip>
                ) : null}
              </div>
            ) : (
              <span>
                {data?.placement
                  ? toTitleCase(changeFormatPlacement(data?.placement))
                  : "-"}
              </span>
            )}
          </div>
          <div>
            <span>Jumlah Kebutuhan</span>
            <span>{data?.amount || "-"} TKO</span>
          </div>
        </div>

        <div>
          <div>
            <span>Tipe Kontrak</span>
            <span>{data?.contractType || "-"}</span>
          </div>

          <div>
            <span>Target tanggal pemenuhan dari klien</span>
            <span>
              {data?.fullFillmentDate
                ? moment(new Date(data?.fullFillmentDate)).format(
                    "DD MMMM YYYY"
                  )
                : "-"}
            </span>
          </div>
        </div>
      </div>

      <div>
        <span>Kriteria</span>
        <div>
          {data?.requirements?.map((el, index) => {
            return (
              <div key={index}>
                <div></div>
                <span>{el?.value || "-"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
