import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import { Suspense, useState } from 'react'
import PipelineListController from '@Organism/Manager/Pipeline/PipelineList/Controller'
import PipelineData from '@Organism/Manager/Pipeline/PipelineList/List'
import { getPipelines } from '@Services/manager/pipeline'
import { Await, defer, useLoaderData } from 'react-router-dom'
import LoadingAnimation from '@Atom/LoadingAnimation'
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary'

export async function loader() {
  const query = `limit=10&page=1&search=`;

  const packagePipelinesPromise = getPipelines(query)

  return defer({
    packagePipelines: packagePipelinesPromise
  });

}

export default function PipelineList() {
  const { packagePipelines } = useLoaderData()
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Pipeline</title>
      </Helmet>
      <div className={Styles.controllerWrapper}>
        <PipelineListController
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packagePipelines}
            errorElement={
              <div style={{ height: 'calc(100vh - 220px)' }}>
                <AsyncErrorBoundary />
              </div>
            }>
            {(packagePipelines) => (
              <PipelineData
                defaultData={packagePipelines?.response?.data}
                defaultTotalPage={packagePipelines?.response?.totalPage}
                searchTerm={searchTerm}
              />
            )}

          </Await>

        </Suspense>
      </div>
    </div>
  )
}
