/* eslint-disable react-hooks/exhaustive-deps */
import FormErrorMessage from "@Atom/FormErrorMessage";
import Icon from "@Atom/Icon";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Radio from "@Atom/Radio";
import { fileBaseUrl } from "@Config/api";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import useQuery from "@Hooks/useQuery";
import InputField from "@Molecule/InputField";
import {
  addClientContact,
  updateClientContact,
} from "@Services/manager/client";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Tooltip from "@Atom/Tooltip";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import { isValidEmail } from "@Helpers/isValidEmail";
import TableEmptyHandler from "@Atom/TableEmptyHandler";

const TABLE_HEAD = [
  { id: "name", label: "Nama", align: "left" },
  { id: "workUnit", label: "Unit Kerja", align: "left" },
  { id: "phoneNumber", label: "No Hp", align: "left" },
  { id: "action", label: "", align: "left" },
];

const Mobile = ({
  contacts,
  handleClose,
  handlePreview,
  clientName,
  selectedContact,
  setSelectedContact,
  setTrigger,
  form,
  setForm,
  error,
  setError,
  isSubmitting,
  handleSubmit,
  showAdd,
  setShowAdd = () => {},
  showUpdate,
  setShowUpdate = () => {},
  handleUpdate = () => {},
  typeSender,
  isLoadingDataCandidate,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedSendArr, setCheckedSendArr] = useState([]);

  const filteredContacts = useMemo(() => {
    return contacts?.filter((el) =>
      el?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  }, [contacts, searchTerm]);

  const disableButton = useMemo(() => {
    if (
      selectedContact &&
      selectedContact.id &&
      selectedContact.via &&
      selectedContact.via.length > 0
    )
      return false;
    else return true;
  }, [selectedContact]);

  const onCancel = () => {
    setSelectedContact(null);
    handleClose();
  };

  const isSendChecked = useCallback(
    (id) => {
      return checkedSendArr.includes(id);
    },
    [checkedSendArr]
  );

  const handleSendCheck = useCallback(
    (id) => {
      const index = checkedSendArr.indexOf(id);
      if (index === -1) {
        setCheckedSendArr([...checkedSendArr, id]);
        setSelectedContact({
          ...selectedContact,
          via: [...checkedSendArr, id],
        });
      } else {
        const temp = [...checkedSendArr];
        temp.splice(index, 1);
        setCheckedSendArr(temp);
        setSelectedContact({ ...selectedContact, via: [...temp] });
      }
    },
    [checkedSendArr, setCheckedSendArr]
  );

  return (
    <div
      className={`${Styles.mobileContainer} ${
        typeSender === "recommended" && Styles.mobileContainerRecommended
      }`}
    >
      <div className={Styles.header}>
        <h3>
          <span>Send to...</span>
          <Icon icon={"report"} size={22} />
          <span>{clientName}</span>
        </h3>
      </div>
      {showAdd ? (
        <NewContactForm
          setShow={setShowAdd}
          setTrigger={setTrigger}
          form={form}
          setForm={setForm}
          error={error}
          setError={setError}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
        />
      ) : showUpdate.length > 0 ? (
        <UpdateContactForm
          setShow={setShowUpdate}
          setTrigger={setTrigger}
          value={selectedContact}
          setValue={setSelectedContact}
          error={error}
          setError={setError}
          isSubmitting={isSubmitting}
          handleSubmit={handleUpdate}
          updateField={showUpdate}
        />
      ) : (
        <div className={Styles.normal}>
          <div className={Styles.title}>
            <h4>Kontak Klien</h4>
            <button onClick={() => setShowAdd(true)}>
              <span>Tambahkan Kontak Klien</span>
              <Icon icon={"plus"} size={14} />
            </button>
          </div>
          <div className={Styles.content}>
            <div className={Styles.searchBarWrapper}>
              <OutlinedSearchBar
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder={"Search"}
              />
            </div>
            <div
              className={`${Styles.tableWrapper} ${
                filteredContacts?.length === 0 || isLoadingDataCandidate
                  ? Styles.grow
                  : ""
              }`}
            >
              <TableContainer className={Styles.tableContainer}>
                <Table className={Styles.table}>
                  <colgroup>
                    <col style={{ width: "55%%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "25%" }} />
                  </colgroup>
                  <TableHead className={Styles.tableHead}>
                    <TableRow className={Styles.tableHeadRow}>
                      {TABLE_HEAD?.map((eachH, iH) => (
                        <TableCell
                          key={"headCell" + iH}
                          align={eachH?.align}
                          padding="none"
                          className={Styles.tableHeadCell}
                        >
                          <div>
                            <span>{eachH?.label}</span>
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={Styles.tableBody}>
                    {filteredContacts?.length > 0 &&
                      !isLoadingDataCandidate &&
                      filteredContacts?.map((eachC, iC) => {
                        return (
                          <TableRow
                            className={`${Styles.tableRow} ${
                              selectedContact?.id === eachC?.id
                                ? Styles.selected
                                : ""
                            }`}
                            key={"tableRow" + iC}
                            onClick={() => {
                              setSelectedContact(eachC);
                              setCheckedSendArr([]);
                            }}
                          >
                            <TableCell
                              padding="none"
                              className={Styles.tableCell}
                            >
                              <div className={Styles.stackedProfile}>
                                {eachC?.profilePicture ? (
                                  <Avatar
                                    alt={eachC?.name}
                                    src={
                                      eachC?.profilePicture?.includes(
                                        "myworkspace"
                                      )
                                        ? eachC?.profilePicture
                                        : fileBaseUrl + eachC?.profilePicture
                                    }
                                    sx={{ width: 36, height: 36 }}
                                  />
                                ) : (
                                  <Avatar sx={{ width: 36, height: 36 }}>
                                    {eachC?.name[0]}
                                  </Avatar>
                                )}
                                <span>{eachC?.name}</span>
                                {Math.abs(
                                  new Date() - new Date(eachC?.createdAt)
                                ) /
                                  36e5 <=
                                  12 && (
                                  <div className={Styles.newTag}>New</div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              padding="none"
                              className={Styles.tableCell}
                            >
                              <span>{eachC?.workUnit}</span>
                            </TableCell>
                            <TableCell
                              padding="none"
                              className={Styles.tableCell}
                            >
                              <span>{eachC?.phoneNumber}</span>
                            </TableCell>
                            <TableCell>
                              {eachC?.email ? (
                                <button className={Styles.buttonIcon} disabled>
                                  <Icon icon={"email-add"} size={24} />
                                </button>
                              ) : (
                                <Tooltip content={"Tambah Email"} noWhiteSpace>
                                  <button
                                    className={Styles.buttonIcon}
                                    onClick={() => {
                                      setSelectedContact(eachC);
                                      setShowUpdate(["email"]);
                                    }}
                                  >
                                    <Icon icon={"email-add"} size={24} />
                                  </button>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                <div className={Styles.bottom} />

                {isLoadingDataCandidate || filteredContacts?.length === 0 ? (
                  <TableEmptyHandler
                    isV2={true}
                    title={
                      isLoadingDataCandidate ? "Tunggu sebentar" : "Data Kosong"
                    }
                    desc={
                      isLoadingDataCandidate
                        ? "Data sedang dimuat"
                        : "Tidak ada data atau data belum ada"
                    }
                  />
                ) : null}
              </TableContainer>
            </div>

            <div className={Styles.titleWrapper}>
              <h4>Kirim Rekomendasi Melalui</h4>
            </div>

            <div className={Styles.checkBoxWrapper}>
              <div className={Styles.containerSend}>
                <CheckboxJobPosting
                  checked={isSendChecked("phoneNumber") || false}
                  onChange={() => handleSendCheck("phoneNumber")}
                />
                <span>Kirim Via WA</span>
                <Icon icon={"whatsapp"} color={"#43AB12"} size={20} />
              </div>

              {!selectedContact || selectedContact?.email ? (
                <div className={Styles.containerSend}>
                  <CheckboxJobPosting
                    checked={isSendChecked("email") || false}
                    onChange={() => handleSendCheck("email")}
                  />
                  <span>Kirim Via Email</span>
                  <Icon icon={"email"} color={"#1571DE"} size={20} />
                </div>
              ) : (
                <div className={Styles.containerSendDisable}>
                  <CheckboxJobPosting disabled={true} checked={false} />
                  <span>Kirim Via Email</span>
                  <Icon icon={"email"} color={"#9E9E9E"} size={20} />
                </div>
              )}
              <div></div>
              {selectedContact && !selectedContact?.email && (
                <div className={Styles.containerSendEmailError}>
                  <Icon
                    icon={"information-solid"}
                    color={"#1571DE"}
                    size={20}
                  />
                  <span>Email kontak tidak terdaftar</span>
                </div>
              )}
            </div>

            <div className={Styles.buttonsWrapper}>
              <button onClick={onCancel}>Batalkan</button>
              <button disabled={disableButton} onClick={handlePreview}>
                Kirim
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Desktop = ({
  contacts,
  handleClose,
  handlePreview,
  clientName,
  setSelectedContact,
  selectedContact,
  setTrigger,
  form,
  setForm,
  error,
  setError,
  isSubmitting,
  handleSubmit,
  showAdd,
  setShowAdd = () => {},
  showUpdate,
  setShowUpdate = () => {},
  handleUpdate = () => {},
  typeSender,
  isLoadingDataCandidate,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedSendArr, setCheckedSendArr] = useState([]);

  const filteredContacts = useMemo(() => {
    return contacts?.filter((el) =>
      el?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  }, [contacts, searchTerm]);

  const disableButton = useMemo(() => {
    if (
      selectedContact &&
      selectedContact.id &&
      selectedContact.via &&
      selectedContact.via.length > 0
    )
      return false;
    else return true;
  }, [selectedContact]);

  const onCancel = () => {
    setSelectedContact(null);
    handleClose();
  };

  const clickRadio = (option) => {
    if (selectedContact === option) {
      setSelectedContact(null);
      setCheckedSendArr([]);
    } else {
      setSelectedContact(option);
      setCheckedSendArr([]);
    }
  };

  const isSendChecked = useCallback(
    (id) => {
      return checkedSendArr.includes(id);
    },
    [checkedSendArr]
  );

  const handleSendCheck = useCallback(
    (id) => {
      const index = checkedSendArr.indexOf(id);
      if (index === -1) {
        setCheckedSendArr([...checkedSendArr, id]);
        setSelectedContact({
          ...selectedContact,
          via: [...checkedSendArr, id],
        });
      } else {
        const temp = [...checkedSendArr];
        temp.splice(index, 1);
        setCheckedSendArr(temp);
        setSelectedContact({ ...selectedContact, via: [...temp] });
      }
    },
    [checkedSendArr, setCheckedSendArr]
  );

  return (
    <div
      className={`${Styles.background} ${
        typeSender === "recommended" && Styles.backgroundRecommended
      }`}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>
            <span>Send to...</span>
            <Icon icon={"report"} size={22} />
            <span>{clientName}</span>
          </h3>
        </div>
        {showAdd ? (
          <NewContactForm
            setShow={setShowAdd}
            setTrigger={setTrigger}
            form={form}
            setForm={setForm}
            error={error}
            setError={setError}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
          />
        ) : showUpdate.length > 0 ? (
          <UpdateContactForm
            setShow={setShowUpdate}
            setTrigger={setTrigger}
            value={selectedContact}
            setValue={setSelectedContact}
            error={error}
            setError={setError}
            isSubmitting={isSubmitting}
            handleSubmit={handleUpdate}
            updateField={showUpdate}
          />
        ) : (
          <div className={Styles.normal}>
            <div className={Styles.title}>
              <h4>Kontak Klien</h4>
              <button onClick={() => setShowAdd(true)}>
                <span>Tambahkan Kontak Klien</span>
                <Icon icon={"plus"} size={14} />
              </button>
            </div>
            <div className={Styles.content}>
              <div className={Styles.searchBarWrapper}>
                <OutlinedSearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholder={"Search"}
                />
              </div>
              <div
                className={`${Styles.tableWrapper} ${
                  filteredContacts?.length === 0 || isLoadingDataCandidate
                    ? Styles.grow
                    : ""
                }`}
              >
                <TableContainer className={Styles.tableContainer}>
                  <Table className={Styles.table}>
                    <colgroup>
                      <col style={{ width: "55%%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "25%" }} />
                    </colgroup>
                    <TableHead className={Styles.tableHead}>
                      <TableRow className={Styles.tableHeadRow}>
                        {TABLE_HEAD?.map((eachH, iH) => (
                          <TableCell
                            key={"headCell" + iH}
                            align={eachH?.align}
                            padding="none"
                            className={Styles.tableHeadCell}
                          >
                            <div>
                              <span>{eachH?.label}</span>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className={Styles.tableBody}>
                      {filteredContacts?.length > 0 &&
                        !isLoadingDataCandidate &&
                        filteredContacts?.map((eachC, iC) => {
                          return (
                            <TableRow
                              className={`${Styles.tableRow}`}
                              key={"tableRow" + iC}
                              // onClick={() => setSelectedContact(eachC)}
                            >
                              <TableCell
                                padding="none"
                                className={Styles.tableCell}
                              >
                                <div className={Styles.stackedProfile}>
                                  <Radio
                                    setIsChecked={() => clickRadio(eachC)}
                                    isChecked={
                                      selectedContact?.id === eachC?.id
                                    }
                                  />
                                  {eachC?.profilePicture ? (
                                    <Avatar
                                      alt={eachC?.name}
                                      src={
                                        eachC?.profilePicture?.includes("http")
                                          ? eachC?.profilePicture?.replace(
                                              "https://myworkspace",
                                              "https://api1.myworkspace"
                                            )
                                          : fileBaseUrl + eachC?.profilePicture
                                      }
                                      sx={{ width: 36, height: 36 }}
                                    />
                                  ) : (
                                    <Avatar sx={{ width: 36, height: 36 }}>
                                      {eachC?.name[0]}
                                    </Avatar>
                                  )}
                                  <span>{eachC?.name}</span>
                                  {Math.abs(
                                    new Date() - new Date(eachC?.createdAt)
                                  ) /
                                    36e5 <=
                                    12 && (
                                    <div className={Styles.newTag}>New</div>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                padding="none"
                                className={Styles.tableCell}
                              >
                                <span>{eachC?.workUnit}</span>
                              </TableCell>
                              <TableCell
                                padding="none"
                                className={Styles.tableCell}
                              >
                                <span>{eachC?.phoneNumber}</span>
                              </TableCell>
                              <TableCell>
                                {eachC?.email ? (
                                  <button
                                    className={Styles.buttonIcon}
                                    disabled
                                  >
                                    <Icon icon={"email-add"} size={24} />
                                  </button>
                                ) : (
                                  <Tooltip
                                    content={"Tambah Email"}
                                    noWhiteSpace
                                  >
                                    <button
                                      className={Styles.buttonIcon}
                                      onClick={() => {
                                        setSelectedContact(eachC);
                                        setShowUpdate(["email"]);
                                      }}
                                    >
                                      <Icon icon={"email-add"} size={24} />
                                    </button>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  <div className={Styles.bottom} />
                  {isLoadingDataCandidate || filteredContacts?.length === 0 ? (
                    <TableEmptyHandler
                      isV2={true}
                      title={
                        isLoadingDataCandidate
                          ? "Tunggu sebentar"
                          : "Data Kosong"
                      }
                      desc={
                        isLoadingDataCandidate
                          ? "Data sedang dimuat"
                          : "Tidak ada data atau data belum ada"
                      }
                    />
                  ) : null}
                </TableContainer>
              </div>

              <div className={Styles.titleWrapper}>
                <h4>Kirim Rekomendasi Melalui</h4>
              </div>

              <div className={Styles.checkBoxWrapper}>
                <div className={Styles.containerSend}>
                  <CheckboxJobPosting
                    checked={isSendChecked("phoneNumber") || false}
                    onChange={() => handleSendCheck("phoneNumber")}
                  />
                  <span>Kirim Via WA</span>
                  <Icon icon={"whatsapp"} color={"#43AB12"} size={20} />
                </div>

                {!selectedContact || selectedContact?.email ? (
                  <div className={Styles.containerSend}>
                    <CheckboxJobPosting
                      checked={isSendChecked("email") || false}
                      onChange={() => handleSendCheck("email")}
                    />
                    <span>Kirim Via Email</span>
                    <Icon icon={"email"} color={"#1571DE"} size={20} />
                  </div>
                ) : (
                  <div className={Styles.containerSendDisable}>
                    <CheckboxJobPosting disabled={true} checked={false} />
                    <span>Kirim Via Email</span>
                    <Icon icon={"email"} color={"#9E9E9E"} size={20} />
                  </div>
                )}
                <div></div>
                {selectedContact && !selectedContact?.email && (
                  <div className={Styles.containerSendEmailError}>
                    <Icon
                      icon={"information-solid"}
                      color={"#1571DE"}
                      size={20}
                    />
                    <span>Email kontak tidak terdaftar</span>
                  </div>
                )}
              </div>
              <div className={Styles.buttonsWrapper}>
                <button onClick={onCancel}>Batalkan</button>
                <button disabled={disableButton} onClick={handlePreview}>
                  Kirim
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function SendModal({
  type,
  contacts,
  setContacts,
  setShow,
  setShowPreview,
  selectedContact,
  setSelectedContact,
  refetchSync = () => {},
  setTrigger = () => {},
  typeSender,
  selectedClient,
  isLoadingDataCandidate,
}) {
  const query = useQuery();
  const name =
    typeSender === "recommended" ? selectedClient?.name : query.get("n");

  const handlePreview = (e) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const clientCode =
    typeSender === "recommended" ? selectedClient?.code : query.get("c");
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState([]);

  const [form, setForm] = useState({
    name: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    workUnit: "",
    clientCode: clientCode,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { response } = await addClientContact({
        ...form,
        phoneNumber: "62" + form?.phoneNumber,
      });
      setContacts((prev) => {
        return [...prev, response];
      });
      // await refetchSync()
      setIsSubmitting(false);
      setShowAdd(false);
    } catch (err) {
      setIsSubmitting(false);
      const errorText = err?.response?.data?.error;
      setError(errorText);
    }
  };

  const handleUpdate = async (field = []) => {
    try {
      setIsSubmitting(true);

      let payload = {};
      for (const el of field) {
        if (el === "email" && selectedContact?.email) {
          // eslint-disable-next-line no-throw-literal
          if (!isValidEmail(selectedContact[el]))
            throw { response: { data: { error: "Email tidak valid!" } } };
        }
        payload = {
          ...payload,
          [el]: selectedContact ? selectedContact[el] : null,
        };
      }
      const { response } = await updateClientContact(
        selectedContact?.id,
        payload
      );

      setContacts((prev) => {
        let result = prev.map((item) => {
          if (item.id === selectedContact?.id) {
            return response;
          }
          return item;
        });
        return result;
      });

      setIsSubmitting(false);
      setShowUpdate([]);
      setSelectedContact(null);
    } catch (err) {
      setIsSubmitting(false);
      const errorText = err?.response?.data?.error;
      setError(errorText);
    }
  };

  switch (type) {
    case "mobile":
      return (
        <Mobile
          clientName={name}
          contacts={contacts}
          handleClose={handleClose}
          handlePreview={handlePreview}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          setTrigger={setTrigger}
          form={form}
          setForm={setForm}
          error={error}
          setError={setError}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          showUpdate={showUpdate}
          setShowUpdate={setShowUpdate}
          handleUpdate={handleUpdate}
          typeSender={typeSender}
          isLoadingDataCandidate={isLoadingDataCandidate}
        />
      );
    case "desktop":
      return (
        <Desktop
          clientName={name}
          contacts={contacts}
          handleClose={handleClose}
          handlePreview={handlePreview}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          setTrigger={setTrigger}
          form={form}
          setForm={setForm}
          error={error}
          setError={setError}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          showUpdate={showUpdate}
          setShowUpdate={setShowUpdate}
          handleUpdate={handleUpdate}
          typeSender={typeSender}
          isLoadingDataCandidate={isLoadingDataCandidate}
        />
      );
    default:
      return (
        <Desktop
          clientName={name}
          contacts={contacts}
          handleClose={handleClose}
          handlePreview={handlePreview}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          setTrigger={setTrigger}
          form={form}
          setForm={setForm}
          error={error}
          setError={setError}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          showAdd={showAdd}
          setShowAdd={setShowAdd}
          showUpdate={showUpdate}
          setShowUpdate={setShowUpdate}
          handleUpdate={handleUpdate}
          isLoadingDataCandidate={isLoadingDataCandidate}
        />
      );
  }
}

const NewContactForm = ({
  setShow,
  setTrigger,
  form,
  setForm,
  error,
  setError,
  isSubmitting,
  handleSubmit,
}) => {
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const template = useMemo(() => {
    return [
      {
        name: "name",
        title: "Nama",
        placeholder: "Tulis nama",
      },
      {
        name: "jobTitle",
        title: "Posisi",
        placeholder: "Tulis nama posisi",
      },
      {
        name: "email",
        title: "Email",
        placeholder: "Tulis email",
      },
      {
        name: "phoneNumber",
        title: "No Hp",
        placeholder: "8xxx",
        type: "phone",
      },
      {
        name: "workUnit",
        title: "Unit Kerja",
        placeholder: "Tulis unit kerja",
      },
    ];
  }, []);

  return (
    <div className={Styles.newContactContainer}>
      <div className={Styles.newHeader}>
        <button onClick={() => setShow(false)}>
          <Icon icon={"arrow-left"} size={24} />
        </button>
        <span>Tambah Kontak Klien</span>
      </div>
      <div className={Styles.newContent}>
        {template?.map((each, i) => (
          <InputField
            key={i}
            type={each?.type || ""}
            title={each?.title}
            placeholder={each?.placeholder}
            value={form?.[each?.name]}
            onChange={(e) =>
              setForm((prev) => {
                return {
                  ...prev,
                  [each?.name]: e?.target?.value,
                };
              })
            }
          />
        ))}
      </div>
      <FormErrorMessage message={error} />
      <div className={Styles.buttonsWrapper}>
        <button onClick={() => setShow(false)}>Batalkan</button>
        <button
          disabled={isSubmitting || !allFilledObjChecker(form)}
          onClick={handleSubmit}
        >
          {isSubmitting ? "Menambahkan..." : "Tambahkan"}
        </button>
      </div>
    </div>
  );
};

const UpdateContactForm = ({
  setShow,
  setTrigger,
  value,
  setValue,
  error,
  setError,
  isSubmitting,
  handleSubmit = (field) => {},
  updateField = [],
}) => {
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const template = useMemo(() => {
    return [
      {
        name: "name",
        title: "Nama",
        placeholder: "Tulis nama",
      },
      {
        name: "jobTitle",
        title: "Posisi",
        placeholder: "Tulis nama posisi",
      },
      {
        name: "email",
        title: "Email",
        placeholder: "Tulis email",
      },
      {
        name: "phoneNumber",
        title: "No Hp",
        placeholder: "8xxx",
        type: "phone",
      },
      {
        name: "workUnit",
        title: "Unit Kerja",
        placeholder: "Tulis unit kerja",
      },
    ];
  }, []);

  const formTitle = useMemo(() => {
    let title = "Ubah Kontak Klien";
    if (updateField?.length === 1) {
      let temp = template.find(
        (f) => f.name.toLowerCase() === updateField[0]?.toLowerCase()
      );
      if (temp) {
        title = `Tambah ${temp.title} Kontak Klien`;
      }
    }
    return title;
  }, []);

  const handleCancel = () => {
    setValue(null);
    setShow([]);
  };

  return (
    <div className={Styles.newContactContainer}>
      <div className={Styles.newHeader}>
        <button onClick={() => setShow([])}>
          <Icon icon={"arrow-left"} size={24} />
        </button>
        <span>{formTitle}</span>
      </div>
      <div className={Styles.newContent}>
        {template?.map((each, i) => (
          <InputField
            key={i}
            type={each?.type || ""}
            title={each?.title}
            placeholder={each?.placeholder}
            value={value?.[each?.name]}
            disabled={!updateField.includes(each?.name)}
            onChange={(e) =>
              setValue((prev) => {
                return {
                  ...prev,
                  [each?.name]: e?.target?.value,
                };
              })
            }
          />
        ))}
      </div>
      <FormErrorMessage message={error} />
      <div className={Styles.buttonsWrapper}>
        <button onClick={() => handleCancel()}>Batalkan</button>
        <button
          disabled={
            isSubmitting ||
            !allFilledObjChecker(value, ["password", "profilePicture"])
          }
          onClick={() => handleSubmit(updateField)}
        >
          {isSubmitting ? "Menambahkan..." : "Tambahkan"}
        </button>
      </div>
    </div>
  );
};
