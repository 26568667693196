import { useEffect, useRef } from 'react'
import AccountSection from './AccountSection'
import FileUploadSection from './FileUploadSection'
import FlowSection from './FlowSection'
import OthersSection from './OthersSection'
import PeriodSection from './PeriodSection'
import Styles from './style.module.scss'


export default function ThirdStep(props) {
  const { form, setForm, files, setFiles, onPrevious, onNext, isDone, setTrigger } = props

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <PeriodSection setTrigger={setTrigger} form={form} setForm={setForm} />
      <FlowSection setTrigger={setTrigger} form={form} setForm={setForm} />
      <AccountSection setTrigger={setTrigger} form={form} setForm={setForm} />
      <OthersSection setTrigger={setTrigger} form={form} setForm={setForm} />
      <FileUploadSection form={form} setForm={setForm} files={files} setFiles={setFiles} />
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button disabled={!isDone} onClick={onNext}>Next Step</button>
      </div>

    </div>
  )
}