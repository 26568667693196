import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";
// import IMAGES_TUTORIAL from "@Assets/Images/Tutorial/tutorial-1.png";

import useWindowSize from "@Hooks/useWindowSize";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function TutorialSelfieModal({
  closeModal,
  dropdownImage,
  handleChangePhoto,
  handleDone = () => {},
}) {
  const [bg, setBg] = useState("bg-1");
  const interval = useRef();
  const { width } = useWindowSize();
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (bg === "bg-1") setBg("bg-2");
      else if (bg === "bg-2") setBg("bg-3");
      else if (bg === "bg-3") setBg("bg-4");
      else if (bg === "bg-4") setBg("bg-5");
      else if (bg === "bg-5") setBg("bg-6");
      else setBg("bg-1");
    }, 1500);
  }, [bg]);

  const BackgroundMemo = useMemo(() => {
    switch (bg) {
      case "bg-1":
        return Styles.Background_1;
      case "bg-2":
        return Styles.Background_2;
      case "bg-3":
        return Styles.Background_3;
      case "bg-4":
        return Styles.Background_4;
      case "bg-5":
        return Styles.Background_5;
      case "bg-6":
        return Styles.Background_5;
      default:
        return Styles.Background_1;
    }
  }, [bg]);

  return (
    <div className={Styles.containerModal}>
      <div className={Styles.modalTutorial} ref={boxRef}>
        <span className={Styles.textModal}>
          Ikuti Ketentuan Berikut Untuk Pengambilan Foto Anda
        </span>
        <div className={Styles.carouselImages}>
          <div className={`${Styles.carousel} ${BackgroundMemo}`}></div>
          {/* <img src={IMAGES_TUTORIAL} alt="" /> */}
        </div>

        <div className={Styles.descBox}>
          <div className={Styles.textDesc}>
            <span className={Styles.bullet}>.</span>
            <span className={Styles.p}>Kenakan pakaian rapih dan sopan</span>
          </div>

          <div className={Styles.textDesc}>
            <span className={Styles.bullet}>.</span>
            <span className={Styles.p}>
              Pastikan wajah terlihat jelas dan tidak buram
            </span>
          </div>

          <div className={Styles.textDesc}>
            <span className={Styles.bullet}>.</span>
            <span className={Styles.p}>Berpose dengan sopan</span>
          </div>

          <div className={Styles.textDesc}>
            <span className={Styles.bullet}>.</span>
            <span className={Styles.p}>
              Gunakan latar polos seperti tembok bila memungkinkan
            </span>
          </div>
        </div>

        <div className={Styles.buttonBox}>
          <div className={Styles.buttonBack} onClick={closeModal}>
            Kembali
          </div>
          {width > 768 ? (
            <div
              className={Styles.buttonNext}
              {...dropdownImage.getRootProps()}
            >
              Lanjutkan Ambil Foto
              <input
                {...dropdownImage.getInputProps()}
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={handleChangePhoto}
                hidden
              />
            </div>
          ) : (
            <label
              className={Styles.buttonNext}
              htmlFor="camera"
              // {...dropdownImage.getRootProps()}
            >
              Lanjutkan Ambil Foto
              <input
                type="file"
                // ref={inputFileRef}
                capture="user"
                name="file"
                onChange={handleChangePhoto}
                hidden
                multiple={false}
                id="camera"
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
