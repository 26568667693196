import LoadingAnimation from "@Atom/LoadingAnimation";
import ClientWorkSchemesLayout from "@Organism/Manager/Client/WorkSchemes/SchemeList/Layout";
import SchemeListNavigation from "@Organism/Manager/Client/WorkSchemes/SchemeList/Navigation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getClientOrders } from "@Services/manager/client";
import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const code = params?.get("c");

  const packageOrdersPromise = getClientOrders(code, 1, 10)

  return defer({
    packageOrders: packageOrdersPromise
  })
}

export default function ClientWorkSchemes() {
  const { packageOrders } = useLoaderData();

  const [searchTerm, setSearchTerm] = useState('')
  const [sort, setSort] = useState('')




  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Work Schemes</title>
      </Helmet>
      <div className={Styles.topWrapper}>
        <SchemeListNavigation
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sort={sort}
          setSort={setSort}
        />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packageOrders}
            errorElement={<div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>}
          >
            {(packageOrders) => (
              <ClientWorkSchemesLayout
                defaultOrderData={packageOrders?.response?.data}
                defaultTotalPage={packageOrders?.response?.totalPage}
                searchTerm={searchTerm}
                sort={sort}
              />
            )}

          </Await>

        </Suspense>

      </div>
    </div>
  );
}
