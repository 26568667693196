/* eslint-disable react-hooks/exhaustive-deps */
import AutocompleteField from "@Molecule/AutocompleteField";
import CheckboxField from "@Molecule/CheckboxField";
import InputDateField from "@Molecule/InputDateField";
import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { validateEmail } from "@Helpers/validateEmail";

export default function PersonalDataSection({
  form,
  setForm,
  trigger,
  setTrigger,
  lifetimeIdCard,
  setLifetimeIdCard,
  noTaxNumber,
  setNoTaxNumber,
  options,

  disableInput,
  disablePhoneVerification,
  loadingPersonId,
  isPhoneVerified,
  setIsPhoneVerified,
  loadingPhoneVerify,
  onClickPhoneVerification,
  errorField
}) {
  const handleChange = useCallback(
    (e, inputName) => {
      let targetValue;
      const re = /^[0-9\b]+$/;
      if (
        inputName === "phoneNumber" ||
        inputName === "height" ||
        inputName === "weight" ||
        inputName === "kk" ||
        inputName === "nik" ||
        inputName === "taxNumber" ||
        inputName === "phoneNumber"
      ) {
        if (inputName === "phoneNumber") {
          setIsPhoneVerified(false)
          if (
            e.target.value === "" ||
            (re.test(e.target.value) && e.target.value.startsWith("8"))
          ) {
            targetValue = !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase();
          }
        } else {
          if (e.target.value === "" || re.test(e.target.value)) {
            targetValue = !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase();
          }
        }
        if (inputName === "nik" || inputName === "phoneNumber") setIsPhoneVerified(false)
      } else if (inputName === "email") {
        targetValue = e.target.value.toLowerCase().trim();
      } else {
        targetValue = !isNaN(e.target.value)
          ? e.target.value
          : e.target.value.toUpperCase();
      }

      if (targetValue || targetValue === "") {
        setForm({
          ...form,
          [inputName]: targetValue,
        });
      }
    },
    [form, setForm, setIsPhoneVerified]
  );

  useEffect(() => {
    if (noTaxNumber === true) {
      setForm({
        ...form,
        taxNumber: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxNumber]);

  const [birthDateTemp, setBirthDateTemp] = useState(form?.birthDate);
  const [genderTemp, setGenderTemp] = useState(form?.gender);
  const [religionTemp, setReligionTemp] = useState(form?.religion);
  const [bloodTypeTemp, setBloodTypeTemp] = useState(form?.bloodType);
  const [maritalStatusTemp, setMaritalStatusTemp] = useState(
    form?.maritalStatus
  );
  const [idCardPeriodTemp, setIdCardPeriodTemp] = useState(form?.idCardPeriod);

  useEffect(() => {
    setBirthDateTemp(form?.birthDate || "")
    setGenderTemp(form?.gender || "")
    setReligionTemp(form?.religion || "")
    setBloodTypeTemp(form?.bloodType || "")
    setMaritalStatusTemp(form?.maritalStatus || "")
    setIdCardPeriodTemp(form?.idCardPeriod || "")
  }, [
    form?.birthDate, 
    form?.gender, 
    form?.religion, 
    form?.bloodType, 
    form?.maritalStatus, 
    form?.idCardPeriod
  ])

  useEffect(() => {
    if (lifetimeIdCard === true) {
      setForm((f) => ({
        ...form,
        idCardPeriod: "",
      }));
      setIdCardPeriodTemp("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifetimeIdCard]);

  useEffect(() => {
    // setForm({
    //   ...form,
    //   "birthDate": birthDateTemp,
    //   "gender": genderTemp,
    //   "religion": religionTemp,
    //   "bloodType": bloodTypeTemp,
    //   "maritalStatus": maritalStatusTemp,
    //   "idCardPeriod": idCardPeriodTemp
    // })

    setForm((f) => ({
      ...f,
      birthDate: birthDateTemp,
      gender: genderTemp,
      religion: religionTemp,
      bloodType: bloodTypeTemp,
      maritalStatus: maritalStatusTemp,
      idCardPeriod: idCardPeriodTemp,
    }));

    //eslint-disable-next-line
  }, [
    birthDateTemp,
    genderTemp,
    religionTemp,
    bloodTypeTemp,
    maritalStatusTemp,
    idCardPeriodTemp,
    setForm,
  ]);

  return (
      <div className={Styles.container}>
          <h3>Personal Data</h3>
          <div className={Styles.content}>
              <div className={Styles.inputEndAdornment}>
                  <InputField
                      title={'Nomor KTP (16 Digit)'}
                      placeholder={'Nomor KTP'}
                      value={form?.nik}
                      onChange={(e) => {
                          if (e.target.value.length < 17) {
                              handleChange(e, 'nik');
                          }
                      }}
                      required
                      isError={errorField && !form?.nik}
                      errorText={'*Nomor KTP belum diisi'}
                  />
                  {loadingPersonId && (
                      <button type="button" className={Styles.endAdornment}>
                          <Box sx={{ position: 'relative' }}>
                              <CircularProgress
                                  variant="determinate"
                                  sx={{
                                      color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                  }}
                                  size={20}
                                  thickness={4}
                                  value={100}
                              />
                              <CircularProgress
                                  variant="indeterminate"
                                  disableShrink
                                  sx={{
                                      color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                      animationDuration: '550ms',
                                      position: 'absolute',
                                      left: 0,
                                      [`& .${circularProgressClasses.circle}`]: {
                                          strokeLinecap: 'round',
                                      },
                                  }}
                                  size={20}
                                  thickness={4}
                              />
                          </Box>
                      </button>
                  )}
              </div>
              <InputField
                  type="phoneVerification"
                  value={form?.phoneNumber}
                  title="Nomor Whatsapp"
                  placeholder={'8***'}
                  onChange={(e) => handleChange(e, 'phoneNumber')}
                  required
                  onClick={onClickPhoneVerification}
                  isLoading={loadingPhoneVerify}
                  isVerified={isPhoneVerified}
                  disabled={disablePhoneVerification}
                  isError={errorField && (!form?.phoneNumber || !isPhoneVerified)}
                  errorText={'*Nomor HP belum diisi atau di verifikasi'}
              />
              <InputField
                  title={'Nama'}
                  placeholder={'Nama sesuai KTP'}
                  value={form?.name}
                  onChange={(e) => handleChange(e, 'name')}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.name}
                  errorText={'*Nama belum diisi'}
              />
              <InputField
                  title={'Tempat Lahir'}
                  placeholder={'Tempat lahir'}
                  value={form?.birthPlace}
                  onChange={(e) => handleChange(e, 'birthPlace')}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.birthPlace}
                  errorText={'*Tempat lahir belum diisi'}
              />
              <InputDateField
                  title={'Tanggal Lahir'}
                  placeholder={'Pilih tanggal'}
                  iconColor={'#1571DE'}
                  // value={form?.birthDate}
                  value={birthDateTemp}
                  setValue={setBirthDateTemp}
                  required
                  // onChange={(e) => handleChange(e, 'birthDate')}
                  disabled={disableInput}
                  isError={errorField && !birthDateTemp}
                  errorText={'*Tanggal lahir belum diisi'}
              />
              <AutocompleteField
                  title={'Jenis Kelamin'}
                  placeholder={'Pilih salah satu'}
                  searchOptions={options?.genders}
                  value={form?.gender}
                  setValue={setGenderTemp}
                  iconColor={'#1571DE'}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.gender}
                  errorText={'*Jenis kelamin belum diisi'}
              />
              <AutocompleteField
                  title={'Agama'}
                  placeholder={'Pilih agama'}
                  searchOptions={options?.religions}
                  value={form?.religion}
                  setValue={setReligionTemp}
                  iconColor={'#1571DE'}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.religion}
                  errorText={'*Agama belum diisi'}
              />
              <AutocompleteField
                  title={'Golongan Darah'}
                  placeholder={'Pilih salah satu'}
                  searchOptions={options?.bloodTypes}
                  value={form?.bloodType}
                  setValue={setBloodTypeTemp}
                  iconColor={'#1571DE'}
                  required
                  // onChange={(e) => handleChange(e, 'bloodType')}
                  disabled={disableInput}
                  isError={errorField && !form?.bloodType}
                  errorText={'*Golongan darah belum diisi'}
              />
              <InputField
                  title={'Tinggi Badan'}
                  placeholder={'Tinggi badan anda'}
                  value={form?.height}
                  onChange={(e) => handleChange(e, 'height')}
                  floatingEndAdornment={<span className={Styles.floatingText}>Cm</span>}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.height}
                  errorText={'*Tinggi badan belum diisi'}
              />
              <InputField
                  title={'Berat Badan'}
                  placeholder={'Berat badan anda'}
                  value={form?.weight}
                  onChange={(e) => handleChange(e, 'weight')}
                  floatingEndAdornment={<span className={Styles.floatingText}>Kg</span>}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.weight}
                  errorText={'*Berat badan belum diisi'}
              />
              <AutocompleteField
                  title={'Status Kawin'}
                  placeholder={'Pilih salah satu'}
                  searchOptions={options?.maritalStatus}
                  value={form?.maritalStatus}
                  setValue={setMaritalStatusTemp}
                  iconColor={'#1571DE'}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.maritalStatus}
                  errorText={'*Status Kawin belum diisi'}
              />
              <InputField
                  title={'Nomor Kartu Keluarga (16 Digit)'}
                  placeholder={'Nomor KK'}
                  value={form?.kk}
                  onChange={(e) => {
                      if (e.target.value.length < 17) {
                          handleChange(e, 'kk');
                      }
                  }}
                  required
                  disabled={disableInput}
                  isError={errorField && (!form?.kk || form?.kk?.length < 16)}
                  errorText={'*Nomor KK belum diisi atau belum 16 digit'}
              />

              <div className={Styles.stackedField}>
                  <InputDateField
                      title={'Masa Berlaku KTP'}
                      placeholder={'Pilih tanggal'}
                      value={idCardPeriodTemp}
                      setValue={setIdCardPeriodTemp}
                      iconColor={'#1571DE'}
                      disabled={lifetimeIdCard || disableInput}
                      required
                      isError={errorField && !idCardPeriodTemp && !lifetimeIdCard}
                      errorText={'*Masa berlaku KTP belum diisi'}
                  />
                  <CheckboxField
                      title="Seumur hidup"
                      value={lifetimeIdCard}
                      setValue={setLifetimeIdCard}
                      required
                      disabled={disableInput}
                  />
              </div>

              <div className={Styles.stackedField}>
                  <InputField
                      title={'Nomor NPWP'}
                      placeholder={'Nomor NPWP'}
                      value={form?.taxNumber}
                      disabled={noTaxNumber || disableInput}
                      onChange={(e) => handleChange(e, 'taxNumber')}
                      required
                      isError={errorField && !form?.taxNumber && !noTaxNumber}
                      errorText={'*Nomor NPWP belum diisi'}
                  />
                  <CheckboxField
                      title="Tidak memiliki NPWP"
                      value={noTaxNumber}
                      setValue={setNoTaxNumber}
                      required
                      disabled={disableInput}
                  />
              </div>

              <InputField
                  title={'Email'}
                  placeholder={'example@mail.com'}
                  value={form?.email}
                  onChange={(e) => handleChange(e, 'email')}
                  required
                  disabled={disableInput}
                  isError={errorField && (!form?.email || !validateEmail(form?.email))}
                  errorText={'*Email belum diisi atau tidak sesuai format'}
              />

              {/* <InputField
          title={"Nomor HP"}
          placeholder={"8***"}
          value={form?.phoneNumber}
          onChange={(e) => handleChange(e, "phoneNumber")}
          floatingStartAdornment={
            <span className={Styles.floatingText}>+62</span>
          }
          required
        /> */}
              <InputField
                  title={'Nama Ibu Kandung'}
                  placeholder={'Nama ibu kandung anda'}
                  value={form?.biologicalMotherName}
                  onChange={(e) => handleChange(e, 'biologicalMotherName')}
                  required
                  disabled={disableInput}
                  isError={errorField && !form?.biologicalMotherName}
                  errorText={'*Nama ibu belum diisi'}
              />
          </div>
      </div>
  );
}
