import Icon from '@Atom/Icon';
import Styles from './styles.module.scss';

export default function PhotoForm({ value, dropdownImage, handleChangePhoto, errorFileReject }) {
    return (
        <div className={Styles.formWrapper}>
            <span className={Styles.titleForm}>Unggah Foto Kandidat</span>
            <div className={Styles.imageInput}>
                {value?.candidatePhoto ? (
                    <div className={`${Styles.Section}`}>
                        <div className={Styles.ImageidContainer} {...dropdownImage.getRootProps()}>
                            <img src={URL.createObjectURL(value.candidatePhoto)} alt="imageid" />
                            <div className={Styles.Footer}>
                                <Icon icon={'camera'} size="24px" />
                                <p>Upload Ulang</p>
                                <input
                                    {...dropdownImage.getInputProps()}
                                    type="file"
                                    // ref={inputFileRef}
                                    name="file"
                                    onChange={handleChangePhoto}
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`${Styles.Section} `}>
                        <div className={`${Styles.DropdownSection}`} {...dropdownImage.getRootProps()}>
                            <Icon icon="camera" size={24} color="#C2C2C2" />
                            <p>Foto Kandidat</p>
                            <div className={Styles.buttonSubmit}>
                                Upload Foto
                                <input
                                    {...dropdownImage.getInputProps()}
                                    type="file"
                                    capture="user"
                                    name="file"
                                    onChange={handleChangePhoto}
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                )}
                {errorFileReject?.key === 'candidatePhoto' ? (
                    <span className={Styles.error}>*{errorFileReject?.message}</span>
                ) : null}
            </div>
        </div>
    );
}
