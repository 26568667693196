import LoadingAnimation from "@Atom/LoadingAnimation";
import SchemeEditFormNavigation from "@Organism/Manager/Client/WorkSchemes/SchemeEditForm/Navigation";
import SchemeProceedFormLayout from "@Organism/Manager/Client/WorkSchemes/SchemeProceed";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { orderSync } from "@Services/manager/client";
import { getPipelineOrderDetails } from "@Services/manager/pipeline";
import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const id = params?.get("i");
  // const code = params.get("c") || '';

  const packageOrderSyncPromise = orderSync();
  const packagePipelineOrderDetailPromise = getPipelineOrderDetails(id);
  // const packageClientContactPromise = getAllContactsByClientCode(code);

  return defer({
    packageOrderSync: packageOrderSyncPromise,
    packageOrderDetails: packagePipelineOrderDetailPromise,
    // packageClientContacts: packageClientContactPromise,
  });
}

export default function SchemeProceedForm() {
  const { packageOrderSync, packageOrderDetails } = useLoaderData();

  const [alertMessage, setAlertMessage] = useState("");

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Scheme Edit Form</title>
      </Helmet>
      <div className={Styles.navigationWrapper}>
        <SchemeEditFormNavigation
          // stepsOptions={stepsOptions}
          // currentStep={currentStep}
          // contractType={contractType}
          // handleClickStep={handleClickStep}
          isProceed
        />
        {alertMessage ? (
          <div className={Styles.infoWrapper}>
            <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
            <span>{alertMessage}</span>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={Styles.layoutWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageOrderSync}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageOrderSync) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await
                  resolve={packageOrderDetails}
                  errorElement={
                    <div className={Styles.errorWrapper}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageOrderDetails) => (
                    <SchemeProceedFormLayout
                      syncData={packageOrderSync?.response}
                      defaultOrderForm={packageOrderDetails?.response}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
