export function base64ToFile(base64String, fileName) {
  // eslint-disable-next-line no-useless-escape
  const match = base64String.match(/^data:([a-zA-Z\/]+);base64,/);
  if (!match) {
    throw new Error("Invalid base64 data URI");
  }

  const mimeType = match[1];
  const base64Data = base64String.split(",")[1];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
}
