import useWindowSize from '@Hooks/useWindowSize';
import { Section } from '@Organism/Additional/CandidateForm/OnProgress/Sixth';
import Images from '@Theme/Images';
import { useEffect, useRef, useState } from 'react';
import { RightColumn } from '../Second';
import { LogoCard } from '../Third';
import Styles from './style.module.scss';

export default function SixthStep({
  documents = [],
  setDocuments = () => { },
  stepperHeight = '',
}) {

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);



  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const { width } = useWindowSize()

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef} style={{
        position: width > 768 ? 'sticky' : 'absolute',
        top: 0
        // top: `calc(${stepperHeight}px)`,
      }}>
        Unggah Dokumen
      </h3>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <LogoCard
            style={{
              //  top: `calc(${headerHeight + stepperHeight}px)`
              top: `calc(${headerHeight}px)`,
            }}
            img={Images.DOCUMENT_BANNER}
            noAddButton
            headerHeight={headerHeight}
            stepperHeight={stepperHeight}
          />
        </div>
        <RightColumn className={Styles.right}>
          <Section
            data={documents?.mandatory}
            title={"Dokumen Wajib"}
            form={documents}
            setForm={setDocuments}
            identifier={"mandatory"}
          />
          <Section
            data={documents?.nonMandatory}
            title={"Dokumen Tidak Wajib"}
            form={documents}
            setForm={setDocuments}
            identifier={"nonMandatory"}
          />
          <Section
            data={documents?.additional}
            title={"Dokumen Tambahan"}
            form={documents}
            setForm={setDocuments}
            identifier={"additional"}
          />
        </RightColumn>
      </div>

    </div>
  )
}