import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";

export default function PreviewJobPosting({
  previewVideos,
  // positionNameNormal,
  // location,
  addTags,
  handleDone,
  handleBack,
  acceptedFiles,
  data,
}) {
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);
  const startIndex = 0;
  const endIndex = 1;

  // eslint-disable-next-line no-unused-vars
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function changeFormatPlacement(str) {
    return str.replaceAll("::", ", ");
  }

  const slicedItems = useMemo(() => {
    if (data?.placement?.length > 1) {
      return data?.placement?.slice(endIndex);
    }
  }, [data?.placement]);

  const selectedItems = useMemo(() => {
    if (data?.placement?.length > 0) {
      return data?.placement?.slice(startIndex, endIndex);
    }
    return [];
  }, [data?.placement]);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);
  const [playVideo, setPlayVideo] = useState(false);
  const videoRef = useRef();
  const handlePlay = () => {
    videoRef.current.play();
    setPlayVideo(true);
  };

  const handlePause = () => {
    videoRef.current.pause();
    setPlayVideo(false);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.modalPreviw} ref={boxRef}>
        <div className={Styles.boxTitle}>
          <span className={Styles.preview}>Preview</span>
          <Icon
            icon={"cross"}
            size={24}
            className={Styles.iconCross}
            onClick={handleBack}
          />
        </div>
        {previewVideos ? (
          <>
            {acceptedFiles?.includes("image") ? (
              <div className={Styles.previewContent}>
                <div className={Styles.templateBox}>
                  <img src={Images.TEMPLATE_IPHONE} alt="" />
                  <div className={Styles.descJobs}>
                    <span className={Styles.positionName}>
                      {data?.title ? data?.title?.toUpperCase() : ""}
                    </span>
                    <div className={Styles.address}>
                      <Icon icon={"map-pin-solid"} size={16} color={"white"} />

                      {Array.isArray(data?.placement) ? (
                        <div>
                          <span>
                            {" "}
                            {selectedItems?.length > 0
                              ? changeFormatPlacement(
                                  selectedItems
                                    .map((item) => item.city)
                                    .join(", ")
                                )?.toUpperCase()
                              : "-"}
                          </span>
                          {data?.placement?.length > 1 ? (
                            <span>+{slicedItems?.length}</span>
                          ) : null}
                        </div>
                      ) : (
                        <span>
                          {data?.placement
                            ? changeFormatPlacement(
                                data?.placement
                              )?.toUpperCase()
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className={Styles.tagsBox}>
                      {addTags?.length === 0
                        ? ""
                        : addTags.map((el) => {
                            return (
                              <div key={el.id} className={Styles.tags}>
                                {el.name}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <img
                  src={previewVideos}
                  alt=""
                  className={Styles.imagePreview}
                />
              </div>
            ) : (
              <div className={Styles.previewContent}>
                <div className={Styles.templateBox}>
                  <img src={Images.TEMPLATE_IPHONE} alt="" />
                  <div className={Styles.descJobs}>
                    <span className={Styles.positionName}>
                      {data?.title ? data?.title?.toUpperCase() : ""}
                    </span>
                    <div className={Styles.address}>
                      <Icon icon={"map-pin-solid"} size={16} color={"white"} />
                      {Array.isArray(data?.placement) ? (
                        <div>
                          <span>
                            {" "}
                            {selectedItems?.length > 0
                              ? changeFormatPlacement(
                                  selectedItems
                                    .map((item) => item.city)
                                    .join(", ")
                                )?.toUpperCase()
                              : "-"}
                          </span>
                          {data?.placement?.length > 1 ? (
                            <span>+{slicedItems?.length}</span>
                          ) : null}
                        </div>
                      ) : (
                        <span>
                          {data?.placement
                            ? changeFormatPlacement(
                                data?.placement
                              )?.toUpperCase()
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className={Styles.tagsBox}>
                      {addTags?.length === 0
                        ? ""
                        : addTags.map((el) => {
                            return (
                              <div key={el.id} className={Styles.tags}>
                                {el.name}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <video
                  className={Styles.video}
                  src={previewVideos}
                  ref={videoRef}
                  onClick={playVideo === false ? handlePlay : handlePause}
                ></video>

                {playVideo === false ? (
                  <div className={Styles.iconPlay} onClick={handlePlay}>
                    <Icon icon={"play"} size={20} color={"black"} />
                  </div>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div className={Styles.previewDisable}>
            <span className={Styles.disableText}>Belum ada konten</span>
          </div>
        )}
      </div>
    </div>
  );
}
