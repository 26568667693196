import RenewDataLayout from "@Organism/Additional/RenewData";
import { getBankList, getCandidateRenewData, getCandidateSync } from "@Services/officer/recruitment";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader({ params }) {
  const hash = params.hash;

  const renewDataPromise = getCandidateRenewData(hash);
  const candidateSyncPromise = getCandidateSync();
  const bankListPrommise = getBankList()

  return defer({
    packageCandidateSync: candidateSyncPromise,
    packageRenewData: renewDataPromise,
    packageBanks: bankListPrommise
  });
}

export default function RenewData() {
  const { packageCandidateSync, packageRenewData, packageBanks } = useLoaderData();


  return (
    <Suspense
      fallback={
        <div className={Styles.loadingWrapper}>
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={packageCandidateSync}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageCandidateSync) => (
          <Suspense
            fallback={
              <div className={Styles.loadingWrapper}>
                <LoadingAnimation />
              </div>
            }
          >
            <Await
              resolve={packageRenewData}
              errorElement={
                <div style={{ height: "calc(100vh - 220px)" }}>
                  <AsyncErrorBoundary />
                </div>
              }
            >
              {(packageRenewData) => (
                <Suspense>
                  <Await
                    resolve={packageBanks}

                  >
                    {(packageBanks) => (
                      <RenewDataLayout
                        syncData={packageCandidateSync?.response}
                        defaultData={packageRenewData?.data}
                        banks={packageBanks?.response}
                      />
                    )}
                  </Await>
                </Suspense>
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  )
}