import Icon from '@Atom/Icon';
import UseOutsideClick from '@Hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import Styles from './styles.module.scss';

export default function RecruiterApprovalModal({ handleDone = () => {}, onCancel, show, data, type }) {
    const boxRef = useRef();
    const boxOutsideClick = UseOutsideClick(boxRef);

    // eslint-disable-next-line no-unused-vars
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [textError, setTextError] = useState(null);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [show]);

    useEffect(() => {
        if (boxOutsideClick) {
            onCancel();
        }
    }, [boxOutsideClick, onCancel]);

    useEffect(() => {
        setTimeout(()=> {
            setTextError(null)
        }, 3000)
    },[textError])    

    return (
        <div className={Styles.background}>
            <div className={Styles.container} ref={boxRef}>
                <Icon icon="question-mark" color={'#E4C200'} size={50} />
                <h4>{`Alihkan Kandidat Ke Daftar ${type}?`}</h4>
                <p>{`Apakah anda yakin kandidat ${data?.name} dengan posisi ${data?.ExpectedPosition?.name} ingin dialihkan ke daftar ${type}?`}</p>

                {type === 'Public' && (
                    <div className={Styles.messageWrapper}>
                        <div className={Styles.iconDash}>
                            <Icon icon="information-solid" color={'#E4C200'} size={24} />
                        </div>
                        <span>
                            {
                                'Bila kandidat dipindahkan ke daftar publik, kandidat dapat diambil oleh seluruh recruiter yang ada'
                            }
                        </span>
                    </div>
                )}

                <div className={`${Styles.buttonsWrapper}`}>
                    <button onClick={onCancel}>Batal</button>
                    <button
                        className={`${isSubmiting ? Styles.process : ''}`}
                        onClick={handleDone}>
                        {!isSubmiting ? (
                            <span>Konfirmasi</span>
                        ) : (
                            <span>Menyimpan...</span>
                        )}
                    </button>
                </div>

                {textError && 
                    <div className={Styles.errorWrapper}>
                        <span>{textError}</span>
                    </div>
                }
            </div>
        </div>
    );
}
