import { useNavigate } from 'react-router-dom';
import Styles from './style.module.scss'
import useQuery from '@Hooks/useQuery';
import Icon from '@Atom/Icon';
import CustomBreadCrumb from '@Molecule/CustomBreadCrumb';
import Dropdown from '@Atom/DropDown';
import SearchBar from '@Atom/SearchBar';

const dropdownOptions = [
  { name: "Sent" },
  { name: "Draft" },
  { name: "Incomplete" }
]

export default function SchemeListNavigation({
  searchTerm,
  setSearchTerm,
  sort,
  setSort
}) {
  const navigate = useNavigate();

  const query = useQuery();

  const name = query.get("n");
  const code = query.get("c");


  const handleClickBack = () => {
    navigate(`/clients/details?n=${name}&c=${code}`);
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbWrapper}>
        <span onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
        </span>
        <CustomBreadCrumb>
          <span className={Styles.breadcrumbText}>Job Orders</span>
          <span className={Styles.breadcrumbText2}>All List</span>
        </CustomBreadCrumb>
      </div>
      <div className={Styles.filterWrapper}>
        <div className={Styles.searchWrapper}>
          <SearchBar
            placeholder={"Search by location"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className={Styles.dropdownWrapper}>
          <Dropdown
            placeholder={"All status"}
            options={dropdownOptions}
            value={sort}
            setValue={setSort}
            defaultText={'All'}
            handleSetDefault={() => setSort('')}
            iconName={"filter"}
            withDefaultSetter
            withIcon
            autoClose
          />
        </div>
      </div>
    </div>
  )
}