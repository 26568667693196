import AutocompleteField from "@Molecule/AutocompleteField";
import InputField from "@Molecule/InputField";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";

export default function ContactInfoSection({ form, setForm, options, disableInput, errorField }) {
  const [
    emergencyContactRelationshipTemp,
    setEmergencyContactRelationshipTemp,
  ] = useState(form?.emergencyContact?.relationship || "");
  const [
    sameHouseFamilyContactRelationshipTemp,
    setSameHouseFamilyContactRelationshipTemp,
  ] = useState(form?.sameHouseFamilyContact?.relationship);
  const [
    differentHouseFamilyContactRelationshipTemp,
    setDifferentHouseFamilyContactRelationshipTemp,
  ] = useState(form?.differentHouseFamilyContact?.relationship);

  const handleChange = (e, objectTitle, inputName) => {
    let targetValue;
    const re = /^[0-9\b]+$/;

    if (inputName === "phoneNumber") {
      if (
        e.target.value === "" ||
        (re.test(e.target.value) && e.target.value.startsWith("8"))
      ) {
        targetValue = !isNaN(e.target.value)
          ? e.target.value
          : e.target.value.toUpperCase();
      }
    } else {
      targetValue = !isNaN(e.target.value)
        ? e.target.value
        : e.target.value.toUpperCase();
    }

    if (targetValue || targetValue === "") {
      setForm({
        ...form,
        [objectTitle]: {
          ...form?.[objectTitle],
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        },
      });
    }
  };

  useEffect(() => {
    setForm({
      ...form,
      emergencyContact: {
        ...form?.emergencyContact,
        relationship: emergencyContactRelationshipTemp,
      },
      sameHouseFamilyContact: {
        ...form?.sameHouseFamilyContact,
        relationship: sameHouseFamilyContactRelationshipTemp,
      },
      differentHouseFamilyContact: {
        ...form?.differentHouseFamilyContact,
        relationship: differentHouseFamilyContactRelationshipTemp,
      },
    });

    // eslint-disable-next-line
  }, [
    emergencyContactRelationshipTemp,
    sameHouseFamilyContactRelationshipTemp,
    differentHouseFamilyContactRelationshipTemp,
  ]);

  useEffect(() => {
      setEmergencyContactRelationshipTemp(form?.emergencyContact?.relationship || "");
      setSameHouseFamilyContactRelationshipTemp(form?.sameHouseFamilyContact?.relationship || "");
      setDifferentHouseFamilyContactRelationshipTemp(form?.differentHouseFamilyContact?.relationship || "");
  }, [
    form?.emergencyContact?.relationship, 
    form?.sameHouseFamilyContact?.relationship,
    form?.differentHouseFamilyContact?.relationship
  ]);

  return (
    <div className={Styles.container}>
      <h3>Kontak</h3>
      <div className={Styles.section}>
        <h4>Kontak Darurat</h4>
        <div className={Styles.content}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={form?.emergencyContact?.name}
            onChange={(e) => handleChange(e, "emergencyContact", "name")}
            required
            disabled={disableInput}
            isError={errorField && !form?.emergencyContact?.name}
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={emergencyContactRelationshipTemp}
            setValue={setEmergencyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !emergencyContactRelationshipTemp}
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={form?.emergencyContact?.phoneNumber}
            onChange={(e) => handleChange(e, "emergencyContact", "phoneNumber")}
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={errorField && !form?.emergencyContact?.phoneNumber}
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <InputField
          title="Alamat "
          type={"address"}
          value={form?.emergencyContact?.address}
          placeholder={"Tulis alamat pemilik kontak"}
          onChange={(e) => handleChange(e, "emergencyContact", "address")}
          hideIcon
          required
          disabled={disableInput}
          isError={errorField && !form?.emergencyContact?.address}
          errorText={"*Alamat belum diisi"}
        />
      </div>
      <div className={Styles.section}>
        <h4>Kontak Keluarga Serumah</h4>
        <div className={Styles.content}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={form?.sameHouseFamilyContact?.name}
            onChange={(e) => handleChange(e, "sameHouseFamilyContact", "name")}
            required
            disabled={disableInput}
            isError={errorField && !form?.sameHouseFamilyContact?.name}
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={sameHouseFamilyContactRelationshipTemp}
            setValue={setSameHouseFamilyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !sameHouseFamilyContactRelationshipTemp}
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={form?.sameHouseFamilyContact?.phoneNumber}
            onChange={(e) =>
              handleChange(e, "sameHouseFamilyContact", "phoneNumber")
            }
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={errorField && !form?.sameHouseFamilyContact?.phoneNumber}
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <InputField
          title="Alamat "
          type={"address"}
          value={form?.sameHouseFamilyContact?.address}
          placeholder={"Tulis alamat pemilik kontak"}
          onChange={(e) => handleChange(e, "sameHouseFamilyContact", "address")}
          hideIcon
          required
          disabled={disableInput}
          isError={errorField && !form?.sameHouseFamilyContact?.address}
          errorText={"*Alamat belum diisi"}
        />
      </div>
      <div className={Styles.section}>
        <h4>Kontak Keluarga Tidak Serumah</h4>
        <div className={Styles.content}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={form?.differentHouseFamilyContact?.name}
            onChange={(e) =>
              handleChange(e, "differentHouseFamilyContact", "name")
            }
            required
            disabled={disableInput}
            isError={errorField && !form?.differentHouseFamilyContact?.name}
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={differentHouseFamilyContactRelationshipTemp}
            setValue={setDifferentHouseFamilyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !differentHouseFamilyContactRelationshipTemp}
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={form?.differentHouseFamilyContact?.phoneNumber}
            onChange={(e) =>
              handleChange(e, "differentHouseFamilyContact", "phoneNumber")
            }
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={errorField && !form?.differentHouseFamilyContact?.phoneNumber}
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <InputField
          title="Alamat "
          type={"address"}
          value={form?.differentHouseFamilyContact?.address}
          placeholder={"Tulis alamat pemilik kontak"}
          onChange={(e) =>
            handleChange(e, "differentHouseFamilyContact", "address")
          }
          hideIcon
          required
          disabled={disableInput}
          isError={errorField && !form?.differentHouseFamilyContact?.address}
          errorText={"*Alamat belum diisi"}
        />
      </div>
    </div>
  );
}
