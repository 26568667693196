import React from "react";
import Styles from "./style.module.scss";
import Dropdown from "@Atom/DropDown";

const SelectOptions = ({
  title,
  placeholder,
  options,
  withToggle,
  value,
  setValue,
  autoClose,
  iconColor,
  required,
  disabled = false,
  requiredLabel = "*",
  isError = false,
  errorText = 'Please fill the field',
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={`${Styles.header} ${!withToggle && Styles.noToggle}`}>
        <div className={Styles.title}>
          <p>{title}</p>
          {required && <span className={Styles.asterisk}>{requiredLabel}</span>}
        </div>
      </div>
      <div className={Styles.dropdownContainer}>
        <Dropdown
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          value={value}
          setValue={setValue}
          autoClose={autoClose}
          iconColor={iconColor}
          isError={isError}
          errorText={errorText}
        />
      </div>
    </div>
  );
};

export default SelectOptions;
