import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./styles.module.scss";
// import Styles from "./styles.module.scss";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import {
  getCandidateSync,
  getOfferTransitionOfferingData,
} from "@Services/officer/recruitment";
import OfferTransitionComponent from "@Organism/Additional/OfferTransition";

export async function loader({ params }) {
  const hash = params.hash;
  const candidateOfferingPromise = getOfferTransitionOfferingData(hash);
  const candidateSyncPromise = getCandidateSync();
  return defer({
    packageCandidateSync: candidateSyncPromise,
    packageCandidateOffering: candidateOfferingPromise,
  });
}

export default function OfferTransition() {
  const { packageCandidateSync, packageCandidateOffering } = useLoaderData();
  const [currentStep, setCurrentStep] = useState(
    +sessionStorage.getItem("transitionStep") || 0
  );
  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Candidate Form</title>
      </Helmet>
      <Suspense
        fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageCandidateSync}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageCandidateSync) => (
            <Suspense
              fallback={
                <div className={Styles.loadingWrapper}>
                  <LoadingAnimation />
                </div>
              }
            >
              <Await
                resolve={packageCandidateOffering}
                errorElement={
                  <div style={{ height: "calc(100vh - 220px)" }}>
                    <AsyncErrorBoundary />
                  </div>
                }
              >
                {(packageCandidateOffering) => (
                  <OfferTransitionComponent
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    syncData={packageCandidateSync?.response}
                    offeringData={packageCandidateOffering?.response}
                  />
                )}
              </Await>
            </Suspense>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
