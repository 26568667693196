import ErrorBubble from "@Atom/ErrorBubble";
import Styles from "./inputPhoneNumber.module.scss";
import { useState } from "react";

const InputPhoneNumber = ({
  placeholder,
  id,
  value,
  onChange,
  disabled,
  isError = false,
  isErrorV2 = false,
  readOnly = false,
  errorText = "",
  isAddClient = false,
  ...props
}) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <div className={Styles.wrapper}>
      <div
        className={`${Styles.container} ${
          isError && !onFocus ? Styles.error : ""
        }`}
      >
        <div className={Styles.phoneCodeWrapper}>+62</div>
        <input
          className={Styles.input}
          // type="number"
          type="text"
          pattern="\d*"
          onKeyDown={(event) => {
            if (isAddClient) {
              // if (
              //   event?.key === "." ||
              //   event?.key === "-" ||
              //   event?.key === "e" ||
              //   event?.key === "=" ||
              //   /^[a-zA-Z]$/.test(event?.key)
              // ) {
              //   event?.preventDefault();
              // }
            } else {
              // if (
              //   event?.key === "." ||
              //   event?.key === "-" ||
              //   event?.key === "e" ||
              //   event?.key === "=" ||
              //   /^[a-zA-Z]$/.test(event?.key) ||
              //   !/[0-9]/.test(event.key) ||
              //   (!value?.length && event?.key !== "8")
              // ) {
              //   event?.preventDefault();
              // }
            }
          }}
          onWheel={(e) => e.target.blur()}
          id={id || "fname"}
          name="fname"
          value={value || ""}
          // onChange={onChange}
          onChange={(e) => {
            const re = /^(8[0-9]{0,11})$/;
            const re1 = /[^0-9]/g;
            if (!isAddClient) {
              if (e.target.value === "" || re.test(e.target.value)) {
                onChange(e);
              }
            } else {
              if (!re1?.test(e.target.value) || e.target.value === "") {
                onChange(e);
              }
            }
          }}
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={13}
        />
      </div>
      {isError &&
        !disabled &&
        !readOnly &&
        !onFocus &&
        (!isErrorV2 ? (
          <span className={Styles.errorText}>{errorText}</span>
        ) : (
          <div>
            <ErrorBubble errorText={errorText} />
          </div>
        ))}
    </div>
  );
};

export default InputPhoneNumber;
