/* eslint-disable no-useless-escape */
import Styles from "./style.module.scss";
import LeftSide from "./left";
import RightSide from "./right";
import ModalWrapper from "@Atom/ModalWrapper";
import ParameterListModal from "@Molecule/_modal/HumanCapital/ParameterListModal";
import { useState } from "react";
import SuccessCreateContractModal from "@Molecule/_modal/HumanCapital/SuccessCreateContractModal";
import FailedCreateContractModal from "@Molecule/_modal/HumanCapital/FailedCreateContractModal";
import { useNavigate } from "react-router-dom";

const CreateContractDefault = ({
  clientContracts,
  parameters,
  data,
  type,
  ...props
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const id = data?.id;
  const navigate = useNavigate();
  const html = ``;
  const [modalOpen, setModalOpen] = useState(false);
  const [contractData, setContractData] = useState({
    name: type === "DUPLICATE_CONTRACT" ? "" : data?.name ? data?.name : "",
    contractCode:
      type === "DUPLICATE_CONTRACT"
        ? ""
        : data?.docNumber
        ? data?.docNumber
        : "",
    template: data?.template || html,
    type: "CONTRACT",
    clientContractID: data?.id || "",
  });

  return (
    <div className={Styles.container}>
      <ModalWrapper show={modalOpen || successModal || errorModal}>
        {modalOpen && (
          <ParameterListModal setShow={setModalOpen} parameters={parameters} />
        )}
        {successModal && (
          <SuccessCreateContractModal
            setShow={setSuccessModal}
            action={() => navigate("/drafts-contract")}
          />
        )}
        {errorModal && (
          <FailedCreateContractModal
            setShow={setErrorModal}
            errorMessage={errorMessage}
            action={() => setErrorModal(false)}
          />
        )}
      </ModalWrapper>
      <LeftSide
        contractData={contractData}
        setContractData={setContractData}
        type={type}
        clientContracts={clientContracts}
        parameters={parameters}
      />
      <RightSide
        contractData={contractData}
        setModalOpen={() => setModalOpen(true)}
        type={type}
        id={id}
        setSuccessModal={setSuccessModal}
        setErrorModal={setErrorModal}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

const CreatePKWTContract = ({
  clientContracts,
  parameters,
  data,
  type,
  ...props
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const id = data?.id;
  const navigate = useNavigate();
  const html = `
  <p>&nbsp;CONTRACT TEMPLATE<br> ini PIC_NAME {PIC_NAME}<br> ini POSITION {POSITION}<br> ini ALAMAT {ALAMAT}<br> ini NAME {NAME}<br> ini GENDER {GENDER}<br> ini DOB{DOB}<br> ini LOCATION{LOCATION}<br> ini EDUCATION{EDUCATION}<br> ini PHONE{PHONE}<br> ini NIK{NIK}<br> ini LOKASI{LOKASI}<br> ini APP_ID{APP_ID}<br> ini AUTO_NUMBER{AUTO_NUMBER}<br>              ini adalah paragraf. fungsinya adalah menyampaikan alur cerita             secara lebih detail. kita bias menampilkan text bold, italic,             underline, atau kombinasi dari beberapa format tersebut.           <br>              kita bisa menambahkan paragraf kedua dan seterusnya. Maka masing             masing dari paragraf akan ditampilkan terpisah seperti ini. Didalam             sebuah paragraf kita dapat menyelipkan sebuah link ke website             apapun.&nbsp;</p>\n<p style="text-align:center;"><strong>TES EDIT</strong><br>&nbsp;</p>\n`;
  const [modalOpen, setModalOpen] = useState(false);
  const [contractData, setContractData] = useState({
    name: data?.name || "",
    contractCode: data?.contractCode || "",
    template: data?.template || html,
    type: "CONTRACT",
    clientContractID: data?.id || "",
  });

  return (
    <div className={Styles.container}>
      <ModalWrapper show={modalOpen || successModal || errorModal}>
        {modalOpen && (
          <ParameterListModal setShow={setModalOpen} parameters={parameters} />
        )}
        {successModal && (
          <SuccessCreateContractModal
            setShow={setSuccessModal}
            action={() => navigate("/drafts-contract")}
          />
        )}
        {errorModal && (
          <FailedCreateContractModal
            setShow={setErrorModal}
            errorMessage={errorMessage}
            action={() => setErrorModal(false)}
          />
        )}
      </ModalWrapper>
      <LeftSide
        contractData={contractData}
        setContractData={setContractData}
        type={type}
        clientContracts={clientContracts}
        parameters={parameters}
      />
      <RightSide
        contractData={contractData}
        setModalOpen={() => setModalOpen(true)}
        type={type}
        id={id}
        setSuccessModal={setSuccessModal}
        setErrorModal={setErrorModal}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

const CreatePKMContract = ({
  clientContracts,
  parameters,
  data,
  type,
  ...props
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const id = data?.id;
  const navigate = useNavigate();
  const html = `
  <p>&nbsp;CONTRACT TEMPLATE PT Bank Central Asia Tbk<br> ini Nama {NAME} <br> ini Jenis Kelamin {GENDER} <br> ini Tanggal Lahir {DOB} <br> ini LOCATION : {LOCATION} <br> ini Pendidikan : {EDUCATION} <br> ini Nomor HP : {PHONE}&nbsp;</p>\n<p>ddhshshshhshs</p>\n<p>jsjsjsjsjjsjsjsjsjsjsjsjjs</p>\n<p>ggsgsgsgsggsgs<br>&nbsp;</p>\n`;
  const [modalOpen, setModalOpen] = useState(false);
  const [contractData, setContractData] = useState({
    name: data?.name || "",
    contractCode: data?.contractCode || "",
    template: data?.template || html,
    type: "CONTRACT",
    clientContractID: data?.id || "",
  });

  return (
    <div className={Styles.container}>
      <ModalWrapper show={modalOpen || successModal || errorModal}>
        {modalOpen && (
          <ParameterListModal setShow={setModalOpen} parameters={parameters} />
        )}
        {successModal && (
          <SuccessCreateContractModal
            setShow={setSuccessModal}
            action={() => navigate("/drafts-contract")}
          />
        )}
        {errorModal && (
          <FailedCreateContractModal
            setShow={setErrorModal}
            errorMessage={errorMessage}
            action={() => setErrorModal(false)}
          />
        )}
      </ModalWrapper>
      <LeftSide
        contractData={contractData}
        setContractData={setContractData}
        type={type}
        clientContracts={clientContracts}
        parameters={parameters}
      />
      <RightSide
        contractData={contractData}
        setModalOpen={() => setModalOpen(true)}
        type={type}
        id={id}
        setSuccessModal={setSuccessModal}
        setErrorModal={setErrorModal}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

const CreateAdditionalDraft = ({
  clientContracts,
  parameters,
  data,
  type,
  ...props
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const id = data?.id;
  const navigate = useNavigate();
  const html = `
  <h1 style="font-weight: bolder; text-align: center;">PERJANJIAN KERJA WAKTU TERTENTU</h1>
  <p style="text-align: center;">001/PIS/PKWT_MFCC/JKT1/I/2023</p>
  <br />
  <p style="text-align: justify;">Perjanjian Kerja Waktu Tertentu ini
      dibuat pada hari Selasa tanggal 3 bulan Januari Tahun 2023 (3/1/2023), oleh dan antara yang bertanda tangan di
      bawah ini, masing-masing:</p>
  <br />

  <!-- Pihak 1 -->
  <div style="display: grid; grid-template-columns: 24pt auto; gap: 8pt;">
      <span>I.</span>
      <div style="display: grid; grid-template-columns: 84pt 10pt auto; gap: 4pt 8pt;">
          <div>Nama</div>
          <div>:</div>
          <div>Kujou Sara</div>
          <div>Jabatan</div>
          <div>:</div>
          <div>Human Capital Manager</div>
          <div>Alamat</div>
          <div>:</div>
          <div>Tenryou Commision, Inazuma</div>
      </div>
  </div>

  <br />
  <p style="text-indent: 0pt; text-align: justify;">Dalam perjanjian ini bertindak dan bertanggung jawab atas nama PT. PERMATA INDO SEJAHTERA selanjutnya disebut sebagai PERUSAHAAN.</p>
  <br />

  <!-- Pihak 2 -->
  <div style="display: grid; grid-template-columns: 24pt auto; gap: 8pt;">
      <span>II.</span>
      <div style="display: grid; grid-template-columns: 84pt 10pt auto; gap: 4pt 8pt;">
          <div>Nama</div>
          <div>:</div>
          <div>Sangonomiya Kokomi</div>
          <div>Tanggal Lahir</div>
          <div>:</div>
          <div>Inazuma, 22 Februari 1998</div>
          <div>Jenis Kelamin</div>
          <div>:</div>
          <div>Perempuan</div>
          <div>Alamat</div>
          <div>:</div>
          <div>Watatsumi, Inazuma</div>
          <div>Telepon</div>
          <div>:</div>
          <div>62811111111</div>
          <div>Pendidikan</div>
          <div>:</div>
          <div>S1</div>
      </div>
  </div>
  <br />
  <p style="text-indent: 0pt;text-align: justify;">Dalam perjanjian ini bertindak dan bertanggung jawab atas nama diri
      sendiri, selanjutnya disebut sebagai KARYAWAN.</p>
  <br />
  
  <!-- Isi -->
  <p style="text-indent: 0pt;text-align: justify;">Kedua belah pihak telah sepakat mengadakan perjanjian hubungan kerja,
      untuk jangka waktu tertentu (selanjutnya disebut sebagai PERJANJIAN) dengan persyaratan sebagai berikut :
  </p>
  <br />

  <!-- Pasal 1 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 1</h1>
  <h1 style="font-weight: bold; text-align: center;">Hubungan Kerja</h1>
  <br/>
  
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <span style="text-indent: 0pt; text-align: justify;">Perusahaan menerima dan memperkerjakan Karyawan dalam hubungan kerja waktu
              tertentu dengan posisi sebagai Driver berlokasi di {PLACEMENT_ADDRESS}</span>
      </li>
      <li style="margin-bottom:12pt;">
          <span style="text-indent: 0pt;text-align: justify;">Perjanjian kerja waktu tertentu ini berlaku mulai tanggal 3 Januari
              2023 dan akan berakhir pada tanggal 5 November 2024
          </span>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <span style="text-indent: 0pt;text-align: justify;">Karyawan menyatakan bahwa hubungan kerja dengan perusahaan sebelumnya telah
              berakhir dan semua kewajiban dan hak telah diselesaikan
              </span>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <span style="text-indent: 0pt;text-align: justify;">Karyawan dapat diputuskan kontraknya sebelum jangka waktu yang ditentukan apabila
              perusahaan mengalami penurunan bisnis, dan/atau dalam segi bisnis tidak dapat dipertahankan dan tidak mungkin dilanjutkan. dengan tanpa penggantian sisa masa kontrak</span>
          <span
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</span>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt; text-align: justify;">Karyawan dapat diputuskan kontraknya sebelum jangka waktu yang ditentukan apabila
              klien perusahaan karena sesuatu dan lain hal, memutuskan untuk melakukan pengurangan karyawan ataupun membatalkan proyek yang tengah berlangsung, dengan tanpa
              penggantian sisa masa kontrak. Apabila terjadi pemutusan hubungan kerja oleh klien
              perusahaan maka karyawan harus menerima tanpa melibatkan Perusahaan.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
  </ol>

  <!-- Pasal 2 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 2</h1>
  <h1 style="font-weight: bold; text-align: center;">Tugas dan Kewajiban Karyawan</h1>
  <br/>
  <p style="text-indent: 0pt; text-align: justify;">Karyawan dalam hal ini harus memenuhi tugas dan kewajiban- kewajibannya antara lain
      namun tidak terbatas kepada :</p>
  <br/>
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Menyediakan waktunya secara utuh dan tidak mempunyai hubungan kerja dengan
              perusahaan lain baik langsung maupun tidak langsung serta memenuhi sepenuhnya
              pembagian tugas dan lokasi pekerjaan yang diberikan oleh perusahaan.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Mentaati peraturan umum, jadwal kerja dan tata tertib kerja perusahaan serta ketentuan-ketentuan lainnya yang berlaku di Peraturan Perusahaan.</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Menjaga kerahasiaan perusahaan, dengan tidak menyimpan dan atau memperlihatkan
              kepada pihak lain dokumen-dokumen perusahaan diluar kepentingan dinas.
              </p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Memenuhi target/sasaran kinerja/performance yang telah ditentukan oleh Perusahaan.</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt; text-align: justify;">Selain sebagaimana diatur dalam pasal ini perusahaan akan menetapkan lebih lanjut
              mengenai target, tugas dan kewajiban Karyawan.</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Apabila karyawan tidak mencapai standar kinerja yang ditetapkan Perusahaan, maka
              karyawan bersedia untuk mengundurkan diri tanpa ada tuntutan apapun</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Apabila karyawan mengundurkan diri sebelum berakhirnya masa perjanjian, maka
              karyawan harus memberitahukan kepada perusahaan paling lambat 30 hari sebelumnya.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
  </ol>

  <!-- Pasal 3 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 3</h1>
  <h1 style="font-weight: bold; text-align: center;">Imbalan</h1>
  <br/>
  
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Sebagai imbalan atas pekerjaan yang dilakukan oleh Karyawan, Perusahaan akan
              memberikan upah sebagai berikut :</p>
          <p style="text-indent: 0pt; text-align: justify;">Yang terdiri dari :</p>
          <br/>
          <div style="display: grid; grid-template-columns: 60% 24pt 64pt; gap: 4pt 0pt;">
              <div>Gaji Pokok</div>
              <div>Rp.</div>
              <div style="text-align: end;">10.000.000</div>
              <!--  -->
              <div>Tunjangan Lain-lain</div>
              <div>Rp.</div>
              <div style="text-align: end;">100.000</div>
              <!--  -->
              <div>Total</div>
              <div>Rp.</div>
              <div style="text-align: end;">10.100.000</div>
          </div>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Periode penggajian dihitung mulai dari tanggal 1 bulan sebelumnya sampai tanggal 31
              bulan berjalan.</p>
      </li>
      <li style="margin-bottom:12pt; ">
          <p style="text-indent: 0pt;text-align: justify;">Upah akan dibayarkan tanggal 25 setiap bulannya, namun apabila tanggal 25 jatuh pada
              hari libur maka akan dibayarkan pada hari kerja berikutnya.
              </p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Apabila Karyawan mulai bekerja pada tanggal di antara tanggal cut off maka gaji akan
              dibayarkan secara proporsional.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Apabila Karyawan berhenti bekerja pada tanggal di antara tanggal cut off maka gaji akan
              dibayarkan secara proporsional.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Perusahaan akan memotong gaji Karyawan untuk pembayaran PPh 21 dan Iuran BPJS
              Ketenaga kerjaan 2%, Jaminan Pensiun 1% sebesar BPJS Kesehatan 1%. (sesuai
              aturan yang berlaku)</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Kepemilikan NPWP wajib dilakukan karyawan sesuai dengan prosedur PPh.21 dan bila
              tidak/belum memiliki maka besar pajak PPh.21 akan bertambah 20% dari tarif normal
              pajak PPh.21 yang dikenakan.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Biaya administrasi bank yang berhubungan dengan semua transaksi perbankan akan
              menjadi beban karyawan</p>
      </li>
  </ol>

  <!-- Pasal 4 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 4</h1>
  <h1 style="font-weight: bold; text-align: center;">Pemutusan Perjanjian</h1>
  <br/>
  <p style="text-indent: 0pt;text-align: justify;">Perusahaan setiap saat dapat memutuskan perjanjian ini jika antara lain:</p>
  <br/>
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Karyawan meninggal dunia.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Karyawan tidak masuk kerja 3 hari berturut-turut tanpa pemberitahuan</p>
      </li>
      <li style="margin-bottom:12pt; ">
          <p style="text-indent: 0pt;text-align: justify;">Karyawan tidak dapat memenuhi standar kinerja yang ditetapkan Perusahaan</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Karyawan telah melakukan pelanggaran / kesalahan berat yang akibatnya baik langsung
              maupun tidak langsung dapat merugikan atau mencemarkan nama baik perusahaan.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Memberi keterangan palsu dan / atau dipalsukan sehingga merugikan Perusahaan.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Karyawan melakukan pelanggaran terhadap isi perjanjian ini.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Perusahaan mengalami penurunan bisnis.</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;"> Apabila salah satu pihak mengakhiri hubungan kerja sebelum berakhirnya jangka waktu
              yang ditetapkan dalam perjanjian kerja waktu tertentu ini, para pihak sepakat untuk tidak
              saling meminta kompensasi pengganti sisa kontrak yang belum selesai.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
  </ol>

  <!-- Pasal 5 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 5</h1>
  <h1 style="font-weight: bold; text-align: center;">Akibat Pemutusan Perjanjian</h1>
  <br/>
  <p style="text-indent: 0pt;text-align: justify;">Apabila terjadi pemutusan perjanjian ini maka :</p>
  <br/>
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Karyawan berkewajiban mengembalikan seluruh barang-barang, data atau property yang berada di tangan Karyawan sehubungan dengan tugas dan kewajibannya
              berdasarkan perjanjian ini.</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Jika Karyawan masih mempunyai kewajiban apapun kepada Perusahaan, maka atas
              kewajibannya tersebut Karyawan tetap berkewajiban untuk menyelesaikan dan melaksanakan kewajiban tersebut.</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Karyawan wajib mengganti biaya sisa masa kontrak bila mengundurkan diri sebelum
              masa kontrak berakhir.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
  </ol>

  <!-- Pasal 6 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 6</h1>
  <h1 style="font-weight: bold; text-align: center;">Penyelesaian Perselisihan</h1>
  <br/>
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt; text-align: justify;">Perselisihan yang timbul sebagai akibat perjanjian ini akan diselesaikan secara
              musyawarah untuk mencapai mufakat.,</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Apabila perselisihan tidak dapat diselesaikan secara musyawarah maka masing-masing pihak sepakat untuk menyelesaikannya di pengadilan negeri setempat dan masing-masing pihak memilih kediaman hukum yang tetap di Kepaniteraan Pengadilan
              Negeri.,</p>
      </li>
  </ol>

  <!-- Pasal 7 -->
  <h1 style="font-weight: bold; text-align: center;">Pasal 7</h1>
  <h1 style="font-weight: bold; text-align: center;">Ketentuan Penutup</h1>
  <br/>
  
  <ol style="padding-left: 16pt; list-style-position: outside;">
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt; text-align: justify;">Perjanjian ini berlaku sejak tanggal tersebut di atas dan berakhir sesuai pasal 1 ayat b
              atau berakhir dengan sendirinya setelah pemutusan perjanjian karena alasan-alasan
              yang ditetapkan d alam ketentuan pasal 4 perjanjian ini.,</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt;text-align: justify;">Sepanjang mengenai pemutusan perjanjian ini para Pihak sepakat untuk melepaskan
              atau mengesampingkan keberlakuan ketentuan pasal 1266 Kitab Undang-Undang
              Hukum Perdata.,
          </p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Seluruh lampiran-lampiran apabila ada yang melekat pada perjanjian ini baik yang dibuat
              sekarang atau dikemudian hari merupakan satu kesatuan dan tidak dapat dipisahkan
              dari perjanjian ini.,</p>
      </li>
      <li style="margin-bottom:12pt;">
          <p style="text-indent: 0pt;text-align: justify;">Setiap perubahan dan atau tambahan terhadap perjanjian ini dan hal-hal yang belum
              cukup diatur dalam perjanjian ini akan diatur tersendiri dan menjadi bagian yang tidak
              terpisahkan dari perjanjian ini.,</p>
      </li>
      <li style="margin-bottom:12pt; position: relative;">
          <p style="text-indent: 0pt; text-align: justify;">Dengan ditandatanganinya Perjanjian ini maka para pihak dianggap telah membaca,
              mengerti dan memahami isi dari perjanjian beserta lampirannya.</p>
          <p
              style="position: absolute; top: 0pt; right: -32pt; text-align: right; border-left: 2px solid coral; color:grey; font-size: 9pt;">
              Paraf</p>
      </li>
  </ol>

  <br />
  <!-- Sign -->
  <div style="display: grid; grid-template-columns: 30% 50%; gap: 30%;">
      <!-- Pihak Pertama -->
      <div style="text-align: center;">
          <p text-indent: 0pt; text-align: justify; style="font-weight:bold;">Pihak Pertama</p>
          <br />
          <br />
          <br />
          <br />
          <p text-indent: 0pt; text-align: justify; style="font-weight: bold; text-decoration: underline;">Kujou Sara</p>
          <p text-indent: 0pt; text-align: justify;>Human Capital Manager</p>
      </div>
      <!-- Pihak Kedua -->
      <div style=" text-align: center;">
          <p text-indent: 0pt; text-align: justify; style="font-weight: bold;">Pihak
              Kedua</p>
          <br />
          <br />
          <br />
          <br />
          <p text-indent: 0pt; text-align: justify; style="font-weight: bold; text-decoration: underline;">Sangonomiya Kokomi</p>
          <p text-indent: 0pt; text-align: justify;>Sales Manager</p>
      </div>
  </div>
  `;
  const [modalOpen, setModalOpen] = useState(false);
  const [contractData, setContractData] = useState({
    name: data?.name || "",
    contractCode: data?.contractCode || "",
    template: data?.template || html,
    type: "ADDITIONAL",
    clientContractID: data?.id || "",
  });

  return (
    <div className={Styles.container}>
      <ModalWrapper show={modalOpen || successModal || errorModal}>
        {modalOpen && (
          <ParameterListModal setShow={setModalOpen} parameters={parameters} />
        )}
        {successModal && (
          <SuccessCreateContractModal
            setShow={setSuccessModal}
            action={() => navigate("/drafts-contract")}
          />
        )}
        {errorModal && (
          <FailedCreateContractModal
            setShow={setErrorModal}
            errorMessage={errorMessage}
            action={() => setErrorModal(false)}
          />
        )}
      </ModalWrapper>
      <LeftSide
        contractData={contractData}
        setContractData={setContractData}
        type={type}
        clientContracts={clientContracts}
        parameters={parameters}
      />
      <RightSide
        contractData={contractData}
        setModalOpen={() => setModalOpen(true)}
        type={type}
        id={id}
        setSuccessModal={setSuccessModal}
        setErrorModal={setErrorModal}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

const UpdateContractDraft = ({
  clientContracts,
  parameters,
  data,
  type,
  ...props
}) => {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const id = data?.id;
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [contractData, setContractData] = useState({
    name: data?.name || "",
    contractCode: data?.contractCode || "",
    template: data?.template || "",
    type: { type },
    clientContractID: data?.id || "",
  });

  return (
    <div className={Styles.container}>
      <ModalWrapper show={modalOpen || successModal || errorModal}>
        {modalOpen && (
          <ParameterListModal setShow={setModalOpen} parameters={parameters} />
        )}
        {successModal && (
          <SuccessCreateContractModal
            setShow={setSuccessModal}
            action={() => navigate("/drafts-contract")}
          />
        )}
        {errorModal && (
          <FailedCreateContractModal
            setShow={setErrorModal}
            errorMessage={errorMessage}
            action={() => setErrorModal(false)}
          />
        )}
      </ModalWrapper>
      <LeftSide
        contractData={contractData}
        setContractData={setContractData}
        type={type}
        clientContracts={clientContracts}
        parameters={parameters}
      />
      <RightSide
        contractData={contractData}
        setModalOpen={() => setModalOpen(true)}
        type={type}
        id={id}
        setSuccessModal={setSuccessModal}
        setErrorModal={setErrorModal}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

const CreateContract = ({ type, createTemplateSync, data, ...props }) => {
  const { clientContracts, parameters } = createTemplateSync?.response;


  switch (type) {
    case "PKWT":
      return (
        <CreatePKWTContract
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
    case "PKM":
      return (
        <CreatePKMContract
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
    case "ADDITIONAL":
      return (
        <CreateAdditionalDraft
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
    case "UPDATE_CONTRACT":
      return (
        <UpdateContractDraft
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
    case "UPDATE_ADDITIONAL":
      return (
        <UpdateContractDraft
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
    default:
      return (
        <CreateContractDefault
          clientContracts={clientContracts}
          parameters={parameters}
          data={data}
          type={type}
        />
      );
  }
};

export default CreateContract;
