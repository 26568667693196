import { API, API2 } from "@Config/api";
import axios from "axios";

export function getIdNumber(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/social-media/candidate/${id}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOrderList(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientRecommendation() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate-recommendation/sync-client-position`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateLogs(personId, page, limit) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate-log?personId=${personId}&page=${page}&limit=${limit}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateRecommend(positionID, clientCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate-recommendation/sync-candidate-contact?positionID=${positionID}&clientCode=${clientCode}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateEvaluations(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-evaluations/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getSyncOffering(id, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/offer/sync/${id}?search=${search}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

////offer/sync/5715

export function getOrderListByArea(query, code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/${code}?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getRecruitmentSync(clientCode, query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `recruitment-officer/${clientCode}?${query}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /recruitment-officer/candidates/9754

export function getDetailRecruitment(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/recruitment-officer/candidates/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllCandidateSync(
  page,
  limit,
  process,
  status,
  clientCode,
  cityCode,
  positionName,
  jobType,
  candidateID,
  positionID
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/recruitment-officer/candidates?page=${page}&limit=${limit}&process=${process}&status=${status}&clientCode=${clientCode}&cityCode=${cityCode}&positionName=${positionName}&jobType=${jobType}&candidateID=${candidateID}&positionID=${positionID}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getRecruitmentFilter(candidateName) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/recruitment-officer/sync?candidateName=${candidateName}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /recruitment-officer/sync?candidateName=ronny

export function getCandidates(clientCode, query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`candidate/${clientCode}?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addCandidate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addCandidateByJobForm(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/social-media/register`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addCandidateByExcel(positionID, file, jobPostingID, type) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData?.append("positionID", positionID);
    formData?.append("jobPostingID", jobPostingID);
    formData?.append("type", type);
    formData?.append("file", file);
    try {
      const { data } = await API.post(`/candidate/upload-excel`, formData, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendOTP(phoneNumber) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/candidate/otp`,
        { phoneNumber: phoneNumber },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyOTP(hash, otp) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/candidate/verify-otp/${hash}`,
        { otp: otp },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateTag(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/candidate/bulk-update`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function revertCandidate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/recruitment-officer/revert`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /recruitment-officer/revert

export function sendCandidatesRecommendation(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-recommendation`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateRecommendation(
  hash,
  limit,
  page,
  positionFilter,
  locationFilter
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate-recommendation/${hash}?limit=${limit}&page=${page}&position=${positionFilter}&location=${locationFilter}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function candidateRecommendationApproval(id, tag, rejectionNote = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/candidate-recommendation/approval/${id}`,
        new URLSearchParams({
          tag: tag,
          rejectionNote: rejectionNote,
        }),
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function candidateEvaluationReject(candidateID, rejectionNote = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/candidate-evaluations`,
        new URLSearchParams({
          candidateID: candidateID,
          rejectionNote: rejectionNote,
        }),
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function candidateEvaluationAccept(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-evaluations`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getInvitationSync(clientCode) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `candidate-invitation/sync?clientCode=${clientCode}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendInvitation(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-invitation`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendInvitation(invitationID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/candidate-invitation/resend/${invitationID}`,
        { data: "" },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendInvitationNew(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-invitation/resend`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function absentCandidate(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-invitation/absent/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /candidate-invitation/absent/9754

export function getInvitatonData(hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-invitation/${hash}`);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function responseInvitation(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.put(`/candidate-invitation/${id}`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getRegisterSync(limit, page, search, hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const endpoint = hash
        ? `social-media/sync/${hash}`
        : `social-media/sync?limit=${limit}&page=${page}&search=${search}`;
      const { data } = await API.get(endpoint);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`candidate-comprehensive/sync`);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getBankList() {
  return new Promise(async (resolve, reject) => {
    const API3 = axios.create({
      baseURL: "https://api1.myworkspace.id/api/v3",
    });
    try {
      const { data } = await API3.get(`bank/list-365 `);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendCandidateForm(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-comprehensive`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function sendRenewData(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API2.postForm(`/user/renewal-data`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendCandidateUpdateForm(formData) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/candidate-comprehensive`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /candidate-comprehensive

export function sendTransitionForm(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/offer/transition`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateRenewData(hash) {
  return new Promise(async (resolve, reject) => {
    const API3 = axios.create({
      baseURL: "https://dev.myworkspace.id/api/v3",
    });
    try {
      const { data } = await API3.get(`renewal-data/${hash}`);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /offer/transition
export function getCandidateOfferingData(hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`candidate/offer/${hash}`);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOfferTransitionOfferingData(hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`offer/transition/${hash}`);

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateTransitionOfferingData(personId, positionID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `offer/transition/retrieval?personId=${personId}&positionID=${positionID}`
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendOffering(candidateId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/offer/${candidateId}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendOffering(candidateId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate/offer/resend/${candidateId}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getTransferSync(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-transfer/sync?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function transferCandidate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/candidate-transfer`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAddExistingSync(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-transfer/add-sync?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addExisting(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-transfer/add`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchRecruiterCandidateLists(query, type = "public") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-lists/${type}?${query}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchRecruiterCandidateSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-lists/sync`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllPrivateJobPost(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-lists/all-job-post?${query}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchAllRecruiter(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-lists/ro?${query}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchJobPostByRO(id, query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate-lists/job-post-ro/${id}?${query}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function candidateTransferType(id, type, lastPosition, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `candidate-lists/transfer-type/${id}?type=${type}&lastPosition=${lastPosition}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addRecruiterCandidate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-lists`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
