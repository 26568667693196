/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import { Suspense, useEffect, useState } from 'react'
import PipelineOrderDetailsContent from '@Organism/Manager/Pipeline/PipelineOrderDetails/Content'
import PipelineOrderDetailsNavController from '@Organism/Manager/Pipeline/PipelineOrderDetails/NavController'
import { orderSync } from '@Services/manager/client'
import { Await, defer, useLoaderData } from 'react-router-dom'
import LoadingSkeleton from '../../Client/AddNewOrder/Skeleton'
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary'
import { getPipelineOrderDetails } from '@Services/manager/pipeline'

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const id = params?.get("i");

  const packageOrderSyncPromise = orderSync()
  const packagePipelineOrderDetailPromise = getPipelineOrderDetails(id)

  return defer({
    packageOrderSync: packageOrderSyncPromise,
    packageOrderDetails: packagePipelineOrderDetailPromise
  });

}

export default function PipelineOrderDetails() {
  const { packageOrderSync, packageOrderDetails } = useLoaderData()

  const [isCompleted, setIsCompleted] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [stepsOptions, setStepsOptions] = useState([
    {
      id: 1,
      label: "Contract Type",
      isDone: false,
    },
    {
      id: 2,
      label: "Work Scheme",
      isDone: false,
    },
    {
      id: 3,
      label: "Additional Information",
      isDone: false,
      warning: true,
    },
    {
      id: 4,
      label: "Man Power",
      isDone: false,
    },
    {
      id: 5,
      label: "Payment Method & Estimation",
      isDone: false,
    },
    {
      id: 6,
      label: "Deployment",
      isDone: false,
      warning: true,
    },
    {
      id: 7,
      label: "Finish",
      isDone: false,
    },
  ])
  const [maxStep, setMaxStep] = useState(7)

  const [contractType, setContractType] = useState(null);

  const handleClickStep = (step) => {
    if (maxStep >= step) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("pipelineStep", currentStep);
  }, [currentStep]);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Pipeline</title>
      </Helmet>
      <div className={Styles.navControlWrapper}>
        <PipelineOrderDetailsNavController
          isCompleted={isCompleted}
          stepsOptions={stepsOptions}
          currentStep={currentStep}
          contractType={contractType}
          handleClickStep={handleClickStep}
        />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <LoadingSkeleton />
        }>
          <Await resolve={packageOrderSync} errorElement={
            <div className={Styles.errorWrapper}>
              <AsyncErrorBoundary />
            </div>
          }>
            {(packageOrderSync) => (
              <Suspense fallback={
                <LoadingSkeleton />
              }>
                <Await resolve={packageOrderDetails} errorElement={
                  <div className={Styles.errorWrapper}>
                    <AsyncErrorBoundary />
                  </div>
                }>
                  {(packageOrderDetails) => (
                    <PipelineOrderDetailsContent
                      syncData={packageOrderSync?.response}
                      defaultOrderForm={packageOrderDetails?.response}
                      steps={stepsOptions}
                      setSteps={setStepsOptions}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      setMaxStep={setMaxStep}
                      setContractType={setContractType}
                      setIsCompleted={setIsCompleted}
                    />
                  )}
                </Await>

              </Suspense>

            )}

          </Await>
        </Suspense>

      </div>
    </div>
  );
}
