import LoadingAnimation from '@Atom/LoadingAnimation';
import { useDebounce } from '@Hooks/useDebounce';
import useQuery from '@Hooks/useQuery';
import RecruiterCandidateListLayout from '@Organism/Recruiter/RecruiterCandidateList';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';
import { fetchRecruiterCandidateLists, fetchRecruiterCandidateSync } from '@Services/officer/recruitment';
import { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Await, defer, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import Styles from './style.module.scss';

export const loader = async (route) => {
    try {
        const url = new URL(route?.request?.url);
        const limit = url.searchParams.get('l') || 5;
        const page = url.searchParams.get('p') || 1;
        const search = url.searchParams.get('n') || '';
        const position = url.searchParams.get('sp') || '';
        const path = url.pathname;

        console.log('path', path);

        const segments = path.split('-');
        const mode = segments.pop() || segments.pop();

        console.log('path mode', mode);
        const packageCandidateSyncPromise = fetchRecruiterCandidateSync();

        const query = `limit=${limit}&page=${page}&search=${search}&position=${position}`;

        const packageCandidateListPromise = fetchRecruiterCandidateLists(query, mode);

        return defer({
            packageCandidateSync: packageCandidateSyncPromise,
            packageCandidateList: packageCandidateListPromise,
        });
    } catch (err) {
        console.error(err);
    }
};

export default function RecruiterCandidateList({ mode = 'public' }) {
    const { packageCandidateList, packageCandidateSync } = useLoaderData();

    const query = useQuery();
    const defaultLimit = query.get('l') || 5;
    const defaultPage = query.get('p') || 1;
    const defaultSearch = query.get('n') || '';
    const defaultSearchPosition = query.get('sp') || '';

    const [limit, setLimit] = useState(defaultLimit);
    const [page, setPage] = useState(defaultPage);
    const [search, setSearch] = useState(defaultSearch);
    const [searchPosition, setSearchPosition] = useState(defaultSearchPosition);
    const [trigger, setTrigger] = useState('');

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearch = useDebounce(search, 300);
    const debouncedSearchPosition = useDebounce(searchPosition, 300);

    useEffect(() => {
        navigate(`${pathname}?l=${limit}&p=${page}&n=${debouncedSearch}&sp=${debouncedSearchPosition}`);
    }, [limit, page, search, searchPosition, debouncedSearch, debouncedSearchPosition, pathname, navigate, trigger]);

    return (
        <div className={Styles.container}>
            <Helmet>
                <title>Kerja 365 | Candidate List</title>
            </Helmet>
            <div className={Styles.contentWrapper}>
                <Suspense
                    fallback={
                        <div className={Styles.loadingWrapper}>
                            <LoadingAnimation />
                        </div>
                    }>
                    <Await
                        resolve={packageCandidateList}
                        errorElement={
                            <div style={{ height: 'calc(100vh - 220px)' }}>
                                <AsyncErrorBoundary />
                            </div>
                        }>
                        {(packageCandidateList) => (
                            <Suspense
                                fallback={
                                    <div className={Styles.loadingWrapper}>
                                        <LoadingAnimation />
                                    </div>
                                }>
                                <Await
                                    resolve={packageCandidateSync}
                                    errorElement={
                                        <div style={{ height: 'calc(100vh - 220px)' }}>
                                            <AsyncErrorBoundary />
                                        </div>
                                    }>
                                    {(packageCandidateSync) => (
                                        <RecruiterCandidateListLayout
                                            mode={mode}
                                            data={packageCandidateList?.response}
                                            sync={packageCandidateSync?.response}
                                            limit={limit}
                                            setLimit={setLimit}
                                            page={page}
                                            setPage={setPage}
                                            search={search}
                                            setSearch={setSearch}
                                            searchPosition={searchPosition}
                                            setSearchPosition={setSearchPosition}
                                            debouncedSearch={debouncedSearch}
                                            debouncedSearchPosition={debouncedSearchPosition}
                                            setTrigger={setTrigger}
                                        />
                                    )}
                                </Await>
                            </Suspense>
                        )}
                    </Await>
                </Suspense>
            </div>
        </div>
    );
}
