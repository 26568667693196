import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import ICON from "../../../../../assets/Images/login-icon.png";
import { verifyCandidate } from "@Services/support/candidate";

const RejoinModal = ({
  id,
  onClose,
  stage,
  setTrigger
}) => {
  const handleNext = async () => {
    try {
      await verifyCandidate('', stage, id, '', 'REJOIN')
      setTrigger(Math.random())
      onClose()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.title}>Rejoin Kandidat</div>
        <Icon size="24" icon="cross" className={Styles.icon} onClick={onClose} />
      </div>
      <div className={Styles.iconWrapper}>
        <img src={ICON} alt="" height={120} width={120} />
        <div className={Styles.text}>
          Apakah anda yakin ingin memasukan kembali kandidat
        </div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={onClose}>
          <div className={Styles.text}>Batal</div>
        </div>
        <div className={Styles.primaryButton} onClick={handleNext}>
          <div className={Styles.text}>Proses</div>
        </div>
      </div>
    </div>
  );
};

export default RejoinModal;
