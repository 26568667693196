import Icon from "@Atom/Icon";
import { formatNumber } from "@Helpers/formatNumber";
import { getSum } from "@Helpers/getSum";
import UseOutsideClick from "@Hooks/useOutsideClick";
import ManPowerChart from "@Organism/Manager/DashboardBM/ManPowerChart";
import ProcessCandidateChart from "@Organism/Manager/DashboardBM/ProcessCandidateChart";
import { getBMDashboardSync } from "@Services/manager/bm";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function DashboardSyncComponent({
  syncData,
  withBMAmount = false,
  isLoading = false,
}) {
  return (
    <div className={Styles.sync}>
      <div className={Styles.sTop}>
        {[
          {
            icon: "people",
            bgColor: "#FB8E19",
            label: "Business Manager",
            value: syncData?.totalBM ? formatNumber(syncData?.totalBM) : "-",
          },
          {
            icon: "company",
            bgColor: "#1571DE",
            label: "Klien",
            value: syncData?.totalClient
              ? formatNumber(syncData?.totalClient)
              : "-",
          },
          {
            icon: "people",
            bgColor: "#7F3BEF",
            label: "Permintaan Manpower",
            value: syncData?.totalQuota
              ? formatNumber(syncData?.totalQuota)
              : "-",
          },
          {
            icon: "search-2",
            bgColor: "#FFC700",
            label: "Vacant",
            value: syncData?.totalVacant
              ? formatNumber(syncData?.totalVacant)
              : "-",
          },
          {
            icon: "check-circle-2",
            bgColor: "#27BB83",
            label: "Pemenuhan Manpower",
            value: syncData?.totalFullfillment
              ? formatNumber(syncData?.totalFullfillment)
              : "-",
          },
          {
            icon: "target",
            bgColor: "#27B2BB",
            label: "Run Rate",
            value: syncData?.runRate ? formatNumber(syncData?.runRate) : "-",
          },
        ]
          ?.filter((obj) =>
            !withBMAmount ? obj?.label !== "Business Manager" : obj?.label
          )
          ?.map((i, iI) => (
            <div key={iI}>
              <div style={{ backgroundColor: i?.bgColor }}>
                <Icon icon={i?.icon} color={"#fff"} size={18} />
              </div>
              <span>{isLoading ? "Loading" : i?.value}</span>
              <p>{i?.label}</p>
            </div>
          ))}
      </div>
      <div className={Styles.sBottom}>
        {[
          {
            title: "Pemenuhan Manpower",
            middleText:
              Math.floor(
                (+syncData?.totalFullfillment || 0) /
                  (+syncData?.totalQuota || 0)
              ) *
                100 +
              "%",
            defaultData: [
              {
                label: "Vacant",
                bgColor: "#EDEDED",
                amount: !isLoading ? syncData?.totalVacant : 100,
              },
              {
                label: "Pemenuhan",
                bgColor: "#1571DE",
                amount: syncData?.totalFullfillment || 0,
              },
            ],
          },
          {
            title: "Performa",
            middleText: formatNumber(
              (+syncData?.totalFullfillment || 0) +
                (+syncData?.totalVendor || 0)
            ),
            defaultData: [
              {
                label: "Permata",
                bgColor: "#1571DE",
                amount: !isLoading ? syncData?.totalFullfillment : 100,
              },
              {
                label: "Vendor Lain",
                bgColor: "#FC86A2",
                amount: syncData?.totalVendor || 0,
              },
            ],
          },
        ]?.map((c, cI) => (
          <DashboardSyncDoughnutChart
            key={cI}
            title={c?.title}
            defaultData={c?.defaultData}
            middleText={c?.middleText}
            isLoading={isLoading}
          />
        ))}
        <DashboardSyncGraphicChart
          title="Kandidate Diproses"
          syncData={syncData}
        />
      </div>
    </div>
  );
}

export const DashboardSyncDoughnutChart = ({
  title = "",
  defaultData = [],
  middleText = "",
  isLoading,
}) => {
  return (
    <div className={Styles.dcContainer}>
      <span>{title}</span>
      <ManPowerChart
        defaultData={defaultData}
        middleText={middleText}
        isLoading={isLoading}
      />
      <div className={Styles.legends}>
        {defaultData?.map((d, dI) => (
          <div key={dI}>
            <div style={{ backgroundColor: d?.bgColor }} />
            <span>{d?.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DashboardSyncGraphicChart = ({ title = "", syncData }) => {
  const [data, setData] = useState(syncData);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBM, setSelectedBM] = useState(null);
  const [candidateStep, setCandidateStep] = useState(null);
  const [month, setMonth] = useState(null);
  const [expandBM, setExpandBM] = useState(false);
  const [expandStep, setExpandStep] = useState(false);
  const [expandMonth, setExpandMonth] = useState(false);

  const selectedOption = useMemo(() => {
    return data?.selectedOptionChart;
  }, [data?.selectedOptionChart]);

  useEffect(() => {
    const found = data?.monthOptions?.find(
      (obj) =>
        parseInt(obj?.monthSequence) === selectedOption?.month &&
        obj?.year === selectedOption?.year
    );
    if (found) {
      setMonth({
        label: `${found?.month} ${found?.year}`,
      });
    }
  }, [data?.monthOptions, selectedOption?.month, selectedOption?.year]);

  useEffect(() => {
    setCandidateStep({
      label: data?.selectedOptionChart?.step,
    });
  }, [data?.selectedOptionChart?.step]);

  const bmRef = useRef();
  const stepRef = useRef();
  const monthRef = useRef();

  const outsideBM = UseOutsideClick(bmRef);
  const outsideStep = UseOutsideClick(stepRef);
  const outsideMonth = UseOutsideClick(monthRef);

  useEffect(() => {
    if (expandBM && outsideBM) {
      setExpandBM(false);
    }
  }, [expandBM, outsideBM]);
  useEffect(() => {
    if (expandStep && outsideStep) {
      setExpandStep(false);
    }
  }, [expandBM, expandStep, outsideBM, outsideStep]);
  useEffect(() => {
    if (expandMonth && outsideMonth) {
      setExpandMonth(false);
    }
  }, [expandBM, expandMonth, outsideBM, outsideMonth]);

  const fixedMonth = useMemo(() => {
    return month
      ? data?.monthOptions?.find(
          (obj) => obj?.month === month?.label?.split(" ")[0]
        )?.monthSequence
      : "";
  }, [data?.monthOptions, month]);

  const fixedYear = useMemo(() => {
    return month ? month?.label?.split(" ")[1] : "";
  }, [month]);

  const refetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { response } = await getBMDashboardSync(
        "",
        fixedMonth,
        fixedYear,
        selectedBM?.employeeID ? [selectedBM?.employeeID] : []
      );
      setData(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err, "err on fetching chart");
    }
  }, [fixedMonth, fixedYear, selectedBM?.employeeID]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const dataBMList = useMemo(() => {
    if (data?.bmLists?.length > 0) {
      return data?.bmLists?.map((el) => {});
    } else {
      return [];
    }
  }, [data]);

  return (
    <div className={Styles.gcContainer}>
      <div className={Styles.gcHeader}>
        <span>{title}</span>
        <div>
          {[
            {
              value: selectedBM,
              setValue: setSelectedBM,
              options: [
                { label: "Semua BM", employeeID: "" },
                ...(data?.bmLists?.map((obj) => ({
                  label: obj?.User?.name,
                  employeeID: obj?.bmID,
                })) || []),
              ],
              placeholder: "Select BM",
              ref: bmRef,
              expand: expandBM,
              setExpand: setExpandBM,
            },
            {
              value: candidateStep,
              setValue: setCandidateStep,
              options: data?.candidateStep?.map((obj) => {
                return {
                  label: obj,
                };
              }),
              placeholder: "Select candidate step",
              ref: stepRef,
              expand: expandStep,
              setExpand: setExpandStep,
            },
            {
              value: month,
              setValue: setMonth,
              options: data?.monthOptions?.map((obj) => {
                return {
                  label: `${obj?.month} ${obj?.year}`,
                };
              }),
              placeholder: "Select month",
              ref: monthRef,
              expand: expandMonth,
              setExpand: setExpandMonth,
            },
          ]?.map((c, cI) => (
            <div
              ref={c?.ref}
              key={cI}
              className={Styles.selectWrapper}
              onClick={() => c?.setExpand(!c?.expand)}
            >
              <span>{c?.value?.label || c?.placeholder}</span>
              <Icon icon={"arrow-down"} size={24} />
              {c?.expand && (
                <div className={Styles.options}>
                  {c?.options?.map((o, oI) => (
                    <div key={oI} onClick={() => c?.setValue(o)}>
                      {o?.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <p>
        {data?.monthlyChart?.length
          ? getSum(data?.monthlyChart?.map((obj) => obj?.total))
          : 0}
        <span> Kandidat</span>
      </p>
      <ProcessCandidateChart
        arrData={data?.monthlyChart}
        isLoading={isLoading}
      />
    </div>
  );
};
