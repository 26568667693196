import Icon from "@Atom/Icon";
import useWindowSize from "@Hooks/useWindowSize";
import { Link } from "react-router-dom";
import Styles from "./style.module.scss";

export default function AddJobPostingHeader({
  setOpenPreview,
  selectedButtonTypePosting,
}) {
  const { width } = useWindowSize();
  // const handleClickBack = () => {};
  return (
    <div className={Styles.container}>
      <div className={Styles.topBox}>
        <div className={Styles.breadcrumbWrapper}>
          <Link to={"/job-posting"} className={Styles.span}>
            <Icon icon="arrow-left" size={width <= 768 ? 16 : 20} />
          </Link>
          <div className={Styles.breadcrumbTextBox}>
            <Link to={"/job-posting"} className={Styles.breadcrumbText}>
              Job Posting
            </Link>
            <Icon icon="arrow-right" size={20} />
            <span className={Styles.breadcrumbText2}>New Posting</span>
          </div>
        </div>
        <div className={Styles.postBox}>
          <span className={Styles.titleSpan}>Buat Postingan</span>
          {selectedButtonTypePosting === "Normal" ? (
            <div className={Styles.button} onClick={() => setOpenPreview(true)}>
              Lihat Preview
            </div>
          ) : (
            <div className={Styles.buttonDisabled}>Lihat Preview</div>
          )}
        </div>
      </div>

      {selectedButtonTypePosting === "Normal" ? (
        <div className={Styles.info}>
          <Icon icon={"information-solid"} size={24} />
          <span>
            Pastikan anda memasukan media konten pada halaman job posting
          </span>
        </div>
      ) : null}
    </div>
  );
}
