import LoadingAnimation from "@Atom/LoadingAnimation";
import InterviewInvitation from "@Organism/Additional/InterviewInvitation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getInvitatonData } from "@Services/officer/recruitment";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader({ params }) {
  const hash = params.hash;

  const invitationDataPromise = getInvitatonData(hash);

  return defer({
    packageInvitationData: invitationDataPromise,
  });
}

export default function InvitationSPA() {
  const { packageInvitationData } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Interview Invitation</title>
      </Helmet>
      <Suspense
        fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageInvitationData}
          errorElement={
            <div style={{ height: '100vh' }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageInvitationData) => (
            <InterviewInvitation defaultData={packageInvitationData?.response} />
          )}
        </Await>
      </Suspense>
    </div>
  );
}
