import SelectOptionsToggle from '@Molecule/SelectOptionToggle';
import Styles from './style.module.scss'
import { useEffect, useState } from 'react';

export default function BPJSSection(props) {
  const {
    form,
    setForm,
    options,
    setTrigger
  } = props;

  const [registration, setRegistration] = useState(form?.bpjs?.registration)
  const [paymentProof, setPaymentProof] = useState(form?.bpjs?.paymentProof)
  const [jkk, setJkk] = useState(form?.bpjs?.jkk)
  const [jkm, setJkm] = useState(form?.bpjs?.jkm)
  const [jht, setJht] = useState(form?.bpjs?.jht)
  const [jp, setJp] = useState(form?.bpjs?.jp)
  const [ks, setKs] = useState(form?.bpjs?.health)
  const [akdhk, setAkdhk] = useState(form?.bpjs?.akhdk)
  const [cutOffBPJSTKO, setCutOffBPJSTKO] = useState(form?.bpjs?.cutOffBPJSTKO)


  useEffect(() => {
    const newForm = form
    newForm.bpjs.registration = registration
    newForm.bpjs.paymentProof = paymentProof
    newForm.bpjs.jkk = jkk
    newForm.bpjs.jkm = jkm
    newForm.bpjs.jht = jht
    newForm.bpjs.jp = jp
    newForm.bpjs.health = ks
    newForm.bpjs.akhdk = akdhk
    newForm.bpjs.cutOffBPJSTKO = cutOffBPJSTKO
    setForm(newForm)
    setTrigger(Math.random())
  }, [
    form,
    setForm,
    registration,
    paymentProof,
    jkk,
    jkm,
    jht,
    jp,
    ks,
    akdhk,
    cutOffBPJSTKO,
    setTrigger
  ])


  return (
    <div className={Styles.container}>
      <h3>BPJS</h3>
      <div className={Styles.content}>
        <div className={Styles.top}>
          <div className={Styles.selectWrapper}>
            <SelectOptionsToggle
              title="Pendaftaran BPJS"
              options={options?.registration}
              placeholder={"Select"}
              value={registration}
              setValue={setRegistration}
              autoClose={true}
              required
            />
          </div>
          <div className={Styles.selectWrapper}>
            <SelectOptionsToggle
              title="Bukti Pembayaran BPJS"
              options={options?.paymentProof}
              placeholder={"Select"}
              value={paymentProof}
              setValue={setPaymentProof}
              autoClose={true}
              required
            />
          </div>
          <div className={Styles.selectWrapper}>
            <SelectOptionsToggle
              title="Potongan BPJS TKO"
              options={options?.cutOffBPJSTKO}
              placeholder={"Select"}
              value={cutOffBPJSTKO}
              setValue={setCutOffBPJSTKO}
              autoClose={true}
              required
            />
          </div>
        </div>
        <div className={Styles.middle}>
          <h4>Ketenagarjaan</h4>
          <div className={Styles.subContent}>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="JKK (Jaminan Kelengkapan Kerja)"
                options={options?.jkk}
                placeholder={"Select"}
                value={jkk}
                setValue={setJkk}
                withToggle={true}
                autoClose={true}
              />
            </div>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="JKM (Jaminan Kematian)"
                options={options?.jkm}
                placeholder={"Select"}
                value={jkm}
                setValue={setJkm}
                withToggle={true}
                autoClose={true}
              />
            </div>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="JHT (Jaminan Hari Tua)"
                options={options?.jht}
                placeholder={"Select"}
                value={jht}
                setValue={setJht}
                withToggle={true}
                autoClose={true}
              />
            </div>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="JP (Jaminan Pensiun)"
                options={options?.jp}
                placeholder={"Select"}
                value={jp}
                setValue={setJp}
                withToggle={true}
                autoClose={true}
              />
            </div>
          </div>

        </div>
        <div className={Styles.bottom}>
          <div className={Styles.boxWrapper}>
            <h4>Kesehatan</h4>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="KS (kesehatan)"
                options={options?.health}
                placeholder={"Select"}
                value={ks}
                setValue={setKs}
                withToggle={true}
                autoClose={true}
              />
            </div>
          </div>
          <div className={Styles.boxWrapper}>
            <h4>AKDHK</h4>
            <div className={Styles.selectSwitchWrapper}>
              <SelectOptionsToggle
                title="Asuransi Kecelakaan Diluar Hari Kerja"
                options={options?.akdhk}
                placeholder={"Select"}
                value={akdhk}
                setValue={setAkdhk}
                withToggle={true}
                autoClose={true}
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}