import Icon from '@Atom/Icon'
import FormEmptyPlaceholder from '@Molecule/_candidate/EmptyPlaceholder/FormEmptyPlaceholder'
import Images from '@Theme/Images'
import { useCallback, useEffect, useRef, useState } from 'react'
import NotEmpty from './NotEmpty'
import Styles from './style.module.scss'

export default function ThirdStep({
  form,
  setForm,
  options,
  errorField
}) {

  const onAdd = useCallback(() => {
    setForm([
      ...form,
      {
        id: Math.random(),
        startingYear: null,
        finishingYear: null,
        educationType: null,
        institution: null,
        faculty: null,
        certificateNumber: null,
        city: null
      }
    ])
  }, [form, setForm])

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);


  return (
    <div className={Styles.container}>
      <h3 ref={headerRef}>
        Data Pendidikan
        <span className={Styles.redStar}>*</span>
        </h3>
      <div className={Styles.content}>
        <div  style={{position: 'sticky', top: `${headerHeight}px`}} className={`${Styles.left} ${form?.length ? Styles.zero : ''}`}>
          <img src={Images?.EDUCATION_BANNER} alt="banner" className={form?.length ? Styles.none : ''} />
          <div className={Styles.buttonWrapper}>
            <button onClick={onAdd}>
              <Icon icon="add-circle" size={20} />
              <span>Data Pendidikan</span>
            </button>
          </div>
        </div>
        <div className={Styles.right}>
          {!form?.length
            ? <FormEmptyPlaceholder
              title={"Belum ada Data Pendidikan yang Ditambahkan"}
              description={"Tambahkan data pendidikan anda, klik pada tombol tambah data pendidikan"}
            />
            : <NotEmpty
              form={form}
              setForm={setForm}
              options={options}
              errorField={errorField}
            />
          }
        </div>
      </div>
    </div>
  )
}