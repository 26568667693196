import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss'
import useWindowSize from '@Hooks/useWindowSize';
import { DeleteButton, EmptyPlaceholder, LogoCard } from '../Third';
import Images from '@Theme/Images';
import { makeRandomString } from '@Helpers/makeRandomString';
import { Card, RightColumn } from '../Second';
import InputYearField from '@Molecule/InputYearField';
import InputField from '@Molecule/InputField';
import TextareaField from '@Molecule/TextAreaField';

export default function FifthStep({
  achievementData = [],
  setAchievementData = () => { },
  stepperHeight = '',
}) {

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const handleChangeAchievementData = (id, name, value) => {
    const newData = achievementData?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      }
      return obj
    })
    setAchievementData(newData)
  }

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const { width } = useWindowSize()

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef} style={{
        position: width > 768 ? 'sticky' : 'absolute',
        top: 0
        // top: `calc(${stepperHeight}px)`,
      }}>
        Data Prestasi
      </h3>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <LogoCard
            style={{
              //  top: `calc(${headerHeight + stepperHeight}px)`
              top: `calc(${headerHeight}px)`,
            }}
            img={Images.PERFORMANCE_BANNER}
            addTitle={'Data Pekerjaan'}
            onAdd={() => {
              const newData = [...achievementData, {
                id: makeRandomString(5),
                year: '',
                achievement: '',
                description: ''
              }]
              setAchievementData(newData)
            }}
            headerHeight={headerHeight}
            stepperHeight={stepperHeight}
          />
        </div>
        <RightColumn className={Styles.right}>
          {!achievementData?.length
            ?
            <EmptyPlaceholder
              title={'Belum ada Data Prestasi Ditambahkan'}
              desc={'Tambahkan data prestasi anda, klik pada tombol tambah data prestasi'}
              img={Images.PERFORMANCE_BANNER}
            />
            :
            achievementData?.map((ed, eI) => (
              <Card key={eI} className={`${Styles.card}`}>
                {
                  width <= 768
                  &&
                  <span>{`Data Prestasi ${eI + 1}`}</span>
                }
                <div className={Styles.grid}>
                  <InputYearField
                    title={'Tahun'}
                    value={ed?.year}
                    setValue={(newVal) => handleChangeAchievementData(ed?.id, 'year', newVal)}
                    placeholder={"Pilih tahun"}
                    iconColor={"#1571DE"}
                    required
                  />

                  <InputField
                    title={'Prestasi'}
                    value={ed?.achievement?.toUpperCase()}
                    onChange={(e) => handleChangeAchievementData(ed?.id, 'achievement', e?.target?.value)}
                    placeholder={'Tulis nama prestasi'}
                    required
                  />
                </div>
                <TextareaField
                  title={'Keterangan'}
                  value={ed?.description?.toUpperCase()}
                  onChange={(e) => handleChangeAchievementData(ed?.id, 'description', e?.target?.value)}
                  placeholder={'Keterangan prestasi'}
                  required

                />
                <DeleteButton
                  onDelete={() => setAchievementData(prev => prev?.filter(obj => obj?.id !== ed?.id))}
                />
              </Card>
            ))
          }
        </RightColumn>
      </div>

    </div>
  )
}