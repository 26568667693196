import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Styles from "./style.module.scss";
import useQuery from "@Hooks/useQuery";
import Icon from "@Atom/Icon";

export default function ContractTableHeader({
  title,
  amount,
  searchTerm,
  setSearchTerm,
  withSearchbar = true,
  onClickSearch = () => { },
  types = [],
  activeType,
  setActiveType = () => { },
  inputRef = null
}) {
  const query = useQuery()

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <div className={Styles.lTop}>
          <span>{title}</span>
          <div className={`${Styles.divider}  ${!query?.get('s') ? Styles.dNone : ''}`} />
          <div className={`${Styles.amount} ${!query?.get('s') ? Styles.dNone : ''}`}>{amount}</div>
        </div>
        <div className={Styles.lBottom}>
          {
            types?.map((t, tI) => (
              <button key={tI} className={activeType === t?.value ? Styles.active : ''} onClick={() => setActiveType(t?.value)}>
                {t?.label}
              </button>
            ))
          }
        </div>
      </div>
      {withSearchbar && (query?.get('s') || activeType === '1') && (
        <div className={Styles.right}>
          {
            activeType === '1'
            &&
            <div className={Styles.info}>
              <Icon icon={'information-solid'} size={24} color={'#1571DE'} />
              <p>
                Berikut daftar karyawan yang akan berakhir kontraknya pada <span>30 hari</span> kedepan
              </p>
            </div>
          }
          <div className={Styles.searchWrapper}>
            <OutlinedSearchBar
              inputRef={inputRef}
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={"Cari berdasarkan nama/NRK"}
              onClickIcon={onClickSearch}
            />

          </div>
        </div>
      )}
    </div>
  );
}
