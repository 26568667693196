import InputDate from '@Atom/InputDate'
import { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'


export default function SecondSubStep(props) {
  const { steps, setTrigger, form, setForm, locationIndex, manPowerIndex, onPrevious, onNext } = props

  const [fulfillmentDate, setFullfillmentDate] = useState(form[locationIndex].jobs[manPowerIndex].fulfillmentDate)

  useEffect(() => {
    const newForm = form
    newForm[locationIndex].jobs[manPowerIndex].fulfillmentDate = fulfillmentDate
    setForm(newForm)
  }, [form, setForm, locationIndex, manPowerIndex, fulfillmentDate])

  useEffect(()=>{
    const newSteps = [...steps]
    if (!fulfillmentDate) {
      newSteps[1].isDone = false
    } else {
      newSteps[1].isDone = true
    }
    setTrigger(Math.random())

    //eslint-disable-next-line
  }, [steps, fulfillmentDate])

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <h4>Fulfillment Date<span className={Styles.asterisk}>&#x2a;</span></h4>
        <div className={Styles.dateWrapper}>
          <InputDate value={fulfillmentDate} setValue={setFullfillmentDate} />
        </div>
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>
          <Icon icon="arrow-left" size={18} />
          <span>Back</span>
        </button>
        <button disabled={!fulfillmentDate} onClick={onNext}>
          <span>Next</span>
          <Icon icon="arrow-right" size={18} />
        </button>
      </div>
    </div>
  )
}