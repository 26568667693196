import Styles from './style.module.scss';

export default function SelectModal(props) {
  const {
    setManfeeMode,
    setShow
  } = props;

  const handleMode = (mode) => {
    setManfeeMode(mode)
    setShow(false)
  }


  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Man Fee</h3>
      </div>
      <div className={Styles.content}>
        <div onClick={()=> handleMode('Rp')}>
          <span>Rupiah</span>
        </div>
        <div onClick={()=> handleMode('%')}>
          <span>Percentage</span>
        </div>
      </div>

    </div>
  )
}