import Styles from "./multiSelectProfileLabel.module.scss";
import MultiSelect from "@Atom/MultiSelect";
import ProfileLabel from "@Atom/ProfileLabel";

const MultiSelectProfileLabel = ({
  title,
  options,
  labels,
  onAdd,
  onRemove,
  index,
  id,
  setLabels,
  type,
  handleIsPIC,
  handleNotPIC,
  ...props
}) => {
  const removeLabel = (name) => {
    if (onRemove) {
      onRemove(name, id, index);
    } else {
      const newArray = labels.filter((label) => label.name !== name);
      setLabels(newArray);
    }
  };

  const addLabel = (label) => {
    if (onAdd) {
      onAdd(label, id, index);
    } else {
      setLabels([...labels, label]);
    }
  };

  if (type === "pic") {
    return (
      <div className={Styles.container}>
        <div className={Styles.titleWrapper}>{title}</div>
        <div className={Styles.multiSelectWrapper}>
          <MultiSelect
            options={options}
            addLabel={addLabel}
            labels={labels}
            removeLabel={removeLabel}
          />
        </div>
        <div className={Styles.labelsWrapper}>
          {labels?.map((label, id) => {
            return (
              <ProfileLabel
                label={label}
                key={id}
                removeLabel={removeLabel}
                type={"pic"}
                handleIsPIC={() => handleIsPIC(label?._id, label?.locationId)}
                handleNotPIC={() => handleNotPIC(label?._id, label?.locationId)}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>{title}</div>
      <div className={Styles.multiSelectWrapper}>
        <MultiSelect
          options={options}
          addLabel={addLabel}
          labels={labels}
          removeLabel={removeLabel}
        />
      </div>
      <div className={Styles.labelsWrapper}>
        {labels?.map((label, id) => {
          return (
            <ProfileLabel label={label} key={id} removeLabel={removeLabel} />
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelectProfileLabel;
