import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import BLUE_BUBBLE from "@Assets/Images/blue-bubble-bg.png";
import THUMBNAIL from "@Assets/Images/temannya-irsyad-fajri.png";
import TipsAndTricksStatus from "@Molecule/TipsAndTricksStatus";
import { useState, useRef, useEffect } from "react";
import AVATAR from "@Assets/Images/ava-default.png";

const TipsAndTrickArticleDetail = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [onBlur, setOnBlur] = useState(true);
  const ref = useRef(null);

  const scrollEvent = (e) => {
    setScrollHeight(
      Math.floor(ref.current.scrollHeight - ref.current.clientHeight)
    );
    setScrollPosition(Math.round(e.target.scrollTop));
  };

  useEffect(() => {
    if (scrollPosition < scrollHeight) {
      setOnBlur(true);
    }
    if (scrollPosition >= scrollHeight) {
      setOnBlur(false);
    }
  }, [scrollPosition, scrollHeight]);

  useEffect(() => {
    setOnBlur(true);
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <div className={Styles.backButton}>
            <Icon icon="arrow-left" size="24" className={Styles.icon} />
          </div>
          <div className={Styles.navigation}>
            <div className={Styles.previousPageText}>Tips & Trick</div>
            <Icon icon="arrow-right" size="18" className={Styles.icon} />
            <div className={Styles.currentPageText}>New Post</div>
          </div>
        </div>
        <div className={Styles.title}>Detail Postingan</div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.detail}>
          <div className={Styles.thumbnail}>
            <div className={Styles.left}>
              <div className={Styles.articleTitle}>
                Tips Membuat CV Dengan Baik dan Benar
              </div>
              <div className={Styles.tag}>
                <div className={Styles.tagText}>Artikel</div>
              </div>
            </div>
            <div className={Styles.right}>
              <img alt="" src={BLUE_BUBBLE} />
              <div className={Styles.avatar}>
                <img alt="" src={THUMBNAIL} />
              </div>
            </div>
          </div>
          <div className={Styles.article} ref={ref} onScroll={scrollEvent}>
            <div className={Styles.statusWrapper}>
              <TipsAndTricksStatus status="active" />
            </div>
            <div className={Styles.content}>
              <div className={Styles.contentTitle}>Hallo Sobat PIS</div>
              <div className={Styles.contentText}>
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                sapien fringilla, mattis ligula consectetur, ultrices mauris.
                Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                Curabitur pellentesque nibh nibh, at maximus ante fermentum sit
                amet. Pellentesque commodo lacus at sodales sodales. Quisque
                sagittis orci ut diam condimentum, vel euismod erat placerat. In
                iaculis arcu eros, eget tempus orci facilisis id. Praesent lorem
                orci, mattis non efficitur id, ultricies vel nibh. Sed volutpat
                lacus vitae gravida viverra. Fusce vel tempor elit. Proin
                tempus, magna id scelerisque vestibulum, nulla ex pharetra
                sapien, tempor posuere massa neque nec felis. Aliquam sem ipsum,
                vehicula ac tortor vel, egestas ullamcorper dui. Curabitur at
                risus sodales, tristique est id, euismod justo. Mauris nec leo
                non libero sodales lobortis. Quisque a neque pretium, dictum
                tellus vitae, euismod neque. Nulla facilisi. Phasellus ultricies
                dignissim nibh ut cursus. Nam et quam sit amet turpis finibus
                maximus tempor eget augue. Aenean at ultricies lorem. Sed
                egestas ligula tortor, sit amet mattis ex feugiat non. Duis
                purus diam, dictum et ante at, commodo iaculis urna. Aenean
                lacinia, nisl id vehicula condimentum, enim massa tempor nibh,
                vitae volutpat sapien metus aliquet nisl. Etiam dui eros,
                tincidunt tristique blandit id, gravida vitae augue. Proin
                imperdiet mi nec justo convallis gravida. Pellentesque habitant
                morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Mauris consectetur nibh in mattis commodo. Etiam in
                pellentesque eros. Etiam tempus fermentum tincidunt. Duis sit
                amet nisl consectetur, pharetra nibh vitae, egestas urna. Nam
                auctor mauris ut diam viverra vehicula. Aenean congue faucibus
                nunc nec ornare. Proin sem risus, convallis quis volutpat et,
                sollicitudin at urna. Duis molestie odio orci, vel auctor velit
                posuere scelerisque. Vestibulum eget dictum libero. Nam finibus
                turpis ac eleifend suscipit. Vivamus auctor sollicitudin
                placerat. Etiam turpis ligula, lobortis id fringilla quis,
                rhoncus a quam. Nulla commodo, lacus vel placerat aliquam, velit
                quam mollis lacus, gravida iaculis eros orci sit amet augue. Sed
                semper ac lectus sed dapibus. Duis non tortor arcu. “Aenean
                euismod, purus sit amet vestibulum maximus, augue sem fermentum
                arcu, ac elementum dui libero at urna. Quisque vitae pharetra
                ligula. Vestibulum sollicitudin,” odio in dictum condimentum,
                enim sem placerat nunc, vitae dictum arcu nunc eget quam. Ut
                quis massa eget lectus condimentum porttitor sed ultricies
                ipsum. Duis est lectus, bibendum eget suscipit quis, scelerisque
                vitae augue. In a risus a odio pellentesque lacinia nec eget
                dui. Nunc mollis arcu sed tortor laoreet eleifend. Phasellus id
                justo laoreet, pulvinar est mattis, sodales mauris. Orci varius
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Pellentesque eget augue cursus, vulputate velit
                ut, rhoncus eros. Nullam rhoncus id nulla non rhoncus. Donec
                molestie lorem vel varius condimentum. Phasellus at condimentum
                erat. Praesent eu ullamcorper elit. Fusce tempor neque neque,
                commodo mattis sapien finibus et. Proin venenatis nisl dui, eget
                posuere justo bibendum vel. Morbi in ullamcorper risus. Aliquam
                eu tortor lorem. Duis mattis ligula eu dolor. Vivamus auctor
                sollicitudin placerat. Etiam turpis ligula, lobortis id
                fringilla quis, rhoncus a quam. Nulla commodo, lacus vel
                placerat aliquam, velit quam mollis lacus, gravida iaculis eros
                orci sit amet augue. Sed semper ac lectus sed dapibus. Duis non
                tortor arcu. “Aenean euismod, purus sit amet vestibulum maximus,
                augue sem fermentum arcu, ac elementum dui libero at urna.
                Quisque vitae pharetra ligula. Vestibulum sollicitudin,” odio in
                dictum condimentum, enim sem placerat nunc, vitae dictum arcu
                nunc eget quam. Ut quis massa eget lectus condimentum porttitor
                sed ultricies ipsum. Duis est lectus, bibendum eget suscipit
                quis, scelerisque vitae augue. In a risus a odio pellentesque
                lacinia nec eget dui. Nunc mollis arcu sed tortor laoreet
                eleifend. Phasellus id justo laoreet, pulvinar est mattis,
                sodales mauris. Orci varius natoque penatibus et magnis dis
                parturient montes, nascetur ridiculus mus. Pellentesque eget
                augue cursus, vulputate velit ut, rhoncus eros. Nullam rhoncus
                id nulla non rhoncus. Donec molestie lorem vel varius
                condimentum. Phasellus at condimentum erat. Praesent eu
                ullamcorper elit. Fusce tempor neque neque, commodo mattis
                sapien finibus et. Proin venenatis nisl dui, eget posuere justo
                bibendum vel. Morbi in ullamcorper risus. Aliquam eu tortor
                lorem. Duis mattis ligula eu dolor.
              </div>
              <div className={Styles.creatorWrapper}>
                <div className={Styles.creator}>
                  <div className={Styles.picture}>
                    <img alt="" src={AVATAR} />
                  </div>
                  <div className={Styles.creatorName}>Lionel Messi</div>
                </div>
                <div className={Styles.createdAt}>•</div>
                <div className={Styles.createdAt}>15 Nov 23 </div>
              </div>
            </div>
            {onBlur && (
              <div className={Styles.blur}>
                <div className={Styles.creatorWrapper}>
                  <div className={Styles.creator}>
                    <div className={Styles.picture}>
                      <img alt="" src={AVATAR} />
                    </div>
                    <div className={Styles.creatorName}>Lionel Messi</div>
                  </div>
                  <div className={Styles.createdAt}>•</div>
                  <div className={Styles.createdAt}>15 Nov 23 </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipsAndTrickArticleDetail;
