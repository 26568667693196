import Images from '@Theme/Images'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'

export default function CandidateIDCard({
  photoProfile,
  name,
  position,
  phoneNumber
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={Images.K365_DARKER} alt='' />
      </div>
      <div className={Styles.content}>
        <img src={photoProfile || Images.PP_DEFAULT} alt='' />
        <div className={Styles.desc}>
          <h3>{name}</h3>
          <span>{`Posisi: ${position}`}</span>
          <div className={Styles.contact}>
            <Icon icon={'phone-outline'} size={20} />
            <span>{phoneNumber}</span>
          </div>
        </div>
      </div>
      <img src={Images.DOUBLE_WAVE} alt='' />
    </div>
  )
}