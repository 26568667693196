import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import Styles from "./style.module.scss";

export default function AllowanceField({
  title,
  value,
  setValue,
  type,
  setType = () => {},
  placeholder = "Masukkan nominal",
  onDelete,
  typeInput,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.input}>
        <Input
          value={value}
          // eslint-disable-next-line react-hooks/exhaustive-deps

          // onChange={useCallback((newVal) => setValue(newVal), [setValue])}
          onChange={(newVal) => setValue(newVal)}
          placeholder={placeholder}
          isCurrency
        />
        <Dropdown
          value={type}
          setValue={setType}
          placeholder={"Harian/Mingguan/Bulanan"}
          options={[
            {
              name: "harian",
            },
            {
              name: "mingguan",
            },
            {
              name: "bulanan",
            },
          ]}
          autoClose
        />
        <div>
          <button onClick={onDelete}>
            <Icon icon={"trash"} size={24} />
          </button>
        </div>
      </div>
    </div>
  );
}
