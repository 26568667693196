import ContractInclude from "@Molecule/ContractInclude";
import Styles from "./style.module.scss";
import { useMemo } from "react";
import Icon from "@Atom/Icon";

export default function ThirdStep({
  additionals,
  setAdditionals,
  selectedAdditionalsTemplates,
  setSelectedAdditionalsTemplates,
  additionalValues,
  setAdditionalValues,
  c2cAdditionals,
  setC2cAdditionals,
  searchTerm,
  selectedContractType
}) {
  const filteredOptions = useMemo(() => {
    return additionals?.filter((el) =>
      el?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  }, [additionals, searchTerm]);

  const handleChangeValue = (obj, body, value) => {
    if (body.source === "365") {
      setAdditionalValues((prev) =>
        prev.map((el) =>
          el.contractTemplateID === obj.contractTemplateID &&
          el.code === obj.code
            ? { ...el, value: value }
            : el
        )
      );
    } else {
      setC2cAdditionals((prev) =>
        prev.map((el) =>
          el.addition === body.template
            ? {
                ...el,
                params: el.params.map((el1) =>
                  el1.code === obj.code || el1.codeLocal === obj?.codeLocal
                    ? { ...el1, value: value }
                    : el1
                ),
              }
            : el
        )
      );
    }
  };

  const findParams = (each) => {
    if (each.source === "C2C") {
      if (c2cAdditionals.find((el) => el.addition === each.template)) {
        return c2cAdditionals.find((el) => el.addition === each.template)
          ?.params;
      } else {
        return [];
      }
    } else {
      return additionalValues.filter((el) => el.contractTemplateID === each.id);
    }
  };

  // console.log(filteredOptions);

  return (
      <div className={Styles.container}>
          <div className={Styles.information}>
              <Icon icon={'duplicate-document'} size={'20px'} color={'#757575'} />
              <p>
                  {'Daftar kontrak tambahan : '}
                  <span className={Styles.infoContractType}>{selectedContractType}</span>
              </p>
          </div>

          {filteredOptions?.map((each, i) => (
              <ContractInclude
                  key={i}
                  name={each?.name}
                  isChecked={selectedAdditionalsTemplates?.find((obj) => obj.name === each?.name)}
                  setAdditionals={setAdditionals}
                  setIsChecked={() => {
                      const found = selectedAdditionalsTemplates.find((obj) => obj.name === each?.name);
                      if (found) {
                          setSelectedAdditionalsTemplates(
                              [...selectedAdditionalsTemplates]?.filter((obj) => obj.name !== each?.name)
                          );
                      } else {
                          setSelectedAdditionalsTemplates([...selectedAdditionalsTemplates, each]);
                      }
                  }}
                  filteredAdditionalValues={findParams(each)}
                  handleChangeValues={handleChangeValue}
                  body={each}
              />
          ))}
      </div>
  );
}
