import Styles from "./style.module.scss";
import ICON from "../../../../../assets/Images/exclamation.png";

const RejectConfirmationModal = ({
  setShowReject
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={ICON} alt="" height={120} width={120} />
      </div>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Konfirmasi Reject</div>
        <div className={Styles.text}>
          Apakah anda yakin mereject applicant ini?
        </div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={() => setShowReject(0)}>
          <div className={Styles.text}>Batal</div>
        </div>
        <div className={Styles.primaryButton} onClick={() => setShowReject(2)}>
          <div className={Styles.text}>Lanjutkan</div>
        </div>
      </div>
    </div>
  );
};

export default RejectConfirmationModal;
