import InputDateField from '@Molecule/InputDateField'
import Styles from './style.module.scss'
import { useEffect, useState } from 'react'
import InputField from '@Molecule/InputField'

export default function PeriodSection(props) {
  const { form, setForm, setTrigger } = props;
  const [startDate, setStartDate] = useState(form?.cooperationPeriod?.startDate)
  const [endDate, setEndDate] = useState(form?.cooperationPeriod?.endDate)
  const [documentNumber, setDocumentNumber] = useState(form?.cooperationPeriod?.documentNumber)
  const onChangeDocNumber = (e) => {
    setDocumentNumber(e.target.value)
  }

  useEffect(() => {
    const newForm = form
    newForm.cooperationPeriod.startDate = startDate
    newForm.cooperationPeriod.endDate = endDate
    newForm.cooperationPeriod.documentNumber = documentNumber
    setForm(newForm)
    setTrigger(Math.random())
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
  }, [startDate, endDate, documentNumber, form, setForm, setTrigger])


  return (
    <div className={Styles.container}>
      <h3>Periode Kerjasama</h3>

      <div className={Styles.content}>
        <div className={Styles.datePickerWrapper}>
          <InputDateField value={startDate} title="Tanggal Mulai" setValue={setStartDate} required />
        </div>
        <div className={Styles.datePickerWrapper}>
          <InputDateField value={endDate} title="Tanggal Berakhir" setValue={setEndDate} required />
        </div>
        <div className={Styles.documentWrapper}>
          <InputField title="Nomor Dokumen (PKS/SPK/WO/PO)" value={documentNumber} onChange={onChangeDocNumber} placeholder={"Nomor dokumen"} required />
        </div>

      </div>
    </div>
  )
}