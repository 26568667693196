export function joinArrayToString(array) {
    if (!Array.isArray(array)) {
        return array;
    }
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        return array.join(',');
    }
}
