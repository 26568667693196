import Styles from "./style.module.scss";

const Active = () => {
  return (
    <div className={Styles.container} style={{ backgroundColor: "#EAFEF3" }}>
      <div className={Styles.dot} style={{ backgroundColor: "#27BB83" }}></div>
      <div className={Styles.text}>Aktif</div>
    </div>
  );
};

const Draft = () => {
  return (
    <div className={Styles.container} style={{ backgroundColor: "#FFFDD9" }}>
      <div className={Styles.dot} style={{ backgroundColor: "#E5DD1C" }}></div>
      <div className={Styles.text}>Draft</div>
    </div>
  );
};

const Inactive = () => {
  return (
    <div className={Styles.container} style={{ backgroundColor: "#F5f5f5" }}>
      <div className={Styles.dot} style={{ backgroundColor: "#c2c2c2" }}></div>
      <div className={Styles.text}>Tidak Aktif</div>
    </div>
  );
};

const TipsAndTricksStatus = ({ status, ...props }) => {
  if (status === "active") {
    return <Active />;
  }
  if (status === "inactive") {
    return <Inactive />;
  }
  if (status === "draft") {
    return <Draft />;
  }
  return <Active />;
};

export default TipsAndTricksStatus;
