/* eslint-disable no-unused-vars */

import Doc, {
  loader as flbLoader,
  pdfPreviewLoader,
  logHistoryLoader,
} from "@Organism/Manager/Doc";
import BagusSandbox from "@Organism/Sandbox/Bagus";
import HogieSandbox from "@Organism/Sandbox/Hogie";
import RiskasSandbox from "@Organism/Sandbox/Riska";
import Tasya from "@Organism/Sandbox/Tasya";
import ErrorBoundary from "@Organism/Utils/ErrorBoundary";
import NotFoundPage from "@Organism/Utils/NotFound";
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import BmContractView, {
  loader as contratViewLoader,
} from "@Pages/Additional/BMContractView";
import CandidateContract, {
  loader as candidateContractLoader,
} from "@Pages/Additional/CandidateContract";
import CandidateForm, {
  loader as candidateFormLoader,
} from "@Pages/Additional/CandidateForm";
import CandidatesRecommendationSPA, {
  loader as candidatesRecommendationLoader,
} from "@Pages/Additional/CandidatesRecommendation";
import DocView from "@Pages/Additional/DocView";
import InvitationSPA, {
  loader as invitationLoader,
} from "@Pages/Additional/InvitationSPA";
import JobApplicationForm, {
  loader as jobApplicationLoader,
} from "@Pages/Additional/JobApplicationForm";
import ClientAssignmentSPA, {
  loader as clientAssignmentLoader,
} from "@Pages/Additional/NewClientAssignment";
import OfferTransition, {
  loader as LoaderOfferTransition,
} from "@Pages/Additional/OfferTransition";
import PrivacyPolicyPage from "@Pages/Additional/PrivacyPolicy";
import RenewData, {
  loader as candidateRenewLoader,
} from "@Pages/Additional/RenewData";
import CandidateDetails, {
  loader as candidateDetailsLoader,
} from "@Pages/Auth/BusinessSupport/Candidates/CandidateDetails";
import CandidateList, {
  loader as candidateListLoader,
} from "@Pages/Auth/BusinessSupport/Candidates/CandidateList";
import ContractList from "@Pages/Auth/BusinessSupport/Contracts/ContractList";
import PendingContractList, {
  loader as pendingContractLoader,
} from "@Pages/Auth/BusinessSupport/Contracts/PendingContractList";
import EmployeeDetail, {
  loader as employeeDetailLoader,
} from "@Pages/Auth/Client/Employee/EmployeeDetail";
import EmployeeList, {
  loader as employeeListLoader,
} from "@Pages/Auth/Client/Employee/EmployeeList";
import ManPowerRequestSummaryClient, {
  loader as manPowerRequestSummaryLoaderClient,
} from "@Pages/Auth/Client/ManPowerRequestSummary";
import ManPowerOutsource, {
  loader as mpoLoader,
} from "@Pages/Auth/Client/Order/ManPoweOutsource";
import ClientOrderDetails, {
  loader as clientOrderDetailsLoader,
} from "@Pages/Auth/Client/Orders/OrderDetails";
import ClientOrderList, {
  loader as clientOrderListLoader,
} from "@Pages/Auth/Client/Orders/OrderList";
import Dashboard, { loader as dashboardLoader } from "@Pages/Auth/Dashboard";
import HumanCapitalContractList, {
  loader as HumanCapitalContractListLoader,
} from "@Pages/Auth/HumanCapital/Contracts/ContractList";
import CreateContractPage, {
  loader as createContractPageLoader,
} from "@Pages/Auth/HumanCapital/CreateContract";
import EditTemplatePage, {
  loader as editContractPageLoader,
} from "@Pages/Auth/HumanCapital/EditDraft";
import FLBVerificationListPage, {
  loader as flbVerificationLoader,
} from "@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationList";
import FLBVerificationDetailsPage, {
  loader as flbVerificationDetailsLoader,
} from "@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationDetails";
import ApprovalList, {
  loader as approvalListLoader,
} from "@Pages/Auth/Manager/Approval/ApprovalList";
import AddNewClient from "@Pages/Auth/Manager/Client/AddNewClient";
import AddNewOrder, {
  loader as addOrderLoader,
} from "@Pages/Auth/Manager/Client/AddNewOrder";
import ClientDetail, {
  loader as clientDetailLoader,
} from "@Pages/Auth/Manager/Client/ClientDetail";
import ClientList, {
  loader as clientListLoader,
} from "@Pages/Auth/Manager/Client/ClientList";
import ManPowerRequestSummary, {
  loader as manPowerRequestSummaryLoader,
} from "@Pages/Auth/Manager/Client/ManPowerRequestSummary";
import SchemeEditForm, {
  loader as schemeEditLoader,
} from "@Pages/Auth/Manager/Client/WorkSchemes/EditScheme";
import SchemeDetail, {
  loader as schemeDetailLoader,
} from "@Pages/Auth/Manager/Client/WorkSchemes/SchemeDetail";
import ClientWorkSchemes, {
  loader as workSchemeLoader,
} from "@Pages/Auth/Manager/Client/WorkSchemes/SchemeList";
import PipelineList, {
  loader as pipelineListLoader,
} from "@Pages/Auth/Manager/Pipeline/PipelineList";
import PipelineOrder, {
  loader as pipelineOrderLoader,
} from "@Pages/Auth/Manager/Pipeline/PipelineOrder";
import PipelineOrderDetails, {
  loader as pipelineOrderDetailsLoader,
} from "@Pages/Auth/Manager/Pipeline/PipelineOrderDetails";
import ChatPage from "@Pages/Auth/Recruiter/Chat";
import AddJobPostingPage, {
  loader as addJobPostingLoader,
} from "@Pages/Auth/Recruiter/JobPosting/AddJobPosting";
import JobPostingListPage, {
  loader as jobPostingListLoader,
} from "@Pages/Auth/Recruiter/JobPosting/JobPostingList";
import PipelinePage, {
  loader as loaderPipeline,
} from "@Pages/Auth/Recruiter/Pipeline";
import PipelineDetailPage, {
  loader as loaderPipelineDetail,
} from "@Pages/Auth/Recruiter/PipelineDetail";
import CreateTipsAndTricksPage from "@Pages/Auth/Recruiter/Recruitment/CreateTipsAndTricks";
import RecruitmentAreaDetail from "@Pages/Auth/Recruiter/Recruitment/RecruitmentAreaDetail";
import RecruitmentDetail, {
  loader as recruitmentDetailLoader,
} from "@Pages/Auth/Recruiter/Recruitment/RecruitmentDetail";
import RecruitmentList, {
  loader as recruitmentListLoader,
} from "@Pages/Auth/Recruiter/Recruitment/RecruitmentList";
import TipsAndTrickDetailPage from "@Pages/Auth/Recruiter/Recruitment/TipsAndTrickDetail";
import { loader as tipsLoader } from "@Pages/Auth/Recruiter/Recruitment/TipsAndTricks";
import BusinessHomePage from "@Pages/Home/Business";
import ClientForgotPassword from "@Pages/Home/ForgotPassword";
import JobSeakerDetailPage, {
  loader as loaderJobSeekerList,
} from "@Pages/Home/JobSeakerDetails";
import JobSeekersHomePage, {
  loader as loaderJobSeekerHome,
} from "@Pages/Home/JobSeeker";
import ClientLogin from "@Pages/Home/Login";
import ClientRegister from "@Pages/Home/Register";
import Login, { loader as LoginLoader } from "@Pages/Login";
import Logout from "@Pages/Logout";
import { addClientSyncOptions } from "@Services/manager/client";
import App, { loader as AppLoader, toolsLoader } from "App";
import RecruiterCandidateList, {
  loader as recruiterCandidateListLoader,
} from "@Pages/Auth/Recruiter/RecruiterCandidateList";
import SchemeProceedForm, {
  loader as schemeProceedLoader,
} from "@Pages/Auth/Manager/Client/WorkSchemes/ProceedScheme";
import JobPostingDetailPage, {
  loader as JobPostingDetailLoader,
} from "@Pages/Auth/Recruiter/JobPosting/JobPostingDetail";
import RecruitmentListPage, {
  loader as recruitmentListPageLoader,
} from "@Pages/Auth/Recruiter/Recruitment/RecruitmentListPage";
import ExtendContract, {
  loader as extendContractLoader,
} from "@Pages/Auth/BusinessSupport/Contracts/ExtendContract";
import BMListPage, { loader as bmListLoader } from "@Pages/Auth/Manager/BM";
import PendingContractDetail, {
  loader as pendingContractDetailLoader,
} from "@Pages/Auth/BusinessSupport/Contracts/PendingContractDetail";
import CompleteContractList, {
  loader as completeContractDetailLoader,
} from "@Pages/Auth/BusinessSupport/Contracts/CompleteContractList";

// import { element } from "prop-types";

const {
  createBrowserRouter,
  redirect,
  Outlet,
  defer,
} = require("react-router-dom");

const tools = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    loader: toolsLoader,
    children: [
      {
        index: true,
        loader: dashboardLoader,
        element: <Dashboard />,
      },
      {
        loader: () => {
          const isBM =
            localStorage.getItem("role") === "BUSINESS MANAGER" ||
            localStorage.getItem("role") === "REGION BUSINESS MANAGER" ||
            localStorage.getItem("role") === "BUSINESS DIRECTOR"
              ? true
              : false;
          if (!isBM) {
            throw redirect("/");
          }
          return null;
        },
        element: <Outlet />,
        children: [
          {
            path: "clients",
            children: [
              {
                index: true,
                loader: clientListLoader,
                element: <ClientList />,
              },
              {
                path: "add-new-client",
                loader: async () => {
                  return defer({
                    packageSync: addClientSyncOptions(),
                  });
                },
                element: <AddNewClient />,
              },
              {
                path: "details",
                children: [
                  {
                    index: true,
                    loader: clientDetailLoader,
                    errorElement: <ErrorBoundary />,
                    element: <ClientDetail />,
                  },
                  {
                    path: "man-power-request",
                    loader: manPowerRequestSummaryLoader,
                    element: <ManPowerRequestSummary />,
                  },
                  {
                    path: "candidate",
                    element: <UnderMaintenacePage />,
                  },
                ],
              },

              {
                path: "orders",
                children: [
                  {
                    index: true,
                    loader: workSchemeLoader,
                    element: <ClientWorkSchemes />,
                  },
                  {
                    path: "edit",
                    loader: schemeEditLoader,
                    element: <SchemeEditForm />,
                  },
                  {
                    path: "proceed",
                    loader: schemeProceedLoader,
                    element: <SchemeProceedForm />,
                  },
                  {
                    path: "details/:id",
                    loader: schemeDetailLoader,
                    element: <SchemeDetail />,
                  },
                ],
              },
              {
                path: "add-new-order",
                loader: addOrderLoader,
                errorElement: <ErrorBoundary />,
                element: <AddNewOrder />,
              },
            ],
          },
          {
            path: "pipeline",
            children: [
              {
                index: true,
                loader: pipelineListLoader,
                element: <PipelineList />,
              },
              {
                path: "order",
                children: [
                  {
                    index: true,
                    loader: pipelineOrderLoader,
                    element: <PipelineOrder />,
                  },
                  {
                    path: "details",
                    loader: pipelineOrderDetailsLoader,
                    element: <PipelineOrderDetails />,
                  },
                ],
              },
            ],
          },
          {
            path: "approval",
            children: [
              {
                index: true,
                loader: approvalListLoader,
                element: <ApprovalList />,
              },
            ],
          },
          {
            path: "daftar-bm",
            children: [
              {
                index: true,
                loader: bmListLoader,
                element: <BMListPage />,
              },
              {
                path: ":id",
                children: [
                  {
                    index: true,
                    loader: clientListLoader,
                    element: <ClientList rbmView />,
                  },
                  {
                    path: "details",
                    children: [
                      {
                        index: true,
                        loader: clientDetailLoader,
                        errorElement: <ErrorBoundary />,
                        element: <ClientDetail rbmView />,
                      },
                      {
                        path: "man-power-request",
                        loader: manPowerRequestSummaryLoader,
                        element: <ManPowerRequestSummary />,
                      },
                      {
                        path: "candidate",
                        element: <UnderMaintenacePage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "tambah-lokasi",
            children: [
              {
                index: true,
                element: <UnderMaintenacePage />,
              },
            ],
          },
          {
            path: "recruitment",
            children: [
              {
                index: true,
                element: <UnderMaintenacePage />,
              },
            ],
          },
        ],
      },
      {
        loader: () => {
          const isRO =
            localStorage.getItem("role") === "RECRUITMENT OFFICER"
              ? true
              : false;
          if (!isRO) {
            throw redirect("/");
          }
          return null;
        },
        element: <Outlet />,
        children: [
          {
            path: "rekrutmen",
            children: [
              {
                index: true,
                loader: recruitmentListPageLoader,
                // errorElement: <ErrorBoundary />,
                element: <RecruitmentListPage />,
              },
              {
                path: "area",
                errorElement: <ErrorBoundary />,
                element: <RecruitmentAreaDetail />,
              },
              {
                path: "details",
                loader: recruitmentDetailLoader,
                errorElement: <ErrorBoundary />,
                element: <RecruitmentDetail />,
              },
            ],
          },
          {
            path: "jadwal",
            element: <UnderMaintenacePage />,
          },
          {
            path: "job-posting",
            children: [
              {
                index: true,
                loader: jobPostingListLoader,
                element: <JobPostingListPage />,
              },
              {
                path: "new/:id",
                loader: addJobPostingLoader,
                element: <AddJobPostingPage />,
              },
              {
                path: ":id",
                loader: JobPostingDetailLoader,
                element: <JobPostingDetailPage />,
              },
            ],
          },
          // {
          //   path: "pipeline-list",
          //   children: [
          //     {
          //       index: true,
          //       loader: loaderPipeline,
          //       element: <PipelinePage />,
          //     },
          //     {
          //       path: ":id",
          //       loader: loaderPipelineDetail,
          //       element: <PipelineDetailPage />,
          //     },
          //   ],
          // },
          {
            path: "klien",
            element: <UnderMaintenacePage />,
          },
          {
            path: "chat",
            element: <ChatPage />,
          },
          {
            path: "tips-&-trick",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <UnderMaintenacePage />,
                loader: tipsLoader,
              },
              {
                path: "new-post",
                element: <CreateTipsAndTricksPage />,
              },
              {
                path: "article/:id",
                element: <TipsAndTrickDetailPage type="article" />,
              },
              {
                path: "video/:id",
                element: <TipsAndTrickDetailPage type="video" />,
              },
            ],
          },
          {
            path: "tugas",
            element: <UnderMaintenacePage />,
          },
          {
            path: "laporan",
            element: <UnderMaintenacePage />,
          },
          {
            path: "daftar-kandidat-public",
            children: [
              {
                index: true,
                loader: recruiterCandidateListLoader,
                element: <RecruiterCandidateList mode="public" />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "daftar-kandidat-private",
            children: [
              {
                index: true,
                loader: recruiterCandidateListLoader,
                element: <RecruiterCandidateList mode="private" />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
          {
            path: "daftar-kandidat-unprocessed",
            children: [
              {
                index: true,
                loader: recruiterCandidateListLoader,
                element: <RecruiterCandidateList mode="unprocessed" />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
        ],
      },
      {
        loader: () => {
          const isBS =
            localStorage.getItem("role") === "BUSINESS SUPPORT" ? true : false;
          if (!isBS) {
            throw redirect("/");
          }
          return null;
        },
        element: <Outlet />,
        children: [
          {
            path: "candidates",
            children: [
              {
                index: true,
                element: <CandidateList />,
                loader: candidateListLoader,
                // element: <>test</>
              },
              {
                path: ":id",
                element: <CandidateDetails />,
                loader: candidateDetailsLoader,
              },
            ],
          },
          {
            path: "data",
            children: [
              {
                path: "contracts",
                element: <ContractList />,
              },
              {
                path: "contracts/:employeeId",
                element: <ExtendContract />,
                loader: extendContractLoader,
              },
              {
                path: "pending-contracts",
                element: <PendingContractList />,
                loader: pendingContractLoader,
              },
              {
                path: "pending-contracts/:employeeId",
                element: <PendingContractDetail />,
                loader: pendingContractDetailLoader,
              },
              {
                path: "complete-contracts",
                element: <CompleteContractList />,
                loader: completeContractDetailLoader,
              },
            ],
          },
        ],
      },
      {
        loader: () => {
          const isHC =
            localStorage.getItem("role") === "HUMAN CAPITAL MANAGER"
              ? true
              : false;
          if (!isHC) {
            throw redirect("/");
          }
          return null;
        },
        element: <Outlet />,
        children: [
          {
            path: "drafts-contract",
            children: [
              {
                index: true,
                element: <HumanCapitalContractList isUnderMaintenance />,
                loader: HumanCapitalContractListLoader,
                // element: <>test</>
              },
              {
                path: ":id",
                element: <CandidateDetails />,
                loader: candidateDetailsLoader,
              },
              {
                path: "update-draft",
                element: <EditTemplatePage />,
                loader: editContractPageLoader,
              },
            ],
          },
          {
            path: "user",
            element: <UnderMaintenacePage />,
          },
          {
            path: "new-draft",
            loader: createContractPageLoader,
            element: <CreateContractPage />,
          },
          {
            path: "flb-verification",
            children: [
              {
                index: true,
                loader: flbVerificationLoader,
                element: <FLBVerificationListPage />,
              },
              {
                path: ":id",
                loader: flbVerificationDetailsLoader,
                element: <FLBVerificationDetailsPage />,
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        element: <UnderMaintenacePage />,
      },
      {
        path: "profile",
        element: <UnderMaintenacePage />,
      },
    ],
  },
  {
    path: "/sign-in",
    element: <Login />,
    loader: LoginLoader,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/job-application",
    children: [
      {
        index: true,
        loader: jobApplicationLoader,
        element: <JobApplicationForm />,
      },
      {
        path: ":hash",
        loader: jobApplicationLoader,
        element: <JobApplicationForm />,
      },
    ],
  },
  {
    path: "/candidate-invitation/:hash",
    loader: invitationLoader,
    element: <InvitationSPA />,
  },
  {
    path: "/candidate-offer/:hash",
    loader: candidateFormLoader,
    element: <CandidateForm />,
  },
  {
    path: "/offer-transition/:hash",
    element: <OfferTransition />,
    loader: LoaderOfferTransition,
  },
  {
    path: "/workorder/:hash",
    element: <Doc />,
    errorElement: <ErrorBoundary />,
    loader: flbLoader,
  },
  {
    path: "view",
    element: <DocView />,
  },
  {
    path: "/assign/:hash",
    element: <ClientAssignmentSPA />,
    errorElement: <ErrorBoundary />,
    loader: clientAssignmentLoader,
  },
  {
    path: "/candidate-recomendation/:hash",
    loader: candidatesRecommendationLoader,
    element: <CandidatesRecommendationSPA />,
  },
  {
    path: "/clients/orders/pdf-preview/:id",
    loader: pdfPreviewLoader,
    element: <Doc />,
  },
  {
    path: "/flb-history/:id",
    loader: logHistoryLoader,
    element: <Doc />,
  },
  {
    path: "/contract-approval/:hash",
    loader: contratViewLoader,
    element: <BmContractView />,
  },
  {
    path: "/candidate-contract/:hash",
    loader: candidateContractLoader,
    errorElement: <ErrorBoundary />,
    element: <CandidateContract />,
  },
  {
    path: "/update-data/:hash",
    errorElement: <ErrorBoundary />,
    loader: candidateRenewLoader,
    element: <RenewData />,
  },
  {
    path: "/sign-example",
    element: <HogieSandbox />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const client = [
  {
    path: "/",
    element: <App />,
    loader: AppLoader,
    children: [
      {
        index: true,
        element: <JobSeekersHomePage />,
        loader: loaderJobSeekerHome,
      },
      {
        path: "business",
        element: <BusinessHomePage />,
      },
      {
        path: "job-seeker",
        loader: loaderJobSeekerList,
        element: <JobSeakerDetailPage />,
      },

      {
        path: "login",
        element: <ClientLogin />,
      },
      {
        path: "register",
        element: <ClientRegister />,
      },
      {
        path: "forgot-password",
        element: <ClientForgotPassword />,
      },
      {
        path: "dashboard",
        children: [
          {
            index: true,
            loader: dashboardLoader,
            element: <Dashboard />,
          },
          {
            path: "man-power-request",
            loader: manPowerRequestSummaryLoaderClient,
            element: <ManPowerRequestSummaryClient />,
          },
          {
            path: "orders",
            children: [
              {
                index: true,
                loader: clientOrderListLoader,
                element: <ClientOrderList />,
              },
              {
                path: "details/:id",
                loader: clientOrderDetailsLoader,
                element: <ClientOrderDetails />,
              },
            ],
          },
        ],
      },

      {
        path: "employee",
        children: [
          {
            index: true,
            element: <EmployeeList />,
            loader: employeeListLoader,
          },
          {
            path: "details",
            errorElement: <ErrorBoundary />,
            loader: employeeDetailLoader,
            element: <EmployeeDetail />,
          },
        ],
      },
      {
        path: "order",
        children: [
          {
            path: "manpower-outsource",
            element: <ManPowerOutsource />,
            loader: mpoLoader,
          },
        ],
      },
      {
        path: "settings",
        element: <UnderMaintenacePage />,
      },
      {
        path: "profile",
        element: <UnderMaintenacePage />,
      },
    ],
  },
  {
    path: "view",
    element: <DocView />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  // {
  //   path: "/job-application",
  //   // loader: LoginLoader,
  //   element: <JobApplicationForm />,
  // },

  // {
  //   path: '/candidate-recommendation/:hash',
  //   loader: candidatesRecommendationLoader,
  //   element: <CandidatesRecommendationSPA />
  // },
];

const sandbox = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "grid",
              placeItems: "center",
              color: "#9e9e9e",
            }}
          >
            #componentHunt
          </div>
        ),
      },
      {
        path: "riska",
        element: <RiskasSandbox />,
      },
      {
        path: "hogie",
        element: <HogieSandbox />,
      },
      {
        path: "tasya",
        element: <Tasya />,
      },
      {
        path: "bagus",
        element: <BagusSandbox />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const renewData = [
  {
    path: "/update-data/:hash",
    errorElement: <ErrorBoundary />,
    loader: candidateRenewLoader,
    element: <RenewData />,
  },
];

export const router = createBrowserRouter(
  // client
  tools
  // sandbox
);
