import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function ErrorModal({ error, handleDone, onBack }) {
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);
  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);
  return (
    <div className={Styles.containerError}>
      <div className={Styles.textError} ref={boxRef}>
        <Icon
          icon={"cross-circle"}
          size={20}
          color={"#d90707"}
          className={Styles.errorIcon}
          onClick={onBack}
        />
        {error}
      </div>
    </div>
  );
}
