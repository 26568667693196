import { useEffect } from "react";
import Styles from "./styles.module.scss";

const dataMethod = [
  {
    id: 1,
    name: "WFA",
    code: "WFA",
  },
  {
    id: 2,
    name: "WFO",
    code: "WFO",
  },
];

const dataType = [
  {
    id: 1,
    name: "Internal",
    code: "Internal",
  },
  {
    id: 2,
    name: "Outsource",
    code: "Outsource",
  },
];

const dataSystem = [
  {
    id: 1,
    name: "Full Time",
    code: "Full Time",
  },
  {
    id: 2,
    name: "Part Time",
    code: "Part Time",
  },
  {
    id: 3,
    name: "Freelance",
    code: "Freelance",
  },
];

export default function AgreementStep({
  selectedButtonMethod,
  setSelectedButtonMethod,
  selectedButtonType,
  setSelectedButtonType,
  selectedButtonSystem,
  setSelectedButtonSystem,
  validate,
  data,
}) {
  const setActiveButtonMethod = (idButton) => {
    setSelectedButtonMethod(idButton);
    // sessionStorage.setItem("idButtonTypePosting", idButton);
  };

  useEffect(() => {
    if (selectedButtonMethod) {
      setSelectedButtonMethod(selectedButtonMethod);
    } else {
      setSelectedButtonMethod("");
    }
  }, [selectedButtonMethod, setSelectedButtonMethod]);

  const setActiveButtonType = (idButton) => {
    setSelectedButtonType(idButton);
    // sessionStorage.setItem("idButtonTypePosting", idButton);
  };

  useEffect(() => {
    if (selectedButtonType) {
      setSelectedButtonType(selectedButtonType);
    } else {
      setSelectedButtonType("");
    }
  }, [selectedButtonType, setSelectedButtonType]);

  const setActiveButtonSystem = (idButton) => {
    setSelectedButtonSystem(idButton);
    // sessionStorage.setItem("idButtonTypePosting", idButton);
  };

  useEffect(() => {
    if (selectedButtonSystem) {
      setSelectedButtonSystem(selectedButtonSystem);
    } else {
      setSelectedButtonSystem("");
    }
  }, [selectedButtonSystem, setSelectedButtonSystem]);

  useEffect(() => {
    if (data && data?.workType) {
      setSelectedButtonType(data?.workType);
    } else {
      setSelectedButtonType("");
    }
  }, [data, setSelectedButtonType]);

  return (
    <div className={Styles.container}>
      <div>
        <span>
          Metode Kerja <span>*</span>
        </span>
        <div>
          {dataMethod?.map((el, index) => {
            return (
              <div
                key={index}
                onClick={() => setActiveButtonMethod(el?.code)}
                className={`${
                  selectedButtonMethod === el?.code && Styles.active
                } ${
                  validate?.selectedButtonMethod &&
                  !selectedButtonMethod &&
                  Styles.redBorder
                }`}
              >
                {el?.name || "-"}
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <span>
          Tipe Pekerjaan <span>*</span>
        </span>
        <div>
          {dataType?.map((el, index) => {
            return (
              <div
                key={index}
                onClick={() => setActiveButtonType(el?.code)}
                className={`${
                  selectedButtonType === el?.code && Styles.active
                } ${
                  validate?.selectedButtonType &&
                  !selectedButtonType &&
                  Styles.redBorder
                }`}
              >
                {el?.name || "-"}
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <span>
          Sistem Kerja <span>*</span>
        </span>
        <div>
          {dataSystem?.map((el, index) => {
            return (
              <div
                key={index}
                onClick={() => setActiveButtonSystem(el?.code)}
                className={`${
                  selectedButtonSystem === el?.code && Styles.active
                } ${
                  validate?.selectedButtonSystem &&
                  !selectedButtonSystem &&
                  Styles.redBorder
                }`}
              >
                {el?.name || "-"}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
