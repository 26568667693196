import Icon from "@Atom/Icon";
import Styles from "./button.module.scss";

const Primary = ({ title, disabled, onClick, ...props }) => {
  return (
    <div
      className={`${Styles.container} ${
        !disabled ? Styles.primary : Styles.primaryDisabled
      }`}
      onClick={!disabled ? onClick : () => {}}
    >
      {title}
    </div>
  );
};

const SecondaryBlack = ({ title, disabled, onClick, ...props }) => {
  return (
    <div
      className={`${Styles.container} ${Styles.secondaryBlack}`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

const SecondaryBlue = ({ title, disabled, onClick, ...props }) => {
  return (
    <div
      className={`${Styles.container} ${Styles.secondaryBlue}`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};
const SecondaryBlueTransparent = ({ title, disabled, onClick, ...props }) => {
  return (
    <div
      className={`${Styles.container} ${Styles.secondaryBlueTransparent} ${
        disabled && Styles.disabled
      }`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

const CandidateTab = ({
  title,
  disabled,
  onClick,
  isActive,
  width,
  ...props
}) => {
  return (
    <button
      className={`${isActive ? Styles.active : ""} ${
        Styles.candidateTabButton
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

const ButtonWithIcon = ({
  title,
  disabled,
  onClick,
  isActive,
  icon,
  ...props
}) => {
  return (
    <button
      className={`${isActive ? Styles.active : ""} ${Styles.buttonWithIcon}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <span>{title}</span>
      <Icon icon={icon} size={20} />
    </button>
  );
};

const Button = ({
  title,
  type,
  disabled,
  onClick,
  isActive,
  icon,
  width,
  ...props
}) => {
  switch (type) {
    case "primary":
      return (
        <Primary
          {...props}
          disabled={disabled}
          title={title}
          onClick={onClick}
        />
      );
    case "secondary-black":
      return <SecondaryBlack {...props} title={title} onClick={onClick} />;
    case "secondary-blue":
      return <SecondaryBlue {...props} title={title} onClick={onClick} />;
    case "secondary-blue-transparent":
      return (
        <SecondaryBlueTransparent
          {...props}
          title={title}
          onClick={onClick}
          disabled={disabled}
        />
      );
    case "candidate-tab":
      return (
        <CandidateTab
          {...props}
          title={title}
          onClick={onClick}
          isActive={isActive}
          width={width}
        />
      );
    case "button-icon":
      return (
        <ButtonWithIcon
          {...props}
          title={title}
          onClick={onClick}
          isActive={isActive}
          icon={icon}
          disabled={disabled}
        />
      );
    default:
      return (
        <Primary
          {...props}
          disabled={disabled}
          title={title}
          onClick={onClick}
        />
      );
  }
};

export default Button;

export const RefreshButton = ({ onClick = () => {} }) => {
  return (
    <div className={Styles.refreshButton} onClick={onClick}>
      <span>Muat Ulang</span>
      <Icon icon={"refresh"} size={20} />
    </div>
  );
};
