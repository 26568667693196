import Styles from "./styles.module.scss";

export default function DetailRemarkModal({ data, handleClose }) {
  return (
    <div className={Styles.remarkContainer}>
      <div className={Styles.title}>Catatan</div>
      <div className={Styles.remark}>{data?.remark || "-"}</div>
      <div className={Styles.close} onClick={handleClose}>Tutup</div>
    </div>
  );
}
