import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useEffect, useState } from "react";

export default function SuccessApprovalModal({
  message,
  handleDone,
  onBack,
  cancelType = "icon",
}) {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const newTimer = setTimeout(() => {
      handleDone();
    }, 5000);
    setTimer(newTimer);
  }, [handleDone]);

  const handleCancel = () => {
    if (timer) {
      clearTimeout(timer);
    }
    onBack();
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.text}>
        <div className={Styles.content}>
          <Icon icon={"check-circle-outline"} color={"#27bb83"} size={20} />
          {message}
        </div>
        {cancelType === "icon" ? (
          <Icon
            icon={"cross"}
            size={20}
            color={"#ffffff"}
            onClick={handleCancel}
            className={Styles.icon}
          />
        ) : cancelType === "button" ? (
          <button className={Styles.cancelButton} onClick={handleCancel}>
            Batalkan
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
