import { Link } from "react-router-dom";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

export default function ProfileSection({ data, setEditInfoMode, isClientService = false }) {
  return (
    <div className={Styles.profileSection}>
      <div className={Styles.header}>
        <h3>Company Information</h3>
        {!isClientService && <button onClick={() => setEditInfoMode(true)}>
          <Icon icon="edit" size={20} />
        </button>
        }
      </div>
      <div className={Styles.content}>
        <div className={Styles.boxProfile}>
          <h4>Nama</h4>
          <p>{data?.name || '-'}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Nama Resmi Perusahaan (terdaftar secara legal)</h4>
          <p>{data?.legalName || '-'}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Nama Group</h4>
          <p>{data?.groupName || '-'}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Nama Brand</h4>
          <p>{data?.brandName || '-'}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Industri</h4>
          <p>{data?.industry || '-'}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Website</h4>
          <Link to={data?.website} target="_blank" rel="noopener noreferrer">
            <p className={Styles.website}>{data?.website}</p>
          </Link>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Nomor Telepon</h4>
          <p>{data?.phoneNumber}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Alamat </h4>
          <p>{data?.address}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Provinsi</h4>
          <p>{data?.province}</p>
        </div>
        <div className={Styles.boxProfile}>
          <h4>Kode Pos</h4>
          <p>{data?.postal}</p>
        </div>
      </div>
    </div>
  );
}
