import Images from '@Theme/Images';
import axios from 'axios';
import Styles from './style.module.scss';

// eslint-disable-next-line
const viewFile = async (url) => {
  const response = await axios.get(url, {
    method: "GET",
    responseType: "blob",
  });

  const file = new Blob([response.data], {
    type: "application/pdf",
  });

  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};


const DocumentCard = ({
  title,
  document
}) => {

  return (
    <div className={Styles.docCard}>
      <h4>{title}</h4>
      <div className={Styles.content}>
        <img src={document ? Images?.NOTE_SHEET : Images.FOLDER} alt="logo" />
        <p className={document ? Styles.withDocument : Styles.noDocument}>{document?.url || 'No file yet'}</p>
        {document ? (
          <button onClick={()=>window.open(`/view?u=${document?.url}`,'_blank', 'rel=noopener noreferrer')}>See File</button>
          // <button onClick={()=> viewFile(`${fileBaseUrl}${document?.url}`)}>See File</button>
        ) : (
          <span>Still no uploaded file</span>
        )}
      </div>
      
    </div>
  )
}

export default DocumentCard