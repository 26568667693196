/* eslint-disable no-unused-vars */
import SearchBar from '@Atom/SearchBar';
import Styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import Icon from '@Atom/Icon';

const Desktop = (props) => {
  const {
    searchTerm,
    onChange,
    isSelected,
    handleSelectedQualification,
    onCancel,
    onAdd,
    qualifications
  } = props;

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h4>Add Qualification</h4>
          <div className={Styles.searchbarWrapper}>
            <SearchBar value={searchTerm} onChange={onChange} placeholder="Search Qualification" />
          </div>
        </div>
        <div className={Styles.content}>
          {qualifications?.filter((el) => el?.name?.toLowerCase().includes(searchTerm.toLowerCase()))?.length > 0
            ?
            <>
              {
                qualifications
                  ?.filter((el) => el?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                  ?.map((each, i) => {
                    const isItemSelected = Boolean(isSelected(each))
                    return (
                      <div key={i} className={Styles.each} onClick={() => handleSelectedQualification(each)}>
                        <label>
                          {each?.name}
                        </label>
                        <Checkbox
                          checked={isItemSelected}
                          sx={{ margin: 0, padding: 0, zIndex: 0 }}
                          icon={<Icon icon="radio-button-default" size={20} className={Styles.unchecked} />}
                          checkedIcon={<Icon icon="radio-button-active" size={20} className={Styles.checked} />} />
                      </div>
                    )
                  })
              }
            </>
            :
            <div className={Styles.noOption}>
              No such location
            </div>
          }


        </div>
        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel}>
            <span>Cancel</span>
          </button>
          <button onClick={onAdd}>
            <span>Add</span>
          </button>
        </div>
      </div>

    </div>
  )
}
const Mobile = (props) => {
  const {
    isSelected,
    handleSelectedQualification,
    onCancel,
    onAdd,
    qualifications
  } = props;

  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Add Qualification</h3>
        </div>

      </div>
      <div className={Styles.content}>
        {
          qualifications
            ?.map((each, i) => {
              const isItemSelected = Boolean(isSelected(each))
              return (
                <div key={i} className={Styles.each} onClick={() => handleSelectedQualification(each)}>
                  <label>
                    {each?.name}
                  </label>
                  <Checkbox
                    checked={isItemSelected}
                    sx={{ margin: 0, padding: 0, zIndex: 0 }}
                    icon={<Icon icon="radio-button-default" size={20} className={Styles.unchecked} />}
                    checkedIcon={<Icon icon="radio-button-active" size={20} className={Styles.checked} />} />
                </div>
              )
            })
        }

      </div>
      <div className={Styles.actionsWrapper}>
        <button onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button onClick={onAdd}>
          <span>Add</span>
        </button>
      </div>
    </div>
  )
}

export default function AddQualificationModal(props) {
  const {
    type,
    setShowAdd,
    form,
    setForm,
    locationIndex,
    manPowerIndex,
    qualifications,
    trigger,
    setTrigger,
    preventDeleteIndicator,
  } = props;
  const [searchTerm, setSearchTerm] = useState('')
  const [qualificationArr, setQualificationArr] = useState([])

  useEffect(() => {
    setQualificationArr(form[locationIndex]?.jobs[manPowerIndex]?.qualifications)
  }, [form, locationIndex, manPowerIndex, trigger])

  const onChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSelectedQualification = (option) => {
    const data = [...qualificationArr]
    const isExist = data?.find((item) => item?.name === option?.name);

    if (!isExist) {
      data.push({ ...option })
    } else {
      if (!isExist?.[preventDeleteIndicator]) {
        const index = data?.findIndex(object => {
          return object.name === option?.name;
        });
        data.splice(index, 1)
      }

    }

    setQualificationArr(data)
  }

  const onCancel = () => {
    setShowAdd(false)
  }


  const onAdd = () => {
    form[locationIndex].jobs[manPowerIndex].qualifications = qualificationArr
    setShowAdd(false)
  }

  const isSelected = (option) => qualificationArr?.find((item) => item?.name === option?.name);
  switch (type) {
    case "mobile":
      return <Mobile
        searchTerm={searchTerm}
        onChange={onChange}
        handleSelectedQualification={handleSelectedQualification}
        onCancel={onCancel}
        onAdd={onAdd}
        isSelected={isSelected}
        qualifications={qualifications}
      />
    case "desktop":
      return <Desktop
        searchTerm={searchTerm}
        onChange={onChange}
        handleSelectedQualification={handleSelectedQualification}
        onCancel={onCancel}
        onAdd={onAdd}
        isSelected={isSelected}
        qualifications={qualifications}
      />
    default:
      return <Desktop
        searchTerm={searchTerm}
        onChange={onChange}
        handleSelectedQualification={handleSelectedQualification}
        onCancel={onCancel}
        onAdd={onAdd}
        isSelected={isSelected}
        qualifications={qualifications}
      />
  }
}