import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import SearchBar from "@Atom/SearchBar";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function AllowanceModal({
  show,
  setShow,
  allowances,
  setAllowances,
  options = [],
  contractList,
  selectedItems,
  setSelectedItems,
  handleCheckboxChange,
  handleChecked,
  newData,
  handleSubmit,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const [allowancesTemp, setAllowancesTemp] = useState(allowances);

  useEffect(() => {
    setAllowancesTemp(allowances);
  }, [show, allowances]);

  // eslint-disable-next-line no-unused-vars
  const handleClick = (item) => {
    const found = allowancesTemp?.find((obj) => obj?.name === item);
    if (found) {
      if (!found?.value) {
        setAllowancesTemp(allowancesTemp?.filter((obj) => obj?.name !== item));
      }
    } else {
      setAllowancesTemp([
        ...allowancesTemp,
        { name: item, value: "", type: "" },
      ]);
    }
  };

  const filteredOptions = useMemo(() => {
    return contractList?.allAllowances?.filter((el) =>
      el?.value?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  }, [contractList?.allAllowances, searchTerm]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Tunjangan</h3>
        <div className={Styles.searchWrapper}>
          <SearchBar
            value={searchTerm}
            onChange={(e) => setSearchTerm(e?.target?.value)}
            placeholder={"Cari tunjangan yang tersedia"}
          />
        </div>
      </div>

      <div className={Styles.content}>
        {filteredOptions?.map((el, i) => {
          return (
            <div key={i}>
              <span>{el?.value}</span>
              <CheckboxJobPosting
                checked={handleChecked(el)}
                onChange={() => {
                  handleCheckboxChange(el);
                  // setIsActive(false);
                }}
              />
              {/* <Radio
                isChecked={allowancesTemp?.find(
                  (obj) => obj?.value === el?.value
                )}
              /> */}
            </div>
          );
        })}
        {/* {options?.map((option, i) => (
          <div
            key={i}
            onClick={() => handleClick(option)}
          >
            <span>{option}</span>
            <Radio
              isChecked={allowancesTemp?.find(obj => obj?.name === option)}
            />
          </div>
        ))} */}
      </div>

      <div className={Styles.footer}>
        <button onClick={() => setShow(false)}>Batalkan</button>
        <button onClick={handleSubmit}>Tambah</button>
      </div>
    </div>
  );
}
