import AutocompleteField from "@Molecule/AutocompleteField";
import CheckboxField from "@Molecule/CheckboxField";
import InputField from "@Molecule/InputField";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";

export default function AddressSection({
  form,
  setForm,
  isDomicileTheSame,
  setIsDomicileTheSame,
  options,
  disableInput,
  errorField,
}) {
  const handleChange = (e, objectTitle, inputName) => {
    let targetValue;
    const re = /^[0-9\b]+$/;
    if (inputName === "rt" || inputName === "rw" || inputName === "postal") {
      if (e.target.value === "" || re.test(e.target.value)) {
        targetValue = !isNaN(e.target.value)
          ? e.target.value
          : e.target.value.toUpperCase();
      }
    } else {
      targetValue = !isNaN(e.target.value)
        ? e.target.value
        : e.target.value.toUpperCase();
    }

    if (targetValue || targetValue === "") {
      setForm({
        ...form,
        [objectTitle]: {
          ...form?.[objectTitle],
          [inputName]: targetValue,
        },
      });
    }
  };

  const [idCardRegencyTemp, setIdCardRegencyTemp] = useState(
    form?.idCard?.regency || ""
  );
  const [idCardProvinceTemp, setIdCardProvinceTemp] = useState(
    form?.idCard?.province
  );

  const [domicileRegencyTemp, setDomicileRegencyTemp] = useState(
    form?.domicile?.regency || ""
  );
  const [domicileProvinceTemp, setDomicileProvinceTemp] = useState(
    form?.domicile?.province
  );

  useEffect(() => {
    setIdCardRegencyTemp(form?.idCard?.regency || "");
    setIdCardProvinceTemp(form?.idCard?.province || "");
    setDomicileRegencyTemp(form?.domicile?.regency || "");
    setDomicileProvinceTemp(form?.domicile?.province || "");
  }, [
    form?.idCard?.regency,
    form?.idCard?.province,
    form?.domicile?.regency,
    form?.domicile?.province,
  ]);

  useEffect(() => {
    setForm({
      ...form,
      idCard: {
        ...form?.idCard,
        regency: idCardRegencyTemp,
        province: idCardProvinceTemp,
      },
      domicile: {
        ...form?.domicile,
        regency: domicileRegencyTemp,
        province: domicileProvinceTemp,
      },
    });
    // eslint-disable-next-line
  }, [
    idCardRegencyTemp,
    idCardProvinceTemp,
    domicileRegencyTemp,
    domicileProvinceTemp,
  ]);

  useEffect(() => {
    if (isDomicileTheSame === true) {
      setForm({
        ...form,
        domicile: {
          ward: "",
          subdistrict: "",
          regency: "",
          province: "",
        },
      });
      setDomicileRegencyTemp("");
      setDomicileProvinceTemp("");
    }
    // eslint-disable-next-line
  }, [isDomicileTheSame, domicileRegencyTemp, domicileProvinceTemp]);

  return (
    <div className={Styles.container}>
      <h3>Alamat</h3>
      <div className={Styles.section}>
        <h4>Alamat KTP</h4>
        <InputField
          title="Alamat "
          type={"address"}
          value={form?.idCard?.address}
          placeholder={"Tulis alamat anda"}
          onChange={(e) => handleChange(e, "idCard", "address")}
          hideIcon
          required
          disabled={disableInput}
          isError={errorField && !form?.idCard?.address}
          errorText={"*Alamat belum diisi"}
        />
        <div className={Styles.content}>
          <InputField
            title={"RT"}
            placeholder={"000"}
            value={form?.idCard?.rt}
            onChange={(e) => handleChange(e, "idCard", "rt")}
            required
            disabled={disableInput}
            isError={errorField && !form?.idCard?.rt}
            errorText={"*RT belum diisi"}
          />
          <InputField
            title={"RW"}
            placeholder={"000"}
            value={form?.idCard?.rw}
            onChange={(e) => handleChange(e, "idCard", "rw")}
            required
            disabled={disableInput}
            isError={errorField && !form?.idCard?.rw}
            errorText={"*RW belum diisi"}
          />
          <InputField
            title={"Kelurahan"}
            placeholder={"Tulis kelurahan anda"}
            value={form?.idCard?.ward}
            onChange={(e) => handleChange(e, "idCard", "ward")}
            required
            disabled={disableInput}
            isError={errorField && !form?.idCard?.ward}
            errorText={"*Kelurahan belum diisi"}
          />
          {/* <AutocompleteField
            title={"Kelurahan"}
            placeholder={"Pilih kelurahan"}
            searchOptions={kelurahanOptions}
            value={idCardWardTemp}
            setValue={setIdCardWardTemp}
            iconColor={"#1571DE"}
          /> */}
          <InputField
            title={"Kecamatan"}
            placeholder={"Tulis kecamatan anda"}
            value={form?.idCard?.subdistrict}
            onChange={(e) => handleChange(e, "idCard", "subdistrict")}
            required
            disabled={disableInput}
            isError={errorField && !form?.idCard?.subdistrict}
            errorText={"*Kecamatan belum diisi"}
          />
          {/* <AutocompleteField
            title={"Kecamatan"}
            placeholder={"Pilih kecamatan"}
            searchOptions={kecamatanOptions}
            value={idCardSubDistrictTemp}
            setValue={setIdCardSubDistrictTemp}
            iconColor={"#1571DE"}
          /> */}
          <AutocompleteField
            title={"Kabupaten/Kota"}
            placeholder={"Pilih kabupaten"}
            searchOptions={options?.cities}
            value={idCardRegencyTemp}
            setValue={setIdCardRegencyTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !idCardRegencyTemp}
            errorText={"*Kabupaten/Kota belum diisi"}
          />
          <AutocompleteField
            title={"Provinsi"}
            placeholder={"Pilih provinsi"}
            searchOptions={options?.provinces}
            value={idCardProvinceTemp}
            setValue={setIdCardProvinceTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !idCardProvinceTemp}
            errorText={"*Provinsi belum diisi"}
          />
          <InputField
            title={"Kode Pos"}
            placeholder={"000"}
            value={form?.idCard?.postal}
            onChange={(e) => handleChange(e, "idCard", "postal")}
            required
            disabled={disableInput}
            isError={errorField && !form?.idCard?.postal}
            errorText={"*Kode Pos belum diisi"}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            type="number"
          />
        </div>
      </div>
      <div className={Styles.section}>
        <h4>Alamat Domisili</h4>
        <div className={Styles.checkboxWrapper}>
          <CheckboxField
            title={"Alamat domisili sama dengan alamat KTP"}
            value={isDomicileTheSame}
            setValue={setIsDomicileTheSame}
            disabled={disableInput}
          />
        </div>
        <div className={Styles.textAreaWrapper}>
          <InputField
            title="Alamat "
            type={"address"}
            value={form?.domicile?.address}
            placeholder={"Tulis alamat anda"}
            onChange={(e) => handleChange(e, "domicile", "address")}
            disabled={isDomicileTheSame || disableInput}
            hideIcon
            required
            isError={
              errorField && !form?.domicile?.address && !isDomicileTheSame
            }
            errorText={"*Alamat domisili belum diisi"}
          />
        </div>

        <div className={Styles.content}>
          <InputField
            title={"RT"}
            placeholder={"000"}
            value={form?.domicile?.rt}
            onChange={(e) => handleChange(e, "domicile", "rt")}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={errorField && !form?.domicile?.rt && !isDomicileTheSame}
            errorText={"*RT domisili belum diisi"}
          />
          <InputField
            title={"RW"}
            placeholder={"000"}
            value={form?.domicile?.rw}
            onChange={(e) => handleChange(e, "domicile", "rw")}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={errorField && !form?.domicile?.rw && !isDomicileTheSame}
            errorText={"*RW domisili belum diisi"}
          />
          <InputField
            title={"Kelurahan"}
            placeholder={"Tulis kelurahan anda"}
            value={form?.domicile?.ward}
            onChange={(e) => handleChange(e, "domicile", "ward")}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={errorField && !form?.domicile?.ward && !isDomicileTheSame}
            errorText={"*Kelurahan domisili belum diisi"}
          />
          {/* <AutocompleteField
            title={"Kelurahan"}
            placeholder={"Pilih kelurahan"}
            searchOptions={kelurahanOptions}
            value={domicileWardTemp}
            setValue={setDomicileWardTemp}
            iconColor={"#1571DE"}
            disabled={isDomicileTheSame || disableInput}
          /> */}
          <InputField
            title={"Kecamatan"}
            placeholder={"Tulis kecamatan anda"}
            value={form?.domicile?.subdistrict}
            onChange={(e) => handleChange(e, "domicile", "subdistrict")}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={
              errorField && !form?.domicile?.subdistrict && !isDomicileTheSame
            }
            errorText={"*Kecamatan domisili belum diisi"}
          />
          {/* <AutocompleteField
            title={"Kecamatan"}
            placeholder={"Pilih kecamatan"}
            searchOptions={kecamatanOptions}
            value={domicileSubDistrictTemp}
            setValue={setDomicileSubDistrictTemp}
            iconColor={"#1571DE"}
            disabled={isDomicileTheSame || disableInput}
          /> */}
          <AutocompleteField
            title={"Kabupaten/Kota"}
            placeholder={"Pilih kabupaten"}
            searchOptions={options?.cities}
            value={domicileRegencyTemp}
            setValue={setDomicileRegencyTemp}
            iconColor={"#1571DE"}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={errorField && !domicileRegencyTemp && !isDomicileTheSame}
            errorText={"*Kabupaten/Kota domisili belum diisi"}
          />
          <AutocompleteField
            title={"Provinsi"}
            placeholder={"Pilih provinsi"}
            searchOptions={options?.provinces}
            value={domicileProvinceTemp}
            setValue={setDomicileProvinceTemp}
            iconColor={"#1571DE"}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={errorField && !domicileProvinceTemp && !isDomicileTheSame}
            errorText={"*Alamat domisili belum diisi"}
          />
          <InputField
            title={"Kode Pos"}
            placeholder={"000"}
            value={form?.domicile?.postal}
            onChange={(e) => handleChange(e, "domicile", "postal")}
            disabled={isDomicileTheSame || disableInput}
            required
            isError={
              errorField && !form?.domicile?.postal && !isDomicileTheSame
            }
            errorText={"*Kode Pos domisili belum diisi"}
          />
        </div>
      </div>
    </div>
  );
}
