import Icon from "@Atom/Icon";
import UseQuery from "@Hooks/useQuery";
import Breadcrumb from "@Molecule/BreadCrumbs";
import RecruitmentAreaDetailList from "@Organism/Recruiter/Recruitment/RecruitmentAreaDetailList";
import { getOrderListByArea } from "@Services/officer/recruitment";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader(route) {
  // const params = new URLSearchParams(route?.request?.url);
  // const code = params?.get("c");
  // const token = sessionStorage.getItem("accessToken");
  // try {
  //   const { response } = await getClientDetail(token, code);
  //   const { response : options } = await orderSync(token);
  //   return {
  //     client: response,
  //     cities: options?.cities
  //   };
  // } catch (err) {
  //   console.error(err);
  // }
}

export default function RecruitmentAreaDetail() {
  // const { client, cities } = useLoaderData();

  const query = UseQuery();
  const navigate = useNavigate();
  const area = query.get("n");
  const code = query.get("c");
  // const area = "Jakarta";

  const [searchClient, setSearchClient] = useState("");
  const [dataClient, setDataClient] = useState(dataTemplate);
  const [valueClient, setValueClient] = useState(5);
  const [pagesClient, setPagesClient] = useState(`1-${valueClient}`);
  const [pageListClient, setPageListClient] = useState([]);
  const [selectedPageClient, setSelectedPageClient] = useState(1);
  const [sortBy, setSortBy] = useState({
    name: "newest",
    label: "ascending",
    value: "",
  });

  const dataQuery = useMemo(() => {
    return `limit=${valueClient}&page=${selectedPageClient}&sort=${sortBy.value}&search=${searchClient}`;
  }, [selectedPageClient, sortBy, valueClient, searchClient]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { response } = await getOrderListByArea(dataQuery, code);
        setDataClient(response);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [dataQuery, code]);

  const handleClickBack = () => {
    navigate(`/rekrutmen`);
  };

  // const handleNavigateAdd = () => {
  //   navigate(`/clients/add-new-order?n=${name}&c=${code}`)
  //   setPending(true)
  // }

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Area Details</title>
      </Helmet>
      <div className={Styles.breadcrumbWrapper}>
        <span onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#0a0a0a" size={20} />
        </span>
        <Breadcrumb>
          <span onClick={handleClickBack} className={Styles.breadcrumbText}>
            Rekrutmen
          </span>
          <span className={Styles.breadcrumbText2}>{area.toLowerCase()}</span>
        </Breadcrumb>
      </div>
      <div className={Styles.headTitle}>
        <h1>{area}</h1>
      </div>

      <div className={Styles.contentWrapper}>
        <RecruitmentAreaDetailList
          search={searchClient}
          setSearch={setSearchClient}
          value={valueClient}
          setValue={setValueClient}
          pages={pagesClient}
          setPages={setPagesClient}
          pageList={pageListClient}
          setPageList={setPageListClient}
          selectedPage={selectedPageClient}
          setSelectedPage={setSelectedPageClient}
          data={dataClient}
          sort={sortBy}
          setSort={setSortBy}
          sortOption={sortOption}
        />
      </div>
    </div>
  );
}

const sortOption = [
  {
    name: "Newest",
    label: "ascending",
    value: "",
  },
  {
    name: "Oldest",
    label: "descending",
    value: "oldest",
  },
  {
    name: "A - Z",
    label: "alphabet-ascending",
    value: "A-Z",
  },
  {
    name: "Z - A",
    label: "alphabet-descending",
    value: "Z-A",
  },
];

const dataTemplate = {
  totalPage: 1,
  data: [
    {
      code: "PIS",
      name: "PERMATA INDONESIA",
      createdAt: "2023-04-27T03:33:00.142Z",
      positions: [
        {
          name: "CUSTOMER SERVICE",
          id: 1,
          cityCode: "001",
        },
        {
          name: "Security",
          id: 6,
          cityCode: "001",
        },
      ],
      request: 26,
    },
    {
      code: "KG",
      name: "KHONG GUAN",
      createdAt: "2023-04-27T03:33:00.142Z",
      positions: [
        {
          name: "Security",
          id: 2,
          cityCode: "001",
        },
        {
          name: "Head Security",
          id: 3,
          cityCode: "001",
        },
        {
          name: "Admin Officer",
          id: 33,
          cityCode: "001",
        },
        {
          name: "Admin Staff",
          id: 41,
          cityCode: "001",
        },
      ],
      request: 18,
    },
  ],
};
