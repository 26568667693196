import { useState } from "react";
import Styles from "./styles.module.scss";
import ModalWrapper from "@Atom/ModalWrapper";
import Images from "@Theme/Images";
import { Tooltip } from "@mui/material";

export default function ContactPerson({ data }) {
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const maxLength = 20;

  return (
    <div className={Styles.container}>
      <div>Client Contact</div>

      {data?.clientContacts?.length > 0 && (
        <div>
          <div className={Styles.tableBox}>
            <div className={Styles.tableHead}>
              <span>Name</span>
              <span>Unit Kerja</span>
              <span>Position</span>
              <span>Email</span>
              <span>Phone</span>
            </div>

            <div className={Styles.tablebody}>
              {data?.clientContacts?.map((el, index) => {
                return (
                  <div
                    className={`${Styles.body} ${
                      index === data?.clientContacts?.length - 1 &&
                      Styles.borderNone
                    }`}
                    key={index}
                  >
                    <span>{el?.ClientContact?.name || "-"}</span>
                    <span>{el?.ClientContact?.workUnit || "-"}</span>
                    <span>{el?.ClientContact?.jobTitle || "-"}</span>
                    <Tooltip title={el?.ClientContact?.email}>
                      <span>
                        {el?.ClientContact?.email
                          ? el?.ClientContact?.email?.length > maxLength
                            ? el?.ClientContact?.email?.substring(
                                0,
                                maxLength
                              ) + "..."
                            : el?.ClientContact?.email
                          : "-"}
                      </span>
                    </Tooltip>
                    <span>{el?.ClientContact?.phoneNumber || "-"}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={Styles.tableBoxResponsive}>
            <div className={Styles.tableHead}>
              <span>Name</span>
              <span>Action</span>
            </div>

            <div className={Styles.tablebody}>
              {data?.clientContacts?.map((el, index) => {
                return (
                  <div
                    className={`${Styles.body} ${
                      index === data?.clientContacts?.length - 1 &&
                      Styles.borderNone
                    }`}
                    key={index}
                  >
                    <span>{el?.ClientContact?.name || "-"}</span>
                    <div>
                      <div
                        onClick={() => {
                          setSelectedContactPerson(el);
                        }}
                      >
                        See More
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {data?.clientContacts?.length === 0 && (
        <div>
          <div className={Styles.notFound}>
            <div>
              <div>
                <img src={Images.FILE_AND_FOLDER} alt="" />
              </div>
              <span>Kontak klien masih kosong</span>
            </div>
          </div>
        </div>
      )}

      {selectedContactPerson && (
        <ModalWrapper
          show={selectedContactPerson}
          handleClose={() => setSelectedContactPerson(null)}
        >
          <div className={Styles.containerDetail}>
            <div className={Styles.title}>Client Contacts</div>
            <div>
              <span>Name</span>
              <span>{selectedContactPerson?.ClientContact?.name || "-"}</span>
            </div>

            <div>
              <span>Unit Kerja</span>
              <span>
                {selectedContactPerson?.ClientContact?.workUnit || "-"}
              </span>
            </div>

            <div>
              <span>Position</span>
              <span>
                {selectedContactPerson?.ClientContact?.jobTitle || "-"}
              </span>
            </div>

            <div>
              <span>Email</span>
              <span>{selectedContactPerson?.ClientContact?.email || "-"}</span>
            </div>

            <div>
              <span>Phone</span>
              <span>
                {selectedContactPerson?.ClientContact?.phoneNumber || "-"}
              </span>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
}
