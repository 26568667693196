import { IDR } from "@Helpers/IDR";
import Images from "@Theme/Images";
import { Image, Text, View } from "@react-pdf/renderer";
import { useMemo } from "react";
import Styles from "./styles";
import CustomText from "./text";

const getPeriodText = (periodStr) => {
  switch (periodStr) {
    case 'Harian':
      return 'Harian x 20'
    case 'Mingguan':
      return 'Mingguan x 4'
    default:
      return 'Bulanan'
  }
}

const TableHead = () => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 15 / 2,
        borderBottomColor: "#ece6e6",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
    >
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "17%",
        }}
      >
        Man Power
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "5%",
          textAlign: "center",
        }}
      >
        Total
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        Salary
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        Salary in Month
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        Personal Work Equipment
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        Allowance
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        BPJS
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          display: "flex",
          textAlign: "right",
        }}
      >
        Total Cost
      </Text>
    </View>
  );
};

const TableBody = ({ manPowerCost, lastId, ...props }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: lastId ? 0 : 15 / 2,
        borderBottomColor: "#ece6e6",
        borderBottomWidth: lastId ? 0 : 1,
        borderBottomStyle: "solid",
      }}
    >
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "17%",
        }}
      >
        {manPowerCost?.name}
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "5%",
          textAlign: "center",
        }}
      >
        {manPowerCost?.amount}
      </Text>
      <View
        style={{
          fontSize: 16 / 2,
          width: "15%",
          gap: '2px',
          alignItems: 'flex-end',
          textAlign: 'right'
        }}
      >
        <Text
          style={{
            fontSize: 16 / 2,
            fontFamily: "Helvetica",
            color: "#000",
            // width: "10%",
            textAlign: "right",
          }}
        >
          {IDR.format(manPowerCost?.salary)}
        </Text>
        <Text
          style={{
            fontSize: 14 / 2,
            fontFamily: "Helvetica",
            color: "#9E9E9E",
            // width: "10%",
            textAlign: "right",
          }}
        >
          {getPeriodText(manPowerCost?.salaryDenom)}
        </Text>
      </View>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        {IDR.format(manPowerCost?.salaryPermonth)}
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        {IDR.format(manPowerCost?.personalEquipmentCost)}
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        {IDR.format(manPowerCost?.allowances)}
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        {IDR.format(manPowerCost?.bpjs)}
      </Text>
      <Text
        style={{
          fontSize: 16 / 2,
          fontFamily: "Helvetica",
          color: "#000",
          width: "15%",
          textAlign: "right",
        }}
      >
        {IDR.format(manPowerCost?.totalCosts)}
      </Text>
    </View>
  );
};

const EbitComponent = ({ name, value, ...props }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <CustomText size={16 / 2} color="#000">
        {name}
      </CustomText>
      <CustomText size={16 / 2} color="#000">
        {IDR.format(value)}
      </CustomText>
    </View>
  );
};

const GrandTotalComponent = ({ name, value, extraComponent, ...props }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: 'relative'
      }}
    >
      {extraComponent && extraComponent}
      <CustomText size={16 / 2} color="#000" type="bold">
        {name}
      </CustomText>
      <CustomText size={16 / 2} color="#000" type="bold">
        {IDR.format(value)}
      </CustomText>
    </View>
  );
};

const Ebit = ({ data, manfee, ...props }) => {
  const manfeePercentage = useMemo(() => {
    if (manfee?.includes('%')) {
      return ` (${manfee})`
    } else return ''
  }, [manfee])
  const manfeeText = useMemo(() => {
    let text = 'Management Fee' + manfeePercentage
    return text
  }, [manfeePercentage])
  return (
    <View
      style={{
        borderBottomColor: "#000",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        display: "flex",
        flexDirection: "column",
        paddingTop: 20 / 2,
        paddingBottom: 20 / 2,
        gap: 15 / 2,
      }}
    >
      <EbitComponent name="Sub Total" value={data?.subTotal} />
      <EbitComponent
        name="Area Work Equipment"
        value={data?.areaEquipmentCost?.toLocaleString("id")}
      />
      <EbitComponent name={manfeeText} value={data?.manfee} />
    </View>
  );
};

const GrandTotal = ({ data, orderComprehensives, ...props }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: 8 / 2,
        paddingBottom: 20 / 2,
        gap: 9 / 2,
      }}
    >
      <GrandTotalComponent name="Total biaya" value={data?.total} />
      <GrandTotalComponent
        name={`PPN 11% ${orderComprehensives?.PPN?.toLowerCase()}`}
        value={data?.ppn}
      />
      <GrandTotalComponent
        name={`PPH23 2% ${orderComprehensives?.PPH23?.toLowerCase()}`}
        value={data?.pph}
        extraComponent={
          <View
            style={{
              position: "absolute",
              top: '-4px',
              left: '-50%',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '4px',
              padding: '2px 4px',
              backgroundColor: '#EDEDED',
              width: '48%',
              borderRadius: '4px'
            }}
          >
            <Image
              src={Images.INFORMATION_SOLID}
              style={{
                width: '16px',
                aspectRatio: '1',
              }} />
            <Text
              style={{
                fontSize: 16 / 2,
                width: '100%',
              }}
            >Komponen Pengurang</Text>
          </View>
        }
      />
      <GrandTotalComponent name="Grand total" value={data?.grandTotal} />
    </View>
  );
};

const CostEstimation = ({ data, manfee, lastId, orderComprehensives, ...props }) => {
  return (
    <View
      wrap={false}
      style={{
        width: "100%",
        paddingLeft: 16 / 2,
        paddingRight: 16 / 2,
        paddingBottom: lastId ? 16 / 2 : 0,
      }}
    >
      <View style={Styles.regionWrapper}>
        <CustomText size={22 / 2} color="#1571de" type="bold">
          {data?.cityName}
        </CustomText>
      </View>
      <View style={{ display: "flex", gap: 16 / 2 }}>
        <TableHead />
        {data?.manPowerCosts.map((manPowerCost, id) => (
          <TableBody
            manPowerCost={manPowerCost}
            key={id}
            lastId={id === data?.manPowerCosts?.length - 1}
          />
        ))}
        <View
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            borderTopColor: "#000",
            borderTopStyle: "solid",
            borderTopWidth: 0.5,
          }}
        >
          <View
            style={{
              width: "50%",
            }}
          >
            <Ebit data={data} manfee={manfee} />
            <GrandTotal data={data} orderComprehensives={orderComprehensives} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default CostEstimation;
