import ModalWrapper from "@Atom/ModalWrapper";
import AllowanceField from "@Molecule/AllowanceField";
import AllowanceModal from "@Molecule/_modal/BusinessSupport/AllowanceModal";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";

export default function SecondStep({
  values,
  setValues,
  allowances,
  setAllowances,
  sync,
  contractList,
  setSelectedItemAllowances,
  selectedItemAllowance,
  data,
  valueVariableCost,
  setValueVariableCost,
}) {
  const [showAllowances, setShowAllowances] = useState(false);

  const salary = useMemo(() => {
    const obj = values?.find((obj) =>
      obj?.code?.toLowerCase()?.includes("salary")
    );

    let salary = obj?.value;
    if (salary){
      if (salary.includes("::")){
        const salarySplit = salary.split("/");
        salary = `${salarySplit[0].split(".").join("")}::${salarySplit[1]}`;
      } else {
        salary = obj?.value?.split(".").join("");
      }
    }

    return { ...obj, value: salary };
  }, [values]);

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedItemAllowance.length; i++) {
      if (selectedItemAllowance[i]?.id === item?.id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  const handleCheckboxChange = (item) => {
    let isSelected = handleChecked(item);
    if (isSelected) {
      console.log("gabisa di uncheck");
      // setSelectedItemAllowances(
      //   selectedItemAllowance.filter((selected) => selected?.id !== item?.id)
      // );
    } else {
      setSelectedItemAllowances([...selectedItemAllowance, item]);
      // setIsActive(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Filter out items that are already in allowances
    const newAllowances = selectedItemAllowance
      ?.filter((el) => {
        // Check if the item already exists in allowances
        return !allowances?.some((item) => item.id === el.id);
      })
      .map((el) => {
        return {
          id: el.id,
          value: "",
          label: el.value,
          code: `{TUNJANGAN_${el.value}}`,
          contractTemplateID: null,
          candidateID: data?.id,
        };
      });

    // Concatenate the new allowances with the existing allowances
    setAllowances((prev) => {
      if (!Array.isArray(prev)){
        prev = []
      }
      return [...prev, ...newAllowances]
    });
    setShowAllowances(false);
  };

  useEffect(() => {
    sessionStorage.setItem("c_allowances", JSON.stringify(allowances));
  }, [allowances]);

  const handleDelete = (id) => {
    // console.log(id);
    const filtered = allowances?.filter((data) => {
      return data.id !== id;
    });
    const selectedFilter = selectedItemAllowance?.filter((data) => {
      return data.id !== id;
    });
    setAllowances(filtered);
    setSelectedItemAllowances(selectedFilter);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.adder}>
        <span>Data Tunjangan</span>
        {/* tambah tunjangan bs */}
        <button onClick={() => setShowAllowances(true)}>
          <span>Tunjangan</span>
          <Icon icon={"add-circle"} size={20} />
        </button>
      </div>
      <div className={Styles.content}>
        {salary && (
          <div>
            <AllowanceField
              setType={(newVal) => {
                setValues(
                  values?.map((obj) => {
                    if (obj?.code === salary?.code) {
                      const currentValue = obj?.value?.toString()?.split("::");
                      const nominal = currentValue[0];
                      return {
                        ...obj,
                        value: `${nominal}::` + newVal,
                      };
                    }
                    return obj;
                  })
                );
              }}
              // onDelete={() => {
              //   const newAllowances = [...form?.allowances?.filter(obj => obj?.name !== allowance?.name)]
              //   setForm(f => ({ ...f, allowances: newAllowances }))
              // }}
              type={salary?.value?.toString().split("::")[1]}
              title={salary?.label}
              isCurrency
              value={
                salary?.value?.toString()?.includes("::")
                  ? salary?.value?.toString()?.split("::")[0]
                  : salary?.value
              }
              setValue={(newVal) => {
                setValues(
                  values?.map((obj) => {
                    if (obj?.code === salary?.code) {
                      const currentValue = obj?.value?.toString()?.split("::")
                      const term = currentValue?.length > 1 ? `::${currentValue[1]}` : ""
                      return {
                        ...obj,
                        value: newVal + term,
                      };
                    }
                    return obj;
                  })
                );
              }}
              placeholder={"Gaji pokok"}
            />
          </div>
        )}

        {valueVariableCost?.length > 0 && (
          <div className={Styles.variableCost}>
            {valueVariableCost?.map((el, index) => {
              return (
                <div className={Styles.inputCost}>
                  <span className={Styles.spanVariableCost}>{el?.label}</span>
                  <div className={Styles.input}>
                    <Input
                      key={index}
                      value={el?.value}
                      onChange={(newValue) => {
                        setValueVariableCost(
                          valueVariableCost?.map((item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                value: newValue,
                              };
                            } else {
                              return item;
                            }
                          })
                        );
                      }}
                      placeholder={"Masukkan Nominal"}
                      isCurrency
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!allowances?.length ? (
          <div className={Styles.empty}>
            {/* <span>Belum ada data tunjangan yang ditambahkan!</span>
            <span>
              Tambahkan data dengan mengklik tombol penyisihan di atas
            </span> */}
          </div>
        ) : (
          <div className={Styles.allowances}>
            {allowances?.map((allowance, i) => (
              <AllowanceField
                onDelete={() => handleDelete(allowance.id)}
                key={i}
                title={allowance?.label}
                value={
                  allowance?.value?.toString()?.includes("::")
                    ? allowance?.value?.toString()?.split("::")[0]?.split(".").join("")
                    : allowance?.value?.split(".").join("")
                }
                type={allowance?.value?.toString().split("::")[1]}
                setValue={(newVal) => {
                  setAllowances(
                    allowances?.map((obj) => {
                      if (obj?.code === allowance?.code) {
                        const currentValue = obj?.value
                          ?.toString()
                          ?.split("::");
                        const term =
                          currentValue?.length > 1 ? `::${currentValue[1]}` : "";
                        return {
                          ...obj,
                          value: newVal + term,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                setType={(newVal) => {
                  setAllowances(
                    allowances?.map((obj) => {
                      if (obj?.code === allowance?.code) {
                        const currentValue = obj?.value
                          ?.toString()
                          ?.split("::");
                        const nominal = currentValue[0];
                        return {
                          ...obj,
                          value: `${nominal}::` + newVal,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                // onDelete={() => {
                //   const newAllowances = [...form?.allowances?.filter(obj => obj?.name !== allowance?.name)]
                //   setForm(f => ({ ...f, allowances: newAllowances }))
                // }}
              />
            ))}
          </div>
        )}
      </div>
      <ModalWrapper show={showAllowances}>
        <AllowanceModal
          show={showAllowances}
          setShow={setShowAllowances}
          contractList={contractList}
          handleCheckboxChange={handleCheckboxChange}
          handleChecked={handleChecked}
          // allowances={allowances}
          // setAllowances={(newVal) => setForm(f => ({ ...f, allowances: newVal }))}
          options={["allowance 1", "allowance 2", "allowance 3"]}
          handleSubmit={handleSubmit}
        />
      </ModalWrapper>
    </div>
  );
}
