/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import UploadBox from "@Atom/UploadBox";
import { useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./style.module.scss";

const Mobile = ({ isMultiple, files, setFiles, title, setShow }) => {
  const [filesTemp, setFilesTemp] = useState(files);
  const [errorFileReject, setErrorFileReject] = useState({});
  // const inputFileRef = useRef();

  const onClick = () => {
    // inputFileRef.current.click();
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    if (isMultiple) {
      if (acceptedFiles.length > 0) {
        const newFiles = [...filesTemp];
        newFiles.push(acceptedFiles[0]);
        setFilesTemp(newFiles);
        setErrorFileReject({});
      }
    } else {
      if (acceptedFiles.length > 0) {
        setFilesTemp(acceptedFiles[0]);
        setErrorFileReject({});
      }
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
            });
          }
        });
      });
    }
  };

  const onChange = (e) => {
    // if (e.target.value) {
    //   if (isMultiple) {
    //     const newFiles = [...filesTemp];
    //     newFiles.push(e.target.value);
    //     setFilesTemp(newFiles);
    //   } else {
    //     setFilesTemp(e.target.value);
    //   }
    // }
    if (e?.target?.input?.files?.length > 0) {
      if (isMultiple) {
        const newFiles = [...filesTemp];
        newFiles.push(e?.target?.input?.files[0]);
        setFilesTemp(newFiles);
      } else {
        setFilesTemp(e?.target?.input?.files[0]);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: isMultiple || false,
    maxSize: 1048576 * 5,
  });

  const onDeleteFile = (index) => {
    if (isMultiple) {
      const newFiles = [...filesTemp];
      newFiles.splice(index, 1);
      setFilesTemp(newFiles);
    } else {
      setFilesTemp(null);
    }
  };

  const isFilesArrray = useMemo(() => {
    return Array.isArray(files);
  }, [files]);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    setFiles(filesTemp);
    setShow(false);
  };

  return (
    <div className={`${Styles.mobileContainer}`}>
      <div className={Styles.titleWrapper}>
        <div className={Styles.title}>
          <span>{title}</span>
          <Icon icon="upload" size={18} />
        </div>
      </div>
      <div className={Styles.commandText}>Taruh file di kotak bawah ini</div>
      <div
        {...getRootProps()}
        className={`${Styles.uploadBoxWrapper} ${Styles.extraHeight}`}
      >
        <UploadBox
          type="light"
          onClick={onClick}
          uploading={false}
          isUploaded={false}
        />
        <input
          {...getInputProps()}
          disabled={false}
          type="file"
          // ref={inputFileRef}
          name="file"
          // onChange={onChange}
          hidden
        />
      </div>
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : null}
      <div className={Styles.filesWrapper}>
        {isFilesArrray ? (
          <>
            {filesTemp?.map((file, i) => {
              return (
                <div key={i} className={Styles.fileCard}>
                  <div className={Styles.left}>
                    <Icon icon="excel" size={22} className={Styles.fileIcon} />
                    <div>
                      <span>{file?.name}</span>
                    </div>
                  </div>
                  <button disabled={false} onClick={(e) => onDeleteFile(i)}>
                    <Icon icon="cross-circle" size={22} />
                  </button>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {filesTemp && (
              <div className={Styles.fileCard}>
                <div className={Styles.left}>
                  <Icon icon="excel" size={22} className={Styles.fileIcon} />
                  <div>
                    <span>{filesTemp?.name}</span>
                  </div>
                </div>
                <button disabled={false} onClick={(e) => onDeleteFile()}>
                  <Icon icon="cross-circle" size={22} />
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className={Styles.buttonsWrapper}>
        <button className={Styles.Cancel} onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={Styles.Add}
          onClick={handleSave}
          disabled={isFilesArrray ? filesTemp?.length === 0 : !filesTemp}
        >
          Save
        </button>
      </div>
    </div>
  );
};
const Desktop = ({ isMultiple, files, setFiles, title, setShow }) => {
  const [filesTemp, setFilesTemp] = useState(files);
  const inputFileRef = useRef();
  const [errorFileReject, setErrorFileReject] = useState({});
  const [errorFormat, setErrorFormat] = useState("");

  const onClick = () => {
    // inputFileRef.current.click();
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    if (isMultiple) {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          const newFiles = [...filesTemp];
          newFiles.push(acceptedFiles[0]);
          setFilesTemp(newFiles);
          setErrorFileReject({});
        } else {
          setErrorFormat("Format file tidak sesuai");
          setErrorFileReject({});
        }
      } else {
        setErrorFormat("Format file tidak sesuai");
        setErrorFileReject({});
      }
    } else {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setFilesTemp(acceptedFiles[0]);
          setErrorFileReject({});
        } else {
          setErrorFormat("Format file tidak sesuai");
          setErrorFileReject({});
        }
      } else {
        setErrorFormat("Format file tidak sesuai");
        setErrorFileReject({});
      }
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
            });
            setErrorFormat("");
          }
        });
      });
    }
  };

  const onChange = (e) => {
    if (e?.target?.input?.files?.length > 0) {
      if (isMultiple) {
        const newFiles = [...filesTemp];
        newFiles.push(e?.target?.input?.files[0]);
        setFilesTemp(newFiles);
      } else {
        setFilesTemp(e?.target?.input?.files[0]);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: isMultiple || false,
    maxSize: 1048576 * 5,
  });

  const onDeleteFile = (index) => {
    if (isMultiple) {
      const newFiles = [...filesTemp];
      newFiles.splice(index, 1);
      setFilesTemp(newFiles);
    } else {
      setFilesTemp(null);
    }
  };

  const isFilesArrray = useMemo(() => {
    return Array.isArray(files);
  }, [files]);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    setFiles(filesTemp);
    setShow(false);
  };

  return (
    <div className={Styles.background}>
      <div className={`${Styles.container}`}>
        <div className={Styles.titleWrapper}>
          <div className={Styles.title}>
            <span>{title}</span>
            <Icon icon="upload" size={18} />
          </div>
        </div>
        <div className={Styles.commandText}>Taruh file di kotak bawah ini</div>
        <div
          {...getRootProps()}
          className={`${Styles.uploadBoxWrapper} ${Styles.extraHeight}`}
        >
          <UploadBox
            type="light"
            onClick={onClick}
            uploading={false}
            isUploaded={false}
          />
          <input
            {...getInputProps()}
            disabled={false}
            type="file"
            // ref={inputFileRef}
            name="file"
            // onChange={onChange}
            hidden
          />
        </div>
        {errorFileReject?.message ? (
          <span className={Styles.errorText}>*{errorFileReject?.message}</span>
        ) : null}

        {errorFormat ? (
          <span className={Styles.errorText}>*{errorFormat}</span>
        ) : null}
        <div className={Styles.filesWrapper}>
          {isFilesArrray ? (
            <>
              {filesTemp?.map((file, i) => {
                return (
                  <div key={i} className={Styles.fileCard}>
                    <div className={Styles.left}>
                      <Icon
                        icon="excel"
                        size={22}
                        className={Styles.fileIcon}
                      />
                      <div>
                        <span>{file?.name}</span>
                      </div>
                    </div>
                    <button disabled={false} onClick={(e) => onDeleteFile(i)}>
                      <Icon icon="cross-circle" size={22} />
                    </button>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {filesTemp && (
                <div className={Styles.fileCard}>
                  <div className={Styles.left}>
                    <Icon icon="excel" size={22} className={Styles.fileIcon} />
                    <div>
                      <span>{filesTemp?.name}</span>
                    </div>
                  </div>
                  <button disabled={false} onClick={(e) => onDeleteFile()}>
                    <Icon icon="cross-circle" size={22} />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className={Styles.buttonsWrapper}>
          <button className={Styles.Cancel} onClick={handleCancel}>
            Cancel
          </button>
          <button
            className={Styles.Add}
            onClick={handleSave}
            disabled={isFilesArrray ? filesTemp?.length === 0 : !filesTemp}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default function FileUploadModal({
  type,
  isMultiple,
  files,
  setFiles,
  setShow,
  title,
}) {
  switch (type) {
    case "desktop":
      return (
        <Desktop
          isMultiple={isMultiple}
          files={files}
          setFiles={setFiles}
          setShow={setShow}
          title={title}
        />
      );
    case "mobile":
      return (
        <Mobile
          isMultiple={isMultiple}
          files={files}
          setFiles={setFiles}
          setShow={setShow}
          title={title}
        />
      );
    default:
      return (
        <Desktop
          isMultiple={isMultiple}
          files={files}
          setFiles={setFiles}
          setShow={setShow}
          title={title}
        />
      );
  }
}
