import Images from "@Theme/Images";
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";

export default function SuccesModal({ name, code }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.body}>
        <div>
          <div>
            <img src={Images.AMICO2} alt="" />
          </div>

          <div>Klien Baru Berhasil Ditambahkan</div>
        </div>

        <div>Apakah kamu ingin melanjutkan membuat order baru?</div>

        <div>
          <Link to={`/clients`} className={Styles.back}>
            Kembali
          </Link>
          <Link
            to={`/clients/add-new-order?n=${name || ""}&c=${code || ""}`}
            className={Styles.next}
          >
            Lanjut
          </Link>
        </div>
      </div>
    </div>
  );
}
