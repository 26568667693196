import { useEffect, useRef } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function ReasonRejectModal({
  onClickCancel,
  setValues,
  value,
  handleChangeInput,
  onClickBack,
  onRejectCandidate,
}) {
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      onClickBack();
    }
  }, [boxOutsideClick, onClickBack]);

  return (
    <div className={Styles.bodyModal}>
      <div className={Styles.container} ref={boxRef}>
        <div className={Styles.top}>
          <div className={Styles.title}>
            <span className={Styles.reasonTitle}>Alasan Belum Bisa Join</span>
            <span className={Styles.desc}>
              Tulis catatan anda untuk memberikan informasi ke kami
            </span>
          </div>

          <div className={Styles.inputForm}>
            <span className={Styles.label}>Catatan</span>
            <textarea
              type="text"
              className={Styles.remark}
              name="remark"
              placeholder="Masukan catatan disini"
              onChange={handleChangeInput}
              value={value}
            />
          </div>
        </div>

        <div className={Styles.buttonSection}>
          <div className={Styles.cancel} onClick={onClickCancel}>
            Batal
          </div>
          <div className={Styles.sent} onClick={onRejectCandidate}>
            Kirim
          </div>
        </div>
      </div>
    </div>
  );
}
