import Styles from "./inputDateField.module.scss";
import InputDate from "@Atom/InputDate";

const InputDateField = ({
  value,
  setValue,
  title,
  iconColor,
  required,
  disabled,
  readOnly,
  placeholder,
  disabledDate,
  type,
  isError = false,
  isErrorV2 = false,
  errorText = "Please fill the field",
  preventPastDates = false,
  min,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        {title}
        {required && <span className={Styles.asterisk}>&#x2a;</span>}
      </div>
      <InputDate
        type={type}
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        iconColor={iconColor}
        disabled={disabled}
        readOnly={readOnly}
        disabledDate={disabledDate}
        isError={isError}
        isErrorV2={isErrorV2}
        errorText={errorText}
        preventPastDates={preventPastDates}
        min={min}
      />
    </div>
  );
};

export default InputDateField;
