/* eslint-disable eqeqeq */
import useWindowSize from '@Hooks/useWindowSize';
import DropdownField from '@Molecule/DropdownField';
import InputDateField from '@Molecule/InputDateField';
import InputField from '@Molecule/InputField';
import Images from '@Theme/Images';
import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss';

export default function SecondStep({
  familyData,
  setFamilyData,
  personalData,
  stepperHeight,
  syncData,
  familyDataToMap = [],
  errorKeys = []
}) {


  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);


  const handleChangeFamilyData = (id, name, value) => {
    const newData = familyData?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      }
      return obj
    })
    setFamilyData(newData)
  }

  const { width } = useWindowSize()

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container} >
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef} style={{
        position: width > 768 ? 'sticky' : 'absolute',
        // top: `calc(${stepperHeight}px)`,
        top: 0
      }}>
        Data Keluarga
      </h3>
      <div className={Styles.content}>
        <div className={Styles.left} >
          <div className={Styles.logoCard} style={{
            //  top: `calc(${headerHeight + stepperHeight}px)`,
            top: `calc(${headerHeight}px)`,
          }}>
            <img src={Images.FAMILY_BANNER} alt='' />
            <span>Silahkan isi sesuai jumlah keluarga yang telah anda isi di step 1.</span>
            <p>Contoh: Status K2(kawin 2 anak), anda harus mengisi data diri pasangan anda dan 2 anak anda.</p>
          </div>
        </div>
        <RightColumn className={Styles.right}>
          <Card className={Styles.personalData}>
            <span>Data Diri Anda</span>
            <div className={Styles.fields}>
              <InputField
                title={'Nama'}
                value={personalData?.name}
                disabled
              />
              <div className={Styles.grid}>
                <InputField
                  title={'Tempat Lahir'}
                  value={personalData?.birthPlace}
                  disabled
                />
                <InputDateField
                  title={'Tanggal Lahir'}
                  value={personalData?.birthDate}
                  readOnly
                />
                <InputField
                  title={'NIK'}
                  value={personalData?.idCardNumber}
                  disabled
                />
                <InputField
                  title={'Jenis Kelamin'}
                  value={personalData?.gender}
                  disabled
                />
              </div>
            </div>
          </Card>
          {
            familyDataToMap?.map((f, fI) => (
              <Card key={fI} className={Styles.familyData}>
                <span>{`Data Keluarga ${fI + 1}`}</span>
                <div className={Styles.grid}>
                  <DropdownField
                    title={'Hubungan Keluarga'}
                    value={f?.relation?.toUpperCase()}
                    setValue={(newVal) => handleChangeFamilyData(f?.id, 'relation', newVal)}
                    // options={syncData?.dropdownOptions?.filter(obj => obj?.type === 'Family Relationship')?.map(obj => {
                    //   return {
                    //     name: obj?.value
                    //   }
                    // })
                    // }
                    options={[
                      'SUAMI / ISTRI',
                      'ANAK'
                    ]?.map(obj => {
                      return {
                        name: obj
                      }
                    })}
                    placeholder='Hubungan keluarga'
                    iconColor={"#1571DE"}
                    autoClose
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'relation' && obj?.split('-')[1] == fI) && !f?.relation}
                    errorText='Hubungan Keluarga belum diisi'
                  />
                  <InputField
                    title={'Nama'}
                    value={f?.name}
                    onChange={(e) => handleChangeFamilyData(f?.id, 'name', e?.target?.value)}
                    placeholder={'Nama anggota keluarga'}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'name' && obj?.split('-')[1] == fI) && !f?.name}
                    errorText='Nama belum diisi'
                  />
                  <InputField
                    title={'Tempat Lahir'}
                    value={f?.birthPlace}
                    onChange={(e) => handleChangeFamilyData(f?.id, 'birthPlace', e?.target?.value)}
                    placeholder={'Tempat lahir'}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'birthPlace' && obj?.split('-')[1] == fI) && !f?.birthPlace}
                    errorText='Tempat Lahir belum diisi'
                  />
                  <InputDateField
                    title={'Tanggal Lahir'}
                    value={f?.birthDate}
                    setValue={(newVal) => handleChangeFamilyData(f?.id, 'birthDate', newVal)}
                    placeholder={'Tanggal lahir'}
                    iconColor={"#1571DE"}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'birthDate' && obj?.split('-')[1] == fI) && !f?.birthDate}
                    errorText='Tanggal Lahir belum diisi'
                  />
                  <InputField
                    title={'NIK'}
                    value={f?.idCardNumber}
                    onChange={(e) => handleChangeFamilyData(f?.id, 'idCardNumber', e?.target?.value)}
                    placeholder={'000'}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'idCardNumber' && obj?.split('-')[1] == fI) && !f?.idCardNumber()}
                    errorText='NIK belum diisi'
                  />
                  <DropdownField
                    title={'Jenis Kelamin'}
                    value={f?.gender?.toUpperCase()}
                    setValue={(newVal) => handleChangeFamilyData(f?.id, 'gender', newVal)}
                    options={syncData?.dropdownOptions?.filter(obj => obj?.type === 'Gender')?.map(obj => {
                      return {
                        name: obj?.value?.toUpperCase()
                      }
                    })
                    }
                    placeholder='Pilih jenis kelamin'
                    iconColor={"#1571DE"}
                    autoClose
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'gender' && obj?.split('-')[1] == fI) && !f?.gender}
                    errorText='Jenis Kelamin belum diisi'
                  />
                  <DropdownField
                    title={'Status Pekerjaan'}
                    value={f?.workingStatus?.toUpperCase()}
                    setValue={(newVal) => handleChangeFamilyData(f?.id, 'workingStatus', newVal)}
                    options={[
                      'Tidak bekerja',
                      'Pelajar/mahasiswa',
                      'Karyawan swasta',
                      'Karyawan Negeri (ASN)'
                    ]?.map(obj => {
                      return {
                        name: obj?.toUpperCase()
                      }
                    })
                    }
                    placeholder='Pilih status pekerjaan'
                    iconColor={"#1571DE"}
                    autoClose
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'workingStatus' && obj?.split('-')[1] == fI) && !f?.workingStatus}
                    errorText='Status Pekerjaan belum diisi'
                  />
                </div>

              </Card>
            ))
          }
        </RightColumn>
      </div>

    </div>
  )
}

export const Card = ({ children, className = '', ...props }) => {

  return (
    <div {...props} className={`${Styles.card} ${className}`}>
      {children}
    </div>
  )
}

export const RightColumn = ({
  children,
  className = '',
  ...props
}) => {

  return (
    <div {...props} className={`${Styles.rightColumn} ${className}`}>
      {children}
    </div>
  )
}