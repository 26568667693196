import { Text } from "@react-pdf/renderer";

const CustomText = ({ children, color, size, type, fontWeight, ...props }) => {
  return (
    <Text
      style={{
        fontSize: size,
        fontFamily: type === "bold" ? "Helvetica-Bold" : "Helvetica",
        color: color,
        fontWeight: fontWeight,
      }}
    >
      {children}
    </Text>
  );
};

export default CustomText;
