/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import InvitationConfirmationModal from "@Molecule/_modal/InvitationConfirmationModal";
import Images from "@Theme/Images";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { responseInvitation } from "@Services/officer/recruitment";
import InterviewInvitationClient from "./InterviewInvitationClient";

function InterviewInvitationRo({ defaultData }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmationMode, setShowConfirmationMode] = useState("reject");

  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const [isConfirming, setIsConfirming] = useState(false);

  const data = useMemo(() => {
    return {
      candidateName: defaultData?.candidate?.name,
      expectedPosition: defaultData?.candidate?.ExpectedPosition?.name,
      interviewDetails: {
        method: defaultData?.candidateInvitation?.method,
        date: new Date(defaultData?.candidateInvitation?.date),
        time: defaultData?.candidateInvitation?.time,
        location: defaultData?.candidateInvitation?.via,
        link: defaultData?.candidateInvitation?.via,
        remark: defaultData?.candidateInvitation?.remark,
        notes: defaultData?.candidateInvitation?.notes,
      },
      roContact: {
        name: defaultData?.recruiter?.name,
        email: defaultData?.recruiter?.email,
        phone: defaultData?.recruiter?.phoneNumber,
      },
    };
  }, [defaultData]);

  const handleReject = () => {
    setShowConfirmationMode("reject");
    setShowConfirmationModal(true);
  };
  const handleReschedule = () => {
    setShowConfirmationMode("reschedule");
    setShowConfirmationModal(true);
  };

  const handleAccept = async () => {
    try {
      // setIsConfirming(true)
      // await responseInvitation({
      //   status: 'Accepted'
      // }, defaultData?.candidateInvitation?.id)
      // setIsConfirming(false)
      setShowConfirmationMode("accept");
      // setIsSuccess(true)
      setShowConfirmationModal(true);
    } catch (err) {
      // setIsConfirming(false)
      setError(err?.response?.data?.error || "Something went wrong");
    }
  };

  const interviewLinks = useMemo(() => {
    if (defaultData?.candidateInvitation?.method.toLowerCase() === "offline") {
      if (
        defaultData?.candidateInvitation?.via?.split("::")[0] ===
        "Gedung Permata Indonesia Mayestik"
      ) {
        return "https://maps.app.goo.gl/cnxGn789sfRk9owTA";
      } else if (
        defaultData?.candidateInvitation?.via?.split("::")[0] ===
        "Gedung Permata Indonesia Kebayoran"
      ) {
        return "https://maps.app.goo.gl/iUuQxtY2BpxVFcrZA";
      } else {
        return "";
      }
    }
  }, [defaultData]);

  return (
    <div className={Styles.container}>
      {defaultData?.candidateInvitation?.remark === "EXPIRED INVITATION" && (
        <div className={Styles.notifWrapper}>
          <div className={Styles.logoWrapper}>
            <img src={Images.DEADLINE_ICON} alt="icon" />
            <p>EXPIRED</p>
          </div>
          <p>Maaf surat undangan interview ini sudah expired</p>
        </div>
      )}
      <div className={Styles.topWrapper}>
        <div className={Styles.header}>
          <div>
            <img src={Images?.INITIAL_LOGO} alt="logo" />
          </div>
          <span>PT Permata Indonesia Sejahtera</span>
        </div>
        <div className={Styles.dateWrapper}>
          <span>{moment(new Date())?.format("LL")}</span>
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        <h3>Surat Undangan Interview</h3>
        <div className={Styles.to}>
          <p className={Styles.normal}>Kepada yang terhormat,</p>
          <p className={Styles.bold}>{data?.candidateName}</p>
        </div>
        <div className={Styles.intro}>
          <p className={Styles.normal}>
            Sesuai dengan lamaran Anda yang telah kami terima, kami mengundang
            Anda interview untuk posisi{" "}
            <span className={Styles.bold}>{data?.expectedPosition}</span>,
            berikut informasi jadwal interview yang akan diadakan:
          </p>
        </div>
        <div className={Styles.details}>
          <div className={Styles.stacked}>
            <span className={Styles.bold}>Tanggal</span>
            <span className={Styles.bold}> :</span>
            <span className={Styles.bold}>
              {moment(new Date(`/${data?.interviewDetails?.date}`))?.format(
                "LL"
              )}
            </span>
          </div>
          <div className={Styles.stacked}>
            <span className={Styles.bold}>Jam</span>
            <span className={Styles.bold}>:</span>
            <span className={Styles.bold}>
              {data?.interviewDetails?.time} WIB
            </span>
          </div>
          {data?.interviewDetails?.method?.toLowerCase() === "offline" ? (
            <div className={Styles.stacked}>
              <span className={Styles.bold}>Tempat</span>
              <span className={Styles.bold}>:</span>
              <div className={Styles.address}>
                <p> {data?.interviewDetails?.location?.split("::")[0]}</p>
                <p
                  onClick={() =>
                    interviewLinks !== "" && window.open(interviewLinks)
                  }
                  className={interviewLinks !== "" ? Styles.interviewLink : ""}
                >
                  {data?.interviewDetails?.location?.split("::")[1]}
                </p>
              </div>
              {/* <span className={Styles.bold}>
                {data?.interviewDetails?.location.split("::").join(" • ")}
              </span> */}
            </div>
          ) : (
            <div className={Styles.stacked}>
              <span className={Styles.bold}>Link</span>
              <span className={Styles.bold}>:</span>
              {/* <span className={`${Styles.bold} ${Styles.link}`} onClick={()=> window.open(data?.interviewDetails?.link)}>{data?.interviewDetails?.link}</span> */}
              <a
                className={`${Styles.bold} ${Styles.link}`}
                href={
                  data?.interviewDetails?.link?.includes("http")
                    ? data?.interviewDetails?.link
                    : "https://" + data?.interviewDetails?.link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.interviewDetails?.link}
              </a>
            </div>
          )}
        </div>
        <div className={Styles.outro}>
          <p className={Styles.normal}>
            Diharapkan hadir 15 menit sebelum waktu interview.
          </p>
        </div>
        <div className={Styles.outro}>
          <p className={Styles.normal}>Sampai berjumpa!</p>
        </div>
        <div className={data.interviewDetails.notes ? "" : Styles.closure}>
          <p className={Styles.normal}>Salam,</p>
          <p className={Styles.normal}>
            Recruitment Officer Permata Indo Sejahtera
          </p>
        </div>
      </div>
      <div
        className={`${Styles.RemarksNote} ${data.interviewDetails.notes ? "" : Styles.Hidden
          }`}
      >
        <img src={Images.POST_IT_ICON} alt="post-it" />
        <div className={Styles.RemarksText}>
          <p>Catatan</p>
          <p>{data.interviewDetails.notes}</p>
        </div>
      </div>
      <div className={Styles.notesWrapper}>
        {/* <div className={Styles.section}>
          <h4>Alamat</h4>
          <div className={Styles.stacked}>
            <Icon icon="pin" size={20} />
            <div className={Styles.address}>
              <p>Gedung Permata Indonesia</p>
              <p
              onClick={() =>
                window.open("https://maps.app.goo.gl/24aYgnKXrPVKT8p49")
              }
              >
                Jl. Kyai Maja No.7a, RT.4/RW.1, Grogol Sel., Kec. Kby. Baru,
                Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130
              </p>
            </div>
          </div>
        </div> */}
        <div className={Styles.section}>
          <h4>Recruitment Officer Kontak</h4>
          <div className={Styles.stacked}>
            <Icon icon="phone" size={20} />
            <p>
              {data?.roContact?.phone} ({data?.roContact?.name})
            </p>
          </div>
          <div className={Styles.stacked}>
            <Icon icon="email" size={20} />
            <p>{data?.roContact?.email}</p>
          </div>
        </div>
      </div>
      <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
        {error || "error placeholder"}*
      </div>
      {!isSuccess && (!defaultData?.candidateInvitation?.status || defaultData?.candidateInvitation?.status?.toLowerCase() === 'resend') && (
        <div className={Styles.actionsWrapper}>
          <button disabled={isConfirming} onClick={handleReject}>
            <span>Belum bisa hadir</span>
            <Icon icon={"cross-circle"} size={20} className={Styles.red} />
          </button>
          <button disabled={isConfirming} onClick={handleReschedule}>
            <span>Jadwal Ulang</span>
            <Icon icon={"calendar-v2"} size={20} />
          </button>
          <button disabled={isConfirming} onClick={handleAccept}>
            {isConfirming ? "Mengonfirmasi..." : "Ya, saya bisa hadir"}
          </button>
        </div>
      )}

      <div>
        <ModalWrapper
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          type={isSuccess ? "fullscreen" : "normal"}
          forceCenter={isSuccess}
        >
          {showConfirmationModal && (
            <InvitationConfirmationModal
              type={confirmationMode}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              setShow={setShowConfirmationModal}
              id={defaultData?.candidateInvitation?.id}
              ordinal={1}
            />
          )}
        </ModalWrapper>
      </div>
    </div>
  );
}

export default function InterviewInvitation({ defaultData }) {
  const type = defaultData.candidateInvitation.ordinal;
  if (type) {
    if (+type === 2) {
      return <InterviewInvitationClient defaultData={defaultData} />;
    } else {
      return <InterviewInvitationRo defaultData={defaultData} />;
    }
  } else {
    return <InterviewInvitationRo defaultData={defaultData} />;
  }
}
