import { useEffect, useRef } from 'react';
import LocationSection from './LocationSection'
import RemarkSection from './RemarkSection'
import Styles from './style.module.scss'

export default function FourthStep(props) {
  const {
    onPrevious,
    onNext,
    form,
    setForm,
    cities,
    qualifications,
    options,
    remark,
    setRemark,
    trigger,
    setTrigger,
    debounceTrigger,
    isDone
  } = props

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {/* <div style={{ zIndex: 1 }}> */}
        <LocationSection debounceTrigger={debounceTrigger} trigger={trigger} setTrigger={setTrigger} options={options} cities={cities} qualifications={qualifications} form={form} setForm={setForm} />
      {/* </div>
      <div style={{ zIndex: 0 }}> */}
        <RemarkSection debounceTrigger={debounceTrigger} form={form} setForm={setForm} remark={remark} setRemark={setRemark} />
      {/* </div> */}
      <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button disabled={!isDone} onClick={onNext}>Next Step</button>
      </div>
    </div>
  )
}