import Icon from "@Atom/Icon";
import Switch from "@Atom/Switch";
import Textarea from "@Atom/Textarea";
import { verifyCandidate } from "@Services/support/candidate";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import Spinner from "@Atom/Spinner";

const ApproveVerificationModal = ({ setShowAccept, setTrigger, stage }) => {
  const [isTrue, setIsTrue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");

  // console.log(stage);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await verifyCandidate(
        "Accepted",
        stage === "REGISTER" || stage === "FIRST-VERIFICATION"
          ? "FIRST-VERIFICATION"
          : "SECOND-VERIFICATION",
        id,
        remark,
        "VERIFICATION"
      );
      setTrigger(Math.random());
      setIsLoading(false);
      setShowAccept(3);
    } catch (err) {
      console.log(err, "err");
      setIsLoading(false);
      setError(err?.response?.data?.error);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.iconWrapper}>
          <Icon icon="shield-check" size="48" className={Styles.icon} />
        </div>
        <div className={Styles.textWrapper}>
          <div className={Styles.title}>Setujui Verifikasi</div>
          <div className={Styles.text}>Catatan akan dikirimkan ke kandidat</div>
        </div>
      </div>
      <div className={Styles.formWrapper}>
        <div className={Styles.toggleWrapper}>
          <div className={Styles.titleWrapper}>
            <Icon icon="check-circle" size="20" className={Styles.icon} />
            <div className={Styles.text}>Verifikasi dengan deviasi</div>
          </div>
          <Switch isChecked={isTrue} setIsChecked={setIsTrue} />
        </div>
        {isTrue && (
          <div className={Styles.textareaWrapper}>
            <Textarea
              placeholder="Tulis catatan Anda"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>
        )}
        <div className={Styles.buttonBox}>
          {error ? <span>{error}</span> : null}
          <div className={Styles.buttons}>
            <button
              className={Styles.secondaryButton}
              onClick={() => setShowAccept(0)}
            >
              <span className={Styles.text}>Batal</span>
            </button>
            <button
              className={Styles.primaryButton}
              onClick={isLoading ? null : handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <span className={Styles.text}>Submit</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveVerificationModal;
