import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import PinMaps from "@Atom/Maps";
import Images from "@Theme/Images";
import React, { useCallback, useState } from "react";
import Styles from "./style.module.scss";

export default function SignPreview({
  data,
  setStep,
  pdf,
  images,
  handleSubmit,
  isSubmitting,
}) {
  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const RenderPdf = useCallback(() => {
    if (windowSize[0] > 768) {
      if (pdf) {
        return (
          <div className={Styles.PdfWrapper}>
            <embed src={`${pdf}#navpanes=0`} type="application/pdf"></embed>
          </div>
        );
      }
    } else {
      return null;
    }
  }, [windowSize, pdf]);

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.Headers}>
          <div className={Styles.Logo}>
            <div>
              <img src={Images.PERMATA_LOGO} alt="permata-logo" />
            </div>
            <p>Permata Indo Sejahtera</p>
          </div>
          <div>
            <p>Preview Kontrak</p>
          </div>
        </div>
        <div className={Styles.Body}>
          <div className={Styles.LeftSection}>
            <div className={Styles.HeaderSection}>
              <p className={Styles.HeaderTitle}>Data Anda</p>
              <div className={Styles.ButtonWithIcon} onClick={() => setStep(3)}>
                <p>Ubah Data</p>
                <Icon icon="pencil-square" size={20} />
              </div>
            </div>
            <div className={Styles.SectionWrapper}>
              <div className={Styles.SignContainer}>
                <div className={Styles.SignWrapper}>
                  <p className={Styles.SectionTitle}>Tanda Tangan</p>
                  <div className={Styles.CanvasWrapper}>
                    <img src={data.signature} alt="sign" />
                  </div>
                </div>
                <div className={Styles.SignWrapper}>
                  <p className={Styles.SectionTitle}>Paraf</p>
                  <div className={Styles.CanvasWrapper}>
                    <img src={data.initials} alt="initial" />
                  </div>
                </div>
              </div>
              <div className={Styles.Section}>
                <p className={Styles.SectionTitle}>Foto Dengan KTP</p>
                <img src={URL.createObjectURL(data.image)} alt="id" />
              </div>
              <div className={Styles.Section}>
                <p className={Styles.SectionTitle}>Lokasi</p>
                <PinMaps
                  lat={data.latitude}
                  lng={data.longitude}
                  mapHeight={"278px"}
                  mapWidth={"100%"}
                />
                <div className={Styles.Coords}>
                  <p>Latitude : {data.latitude}</p>
                  <p>Longitude : {data.longitude}</p>
                </div>
              </div>
              {/* <div className={`${Styles.Action} ${Styles.HideOnMobile}`}>
                <Button
                  type={"button-icon"}
                  title={"Setujui Kontrakkk"}
                  icon={"arrow-up-right"}
                  onClick={handleSubmit}
                />
              </div> */}
            </div>
          </div>
          <div className={Styles.RightSection}>
            <RenderPdf />
          </div>
          {/* <div className={`${Styles.Action} ${Styles.ShowOnMobile}`}>
            <Button
              type={"button-icon"}
              title={"Setujui Kontrak"}
              icon={"arrow-up-right"}
              onClick={handleSubmit}
            />
          </div> */}
        </div>
      </div>
      <div className={Styles.actionWrapper}>
        <span>
          Silahkan submit kontrak setelah anda selesai meninjau preview kontrak
          ini.
        </span>
        <Button
          type={"button-icon"}
          title={isSubmitting ? "Submitting..." : "Submit"}
          icon={"arrow-up-right"}
          onClick={handleSubmit}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
}
