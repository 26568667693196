import Icon from '@Atom/Icon'
import useQuery from '@Hooks/useQuery'
import CustomBreadCrumb from '@Molecule/CustomBreadCrumb'
import { useNavigate } from 'react-router-dom'
import Styles from './style.module.scss'

export default function ManPowerRequestSummaryNavController() {
  const query = useQuery();
  const name = query.get("n");
  // eslint-disable-next-line
  const code = query.get('c')
  const position = query.get('p')
  const navigate = useNavigate()

  const handleClickBack = () => {
    navigate(`/clients/details?n=${name}&c=${code}`)

  }
  const handleNavigateToDetails = () => {
    navigate(`/clients/details?n=${name}&c=${code}`)

  }
  const handleNavigateToClients = () => {
    navigate(`/clients`)

  }

  return (
    <div className={Styles.container}>
       <div className={Styles.breadcrumbWrapper}>
        <button onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
        </button>
        <CustomBreadCrumb>
          <span onClick={handleNavigateToClients} className={Styles.breadcrumbText}>Clients</span>
          <span onClick={handleNavigateToDetails} className={Styles.breadcrumbText}>{name}</span>
          <span className={Styles.breadcrumbText}>Man Power Request Summary</span>
          <span className={Styles.breadcrumbTextBold}>{position}</span>
        </CustomBreadCrumb>
      </div>
    </div>
  );
}
