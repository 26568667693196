import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const ApproveSuccessModal = ({
  setShowAccept,
  setGenerateContractMode,
  stage,
}) => {
  // stage === "REGISTER" ? "FIRST-VERIFICATION" : "SECOND-VERIFICATION",
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.iconWrapper}>
          <Icon icon="shield-check" size="48" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.textWrapper}>
          <div className={Styles.title}>Verifikasi Berhasil</div>
          {stage === "GENERATE-CONTRACT" ? (
            <div className={Styles.text}>
              Verifikasi pertama berhasil, kandidat akan otomatis masuk ke stage
              <span className={Styles.span}> Generate Kontrak</span>
            </div>
          ) :  stage === "extend" ? (
            <div className={Styles.text}>
              Verifikasi perpanjang kontrak karyawan berhasil dan masuk ke
              <span className={Styles.span}> Complate Kontrak</span>
            </div>
          ) : (
            <div className={Styles.text}>
              Verifikasi kedua berhasil, kandidat akan otomatis masuk ke step
              <span className={Styles.span}> Contracted</span>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.buttons}>
        {stage === "GENERATE-CONTRACT" ? (
          <div
            className={Styles.primaryButton}
            onClick={() => {
              setGenerateContractMode(true);
              setShowAccept(0);
            }}
          >
            <div className={Styles.text}>Generate Kontrak</div>
          </div>
        ) : (
          <div
            className={Styles.secondaryButton}
            onClick={() => setShowAccept(0)}
          >
            <div className={Styles.text}>Kembali</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApproveSuccessModal;
