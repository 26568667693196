import { useState } from "react";
import AddMode from "./AddMode";
import ListMode from "./ListMode";
import Styles from "./style.module.scss";

export default function FirstStage({
  positions,
  locations,
  educations,
  invitationSource,
  rejectedSource,
  acceptedSource,
  locationFilter,
  positionFilter,
  contacts,
  defaultCandidates,
  defaultTotalPage,
  syncData,
  invitationSyncData,
  trigger,
  setTrigger,
  requestDetails,
  cities,
  clientContacts,
  selectedPosition,
  evaluationParameters,
  positionIDFilter,
}) {
  const [addMode, setAddMode] = useState(false);

  return (
    <div className={Styles.container}>
      {!addMode ? (
        <ListMode
          setAddMode={setAddMode}
          invitationSource={invitationSource}
          rejectedSource={rejectedSource}
          acceptedSource={acceptedSource}
          locationFilter={locationFilter}
          positionFilter={positionFilter}
          contacts={contacts}
          defaultCandidates={defaultCandidates}
          defaultTotalPage={defaultTotalPage}
          trigger={trigger}
          setTrigger={setTrigger}
          requestDetails={requestDetails}
          clientContacts={clientContacts}
          invitationSyncData={invitationSyncData}
          selectedPosition={selectedPosition}
          evaluationParameters={evaluationParameters}
          positionIDFilter={positionIDFilter}
        />
      ) : (
        <AddMode
          syncData={syncData}
          setAddMode={setAddMode}
          positions={positions}
          locations={cities?.map((el) => el.name)}
          educations={educations}
        />
      )}
    </div>
  );
}
