/* eslint-disable eqeqeq */
import Dropdown from '@Atom/DropDown'
import Textarea from '@Atom/Textarea'
import AutocompleteField from '@Molecule/AutocompleteField'
import CheckboxField from '@Molecule/CheckboxField'
import DropdownField from '@Molecule/DropdownField'
import InputDateField from '@Molecule/InputDateField'
import InputField, { InputTitle } from '@Molecule/InputField'
import { useEffect, useRef } from 'react'
import Styles from './style.module.scss'

export default function FirstStep({
  form,
  setForm,
  syncData,
  errorRef,
  errorKeys = [],
  banks
}) {

  const handleChangeForm = (name, value, noUpperCase = false, resetAddress = false) => {

    if (resetAddress) {

      setForm(prev => {
        return {
          ...prev,
          domicileAddress: '',
          domicileRT: '',
          domicileRW: '',
          domicileKelurahan: '',
          domicileKecamatan: '',
          domicileCity: '',
          domicileProvince: '',
          domicilePostalCode: '',
          [name]: (noUpperCase || typeof value == Boolean) ? value : value?.toUpperCase()
        }
      })

    } else {
      setForm(prev => {
        return {
          ...prev,
          [name]: (noUpperCase || typeof value == Boolean) ? value : value?.toUpperCase()
        }
      })

    }
  }

  const phoneNumberValidation = (e) => {
    var key = e.which || e.keyCode;
    const value = e.target.value;
    if (!value?.length) {
      if (key && key != 56) {
        e.preventDefault()
      }
    } else {
      if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
        if (key != 9) {
          e.preventDefault();
        }
      }

    }
  }

  const content = [
    <PersonalDataSection form={form} handleChangeForm={handleChangeForm} syncData={syncData} errorKeys={errorKeys} phoneNumberValidation={phoneNumberValidation} />,
    <AddressSecton form={form} handleChangeForm={handleChangeForm} syncData={syncData} errorKeys={errorKeys} phoneNumberValidation={phoneNumberValidation} />,
    <LastEducationSection form={form} handleChangeForm={handleChangeForm} syncData={syncData} errorKeys={errorKeys} phoneNumberValidation={phoneNumberValidation} />,
    <ContactSection form={form} handleChangeForm={handleChangeForm} syncData={syncData} errorKeys={errorKeys} phoneNumberValidation={phoneNumberValidation} />,
    <OtherSection form={form} handleChangeForm={handleChangeForm} errorKeys={errorKeys} banks={banks} phoneNumberValidation={phoneNumberValidation} />
  ]


  return (
    <div className={Styles.container}>
      {
        [
          'Personal Data',
          'Alamat',
          'Pendidikan Terakhir',
          'Kontak',
          'Data Lainnya'
        ]?.map((each, i) => (
          <div key={i} className={Styles.section}>
            <span>{each}</span>
            {content[i]}
          </div>
        ))
      }
    </div>
  )
}

const PersonalDataSection = ({
  form,
  handleChangeForm,
  syncData,
  errorKeys = [],
  phoneNumberValidation = () => { }
}) => {

  const template = [
    {
      name: 'name',
      title: 'Nama',
      placeholder: 'Nama sesuai KTP',
      isRequired: true,
      disabled: false,
      readOnly: false
    },
    {
      name: 'birthPlace',
      title: 'Tempat Lahir',
      placeholder: 'Tempat lahir',
      isRequired: true,
      disabled: false,
      readOnly: false
    },
    {
      name: 'birthDate',
      title: 'Tanggal Lahir',
      placeholder: 'Tanggal lahir',
      isRequired: true,
      disabled: false,
      readOnly: false,
      isDate: true,
      noUpperCase: true,
    },
    {
      name: 'gender',
      title: 'Jenis Kelamin',
      placeholder: 'Jenis kelamin',
      isRequired: true,
      disabled: false,
      readOnly: false,
      isDropdown: true,
      dropdownOptions: [
        'Laki - laki',
        'Perempuan',
      ]?.map(obj => {
        return {
          name: obj?.toUpperCase()
        }
      })
    },
    {
      name: 'religion',
      title: 'Agama',
      placeholder: 'Pilih salah satu',
      isRequired: true,
      disabled: false,
      readOnly: false,
      isDropdown: true,
      dropdownOptions: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Religion")?.map(obj => {
          return {
            name: obj?.value
          }
        })
    },
    {
      name: 'bloodType',
      title: 'Golongan Darah',
      placeholder: 'Pilih salah satu',
      isRequired: true,
      disabled: false,
      readOnly: false,
      isDropdown: true,
      dropdownOptions: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Blood Type")?.map(obj => {
          return {
            name: obj?.value
          }
        })
    },
    {
      name: 'height',
      title: 'Tinggi Badan',
      placeholder: 'Tinggi badan anda',
      // isRequired: true,
      disabled: false,
      readOnly: false,
      onKeyDown: (e) => {
        var key = e.which || e.keyCode;
        if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
          if (key != 9) {
            e.preventDefault();
          }
        }
      },
      endAdornment: <span className={Styles.adornment}>Cm</span>
    },
    {
      name: 'weight',
      title: 'Berat Badan',
      placeholder: 'Berat badan anda',
      // isRequired: true,
      disabled: false,
      readOnly: false,
      onKeyDown: (e) => {
        var key = e.which || e.keyCode;
        if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
          if (key != 9) {
            e.preventDefault();
          }
        }
      },
      endAdornment: <span className={Styles.adornment}>Kg</span>
    },
    {
      name: 'maritalStatus',
      title: 'Status Kawin',
      placeholder: 'Pilih salah satu',
      isRequired: true,
      disabled: false,
      readOnly: false,
      isDropdown: true,
      dropdownOptions1: [
        'TK',
        'K0',
        'K1',
        'K2',
        'K3',
      ]?.map(obj => {
        return {
          name: obj
        }
      }),
      dropdownOptions: syncData?.dropdownOptions?.filter(obj => obj?.type === 'Marital Status')?.map(obj => {
        return {
          name: obj?.value?.toUpperCase()
        }
      })
    },
    {
      name: 'familyNumber',
      title: 'Nomor Kartu Keluarga',
      placeholder: 'Nomor KK',
      isRequired: true,
      disabled: false,
      readOnly: false,
      maxLength: 16,
      onKeyDown: (e) => {
        var key = e.which || e.keyCode;
        if (e?.target?.value?.length === 16) {
          // e?.preventDefault()
        } else {
          if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
            if (key != 9) {
              e.preventDefault();
            }
          }
        }

      },
    },
    {
      name: 'idCardNumber',
      title: 'Nomor KTP',
      placeholder: 'Nomor KTP',
      isRequired: true,
      disabled: false,
      readOnly: false,
      maxLength: 16,
      onKeyDown: (e) => {
        var key = e.which || e.keyCode;
        if (e?.target?.value?.length === 16) {
          // e?.preventDefault()
        } else {
          if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
            if (key != 9) {
              e.preventDefault();
            }
          }
        }

      },
    },
    {
      name: 'idCardPeriod',
      title: 'Masa Berlaku KTP',
      placeholder: 'Pilih tanggal',
      isRequired: true,
      disabled: form?.lifeTimeIDCard,
      readOnly: false,
      isDate: true,
      noUpperCase: true,
      extra:
        <CheckboxField
          title="Seumur hidup"
          value={form?.lifeTimeIDCard}
          setValue={(newVal) => handleChangeForm('lifeTimeIDCard', newVal, true)}
        />
    },
    {
      name: 'taxNumber',
      title: 'Nomor NPWP',
      placeholder: 'Nomor NPWP',
      isRequired: true,
      disabled: form?.noTaxNumber,
      readOnly: false,
      onKeyDown: (e) => {
        var key = e.which || e.keyCode;
        if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
          if (key != 9) {
            e.preventDefault();
          }
        }
      },
      extra:
        <CheckboxField
          title="Tidak memiliki NPWP"
          value={form?.noTaxNumber}
          setValue={(newVal) => handleChangeForm('noTaxNumber', newVal, true)}
        />
    },
    {
      name: 'email',
      title: 'Email',
      placeholder: 'example@mail.com',
      isRequired: true,
      disabled: false,
      readOnly: false
    },
    {
      name: 'phoneNumber',
      title: 'Nomor HP',
      placeholder: '8xxx',
      maxLength: 12,
      isRequired: true,
      disabled: false,
      readOnly: false,
      onKeyDown: phoneNumberValidation,
      startAdornment: <span className={Styles.adornment}>+62</span>
    },
    {
      name: 'birthMotherName',
      title: 'Nama Ibu Kandung',
      placeholder: 'Nama ibu kandung anda',
      // isRequired: true,
      disabled: false,
      readOnly: false
    },

    {
      name: 'birthMotherPhoneNumber',
      title: 'Nomor HP Ibu Kandung',
      placeholder: '8xxx',
      isRequired: false,
      disabled: false,
      readOnly: false,
      onKeyDown: phoneNumberValidation,
      startAdornment: <span className={Styles.adornment}>+62</span>
    },

  ]

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);


  return (
    <div className={`${Styles.personalData} ${Styles.grid}`}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {
        template?.map((t, tI) => (
          <div key={tI} className={Styles.eachP}>
            {
              !t?.isDate && !t?.isDropdown
              &&
              <InputField
                title={t?.title}
                value={t?.disabled ? '' : form[t?.name]}
                onChange={(e) => handleChangeForm(t?.name, e?.target?.value, typeof e?.target?.value == Boolean ? true : false)}
                placeholder={t?.placeholder}
                required={t?.isRequired}
                disabled={t?.disabled}
                readOnly={t?.readOnly}
                floatingStartAdornment={t?.startAdornment}
                floatingEndAdornment={t?.endAdornment}
                isError={errorKeys?.find(obj => obj === t?.name) && !form[t?.name]}
                errorText={`${t?.title} belum diisi`}
                type={t?.type || ''}
                onKeyDown={t?.onKeyDown}
                onInput={t?.onKeyDown}
                maxLength={t?.maxLength}
              />
            }
            {
              t?.isDate
              &&
              <InputDateField
                title={t?.title}
                value={t?.disabled ? '' : form[t?.name] || ''}
                setValue={(newVal) => handleChangeForm(t?.name, newVal, true)}
                iconColor={"#1571DE"}
                placeholder={t?.placeholder}
                required={t?.isRequired}
                disabled={t?.disabled}
                readOnly={t?.readOnly}
                isError={errorKeys?.find(obj => obj === t?.name) && (!form[t?.name] || form[t?.name] === 'NaN-NaN-NaN')}
                errorText={`${t?.title} belum diisi`}
              />
            }
            {
              t?.isDropdown
              &&
              <div>
                <InputTitle
                  title={t?.title}
                  required={t?.isRequired}
                />
                <Dropdown
                  value={form[t?.name]}
                  setValue={(newVal) => handleChangeForm(t?.name, newVal)}
                  placeholder={t?.placeholder}
                  required={t?.isRequired}
                  disabled={t?.disabled}
                  readOnly={t?.readOnly}
                  iconColor={"#1571DE"}
                  options={t?.dropdownOptions}
                  isError={errorKeys?.find(obj => obj === t?.name) && !form[t?.name]}
                  errorText={`${t?.title} belum diisi`}
                  autoClose
                />

              </div>
            }
            {t?.extra
              &&
              t?.extra
            }
          </div>
        ))
      }
    </div>
  )
}

const AddressSecton = ({
  form,
  handleChangeForm,
  syncData,
  errorKeys = []
}) => {

  const isDisabled = (a) => {
    return !a?.includes('KTP') && form['isDomicileTheSame']
  }

  return (
    <div className={Styles.address}>
      {
        [
          'Alamat KTP',
          'Alamat Domisili'
        ]?.map((a, aI) => (
          <div key={aI} className={Styles.eachA}>
            <span>{a}</span>
            {!a?.includes('KTP')
              &&
              <div className={Styles.isDomicileTheSame}>
                <CheckboxField
                  title={"Alamat domisili sama dengan alamat KTP"}
                  value={form?.isDomicileTheSame}
                  setValue={(newVal) => handleChangeForm('isDomicileTheSame', newVal, true, true)}
                />
              </div>
            }
            <div className={Styles.aFields}>
              <div className={Styles.addressField}>
                <InputTitle
                  title={'Alamat'}
                  required
                />
                <Textarea
                  value={form[a?.includes('KTP') ? 'idCardAddress' : 'domicileAddress']?.toUpperCase()}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardAddress' : 'domicileAddress', e?.target?.value)}
                  placeholder={'Tulis alamat anda'}
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardAddress' : 'domicileAddress') && !form[a?.includes('KTP') ? 'idCardAddress' : 'domicileAddress']}
                  errorText={`Alamat belum diisi`}
                />

              </div>
              <div className={`${Styles.aFieldsSection} ${Styles.grid}`}>
                <InputField
                  title={'RT'}
                  value={form[a?.includes('KTP') ? 'idCardRT' : 'domicileRT']}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardRT' : 'domicileRT', e?.target?.value)}
                  placeholder={'000'}
                  // required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardRT' : 'domicileRT') && !form[a?.includes('KTP') ? 'idCardRT' : 'domicileRT']}
                  errorText={`RT belum diisi`}
                  onKeyDown={(e) => {
                    var key = e.which || e.keyCode;
                    if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
                      if (key != 9) {
                        e.preventDefault();
                      }
                    }
                  }}
                  maxLength={3}
                />
                <InputField
                  title={'RW'}
                  value={form[a?.includes('KTP') ? 'idCardRW' : 'domicileRW']}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardRW' : 'domicileRW', e?.target?.value)}
                  placeholder={'000'}
                  // required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardRW' : 'domicileRW') && !form[a?.includes('KTP') ? 'idCardRW' : 'domicileRW']}
                  errorText={`RW belum diisi`}
                  onKeyDown={(e) => {
                    var key = e.which || e.keyCode;
                    if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
                      if (key != 9) {
                        e.preventDefault();
                      }
                    }
                  }}
                  maxLength={3}
                />
                <InputField
                  title={'Kelurahan'}
                  value={form[a?.includes('KTP') ? 'idCardKelurahan' : 'domicileKelurahan']?.toUpperCase()}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardKelurahan' : 'domicileKelurahan', e?.target?.value)}
                  placeholder={'Masukkan kelurahan'}
                  required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardKelurahan' : 'domicileKelurahan') && !form[a?.includes('KTP') ? 'idCardKelurahan' : 'domicileKelurahan']}
                  errorText={`Kelurahan belum diisi`}
                />
                <InputField
                  title={'Kecamatan'}
                  value={form[a?.includes('KTP') ? 'idCardKecamatan' : 'domicileKecamatan']?.toUpperCase()}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardKecamatan' : 'domicileKecamatan', e?.target?.value)}
                  placeholder={'Masukkan kecamatan'}
                  required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardKecamatan' : 'domicileKecamatan') && !form[a?.includes('KTP') ? 'idCardKecamatan' : 'domicileKecamatan']}
                  errorText={`Kecamatan belum diisi`}
                />
                <AutocompleteField
                  title={'Kabupaten/Kota'}
                  value={form[a?.includes('KTP') ? 'idCardCity' : 'domicileCity']}
                  setValue={(newVal) => handleChangeForm(a?.includes('KTP') ? 'idCardCity' : 'domicileCity', newVal)}
                  iconColor={"#1571DE"}
                  placeholder={'Pilih kabupaten/kota'}
                  searchOptions={syncData?.cities?.sort()}
                  required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardCity' : 'domicileCity') && !form[a?.includes('KTP') ? 'idCardCity' : 'domicileCity']}
                  errorText={`Kabupaten/kota belum diisi`}
                />
                <AutocompleteField
                  title={'Provinsi'}
                  value={form[a?.includes('KTP') ? 'idCardProvince' : 'domicileProvince']}
                  setValue={(newVal) => handleChangeForm(a?.includes('KTP') ? 'idCardProvince' : 'domicileProvince', newVal)}
                  placeholder={'Pilih provinsi'}
                  iconColor={"#1571DE"}
                  searchOptions={syncData?.provinces?.sort()}
                  required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardProvince' : 'domicileProvince') && !form[a?.includes('KTP') ? 'idCardProvince' : 'domicileProvince']}
                  errorText={`Provinsi belum diisi`}

                />
                <InputField
                  title={'Kode Pos'}
                  value={form[a?.includes('KTP') ? 'idCardPostalCode' : 'domicilePostalCode']}
                  onChange={(e) => handleChangeForm(a?.includes('KTP') ? 'idCardPostalCode' : 'domicilePostalCode', e?.target?.value)}
                  placeholder={'000'}
                  // required
                  disabled={isDisabled(a)}
                  isError={errorKeys?.find(obj => obj === a?.includes('KTP') ? 'idCardPostalCode' : 'domicilePostalCode') && !form[a?.includes('KTP') ? 'idCardPostalCode' : 'domicilePostalCode']}
                  errorText={`Kode pos belum diisi`}
                  onKeyDown={(e) => {
                    var key = e.which || e.keyCode;
                    if (key && (key <= 47 || key >= 58) && key != 8 && key != 37 && key != 39) {
                      if (key != 9) {
                        e.preventDefault();
                      }
                    }
                  }}
                  maxLength={5}
                />
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}



const LastEducationSection = ({
  form,
  handleChangeForm,
  syncData,
  errorKeys = []
}) => {

  return (
    <div className={`${Styles.lastEducation} ${Styles.grid}`}>
      <div className={Styles.lastEducationType}>
        <InputTitle
          title={'Jenis Pendidikan'}
          required
        />
        <Dropdown
          value={form['lastEducationType']?.toUpperCase()}
          setValue={(newVal) => handleChangeForm('lastEducationType', newVal)}
          options={syncData?.dropdownOptions
            ?.filter(obj => obj?.type === 'Education')
            ?.map(obj => {
              return {
                name: obj?.value?.toUpperCase()
              }
            })
          }
          iconColor={"#1571DE"}
          placeholder={'Pilih jenis pendidikan'}
          isError={errorKeys?.find(obj => obj === 'lastEducationType') && !form?.lastEducationType}
          errorText={`Jenis Pendidikan belum diisi`}
          autoClose
        />
      </div>
      <InputField
        title={'Nama Sekolah/Universitas'}
        value={form['lastEducationInstitution']}
        onChange={(e) => handleChangeForm('lastEducationInstitution', e?.target?.value)}
        placeholder={'Nama Sekolah/Universitas'}
        required
        isError={errorKeys?.find(obj => obj === 'lastEducationInstitution') && !form?.lastEducationInstitution}
        errorText={`Nama Sekolah/Universitas belum diisi`}
      />
      <InputField
        title={'Fakultas/Jurusan'}
        value={form['lastEducationFaculty']?.toUpperCase()}
        onChange={(e) => handleChangeForm('lastEducationFaculty', e?.target?.value)}
        placeholder={'Fakultas/Jurusan'}
        required
        isError={errorKeys?.find(obj => obj === 'lastEducationFaculty') && !form?.lastEducationFaculty}
        errorText={`Fakultas/Jurusan belum diisi`}
      />
      <InputField
        title={'IPK/Nilai Akhir'}
        value={form['lastEducationGPA']}
        onChange={(e) => handleChangeForm('lastEducationGPA', e?.target?.value)}
        placeholder={'000'}
        // required
        isError={errorKeys?.find(obj => obj === 'lastEducationGPA') && !form?.lastEducationGPA}
        errorText={`IPK/Nilai Akhir belum diisi`}
      />
    </div>
  )
}

const ContactSection = ({
  form,
  handleChangeForm,
  syncData,
  errorKeys = [],
  phoneNumberValidation = () => { }
}) => {

  const template = [
    [
      'emergencyContactName',
      'emergencyContactRelation',
      'emergencyContactPhoneNumber',
      'emergencyContactAddress'
    ],
    [
      'sameHouseContactName',
      'sameHouseContactRelation',
      'sameHouseContactPhoneNumber',
      'sameHouseContactAddress'
    ],
    [
      'differentHouseContactName',
      'differentHouseContactRelation',
      'differentHouseContactPhoneNumber',
      'differentHouseContactAddress'
    ]
  ]

  return (
    <div className={`${Styles.contact}`}>
      {
        [
          'Kontak Darurat',
          'Kontak Keluarga Serumah',
          'Kontak Keluarga Tidak Serumah'
        ]
          ?.map((c, cI) => (
            <div key={cI} className={Styles.subSection}>
              <span>{c}</span>
              <div className={Styles.grid}>
                <InputField
                  title={'Nama'}
                  placeholder={'Nama pemilik kontak'}
                  value={form[template[cI][0]]}
                  onChange={(e) => handleChangeForm(template[cI][0], e?.target?.value)}
                  required={!c?.toLowerCase()?.includes('serumah')}
                  isError={errorKeys?.find(obj => obj === template[cI][0]) && !form[template[cI][0]]}
                  errorText={`Nama belum diisi`}

                />
                <DropdownField
                  title={'Hubungan Dengan Pemilik Kontak '}
                  placeholder={'Hubungan dengan pemilik kontak'}
                  iconColor={"#1571DE"}
                  value={form[template[cI][1]]?.toUpperCase()}
                  setValue={(newVal) => handleChangeForm(template[cI][1], newVal)}
                  options={syncData?.dropdownOptions?.filter(obj => obj?.type === 'Family Relationship')?.map(obj => {
                    return {
                      name: obj?.value?.toUpperCase()
                    }
                  })
                  }
                  autoClose
                  required={!c?.toLowerCase()?.includes('serumah')}
                  isError={errorKeys?.find(obj => obj === template[cI][1]) && !form[template[cI][1]]}
                  errorText={`Hubungan Dengan Pemilik Kontak belum diisi`}

                />
                <InputField
                  title={'Nomor HP'}
                  placeholder={'8xxx'}
                  value={form[template[cI][2]]}
                  onChange={(e) => handleChangeForm(template[cI][2], e?.target?.value)}
                  floatingStartAdornment={<span className={Styles.adornment}>+62</span>}
                  required={!c?.toLowerCase()?.includes('serumah')}
                  isError={errorKeys?.find(obj => obj === template[cI][2]) && !form[template[cI][2]]}
                  errorText={`Nomor HP belum diisi`}
                  onKeyDown={phoneNumberValidation}
                  maxLength={12}
                />
              </div>
              <div className={Styles.addressField}>
                <InputTitle
                  title={'Alamat'}
                  // required={!c?.toLowerCase()?.includes('serumah')}
                  required
                />
                <Textarea
                  value={form[template[cI][3]]}
                  onChange={(e) => handleChangeForm(template[cI][3], e?.target?.value)}
                  placeholder={'Tulis alamat pemilik kontak'}
                  isError={errorKeys?.find(obj => obj === template[cI][3]) && !form[template[cI][3]]}
                  errorText={`Alamat belum diisi`}
                />
              </div>
            </div>
          ))
      }
    </div>
  )
}

const OtherSection = ({
  form,
  handleChangeForm,
  errorKeys = [],
  banks = [],
}) => {

  return (
    <div className={Styles.other}>
      <div className={Styles.grid}>
        <AutocompleteField
          title={'Bank'}
          value={form['bankName']}
          setValue={(newVal) => handleChangeForm('bankName', newVal)}
          placeholder={'Pilih bank'}
          searchOptions={banks?.map(obj => obj?.name)}
          iconColor={"#1571DE"}
          required
          isError={errorKeys?.find(obj => obj === 'bankName') && !form['bankName']}
          errorText={`Bank belum diisi`}
        />
        <InputField
          title={'Nomor Rekening'}
          value={form['accountNumber']}
          onChange={(e) => handleChangeForm('accountNumber', e?.target?.value)}
          placeholder={'000'}
          required
          isError={errorKeys?.find(obj => obj === 'accountNumber') && !form['accountNumber']}
          errorText={`Nomor Rekening belum diisi`}
        />
        <InputField
          title='Klinik/Puskesmas Terdekat'
          value={form['nearestHealthFacility']}
          onChange={(e) => handleChangeForm('nearestHealthFacility', e?.target?.value)}
          placeholder={'Tulis nama klinik/puskesmas'}
          isError={errorKeys?.find(obj => obj === 'nearestHealthFacility') && !form['nearestHealthFacility']}
          errorText={`Klinik/Puskesmas Terdekat belum diisi`}
          required
        />
      </div>
      <div className={Styles.subSection}>
        <span>{'Hobi'}</span>
        <div className={Styles.grid}>
          <InputField
            title={'Hobi'}
            value={form['hobby']?.toUpperCase()}
            onChange={(e) => handleChangeForm('hobby', e?.target?.value)}
            placeholder={'Tulis hobi'}
          />
        </div>
      </div>
      <div className={Styles.subSection}>
        <span>Sosial Media</span>
        <div className={Styles.grid}>
          <InputField
            title={'Facebook'}
            value={form['fbUsername']}
            onChange={(e) => handleChangeForm('fbUsername', e?.target?.value, true)}
            placeholder={'Username'}
          />
          <InputField
            title={'Instagram'}
            value={form['igUsername']}
            onChange={(e) => handleChangeForm('igUsername', e?.target?.value, true)}
            placeholder={'Username'}
          />
          <InputField
            title={'X'}
            value={form['xUsername']}
            onChange={(e) => handleChangeForm('xUsername', e?.target?.value, true)}
            placeholder={'Username'}
          />
          <InputField
            title={'Linkedin'}
            value={form['linkedinUsername']}
            onChange={(e) => handleChangeForm('linkedinUsername', e?.target?.value, true)}
            placeholder={'Username'}
          />
        </div>
      </div>
    </div>
  )
}

