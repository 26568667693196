import { API } from "../config/api";

export function login(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/login`, employeeId);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function resendOTP(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/resend-otp/${employeeId}`, null);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export const verifyOTP = (hash, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verify-otp/${hash}`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const signIn = () =>
  new Promise(async (resolve, reject) => {
    try {
    } catch (error) {}
  });

export const getFLBData = (hash) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`order/flb/${hash}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
