import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useState } from "react";
import InputDate from "@Atom/InputDate";
import { getDownloadReportFLBList } from "@Services/hc/flbVerification";
import moment from "moment";
import ExcelJS from "exceljs";
import Spinner from "@Atom/Spinner";

export default function FilterDownloadReport({
  handleClose,
  isLoadingDownload,
  setIsLoadingDownload,
  type,
}) {
  const [date, setDate] = useState({
    start: "",
    end: "",
  });

  const [error, setError] = useState("");
  const [validate, setValidate] = useState({
    start: false,
    end: false,
  });

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      if (!date?.start || !date?.end) {
        if (!date?.start) {
          setValidate((prev) => ({
            ...prev,
            start: true,
          }));
        }

        if (!date?.end) {
          setValidate((prev) => ({
            ...prev,
            end: true,
          }));
        }
        setError("Tolong isi field yang masih kosong");
      } else {
        setIsLoadingDownload(true);
        const data = await getDownloadReportFLBList(
          type,
          date?.start ? moment(new Date(date?.start)).format("YYYY-MM-DD") : "",
          date?.end ? moment(new Date(date?.end)).format("YYYY-MM-DD") : ""
        );

        if (data?.code === 200) {
          setIsLoadingDownload(false);
          handleClose();
          setDate({
            start: "",
            end: "",
          });
          setValidate({
            start: false,
            end: false,
          });
          setError("");
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("My Sheet");
          sheet.columns = [
            {
              header: "No",
              key: "no",
              width: 10,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "No Dokumen",
              key: "docNumber",
              width: 30,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "Nama Klien",
              key: "client",
              width: 30,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "BM",
              key: "businessManager",
              width: 30,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "Tanggal & Waktu",
              key: "updatedAt",
              width: 30,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "Masa Aktif",
              key: "expiredDate",
              width: 30,
              style: {
                font: { bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              header: "Link FLB",
              key: "link",
              width: 30,
              style: {
                font: { bold: true, color: { argb: "FF1571DE" } },
                alignment: { horizontal: "center" },
              },
            },
          ];

          const headerRow = sheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FF000000" } }; // Set header text color to black
            cell.alignment = { horizontal: "center" };
          });

          data?.response?.map((el, i) => {
            return sheet.addRow({
              no: i + 1,
              docNumber: `${el?.clientContracts?.docType || "-"} • ${
                el?.clientContracts?.docNumber || "-"
              }`,
              client: el?.Client?.name || "",
              businessManager: el?.businessManager?.name || "",
              updatedAt: el?.updatedAt
                ? moment(new Date(el?.updatedAt)).format("DD MMM YYYY, HH:mm")
                : "",

              expiredDate: `${
                el?.clientContracts?.startDate
                  ? moment(new Date(el?.clientContracts?.startDate)).format(
                      "DD MMM YYYY"
                    )
                  : "-"
              } - ${
                el?.clientContracts?.endDate
                  ? moment(new Date(el?.clientContracts?.endDate)).format(
                      "DD MMM YYYY"
                    )
                  : "-"
              }`,

              link: { text: "Lihat FLB", hyperlink: el?.link },
            });
          });

          workbook.xlsx
            .writeBuffer()
            .then((data) => {
              // setIsLoading(true);
              const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
              });

              const url = window.URL.createObjectURL(blob);
              const anchor = document.createElement("a");
              anchor.href = url;
              anchor.download = `Report Data (${type}) ${moment(
                new Date(date?.start)
              )?.format("DD MMMM YYYY")} - ${moment(
                new Date(date?.end)
              )?.format("DD MMMM YYYY")}.xlsx`;
              anchor.click();
              window.URL.revokeObjectURL(url);
              // setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              // setIsLoading(false);
            });
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoadingDownload(false);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div className={Styles.icon}>
          <Icon icon={"arrow-down-tray"} size={24} color={"#1571DE"} />
        </div>

        <div className={Styles.downloadTitle}>
          <span>Download Report</span>
          <span>Silahkan untuk memilih tanggal terlebih dahulu</span>
        </div>

        <div className={Styles.dateForm}>
          <div className={Styles.startDate}>
            <span>Dari</span>
            <div className={Styles.searchWrapper}>
              <InputDate
                isError={validate?.start && !date?.start}
                value={date?.start}
                placeholder={"Pilih tanggal"}
                setValue={(newVal) => {
                  const newForm = { ...date };
                  newForm.start = newVal;
                  if (newVal > date?.end) {
                    newForm.end = "";
                  }

                  setDate(newForm);
                }}
              />
            </div>
          </div>

          <div className={Styles.endDate}>
            <span>Sampai</span>
            <div className={Styles.searchWrapper}>
              <InputDate
                isError={validate?.end && !date?.end}
                value={date?.end}
                placeholder={"Pilih tanggal"}
                setValue={(newVal) => {
                  const newForm = { ...date };
                  newForm.end = newVal;
                  setDate(newForm);
                }}
                disabled={!date?.start}
                min={date?.start}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.bottom}>
        {error && <span>{error || "-"}</span>}
        <div
          className={`${Styles.button} ${isLoadingDownload && Styles.disabled}`}
          onClick={isLoadingDownload ? null : handleDownload}
        >
          {isLoadingDownload ? <Spinner /> : "Download"}
        </div>
      </div>
    </div>
  );
}
