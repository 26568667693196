import Icon from "@Atom/Icon";
import { Breadcrumbs } from "@mui/material";
import React from "react";

export default function Breadcrumb({ children }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<Icon icon="arrow-right" size={"20px"} />}
    >
      {children}
    </Breadcrumbs>
  );
}
