import Icon from "@Atom/Icon";
import useQuery from "@Hooks/useQuery";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Pagination from "@Molecule/Pagination";
import { getClientOrders } from "@Services/manager/client";
import { useDebounce } from "@Hooks/useDebounce";

export default function ClientWorkSchemesLayout({
  defaultOrderData,
  defaultTotalPage,
  searchTerm,
  sort
}) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const query = useQuery();
  const name = query.get("n");
  const code = query.get("c");

  const [orderData, setOrderData] = useState(defaultOrderData || [])
  const [totalPage, setTotalPage] = useState(defaultTotalPage || 0)



  const [value, setValue] = useState(10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setSelectedPage(1)
  }, [value])

  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const renewData = useCallback(async () => {
    const { response } = await getClientOrders(code, selectedPage, value, debouncedSearchTerm, sort)
    setOrderData(response?.data)
    setTotalPage(response?.totalPage)
  }, [
    code,
    selectedPage,
    value,
    debouncedSearchTerm,
    sort
  ])

  useEffect(() => {
    renewData()
  }, [renewData])

  const schemes = useMemo(() => {
    const arr = [];
    const sortedData = orderData?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt);
    });

    for (let i = 0; i < sortedData.length; i++) {
      const data = sortedData[i];


      const each = {
        id: data?.id,
        type:
          data?.contractType?.toLowerCase() === "jasa pemborongan"
            ? "PKM"
            : "PJTK",
        locations: data?.cities?.join(", "),
        jobs: data?.manPower,
        date: moment(new Date(data?.createdAt)).format("LL"),
        status: data?.status
      };

      arr.push(each);
    }
    return arr;
  }, [orderData,]);


  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);



  const handleViewDetail = (id) => {
    navigate(`${pathname}/details/${id}?n=${name}&c=${code}`);
  };



  const handleNavigateToEdit = (id) => {
    navigate(`${pathname}/edit?n=${name}&c=${code}&i=${id}`)
  }
  const handleNavigateToPreview = (id) => {
    // navigate(`${pathname}/pdf-preview/${id}`)
    window.open(`${pathname}/pdf-preview/${id}`, '_blank', 'rel=noopener noreferrer')
  }



  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {schemes?.map((each, i) => {
        return (
          <div key={i} className={Styles.schemeCard}>
            <div className={Styles.top}>
              <div className={Styles.left}>
                <Icon icon="work-scheme" className={Styles.icon} size={27} />
              </div>
              <div className={Styles.middle}>
                <h5>{each?.jobs?.slice(0, 3)?.join(" • ")}{each?.jobs?.length > 3 && ` +${each?.jobs?.length - 3} more`}</h5>
                <span>{each?.type}</span>
                <div className={Styles.location}>
                  <Icon icon="location" size={18} />
                  <span>{each?.locations}</span>
                </div>
                <span>{each?.date}</span>
              </div>
              <div className={`${Styles.status} ${each?.status === 'DRAFT' ? Styles.draft : (each?.status === 'SENT' ? Styles.sent : Styles?.incomplete)}`}>
                <span>{each?.status === 'DRAFT' ? 'Draft' : (each?.status === 'SENT' ? 'Sent' : 'Incomplete')}</span>
              </div>
            </div>
            <div className={Styles.bottom}>
              {each?.status !== ('INCOMPLETE')
                && <button onClick={() => handleNavigateToPreview(each?.id)}>
                  <span>Preview Pdf</span>
                  <Icon icon={"eye-visible"} size={20} />
                </button>
              }

              <button onClick={() => handleNavigateToEdit(each?.id)}>
                <span>Edit</span>
                <Icon icon={"edit"} size={20} />
              </button>
              <div className={`${Styles.seeDetail} ${each?.status === ('INCOMPLETE') ? Styles.hide : ''}`}>
                <button onClick={() => handleViewDetail(each?.id)}>
                  Lihat Detail
                </button>
              </div>
            </div>

          </div>
        );
      })}
      {!schemes?.length && (
        <div className={Styles.emptyCard}>
          no order to show
        </div>
      )}
      <div className={Styles.paginationContainer}>
        <Pagination
          total={schemes?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </div>
  );
}
