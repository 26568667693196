import CheckboxJobPosting from '@Atom/CheckboxJobPosting'
import StackedProfile from '@Atom/StackedProfile'
import Images from '@Theme/Images'
import { useMemo } from 'react'
import Styles from './style.module.scss'
import { fileBaseUrl } from '@Config/api'

export default function PICModal({ data }) {

  const list = useMemo(() => {
    const arr = []
    for (let i = 0; i < data?.delegations?.length; i++) {
      const perLevel = data?.delegations[i]
      for (let j = 0; j < perLevel?.peopleInCharge?.length; j++) {
        arr?.push(perLevel?.peopleInCharge[j])
      }
    }
    return arr
  }, [data?.delegations])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>PIC Management</h3>
        <span>Checklist pada nama yang ingin anda jadikan PIC</span>
      </div>
      <div className={Styles.content}>
        {
          list?.map((each, i) => (
            <div key={i} className={Styles.each}>
              <CheckboxJobPosting />
              <StackedProfile
                profilePicture={each?.User?.profilePicture ? fileBaseUrl + each?.User?.profilePicture : Images.AVA_DEFAULT}
                title={each?.User?.name}
                label={each?.User?.jobLevel}
              />
            </div>
          ))
        }
      </div>
      <div className={Styles.actions}>
        <button>Cancel</button>
        <button>Save</button>
      </div>
    </div>
  )
}