import FLBVerificationDetailsLayout from "@Organism/HumanCapital/FLBVerification/FLBVerificationDetails";
import { getFLBDetails } from "@Services/hc/flbVerification";
import NavProvider from "../../../../../Context/NavContext";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { id } = params;

  return defer({
    packageFLBDetails: getFLBDetails(id),
  });
}

export default function FLBVerificationDetailsPage() {
  const { packageFLBDetails } = useLoaderData();
  return (
    <NavProvider>
      <Suspense fallback={<FLBVerificationDetailsLayout isLoading />}>
        <Await resolve={packageFLBDetails}>
          {(packageFLBDetails) => (
            <FLBVerificationDetailsLayout
              defaultData={packageFLBDetails?.response}
            />
          )}
        </Await>
      </Suspense>
    </NavProvider>
  );
}
