import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function NotesRemarkDec({ handleClose, handleDone, data }) {
  const boxRef = useRef();

  // console.log(images, "ini apa");

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);
  return (
    <div className={Styles.bodyNotif}>
      <div className={Styles.contentNotif} ref={boxRef}>
        <div className={Styles.topNotif}>
          <div className={Styles.left}>
            <div className={Styles.icon}>
              <Icon icon={"notes-bs"} size={32} />
            </div>
            <span className={Styles.cat}>Catatan</span>
          </div>
          <div className={Styles.iconClose} onClick={handleClose}>
            <Icon icon={"cross"} size={24} />
          </div>
        </div>

        <div className={Styles.containerNotif}>
          <div className={Styles.top}>
            <span className={Styles.spanOne}>
              Berikut catatan yang telah anda masukan:
            </span>
            <span className={Styles.spanTwo}>{data || "-"}</span>
          </div>

          <div className={Styles.buttonClose} onClick={handleClose}>
            Tutup
          </div>
        </div>
      </div>
    </div>
  );
}
