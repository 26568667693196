import useQuery from "@Hooks/useQuery";
import {
  addClientContact,
  updateClientContact,
} from "@Services/manager/client";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import { validateEmail } from "@Helpers/validateEmail";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";

export default function AddContactInfoModal({
  setShowAdd,
  setTrigger,
  editMode,
  defaultValue,
}) {
  const query = useQuery();
  const clientCode = query.get("c");
  const [form, setForm] = useState({
    name: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    workUnit: "",
    clientCode: clientCode,
  });

  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [errorField, setErrorField] = useState(false);

  // const handleChangeForm = (e) => {
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       [e.target.name]: e.target.value,
  //     };
  //   });
  // };

  const handleChangeForm = (name, e) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (defaultValue && editMode) {
      setForm((prev) => {
        return {
          ...prev,
          name: defaultValue?.name,
          jobTitle: defaultValue?.jobTitle,
          email: defaultValue?.email,
          phoneNumber: defaultValue?.phoneNumber
            ? defaultValue?.phoneNumber?.slice(
                2,
                defaultValue?.phoneNumber?.length
              )
            : "",
          workUnit: defaultValue?.workUnit,
          clientCode: defaultValue?.clientCode,
        };
      });
    }
  }, [defaultValue, editMode]);

  const handleAdd = async (e) => {
    setIsSubmiting(true);
    try {
      if (!allFilledObjChecker(form) || !validateEmail(form?.email)) {
        setErrorField(true);
        setIsSubmiting(false);
      } else if (form?.phoneNumber && form?.phoneNumber?.length < 8) {
        setErrorField(true);
        setIsSubmiting(false);
      } else {
        e.preventDefault();
        setErrorField(false);
        let fixedForm = form;
        fixedForm.phoneNumber = "62" + form.phoneNumber;
        if (editMode) {
          await updateClientContact(defaultValue?.id, fixedForm);
          setIsSubmiting(false);
        } else {
          await addClientContact(fixedForm);
          setIsSubmiting(false);
        }
        setTrigger(Math.random());
        // navigate(
        //   `${pathname}?n=${clientName}&c=${clientCode}&h=${String(
        //     Math.random()
        //   ).slice(2)}`
        // );
        setShowAdd(false);
      }
    } catch (err) {
      setError(err.response.data.error);
      setIsSubmiting(false);
      setErrorField(false);
    }
  };
  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>{editMode ? "Edit" : "Add"} Contact Information</h3>
        </div>
        <div className={Styles.content}>
          <div className={Styles.formWrapper}>
            <InputField
              title={"Name"}
              value={form?.name}
              onChange={(e) => handleChangeForm("name", e)}
              placeholder={"Enter name"}
              required
              isErrorV2
              isError={errorField && !form?.name}
              errorText="Please fill the field"
            />
            <InputField
              title={"Position"}
              value={form?.jobTitle}
              onChange={(e) => handleChangeForm("jobTitle", e)}
              placeholder={"Work position"}
              required
              isErrorV2
              isError={errorField && !form?.jobTitle}
              errorText="Please fill the field"
            />
            <InputField
              title={"Email"}
              type={"email"}
              value={form?.email}
              onChange={(e) => handleChangeForm("email", e)}
              placeholder={"example@mail.com"}
              required
              isErrorV2
              isError={
                errorField && (!form?.email || !validateEmail(form?.email))
              }
              errorText={
                !form?.email
                  ? "Please fill the field"
                  : "Email is not in the correct format"
              }
            />
            <InputField
              title={"Phone Number"}
              value={form?.phoneNumber}
              onChange={(e) => handleChangeForm("phoneNumber", e)}
              placeholder={"8xxx"}
              type={"phone"}
              required
              isErrorV2
              isError={
                errorField &&
                (!form?.phoneNumber || form?.phoneNumber?.length < 8)
              }
              errorText={
                errorField && !form?.phoneNumber
                  ? "Please fill the field"
                  : "Invalid Phone Number"
              }
            />
            <InputField
              title={"Work Unit"}
              value={form?.workUnit}
              onChange={(e) => handleChangeForm("workUnit", e)}
              placeholder={"Enter work unit"}
              required
              isErrorV2
              isError={errorField && !form?.workUnit}
              errorText="Please fill the field"
            />
            {/* <form onSubmit={handleAdd}> */}
            {/* <div className={Styles.boxForm}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={form?.name}
                onChange={handleChangeForm}
                placeholder="Enter name"
                required
              />
            </div>
            <div className={Styles.boxForm}>
              <label htmlFor="jotTitle">Position</label>
              <input
                type="text"
                name="jobTitle"
                value={form?.jobTitle}
                onChange={handleChangeForm}
                placeholder="Work position"
                required
              />
            </div>
            <div className={Styles.boxForm}>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={form?.email}
                onChange={handleChangeForm}
                placeholder="example@mail.com"
                required
              />
            </div>
            <div className={Styles.boxForm}>
              <label htmlFor="phoneNumber">Phone Number</label>
              <div className={Styles.phoneNumberWrapper}>
                <div>+62</div>
                <input
                  type="tel"
                  name="phoneNumber"
                  onChange={handleChangeForm}
                  value={form?.phoneNumber}
                  required
                />
              </div>
            </div>
            <div className={Styles.boxForm}>
              <label htmlFor="name">Work Unit</label>
              <input
                type="text"
                name="workUnit"
                value={form?.workUnit}
                onChange={handleChangeForm}
                placeholder="Enter work unit"
                required
              />
            </div> */}
            <div className={Styles.alertWrapper}>
              <span className={error ? Styles.alert : Styles.Normal}>
                {error ? error : "Something is wrong"}
              </span>
            </div>
            <div className={Styles.buttonWrapper}>
              <button onClick={() => setShowAdd(false)} type="button">
                Cancel
              </button>
              <button disabled={isSubmitting} type="submit" onClick={handleAdd}>
                {editMode
                  ? isSubmitting
                    ? "Saving..."
                    : "Save"
                  : isSubmitting
                  ? "Adding..."
                  : "Add"}
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
}
