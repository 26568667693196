/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import OtherListDropdown from "@Molecule/_modal/OtherListDropdown";
import PreviewJobPosting from "@Molecule/_modal/PreviewJobPosting";
import SchemeDetailModal from "@Molecule/_modal/SchemeDetailModal";
import SendingModal from "@Molecule/_modal/SendingModal";
import { getJobPostPerID, updateJobPost } from "@Services/officer/jobPosting";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AgreementStep from "./AgreementStep";
import CriteriaJobStep from "./CriteriaJobStep";
import DetailJobStep from "./DetailJobStep";
import RangeSalary from "./RangeSalary";
import UploadBody from "./Upload";
import Styles from "./styles.module.scss";
import Checkbox from "@Atom/Checkbox";
// import SendingModal from "@Molecule/_modal/SendingModal";

export default function AddJobPostingContents({
  sync,
  setDisablePreview,
  // syncData,
  openPreview,
  setOpenPreview,
  //   selectedButtonTypePosting,
  //   setSelectedButtoTypePosting,
}) {
  const containerRef = useRef(null);
  const [selectedButtonExperience, setSelectedButtonExperience] = useState("");
  const [valueNameTag, setValueTag] = useState("");
  const [dataListTag, setDataListTag] = useState([]);
  const [openAddTag, setOpenAddTag] = useState(false);
  const [valueDesc, setValueDesc] = useState("");
  const [categoryValue, setCategorValue] = useState([]);
  const [valueEvaluation, setValueEvaluation] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [addEvaluation, setAddEvaluation] = useState([]);
  const [isCheckedRange, setIsCheckedRange] = useState(false);
  const [minValue, setMinValue] = useState(100000);
  const [maxValue, setMaxValue] = useState(25000000);
  const [selectedButtonMethod, setSelectedButtonMethod] = useState("");
  const [selectedButtonType, setSelectedButtonType] = useState("");
  const [selectedButtonSystem, setSelectedButtonSystem] = useState("");

  // file images
  const [filesImages, setFileImages] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [typeImages, setTypeImages] = useState("");

  // file videos
  const [filesVideos, setFilesVideos] = useState(null);
  const [previewVideos, setPreviewVideos] = useState(null);
  const [typeVideos, setTypeVideos] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState("");
  const [dataSync, setDataSync] = useState(sync || null);
  const [isFecthing, setIsFetching] = useState(false);
  const { id } = useParams();
  const [isLoadingSend, setIsloadingSend] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [showDetailFlb, setShowDetailFlb] = useState(null);

  const refetchData = useCallback(async () => {
    try {
      setIsFetching(true);
      const { response } = await getJobPostPerID(id);
      setDataSync(response);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  // for value type files
  const typeFiles = useMemo(() => {
    let data = [];
    if (typeImages) {
      data.push(typeImages);
    }
    if (typeVideos) {
      data.push(typeVideos);
    }

    return data;
  }, [typeImages, typeVideos]);

  const handleSubmitNewTag = (e) => {
    e.preventDefault();
    const date = new Date();
    const time = date.getTime();
    let items = {
      id: time,
      name: valueNameTag,
    };

    setDataListTag([...dataListTag, items]);
    setValueTag("");
    setOpenAddTag(false);
  };

  const handleCheckboxRangeChange = () => {
    setIsCheckedRange(!isCheckedRange);
  };

  const dataToSendFiles = {
    fileImages: filesImages,
    filesVideos: filesVideos,
  };

  const objectValueFiles = Object.values(dataToSendFiles);

  const [validate, setValidate] = useState({
    selectedButtonExperience: false,
    dataListTag: false,
    valueDesc: false,
    categoryValue: false,
    selectedItems: false,
    selectedButtonMethod: false,
    selectedButtonType: false,
    selectedButtonSystem: false,
    filesImages: false,
    filesVideos: false,
  });

  const [errorStatus, setErrorStatus] = useState("");

  const handleSubmit = useCallback(
    async (typeButton) => {
      try {
        if (
          !selectedButtonExperience ||
          dataListTag?.length === 0 ||
          !valueDesc ||
          categoryValue?.length === 0 ||
          selectedItems?.length === 0 ||
          !selectedButtonMethod ||
          !selectedButtonType ||
          !selectedButtonSystem ||
          !filesImages ||
          !filesVideos
        ) {
          if (!selectedButtonExperience) {
            setValidate((prev) => ({
              ...prev,
              selectedButtonExperience: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              selectedButtonExperience: false,
            }));
          }

          if (dataListTag?.length === 0) {
            setValidate((prev) => ({
              ...prev,
              dataListTag: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              dataListTag: false,
            }));
          }

          if (!valueDesc) {
            setValidate((prev) => ({
              ...prev,
              valueDesc: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              valueDesc: false,
            }));
          }

          if (categoryValue?.length === 0) {
            setValidate((prev) => ({
              ...prev,
              categoryValue: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              categoryValue: false,
            }));
          }

          if (selectedItems?.length === 0) {
            setValidate((prev) => ({
              ...prev,
              selectedItems: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              selectedItems: false,
            }));
          }

          if (!selectedButtonMethod) {
            setValidate((prev) => ({
              ...prev,
              selectedButtonMethod: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              selectedButtonMethod: false,
            }));
          }

          if (!selectedButtonType) {
            setValidate((prev) => ({
              ...prev,
              selectedButtonType: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              selectedButtonType: false,
            }));
          }

          if (!selectedButtonSystem) {
            setValidate((prev) => ({
              ...prev,
              selectedButtonSystem: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              selectedButtonSystem: false,
            }));
          }

          if (!filesImages) {
            setValidate((prev) => ({
              ...prev,
              filesImages: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              filesImages: false,
            }));
          }

          if (!filesVideos) {
            setValidate((prev) => ({
              ...prev,
              filesVideos: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              filesVideos: false,
            }));
          }

          setErrorStatus(
            "Terdapat data yang belum diisi, silahkan lengkapi data dibawah."
          );
          containerRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          setIsloadingSend(true);
          setIsProcessing(true);
          const formData = new FormData();
          formData.append("experience", selectedButtonExperience || "");
          if (dataListTag?.length !== 0) {
            for (let i = 0; i < dataListTag?.length; i++) {
              const tag = dataListTag[i];
              formData.append(`tags[${i}]`, tag?.name);
            }
          }

          if (!dataListTag?.length) {
            formData.append("tags", []);
          }

          formData.append("description", valueDesc || "");

          if (categoryValue?.length !== 0) {
            for (let i = 0; i < categoryValue?.length; i++) {
              const caegories = categoryValue[i];
              formData.append(`categories[${i}]`, caegories?.name);
            }
          }

          if (!categoryValue?.length) {
            formData.append("categories", []);
          }

          if (selectedItems?.length !== 0) {
            for (let i = 0; i < selectedItems?.length; i++) {
              const evaluation = selectedItems[i];
              formData.append(`evaluations[${i}]`, evaluation?.name);
            }
          }

          if (!selectedItems?.length) {
            formData.append("evaluations", []);
          }

          // isDisplaySalary

          formData.append(
            "isSalaryDisplay",
            isCheckedRange === true ? "YES" : "NO"
          );

          if (isCheckedRange === true) {
            formData.append("salary", `${minValue}-${maxValue}` || "");
          } else {
            formData.append("salary", "");
          }

          formData.append("workMethod", selectedButtonMethod || "");
          formData.append("workType", selectedButtonType || "");
          formData.append("jobMethod", selectedButtonSystem || "");

          if (typeFiles?.length !== 0) {
            for (let index = 0; index < typeFiles?.length; index++) {
              const element = typeFiles[index];
              formData.append(`mediaTypes[${index}]`, element);
            }
          }

          if (!typeFiles?.length) {
            formData.append("mediaTypes", []);
          }

          for (let i = 0; i < objectValueFiles?.length; i++) {
            formData.append("files", objectValueFiles[i]);
          }

          formData.append("status", typeButton || "");

          await updateJobPost(formData, id);
          setIsSent(true);
          setIsloadingSend(false);

          setErrorStatus("");
          setValidate({
            selectedButtonExperience: false,
            dataListTag: false,
            valueDesc: false,
            categoryValue: false,
            selectedItems: false,
            selectedButtonMethod: false,
            selectedButtonType: false,
            selectedButtonSystem: false,
            filesImages: false,
            filesVideos: false,
          });
        }
      } catch (error) {
        setError(error?.response?.data?.error);
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedButtonExperience,
      dataListTag,
      valueDesc,
      categoryValue,
      selectedItems,
      selectedButtonMethod,
      selectedButtonType,
      selectedButtonSystem,
      filesImages,
      filesVideos,
      minValue,
      maxValue,
      typeFiles,
      objectValueFiles,
      id,
    ]
  );

  const navigate = useNavigate();

  const handleDone = () => {
    setIsProcessing(false);
    navigate(`/job-posting`);
    // setTrigger(Math.random());
  };

  return (
    <div className={Styles.container} ref={containerRef}>
      <div>
        <div className={Styles.left}>
          <div>
            <Icon icon={"company"} size={24} color={"#1571DE"} />
          </div>
          <div>
            <span>Klien</span>
            <span>
              Job posting untuk kebutuhan klien {dataSync?.clientName || "-"}
            </span>
          </div>
        </div>

        <div className={Styles.right}>
          <span>{dataSync?.clientName || "-"}</span>
        </div>
      </div>

      <div>
        <div>
          <div className={Styles.left}>
            <div className={Styles.imagesBox}>
              <img src={Images.DOCUMENTS_FOLDER} alt="" />
            </div>
            <div>
              <span>Nomor FLB</span>
              <span>{dataSync?.flb || "-"}</span>
            </div>
          </div>

          <div
            className={Styles.right}
            onClick={() => {
              if (dataSync?.schemeID) {
                setShowDetailFlb(dataSync?.schemeID);
              }
            }}
          >
            <span>Lihat Detail</span>
          </div>
        </div>

        <div>
          <span>Detail Pekerjaan</span>
          <DetailJobStep data={dataSync} />
        </div>

        <div>
          <span>Kriteria Kerja</span>
          {validate?.selectedButtonExperience === true ||
          validate?.dataListTag === true ||
          validate?.valueDesc === true ||
          validate?.categoryValue === true ||
          validate?.selectedItems === true ? (
            <span>*{errorStatus}</span>
          ) : null}
          <CriteriaJobStep
            selectedButtonExperience={selectedButtonExperience}
            setSelectedButtonExperience={setSelectedButtonExperience}
            openModalTag={() => {
              setOpenAddTag(true);
            }}
            dataListTag={dataListTag}
            setDataListTag={setDataListTag}
            valueDesc={valueDesc}
            setValueDesc={setValueDesc}
            categoryValue={categoryValue}
            setCategorValue={setCategorValue}
            valueEvaluation={valueEvaluation}
            setValueEvaluation={setValueEvaluation}
            addEvaluation={addEvaluation}
            setAddEvaluation={setAddEvaluation}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            validate={validate}
          />
        </div>

        <div>
          <div className={Styles.top}>
            <span>Range Gaji</span>
            <div className={Styles.checkbox}>
              <div className={Styles.checkboxInput}>
                <Checkbox
                  isChecked={isCheckedRange}
                  setIsChecked={setIsCheckedRange}
                  type={"job-post"}
                />
              </div>
              <span>Tampilkan range gaji pada job posting</span>
            </div>
          </div>

          <RangeSalary
            isCheckedRange={isCheckedRange}
            minValue={minValue}
            setMinValue={setMinValue}
            maxValue={maxValue}
            setMaxValue={setMaxValue}
            data={dataSync}
          />
        </div>

        <div>
          <span>Perjanjian Kerja</span>
          {validate?.selectedButtonMethod === true ||
          validate?.selectedButtonType === true ||
          validate?.selectedButtonSystem === true ? (
            <span>*{errorStatus}</span>
          ) : null}

          <AgreementStep
            selectedButtonMethod={selectedButtonMethod}
            setSelectedButtonMethod={setSelectedButtonMethod}
            selectedButtonType={selectedButtonType}
            setSelectedButtonType={setSelectedButtonType}
            selectedButtonSystem={selectedButtonSystem}
            setSelectedButtonSystem={setSelectedButtonSystem}
            validate={validate}
            data={dataSync}
          />
        </div>

        <div>
          {validate?.filesVideos === true || validate?.filesImages === true ? (
            <span>
              *Gambar atau Video belum dimasukkan, silahkan upload gambar atau
              video dibawah.
            </span>
          ) : null}
          <UploadBody
            typeImages={typeImages}
            setTypeImages={setTypeImages}
            typeVideos={typeVideos}
            setTypeVideos={setTypeVideos}
            fileVideos={filesVideos}
            setFilesVideos={setFilesVideos}
            previewVideos={previewVideos}
            setPreviewVideos={setPreviewVideos}
            fileImages={filesImages}
            setFileImages={setFileImages}
            previewImages={previewImages}
            setPreviewImages={setPreviewImages}
            setAcceptedFiles={setAcceptedFiles}
            validate={validate}
          />
        </div>

        <div>
          <div>
            {/* {errorStatus && <span>{errorStatus || "-"}</span>} */}
            <div>
              <div onClick={() => handleSubmit("Draft")}>Simpan ke Draft</div>
              <div onClick={() => handleSubmit("Publish")}>Publish</div>
            </div>
          </div>
        </div>
      </div>

      {openAddTag && (
        <OtherListDropdown
          disabled={!valueNameTag}
          type={"tag"}
          onSubmit={handleSubmitNewTag}
          handleDone={() => setOpenAddTag(false)}
          value={valueNameTag}
          setValue={setValueTag}
          onBack={() => setOpenAddTag(false)}
          isLimited
        />
      )}

      {openPreview && (
        <PreviewJobPosting
          previewVideos={previewVideos}
          data={dataSync}
          positionNameNormal={"Security"}
          location={"Medan"}
          addTags={dataListTag}
          handleBack={() => setOpenPreview(false)}
          handleDone={() => setOpenPreview(false)}
          acceptedFiles={acceptedFiles}
        />
      )}

      {isProcessing && (
        <SendingModal
          handleDone={handleDone}
          isDone={isSent}
          show={isProcessing}
          error={error}
          setShow={setIsProcessing}
          setError={setError}
          // isJobPosting={true}
          handleClose={() => setIsProcessing(false)}
          // errorData={errorData}
        />
      )}

      {showDetailFlb && (
        <SchemeDetailModal
          handleDone={() => setShowDetailFlb(null)}
          onBack={() => setShowDetailFlb(null)}
          selectedFlb={{
            docNumber: dataSync?.flb || "-",
          }}
          showDetailFlb={showDetailFlb}
          setShowDetailFlb={setShowDetailFlb}
        />
      )}
    </div>
  );
}
