import { useEffect } from "react";
import Styles from "./styles.module.scss";
// import Tooltip from "@Atom/Tooltip";
import Icon from "@Atom/Icon";
import Tooltip from "@Atom/Tooltip";
import AutocompleteCategory from "@Atom/AutoCompleteCategory";
import DropdownJobPosting from "@Atom/DropdownJobPosting";

const dataExperience = [
  {
    id: 1,
    name: "Fresh Graduate",
    code: "Fresh Graduate",
  },
  {
    id: 2,
    name: "> 1 tahun",
    code: "> 1 tahun",
  },
  {
    id: 3,
    name: "> 3 tahun",
    code: "> 3 tahun",
  },
  {
    id: 4,
    name: "> 5 tahun",
    code: "> 5 tahun",
  },
];

const category = [
  {
    id: 1,
    name: "Sales & Marketing",
  },
  {
    id: 2,
    name: "Driver",
  },
  {
    id: 3,
    name: "Admin",
  },
  {
    id: 4,
    name: "Sortasi",
  },
  {
    id: 5,
    name: "Promotor",
  },

  {
    id: 6,
    name: "Teknisi",
  },
  {
    id: 7,
    name: "Security",
  },
  {
    id: 8,
    name: "Kasir",
  },
  {
    id: 9,
    name: "Cleaning Service",
  },
  {
    id: 10,
    name: "Media & Kreatif",
  },
  {
    id: 11,
    name: "Manufaktur & Produksi",
  },
  {
    id: 12,
    name: "Developer/Programmer",
  },
  {
    id: 13,
    name: "Human Resource",
  },
  {
    id: 14,
    name: "General Sevice",
  },
  {
    id: 15,
    name: "Supply Chain",
  },
  {
    id: 16,
    name: "Akuntan & Finance",
  },
  {
    id: 17,
    name: "Lainnya",
  },
];

const optionsPenilaian = [
  {
    id: 1,
    name: "Terampil",
  },
  {
    id: 2,
    name: "Percaya Diri",
  },
  {
    id: 3,
    name: "Ramah",
  },
  {
    id: 4,
    name: "Cekatan",
  },
  {
    id: 5,
    name: "Energik",
  },
  {
    id: 6,
    name: "Rapi",
  },
  {
    id: 7,
    name: "Peduli",
  },
];

export default function CriteriaJobStep({
  selectedButtonExperience,
  setSelectedButtonExperience,
  dataListTag,
  openModalTag,
  setDataListTag,
  valueDesc,
  setValueDesc,
  categoryValue,
  setCategorValue,
  valueEvaluation,
  setValueEvaluation,
  addEvaluation,
  setAddEvaluation,
  selectedItems,
  setSelectedItems,
  validate,
}) {
  const setActiveButtonExperience = (idButton) => {
    setSelectedButtonExperience(idButton);
    // sessionStorage.setItem("idButtonTypePosting", idButton);
  };

  const handleChangeInputDesc = (event) => {
    setValueDesc(event?.target?.value || "");
  };

  useEffect(() => {
    if (selectedButtonExperience) {
      setSelectedButtonExperience(selectedButtonExperience);
    } else {
      setSelectedButtonExperience("");
    }
  }, [selectedButtonExperience, setSelectedButtonExperience]);

  const handleDeleteTag = (id) => {
    // console.log(id);
    const filtered = dataListTag.filter((data) => {
      return data.id !== id;
    });
    setDataListTag(filtered);
  };

  const handleDeleteCategory = (id) => {
    // console.log(id);
    const filtered = categoryValue.filter((data) => {
      return data.id !== id;
    });
    setCategorValue(filtered);
  };

  const handleKeydownEvaluation = (event) => {
    const date = new Date();
    const time = date.getTime();
    let items = {
      id: time,
      name: valueEvaluation,
    };

    if (event.key === "Enter" && valueEvaluation?.trim().length !== 0) {
      event.preventDefault();
      setAddEvaluation([...addEvaluation, items]);
      setSelectedItems([...selectedItems, items]);
      setValueEvaluation("");
    } else if (event.key === "Send" && valueEvaluation?.trim().length !== 0) {
      event.preventDefault();
      setAddEvaluation([...addEvaluation, items]);
      setSelectedItems([...selectedItems, items]);
      setValueEvaluation("");
    }
  };

  const handleChangeInputEvaluation = (event) => {
    setValueEvaluation(event?.target?.value || "");
  };

  const handleDeleteEvaluation = (id) => {
    // console.log(id);
    const filtered = selectedItems?.filter((data) => {
      return data.id !== id;
    });
    setSelectedItems(filtered);
  };

  return (
    <div className={`${Styles.container}`}>
      <div>
        <span>
          Pengalaman <span>*</span>
        </span>
        <div>
          <div className={Styles.experiences}>
            {dataExperience?.map((el, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    selectedButtonExperience === el?.code && Styles.active
                  } ${
                    validate?.selectedButtonExperience === true &&
                    !selectedButtonExperience &&
                    Styles.redBorder
                  }`}
                  onClick={() => setActiveButtonExperience(el?.code)}
                >
                  {el?.name || "-"}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div>
        <span>
          Tag Pekerjaan untuk 365 Personal <span>*</span>
        </span>
        <div>
          <div className={`${Styles.criteriaBox} ${Styles.cV2}`}>
            <div className={Styles.listTags}>
              {dataListTag?.map((el) => {
                return (
                  <div className={`${Styles.boxList} ${Styles.v2}`} key={el.id}>
                    <span className={Styles.nameList}>{el.name}</span>
                    <div
                      className={Styles.iconBox}
                      onClick={() => handleDeleteTag(el.id)}
                    >
                      <Icon
                        icon={"cross"}
                        size={20}
                        color={"#FF3E13"}
                        className={Styles.iconDelete}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                className={`${Styles.buttonSection} ${
                  dataListTag?.length === 6 ? Styles.buttonDisabled : ""
                } ${
                  validate?.dataListTag === true &&
                  dataListTag?.length === 0 &&
                  Styles.redBorder
                }`}
                onClick={dataListTag?.length > 5 ? null : openModalTag}
              >
                <Icon
                  icon={"circle-plus"}
                  size={20}
                  color={dataListTag?.length < 6 ? "#1571DE" : "#91BDF1"}
                />
                <span>Tag Pekerjaan</span>
              </div>
            </div>
            <Tooltip
              content={"Maksimal 6 tag dan 17 karakter"}
              direction="left"
              width="250px"
            >
              <Icon icon={"information-solid"} size={24} color={"#1571DE"} />
            </Tooltip>
          </div>
        </div>
      </div>

      <div>
        <span>
          Deskripsi Pekerjaan <span>*</span>
        </span>
        <div>
          <div className={Styles.textArea}>
            <textarea
              //   disabled={!dataListClient || !selectedFlb}
              type="text"
              className={`${Styles.remark} ${
                validate?.valueDesc === true && !valueDesc && Styles.redBorder
              }`}
              name="description"
              placeholder="Masukan deskripsi pekerjaan maupun benefit yang didapat"
              onChange={handleChangeInputDesc}
              value={valueDesc}
            />
          </div>
        </div>
      </div>

      <div>
        <span>
          Kategori <span>*</span>
        </span>
        <div>
          <div
            className={`${Styles.inputTextForm} ${
              validate?.categoryValue === true &&
              categoryValue?.length === 0 &&
              Styles.redBorder
            }`}
          >
            {categoryValue?.length !== 0 ? (
              <div className={Styles.listTags}>
                {categoryValue?.map((el) => {
                  return (
                    <div className={Styles.boxList} key={el.id}>
                      <span className={Styles.nameList}>{el.name}</span>
                      <div
                        className={Styles.iconBox}
                        onClick={() => handleDeleteCategory(el.id)}
                      >
                        <Icon
                          icon={"cross"}
                          size={20}
                          color={"#FF3E13"}
                          className={Styles.iconDelete}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <AutocompleteCategory
              options={category}
              value={categoryValue}
              setValue={setCategorValue}
              placeholder={"Pilih Kategori"}
              //   disabled={!dataListClient || !selectedFlb}
            />
          </div>
        </div>
      </div>

      <div>
        <span>
          Penilaian <span>*</span>
        </span>
        <div>
          <div
            className={`${Styles.inputTextForm} ${
              validate?.selectedItems === true &&
              selectedItems?.length === 0 &&
              Styles.redBorder
            }`}
          >
            {selectedItems?.length !== 0 ? (
              <div className={Styles.listTags}>
                {selectedItems?.map((el) => {
                  return (
                    <div className={Styles.boxList} key={el.id}>
                      <span className={Styles.nameList}>{el.name}</span>
                      <div
                        className={Styles.iconBox}
                        onClick={() => handleDeleteEvaluation(el.id)}
                      >
                        <Icon
                          icon={"cross"}
                          size={20}
                          color={"#FF3E13"}
                          className={Styles.iconDelete}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <DropdownJobPosting
              placeholder={"Pilih penilaian lain"}
              type={"penilaian"}
              options={optionsPenilaian}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleKeydownEvaluation={handleKeydownEvaluation}
              addEvaluation={addEvaluation}
              handleChangeInputEvaluation={handleChangeInputEvaluation}
              valueEvaluation={valueEvaluation}
              autoClose
              //   disabledEvaluation={!dataListClient || !selectedFlb}
              // disabled={!dataListClient}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
