import AutocompleteV2 from "@Atom/AutocompleteV2";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Icon from "@Atom/Icon";
import Spinner from "@Atom/Spinner";
import { fileBaseUrl } from "@Config/api";
import {
  getCandidateRecommend,
  getClientRecommendation,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import SendModal from "../../RecruitmentDetail/First/ListMode/Accepted/SendModal";
import PreviewRecommended from "./PreviewRecommended";
import Styles from "./styles.module.scss";

export default function SendRecommendationModal({
  showInterviewRO,
  handleDone,
  setShowSendRecommended,
  showSendRecommended,
  showPreview,
  setShowPreview,
  refetchDataAllCandidate,
}) {
  const [searchNameClient, setSearchNameClient] = useState("");
  const [searchNameClientTable, setSearchNameClientTable] = useState("");
  const [searchNamePosition, setSearchNamePosition] = useState("");
  const [searchNamePositionTable, setSearchNamePositionTable] = useState("");
  const [searchNameCandidate, setSearchNameCandidate] = useState("");
  const [searchNameCandidateTable, setSearchNameCandidateTable] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isAllChecked, setIsChecked] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedStep, setSelectedStep] = useState(1);
  const maxLength = 30;

  const startIndex = 0;
  const endIndex = 1;

  const [isLoadingData, setIsloadingData] = useState(false);
  const [data, setData] = useState(null);

  const [isLoadingDataCandidate, setIsLoadingDataCandidate] = useState(false);
  const [dataCandidate, setDataCandidate] = useState(null);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const refetchData = useCallback(async () => {
    try {
      setIsloadingData(true);
      const { response } = await getClientRecommendation();
      setData(response);
      setIsloadingData(false);
    } catch (err) {
      setIsloadingData(false);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (showInterviewRO?.name === "KIRIM-REKOMENDASI") {
      refetchData();
    }
  }, [refetchData, showInterviewRO?.name]);

  const refetchDataCandidate = useCallback(async () => {
    try {
      setIsLoadingDataCandidate(true);
      const { response } = await getCandidateRecommend(
        selectedPosition?.id,
        selectedClient?.code
      );
      setDataCandidate(response);
      setIsLoadingDataCandidate(false);
    } catch (err) {
      setIsLoadingDataCandidate(false);
      console.log(err);
    }
  }, [selectedPosition?.id, selectedClient?.code]);

  useEffect(() => {
    if (
      showInterviewRO?.name === "KIRIM-REKOMENDASI" &&
      selectedClient &&
      selectedPosition
    ) {
      refetchDataCandidate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, selectedPosition, refetchDataCandidate]);

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (dataCandidate) {
      setContacts(dataCandidate?.clientContacts || []);
    } else {
      setContacts([]);
    }
  }, [dataCandidate]);

  const filterOptionsCompany = useMemo(() => {
    return (
      data?.clients?.filter((el) =>
        el?.name?.toLowerCase()?.includes(searchNameClientTable?.toLowerCase())
      ) || []
    );
  }, [data?.clients, searchNameClientTable]);

  const filterOptionsPosition = useMemo(() => {
    return (
      selectedClient?.positions?.filter((el) =>
        el?.name
          ?.toLowerCase()
          ?.includes(searchNamePositionTable?.toLowerCase())
      ) || []
    );
  }, [selectedClient?.positions, searchNamePositionTable]);

  const filterOptionsCandidate = useMemo(() => {
    return (
      dataCandidate?.candidates?.filter((el) =>
        el?.name
          ?.toLowerCase()
          ?.includes(searchNameCandidateTable?.toLowerCase())
      ) || []
    );
  }, [dataCandidate?.candidates, searchNameCandidateTable]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedCandidates?.length; i++) {
      if (selectedCandidates[i]?.id === item?.id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };
  const handleCheckboxChange = (item) => {
    let isSelected = handleChecked(item);
    if (isSelected) {
      setSelectedCandidates(
        selectedCandidates?.filter((selected) => selected?.id !== item.id)
      );
      setIsChecked(false);
    } else {
      setSelectedCandidates([...selectedCandidates, item]);
    }
  };

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  if (showSendRecommended) {
    if (showPreview === true) {
      return (
        <PreviewRecommended
          handleClose={() => setShowPreview(false)}
          selectedCandidates={selectedCandidates}
          selectedContact={selectedContact}
          selectedClient={selectedClient}
          selectedPosition={selectedPosition}
          refetchDataAllCandidate={refetchDataAllCandidate}
          handleDone={handleDone}
          setShowSendRecommended={setShowSendRecommended}
        />
      );
    }
    return (
      <SendModal
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        setShowPreview={setShowPreview}
        setShow={setShowSendRecommended}
        contacts={contacts}
        setContacts={setContacts}
        type={viewType}
        selectedClient={selectedClient}
        typeSender={"recommended"}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>Kirim Kandidat ke Klien</span>
        <Icon
          icon={"cross"}
          size={24}
          className={Styles.iconCross}
          onClick={handleDone}
        />
      </div>

      {windowSize[0] <= 1000 ? (
        <div className={Styles.contentsResponsive}>
          <div>
            <div
              className={`${selectedStep === 1 && Styles.active}`}
              onClick={() => {
                setSelectedStep(1);
              }}
            >
              <span>Step 1</span>
              <span>Pilih Klien</span>
            </div>
            <div
              onClick={() => {
                if (selectedClient) {
                  setSelectedStep(2);
                }
              }}
              className={`${selectedStep === 2 && Styles.active}`}
            >
              <span>Step 2</span>
              <span>Pilih Posisi</span>
            </div>
            <div
              onClick={() => {
                if (selectedClient && selectedPosition) {
                  setSelectedStep(3);
                }
              }}
              className={`${selectedStep === 3 && Styles.active}`}
            >
              <span>Step 3</span>
              <span>Pilih Kandidat</span>
            </div>
          </div>

          <div className={Styles.inputBox}>
            {selectedStep === 1 ? (
              <AutocompleteV2
                options={data?.clients || []}
                value={searchNameClient}
                setValue={setSearchNameClient}
                isRecommended={true}
                placeholder={"Cari Nama Perusahaan"}
                setSearchNameClientTable={setSearchNameClientTable}
                isLoading={isLoadingData}
              />
            ) : selectedStep === 2 ? (
              <AutocompleteV2
                options={selectedClient?.positions || []}
                value={searchNamePosition}
                setValue={setSearchNamePosition}
                isRecommendedPosition={true}
                placeholder={"Cari Nama Posisi"}
                setSearchNameClientTable={setSearchNamePositionTable}
                // isLoading={isLoadingData}
                disabled={!selectedClient}
              />
            ) : selectedStep === 3 ? (
              <AutocompleteV2
                options={dataCandidate?.candidates || []}
                value={searchNameCandidate}
                setValue={setSearchNameCandidate}
                isRecommendedCandidate={true}
                placeholder={"Cari Nama Kandidat"}
                setSearchNameClientTable={setSearchNameCandidateTable}
                // isLoading={isLoadingData}
                disabled={!selectedClient || !selectedPosition}
              />
            ) : (
              <AutocompleteV2
                options={data?.clients || []}
                value={searchNameClient}
                setValue={setSearchNameClient}
                isRecommended={true}
                placeholder={"Cari Nama Perusahaan"}
                setSearchNameClientTable={setSearchNameClientTable}
                isLoading={isLoadingData}
              />
            )}
          </div>
          <div className={Styles.tableHead}>
            <span>
              {selectedStep === 3 ? (
                <CheckboxJobPosting
                  checked={
                    selectedCandidates?.length ===
                    dataCandidate?.candidates?.length
                      ? true
                      : false
                  }
                  onChange={() => {
                    if (
                      selectedCandidates?.length === dataCandidate?.candidates
                    ) {
                      setSelectedCandidates([]);
                    } else {
                      setSelectedCandidates(dataCandidate?.candidates);
                    }
                  }}
                  // type={"radio-section"}
                />
              ) : (
                "No"
              )}
            </span>
            <span>
              {selectedStep === 1
                ? "Nama Perusahaan"
                : selectedStep === 2
                ? "Nama Posisi & Lokasi"
                : selectedStep === 3
                ? "Nama Kandidat"
                : "Nama Perusahaan"}
            </span>
          </div>

          {selectedStep === 1 && (
            <div className={Styles.tableBody}>
              {isLoadingData ? (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner type={"contract"} />
                </div>
              ) : filterOptionsCompany?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsCompany?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.body} ${
                        selectedClient?.code === obj?.code && Styles.active
                      }`}
                      key={idx}
                      onClick={() => {
                        setSelectedClient(obj);
                        setSelectedCandidates([]);
                        setIsChecked(false);
                      }}
                    >
                      <span>{idx + 1}</span>
                      <div>
                        <div>
                          <Icon icon={"building"} size={20} color={"#1571DE"} />
                        </div>
                        <div>
                          <span>
                            {" "}
                            {obj?.name
                              ? obj?.name?.length > maxLength
                                ? obj?.name
                                    ?.substring(0, maxLength)
                                    ?.toUpperCase() + "..."
                                : obj?.name?.toUpperCase()
                              : "-"}
                          </span>
                          <span>
                            {" "}
                            {obj?.cities?.slice(startIndex, endIndex)?.length >
                            0
                              ? obj?.cities
                                  ?.slice(startIndex, endIndex)
                                  ?.join(", ")
                              : "-"}
                            {obj?.cities?.length > 1 ? (
                              <span>
                                +{obj?.cities?.slice(endIndex)?.length}
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </div>

                      {selectedClient?.code === obj?.code ? (
                        <div>
                          <Icon icon={"Polygon-13"} size={30} color={"#fff"} />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              )}
            </div>
          )}
          {selectedStep === 2 && (
            <div className={Styles.tableBody}>
              {!selectedClient ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>
                      Pilih perusahaan untuk dapat menampilkan daftar posisi
                      pekerjaan
                    </span>
                  </div>
                </div>
              ) : filterOptionsPosition?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsPosition?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.bodySecond} ${
                        selectedPosition?.id === obj?.id && Styles.active
                      }`}
                      key={idx}
                      onClick={() => {
                        setSelectedPosition(obj);
                        setSelectedCandidates([]);
                        setIsChecked(false);
                      }}
                    >
                      <span>{idx + 1}</span>
                      <div>
                        <span>{obj?.name || "-"}</span>
                        <span>{obj?.City?.name || "-"}</span>
                      </div>

                      {selectedPosition?.id === obj?.id ? (
                        <div>
                          <Icon icon={"Polygon-13"} size={30} color={"#fff"} />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              )}
            </div>
          )}

          {selectedStep === 3 && (
            <div className={Styles.tableBody}>
              {!selectedClient || !selectedPosition ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>
                      Pilih perusahaan dan posisi untuk dapat menampilkan daftar
                      kandidat
                    </span>
                  </div>
                </div>
              ) : isLoadingDataCandidate ? (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner type={"contract"} />
                </div>
              ) : filterOptionsCandidate?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsCandidate?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.bodyThird}`}
                      key={idx}
                      // onClick={() => {
                      //   setSelectedPosition(obj);
                      // }}
                    >
                      <span>
                        <CheckboxJobPosting
                          checked={handleChecked(obj)}
                          onChange={() => {
                            handleCheckboxChange(obj);
                            // setIsActive(false);
                          }}
                        />
                      </span>
                      <div>
                        <div>
                          <img
                            src={
                              obj?.CandidateDocuments
                                ? fileBaseUrl + obj?.CandidateDocuments?.url
                                : Images.AVA_DEFAULT
                            }
                            alt=""
                          />
                        </div>

                        <div>
                          <span>{obj?.name || "-"}</span>
                          <span>
                            {obj?.ExpectedPosition?.name || "-"} -{" "}
                            {obj?.ExpectedPosition?.contractType || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={Styles.contents}>
          <div className={Styles.first}>
            <div className={Styles.inputBox}>
              <AutocompleteV2
                options={data?.clients || []}
                value={searchNameClient}
                setValue={setSearchNameClient}
                isRecommended={true}
                placeholder={"Cari Nama Perusahaan"}
                setSearchNameClientTable={setSearchNameClientTable}
                isLoading={isLoadingData}
              />
            </div>

            <div className={Styles.tableHead}>
              <span>No</span>
              <span>Nama Perusahaan</span>
            </div>

            <div className={Styles.tableBody}>
              {isLoadingData ? (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner type={"contract"} />
                </div>
              ) : filterOptionsCompany?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsCompany?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.body} ${
                        selectedClient?.code === obj?.code && Styles.active
                      }`}
                      key={idx}
                      onClick={() => {
                        setSelectedClient(obj);
                        setSelectedCandidates([]);
                        setIsChecked(false);
                      }}
                    >
                      <span>{idx + 1}</span>
                      <div>
                        <div>
                          <Icon icon={"building"} size={20} color={"#1571DE"} />
                        </div>
                        <div>
                          <span>
                            {" "}
                            {obj?.name
                              ? obj?.name?.length > maxLength
                                ? obj?.name
                                    ?.substring(0, maxLength)
                                    ?.toUpperCase() + "..."
                                : obj?.name?.toUpperCase()
                              : "-"}
                          </span>
                          <span>
                            {" "}
                            {obj?.cities?.slice(startIndex, endIndex)?.length >
                            0
                              ? obj?.cities
                                  ?.slice(startIndex, endIndex)
                                  ?.join(", ")
                              : "-"}
                            {obj?.cities?.length > 1 ? (
                              <span>
                                +{obj?.cities?.slice(endIndex)?.length}
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </div>

                      {selectedClient?.code === obj?.code ? (
                        <div>
                          <Icon icon={"Polygon-13"} size={30} color={"#fff"} />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className={Styles.second}>
            <div className={Styles.inputBox}>
              <AutocompleteV2
                options={selectedClient?.positions || []}
                value={searchNamePosition}
                setValue={setSearchNamePosition}
                isRecommendedPosition={true}
                placeholder={"Cari Nama Posisi"}
                setSearchNameClientTable={setSearchNamePositionTable}
                // isLoading={isLoadingData}
                disabled={!selectedClient}
              />
            </div>

            <div className={Styles.tableHead}>
              <span>No</span>
              <span>Nama Posisi & Lokasi</span>
            </div>

            <div className={Styles.tableBody}>
              {!selectedClient ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>
                      Pilih perusahaan untuk dapat menampilkan daftar posisi
                      pekerjaan
                    </span>
                  </div>
                </div>
              ) : filterOptionsPosition?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsPosition?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.bodySecond} ${
                        selectedPosition?.id === obj?.id && Styles.active
                      }`}
                      key={idx}
                      onClick={() => {
                        setSelectedPosition(obj);
                        setSelectedCandidates([]);
                        setIsChecked(false);
                      }}
                    >
                      <span>{idx + 1}</span>
                      <div>
                        <span>{obj?.name || "-"}</span>
                        <span>{obj?.City?.name || "-"}</span>
                      </div>

                      {selectedPosition?.id === obj?.id ? (
                        <div>
                          <Icon icon={"Polygon-13"} size={30} color={"#fff"} />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className={Styles.third}>
            <div className={Styles.inputBox}>
              <AutocompleteV2
                options={dataCandidate?.candidates || []}
                value={searchNameCandidate}
                setValue={setSearchNameCandidate}
                isRecommendedCandidate={true}
                placeholder={"Cari Nama Kandidat"}
                setSearchNameClientTable={setSearchNameCandidateTable}
                // isLoading={isLoadingData}
                disabled={!selectedClient || !selectedPosition}
              />
            </div>

            <div className={Styles.tableHead}>
              <span>
                {/* <Checkbox
                isChecked={isAllChecked}
                setIsChecked={setIsChecked}
                type={"recommendad"}
                setSelectedCandidates={setSelectedCandidates}
                data={dataCandidate?.candidates || []}
              /> */}

                <CheckboxJobPosting
                  checked={
                    selectedCandidates?.length ===
                    dataCandidate?.candidates?.length
                      ? true
                      : false
                  }
                  onChange={() => {
                    if (
                      selectedCandidates?.length === dataCandidate?.candidates
                    ) {
                      setSelectedCandidates([]);
                    } else {
                      setSelectedCandidates(dataCandidate?.candidates);
                    }
                  }}
                  // type={"radio-section"}
                />
              </span>
              <span>Nama Kandidat</span>
            </div>

            <div className={Styles.tableBody}>
              {!selectedClient || !selectedPosition ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>
                      Pilih perusahaan dan posisi untuk dapat menampilkan daftar
                      kandidat
                    </span>
                  </div>
                </div>
              ) : isLoadingDataCandidate ? (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner type={"contract"} />
                </div>
              ) : filterOptionsCandidate?.length === 0 ? (
                <div className={Styles.notFound}>
                  <div>
                    <div>
                      <img src={Images.FILE_SEARCHING} alt="" />
                    </div>
                    <span>Data tidak ditemukan</span>
                  </div>
                </div>
              ) : (
                filterOptionsCandidate?.map((obj, idx) => {
                  return (
                    <div
                      className={`${Styles.bodySecond}`}
                      key={idx}
                      // onClick={() => {
                      //   setSelectedPosition(obj);
                      // }}
                    >
                      <span>
                        <CheckboxJobPosting
                          checked={handleChecked(obj)}
                          onChange={() => {
                            handleCheckboxChange(obj);
                            // setIsActive(false);
                          }}
                        />
                      </span>
                      <div>
                        <div>
                          <img
                            src={
                              obj?.CandidateDocuments
                                ? fileBaseUrl + obj?.CandidateDocuments?.url
                                : Images.AVA_DEFAULT
                            }
                            alt=""
                          />
                        </div>

                        <div>
                          <span>{obj?.name || "-"}</span>
                          <span>
                            {obj?.ExpectedPosition?.name || "-"} -{" "}
                            {obj?.ExpectedPosition?.contractType || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}

      {windowSize[0] <= 1000 ? (
        <div className={Styles.buttonSection}>
          {selectedStep === 1 && (
            <div>
              <span
                className={`${Styles.first} ${
                  !selectedClient && Styles.disabled
                }`}
                onClick={() => {
                  if (selectedClient) {
                    setSelectedStep(2);
                  }
                }}
              >
                Next Step
              </span>
            </div>
          )}

          {selectedStep === 2 && (
            <div>
              <span
                className={Styles.first}
                onClick={() => {
                  setSelectedStep(1);
                }}
              >
                Back
              </span>

              <span
                className={`${Styles.second} ${
                  !selectedClient || !selectedPosition ? Styles.disabled : ""
                }`}
                onClick={() => {
                  if (selectedClient && selectedPosition) {
                    setSelectedStep(3);
                  }
                }}
              >
                Next Step
              </span>
            </div>
          )}

          {selectedStep === 3 && (
            <div>
              <span
                className={Styles.first}
                onClick={() => {
                  setSelectedStep(2);
                }}
              >
                Back
              </span>

              <span
                className={`${Styles.second} ${
                  !selectedClient ||
                  !selectedPosition ||
                  selectedCandidates?.length === 0
                    ? Styles.disabled
                    : ""
                }`}
                onClick={() => {
                  if (
                    selectedClient &&
                    selectedPosition &&
                    selectedCandidates?.length > 0
                  ) {
                    setShowSendRecommended(true);
                  }
                }}
              >
                Kirim Kandidat
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className={Styles.buttonSection}>
          <div>
            <span className={Styles.first} onClick={handleDone}>
              Cancel
            </span>
            <span
              className={`${Styles.second} ${
                !selectedClient ||
                !selectedPosition ||
                selectedCandidates?.length === 0
                  ? Styles.disabled
                  : ""
              }`}
              onClick={() => {
                if (
                  selectedClient &&
                  selectedPosition &&
                  selectedCandidates?.length > 0
                ) {
                  setShowSendRecommended(true);
                }
              }}
            >
              Kirim Kandidat
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
