import Styles from "./style.module.scss";

const Card = ({ text, value, ...props }) => {
  return (
    <div className={Styles.cardContainer}>
      <div className={Styles.name}>{text}</div>
      <div className={Styles.tag}>{value}</div>
    </div>
  );
};

export default Card;
