import { useMemo } from 'react'
import Styles from './style.module.scss'

export default function Switcher({
  options = [],
  activeSwitchTitle
}) {

  const template = useMemo(() => {
    return options?.map((el) => ({
      title: el.title,
      onSwitch: el.onSwitch
    }))
  }, [options])

  return (
    <div className={Styles.container}>
      {template?.map((each, i) => (
        <button key={i}
          className={activeSwitchTitle === each?.title ? Styles.active : ''}
          onClick={each?.onSwitch}
        >
          {each?.title}
        </button>
      ))}
    </div>
  )
}