import ContactPerson from "@Molecule/ContactPerson";
import { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import ModalWrapper from "@Atom/ModalWrapper";
import PICModal from "@Molecule/_modal/SetPICModal";

export default function ContactPersonSection({ data }) {

  const [delegationsToSet, setDelegationsToSet] = useState(null)

  const delegationsData = useMemo(() => {
    const allAreas = []
    for (let i = 0; i < data?.length; i++) {
      if (!allAreas?.find(obj => obj?.city === data[i]?.City?.name)) {
        const jobLevels = []

        const perCity = data?.filter(obj => obj?.City?.name === data[i]?.City?.name)

        for (let j = 0; j < perCity.length; j++) {
          if (!jobLevels?.find(obj => obj === perCity[j]?.User?.jobLevel)) {
            jobLevels?.push(perCity[j]?.User?.jobLevel)
          }
        }
        allAreas.push({
          city: data[i]?.City?.name,
          cityCode: data[i]?.City?.code,
          delegations: jobLevels?.map(obj => {
            return {
              jobLevel: obj,
              peopleInCharge: perCity?.filter(p => p?.User?.jobLevel === obj)?.map(obj => {
                return {
                  ...obj,
                  ...obj?.User
                }
              })?.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.employeeId === value.employeeId
                ))
              )
            }
          })
        })
      }
    }
    return allAreas
  }, [data])

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Delegations</h3>
      </div>
      <div className={Styles.content}>
        {delegationsData.map((eachD, iD) => {
          return (
            <div key={iD} className={Styles.regionCard}>
              <div className={Styles.cardHeader}>
                <h4>{eachD?.city}</h4>
                <button
                  onClick={() => setDelegationsToSet(eachD)}
                  disabled
                >Set PIC</button>
              </div>
              <div className={Styles.cardContent}>
                {eachD?.delegations?.map((eachR, iR) => {
                  return (
                    <div key={iR}>
                      <ContactPerson
                        type={"withContact"}
                        title={eachR?.jobLevel}
                        data={eachR?.peopleInCharge}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {
        delegationsToSet
        &&
        <ModalWrapper
          show={delegationsToSet}
          handleClose={() => setDelegationsToSet(null)}
        >
          <PICModal
            data={delegationsToSet}
          />
        </ModalWrapper>
      }
    </div>
  );
}
