import Styles from "./style.module.scss";

export default function Radio({ isChecked = false, setIsChecked = () => {} }) {
  return (
    <div
      className={`${Styles.outer} ${isChecked ? Styles.checked : ""}`}
      onClick={setIsChecked}
    >
      <div className={Styles.inner} />
    </div>
  );
}
