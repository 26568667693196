export const removeZeroStarting = (str, onlyFirstChar = false) => {
  if (onlyFirstChar) {
    if (+str[0] === 0) {
      return str?.substring(1);
    }
    return str;
  } else {
    if (+str[0] === 0) {
      return str?.substring(1);
    }
    return `${str}`;
  }
};
