import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Images from "@Theme/Images";
import DetailJobs from "./DetailJobs";
import CriteriaJobs from "./CriteriaJobs";
import Agreement from "./Agreement";
import { getJobPostPerID, updateStatus } from "@Services/officer/jobPosting";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "@Atom/LoadingAnimation";
import { fileBaseUrl } from "@Config/api";
import NotifJobPosting from "@Molecule/_modal/NotifJobPosting";
import SchemeDetailModal from "@Molecule/_modal/SchemeDetailModal";
import ModalWrapper from "@Atom/ModalWrapper";
import JobPostingLinkModal from "@Molecule/_modal/JobPostingLinkModal";
import { Tooltip } from "@mui/material";

export default function JobPostingDetail({ data }) {
  const [selectedButtonImages, setSelectedButtonImages] = useState("Poster");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isFecthing, setIsFetching] = useState(false);
  const [jobPost, setJobPost] = useState(data || null);
  const { id } = useParams();

  const navigate = useNavigate();

  const [playVideo, setPlayVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false); // State untuk mute/unmute
  const [showNotif, setShowNotif] = useState(false);
  const [statusNotif, setStatusNotif] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [isloadingUpdate, setIsloadingUpdate] = useState(false);
  const [showDetailFlb, setShowDetailFlb] = useState(null);
  const [showModalLink, setShowModalLink] = useState(null);

  // console.log(showDetailFlb, "tetet");
  const [hover, setHover] = useState(null);

  const refetchData = useCallback(async () => {
    try {
      setIsFetching(true);
      const { response } = await getJobPostPerID(id);
      setJobPost(response);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const ref = useRef(null);

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && openDropdown && !ref.current.contains(e.target)) {
        setOpenDropdown(false);
      }
    },
    [ref, openDropdown]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (selectedButtonImages) {
      setSelectedButtonImages(selectedButtonImages);
    } else {
      setSelectedButtonImages("Poster");
    }
  }, [selectedButtonImages]);

  const videoRef = useRef();
  const handlePlay = () => {
    videoRef.current.play();
    setPlayVideo(true);
  };

  const handlePause = () => {
    videoRef.current.pause();
    setPlayVideo(false);
  };

  const handleMute = () => {
    videoRef.current.muted = true;
    setMuteVideo(true);
  };

  const handleUnmute = () => {
    videoRef.current.muted = false;
    setMuteVideo(false);
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setShowNotif(true);
      setTimeout(() => {
        setShowNotif(false);
      }, 2000);
    });
  };

  const onUpdateStatus = async (e) => {
    e.preventDefault();
    try {
      //   setShowAction("");
      setIsloadingUpdate(true);
      await updateStatus(id, {
        status: statusNotif,
      });
      setIsSent(true);
      setIsloadingUpdate(false);
      setStatusNotif("");
      setTimeout(() => {
        setIsSent(false);
      }, 2000);
      refetchData();
    } catch (error) {
      console.log(error);
      setIsloadingUpdate(false);
    }
  };

  return isFecthing ? (
    <div
      style={{
        height: "calc(100vh - 220px)",
        display: "grid",
        placeItems: "center",
      }}
    >
      <LoadingAnimation />
    </div>
  ) : (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div>
          <span
            className={Styles.icon}
            onClick={() => {
              navigate(`/job-posting`);
            }}
          >
            <Icon icon={"arrow-left"} size={50} />
          </span>
          <span className={Styles.detail}>Detail Job Posting</span>
          <div
            className={`${
              jobPost?.status?.toLowerCase() === "draft" && Styles.yellow
            } ${
              jobPost?.status?.toLowerCase() === "publish" ||
              jobPost?.status?.toLowerCase() === "active"
                ? Styles.green
                : ""
            } ${jobPost?.status?.toLowerCase() === "inactive" && Styles.grey} ${
              jobPost?.type &&
              jobPost?.type?.toLowerCase() === "peralihan" &&
              jobPost?.status?.toLowerCase() === "draft" &&
              Styles.grey
            } ${jobPost?.status?.toLowerCase() === "expired" && Styles.red}`}
          >
            <div className={Styles.dots}></div>
            <span>
              {jobPost?.status
                ? jobPost?.type &&
                  jobPost?.type?.toLowerCase() === "peralihan" &&
                  jobPost?.status?.toLowerCase() === "draft"
                  ? "Inactive"
                  : jobPost?.status
                : "-"}
            </span>
          </div>
        </div>

        {jobPost?.status?.toLowerCase() === "expired" ? (
          <div>
            {jobPost?.dedicatedRO?.phoneNumber ? (
              <Tooltip
                title={<span>Hubungi {jobPost?.dedicatedRO?.name || "-"}</span>}
              >
                <div
                  className={`${Styles.roDedicated} ${
                    hover && Styles.boxShadow
                  }`}
                  onMouseEnter={() => setHover(id)}
                  onMouseLeave={() => setHover("")}
                  onClick={() => {
                    if (data?.dedicatedRO?.phoneNumber) {
                      window.open(
                        `https://wa.me/${data?.dedicatedRO?.phoneNumber}`
                      );
                    }
                  }}
                >
                  <div className={Styles.dedicated}>
                    <div className={Styles.images}>
                      <img
                        src={
                          jobPost?.dedicatedRO?.profilePicture
                            ? fileBaseUrl + jobPost?.dedicatedRO?.profilePicture
                            : Images.AVA_DEFAULT
                        }
                        alt=""
                      />
                    </div>
                    <span>{jobPost?.dedicatedRO?.name || "-"}</span>
                  </div>
                  <div className={Styles.wa}>
                    <img src={Images.WHATSAPP_LOGO} alt="" />
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </div>
        ) : (
          <div>
            {jobPost?.dedicatedRO?.phoneNumber ? (
              <Tooltip
                title={<span>Hubungi {jobPost?.dedicatedRO?.name || "-"}</span>}
              >
                <div
                  className={`${Styles.roDedicated} ${
                    hover && Styles.boxShadow
                  }`}
                  onMouseEnter={() => setHover(id)}
                  onMouseLeave={() => setHover("")}
                  onClick={() => {
                    if (data?.dedicatedRO?.phoneNumber) {
                      window.open(
                        `https://wa.me/${data?.dedicatedRO?.phoneNumber}`
                      );
                    }
                  }}
                >
                  <div className={Styles.dedicated}>
                    <div className={Styles.images}>
                      <img
                        src={
                          jobPost?.dedicatedRO?.profilePicture
                            ? fileBaseUrl + jobPost?.dedicatedRO?.profilePicture
                            : Images.AVA_DEFAULT
                        }
                        alt=""
                      />
                    </div>
                    <span>{jobPost?.dedicatedRO?.name || "-"}</span>
                  </div>
                  <div className={Styles.wa}>
                    <img src={Images.WHATSAPP_LOGO} alt="" />
                  </div>
                </div>
              </Tooltip>
            ) : null}

            <div
              className={`${Styles.first} ${
                jobPost?.status?.toLowerCase() === "draft" ||
                jobPost?.status?.toLowerCase() === "inactive"
                  ? Styles.disabled
                  : ""
              }`}
              onClick={() => {
                if (
                  jobPost?.status?.toLowerCase() === "publish" ||
                  jobPost?.status?.toLowerCase() === "active"
                ) {
                  if (jobPost?.type) {
                    if (jobPost?.type?.toLowerCase() === "peralihan") {
                      setShowModalLink({
                        isShow: true,
                        name: "Peralihan",
                        data: jobPost,
                      });
                    } else {
                      setShowModalLink({
                        isShow: true,
                        name: "Normal",
                        data: jobPost,
                      });
                    }
                  } else {
                    setShowModalLink({
                      isShow: true,
                      name: "Normal",
                      data: jobPost,
                    });
                  }
                }
              }}
            >
              Lihat Preview
            </div>

            {jobPost?.recruiterType?.toLowerCase() !== "normal" ? (
              <div
                className={`${Styles.second} ${
                  jobPost?.status?.toLowerCase() === "inactive" &&
                  Styles.darkBlue
                } ${
                  jobPost?.status?.toLowerCase() === "draft" && Styles.darkBlue
                }`}
                onClick={() => {
                  if (jobPost?.recruiterType?.toLowerCase() !== "normal") {
                    if (
                      jobPost?.type &&
                      jobPost?.type?.toLowerCase() === "peralihan" &&
                      jobPost?.status?.toLowerCase() === "draft"
                    ) {
                      setStatusNotif("Active");
                    } else if (jobPost?.status?.toLowerCase() === "draft") {
                      setStatusNotif("Publish");
                    } else if (jobPost?.status?.toLowerCase() === "publish") {
                      setStatusNotif("Draft");
                    } else if (jobPost?.status?.toLowerCase() === "inactive") {
                      setStatusNotif("Active");
                    } else if (jobPost?.status?.toLowerCase() === "active") {
                      setStatusNotif("Inactive");
                    }
                  }
                }}
              >
                {jobPost?.type &&
                jobPost?.type?.toLowerCase() === "peralihan" &&
                jobPost?.status?.toLowerCase() === "draft"
                  ? "Aktifkan"
                  : jobPost?.status?.toLowerCase() === "inactive"
                  ? "Aktifkan"
                  : jobPost?.status?.toLowerCase() === "active"
                  ? "Non Aktifkan"
                  : jobPost?.status?.toLowerCase() === "draft"
                  ? "Publish"
                  : "Unpublish"}
              </div>
            ) : null}

            <div ref={ref}>
              <div
                onClick={() => {
                  if (openDropdown) {
                    setOpenDropdown(false);
                  } else {
                    setOpenDropdown(true);
                  }
                }}
                className={Styles.third}
              >
                <Icon icon={"link"} size={16} color={"#1571DE"} />
                <Icon icon={"arrow-down"} size={16} color={"#1571DE"} />
              </div>

              {openDropdown && (
                <div className={Styles.dropdown}>
                  <span
                    onClick={() => {
                      if (jobPost?.type) {
                        if (data?.type?.toLowerCase() === "peralihan") {
                          setShowModalLink({
                            name: "Peralihan",
                            data: jobPost,
                          });
                        }
                      } else {
                        setShowModalLink({
                          name: "Peralihan",
                          data: jobPost,
                        });
                      }
                    }}
                    className={`${
                      jobPost?.type &&
                      data?.type?.toLowerCase() === "normal" &&
                      Styles.disabled
                    }`}
                  >
                    Salin Link Job Posting <span>Peralihan</span>
                  </span>

                  <span
                    onClick={() => {
                      if (jobPost?.type) {
                        if (
                          jobPost?.type?.toLowerCase() === "normal" &&
                          jobPost?.status?.toLowerCase() === "publish"
                        ) {
                          setShowModalLink({
                            name: "Normal",
                            data: jobPost,
                          });
                        }
                      } else {
                        if (
                          jobPost?.medias?.length > 0 &&
                          jobPost?.status?.toLowerCase() === "publish"
                        ) {
                          setShowModalLink({
                            name: "Normal",
                            data: jobPost,
                          });
                        }
                      }
                    }}
                    className={`${
                      jobPost?.type &&
                      jobPost?.type?.toLowerCase() === "peralihan" &&
                      Styles.disabled
                    } ${
                      !jobPost?.type &&
                      jobPost?.medias?.length === 0 &&
                      Styles.disabled
                    } ${
                      jobPost?.status?.toLowerCase() === "draft" &&
                      Styles.disabled
                    }`}
                  >
                    Salin Link Job Posting <span>Reguler</span>
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={Styles.content}>
        <div>
          <div className={Styles.selectedImages}>
            <span
              onClick={() => {
                setSelectedButtonImages("Poster");
              }}
              className={`${
                selectedButtonImages === "Poster" && Styles.active
              }`}
            >
              Poster Job Posting
            </span>
            <span
              onClick={() => {
                setSelectedButtonImages("Konten");
              }}
              className={`${
                selectedButtonImages === "Konten" && Styles.active
              }`}
            >
              Konten 365 Personal
            </span>
          </div>
          {selectedButtonImages === "Poster" && (
            <div>
              {jobPost?.medias?.find(
                (el) => el?.type?.toLowerCase() === "poster"
              ) ? (
                <div className={Styles.imagesBox}>
                  <img
                    src={
                      fileBaseUrl +
                      jobPost?.medias?.find(
                        (el) => el?.type?.toLowerCase() === "poster"
                      )?.uri
                    }
                    alt=""
                    className={Styles.imagePreview}
                  />
                </div>
              ) : (
                <div className={Styles.imagesBoxNull}>
                  <div>
                    <div className={Styles.images}>
                      <img src={Images.FILE_NULL} alt="" />
                    </div>
                    <span>Belum ada media ditambahkan</span>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedButtonImages === "Konten" && (
            <div>
              {jobPost?.medias?.find((el) => el?.type?.includes("Personal")) ? (
                <>
                  {jobPost?.medias?.find(
                    (el) => el?.type?.toLowerCase() === "personal image"
                  ) ? (
                    <div className={Styles.imagesBox}>
                      <img
                        src={
                          fileBaseUrl +
                          jobPost?.medias?.find((el) =>
                            el?.type?.includes("Personal")
                          )?.uri
                        }
                        alt=""
                        className={Styles.imagePreview}
                      />
                    </div>
                  ) : (
                    <div className={Styles.imagesBox}>
                      <video
                        className={Styles.video}
                        src={
                          jobPost?.medias
                            ?.find((el) => el?.type?.includes("Personal"))
                            ?.uri?.includes("https://video.gumlet.io")
                            ? jobPost?.medias?.find((el) =>
                                el?.type?.includes("Personal")
                              )?.uri
                            : fileBaseUrl +
                              jobPost?.medias?.find((el) =>
                                el?.type?.includes("Personal")
                              )?.uri
                        }
                        ref={videoRef}
                        // onClick={muteVideo === true ? handleUnmute : handleMute}
                      ></video>
                      <div className={Styles.iconPlayBox}>
                        <div
                          className={Styles.iconPlay}
                          onClick={
                            playVideo === false ? handlePlay : handlePause
                          }
                        >
                          <Icon
                            icon={playVideo === false ? "play" : "pause"}
                            size={20}
                            color={"black"}
                          />
                        </div>
                        <div
                          className={Styles.iconPlay}
                          onClick={
                            muteVideo === false ? handleMute : handleUnmute
                          }
                        >
                          <Icon
                            icon={
                              muteVideo === false ? "sound-on" : "sound-off"
                            }
                            size={20}
                            color={"black"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={Styles.imagesBoxNull}>
                  <div>
                    <div className={Styles.images}>
                      <img src={Images.FILE_NULL} alt="" />
                    </div>
                    <span>Belum ada media ditambahkan</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <div className={Styles.headerContent}>
            <div>
              <div>
                <Icon icon={"company"} size={24} color={"#1571DE"} />
              </div>
              <div>
                <span>Klien</span>
                <span>
                  Job posting untuk kebutuhan klien {jobPost?.clientName || "-"}
                </span>
              </div>
            </div>

            <div>
              <span> {jobPost?.clientName || "-"}</span>
            </div>
          </div>

          <div className={Styles.flbContent}>
            <div>
              <div className={Styles.imagesBox}>
                <img src={Images.DOCUMENTS_FOLDER} alt="" />
              </div>
              <div>
                <span>Nomor Dokumen</span>
                <span>{jobPost?.flb || "-"}</span>
              </div>
            </div>

            <div
              onClick={() => {
                if (jobPost?.schemeID) {
                  setShowDetailFlb(jobPost?.schemeID);
                }
              }}
            >
              <span>Lihat Detail</span>
            </div>
          </div>

          <div className={Styles.bodyContent}>
            <div>
              <span>Detail Pekerjaan</span>
              <DetailJobs data={jobPost} />
            </div>

            <div>
              <span>Kriteria Kerja</span>
              <CriteriaJobs data={jobPost} />
            </div>

            <div>
              <span>Perjanjian Kerja</span>
              <Agreement data={jobPost} />
            </div>
          </div>
        </div>
      </div>

      {statusNotif && (
        <NotifJobPosting
          handleCancel={() => setStatusNotif(false)}
          handleDone={() => setStatusNotif(false)}
          labelStatus={
            statusNotif === "Publish"
              ? "Publish"
              : statusNotif === "Active"
              ? "Aktifkan"
              : statusNotif === "Inactive"
              ? "Nonaktifkan"
              : "Draft"
          }
          labelWarning={
            statusNotif === "Publish"
              ? "mempublish"
              : statusNotif === "Active"
              ? "mengaktifkan"
              : statusNotif === "Inactive"
              ? "menonaktifkan"
              : "mendraft"
          }
          isloadingUpdate={isloadingUpdate}
          handleSave={onUpdateStatus}
        />
      )}

      <div className={`${Styles.notification} ${isSent && Styles.show}`}>
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Status berhasil diubah
      </div>

      <div className={`${Styles.notification} ${showNotif && Styles.show}`}>
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Link Berhasil Disalin
      </div>

      {showDetailFlb && (
        <SchemeDetailModal
          handleDone={() => setShowDetailFlb(null)}
          onBack={() => setShowDetailFlb(null)}
          selectedFlb={{
            docNumber: jobPost?.flb || "-",
          }}
          showDetailFlb={showDetailFlb}
          setShowDetailFlb={setShowDetailFlb}
        />
      )}

      {showModalLink && (
        <ModalWrapper show={showModalLink} handleClose={() => {}}>
          <JobPostingLinkModal
            data={showModalLink}
            handleClose={() => setShowModalLink(null)}
            isRecrutiment
            setShowNotif={setShowNotif}
          />
        </ModalWrapper>
      )}
    </div>
  );
}
