import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import ClientListLayout from "@Organism/Manager/Client/ClientList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getBMClients, getBMDashboardSync } from "@Services/manager/bm";
import { getClients } from "@Services/manager/client";
import IMAGES from "@Theme/Images";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Await,
  defer,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Styles from "./clientList.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  console.log(route, "ini route");
  const limit = url.searchParams.get("l") || 5;
  const page = url.searchParams.get("p") || 1;
  const search = url.searchParams.get("n") || "";
  const type = url.searchParams.get("t") || "1";

  const query = `limit=${limit}&page=${page || 1}&search=${search}&clientType=${
    type === "1" ? "OWNER" : "SERVICE"
  }`;

  if (url?.pathname?.includes("daftar-bm")) {
    return defer({
      packageClients: !url?.pathname?.includes("daftar-bm")
        ? getClients(query)
        : getBMClients(
            route?.params?.id,
            page,
            limit,
            search,
            type === "1" ? "OWNER" : "SERVICE"
          ),
      packageDashboardSync: getBMDashboardSync(route?.params?.id),
    });
  }

  return defer({
    packageClients: !url?.pathname?.includes("daftar-bm")
      ? getClients(query)
      : getBMClients(
          route?.params?.id,
          page,
          limit,
          search,
          type === "1" ? "OWNER" : "SERVICE"
        ),
  });
}

export default function ClientList({ rbmView = false }) {
  const loadedData = useLoaderData();
  const packageClients = loadedData?.packageClients;
  const packageDashboardSync = loadedData?.packageDashboardSync;
  const location = useLocation();
  const pathname = location.pathname;
  const [searchTerm, setSearchTerm] = useState("");
  const query = useQuery();
  const name = query.get("n");
  const user = query.get("u") || "";
  const navigate = useNavigate();

  // sessionStorage.setItem('role', '')

  const [value, setValue] = useState(query?.get("l") || 10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(
    query.get("p") ? +query.get("p") : 1
  );

  const [activeType, setActiveType] = useState(query.get("t") || "1");

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    navigate(
      {
        pathname: pathname,
        search: `?p=${selectedPage}&l=${value}&n=${debouncedSearchTerm}&t=${activeType}&u=${user}`,
      },
      { replace: true }
    );
  }, [
    selectedPage,
    value,
    query,
    pathname,
    navigate,
    debouncedSearchTerm,
    activeType,
    user,
  ]);

  const handleClickAdd = () => {
    navigate(pathname + "/add-new-client");
  };

  useEffect(() => {
    if (name) {
      setSearchTerm(name);
    }
  }, [name]);

  if (rbmView) {
    return (
      <div className={Styles.container}>
        <Helmet>
          <title>Kerja 365 | Clients</title>
        </Helmet>

        {!rbmView ? (
          <div className={Styles.topWrapper}>
            <div className={Styles.info}>
              <img src={IMAGES?.NOTE} alt="info" />
              <div className={Styles.infoContent}>
                <span>Info</span>
                <span>Klik pada nama client dan mulai membuat order</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={Styles.backNav}>
            <button
              onClick={() => {
                navigate(`/daftar-bm`);
              }}
            >
              <Icon icon={"arrow-left"} size={"20px"} />
            </button>
            <div>
              <img src={Images.AVA_DEFAULT} alt="" />
              <span>{query?.get("u")}</span>
            </div>
          </div>
        )}

        <Suspense
          fallback={
            // <LoadingSkeleton />
            <div className={Styles.content}>
              <div className={Styles.content}>
                <ClientListLayout
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  value={value}
                  setValue={setValue}
                  pages={pages}
                  setPages={setPages}
                  pageList={pageList}
                  setPageList={setPageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  handleClickAdd={handleClickAdd}
                  activeType={activeType}
                  setActiveType={setActiveType}
                  rbmView={rbmView}
                  isLoadingSync
                  isLoadingTable
                />
              </div>
            </div>
          }
        >
          <Await
            resolve={packageDashboardSync}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageDashboardSync) => (
              <Suspense
                fallback={
                  <div className={Styles.content}>
                    <ClientListLayout
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      value={value}
                      setValue={setValue}
                      pages={pages}
                      setPages={setPages}
                      pageList={pageList}
                      setPageList={setPageList}
                      selectedPage={selectedPage}
                      setSelectedPage={setSelectedPage}
                      handleClickAdd={handleClickAdd}
                      activeType={activeType}
                      setActiveType={setActiveType}
                      syncData={packageDashboardSync?.response}
                      rbmView={rbmView}
                      isLoadingTable
                    />
                  </div>
                }
              >
                <Await resolve={packageClients}>
                  {(packageClients) => (
                    <div className={Styles.content}>
                      <ClientListLayout
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        clients={packageClients?.response?.data}
                        totalPage={packageClients?.response?.totalPage}
                        ownClientAmount={
                          packageClients?.response?.totalData?.owner
                        }
                        serviceClientAmount={
                          packageClients?.response?.totalData?.service
                        }
                        value={value}
                        setValue={setValue}
                        pages={pages}
                        setPages={setPages}
                        pageList={pageList}
                        setPageList={setPageList}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        handleClickAdd={handleClickAdd}
                        activeType={activeType}
                        setActiveType={setActiveType}
                        syncData={packageDashboardSync?.response}
                        rbmView={rbmView}
                      />
                    </div>
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Clients</title>
      </Helmet>

      {!rbmView && (
        <div className={Styles.topWrapper}>
          <div className={Styles.info}>
            <img src={IMAGES?.NOTE} alt="info" />
            <div className={Styles.infoContent}>
              <span>Info</span>
              <span>Klik pada nama client dan mulai membuat order</span>
            </div>
          </div>
        </div>
      )}

      <React.Suspense
        fallback={
          // <LoadingSkeleton />
          <div className={Styles.content}>
            <ClientListLayout
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              value={value}
              setValue={setValue}
              pages={pages}
              setPages={setPages}
              pageList={pageList}
              setPageList={setPageList}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              handleClickAdd={handleClickAdd}
              activeType={activeType}
              setActiveType={setActiveType}
              rbmView={rbmView}
              isLoadingTable
            />
          </div>
        }
      >
        <Await
          resolve={packageClients}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageClients) => (
            <div className={Styles.content}>
              <ClientListLayout
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                clients={packageClients?.response?.data}
                totalPage={packageClients?.response?.totalPage}
                ownClientAmount={packageClients?.response?.totalData?.owner}
                serviceClientAmount={
                  packageClients?.response?.totalData?.service
                }
                value={value}
                setValue={setValue}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                handleClickAdd={handleClickAdd}
                activeType={activeType}
                setActiveType={setActiveType}
                rbmView={rbmView}
              />
            </div>
          )}
        </Await>
      </React.Suspense>
    </div>
  );
}
