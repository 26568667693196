import Images from "@Theme/Images";
import Styles from "./style.module.scss";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import Icon from "@Atom/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Radio from "@Atom/Radio";
import Pagination from "@Molecule/Pagination";
import { useDebounce } from "@Hooks/useDebounce";
import {
  getTransferSync,
  transferCandidate,
} from "@Services/officer/recruitment";
import { makeRandomString } from "@Helpers/makeRandomString";
import Switch from "@Atom/Switch";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
// import { useSubmit } from "react-router-dom";

export default function MoveCandidateModal({
  candidate,
  onClose,
  setTrigger = () => {},
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selectedPositionID, setSelectedPositionID] = useState(null);

  // value id posisi, pake ini untuk ngaish value buat submit
  const [selectedID, setSelectedID] = useState(null);

  // value name posisi, pake ini untuk ngasih value buat submit
  const [selectedName, setSelectedName] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [mode, setMode] = useState("private");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [isClient, setIsClient] = useState(false);

  const modes = ["private", "public"];

  const fetchList = useCallback(async () => {
    try {
      setIsLoading(true);
      setData([]);
      const { response } = await getTransferSync(
        `page=${selectedPage}&limit=${limit}&type=${
          mode === "private" && !isClient
            ? "PUBLIC"
            : mode === "private" && isClient
            ? "PRIVATE"
            : "PUBLIC"
        }&search=${debouncedSearchTerm}&isPrivatePosition=${
          mode === "private" && !isClient
            ? "yes"
            : mode === "public"
            ? "no"
            : ""
        }`
      );
      setData(response?.data);
      setTotalPage(response?.totalPage);
      setTotalData(response?.totalData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [debouncedSearchTerm, isClient, limit, mode, selectedPage]);

  const positionList = useMemo(() => {
    return data?.map((obj) => {
      return {
        ...obj,
        id: obj?.id,
        positionName: obj?.name,
        clientName: obj?.OrderScheme?.Client?.name,
        requestAmount: obj?.amount,
      };
    });
  }, [data]);

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await transferCandidate({
        candidateID: candidate?.id ? candidate?.id : "",
        positionID: selectedID ? selectedID : "",
        type: mode ? mode?.toUpperCase() : "",
        rejectionNote: "",
        name: selectedName ? selectedName : "",
      });
      setIsSubmitting(false);
      setTrigger(makeRandomString(5));
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
      setError(err?.response?.data?.error || "Something went wrong");
    }
  };
  const disableSave = useMemo(() => {
    if (!selectedPositionID || isSubmitting) {
      return true;
    }
    return false;
  }, [isSubmitting, selectedPositionID]);

  const [dataList, setDatalist] = useState([]);
  const [dataListPublic, setDatalistPublic] = useState([]);
  // Simulated asynchronous API calls
  // const fetchPrivateData = async (items) => {
  //   // Simulate API call (replace with actual API call)
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   // Your actual API call to update private data
  // };

  // const fetchPublicData = async (items) => {
  //   // Simulate API call (replace with actual API call)
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   // Your actual API call to update public data
  // };

  const handleSubmitNewPosition = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true); // Set loading to true when starting the fetch
      const date = new Date();
      const time = date.getTime();
      let items = {
        id: time,
        positionName: searchTerm,
      };
      if (mode === "private") {
        setDatalist([items, ...dataList]);
        setSearchTerm("");
        setSelectedPositionID(items.id);
        setSelectedName(items?.positionName);
        setSelectedID(null);
        setSelectedPage(1);
        setIsLoading(false);
      } else {
        setDatalistPublic([items, ...dataListPublic]);
        setSearchTerm("");
        setSelectedPositionID(items.id);
        setSelectedPage(1);
        setSelectedID(null);
        setSelectedName(items?.positionName);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Set loading to false when fetch is complete (whether successful or not)
    }
  };

  useEffect(() => {
    if (dataList?.length > 0 || dataListPublic?.length > 0) {
      fetchList();
    }
    fetchList();
  }, [dataList?.length, dataListPublic?.length, fetchList]);
  
  useEffect(() => {
    setSelectedPositionID(null);
  }, [mode]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const filteredOptions = useMemo(() => {
    return dataList?.filter((el) =>
      el?.positionName
        ?.toLowerCase()
        ?.includes(debouncedSearchTerm?.toLowerCase())
    );
  }, [dataList, debouncedSearchTerm]);

  const filteredOptionsPublic = useMemo(() => {
    return dataListPublic?.filter((el) =>
      el?.positionName
        ?.toLowerCase()
        ?.includes(debouncedSearchTerm?.toLowerCase())
    );
  }, [dataListPublic, debouncedSearchTerm]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Pindah Klien & Posisi</h3>
        <button onClick={onClose}>
          <Icon icon={"cross"} size={24} />
        </button>
      </div>
      <div className={Styles.content}>
        <div className={Styles.intro}>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_USER} alt="name" />
            </div>
            <div>
              <span>Candidate</span>
              <span>{capitalizedFirstLetter(candidate?.name)}</span>
            </div>
          </div>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_JOB} alt="name" />
            </div>
            <div>
              <span>Job</span>
              <span>{candidate?.ExpectedPosition?.name}</span>
            </div>
          </div>
        </div>
        <div className={Styles.modeSetter}>
          <span>Alihkan kandidat yang tidak memenuhi kualifikasi</span>
          <div className={Styles.modes}>
            {modes?.map((m, i) => (
              <div key={i} className={Styles.each}>
                <CheckboxJobPosting
                  checked={m === mode ? true : false}
                  onChange={() => setMode(m)}
                  type={"radio-section"}
                />

                <div className={Styles.icon}>
                  <Icon icon={"building"} size={24} />
                </div>
                <div className={Styles.title}>
                  <span>Penyimpanan</span>
                  <span>{m}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={Styles.list}>
          {/* <span>Posisi Tersedia</span> */}
          {mode === "private" && (
            <div className={Styles.isClientSetter}>
              <span>Tampilkan klien pada posisi yang sudah ada</span>
              <Switch
                isChecked={isClient}
                setIsChecked={setIsClient}
                height="40px"
                padding="4px"
                width="72px"
                offBg="#F5F5F5"
              />
            </div>
          )}
          <div className={Styles.searchbarWrapper}>
            <OutlinedSearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={"Cari nama posisi atau klien"}
            />
            {debouncedSearchTerm &&
              !positionList?.length &&
              !isClient &&
              !filteredOptions?.length &&
              !filteredOptionsPublic?.length && (
                <button onClick={handleSubmitNewPosition}>
                  <Icon icon={"circle-plus"} size={16} />
                  <span>Tambah ke posisi baru</span>
                </button>
              )}
          </div>
          <div
            className={Styles.outerWrapper}
            style={{
              height: mode === "private" && isClient ? "350px" : "300px",
            }}
          >
            <div
              className={`${Styles.tableWrapper} ${
                positionList?.length ? Styles.grow : ""
              }`}
            >
              {mode === "private" && isClient ? (
                <table>
                  <thead>
                    <tr>
                      <th>Posisi</th>
                      <th>Klien</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {positionList?.map((obj, i) => (
                      <tr key={i}>
                        <td>
                          <div>
                            <Radio
                              isChecked={
                                obj?.id === selectedPositionID ? true : false
                              }
                              setIsChecked={() => {
                                setSelectedPositionID(obj?.id);
                                setSelectedID(obj?.id);
                                setSelectedName(null);
                              }}
                            />
                            <span>{obj?.positionName} </span>
                          </div>
                        </td>
                        <td>{obj?.clientName}</td>
                        <td>{obj?.requestAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className={!isLoading ? Styles?.listWrapper : ""}>
                  {filteredOptions?.length > 0 && (
                    <>
                      {mode === "private" &&
                        selectedPage === 1 &&
                        filteredOptions?.map((obj, i) => {
                          return (
                            <div key={i} className={Styles.each}>
                              <CheckboxJobPosting
                                checked={
                                  obj?.id === selectedPositionID ? true : false
                                }
                                onChange={() => {
                                  setSelectedPositionID(obj?.id);
                                  setSelectedName(obj?.positionName);
                                  setSelectedID(null);
                                }}
                                type={"radio-section"}
                              />
                              <Icon icon={"contracted"} size={20} />
                              <span>{obj?.positionName}</span>
                            </div>
                          );
                        })}
                    </>
                  )}
                  {filteredOptionsPublic?.length > 0 && (
                    <>
                      {mode === "public" &&
                        selectedPage === 1 &&
                        filteredOptionsPublic?.map((obj, i) => {
                          return (
                            <div key={i} className={Styles.each}>
                              <CheckboxJobPosting
                                checked={
                                  obj?.id === selectedPositionID ? true : false
                                }
                                onChange={() => {
                                  setSelectedPositionID(obj?.id);
                                  setSelectedName(obj?.positionName);
                                  setSelectedID(null);
                                }}
                                type={"radio-section"}
                              />
                              <Icon icon={"contracted"} size={20} />
                              <span>{obj?.positionName}</span>
                            </div>
                          );
                        })}
                    </>
                  )}
                  {positionList?.length > 0 && (
                    <>
                      {positionList?.map((obj, i) => (
                        <div key={i} className={Styles.each}>
                          <CheckboxJobPosting
                            checked={
                              obj?.id === selectedPositionID ? true : false
                            }
                            onChange={() => {
                              setSelectedPositionID(obj?.id);
                              setSelectedID(obj?.id);
                              setSelectedName(null);
                            }}
                            type={"radio-section"}
                          />
                          <Icon icon={"contracted"} size={20} />
                          <span>{obj?.positionName}</span>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
            {!positionList?.length &&
              !filteredOptions?.length &&
              !filteredOptionsPublic?.length && (
                <div className={Styles.emptyWrapper}>
                  <TableEmptyHandler
                    title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
                    desc={
                      isLoading
                        ? "Data sedang dimuat"
                        : "Tidak ada data atau data belum ada"
                    }
                  />
                </div>
              )}

            <div className={Styles.paginationWrapper}>
              <Pagination
                total={totalData}
                totalPage={totalPage}
                value={limit}
                setValue={setLimit}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                noPageList
                noValueSetter
              />
            </div>
          </div>
        </div>
        <div
          className={`${Styles.errorWrapper} ${
            error ? Styles.visible : Styles.hide
          }`}
        >
          <span>{error || "error placeholder"}</span>
        </div>
        <div className={Styles.buttonSection}>
          <button onClick={handleSave} disabled={disableSave}>
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
}
