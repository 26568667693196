import Styles from "./contactPersonCard.module.scss";

const ContactPersonCard = ({ name, picture, ...props }) => {
  const initial = name[0];
  return (
    <div className={Styles.container}>
      {picture ? (
        <img src={picture} className={Styles.picture} alt="profile" />
      ) : (
        <div className={Styles.pictureWrapper}>{initial}</div>
      )}
      <div className={Styles.contactPersonName}>{name}</div>
    </div>
  );
};

export default ContactPersonCard;
