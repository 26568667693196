import Icon from "@Atom/Icon";
import AutocompleteField from "@Molecule/AutocompleteField";
import InputField from "@Molecule/InputField";
import InputYearField from "@Molecule/InputYearField";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function EducationData({
  data,
  form,
  setForm,
  options,
  errorField,
}) {
  var index = useMemo(() => {
    return form.findIndex((x) => x?.id === data?.id);
  }, [data, form]);

  const [startingYearTemp, setStartingYearTemp] = useState(
    form[index]?.startYear
  );
  const [finishingYearTemp, setFinishingYearTemp] = useState(
    form[index]?.endYear
  );
  const [educationTypeTemp, setEducationTypeTemp] = useState(form[index]?.type);

  useEffect(() => {
    setEducationTypeTemp(form[index]?.type);
    setStartingYearTemp(form[index]?.startYear);
    setFinishingYearTemp(form[index]?.endYear);
  }, [form, index]);

  const handleChange = useCallback(
    (e, inputName) => {
      if (inputName === "sertificateNumber") {
        setForm([
          ...form?.slice(0, index),
          Object.assign({}, form[index], {
            [inputName]: !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase(),
          }),
          ...form?.slice(index + 1),
        ]);
      } else {
        setForm([
          ...form?.slice(0, index),
          Object.assign({}, form[index], {
            [inputName]: !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase(),
          }),
          ...form?.slice(index + 1),
        ]);
      }
    },
    [index, form, setForm]
  );

  useEffect(() => {
    setForm([
      ...form?.slice(0, index),
      Object.assign({}, form[index], {
        type: educationTypeTemp,
        startYear: startingYearTemp,
        endYear: finishingYearTemp,
      }),
      ...form?.slice(index + 1),
    ]);

    // eslint-disable-next-line
  }, [index, educationTypeTemp, startingYearTemp, finishingYearTemp]);

  const handleDelete = useCallback(() => {
    const newForm = [...form];
    newForm?.splice(index, 1);
    setForm(newForm);
  }, [form, setForm, index]);

  return (
    <div className={Styles.container}>
      <h3>Data Pendidikan {index + 1}</h3>
      <div className={Styles.content}>
        <AutocompleteField
          title={"Jenis Pendidikan"}
          placeholder={"Jenis Pendidikan"}
          searchOptions={["FORMAL", "NON FORMAL"]}
          value={educationTypeTemp}
          setValue={setEducationTypeTemp}
          iconColor={"#1571DE"}
          isError={errorField && !educationTypeTemp}
          errorText={"Jenis Pendidikan belum diisi"}
        />
        <div className={Styles.grid}>
          <InputYearField
            title={"Dari Tahun"}
            placeholder={"Pilih tahun"}
            value={startingYearTemp}
            setValue={setStartingYearTemp}
            iconColor={"#1571DE"}
            disabled={!educationTypeTemp}
            isError={errorField && !startingYearTemp}
            errorText={"Tahun belum diisi"}
          />
          <InputYearField
            title={"Sampai Tahun"}
            placeholder={"Pilih tahun"}
            value={finishingYearTemp}
            setValue={setFinishingYearTemp}
            iconColor={"#1571DE"}
            disabled={!educationTypeTemp}
            isError={errorField && !finishingYearTemp}
            rrorText={"Tahun belum diisi"}
          />

          <InputField
            title={"Nama Penyelenggara"}
            placeholder={"Penyelenggara"}
            value={data?.name}
            onChange={(e) => handleChange(e, "name")}
            disabled={!educationTypeTemp}
            isError={errorField && !data?.name}
            errorText={"Penyelenggara belum diisi"}
          />
          <InputField
            title={"Fakultas/Jurusan"}
            placeholder={"Tulis nama fakultas/jurusan"}
            value={data?.mastery}
            onChange={(e) => handleChange(e, "mastery")}
            disabled={!educationTypeTemp}
            isError={errorField && !data?.mastery}
            errorText={"Fakultas/Jurusan belum diisi"}
          />
          <InputField
            title={"Nomor Ijazah"}
            placeholder={"000"}
            value={data?.sertificateNumber}
            onChange={(e) => handleChange(e, "sertificateNumber")}
            disabled={!educationTypeTemp}
            isError={errorField && !data?.sertificateNumber}
            errorText={"Nomor belum diisi"}
          />
          <InputField
            title={"Kota"}
            placeholder={"Tulis nama kota"}
            value={data?.city}
            onChange={(e) => handleChange(e, "city")}
            disabled={!educationTypeTemp}
            isError={errorField && !data?.city}
            errorText={"Kota belum diisi"}
          />
        </div>
      </div>
      <button onClick={handleDelete}>
        <Icon icon="delete" size={20} />
        <span>Hapus</span>
      </button>
    </div>
  );
}
