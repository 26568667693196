import OutlinedSearchBar from '@Atom/OutlinedSearchBar';
import TableEmptyHandler from '@Atom/TableEmptyHandler';
import { fileBaseUrl } from '@Config/api';
import { useDebounce } from '@Hooks/useDebounce';
import Pagination from '@Molecule/Pagination';
import { fetchAllRecruiter } from '@Services/officer/recruitment';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import Styles from '../styles.module.scss';

export default function Step1({  
    selectedRecruiter, 
    setSelectedRecruiter 
}) {
    const TABLE_HEAD = [
        { id: 'ro', label: 'RO', align: 'left' },
        { id: 'totalJobPost', label: 'Jumlah Job Posting', align: 'left' },
    ];
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadRecruiter, setLoadRecruiter] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [pages, setPages] = useState(`1-${limit}`);
    const [pageList, setPageList] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [totalData, setTotalData] = useState();

    const debouncedSearch = useDebounce(searchTerm, 300);

    useEffect(() => {
        const fetchRecruiter = async () => {
            try {
                setIsLoading(true);
                const { response } = await fetchAllRecruiter(
                    `limit=${limit}&page=${page}&search=${debouncedSearch}`
                );
                setLoadRecruiter(response?.data);
                setTotalPage(response?.totalPage);
                setTotalData(response?.totalRecruitmentOfficers)
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.log(error);
            }
        };
        fetchRecruiter();
    }, [limit, page, debouncedSearch]);

    return (
        <div className={Styles.content}>
            <div className={Styles.searchBarWrapper}>
                <OutlinedSearchBar
                    value={searchTerm}
                    setValue={setSearchTerm}
                    placeholder={'Cari Nama RO'}
                />
            </div>
            <div className={Styles.tableWrapper}>
                <TableContainer className={Styles.tableContainer}>
                    <Table className={Styles.table}>
                        <colgroup>
                            <col style={{ width: '55%' }} />
                            <col style={{ width: '45%' }} />
                        </colgroup>
                        <TableHead className={Styles.tableHead}>
                            <TableRow className={Styles.tableHeadRow}>
                                {TABLE_HEAD?.map((eachH, iH) => (
                                    <TableCell
                                        key={'headcell' + iH}
                                        align={eachH?.align}
                                        padding="none"
                                        className={Styles.tableHeadCell}>
                                        <div>
                                            <span>{eachH?.label}</span>
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className={Styles.tableBody}>
                            {loadRecruiter?.map((eachP, iP) => {
                                return (
                                    <TableRow className={`${Styles.tableRow}`} key={'tableRow' + iP}>
                                        <TableCell padding="none" className={Styles.tableCell}>
                                            <div className={Styles.stackedProfile} style={{cursor: "pointer"}} onClick={() => {setSelectedRecruiter(eachP)}}>
                                            {eachP?.profilePicture ? (
                                                    <Avatar
                                                        alt={eachP?.name}
                                                        src={
                                                            fileBaseUrl + eachP?.profilePicture
                                                        }
                                                        sx={{ width: 36, height: 36 }}
                                                    />
                                                ) : (
                                                    <Avatar sx={{ width: 36, height: 36 }}>{eachP?.name[0]}</Avatar>
                                                )}
                                                <span>{eachP.name}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell padding="none" className={Styles.tableCell}>
                                            <span>{eachP?.totalJobPosting}</span>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!loadRecruiter.length && (
                    <div className={Styles.emptyDialogWrapper}>
                        <TableEmptyHandler
                            title={isLoading ? 'Tunggu sebentar' : 'Data Kosong'}
                            desc={isLoading ? 'Data sedang dimuat' : 'Tidak ada data atau data belum ada'}
                        />
                    </div>
                )}

                <div className={Styles.paginationWrapper}>
                    <Pagination
                        total={totalData}
                        totalPage={totalPage}
                        value={limit}
                        setValue={setLimit}
                        pages={pages}
                        setPages={setPages}
                        pageList={pageList}
                        setPageList={setPageList}
                        selectedPage={page}
                        setSelectedPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
}
