import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import ICON from "../../../../../assets/Images/corrupted-file.png";
import { revertStage } from "@Services/support/candidate";
import { useState } from "react";
import Spinner from "@Atom/Spinner";

const RevertStageModal = ({ id, onClose, setTrigger }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleNext = async () => {
    try {
      setIsLoading(true);
      await revertStage(id);
      setIsLoading(false);
      setTrigger(Math.random());
      onClose();
      setError("");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err?.response?.data?.error);
      // setTimeout(() => {
      //   setError("");
      // }, 3000);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.title}>Batalkan Proses</div>
        <Icon
          size="24"
          icon="cross"
          className={Styles.icon}
          onClick={() => {
            if (!isLoading) {
              onClose();
              setError("");
            }
          }}
        />
      </div>
      <div className={Styles.iconWrapper}>
        <img src={ICON} alt="" height={120} width={120} />
        <div className={Styles.text}>
          Membatalkan proses akan mengembalikan kandidat pada proses awal
          (Register) dan kontrak yang telah di generated akan terhapus
        </div>
      </div>
      <div className={Styles.buttonSection}>
        {error && <span>{error}</span>}
        <div className={Styles.buttons}>
          <div
            className={`${Styles.secondaryButton} ${
              isLoading && Styles.disabled
            }`}
            onClick={() => {
              if (!isLoading) {
                onClose();
                setError("");
              }
            }}
          >
            <div className={Styles.text}>Batal</div>
          </div>
          <div
            className={`${Styles.primaryButton} ${
              isLoading && Styles.disabled
            }`}
            onClick={isLoading ? null : handleNext}
          >
            {isLoading ? <Spinner /> : <div className={Styles.text}>Ya</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevertStageModal;
