/*eslint-disable no-useless-escape */

import { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import InputNumberField from '@Molecule/InputNumberField'
import InputField from '@Molecule/InputField'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function FlowSection(props) {
  const { form, setForm, setTrigger } = props
  const [dateOfReceiptOfData, setDateOfReceiptOfData] = useState(form?.invoiceFlow?.dateOfReceiptOfData)
  const [dateOfFillingOfSoftCopy, setDateOfFillingOfSoftCopy] = useState(form?.invoiceFlow?.dateOfFillingOfSoftCopy)
  const [dateOfFillingOfHardCopy, setDateOfFillingOfHardCopy] = useState(form?.invoiceFlow?.dateOfFillingOfHardCopy)
  const [UP, setUP] = useState(form?.invoiceFlow?.UP)
  const [payrollDate, setPayrollDate] = useState(form?.invoiceFlow?.payrollDate)
  const [cutOffAttendance, setCutOffAttendance] = useState(form?.invoiceFlow?.cutOffAttendance)
  const [docSupport, setDocSupport] = useState(form?.invoiceFlow?.supportingDocument)

  useEffect(() => {
    const newForm = form
    newForm.invoiceFlow.dateOfReceiptOfData = Number(dateOfReceiptOfData)
    newForm.invoiceFlow.dateOfFillingOfSoftCopy = Number(dateOfFillingOfSoftCopy)
    newForm.invoiceFlow.dateOfFillingOfHardCopy = Number(dateOfFillingOfHardCopy)
    newForm.invoiceFlow.UP = UP
    newForm.invoiceFlow.payrollDate = Number(payrollDate)
    newForm.invoiceFlow.cutOffAttendance = Number(cutOffAttendance)
    newForm.invoiceFlow.supportingDocument = docSupport
    setForm(newForm)
    setTrigger(makeRandomString(5))
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
  }, [form,
    setForm,
    dateOfReceiptOfData,
    dateOfFillingOfSoftCopy,
    dateOfFillingOfHardCopy,
    UP,
    payrollDate,
    cutOffAttendance,
    docSupport,
    setTrigger
  ])


  return (
    <div className={Styles.container}>
      <h3>Flow Invoice</h3>

      <div className={Styles.content}>
        <div className={Styles.selectsWrapper}>
          <div className={Styles.inputWrapper}>
            <InputNumberField
              title="Tanggal Diterima Data"
              min={1}
              max={31}
              value={dateOfReceiptOfData}
              setValue={setDateOfReceiptOfData}
              required
            />
          </div>
          <div className={Styles.inputWrapper}>
            <InputNumberField
              title="Tanggal Pengajuan Soft Copy Invoice"
              min={1}
              max={31}
              value={dateOfFillingOfSoftCopy}
              setValue={setDateOfFillingOfSoftCopy}
              required
            />
          </div>
          <div className={Styles.inputWrapper}>
            <InputNumberField
              title="Tanggal Hard Copy Invoice Diterima"
              min={1}
              max={31}
              value={dateOfFillingOfHardCopy}
              setValue={setDateOfFillingOfHardCopy}
              required
            />
          </div>
          <div className={Styles.inputWrapper}>
            <InputField
              title="UP di Invoice"
              value={UP || ''}
              onChange={(e) => setUP(e.target.value)}
              placeholder={"Name"}
              required
            />
          </div>
          <div className={Styles.selectWrapper}>
            <InputNumberField
              title="Tanggal Mulai Penggajian"
              min={1}
              max={31}
              value={payrollDate}
              setValue={setPayrollDate}
              required
            />
          </div>
          <div className={Styles.selectWrapper}>
            <InputNumberField
              title="Tanggal Cut Off Absensi"
              min={1}
              max={31}
              value={cutOffAttendance}
              setValue={setCutOffAttendance}
              required
            />
          </div>
        </div>
        <div className={Styles.descWrapper}>
          <InputField
            type="desc"
            title="Dokumen Pendukung Invoice"
            value={docSupport || ''}
            onChange={(e) => setDocSupport(e.target.value.replace(/[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/ig, ""))}
            placeholder={"Deskripsi"}
          />
        </div>

      </div>
    </div>
  )
}