import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Counter from "@Atom/Counter";
import DecorativeInput from "@Atom/DecorativeInput";
import Dropdown from "@Atom/DropDown";
import FormErrorMessage from "@Atom/FormErrorMessage";
import Icon from "@Atom/Icon";
import Rotation from "@Atom/Rotation";
import { makeRandomString } from "@Helpers/makeRandomString";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import DinamicDecorativeInputField from "@Molecule/DinamicDecorativeInputField";
import InputDateField from "@Molecule/InputDateField";
import { InputTitle } from "@Molecule/InputField";
import MultiSelectSmallLabel from "@Molecule/MultiSelectSmallLabel";
import SchemeDetailModal from "@Molecule/_modal/SchemeDetailModal";
import { editOrderAndSendFLB } from "@Services/manager/client";
import Images from "@Theme/Images";
import { Avatar } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FinishedModal from "../../AddOrder/Seventh/FinishedModal";
import Styles from "./style.module.scss";

export default function SchemeProceedFormLayout({
  syncData,
  defaultOrderForm,
  alertMessage = "",
  setAlertMessage = () => {},
}) {
  const navigate = useNavigate();
  const query = useQuery();

  const [viewDetails, setViewDetails] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const defaultDeploymentsData = useMemo(() => {
    return [
      {
        role: "BUSINESS MANAGER",
        delegations: syncData?.users?.businessManagers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ACCOUNT MANAGER / ACCOUNT OFFICER",
        delegations: syncData?.users?.accountManagerOrPIC?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ADMIN / ADMIN CONTRACT",
        delegations: syncData?.users?.accountOfficerOrAdmin?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "RECRUITMENT OFFICER",
        delegations: syncData?.users?.recruitmentOfficers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
    ];
  }, [
    syncData?.users?.accountManagerOrPIC,
    syncData?.users?.accountOfficerOrAdmin,
    syncData?.users?.businessManagers,
    syncData?.users?.recruitmentOfficers,
  ]);

  const selectedButtonBPJS = useMemo(() => {
    return defaultOrderForm?.workScheme?.PenagihanBPU === "" ||
      defaultOrderForm?.workScheme?.PenagihanBPU === null
      ? "Tenaga Kerja"
      : "BPU";
  }, [defaultOrderForm?.workScheme?.PenagihanBPU]);

  const selectedValueBPU = useMemo(() => {
    return defaultOrderForm?.workScheme?.PenagihanBPU || "";
  }, [defaultOrderForm?.workScheme?.PenagihanBPU]);

  const defaultDelegationsForm = useMemo(() => {
    return defaultOrderForm?.position?.deployment?.map((obj) => {
      const users = obj?.users;
      const sorted = [
        users?.businessManagers,
        users?.accountManagerOrPIC,
        users?.accountOfficerOrAdmin,
        users?.recruitmentOfficers,
      ];
      return {
        id: makeRandomString(5),
        cityCode: obj?.cityCode,
        cityName: obj?.cityName,
        umk: syncData?.cities?.find((c) => c?.code === obj?.cityCode)?.umk,
        deployments: defaultDeploymentsData?.map((d, dI) => {
          return {
            ...d,
            delegations: d?.delegations?.map((de) => {
              return {
                ...de,
                delegationID: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                  ?.id,
                isPicked: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                  ? true
                  : false,
                isPIC: sorted[dI]?.find((s) => s?.employeeId === de?.id)?.isPIC
                  ? true
                  : false,
              };
            }),
          };
        }),
      };
    });
  }, [
    defaultDeploymentsData,
    defaultOrderForm?.position?.deployment,
    syncData?.cities,
  ]);

  const defaultPositionsForm = useMemo(() => {
    return defaultOrderForm?.position?.list?.map((obj) => {
      return {
        id: makeRandomString(5),
        preventDelete: true,
        isExpanded: false,
        positionName: obj?.position,
        dedicatedROName: obj?.dedicatedRO?.name,
        dedicatedROId: obj?.dedicatedRO?.employeeId || "",
        qualifications: obj?.qualification?.map((q) => {
          return {
            id: q?.id,
            qualificationID: q?.id,
            name: q?.name,
            value: q?.value,
            denom: syncData?.qualifications?.find((o) => o?.value === q?.name)
              ?.denom,
          };
        }),
        citiesData: obj?.cities?.map((c) => {
          return {
            id: makeRandomString(5),
            positionID: c?.position?.id,
            preventDelete: true,
            preventEdit: true,
            isApplied: true,
            cityCode: c?.position?.cityCode,
            cityName: c?.cityName,
            umk: syncData?.cities?.find(
              (sc) => sc?.code === c?.position?.cityCode
            )?.umk,
            amount: c?.position?.amount,
            salaryValue: c?.position?.salary?.includes("::")
              ? c?.position?.salary?.split("::")[0]
              : c?.position?.salary,
            salaryDenom: c?.position?.salary?.includes("::")
              ? c?.position?.salary?.split("::")[1]
              : "",
            personalEquipments: c?.position?.personalEquipments?.map((p) => {
              return {
                equipmentID: p?.id,
                name: p?.name,
                value: p?.price,
              };
            }),
            allowances: c?.position?.allowances
              ?.filter((a) => a?.type !== "BPU")
              ?.map((a) => {
                return {
                  allowanceID: a?.id,
                  name: a?.name,
                  value: a?.value,
                };
              }),
            jkk:
              c?.position?.allowances?.find((a) => a?.name === "JKK")?.value ||
              0,
            jkkID:
              c?.position?.allowances?.find((a) => a?.name === "JKK")?.id || "",
            jkm:
              c?.position?.allowances?.find((a) => a?.name === "JKM")?.value ||
              0,
            jkmID:
              c?.position?.allowances?.find((a) => a?.name === "JKM")?.id || "",
            jht:
              c?.position?.allowances?.find((a) => a?.name === "JHT")?.value ||
              0,
            jhtID:
              c?.position?.allowances?.find((a) => a?.name === "JHT")?.id || "",
            expectedDate: c?.position?.expectedFulfillmentDate,
          };
        }),
      };
    });
  }, [
    defaultOrderForm?.position?.list,
    syncData?.cities,
    syncData?.qualifications,
  ]);
  const [delegationsForm, setDelegationsForm] = useState(
    defaultDelegationsForm?.length ? defaultDelegationsForm : []
  );
  const [positionsForm, setPositionsForm] = useState(
    defaultPositionsForm?.length ? defaultPositionsForm : []
  );

  const mappedDelegationsForm = useMemo(() => {
    return delegationsForm?.map((obj) => {
      return {
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => {
              return {
                ...u,
                position: d?.role,
              };
            })
          )
          ?.flat()
          ?.filter((u) => u?.isPicked),
      };
    });
  }, [delegationsForm]);
  const mappedDeletedDelegationsForm = useMemo(() => {
    return delegationsForm?.map((obj) => {
      return {
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => {
              return {
                ...u,
                position: d?.role,
              };
            })
          )
          ?.flat()
          ?.filter((u) => !u?.isPicked)
          ?.filter((u) => u?.delegationID),
      };
    });
  }, [delegationsForm]);

  const alertFound = useMemo(() => {
    const dArr = delegationsForm?.map(
      (obj) => obj?.deployments[0]?.delegations
    );
    for (let i = 0; i < dArr.length; i++) {
      const d = dArr[i];
      if (!d?.find((u) => u?.isPicked)) {
        return `Harap pilih BUSINESS MANAGER untuk setiap lokasi`;
      }
    }

    const dArr1 = delegationsForm?.map(
      (obj) => obj?.deployments[1]?.delegations
    );
    for (let i = 0; i < dArr1.length; i++) {
      const d = dArr1[i];
      if (!d?.find((u) => u?.isPicked)) {
        return `Harap pilih ACCOUNT MANAGER / ACCOUNT OFFICER untuk setiap lokasi`;
      }
    }

    const dArr2 = delegationsForm?.map(
      (obj) => obj?.deployments[2]?.delegations
    );
    for (let i = 0; i < dArr2.length; i++) {
      const d = dArr2[i];
      if (!d?.find((u) => u?.isPicked)) {
        return `Harap pilih ADMIN / ADMIN CONTRACT untuk setiap lokasi`;
      }
    }

    const dArr3 = delegationsForm?.map(
      (obj) => obj?.deployments[3]?.delegations
    );
    for (let i = 0; i < dArr3.length; i++) {
      const d = dArr3[i];
      if (!d?.find((u) => u?.isPicked)) {
        return `Harap pilih RECRUITMENT OFFICER untuk setiap lokasi`;
      }
    }
  }, [delegationsForm]);

  useEffect(() => {
    if (!alertFound) {
      setAlertMessage(alertFound);
    }
  }, [alertFound, setAlertMessage]);

  const handleSubmit = async (e) => {
    if (alertFound) {
      setAlertMessage(alertFound);
    } else {
      setIsSubmitting(true);

      try {
        e.preventDefault();

        const formData = new FormData();

        formData?.append("clientCode", query?.get("c"));

        for (let i = 0; i < mappedDelegationsForm.length; i++) {
          const dataPerCity = mappedDelegationsForm[i];
          formData?.append(`orderLocations[${i}][code]`, dataPerCity?.code);

          for (let j = 0; j < dataPerCity?.delegations?.length; j++) {
            const perDelegation = dataPerCity?.delegations[j];

            formData?.append(
              `orderLocations[${i}][delegations][${j}][delegationID]`,
              perDelegation?.delegationID || ""
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][position]`,
              perDelegation?.position
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][employeeId]`,
              perDelegation?.id
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][isPIC]`,
              perDelegation?.isPIC ? "YES" : "NO"
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][action]`,
              perDelegation?.delegationID ? "UPDATE" : ""
            );
          }
          if (mappedDeletedDelegationsForm[i]?.delegations?.length) {
            for (
              let j = 0;
              j < mappedDeletedDelegationsForm[i]?.delegations?.length;
              j++
            ) {
              const perDelegation =
                mappedDeletedDelegationsForm[i]?.delegations[j];

              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][delegationID]`,
                perDelegation?.delegationID || ""
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][position]`,
                perDelegation?.position
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][employeeId]`,
                perDelegation?.id
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][isPIC]`,
                perDelegation?.isPIC ? "YES" : "NO"
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][action]`,
                perDelegation?.delegationID ? "DELETE" : ""
              );
            }
          }
        }

        for (let i = 0; i < positionsForm?.length; i++) {
          const perPositionData = positionsForm[i];

          formData?.append(`jobs[${i}][title]`, perPositionData?.positionName);
          // formData?.append(`jobs[${i}][dedicatedRO]`, perPositionData?.dedicatedROId)

          for (let j = 0; j < perPositionData?.qualifications?.length; j++) {
            const perQualification = perPositionData?.qualifications[j];

            formData?.append(
              `jobs[${i}][qualifications][${j}][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][action]`,
              perQualification?.qualificationID ? "UPDATE" : ""
            );
          }

          const deletedQualificationsArr =
            defaultPositionsForm
              ?.find(
                (obj) => obj?.positionName === perPositionData?.positionName
              )
              ?.qualifications?.filter((obj) =>
                perPositionData?.qualifications?.every(
                  (f) => f?.qualificationID !== obj?.qualificationID
                )
              ) || [];

          for (let j = 0; j < deletedQualificationsArr?.length; j++) {
            const perQualification = deletedQualificationsArr[j];

            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][action]`,
              perQualification?.qualificationID ? "DELETE" : ""
            );
          }

          for (let j = 0; j < perPositionData?.citiesData?.length; j++) {
            const perCityData = perPositionData?.citiesData[j];

            formData?.append(
              `jobs[${i}][locations][${j}][positionID]`,
              perCityData?.positionID || ""
            );
            formData?.append(
              `jobs[${i}][locations][${j}][code]`,
              perCityData?.cityCode
            );
            formData?.append(
              `jobs[${i}][locations][${j}][salary]`,
              `${perCityData?.salaryValue}::${perCityData?.salaryDenom}`
            );
            formData?.append(
              `jobs[${i}][locations][${j}][fulfilmentDate]`,
              moment(perCityData?.expectedDate)?.format("YYYY-MM-DD")
            );
            formData?.append(
              `jobs[${i}][locations][${j}][demand]`,
              perCityData?.amount
            );

            for (let k = 0; k < perCityData?.personalEquipments?.length; k++) {
              const perEquipment = perCityData?.personalEquipments[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][action]`,
                perEquipment?.equipmentID ? "UPDATE" : ""
              );
            }

            const deletedPersonalEquipmentsArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.personalEquipments?.filter((obj) =>
                  perCityData?.personalEquipments?.every(
                    (f) => f?.equipmentID !== obj?.equipmentID
                  )
                ) || [];

            for (let k = 0; k < deletedPersonalEquipmentsArr?.length; k++) {
              const perEquipment = deletedPersonalEquipmentsArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][action]`,
                perEquipment?.equipmentID ? "DELETE" : ""
              );
            }

            for (let k = 0; k < perCityData?.allowances?.length; k++) {
              const perAllowance = perCityData?.allowances[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][action]`,
                perAllowance?.allowanceID ? "UPDATE" : ""
              );
            }

            const deletedAllowancesArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.allowances?.filter((obj) =>
                  perCityData?.allowances?.every(
                    (f) => f?.allowanceID !== obj?.allowanceID
                  )
                ) || [];

            for (let k = 0; k < deletedAllowancesArr?.length; k++) {
              const perAllowance = deletedAllowancesArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][action]`,
                perAllowance?.allowanceID ? "DELETE" : ""
              );
            }

            if (selectedButtonBPJS === "BPU") {
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length + deletedAllowancesArr?.length
                }][allowanceID]`,
                perCityData?.jkkID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length + deletedAllowancesArr?.length
                }][name]`,
                "JKK"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length + deletedAllowancesArr?.length
                }][value]`,
                perCityData?.jkk || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length + deletedAllowancesArr?.length
                }][action]`,
                perCityData?.jkkID ? "UPDATE" : ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length + deletedAllowancesArr?.length
                }][type]`,
                "BPU"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  1
                }][allowanceID]`,
                perCityData?.jkmID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  1
                }][name]`,
                "JKM"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  1
                }][value]`,
                perCityData?.jkm || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  1
                }][action]`,
                perCityData?.jkmID ? "UPDATE" : ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  1
                }][type]`,
                "BPU"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  2
                }][allowanceID]`,
                perCityData?.jhtID
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  2
                }][name]`,
                "JHT"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  2
                }][value]`,
                perCityData?.jht || 0
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  2
                }][action]`,
                perCityData?.jhtID ? "UPDATE" : ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  perCityData?.allowances?.length +
                  deletedAllowancesArr?.length +
                  2
                }][type]`,
                "BPU"
              );
            }
          }

          await editOrderAndSendFLB(formData, query.get("i"));
          setIsSubmitting(false);
          setIsSuccess(true);
        }
      } catch (err) {
        console.log(err.response.data.error);
        setIsSubmitting(false);
        setError({
          code: err?.response?.data?.code || "500",
          message: err?.response?.data?.error || "Something went wrong",
        });
        setAlertMessage(err?.response?.data?.error || "Something went wrong");
        scrollingTop();
      }
    }
  };

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <DetailFlbSection
        clientName={
          defaultOrderForm?.additional?.periodeKerjasama?.Client?.name
        }
        docNumber={defaultOrderForm?.additional?.periodeKerjasama?.docNumber}
        setViewDetails={setViewDetails}
      />
      <LocationSection
        locationList={defaultOrderForm?.position?.deployment?.map(
          (obj) => obj?.cityName
        )}
      />
      <FormSection
        alertMessage={alertMessage}
        delegationsForm={delegationsForm}
        setDelegationsForm={setDelegationsForm}
        positionsForm={positionsForm}
        setPositionsForm={setPositionsForm}
        personalEquipmentOptions={syncData?.options
          ?.filter((obj) => obj?.type === "Personal Work Equipment")
          ?.map((obj) => obj?.value)}
        allowanceOptions={syncData?.options
          ?.filter((obj) => obj?.type === "Allowance")
          ?.map((obj) => obj?.value)}
        qualificationOptions={syncData?.qualifications?.map((obj) => {
          return {
            // id: makeRandomString(5),
            name: obj?.value,
            denom: obj?.denom,
            value: "",
          };
        })}
        selectedButtonBPJS={selectedButtonBPJS}
        selectedValueBPU={selectedValueBPU}
      />
      {!!error && (
        <div style={{ paddingTop: "16px" }}>
          <FormErrorMessage
            message={error?.message || "Something went wrong"}
          />
        </div>
      )}
      <div className={Styles.actions}>
        <button onClick={() => navigate(-1)}>Kembali</button>
        <button disabled={isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? (
            <Rotation width={"20px"} type="white" />
          ) : (
            <span>Simpan</span>
          )}
        </button>
      </div>
      {viewDetails && (
        <SchemeDetailModal
          setShowDetailFlb={setViewDetails}
          onBack={() => setViewDetails(false)}
          handleDone={() => setViewDetails(false)}
          showDetailFlb={defaultOrderForm?.workScheme?.schemeID}
          isBM
        />
      )}
      {isSuccess && (
        <FinishedModal
          clearStorage={() => {}}
          setShow={setIsSuccess}
          submittingType={"send"}
        />
      )}
    </div>
  );
}

const DetailFlbSection = ({ clientName, docNumber, setViewDetails }) => {
  return (
    <div className={`${Styles.detailFlb} ${Styles.section}`}>
      <div className={Styles.dfHeader}>
        <div className={Styles.dfHLeft}>
          <h3 className={Styles.sTitle}>Detail FLB</h3>
          <span>Service Client</span>
        </div>

        <button onClick={() => setViewDetails(true)}>
          <span>View Detail</span>
          <Icon icon={"eye-visible"} size={20} />
        </button>
      </div>
      <div className={`${Styles.dfContent} ${Styles.grid}`}>
        {[...Array(2)]?.map((_, i) => (
          <div key={i}>
            <div>
              <Icon icon={"company"} size={32} />
            </div>
            <span>{i === 0 ? clientName : docNumber}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const LocationSection = ({ locationList = [] }) => {
  return (
    <div className={`${Styles.location} ${Styles.section}`}>
      <h3 className={Styles.sTitle}>Location</h3>
      <div className={`${Styles.locContent} ${Styles.grid}`}>
        {locationList?.map((each, i) => (
          <div key={i}>
            <div>
              <Icon icon={"company-outline"} size={24} />
            </div>
            <span>{each}</span>
            <img src={Images.LOC_DECOR} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

const FormSection = ({
  delegationsForm,
  setDelegationsForm,
  positionsForm,
  setPositionsForm,
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  selectedButtonBPJS,
  selectedValueBPU,
  alertMessage,
}) => {
  const [activeStep, setActiveStep] = useState(
    sessionStorage?.getItem("activeFourthStep") || "1"
  );

  const mainContent = useMemo(() => {
    switch (activeStep) {
      case "1":
        return (
          <div className={Styles.delegationsAllCities}>
            {delegationsForm?.map((d, dI) => (
              <DelegationPerLocation
                key={dI}
                alertMessage={alertMessage}
                delegationData={d}
                setDelegationData={(newData) => {
                  const newDelegationsForm = delegationsForm?.map((obj) => {
                    if (obj?.cityCode === d?.cityCode) {
                      return {
                        ...newData,
                      };
                    }
                    return obj;
                  });
                  setDelegationsForm(newDelegationsForm);
                }}
                onDelete={() =>
                  setDelegationsForm(
                    delegationsForm?.filter(
                      (obj) => obj?.cityCode !== d?.cityCode
                    )
                  )
                }
              />
            ))}
          </div>
        );
      case "2":
        return (
          <div className={Styles.positionsAllCities}>
            {positionsForm?.map((p, pI) => (
              <PerPosition
                key={pI}
                selectedButtonBPJS={selectedButtonBPJS}
                positionsForm={positionsForm}
                onDelete={() =>
                  setPositionsForm(
                    positionsForm?.filter((obj) => obj?.id !== p?.id)
                  )
                }
                positionData={p}
                setPositionData={(name, value) => {
                  setPositionsForm(
                    positionsForm?.map((obj) => {
                      if (p?.id === obj?.id) {
                        return {
                          ...obj,
                          [name]: value,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                personalEquipmentOptions={personalEquipmentOptions}
                allowanceOptions={allowanceOptions}
                qualificationOptions={qualificationOptions}
                selectedValueBPU={selectedValueBPU}
              />
            ))}
          </div>
        );
      default:
        return <></>;
    }
  }, [
    activeStep,
    alertMessage,
    allowanceOptions,
    delegationsForm,
    personalEquipmentOptions,
    positionsForm,
    qualificationOptions,
    selectedButtonBPJS,
    selectedValueBPU,
    setDelegationsForm,
    setPositionsForm,
  ]);

  return (
    <div className={`${Styles.form} ${Styles.section}`}>
      <div className={`${Styles.main} ${Styles.bg}`}>
        <div className={`${Styles.steps} ${Styles.bg}`}>
          {[
            {
              value: "1",
              label: "Deployment",
            },
            {
              value: "2",
              label: "List Position",
            },
          ]?.map((each, i) => (
            <div
              key={i}
              className={Styles.step}
              onClick={() => setActiveStep(each?.value)}
            >
              {i === 0 && <div className={Styles.border} />}
              <div>
                <span
                  className={activeStep === each?.value ? Styles.active : ""}
                >
                  {each?.value}
                </span>
              </div>

              <span className={activeStep === each?.value ? Styles.active : ""}>
                {each?.label}
              </span>
            </div>
          ))}
        </div>
        <div className={Styles.content}>
          {mainContent}

          <div className={Styles.cActions}>
            <button
              disabled={activeStep === "1"}
              onClick={() => setActiveStep("1")}
            >
              <Icon icon={"arrow-left"} size={20} />
              <span>Back</span>
            </button>
            <button
              disabled={activeStep === "2"}
              onClick={() => setActiveStep("2")}
            >
              <span>Next Step</span>
              <Icon icon={"arrow-right"} size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DelegationPerLocation = ({
  delegationData,
  setDelegationData = () => {},
  alertMessage,
  onDelete = () => {},
  roList = [],
}) => {
  const [bmSearch, setBmSearch] = useState("");
  const [amSearch, setAmSearch] = useState("");
  const [aoSearch, setAoSearch] = useState("");
  const [roSearch, setRoSearch] = useState("");

  const [showBm, setShowBm] = useState(false);
  const [showAm, setShowAm] = useState(false);
  const [showAo, setShowAo] = useState(false);
  const [showRo, setShowRo] = useState(false);

  const bmRef = useRef();
  const amRef = useRef();
  const aoRef = useRef();
  const roRef = useRef();

  const bmOutsideClick = UseOutsideClick(bmRef);
  const amOutsideClick = UseOutsideClick(amRef);
  const aoOutsideClick = UseOutsideClick(aoRef);
  const roOutsideClick = UseOutsideClick(roRef);

  useEffect(() => {
    if (bmOutsideClick) {
      setBmSearch("");
      setShowBm(false);
    }
  }, [bmOutsideClick]);
  useEffect(() => {
    if (amOutsideClick) {
      setAmSearch("");
      setShowAm(false);
    }
  }, [amOutsideClick]);
  useEffect(() => {
    if (aoOutsideClick) {
      setAoSearch("");
      setShowAo(false);
    }
  }, [aoOutsideClick]);
  useEffect(() => {
    if (roOutsideClick) {
      setRoSearch("");
      setShowRo(false);
    }
  }, [roOutsideClick]);

  const searchTemplate = useMemo(() => {
    return [
      [bmSearch, setBmSearch, bmRef, showBm, setShowBm],
      [amSearch, setAmSearch, amRef, showAm, setShowAm],
      [aoSearch, setAoSearch, aoRef, showAo, setShowAo],
      [roSearch, setRoSearch, roRef, showRo, setShowRo],
    ];
  }, [amSearch, aoSearch, bmSearch, roSearch, showAm, showAo, showBm, showRo]);

  const onSelect = (user, role) => {
    const newData = {
      ...delegationData,
      deployments: delegationData?.deployments?.map((obj) => {
        if (obj?.role === role) {
          return {
            ...obj,
            delegations: obj?.delegations?.map((u) => {
              if (u?.id === user?.id) {
                return {
                  ...u,
                  isPicked: role === "BUSINESS MANAGER" ? true : !u?.isPicked,
                };
              } else {
                return {
                  ...u,
                  isPicked: role === "BUSINESS MANAGER" ? false : u?.isPicked,
                };
              }
            }),
          };
        } else {
          return obj;
        }
      }),
    };

    if (role === "RECRUITMENT OFFICER") {
      if (!user?.delegationID) {
        setDelegationData(newData);
      }
    } else {
      setDelegationData(newData);
    }
    if (role === "BUSINESS MANAGER") {
      setShowBm(false);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const onSetPIC = (user, role) => {
    const newData = {
      ...delegationData,
      deployments: delegationData?.deployments?.map((obj) => {
        if (obj?.role === role) {
          return {
            ...obj,
            delegations: obj?.delegations?.map((u) => {
              if (u?.id === user?.id) {
                return {
                  ...u,
                  isPIC: !u?.isPIC,
                };
              } else {
                return u;
              }
            }),
          };
        } else {
          return obj;
        }
      }),
    };

    setDelegationData(newData);
    if (role === "BUSINESS MANAGER") {
      setShowBm(false);
    }
  };

  return (
    <div className={Styles.delegationPerCity}>
      <div className={Styles.dPCHeader}>
        <Icon icon={"location"} size={24} color={"#1571DE"} />
        <span>{delegationData?.cityName}</span>
      </div>

      <div className={`${Styles.roles} ${Styles.grid}`}>
        {delegationData?.deployments?.map((d, dI) => (
          <div
            key={dI}
            // className={Styles.role}
            className={`${Styles.role} 
          ${
            alertMessage && !d?.delegations?.find((u) => u?.isPicked)
              ? Styles.error
              : ""
          }
          `}
          >
            <span>{d?.role}</span>
            <div ref={searchTemplate[dI][2]} className={Styles.rSearchWrapper}>
              <input
                value={searchTemplate[dI][0]}
                onChange={(e) => searchTemplate[dI][1](e?.target?.value)}
                placeholder="Search"
                onFocus={() => searchTemplate[dI][4](true)}
                // onBlur={() => searchTemplate[dI][4](false)}
              />
              <Icon
                icon={"search"}
                size={"24px"}
                className={Styles.rSearchIcon}
              />
              {searchTemplate[dI][3] && (
                <div className={Styles.rSearchOptions}>
                  {d?.delegations
                    ?.filter((obj) =>
                      obj?.name
                        ?.toLowerCase()
                        ?.includes(searchTemplate[dI][0]?.toLowerCase())
                    )
                    ?.sort((a, b) =>
                      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                    )
                    ?.map((u, uI) => (
                      <div key={uI} className={Styles.user}>
                        {d?.role !== "BUSINESS MANAGER" && (
                          <CheckboxJobPosting
                            checked={u?.isPicked}
                            onChange={() => onSelect(u, d?.role)}
                          />
                        )}

                        <span onClick={() => onSelect(u, d?.role)}>
                          {u?.name}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            {!!d?.delegations?.filter((obj) => obj?.isPicked)?.length && (
              <div className={Styles.delegatedWrapper}>
                {d?.delegations
                  ?.filter((obj) => obj?.isPicked)
                  ?.map((u, uI) => (
                    <div key={uI} className={Styles.delegated}>
                      <Avatar sx={{ width: "32px", height: "32px" }} />
                      <span>{u?.name}</span>
                      {/* <div className={Styles.dAction}>
                        {!d?.role
                          ?.toLowerCase()
                          ?.includes("business") && (
                            <div className={Styles.picSetter}>
                              <span>Set as PIC : </span>
                              <Switch
                                isChecked={u?.isPIC}
                                setIsChecked={() => onSetPIC(u, d?.role)}
                                width="36px"
                                height="20px"
                              />
                            </div>
                          )}
                        {
                          d?.role !== 'BUSINESS MANAGER'
                          &&
                          <button onClick={() => onSelect(u, d?.role)}>
                            <Icon icon={"cross-circle"} size={18} />
                          </button>

                        }
                      </div> */}

                      <div className={Styles.dAction}>
                        {d?.role !== "BUSINESS MANAGER" &&
                          (d?.role === "RECRUITMENT OFFICER" ? (
                            !u?.delegationID ? (
                              <button onClick={() => onSelect(u, d?.role)}>
                                <Icon icon={"cross-circle"} size={18} />
                              </button>
                            ) : (
                              <></>
                            )
                          ) : (
                            <button onClick={() => onSelect(u, d?.role)}>
                              <Icon icon={"cross-circle"} size={18} />
                            </button>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div></div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PerPosition = ({
  positionData,
  setPositionData,
  contractTypeOptions = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  selectedValueBPU = null,
  // roList = [],
  // positionsForm = [],
  // onDelete = () => { },
  selectedButtonBPJS = "",
}) => {
  const [expandDetails, setExpandDetails] = useState(
    positionData?.isExpanded || false
  );

  useEffect(() => {
    setPositionData("isExpanded", expandDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandDetails]);

  const template = useMemo(() => {
    return [
      // {
      //   title: "Recruitment Officer Dedicated",
      //   component: (
      //     <div
      //       className={`${Styles.roWrapper}
      //      `}
      //     >

      //       <span>Recruitment Officer Dedicated</span>

      //       <div className={Styles.name}>
      //         {positionData?.dedicatedROName || '-'}
      //       </div>
      //       <div className={Styles.notes}>
      //         <Icon icon={'information-solid'} size={20} color={'#1571DE'} />
      //         <p style={{color: !positionData?.dedicatedROName ?'#ff3e13' : ''}} className={Styles.nDesc}>
      //           RO <span className={Styles.italic}>dedicated</span> dipilih oleh BM <span className={`${Styles.italic}`}>Owner</span>, mohoh hubungi BM <span className={Styles.italic}>Owner</span> jika RO <span className={Styles.italic}>dedicated</span> tidak muncul
      //         </p>
      //       </div>
      //     </div>
      //   ),
      // },
      {
        title: 'Kota',
        component: (
          <div className={Styles.cities}>
            {positionData?.citiesData?.map((c, cI) => (
              <PerPositionPerCity
                key={cI}
                selectedButtonBPJS={selectedButtonBPJS}
                cityPositionData={c}
                setCityPositionData={(name, value) => {
                  setPositionData(
                    "citiesData",
                    positionData?.citiesData?.map((obj) => {
                      if (obj?.cityCode === c?.cityCode) {
                        return {
                          ...obj,
                          [name]: value,
                        };
                      }
                      return obj;
                    })
                  );
                }}
                contractTypeOptions={contractTypeOptions}
                personalEquipmentOptions={personalEquipmentOptions}
                allowanceOptions={allowanceOptions}
                qualificationOptions={qualificationOptions}
                selectedValueBPU={selectedValueBPU}
              />
            ))}
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allowanceOptions,
    contractTypeOptions,
    personalEquipmentOptions,
    positionData?.citiesData,
    qualificationOptions,
    selectedButtonBPJS,
    selectedValueBPU,
    setPositionData,
  ]);

  return (
    <div className={Styles.perPosition}>
      <div className={Styles.pHeader}>
        <div className={Styles.pHLogo}>
          <Icon icon={"briefcase-outlined"} color={"#1571DE"} size={20} />
        </div>
        <div className={Styles.pHName}>
          <span>{positionData?.positionName}</span>
        </div>

        <button
          onClick={() => setExpandDetails(!expandDetails)}
          className={Styles.expandButton}
        >
          <Icon icon={expandDetails ? "minus" : "add"} size={20} />
        </button>
      </div>
      {expandDetails && (
        <div className={Styles.pDetails}>
          {template?.map((t, tI) => (
            <div key={tI} className={Styles.pDEach}>
              {/* <div className={Styles.pDEHeader}>
                  <span>{t?.title}</span>
                  {t?.extraTitle ? t?.extraTitle : <></>}
                </div> */}
              {t?.component}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PerPositionPerCity = ({
  cityPositionData,
  setCityPositionData = () => {},
  contractTypeOptions = [],
  personalEquipmentOptions = [],
  allowanceOptions = [],
  qualificationOptions = [],
  selectedValueBPU = null,
  selectedButtonBPJS = "",
}) => {
  const template = useMemo(() => {
    return [
      {
        title: "Salary & Allowance Setiap Manpower",
        component: (
          <div className={`${Styles.salarySection} ${Styles.grid}`}>
            {/* <DropdownField
              title={"Contract Type"}
              value={cityPositionData?.contractType}
              // setValue={(newVal) =>
              //   handleChangeManpowerData("contractType", newVal)
              // }
              placeholder="Select contract type"
              options={contractTypeOptions?.map((obj) => {
                return {
                  name: obj,
                };
              })}
              autoClose
            /> */}
            <div className={Styles.salary}>
              <InputTitle title={"Salary"} />
              <div className={Styles.salaryFields}>
                <DecorativeInput
                  value={cityPositionData?.salaryValue}
                  setValue={(newVal) =>
                    setCityPositionData("salaryValue", newVal)
                  }
                  adornment="Rp"
                  type="currency"
                  placeholder="Salary"
                />
              </div>
            </div>
            <div className={Styles.salary}>
              {/* <InputTitle title={"Salary"} /> */}
              <span style={{ paddingBottom: "18px" }} />
              <Dropdown
                options={["Harian", "Mingguan", "Bulanan"]?.map((obj) => {
                  return {
                    name: obj,
                  };
                })}
                placeholder={"Harian/mingguan/bulanan"}
                value={cityPositionData?.salaryDenom}
                setValue={(newVal) =>
                  setCityPositionData("salaryDenom", newVal)
                }
                autoClose
              />
            </div>

            <div className={Styles.equipments}>
              <MultiSelectSmallLabel
                title="Personal Work Equipment"
                placeholder="Select"
                options={personalEquipmentOptions?.map((obj) => {
                  return {
                    // id: makeRandomString(5),
                    name: obj,
                    value: "",
                  };
                })}
                labels={cityPositionData?.personalEquipments}
                setLabels={(newLabels) =>
                  setCityPositionData("personalEquipments", newLabels)
                }
              />
              {!!cityPositionData?.personalEquipments?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {cityPositionData?.personalEquipments?.map((p, pI) => (
                    <DinamicDecorativeInputField
                      key={pI}
                      onChange={(_, newVal) =>
                        setCityPositionData(
                          "personalEquipments",
                          cityPositionData?.personalEquipments?.map((obj) => {
                            if (p?.name === obj?.name) {
                              return {
                                ...obj,
                                value: newVal,
                              };
                            }
                            return obj;
                          })
                        )
                      }
                      onDelete={() =>
                        setCityPositionData(
                          "personalEquipments",
                          cityPositionData?.personalEquipments?.filter(
                            (obj) => obj?.name !== p?.name
                          )
                        )
                      }
                      name={p?.name}
                      value={p?.value || ""}
                      title={p?.name}
                      adornment="Rp"
                      type="currency"
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={Styles.allowances}>
              <MultiSelectSmallLabel
                title="Allowance"
                placeholder="Select"
                options={
                  allowanceOptions?.length
                    ? allowanceOptions?.map((obj) => {
                        return {
                          // id: makeRandomString(5),
                          name: obj,
                          value: "",
                        };
                      })
                    : []
                }
                preventDeleteIndicator={"disableDelete"}
                labels={cityPositionData?.allowances?.filter(
                  (obj) => obj?.type !== "BPU"
                )}
                setLabels={(newLabels) =>
                  setCityPositionData("allowances", newLabels)
                }
              />
              {!!cityPositionData?.allowances?.length && (
                <div className={Styles.multifieldsWrapper}>
                  {cityPositionData?.allowances?.map((p, pI) => (
                    <DinamicDecorativeInputField
                      key={pI}
                      onChange={(_, newVal) =>
                        setCityPositionData(
                          "allowances",
                          cityPositionData?.allowances?.map((obj) => {
                            if (p?.name === obj?.name) {
                              return {
                                ...obj,
                                value: newVal,
                              };
                            }
                            return obj;
                          })
                        )
                      }
                      onDelete={() =>
                        setCityPositionData(
                          "allowances",
                          cityPositionData?.allowances?.filter(
                            (obj) => obj?.name !== p?.name
                          )
                        )
                      }
                      name={p?.name}
                      value={p?.value || ""}
                      title={p?.name}
                      adornment="Rp"
                      type="currency"
                      preventDelete={p?.disableDelete}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "BPJS (BPU)",
        extraTitle: <div className={Styles.valueBPU}>{selectedValueBPU}</div>,
        component: (
          <div className={`${Styles.bpuSection} ${Styles.grid}`}>
            <DinamicDecorativeInputField
              title={"JKK (Jaminan Kelengkapan Kerja)"}
              value={cityPositionData?.jkk}
              onChange={(_, newVal) => {
                setCityPositionData("jkk", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
            <DinamicDecorativeInputField
              title={"JKM (Jaminan Kematian)"}
              value={cityPositionData?.jkm}
              onChange={(_, newVal) => {
                setCityPositionData("jkm", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
            <DinamicDecorativeInputField
              title={"JHT (Jaminan Hari Tua)"}
              value={cityPositionData?.jht}
              onChange={(_, newVal) => {
                setCityPositionData("jht", newVal);
              }}
              adornment="Rp"
              type="currency"
              preventDelete
            />
          </div>
        ),
      },
      {
        title: "Expected Date",
        component: (
          <InputDateField
            title={"Expected Fulfillment Date"}
            value={cityPositionData?.expectedDate}
            setValue={(newVal) => setCityPositionData("expectedDate", newVal)}
            placeholder={"Select date"}
          />
        ),
      },
    ];
  }, [
    allowanceOptions,
    cityPositionData?.allowances,
    cityPositionData?.expectedDate,
    cityPositionData?.jht,
    cityPositionData?.jkk,
    cityPositionData?.jkm,
    cityPositionData?.personalEquipments,
    cityPositionData?.salaryDenom,
    cityPositionData?.salaryValue,
    personalEquipmentOptions,
    selectedValueBPU,
    setCityPositionData,
  ]);

  return (
    <div
      className={`${Styles.perPositionPerCity} ${
        cityPositionData?.isApplied ? Styles.isApplied : ""
      }`}
    >
      <div className={Styles.pCHeader}>
        <Icon icon={"company-outline"} size={20} />
        <span>{cityPositionData?.cityName}</span>
        {/* <Switch
          isChecked={cityPositionData?.isApplied}
          setIsChecked={(newVal) => setCityPositionData('isApplied', newVal)}
          width="36px"
          height="20px"
        /> */}
      </div>
      {cityPositionData?.isApplied && (
        <div className={Styles.pCDetails}>
          <div className={Styles.pCDHeader}>
            <span>Fulfillment Amount</span>
            <Counter
              value={cityPositionData?.amount}
              onSubtract={() => {
                if (+cityPositionData?.amount > 1) {
                  setCityPositionData("amount", +cityPositionData?.amount - 1);
                }
              }}
              onAdd={() => {
                setCityPositionData("amount", +cityPositionData?.amount + 1);
              }}
            />
          </div>
          <div className={Styles.pCDMain}>
            {selectedButtonBPJS?.toUpperCase()?.includes("BPU")
              ? template?.map((t, tI) => (
                  <div key={tI} className={Styles.pCDMEach}>
                    <div className={Styles.pCDMEHeader}>
                      <span>{t?.title}</span>
                      {t?.extraTitle || <></>}
                    </div>
                    {t?.component}
                  </div>
                ))
              : template
                  ?.filter((obj) => !obj?.title?.includes("BPJS"))
                  ?.map((t, tI) => (
                    <div key={tI} className={Styles.pCDMEach}>
                      <div className={Styles.pCDMEHeader}>
                        <span>{t?.title}</span>
                        {t?.extraTitle || <></>}
                      </div>
                      {t?.component}
                    </div>
                  ))}
          </div>
        </div>
      )}
    </div>
  );
};
