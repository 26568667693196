import AutocompleteV2 from '@Atom/AutocompleteV2'
import Icon from '@Atom/Icon'
import { verifyCandidate } from '@Services/support/candidate'
import { fetchPKSList, injectExtendToIOS, injectToIOS } from '@Services/support/injectIOS'
import Images from '@Theme/Images'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Styles from './style.module.scss'
import { makeRandomString } from '@Helpers/makeRandomString'

export default function SecondVerificationModal({ customID = '', data, handleClose = () => { }, setTrigger = () => { }, type = '' }) {
  const { id } = useParams()

  const [pksList, setPksList] = useState([])
  const [isLoadingPksList, setIsLoadingPksList] = useState(false)
  const [verifyError, setVerifyError] = useState('')
  const [injectError, setInjectError] = useState('')

  const fetchList = useCallback(async () => {
    try {
      setIsLoadingPksList(true)
      const { response } = await fetchPKSList(customID || id)
      setPksList(response?.pksList)
      setIsLoadingPksList(false)
    } catch (err) {
      setIsLoadingPksList(false)
      console.log(err, 'error on fetching PKS list')
    }
  }, [customID, id])

  const [pks, setPks] = useState('')

  const [selectedPks, setSelectedPks] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [verificationMode, setVerificationMode] = useState(true)

  const [isLoadingVerify, setIsLoadingVerify] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState(data?.status === 'BS-CONTRACT' ? 'succeeded' : '')

  useEffect(() => {
    setVerificationStatus(data?.status === 'BS-CONTRACT' ? 'succeeded' : '')
  }, [data?.status])

  const [isLoadingInject, setIsLoadingInject] = useState(false)
  const [injectStatus, setInjectStatus] = useState(data?.stage === 'IOS-SUCCESS' ? 'succeeded' : '')

  useEffect(() => {
    setInjectStatus(data?.stage === 'IOS-SUCCESS' ? 'succeeded' : '')
  }, [data?.stage])

  useEffect(()=> {
    setTrigger(makeRandomString(5))
  }, [verificationStatus, injectStatus, setTrigger])


  useEffect(() => {
    if (verificationStatus === 'succeeded') {
      fetchList()
    }
  }, [fetchList, verificationStatus])

  const verify = useCallback(async () => {
    try {
      setIsLoadingVerify(true)
      await verifyCandidate(
        "Accepted",
        "SECOND-VERIFICATION",
        customID || id,
        '',
        "VERIFICATION"
      );
      setTrigger(makeRandomString(5))
      setIsLoadingVerify(false)
      setVerificationStatus('succeeded')
    } catch (err) {
      setIsLoadingVerify(false)
      setVerifyError(err?.response?.data?.error)
      console.log(err, 'err on second verification')
      setVerificationStatus('failed')
    }
  }, [customID, id, setTrigger])

  const inject = useCallback(async () => {
    try {
      setIsLoadingInject(true)
      if (type === 'EXTEND'){
        await injectExtendToIOS(
          customID || id,
          selectedPks?.pks_id,
          selectedPks?.pks_alias
        )
      } else {
        await injectToIOS(
          customID || id,
          selectedPks?.pks_id,
          selectedPks?.pks_alias
        );
      }
      setTrigger(makeRandomString(5))
      setIsLoadingInject(false)
      setInjectStatus('succeeded')
    } catch (err) {
      setIsLoadingInject(false)
      setInjectError(err?.response?.data?.error)
      console.log(err?.response?.data?.error || err, 'err on inject')
      setInjectStatus('failed')
    }
  }, [customID, id, selectedPks?.pks_alias, selectedPks?.pks_id, setTrigger, type])

  useEffect(() => {
    if (verificationStatus !== 'succeeded') {
      verify()
    }
  }, [verificationStatus, verify])

  useEffect(() => {
    if (selectedPks && !injectStatus && !isLoadingInject) {
      inject()
    }
  }, [inject, injectStatus, isLoadingInject, selectedPks])



  const template = useMemo(() => {
    switch (verificationMode) {
      case false:
        return {
          title: 'PKS Alias',
          desc: 'Mohon tunggu ntuk memasukan informasi PKS Alias untuk memproses kontrak kandidat',
          content:
            <div className={Styles.selectPKS}>
              <div className={Styles.autocompleteWrapper}>
                <AutocompleteV2
                  isLoading={isLoadingPksList}
                  placeholder={'Pilih PKS alias'}
                  options={[]}
                />
              </div>
              <button>
                Proses
              </button>
            </div>
        }
      case true:
        return {
          title: type === 'EXTEND' ? 'Proses Perpanjangan Kontrak Sedang Berlangsung' : 'Proses Verifikasi Ke-2 Sedang Berlangsung',
          desc: type === 'EXTEND' ? 'Proses Perpanjangan Kontrak Sedang Berlangsung' : 'Proses Verifikasi Ke-2 Sedang Berlangsung',
          content:
            <div className={Styles.processWrapper}>
              <div className={Styles.processes}>
                <Step
                  step={'1'}
                  text={type === 'EXTEND' ? 'Verifikasi Perpanjangan Kontrak' : 'Verifikasi Tahap ke-2'}
                  isLoading={isLoadingVerify}
                  isFailed={verificationStatus === 'failed'}
                  isSuccess={verificationStatus === 'succeeded'}
                  failedText={verifyError || 'Verifikasi gagal. Silahkan coba lagi'}
                />
                <div className={Styles.separator} />
                <Step
                  step={'2'}
                  isSuccess={selectedPks}
                  customElement={
                    <div className={Styles.choosePksStep}>
                      <div className={Styles.chooserWrapper}>
                        <span className={verificationStatus !== 'succeeded' ? Styles.grey : ''}>PKS Alias</span>
                        <AutocompleteV2
                          value={pks}
                          setValue={setPks}
                          isLoading={isLoadingPksList}
                          placeholder={'Pilih PKS alias'}
                          options={pksList?.map(obj => {
                            return {
                              name: obj?.pks_alias
                            }
                          })}
                          disabled={verificationStatus !== 'succeeded'}
                        />
                      </div>

                      <div className={Styles.saveButtonWrapper}>
                        <button
                          onClick={() => setSelectedPks(pksList?.find(obj => obj?.pks_alias === pks))}
                          disabled={verificationStatus !== 'succeeded' || !pks || isLoadingInject || injectStatus === 'succeeded'}
                        >Simpan</button>
                      </div>
                    </div>
                  }
                />
                <div className={Styles.separator} />
                <Step
                  step={'3'}
                  text={'Inject Kedalam Sistem'}
                  isLoading={isLoadingInject}
                  isFailed={injectStatus === 'failed'}
                  isSuccess={injectStatus === 'succeeded'}
                  failedText={injectError || 'Inject gagal silahkan coba lagi'}
                />
              </div>
              <div className={Styles.actions}>
                <button
                  disabled={isLoadingVerify || isLoadingInject}
                  onClick={handleClose}
                >{injectStatus === 'succeeded' ? 'Selesai' : 'Kembali'}</button>
                {
                  ((verificationStatus === 'failed' || injectStatus === 'failed') && !isLoadingInject && !isLoadingVerify)
                  &&
                  <button 
                  // disabled={isLoadingInject || isLoadingVerify}
                  onClick={(e) => {
                    e.preventDefault()
                    verificationStatus === 'failed' ? verify() : inject()
                  }}>Coba Lagi</button>
                }
              </div>

            </div>
        }
      default:
        return null
    }
  }, [verificationMode, isLoadingPksList, isLoadingVerify, verificationStatus, verifyError, selectedPks, pks, pksList, isLoadingInject, injectStatus, injectError, handleClose, verify, inject, type])

  return (
    <div className={Styles.container}>
      <h3>{template?.title}</h3>
      <p>{template?.desc}</p>
      <div className={Styles.content}>
        {template?.content}
      </div>

    </div>
  )
}

const Step = ({
  step,
  text,
  isLoading,
  isFailed,
  isSuccess,
  failedText,
  customElement
}) => {
  return (
    <div className={Styles.step}>
      <div className={Styles.logo}>
        {
          !isLoading
            ?
            <>
              {
                !isSuccess
                  ?
                  (isFailed
                    ?
                    <div className={Styles.failed}><Icon icon={'cross'} size={20} /></div>
                    :
                    <div className={Styles.default}>{step}</div>
                  )
                  :
                  <div className={Styles.succeeded}><Icon icon={'check'} size={20} /></div>
              }
            </>
            :
            <img src={Images.LOADER} alt='' />
        }
      </div>
      {
        !customElement
          ?
          <div className={Styles.text}>
            <p className={`${isLoading || isFailed ? Styles.black : ''} ${isSuccess ? Styles.blue : ''}`}>{text}</p>
            {!!isFailed && <span>{failedText}</span>}
          </div>
          :
          customElement
      }

    </div>
  )
}