import Styles from "./style.module.scss";
import Input from "@Atom/Input";
import Textarea from "@Atom/Textarea";
import UploadBox from "@Atom/UploadBox";

const VideoForm = () => {
  return (
    <div className={Styles.fieldWrapper}>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Judul</div>
        <div className={Styles.input}>
          <Input placeholder="Tulis Judul" />
        </div>
      </div>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Deskripsi</div>
        <div className={Styles.input}>
          <Textarea placeholder="Tambahkan Deskripsi" />
        </div>
      </div>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Video</div>
        <div className={Styles.input}>
          <UploadBox type="light" isPdf={false} />
        </div>
      </div>
    </div>
  );
};

export default VideoForm;
