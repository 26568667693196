/* eslint-disable no-unused-vars */
import RecruitmentClientList from "@Organism/Recruiter/Recruitment/RecruitmentClientList";
import { getOrderList } from "@Services/officer/recruitment";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { useDebounce } from "@Hooks/useDebounce";

export const loader = async (route) => {
  try {
    const url = new URL(route?.request?.url);
    const limit = url.searchParams.get("l") || 5;
    const page = url.searchParams.get("p") || 1;
    const search = url.searchParams.get("n") || "";
    const sort = url.searchParams.get("s") || "";
  
    const clientResponse = getOrderList(`limit=${limit}&page=${page}&search=${search}&sort=${sort}`);

    return defer({
      client: clientResponse,
    });
  } catch (err) {
    console.error(err);
  }
};

export default function RecruitmentList() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { client } = useLoaderData()
  const [searchClient, setSearchClient] = useState("");
  const [valueClient, setValueClient] = useState(5);
  const [pagesClient, setPagesClient] = useState(`1-${valueClient}`);
  const [pageListClient, setPageListClient] = useState([]);
  const [selectedPageClient, setSelectedPageClient] = useState(1);
  const [sortByClient, setSortByClient] = useState({
    name: "newest",
    label: "ascending",
  });

  const debouncedSearch = useDebounce(searchClient, 300)

  const clientQuery = useMemo(() => {
    return `l=${valueClient}&p=${selectedPageClient}&s=${sortByClient?.value || ''}&n=${debouncedSearch}`;
  }, [valueClient, selectedPageClient, sortByClient?.value, debouncedSearch]);

  useEffect(() => {
    navigate(`${pathname}?${clientQuery}`)
  }, [clientQuery, navigate, pathname])


  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Recruitment List</title>
      </Helmet>
      <div className={Styles.headTitle}>
        <h1>Rekrutmen</h1>
      </div>
      <Suspense
        fallback={
          <RecruitmentClientList
            search={searchClient}
            setSearch={setSearchClient}
            value={valueClient}
            setValue={setValueClient}
            pages={pagesClient}
            setPages={setPagesClient}
            pageList={pageListClient}
            setPageList={setPageListClient}
            selectedPage={selectedPageClient}
            setSelectedPage={setSelectedPageClient}
            data={{
              data: [],
              totalPage: 0
            }}
            sort={sortByClient}
            setSort={setSortByClient}
            sortOption={sortOption}
            isLoading
          />
        }
      >
        <Await
          resolve={client}
          errorElement={
            <div className={Styles.errorWrapper}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(client) => (
            <RecruitmentClientList
              search={searchClient}
              setSearch={setSearchClient}
              value={valueClient}
              setValue={setValueClient}
              pages={pagesClient}
              setPages={setPagesClient}
              pageList={pageListClient}
              setPageList={setPageListClient}
              selectedPage={selectedPageClient}
              setSelectedPage={setSelectedPageClient}
              data={client?.response}
              sort={sortByClient}
              setSort={setSortByClient}
              sortOption={sortOption}
            />
          )}

        </Await>
      </Suspense>
    </div>
  );
}

const sortOption = [
  {
    name: "Newest",
    label: "ascending",
    value: "",
  },
  {
    name: "Oldest",
    label: "descending",
    value: "oldest",
  },
  {
    name: "A - Z",
    label: "alphabet-ascending",
    value: "A-Z",
  },
  {
    name: "Z - A",
    label: "alphabet-descending",
    value: "Z-A",
  },
];