import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import BMListLayout from "@Organism/Manager/BM/BMList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getBMList } from "@Services/manager/bm";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const limit = url.searchParams.get("l") || 10;
  const page = url.searchParams.get("p") || 1;
  const search = url.searchParams.get("n") || "";

  return defer({
    packageBM: getBMList(page, limit, search),
  });
}

export default function BMListPage() {
  const { packageBM } = useLoaderData()
  const { pathname } = useLocation();
  const navigate = useNavigate()

  const query = useQuery();
  const name = query.get("n");



  const [value, setValue] = useState(query.get('l') || 10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(query.get('p') || 1);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300)


  useEffect(() => {
    navigate(
      {
        pathname: pathname,
        search: `?p=${selectedPage}&l=${value}&n=${debouncedSearchTerm}`,
      },
      { replace: true }
    );
  }, [selectedPage, value, pathname, navigate, debouncedSearchTerm]);

  useEffect(() => {
    if (name) {
      setSearchTerm(name);
    }
  }, [name]);

  const loadingParams = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return searchTerm != name || query?.get('p') != selectedPage || query?.get('l') != value
  }, [name, query, searchTerm, selectedPage, value])


  return (
    <Suspense
      fallback={<BMListLayout
        isLoading
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        value={value}
        setValue={setValue}
        pages={pages}
        setPages={setPages}
        pageList={pageList}
        setPageList={setPageList}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />}
    >
      <Await
        resolve={packageBM}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageBM) => (
          <BMListLayout
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            data={packageBM?.response?.data}
            totalPage={packageBM?.response?.totalPage}
            value={value}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            isLoading={loadingParams}
          />
        )}

      </Await>

    </Suspense>
  )
}