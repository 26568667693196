import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useState } from "react";
import {
  absentCandidate,
  resendInvitationNew,
  revertCandidate,
} from "@Services/officer/recruitment";

export default function RevertModal({
  data,
  handleClose,
  setSuccessBackStep,
  setShowStepBack,
  refetchDataCandidate,
  isAbsent,
  isResend,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      await revertCandidate({
        candidateID: data?.data?.candidateId,
        status: data?.name,
      });
      setIsSubmitting(false);
      setSuccessBackStep(true);
      setTimeout(() => {
        setSuccessBackStep(false);
      }, 2000);
      setShowStepBack({
        status: "DONE",
        name: data?.name,
        step: data?.step,
        data: data?.data,
      });

      //   setSelectedPage(1);
      //   handleClose();
      refetchDataCandidate();
    } catch (err) {
      setIsSubmitting(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setIsError("Check inputted data and make sure to fill all the fields");
      } else {
        setIsError(errorText);
      }
    }
  };

  const [isSubmittingAbsent, setIsSubmittingAbsent] = useState(false);
  // const [isSent, setIsSent] = useState(false);
  const [isErrorAbsent, setIsErrorAbsent] = useState(false);

  const handleSubmitAbsent = async (e) => {
    try {
      e.preventDefault();
      setIsSubmittingAbsent(true);
      await absentCandidate(data?.data?.candidateId);
      setIsSubmittingAbsent(false);
      setSuccessBackStep(true);
      setTimeout(() => {
        setSuccessBackStep(false);
      }, 2000);
      refetchDataCandidate();
      setShowStepBack({
        status: "DONE-ABSENT",
        name: data?.name,
        step: data?.step,
        data: data?.data,
      });
    } catch (err) {
      setIsSubmittingAbsent(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setIsErrorAbsent(
          "Check inputted data and make sure to fill all the fields"
        );
      } else {
        setIsErrorAbsent(errorText);
      }
    }
  };

  const [isSubmittingResend, setIsSubmittingResend] = useState(false);
  // const [isSent, setIsSent] = useState(false);
  const [isErrorResend, setIsErrorResend] = useState(false);

  const handleSubmitResend = async (e) => {
    try {
      e.preventDefault();
      setIsSubmittingResend(true);
      await resendInvitationNew({
        candidateID: data?.data?.candidateId,
        ordinal: data?.step,
      });
      setIsSubmittingResend(false);
      setSuccessBackStep(true);
      setTimeout(() => {
        setSuccessBackStep(false);
      }, 2000);
      handleClose();
      refetchDataCandidate();
    } catch (err) {
      setIsSubmittingResend(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setIsErrorResend(
          "Check inputted data and make sure to fill all the fields"
        );
      } else {
        setIsErrorResend(errorText);
      }
    }
  };

  if (isAbsent) {
    return (
      <div className={Styles.container}>
        <div>
          <Icon
            icon={"Question-Mark-Yellow"}
            size={60}
            className={Styles.icon}
            color={"#EBD700"}
          />
          <div>
            <span>Tandai Kandidat Tidak Hadir</span>
            <span>
              Apakah anda yakin <span>{data?.data?.name || "-"}</span> dengan
              posisi <span>{data?.data?.position || "-"}</span> tidak hadir
              dalam proses interview RO?
            </span>
          </div>
        </div>

        <div>
          {isErrorAbsent && <span>*{isErrorAbsent}</span>}
          <div>
            <button onClick={handleClose}>Batal</button>
            <button
              disabled={isSubmittingAbsent}
              className={`${isSubmittingAbsent && Styles.disabled}`}
              onClick={isSubmittingAbsent ? null : handleSubmitAbsent}
            >
              {isSubmittingAbsent ? "Loading" : "Ya, Simpan"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isResend) {
    return (
      <div className={Styles.container}>
        <div>
          <Icon
            icon={"Question-Mark-Yellow"}
            size={60}
            className={Styles.icon}
            color={"#EBD700"}
          />
          <div>
            <span>Kirim Ulang Undangan Interview</span>
            <span>
              Apakah anda yakin untuk mengirim ulang undangan ke{" "}
              <span>{data?.data?.name || "-"}</span> dengan posisi{" "}
              <span>{data?.data?.position || "-"}</span> dalam proses{" "}
              {data?.name}?
            </span>
          </div>
        </div>

        <div>
          {isErrorResend && <span>*{isErrorResend}</span>}
          <div>
            <button onClick={handleClose}>Batal</button>
            <button
              disabled={isSubmittingResend}
              className={`${isSubmittingResend && Styles.disabled}`}
              onClick={isSubmittingResend ? null : handleSubmitResend}
            >
              {isSubmittingResend ? "Loading" : "Ya, Simpan"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div>
        <Icon
          icon={"Question-Mark-Yellow"}
          size={60}
          className={Styles.icon}
          color={"#EBD700"}
        />
        <div>
          <span>Kembalikan kandidat ke Step {data?.step || 0}?</span>
          <span>
            Apakah anda yakin <span>{data?.data?.name || "-"}</span> dengan
            posisi <span>{data?.data?.position || "-"}</span> ingin dikembalikan
            ke Step {data?.step || 0}: {data?.name || "-"}?
          </span>
        </div>
      </div>

      <div>
        {isError && <span>*{isError}</span>}
        <div>
          <button onClick={handleClose}>Batal</button>
          <button
            disabled={isSubmitting}
            className={`${isSubmitting && Styles.disabled}`}
            onClick={isSubmitting ? null : handleSubmit}
          >
            {isSubmitting ? "Loading" : "Ya, Simpan"}
          </button>
        </div>
      </div>
    </div>
  );
}
