import Button from '@Atom/Button'
import Styles from './style.module.scss'
import locationDefault from '@Assets/Images/time-management.png'
import Icon from '@Atom/Icon'

export default function DefaultLocation(props) {
  const { setShowAdd, setShowAddMobile } = props;

  return (
    <div className={Styles.container}>
      <img src={locationDefault} alt="default" />
      <div className={Styles.desc}>
        <h3>Belum Ada Data yang Dimasukan</h3>
        <p>Tambah lokasi yang diinginkan dan masukan pekerjaan yang dibutuhkan</p>
      </div>
      <div className={`${Styles.buttonWrapper} ${Styles.desktop}`} onClick={() => setShowAdd(true)}>
        <Button type="primary" title={<><Icon icon="add" size={20} />Location</>} />
      </div>
      <div className={`${Styles.buttonWrapper} ${Styles.mobile}`} onClick={() => setShowAddMobile(true)}>
        <Button type="primary" title={<><Icon icon="add" size={20} />Location</>} />
      </div>
    </div>
  )
}