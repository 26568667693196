import LoadingAnimation from "@Atom/LoadingAnimation";
import JobPostingListContent from "@Organism/Recruiter/JobPosting/JobPostingList/Content";
import JobPostingListHeader from "@Organism/Recruiter/JobPosting/JobPostingList/Header";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import { getJobPostingList } from "@Services/officer/jobPosting";
import moment from "moment";
import { Suspense, useState } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import { Helmet } from "react-helmet-async";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const query = url.searchParams;
  // const limit = query.get("l") || 15;
  const page = query.get("p") || 1;
  const search = query.get("s") || "";
  const sort = query.get("sr") || "";
  const status = query.get("st") || "";
  const dateFrom = query.get("df")
    ? moment(new Date(query.get("df")))?.format("YYYY-MM-DD")
    : "";
  const dateTo = query.get("dt")
    ? moment(new Date(query.get("dt")))?.format("YYYY-MM-DD")
    : "";

  const packageJobPostingListPromise = getJobPostingList(
    16,
    page,
    sort,
    dateFrom,
    dateTo,
    status,
    search
  );

  return defer({
    packageJobPostingList: packageJobPostingListPromise,
  });
}

export default function JobPostingListPage() {
  const { packageJobPostingList } = useLoaderData();
  const underMaintenance = false;
  const [trigger, setTrigger] = useState("");
  const [showAction, setShowAction] = useState("");

  if (underMaintenance) {
    return <UnderMaintenacePage />;
  }

  return (
    <Suspense
      fallback={
        <div className={Styles.container}>
          <Helmet>
            <title>Kerja 365 | Job Posting List</title>
          </Helmet>

          <div className={Styles.headerWrapper}>
            <JobPostingListHeader
              data={null}
              trigger={trigger}
              setShowAction={setShowAction}
              showAction={showAction}
            />
            {/* <SearchContent data={null} /> */}
          </div>
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        </div>
      }
    >
      <Await
        resolve={packageJobPostingList}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageJobPostingList) => (
          <div className={Styles.container}>
            <Helmet>
              <title>Kerja 365 | Job Posting List</title>
            </Helmet>
            <div className={Styles.headerWrapper}>
              <JobPostingListHeader
                data={packageJobPostingList?.response}
                trigger={trigger}
                setShowAction={setShowAction}
                showAction={showAction}
              />
              {/* <SearchContent data={packageJobPostingList?.response} /> */}
            </div>

            <div className={Styles.content}>
              <JobPostingListContent
                data={packageJobPostingList?.response}
                setTrigger={setTrigger}
                setShowAction={setShowAction}
                showAction={showAction}
              />
            </div>
          </div>
        )}
      </Await>
    </Suspense>
  );
}
