import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import useQuery from '@Hooks/useQuery'
import { useMemo } from 'react'

export default function PipelineOrderContent({ data }) {
  const { pathname } = useLocation()
  const query = useQuery()
  const name = query.get('n')
  const code = query.get('c')
  const navigate = useNavigate()

  const handleSeeDetail = (id) => {
    navigate(`${pathname}/details?n=${name}&c=${code}&i=${id}`);
  };

  const schemes = useMemo(() => {
    const arr = []
    const orderData = data?.orders
    const sortedData = orderData?.sort((a, b) => {
      return new Date(b?.createdAt) - new Date(a?.createdAt)
    })

    for (let i = 0; i < sortedData.length; i++) {
      const data = sortedData[i]
      const cities = []
      const jobs = []

      for (let j = 0; j < data?.manPowers?.length; j++) {
        const manPower = data?.manPowers[j]
        if (!cities?.includes(manPower?.City?.name)) {
          cities?.push(manPower?.City?.name)
        }
        if (!jobs?.includes(manPower?.name)) {
          jobs?.push(manPower?.name)
        }
      }

      const each = {
        id: data?.id,
        type: data?.contractType?.toLowerCase() === 'jasa pemborongan' ? 'PKM' : 'PJTK',
        locations: cities,
        jobs: jobs,
        date: moment(new Date(data?.createdAt)).format("LL")
      }

      arr.push(each)

    }
    return arr
  }, [data])

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.header}>
          <h3>New Order</h3>
          <p>
            Berikut informasi klien baru, assign klien untuk proses selanjutnya
          </p>
        </div>
        <div className={Styles.title}>
          <div className={Styles.logo}>
            <Icon icon="company" size={30} />
          </div>
          <h4>{data?.name}</h4>
        </div>
        <div className={Styles.profileCard}>
          <div className={Styles.stacked}>
            <span>Kode perusahaan</span>
            <span>{data?.code}</span>
          </div>
          <div className={Styles.stacked}>
            <span>NPWP</span>
            <span>{data?.taxNumber}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Provinsi</span>
            <span>{data?.province}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Alamat </span>
            <span>{data?.address}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Kode Pos </span>
            <span>{data?.postal}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Nomor Telpon </span>
            <span>{data?.phoneNumber}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Website </span>
            <span>{data?.website}</span>
          </div>
        </div>
        {
          schemes?.map((each, i) => {
            return (
              <div key={i} className={Styles.orderCard}>
                <div className={Styles.topSection}>
                  <div>
                    <Icon icon="work-scheme" size={22} />
                  </div>
                  <div className={Styles.middle}>
                    <h5>{each?.jobs?.slice(0, 3)?.join(" • ")}{each?.jobs?.length > 3 && ` +${each?.jobs?.length - 3} more`}</h5>
                    <span>{each?.type}</span>
                    <div>
                      <Icon icon="location" size={15} />
                      <span>{each?.locations?.join(", ")}</span>
                    </div>
                    <span>{each?.date}</span>
                  </div>
                </div>

                <button onClick={() => handleSeeDetail(each?.id)}>Lihat Detail Order</button>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}
