/* eslint-disable no-unused-vars */
import PICTURE_DEFAULT from "@Assets/Images/picture-default.png";
import Icon from "@Atom/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./styles.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import ErrorModal from "@Molecule/_modal/ErrorModal";

export default function ImagesFiles({
  setFileImages,
  fileImages,
  setPreviewImages,
  previewImages,
  typeImages,
  setTypeImages,
  dataListClient,
  validate,
}) {
  console.log(validate);
  const { width } = useWindowSize();
  const [showImagesUpload, setShowImagesUpload] = useState(false);
  const [showerror, setShowError] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (fileImages) {
      const objectUrl = URL.createObjectURL(fileImages);
      setPreviewImages(objectUrl);

      // return () => URL.revokeObjectURL(objectUrl)
    }
  }, [fileImages, setPreviewImages]);

  useEffect(() => {
    if (previewImages) {
      setTypeImages("Poster");
    } else {
      setTypeImages("");
    }
  }, [previewImages, setTypeImages]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (width > 768) {
        if (acceptedFiles.length > 0) {
          setFileImages(acceptedFiles[0]);
        } else {
          setShowError(true);
          setError("Konten telah melebihi maximal size yang telah ditentukan");
          console.log("errr");
        }
      }
    },
    [setFileImages, width]
  );

  const onChange = (e) => {
    if (e?.target?.input?.files?.length > 0) {
      setFileImages(e?.target?.input?.files[0]);
    }
  };

  const onChangeResponsive = (e) => {
    const file = e.target.files[0];
    if (file?.size < 2097152) {
      setFileImages(file);

      // setShowError(false);
    } else {
      setShowError(true);
      setError("Konten telah melebihi maximal size yang telah ditentukan");
    }

    // console.log(e.target.files);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
    multiple: false,
    minSize: 0,
    maxSize: 2097152,
  });

  const [key, setKey] = useState(0);
  const handleFileDelete = () => {
    setPreviewImages(null);
    setFileImages(null);
    setTypeImages("");
    setKey((prevKey) => prevKey + 1);
    document.getElementById("fileInput").value = "";
  };

  const inputFileRef = useRef();
  return (
    <div className={`${Styles.leftBodyImages}`}>
      <div className={Styles.textTitle}>
        <span className={Styles.textTop}>
          Upload Poster Untuk Laman Website Kerja365 dan Sosmed <span>*</span>
        </span>
        <div className={Styles.warningBox}>
          <div className={Styles.boxWarn}>
            <Icon icon={"information-solid"} size={20} color={"#1571DE"} />
            <span className={Styles.textWarn}>
              Unggah gambar dengan rasio 9:16, minimal resolusi 1920 x 1080
              piksel dan minimal size gambar 2 MB
            </span>
          </div>
        </div>
      </div>

      {width <= 768 ? (
        <div
          className={`${Styles.uploadBox}`}
          onClick={() => inputFileRef?.current?.click()}
        >
          <input
            type="file"
            key={key}
            ref={inputFileRef}
            name="file"
            id="fileInput"
            onChange={onChangeResponsive}
            hidden
            accept=".png, .jpeg, .jpg"
          />
          {previewImages ? (
            <div className={Styles.imagesPreview}>
              <img src={previewImages} alt="" />
            </div>
          ) : (
            <div
              className={`${Styles.middleText} ${
                validate?.filesImages === true &&
                !fileImages &&
                Styles.redBorder
              }`}
            >
              <div className={Styles.imagesBoxUpload}>
                <img src={PICTURE_DEFAULT} alt="" />
              </div>

              <span className={Styles.dragAndDrop}>
                Pilih file Foto/Video ke sini untuk upload
              </span>
              <span className={Styles.p}>pilih file dengan</span>
              <span className={Styles.route}>Klik di sini</span>
            </div>
          )}
        </div>
      ) : (
        <div className={`${Styles.uploadBox}`} {...getRootProps()}>
          <input
            {...getInputProps()}
            type="file"
            name="file"
            hidden
            id="fileInput"
            key={key}
          />
          {previewImages ? (
            <div className={Styles.imagesPreview}>
              <img src={previewImages} alt="" />
            </div>
          ) : (
            <div
              className={`${Styles.middleText} ${
                validate?.filesImages === true &&
                !fileImages &&
                Styles.redBorder
              }`}
            >
              <div className={Styles.imagesBoxUpload}>
                <img src={PICTURE_DEFAULT} alt="" />
              </div>

              <span className={Styles.dragAndDrop}>
                Drag dan drop file Foto ke sini untuk upload
              </span>
              <span className={Styles.p}>atau pilih file dengan</span>
              <span
                className={Styles.route}
                // onClick={() => inputFileRef.current.click()}
              >
                Klik di sini
              </span>
            </div>
          )}
        </div>
      )}

      {previewImages ? (
        <div className={Styles.actionImages}>
          {width <= 768 ? (
            <div
              className={Styles.uploadAgain}
              onClick={() => inputFileRef?.current?.click()}
            >
              <input
                type="file"
                ref={inputFileRef}
                name="file"
                onChange={onChangeResponsive}
                hidden
                accept=".png, .jpeg, .jpg"
              />
              <Icon icon={"edit"} size={20} color={"#1571DE"} />
              <span className={Styles.routesEdit}>Ubah Konten</span>
            </div>
          ) : (
            <div className={Styles.uploadAgain} {...getRootProps()}>
              <input
                {...getInputProps()}
                type="file"
                // ref={inputFileRef}
                name="file"
                // onChange={onChange}
                hidden
              />
              <Icon icon={"edit"} size={20} color={"#1571DE"} />
              <span className={Styles.routesEdit}>Ubah Konten</span>
            </div>
          )}

          <div className={Styles.deleteFiles} onClick={handleFileDelete}>
            <Icon icon={"trash"} size={20} color={"#FF3E13"} />
            <span className={Styles.deleteText}>Hapus</span>
          </div>
        </div>
      ) : null}

      {/* {previewImages ? (
        <div
          className={Styles.dropDownImagesShow}
          onClick={() => {
            if (showImagesUpload === false) {
              setShowImagesUpload(true);
            } else {
              setShowImagesUpload(false);
            }
          }}
        >
          <div className={Styles.top}>
            <div className={Styles.leftContent}>
              <Icon icon={"photo"} size={24} color={"#0F172A"} />
              <span className={Styles.exempleText}>
                Contoh konten sosial media & jobseekers
              </span>
            </div>

            <Icon
              icon={showImagesUpload ? "arrow-up" : "arrow-down"}
              size={24}
              color={"#1571DE"}
              className={Styles.iconCross}
            />
          </div>
          {showImagesUpload && (
            <div className={Styles.dropdownContent}>
              <img src={previewImages} alt="" />
            </div>
          )}
        </div>
      ) : null} */}
      {showerror && (
        <ErrorModal
          error={error}
          onBack={() => setShowError(false)}
          handleDone={() => setShowError(false)}
        />
      )}
    </div>
  );
}
