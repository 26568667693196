import { Helmet } from "react-helmet-async";
import Styles from "./style.module.scss";
import ClientAssignment from "@Organism/Additional/NewClientAssignment";
import { getAssignData } from "@Services/manager/client";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader({ params }) {
  const hash = params.hash;
  const assignDataPromise = getAssignData(hash);

  return defer({
    packageAssignData: assignDataPromise,
  });
}

export default function ClientAssignmentSPA() {
  const { packageAssignData } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Clients</title>
      </Helmet>
      <Suspense
        fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageAssignData}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageAssignData) => (
            <ClientAssignment defaultData={packageAssignData?.response} />
          )}
        </Await>
      </Suspense>
    </div>
  );
}
