import CreateTipsAndTricks from "@Organism/CreateTipsAndTricks";

const CreateTipsAndTricksPage = () => {
  return (
    <>
      <CreateTipsAndTricks />
    </>
  );
};

export default CreateTipsAndTricksPage;
