import InputYear from "@Atom/InputYear";
import Styles from "./inputDateField.module.scss";
import { InputTitle } from "@Molecule/InputField";

const InputYearField = ({ value, setValue, title, required = false, placeholder, iconColor, disabled, isError = false,
  errorText = '',
  ...props }) => {
  return (
    <div className={Styles.container}>
      {/* <div className={Styles.title}>{title}</div> */}
      <InputTitle
        title={title}
        required={required}
      />
      <InputYear value={value} setValue={setValue} placeholder={placeholder} iconColor={iconColor} disabled={disabled} isError={isError} errorText={errorText} />
    </div>
  );
};

export default InputYearField;
