import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import InputYearField from "@Molecule/InputYearField";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function PerformanceData({ data, form, setForm, errorField }) {
  var index = useMemo(() => {
    return form.findIndex((x) => x?.id === data?.id);
  }, [data, form]);

  const [yearTemp, setYearTemp] = useState(form[index]?.year);

  useEffect(() => {
    setYearTemp(form[index]?.year);
  }, [form, index]);

  const handleChange = useCallback(
    (e, inputName) => {
      setForm([
        ...form?.slice(0, index),
        Object.assign({}, form[index], {
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        }),
        ...form?.slice(index + 1),
      ]);
    },
    [index, form, setForm]
  );

  useEffect(() => {
    setForm([
      ...form?.slice(0, index),
      Object.assign({}, form[index], {
        year: yearTemp,
      }),
      ...form?.slice(index + 1),
    ]);

    // eslint-disable-next-line
  }, [index, yearTemp]);

  const handleDelete = useCallback(() => {
    const newForm = [...form];
    newForm?.splice(index, 1);
    setForm(newForm);
  }, [form, setForm, index]);

  // year: null,
  // achievement: null,
  // remark: null

  return (
    <div className={Styles.container}>
      <h3>Data Prestasi {index + 1}</h3>
      <div className={Styles.content}>
        <InputYearField
          title={"Tahun"}
          placeholder={"Pilih tahun"}
          value={yearTemp}
          setValue={setYearTemp}
          iconColor={"#1571DE"}
          isError={errorField && !yearTemp}
          errorText={"Tahun belum diisi"}
        />
        <InputField
          title={"Prestasi"}
          placeholder={"Tulis nama prestasi"}
          value={data?.achievement}
          onChange={(e) => handleChange(e, "achievement")}
          isError={errorField && !data?.achievement}
          errorText={"Prestasi belum diisi"}
        />
      </div>
      <div className={Styles.descWrapper}>
        <InputField
          title={"Keterangan"}
          placeholder={"Keterangan prestasi"}
          type={"desc"}
          value={data?.remark}
          onChange={(e) => handleChange(e, "remark")}
          isError={errorField && !data?.remark}
          errorText={"Keterangan belum diisi"}
        />
      </div>
      <button onClick={handleDelete}>
        <Icon icon="delete" size={20} />
        <span>Hapus</span>
      </button>
    </div>
  );
}
