import Icon from "@Atom/Icon";
import NonLinearSlider from "@Atom/RangePriceSlider";
import { useEffect } from "react";
import Styles from "./styles.module.scss";

// const min = 300000;
// const max = 10000000;

export default function RangeSalary({
  isCheckedRange,
  minValue,
  setMinValue,
  maxValue,
  setMaxValue,
  data,
}) {
  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  const minPrice = formatNumber(minValue);
  const maxPrice = formatNumber(maxValue);

  useEffect(() => {
    if (data && data?.salary?.includes("-")) {
      if (isCheckedRange === true && data && data?.salary) {
        if (
          data?.salary?.split("-")[0] === "0" ||
          data?.salary?.split("-")[0] === 0
        ) {
          setMinValue(100000);
        } else if (
          data?.salary?.split("-")[0] === data?.salary?.split("-")[1]
        ) {
          setMinValue(100000);
        } else {
          setMinValue(data?.salary?.split("-")[0]);
        }
      }
    } else {
      setMinValue(100000);
    }
  }, [data, setMinValue, isCheckedRange]);

  const templatePrice = `${minPrice} - ${maxPrice}`;

  useEffect(() => {
    if (data && data?.salary?.includes("-")) {
      if (isCheckedRange === true && data && data?.salary) {
        setMaxValue(data?.salary?.split("-")[1]);
      }
    } else {
      setMaxValue(data?.salary || 25000000);
    }
  }, [data, setMaxValue, isCheckedRange]);

  return (
    <div>
      {!isCheckedRange ? (
        <div className={Styles.containerDisabled}>
          <span>Range Gaji</span>
          <div>
            <div className={Styles.rangeBox}>
              <span>Range gaji</span>
              <span>Rp0 jt - 0 jt</span>
            </div>
            <div className={Styles.linier}></div>
            <div className={Styles.minMaxBox}>
              <span>100K</span>
              <span>Rp25jt</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={Styles.containerActive}>
          <span>Range Gaji</span>
          <div>
            <div className={Styles.rangeBox}>
              <div>
                <span>Range gaji</span>
                <span>Rp{templatePrice || `Rp100rb - 25jt`}</span>
              </div>

              <div>
                <div>
                  <Icon icon={"money"} size={20} color={"#9E9E9E"} />
                </div>
                <div>
                  <span>Patokan gaji sesuai FLB</span>
                  <div>
                    <span>
                      Rp
                      {data && data?.highestSalary
                        ? data?.highestSalary
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        : 0}
                    </span>
                    <span></span>
                    <span>Bulanan</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.linier}>
              <NonLinearSlider
                setMax={setMaxValue}
                setMin={setMinValue}
                min={minValue}
                max={maxValue}
              />
            </div>

            {/* <div className={Styles.minMaxBox}>
              <span>Rp100.000</span>
              <span>Rp25.000.000</span>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
