import checkIcon from '@Assets/Images/check-mark.png';
import Icon from '@Atom/Icon';
import Styles from './style.module.scss';
import { useEffect, useRef } from 'react';

export default function FirstStep(props) {
  const { types, onChange, form, onNext } = props;

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.header}>
        <h3>Choose Contract Type<span className={Styles.asterisk}>&#x2a;</span></h3>
        <span>Pilih tipe kontrak sesuai kebutuhan anda</span>
      </div>
      <div className={Styles.content}>
        {types.map((type, it) => {
          const isType = Boolean(type?.title === form?.contract)
          return (
            <div key={it} onClick={() => onChange(type)} className={`${Styles.card} ${isType && Styles.active}`}>
              <div className={`${Styles.IconWrapper} ${isType ? Styles.selected : Styles.notSelected}`}>
                <Icon icon="check-circle" size={25} className={Styles?.CheckIcon} />
              </div>
              <div className={Styles.cardHeader}>
                <h4>{type?.title}</h4>
                <span>{type?.label}</span>
              </div>
              <div className={Styles.cardContent}>
                {
                  type?.rules?.map((rule, ir) => {
                    return (
                      <div key={ir} className={Styles.rule}>
                        <img src={checkIcon} alt="check" />
                        <p>{rule?.desc}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })}

      </div>
      <div className={Styles.buttonWrapper}>
        <button disabled={!form?.contract} onClick={onNext}>Next</button>
      </div>
    </div>
  )
}