import StackedProfile from '@Atom/StackedProfile';
import Styles from './style.module.scss';
import Images from '@Theme/Images';
import Icon from '@Atom/Icon';

export default function BMSelector({ selectedBM, setSelectedBM, data }) {
    const handleSelectedBM = (value) => {
        if (value !== selectedBM) {
            setSelectedBM(value);
        }
    };

    function toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    return (
        <div className={Styles.container}>
            <div>
                <p className={Styles.title}>
                    <span>Pilih Business Manager</span>
                </p>
            </div>

            <div className={Styles.dataWrapper}>
                {data?.map((value, index) => (
                    <div
                        className={`${Styles.dataCard} ${
                            selectedBM === value.employeeId ? Styles.dataCardSelected : ''
                        }`}
                        key={index}
                        onClick={() => handleSelectedBM(value.employeeId)}>
                        <StackedProfile
                            profilePicture={value?.profilePicture || Images.AVA_DEFAULT}
                            title={toTitleCase(value?.name)}
                            label={value?.region}
                        />

                        {selectedBM === value.employeeId && (
                            <div className={Styles.iconWrapper}>
                                <Icon icon={'check-circle'} size={'32'} color={'#1571DE'} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
