import SearchBar from '@Atom/SearchBar';
import TableEmptyHandler from '@Atom/TableEmptyHandler';
import Tooltip from '@Atom/Tooltip';
import Pagination from '@Molecule/Pagination';
import { getManPowerPerScheme } from '@Services/manager/client';
import { useCallback, useEffect, useState } from 'react';
import ManPowerSection from '../ManPowerSection';
import Styles from './style.module.scss';
import StaticProgressBar from '@Atom/StaticProgressBar';

export default function NewManPowerSection({
  flbData = [],
  flbTotalPage = 0,
  selectedPageFLB = 1,
  setSelectedPageFLB = () => { },
  limit = 5,
  setLimit = () => { },
  search = '',
  setSearch = () => { },
  totalPage,
  orderData,
  selectedFLB = null,
  setSelectedFLB = () => { },
  setSelectedPosition = () => { }
}) {
  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);


  const [mpData, setMpData] = useState()
  const [selectedPageMP, setSelectedPageMP] = useState(1)
  const [mpTotalPage, setMpTotalPage] = useState(0)
  const [limitMP, setLimitMP] = useState(10)

  const fetchRequest = useCallback(async (code) => {
    try {
      const { response } = await getManPowerPerScheme(code, limitMP, selectedPageMP)
      setMpData(response?.data)
      setMpTotalPage(response?.totalPage)
    } catch (err) {
      console.log(err, 'error when fetching requests')
    }
  }, [limitMP, selectedPageMP])

  useEffect(() => {
    if (selectedFLB?.schemeID) {
      fetchRequest(selectedFLB?.schemeID)
    }
  }, [fetchRequest, selectedFLB?.schemeID])


  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });


  const columns = [
    {
      name: "docNumber",
      title: "No.FLB/No.SPK/No.PKS",
    },
    {
      name: "",
      title: "Manpower",
      renderData: (row) => <span>{row?.manPowers?.join(", ")}</span>,
      withTooltip: true,
    },
    {
      name: '',
      title: 'Jumlah Permintaan & Jumlah Terpenuhi',
      renderData: (row) => (
        // eslint-disable-next-line eqeqeq
        row?.candidates?.requirement != 0
          ?
          <StaticProgressBar fulfilledAmount={row?.candidates?.hired} requestAmount={row?.candidates?.requirement} />
          :
          <></>

      )
    }


  ];


  if (selectedFLB) {

    return (
      <div className={Styles.mpContainer}>
        <ManPowerSection
          data={mpData}
          totalPage={mpTotalPage || 0}
          selectedPage={selectedPageMP}
          setSelectedPage={setSelectedPageMP}
          value={limitMP}
          setValue={setLimitMP}
          search={search}
          setSearch={setSearch}
          setSelectedPosition={setSelectedPosition}
        />
      </div>
    )
  }


  return (
    <div className={Styles.flbContainer}>
      <div className={Styles.header}>
        <h3>Kebutuhan Man Power Berdasarkan FLB</h3>
        <div className={Styles.searchWrapper}>
          <SearchBar
            placeholder={'Search FLB Code'}
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </div>
      </div>
      <div className={Styles.content}>
        <div
          className={Styles.tableWrapper}
          style={{ flexGrow: !!flbData?.length ? 1 : "" }}
        >
          <table>
            <thead>
              <tr>
                <th>No.</th>
                {columns?.map((each, i) => (
                  <th
                    key={i}
                    style={{
                      textAlign: each?.align || "left",
                      width: each?.width || "auto",
                    }}
                  >
                    {each?.title}
                  </th>
                ))}
              </tr>
            </thead>
            {!!flbData?.length && (
              <tbody>
                {flbData?.map((each, i) => (
                  <tr
                    key={i}
                    className={i % 2 === 0 ? Styles.odd : ""}
                    // onClick={() =>
                    //   navigate(
                    //     `/clients/orders/details/${each?.schemeID}?n=${query?.get("n") || ""
                    //     }&c=${query?.get("c") || ""}`
                    //   )
                    // }
                    onClick={() => setSelectedFLB(each)}
                  >
                    <td style={{ textAlign: 'center' }}>{i + 1}</td>
                    {columns?.map((c, iC) => (
                      <td key={iC} style={{ textAlign: c?.align || "left" }}>
                        {c?.withTooltip ? (
                          <Tooltip
                            content={
                              c?.renderData
                                ? c?.renderData(each)
                                : each[c?.name]
                            }
                            direction="right"
                            width="150px"
                          >
                            {c?.renderData ? (
                              <div className={Styles.div}>
                                {c?.renderData(each)}
                              </div>
                            ) : (
                              <div
                                style={{ width: c?.width || "auto" }}
                                className={Styles.div}
                              >
                                {" "}
                                {each[c?.name]}{" "}
                              </div>
                            )}
                          </Tooltip>
                        ) : (
                          <div className={Styles.div}>
                            {c?.renderData
                              ? c?.renderData(each)
                              : each[c?.name]}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        {!flbData?.length &&
          <TableEmptyHandler />
        }
        {/* {data?.map((each, i) => {
          return (
            <InformationContact
              key={i}
              title={`Contact ${i + 1}`}
              name={each?.name}
              position={each?.jobTitle}
              email={each?.email}
              phone={each?.phoneNumber}
              groupName={each?.workUnit}
              onDelete={(e) => handleDelete(e, each?.id)}
              onEdit={(e) => handleEdit(each)}
            />
          );
        })} */}
        <div className={Styles.paginationWrapper}>
          <Pagination
            total={flbData?.length || 0}
            totalPage={flbTotalPage || 0}
            value={limit}
            setValue={setLimit}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPageFLB}
            setSelectedPage={setSelectedPageFLB}
          />
        </div>
      </div>
    </div>
  )
}