import LoadingAnimation from '@Atom/LoadingAnimation';
import ModalWrapper from '@Atom/ModalWrapper';
import RejectConfirmationModal from '@Molecule/_modal/BusinessSupport/RejectConfirmationModal';
import RejectSuccessModal from '@Molecule/_modal/BusinessSupport/RejectSuccessModal';
import RejectVerificationModal from '@Molecule/_modal/BusinessSupport/RejectVerificationModal';
import PendingContractDetailLayout from '@Organism/BusinessSupport/Contracts/PendingContractList/ContractDetail';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';
import { getExtendCandidateDetail } from '@Services/support/candidate';
import { Suspense, useMemo, useState } from 'react';
import { Await, defer, useLoaderData } from 'react-router-dom';
import Styles from './styles.module.scss';

export async function loader({ params }) {
    const { employeeId } = params;

    const candidateDetailsPromise = getExtendCandidateDetail(employeeId);

    return defer({
        packageCandidateDetails: candidateDetailsPromise,
    });
}

export default function PendingContractDetail() {
    const { packageCandidateDetails } = useLoaderData();

    const [showReject, setShowReject] = useState(0);
    const [showAccept, setShowAccept] = useState(0);
    const [trigger, setTrigger] = useState('');
    const [stage, setStage] = useState('');
    const [candidateId, setCandidateId] = useState('')
    // const [isLoading, setIsLoading] = useState('')
    // const [error, setError] = useState('')

    // const handleInject = async () => {
    //     try {
    //         setIsLoading(true);
    //         await verifyCandidate('Accepted', 'SECOND-VERIFICATION', candidateId, '', 'VERIFICATION');
    //         console.log('candidateID', candidateId)
    //         setTrigger(Math.random());
    //         setIsLoading(false);
    //         setShowAccept(2);
    //     } catch (error) {
    //         console.log(error, 'error');
    //         setError(error?.response?.data?.error || 'Something went wrong');
    //         setIsLoading(false);
    //     }
    // };

    // const acceptModal = useMemo(() => {
    //     switch (showAccept) {
    //         case 1:
    //             return (
    //                 <ApproveConfirmationExtendModal
    //                     setShowAccept={setShowAccept}
    //                     isLoading={isLoading}
    //                     error={error}
    //                     submit={handleInject}
    //                 />
    //             );
    //         case 2:
    //             return (
    //                 <ApproveSuccessModal
    //                     setShowAccept={setShowAccept}
    //                     stage={"extend"}
    //                 />
    //             );
    //         default:
    //             return '';
    //     }
    // }, [showAccept, error, isLoading]);

    const rejectModal = useMemo(() => {
        switch (showReject) {
          case 1:
            return <RejectConfirmationModal setShowReject={setShowReject} />;
          case 2:
            return (
              <RejectVerificationModal
                setShowReject={setShowReject}
                setTrigger={setTrigger}
                stage={stage}
                customId={candidateId}
              />
            );
          case 3:
            return (
              <RejectSuccessModal setShowReject={setShowReject} stage={stage} />
            );
          default:
            return "";
        }
      }, [showReject, stage, candidateId]);

    return (
        <div className={Styles.container}>
            <Suspense
                fallback={
                    <div className={Styles.loadingWrapper}>
                        <LoadingAnimation />
                    </div>
                }>
                <Await
                    resolve={packageCandidateDetails}
                    errorElement={
                        <div style={{ height: 'calc(100vh - 220px)' }}>
                            <AsyncErrorBoundary />
                        </div>
                    }>
                    {(packageCandidateDetails) => (
                        <PendingContractDetailLayout
                            setShowAccept={setShowAccept}
                            setShowReject={setShowReject}
                            defaultData={packageCandidateDetails?.response}
                            trigger={trigger}
                            setTrigger={setTrigger}
                            stage={stage}
                            setStage={setStage}
                            defaultSync={null}
                            setCandidateId={setCandidateId}
                        />
                    )}
                </Await>
            </Suspense>

            <div>
                <ModalWrapper
                    show={showAccept !== 0 || showReject !== 0}
                    forceCenter={showAccept === 2 ? false : true}
                    handleClose={() => setShowAccept(0)}>
                    {rejectModal}
                    {/* {acceptModal || rejectModal} */}
                </ModalWrapper>
            </div>
        </div>
    );
}
