import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import { titleCase } from "@Helpers/titleCase";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import moment from "moment";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";

export default function CandidateResumeLayout({
  data,
  unqualifiedButton,
  setMode,
  noDocument = false,
  noContact = false
}) {
  const userProfilePict = useMemo(() => {
    if (data?.CandidateDocuments?.find((el) => el.type === "SELF PICTURE")) {
      return (
        fileBaseUrl +
        data?.CandidateDocuments?.find((el) => el.type === "SELF PICTURE").url
      );
    } else {
      return Images.AVA_DEFAULT;
    }
  }, [data]);
  return (
    <div className={Styles.container}>
      <div className={Styles.profileHead}>
        <div className={Styles.profilePicture}>
          {/* {Profile_Photo} */}
          <img src={userProfilePict} alt="profile" />
        </div>
        <div className={Styles.profileDesc}>
          <div className={Styles.descHead}>
            <span>{titleCase(data?.name || "name placeholder")}</span>
            <div className={Styles.divider} />
            <span>{data?.ExpectedPosition?.name}</span>
          </div>

          <div className={Styles.profileInfo}>
            <span>Informasi Umum</span>
            <div className={Styles.info}>
              <p>Posisi</p>
              <p>: {data?.ExpectedPosition?.name}</p>
            </div>
            <div className={Styles.info}>
              <p>NIK</p>
              <p>: {data?.personId || "-"}</p>
            </div>
            <div className={Styles.info}>
              <p>Tanggal lahir</p>
              <p>: {moment(data?.dateOfBirth).format("DD MMMM YYYY") || "-"}</p>
            </div>
            <div className={Styles.info}>
              <p>Domisili</p>
              <p>: {data?.domicile || "-"}</p>
            </div>
            <div className={Styles.info}>
              <p>Alamat</p>
              <p>: {data?.address || "-"}</p>
            </div>
            {!noContact
              &&
              <div className={Styles.info}>
                <p>Nomor Whatsapp</p>
                <p>
                  : <span className={Styles.textNeutral60}>+62 </span>
                  {data?.phoneNumber?.includes("62")
                    ? data?.phoneNumber.slice(2)
                    : data?.phoneNumber || "-"}
                </p>
              </div>
            }
            {!noContact
              &&
              <div className={Styles.info}>
                <p>email</p>
                <p>: {data?.email || "-"}</p>
              </div>
            }


          </div>
        </div>
      </div>
      <div className={Styles.section}>
        <div className={Styles.sectionExp}>
          <p>Pengalaman Kerja</p>
          {data?.WorkExperiences?.length > 0 ? (
            data?.CandidateWorkExperiences?.map((el, idx) => (
              <div className={Styles.experience} key={idx}>
                <div className={Styles.left}>
                  <p>
                    {el.startMonth}{" "}
                    {el.startYear !== el.endYear && el.startYear} -{" "}
                    {el.endMonth} {el.endYear}
                  </p>
                  <p>
                    {el.position} • {el.companyName}
                  </p>
                </div>
                <div className={Styles.right}>
                  <p>{el.description}</p>
                </div>
              </div>
            ))
          ) : (
            <div className={Styles.noExp}>
              <p>Tanpa Pengalaman</p>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.section}>
        <div className={Styles.sectionEducation}>
          <p>Pendidikan Terakhir</p>
          <p>{data?.lastEducation}</p>
        </div>
      </div>
      <div className={Styles.section}>
        <div className={Styles.sectionAdditional}>
          <p>Informasi Lainnya</p>
          <div className={Styles.wrapper}>
            <div className={Styles.additional}>
              <p>Kemampuan bahasa inggris</p>
              <p>{data?.englishLevel || "-"}</p>
            </div>
            <div className={Styles.additional}>
              <p>Apakah memiliki kendaraan sendiri</p>
              <p>{data?.vehicle || "-"}</p>
            </div>
          </div>
        </div>
      </div>
      {!noDocument && (
        <div className={Styles.documentSection}>
          <p>Dokumen</p>
          <div className={Styles.documentWrapper}>
            {data?.CandidateDocuments?.filter(
              (el) => el.type !== "SELF PICTURE"
            ).map((el, idx) => (
              <div className={Styles.document} key={idx}>
                <div className={Styles.left}>
                  <Icon icon="document-text" size={24} />
                  <p>
                    {el.type === "CV"
                      ? "CV.pdf"
                      : el.url.split("-").slice(1).slice(0, -1).join("-")}
                  </p>
                </div>
                {/* <a href={fileBaseUrl + el.url} download> */}
                <Icon
                  icon="download"
                  size={24}
                  onClick={() => window.open(fileBaseUrl + el.url)}
                />
                {/* </a> */}
              </div>
            ))}
          </div>
        </div>
      )}

      {unqualifiedButton && (
        <div className={Styles.actionSection}>
          <p>Apakah kandidat tidak memenuhi kualifikasi?</p>
          <div className={Styles.actionButton}>
            <Button
              title={"Tidak Memenuhi Kualifikasi"}
              type={"secondary-blue"}
              onClick={() => setMode("unqualified")}
            />
          </div>
        </div>
      )}
    </div>
  );
}
