import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import { rupiahFormat } from "@Helpers/formatRupiah";
import Images from "@Theme/Images";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function ManPowerSection({
  data,
  qualificationList,
  selectedLogs,
  isHC,
  updateLogs,
}) {
  // eslint-disable-next-line no-unused-vars
  const orderComprehensive = useMemo(() => {
    return data?.order?.orderComprehensives;
  }, [data]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  // console.log(selectedLogs);
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Deployment & Position</h3>
      </div>
      <div className={Styles.body}>
        <div>
          <span className={Styles.deployment}>Deployment</span>
          <div className={Styles.delegationBox}>
            {data?.delegations?.map((el, idx) => {
              return (
                <div key={idx} className={Styles.delegations}>
                  <div>
                    <Icon icon={"company"} size={20} color={"#9E9E9E"} />
                    <span>{el?.cityName || "-"}</span>
                  </div>

                  <div className={Styles.box}>
                    <div className={Styles.users}>
                      <span>Business Manager</span>
                      {el?.users?.businessManagers?.length > 0 ? (
                        <div>
                          {el?.users?.businessManagers?.map((user, index) => {
                            return (
                              <div className={Styles.cardUsers} key={index}>
                                <div>
                                  <div className={Styles.images}>
                                    <img
                                      src={
                                        user?.profilePicture
                                          ? fileBaseUrl + user?.profilePicture
                                          : Images.AVA_DEFAULT
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <div className={Styles.name}>
                                    <span>{user?.User?.name || "-"}</span>
                                    <span>{user?.User?.jobLevel || "-"}</span>
                                  </div>
                                </div>

                                {user?.isPIC === true ? <span>PIC</span> : null}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <div className={Styles.notFound}>
                            <div className={Styles.img}>
                              <img src={Images.FILE_AND_FOLDER} alt="" />
                            </div>
                            <span>Informasi delegasi masih kosong</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={Styles.users}>
                      <span>Account Manager / Account Officer</span>
                      {el?.users?.accountManagerOrPIC?.length > 0 ? (
                        <div>
                          {el?.users?.accountManagerOrPIC?.map(
                            (user, index) => {
                              return (
                                <div className={Styles.cardUsers} key={index}>
                                  <div>
                                    <div className={Styles.images}>
                                      <img
                                        src={
                                          user?.profilePicture
                                            ? fileBaseUrl + user?.profilePicture
                                            : Images.AVA_DEFAULT
                                        }
                                        alt=""
                                      />
                                    </div>

                                    <div className={Styles.name}>
                                      <span>{user?.User?.name || "-"}</span>
                                      <span>{user?.User?.jobLevel || "-"}</span>
                                    </div>
                                  </div>

                                  {user?.isPIC === true ? (
                                    <span>PIC</span>
                                  ) : null}
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className={Styles.notFound}>
                            <div className={Styles.img}>
                              <img src={Images.FILE_AND_FOLDER} alt="" />
                            </div>
                            <span>Informasi delegasi masih kosong</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={Styles.box}>
                    <div className={Styles.users}>
                      <span>Admin/Admin Contract</span>
                      {el?.users?.accountOfficerOrAdmin?.length > 0 ? (
                        <div>
                          {el?.users?.accountOfficerOrAdmin?.map(
                            (user, index) => {
                              return (
                                <div className={Styles.cardUsers} key={index}>
                                  <div>
                                    <div className={Styles.images}>
                                      <img
                                        src={
                                          user?.profilePicture
                                            ? fileBaseUrl + user?.profilePicture
                                            : Images.AVA_DEFAULT
                                        }
                                        alt=""
                                      />
                                    </div>

                                    <div className={Styles.name}>
                                      <span>{user?.User?.name || "-"}</span>
                                      <span>{user?.User?.jobLevel || "-"}</span>
                                    </div>
                                  </div>

                                  {user?.isPIC === true ? (
                                    <span>PIC</span>
                                  ) : null}
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className={Styles.notFound}>
                            <div className={Styles.img}>
                              <img src={Images.FILE_AND_FOLDER} alt="" />
                            </div>
                            <span>Informasi delegasi masih kosong</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={Styles.users}>
                      <span>Recruitment Officer</span>
                      {el?.users?.recruitmentOfficers?.length > 0 ? (
                        <div>
                          {el?.users?.recruitmentOfficers?.map(
                            (user, index) => {
                              return (
                                <div className={Styles.cardUsers} key={index}>
                                  <div>
                                    <div className={Styles.images}>
                                      <img
                                        src={
                                          user?.profilePicture
                                            ? fileBaseUrl + user?.profilePicture
                                            : Images.AVA_DEFAULT
                                        }
                                        alt=""
                                      />
                                    </div>

                                    <div className={Styles.name}>
                                      <span>{user?.User?.name || "-"}</span>
                                      <span>{user?.User?.jobLevel || "-"}</span>
                                    </div>
                                  </div>

                                  {user?.isPIC === true ? (
                                    <span>PIC</span>
                                  ) : null}
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className={Styles.notFound}>
                            <div className={Styles.img}>
                              <img src={Images.FILE_AND_FOLDER} alt="" />
                            </div>
                            <span>Informasi delegasi masih kosong</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <span className={Styles.deployment}>Position</span>
          {data?.manPowers?.length > 0 ? (
            <div className={Styles.positionBox}>
              {data?.manPowers?.map((el, idx) => {
                return (
                  <div
                    key={idx}
                    className={`${Styles.box} ${
                      isHC &&
                      updateLogs?.length > 0 &&
                      updateLogs?.find(
                        (log) =>
                          log?.updateValue?.toUpperCase() === el?.position &&
                          log?.table === "ExpectedPosition"
                      ) &&
                      Styles.borderYellow
                    }`}
                    id={`${el?.position}`}
                  >
                    <div
                      className={`${Styles.headerPosition} ${
                        isHC &&
                        selectedLogs?.data?.updateValue?.toUpperCase() ===
                          el?.position &&
                        selectedLogs?.data?.table === "ExpectedPosition" &&
                        Styles.yellow
                      }`}
                    >
                      <div>
                        <Icon
                          icon={"briefcase-outlined"}
                          size={20}
                          color={"#9E9E9E"}
                        />
                        <span>{el?.position || "-"}</span>
                      </div>

                      <div>
                        <span>Recruitment Officer Dedicated :</span>

                        <div>
                          <div>
                            <img
                              src={
                                el?.profilePicture
                                  ? fileBaseUrl + el?.profilePicture
                                  : Images.AVA_DEFAULT
                              }
                              alt=""
                            />
                          </div>

                          <span>
                            {el?.dedicatedRO
                              ? el?.dedicatedRO?.name || "-"
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={`${Styles.jobAplicationBox}`}>
                      <span>Job Qualification</span>
                      <div
                        className={`${Styles.qualificationBox} ${
                          isHC &&
                          updateLogs?.length > 0 &&
                          updateLogs?.filter(
                            (log) =>
                              (log?.table === "ExpectedQualification" &&
                                log?.remark
                                  ?.split(" Posisi ")[1]
                                  ?.split(" Ditambahkan")[0]
                                  ?.toUpperCase() === el?.position) ||
                              (log?.table === "ExpectedQualification" &&
                                log?.remark
                                  ?.split(" Posisi ")[1]
                                  ?.split(" Dihapus")[0]
                                  ?.toUpperCase() === el?.position) ||
                              (log?.table === "ExpectedQualification" &&
                                log?.remark
                                  ?.split(" Posisi ")[1]
                                  ?.toUpperCase() === el?.position)
                          )?.length > 0 &&
                          Styles.borderYellow
                        } ${
                          (isHC &&
                            selectedLogs?.data?.remark
                              ?.split(" Posisi ")[1]
                              ?.split(" Ditambahkan")[0]
                              ?.toUpperCase() === el?.position &&
                            selectedLogs?.data?.table ===
                              "ExpectedQualification") ||
                          (isHC &&
                            selectedLogs?.data?.remark
                              ?.split(" Posisi ")[1]
                              ?.split(" Dihapus")[0]
                              ?.toUpperCase() === el?.position &&
                            selectedLogs?.data?.table ===
                              "ExpectedQualification") ||
                          (isHC &&
                            selectedLogs?.data?.remark
                              ?.split(" Posisi ")[1]
                              ?.toUpperCase() === el?.position &&
                            selectedLogs?.data?.table ===
                              "ExpectedQualification")
                            ? Styles.yellow
                            : ""
                        }`}
                      >
                        <div className={Styles.tableHead}>
                          <span>Title</span>
                          <span>Qualification</span>
                        </div>

                        <div className={`${Styles.tableBody}`}>
                          {el?.qualification?.map((qualifications, i) => {
                            return (
                              <div key={i}>
                                <span>{qualifications?.name || "-"}</span>
                                <span>{qualifications?.value || "-"}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className={Styles.cityBox}>
                      <span>City</span>
                      <div className={`${Styles.positionList}`}>
                        {el?.cities?.map((city, index) => {
                          return (
                            <div
                              key={index}
                              className={`${
                                !isHC &&
                                index === el?.cities?.length - 1 &&
                                Styles.noBorder
                              } ${
                                isHC &&
                                updateLogs?.find(
                                  (log) =>
                                    log?.remark?.includes(
                                      "Kota Baru Ditambahkan untuk Posisi"
                                    ) &&
                                    log?.updateValue !== city?.cityName &&
                                    city?.position?.id !== log?.positionID
                                ) &&
                                index === el?.cities?.length - 1 &&
                                Styles.noBorder
                              } ${
                                isHC &&
                                updateLogs?.length > 0 &&
                                updateLogs?.find(
                                  (log) =>
                                    log?.remark?.includes(
                                      "Kota Baru Ditambahkan untuk Posisi"
                                    ) &&
                                    log?.updateValue === city?.cityName &&
                                    city?.position?.id === log?.positionID
                                ) &&
                                Styles.borderYellow
                              } ${
                                selectedLogs?.data?.remark?.includes(
                                  "Kota Baru Ditambahkan untuk Posisi"
                                ) &&
                                selectedLogs?.data?.updateValue ===
                                  city?.cityName &&
                                selectedLogs?.data?.positionID ===
                                  city?.position?.id &&
                                Styles.yellow
                              }`}
                              id={`${city?.position?.id}-${city?.cityName}`}
                            >
                              <div className={Styles.first}>
                                <Icon icon={"frame-4683"} size={16} />
                                <span>{city?.cityName || "-"}</span>
                              </div>

                              <div className={Styles.second}>
                                <div
                                  className={`${
                                    isHC &&
                                    updateLogs?.length > 0 &&
                                    updateLogs?.find(
                                      (log) =>
                                        log?.remark?.includes(
                                          "Perubahan Jumlah Permintaan Manpower"
                                        ) &&
                                        log?.remark
                                          ?.split(" Posisi ")[1]
                                          ?.split(" untuk ")[0]
                                          ?.toUpperCase() === el?.position &&
                                        log?.remark?.split(" untuk ")[2] ===
                                          city?.cityName
                                    ) &&
                                    Styles.borderYellow
                                  } ${
                                    isHC &&
                                    selectedLogs?.data?.remark?.includes(
                                      "Perubahan Jumlah Permintaan Manpower"
                                    ) &&
                                    selectedLogs?.data?.remark
                                      ?.split(" Posisi ")[1]
                                      ?.split(" untuk ")[0]
                                      ?.toUpperCase() === el?.position &&
                                    selectedLogs?.data?.remark?.split(
                                      " untuk "
                                    )[2] === city?.cityName &&
                                    Styles.yellow
                                  }`}
                                >
                                  <span>Total</span>
                                  <span>{city?.position?.amount || "-"}</span>
                                </div>

                                <div
                                  className={`${
                                    isHC &&
                                    updateLogs?.length > 0 &&
                                    updateLogs?.find(
                                      (log) =>
                                        log?.remark?.includes(
                                          "Perubahan Salary"
                                        ) &&
                                        log?.remark
                                          ?.split(" Posisi ")[1]
                                          ?.split(" untuk ")[0]
                                          ?.toUpperCase() === el?.position &&
                                        log?.remark?.split(" untuk ")[1] ===
                                          city?.cityName
                                    ) &&
                                    Styles.borderYellow
                                  } ${
                                    isHC &&
                                    selectedLogs?.data?.remark?.includes(
                                      "Perubahan Salary"
                                    ) &&
                                    selectedLogs?.data?.remark
                                      ?.split(" Posisi ")[1]
                                      ?.split(" untuk ")[0]
                                      ?.toUpperCase() === el?.position &&
                                    selectedLogs?.data?.remark?.split(
                                      " untuk "
                                    )[1] === city?.cityName &&
                                    Styles.yellow
                                  }`}
                                >
                                  <span>Salary</span>
                                  {city?.position?.salary?.includes("-") ? (
                                    <span>
                                      Rp
                                      {city?.position?.salary
                                        ?.split("-")
                                        ?.map((s, is, arr) => (
                                          <span key={is}>
                                            {formatNumber(
                                              +s
                                                ?.replace(".", ",")
                                                .replace(
                                                  /\d{3}(?=(\d{3})*,)/g,
                                                  function (s) {
                                                    return "." + s;
                                                  }
                                                )
                                            )}{" "}
                                            {arr.length > 1 && is === 0
                                              ? " - "
                                              : is !== 0
                                              ? " /bln"
                                              : "/bln"}
                                          </span>
                                        ))}
                                    </span>
                                  ) : (
                                    <span>
                                      {formatNumber(
                                        city?.position?.salary?.split("::")[0]
                                      )}{" "}
                                      / {city?.position?.salary?.split("::")[1]}
                                    </span>
                                  )}
                                </div>

                                <div
                                  className={`${
                                    isHC &&
                                    updateLogs?.length > 0 &&
                                    updateLogs?.find(
                                      (log) =>
                                        log?.remark?.includes(
                                          "Perubahan Expected Date"
                                        ) &&
                                        log?.remark
                                          ?.split(" Posisi ")[1]
                                          ?.split(" untuk ")[0]
                                          ?.toUpperCase() === el?.position &&
                                        log?.remark?.split(" untuk ")[1] ===
                                          city?.cityName
                                    ) &&
                                    Styles.borderYellow
                                  } ${
                                    isHC &&
                                    selectedLogs?.data?.remark?.includes(
                                      "Perubahan Expected Date"
                                    ) &&
                                    selectedLogs?.data?.remark
                                      ?.split(" Posisi ")[1]
                                      ?.split(" untuk ")[0]
                                      ?.toUpperCase() === el?.position &&
                                    selectedLogs?.data?.remark?.split(
                                      " untuk "
                                    )[1] === city?.cityName &&
                                    Styles.yellow
                                  }`}
                                >
                                  <span>Expected date</span>
                                  <span>
                                    {city?.position?.expectedFulfillmentDate
                                      ? moment(
                                          new Date(
                                            city?.position?.expectedFulfillmentDate
                                          )
                                        ).format("DD MMMM YYYY")
                                      : "-"}
                                  </span>
                                </div>
                              </div>

                              <div
                                id={`${city?.position?.id}-${city?.cityName}-Allowances`}
                              ></div>
                              {windowSize[0] > 768 && (
                                <div
                                  id={`${city?.position?.id}-${city?.cityName}-Equipments`}
                                ></div>
                              )}

                              <div className={Styles.third}>
                                <div
                                  className={`${
                                    isHC &&
                                    selectedLogs?.data?.table ===
                                      "ExpectedAllowance" &&
                                    city?.position?.allowances?.find(
                                      (eq) =>
                                        eq?.id ===
                                        selectedLogs?.data?.allowanceID
                                    ) &&
                                    Styles.yellow
                                  } ${
                                    isHC &&
                                    updateLogs?.length > 0 &&
                                    updateLogs?.filter(
                                      (log) =>
                                        log?.table === "ExpectedAllowance"
                                    )?.length > 0 &&
                                    city?.position?.allowances?.some(
                                      (allowancesId) =>
                                        updateLogs
                                          ?.filter(
                                            (log) =>
                                              log?.table === "ExpectedAllowance"
                                          )
                                          ?.some(
                                            (log) =>
                                              log?.allowanceID ===
                                              allowancesId?.id
                                          )
                                    ) &&
                                    Styles.borderYellow
                                  }`}
                                >
                                  <span>Allowance</span>
                                  {city?.position?.allowances?.length > 0 ? (
                                    <div>
                                      {city?.position?.allowances?.map(
                                        (allowances, i) => {
                                          return (
                                            <div key={i}>
                                              <span>
                                                {allowances?.name || "-"}
                                              </span>
                                              <span>
                                                {allowances?.value
                                                  ? rupiahFormat(
                                                      allowances?.value
                                                    )
                                                  : "-"}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className={Styles.notFound}>
                                      <span>
                                        Belum ada data yang dimasukkan
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {windowSize[0] <= 768 && (
                                  <span
                                    id={`${city?.position?.id}-${city?.cityName}-Equipments`}
                                  ></span>
                                )}
                                <div
                                  className={`${
                                    isHC &&
                                    selectedLogs?.data?.table ===
                                      "ExpectedEquipment" &&
                                    city?.position?.personalEquipments?.find(
                                      (eq) =>
                                        eq?.id ===
                                        selectedLogs?.data?.equipmentsID
                                    ) &&
                                    Styles.yellow
                                  } ${
                                    isHC &&
                                    updateLogs?.length > 0 &&
                                    updateLogs?.filter(
                                      (log) =>
                                        log?.table === "ExpectedEquipment"
                                    )?.length > 0 &&
                                    city?.position?.personalEquipments?.some(
                                      (equipment) =>
                                        updateLogs
                                          ?.filter(
                                            (log) =>
                                              log?.table === "ExpectedEquipment"
                                          )
                                          ?.some(
                                            (log) =>
                                              log.equipmentsID === equipment.id
                                          )
                                    ) &&
                                    Styles.borderYellow
                                  }`}
                                >
                                  <span>Personal Work Equipment</span>
                                  {city?.position?.personalEquipments?.length >
                                  0 ? (
                                    <div>
                                      {city?.position?.personalEquipments?.map(
                                        (equipment, i) => {
                                          return (
                                            <div key={i}>
                                              <span>
                                                {equipment?.name || "-"}
                                              </span>
                                              <span>
                                                {equipment?.price
                                                  ? rupiahFormat(
                                                      equipment?.price
                                                    )
                                                  : "-"}
                                              </span>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className={Styles.notFound}>
                                      <span>
                                        Belum ada data yang dimasukkan
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={Styles.notFound}>
              <div>
                <div className={Styles.img}>
                  <img src={Images.FILE_AND_FOLDER} alt="" />
                </div>
                <span>Informasi posisi masih kosong</span>
              </div>
            </div>
          )}
        </div>

        <div id="Remark">
          <span className={Styles.deployment}>Remark</span>
          <div
            className={`${Styles.remarkBox} ${
              isHC &&
              updateLogs?.length &&
              updateLogs?.find(
                (log) => log?.remark === "Perubahan Remarks Posisi"
              ) &&
              Styles.borderYellow
            } ${selectedLogs?.data?.remark === "Perubahan Remarks Posisi"}`}
          >
            {data?.remark || "-"}
          </div>
        </div>
      </div>
    </div>
  );
}
