/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import StackedProfile from "@Atom/StackedProfile";
import CustomTable from "@Molecule/CustomTable";
import ApproveCandidateModal from "@Molecule/_modal/ApproveCandidateModal";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import Tooltip from "@Atom/Tooltip";
import RejectCandidateModal from "@Molecule/_modal/RejectCandidateModal";
import SuccessApprovalModal from "@Molecule/_modal/SuccessApprovalModal";

export default function ApprovalListLayout({
  defaultData = [],
  totalData = 0,
  totalPage = 0,
  isLoading = false,
  isError = false,
}) {
  const tabs = useMemo(() => {
    return ["Candidate List", "Approved"];
  }, []);

  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const defaultMeta = {
    page: query.get("p") || 1,
    limit: query.get("l") || 10,
    search: query.get("s") || "",
    trigger: query.get("t") || "",
  };

  const [tab, setTab] = useState(tabs[0]);

  const [limit, setLimit] = useState(defaultMeta?.limit);
  const [page, setPage] = useState(defaultMeta?.page);

  const [search, setSearch] = useState(defaultMeta?.search);

  const debouncedSearch = useDebounce(search);

  const [trigger, setTrigger] = useState(defaultMeta?.trigger);

  useEffect(() => {
    navigate(
      `${pathname}?p=${page}&l=${limit}&s=${debouncedSearch}&t=${trigger}&i=${
        tab === "Candidate List" ? "false" : "true"
      }`,
      { replace: true }
    );
  }, [limit, navigate, page, pathname, debouncedSearch, trigger, tab]);

  const [selectedData, setSelectedData] = useState(null);
  const [selectedMode, setSelectedMode] = useState("");

  const template = useMemo(() => {
    return {
      data: defaultData?.length
        ? defaultData?.map((obj) => {
            return {
              ...obj,
              id: obj?.id,
              name: obj?.name,
              profilePicture: "",
              position: obj?.ExpectedPosition?.name,
              clientName: obj?.ExpectedPosition?.OrderScheme?.Client?.name,
              email: obj?.email,
              phoneNumber: obj?.phoneNumber,
              isApproved: tab === tabs[0] ? false : true,
              proof: "",
              reason: "Cocok",
              status: obj?.status
            };
          })
        : [],

      columns: [
        {
          name: "name",
          label: "Nama Kandidat",
          maxWidth: "250px",
          minWidth: "250px",
          renderData: (row) => (
            <StackedProfile
              profilePicture={row?.profilePicture}
              imgSize="24px"
              title={row?.name}
            />
          ),
        },
        {
          name: "position",
          label: "Nama Posisi",
          tdClassName: Styles.oneLine,
        },
        {
          name: "clientName",
          label: "Nama Klien",
          maxWidth: "150px",
          minWidth: "150px",
          tdClassName: Styles.oneLine,
        },
        {
          name: "email",
          label: "Email",
          maxWidth: "150px",
          minWidth: "150px",
          tdClassName: Styles.oneLine,
        },
        {
          name: "phoneNumber",
          label: "Nomor HP",
        },
        {
          name: "",
          label: "",
          renderData: (row) => (
            <div className={Styles.buttonWrapper}>
              <button
                className={Styles.approveButton}
                onClick={() => {
                  setSelectedData(row)
                  setSelectedMode("Approve")
                }}
              >
                {row?.isApproved ? "View Detail" : "Approve"}
              </button>
              {row?.status !== "CLIENT-DISQUALIFIED" ?
                <Tooltip content={row?.isApproved ? "Cancel Approval" : "Reject"} noWhiteSpace>
                  <button
                    className={Styles.rejectButton}
                    onClick={() => {
                      setSelectedData(row)
                      setSelectedMode("Reject")
                    }}
                  >
                  <Icon 
                    icon={"cross"}
                    color={"#ff3e13"}
                    size={"20"}
                    />
                  </button>
                </Tooltip>
              : <></>}
            </div>
          ),
        },
      ],
    };
  }, [defaultData, tab, tabs]);

  const [isDone, setIsDone] = useState({})

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.searchbar}>
          <SearchBar
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder={"Search candidate name"}
          />
        </div>
        <button>
          <Icon icon={"adjustment"} size={20} />
          <span>Filter</span>
        </button>
      </div>
      <div className={Styles.tab}>
        <div className={Styles.tabs}>
          {tabs?.map((t, tI) => (
            <button
              key={tI}
              onClick={() => setTab(t)}
              className={tab === t ? Styles.active : ""}
            >
              {t}
            </button>
          ))}
        </div>
        <div className={Styles.notes}>
          <p>
            Berikut adalah daftar kandidat yang{" "}
            {tab === tabs[0] ? "belum" : "sudah"} di <span>Approve</span> oleh{" "}
            {tab === tabs[0] ? "klien" : "BM"}
          </p>
          <Icon icon={"question-mark"} size={24} color={"#1571DE"} />
        </div>
      </div>
      <div className={Styles.content}>
        {!isError ? (
          <div className={Styles.tableWrapper}>
            <CustomTable
              // data={tab === tabs[0] ? template?.data : []}
              data={template?.data}
              columns={template?.columns}
              totalData={tab === tabs[0] ? totalData : 0}
              value={limit || 5}
              setValue={setLimit}
              selectedPage={page}
              setSelectedPage={setPage}
              totalPage={tab === tabs[0] ? totalPage : 0}
              withNumbering
              oddRowClassName={Styles.odd}
              noRowBorder
              isLoadingSkeleton={isLoading || page != query.get('p') || limit != query?.get('l')}
            />
          </div>
        ) : (
          <AsyncErrorBoundary />
        )}
      </div>
      
      {(selectedData && selectedMode == "Approve") && (
        <ApproveCandidateModal
          defaultData={selectedData}
          show={selectedData && selectedMode == "Approve"}
          setSelectedMode={setSelectedMode}
          onClose={() => {
            setSelectedData(null)
            setSelectedMode("")
          }}
          setTrigger={setTrigger}
          setIsDone={setIsDone}
        />
      )} 
      
      {(selectedData && selectedMode == "Reject") && (
        <RejectCandidateModal
          defaultData={selectedData}
          show={selectedData && selectedMode == "Reject"}
          onClose={() => {
            setSelectedData(null)
            setSelectedMode("")
          }}
          setTrigger={setTrigger}
          setIsDone={setIsDone}
        />
      )}

      {(isDone?.name && isDone?.mode) && (
        <SuccessApprovalModal
          message={`Kandidat ${isDone?.name} telah berhasil di ${isDone?.mode}`}
          handleDone={() => setIsDone({})}
          onBack={() => setIsDone({})}
        />
      )}
    </div>
  );
}
