import Images from "@Theme/Images";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import { useEffect, useMemo, useState } from "react";
import Spinner from "@Atom/Spinner";

export default function LocationContract({ state, setStep }) {
  const disableButton = useMemo(
    () => state.longitude === 0 || state.latitude === 0,
    [state]
  );

  const handleReload = () => {
    window.location.reload();
    // Set langkah kembali ke 2 setelah reload
    // setStep(2);
  };

  const [showLocationPermissionModal, setShowLocationPermissionModal] =
    useState(false);

  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });
        if (result.state === "denied") {
          setShowLocationPermissionModal(true);
        }
      } catch (error) {
        console.error("Error checking location permission:", error);
      }
    };

    checkLocationPermission();
  }, []);

  return (
    <div className={Styles.ContractSignContainer}>
      <div className={Styles.Header}>
        <img src={Images.KERJA365_LIGHT} alt="" />
        <div className={Styles.Logo}>
          <div>
            <img src={Images.PERMATA_LOGO} alt="permata-logo" />
          </div>
          <div>
            <span>Powered by</span>
            <p>Permata Indo Sejahtera</p>
          </div>
        </div>
      </div>

      <div className={Styles.section}>
        <div className={Styles.firstSection}>
          <div className={Styles.cardWarning}>
            <div className={Styles.blueBorder}></div>
            <div className={Styles.warn}>
              <div className={Styles.warnH1}>
                Pastikan Pengaturan Lokasimu Aktif
              </div>
              <div className={Styles.box}>
                <div className={Styles.iconInform}>
                  <Icon
                    icon={"perm_device_information"}
                    size={20}
                    color={"#757575"}
                  />
                </div>
                <span className={Styles.p}>
                  Pastikan kamu telah mengizinkan dan mengaktifkan lokasi pada
                  browser.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.imagesBox}>
          <img src={Images.LOCATION_ICON} alt="" />
        </div>

        <div className={Styles.thirdSection}>
          {disableButton ? (
            <div className={Styles.spinner}>
              <Spinner type={"contract"} />
            </div>
          ) : (
            <div className={Styles.iconSuccess}>
              <Icon icon={"check-circle"} size={40} color={"#1571DE"} />
            </div>
          )}

          <div className={Styles.descLocation}>
            <span className={Styles.descP}>
              {disableButton ? "Mengambil Lokasi ..." : "Berhasil"}
            </span>
            <span className={Styles.descText}>
              {disableButton
                ? "Pengambilan lokasi akan otomatis terambil oleh sistem, Refresh kembali jika belum berhasil"
                : "Berhasil mengambil lokasi, silahkan untuk melanjutkan tanda tangan kontrak"}
            </span>
          </div>
        </div>

        <div className={Styles.fourthSection}>
          {disableButton ? (
            <>
              <div className={Styles.buttonRefresh} onClick={handleReload}>
                Refresh
              </div>
              <div className={Styles.buttonNextDisabled}>Lanjut</div>
            </>
          ) : (
            <div
              className={Styles.nextButton}
              onClick={() => {
                setStep(3);
              }}
            >
              Lanjut
            </div>
          )}
        </div>
      </div>

      {showLocationPermissionModal === true ? (
        <div className={Styles.notifBlockLocation}>
          <div className={Styles.modalNotif}>
            <div className={Styles.top}>
              <span className={Styles.H1}>Konfirmasi</span>
              <span className={Styles.p}>
                Silahkan buka pengaturan dan nyalakan lokasi anda untuk bisa
                melanjutkan proses tanda tangan kontrak. Setelah itu, refresh
                kembali halaman ini.
              </span>
            </div>
            <div
              className={Styles.bottom}
              onClick={() => {
                setShowLocationPermissionModal(false);
              }}
            >
              Kembali
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
