import Styles from "./style.module.scss";
import ICON from "../../../../../assets/Images/sent.png";

const SentContractModal = ({ onClickBack = () => {}, type }) => {
  if (type === "candidate" || type === "bm") {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.imageWrapper}>
            <img src={ICON} alt="" height={40} width={40} />
          </div>
        </div>
        <div className={Styles.iconWrapper}>
          <div className={Styles.title}>Terkirim</div>
          <div className={Styles.text}>
            Berhasil terkirim ke{" "}
            {type === "bm" ? "Business Manager" : "kandidat"}
          </div>
        </div>
        <div className={Styles.buttons}>
          <div className={Styles.secondaryButton} onClick={onClickBack}>
            <div className={Styles.text}>Kembali</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.imageWrapper}>
          <img src={ICON} alt="" height={40} width={40} />
        </div>
      </div>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Terkirim</div>
        <div className={Styles.text}>Kontrak berhasil terkirim ke BM</div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={onClickBack}>
          <div className={Styles.text}>Kembali</div>
        </div>
      </div>
    </div>
  );
};

export default SentContractModal;
