import { API } from "@Config/api";

export function fetchPKSList(candidateID = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/ios-intregation/pks/${candidateID}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function injectToIOS(candidateID, pksID='', pksAlias='') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/ios-intregation/candidate/${candidateID}`,
        {
          pksID,
          pksAlias
        },
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function injectExtendToIOS(candidateID, pksID='', pksAlias='') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/ios-intregation/extend-candidate/${candidateID}`,
        {
          pksID,
          pksAlias
        },
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem('accessToken'),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
