import { makeRandomString } from '@Helpers/makeRandomString'
import InputField from '@Molecule/InputField'
import { candidateRecommendationApproval } from '@Services/officer/recruitment'
import Images from '@Theme/Images'
import { useMemo, useState } from 'react'
import Styles from './style.module.scss'

export default function RecommendationConfirmationModal({
  type,
  onClose = () => { },
  setTrigger = () => { },
  data,
  isV2 = false
}) {
  const [loading, setLoading] = useState(false)
  const [rejectionNote, setRejectionNote] = useState('')

  const handleAction = async () => {
    try {
      setLoading(true)
      await candidateRecommendationApproval(data?.id, type === 'accept' ? 'Accepted' : 'Declined', type === 'reject' ? rejectionNote : '')
      setTrigger(makeRandomString(5))
      onClose()
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const disableSubmit = useMemo(() => {
    if (loading || (type === 'reject' && !rejectionNote)) {
      return true
    } return false
  }, [loading, rejectionNote, type])

  const content = useMemo(() => {
    switch (type) {
      case 'reject':
        return (
          <div className={Styles.rejectContent}>
            <h3>Alasan Reject</h3>
            <p>Tulis catatan anda untuk memberikan informasi ke kami</p>
            <InputField
              type={'desc'}
              title={'Catatan'}
              value={rejectionNote}
              onChange={(e) => setRejectionNote(e?.target?.value)}
              placeholder={'Masukan catatan disini'}
            />
          </div>
        )
      default:
        return (
          <div className={Styles.acceptContent}>
            <img src={Images.RESULT_ACCEPTED_ICON} alt='' />
            <h3>Accept</h3>
            <p>Apakah anda yakin kandidat ini lolos dan memenuhi kualifikasi yang anda butuhkan?</p>
          </div>
        )
    }
  }, [rejectionNote, type])

  if (type) {
    return (
      <div className={Styles.container}>
        {content}
        <div className={Styles.actions}>
          <button onClick={onClose}>Batal</button>
          <button
            disabled={disableSubmit}
            onClick={handleAction}
          >{type === 'reject' ? 'Kirim' : 'Lanjutkan'}</button>
        </div>
      </div>
    )
  }


  // return (
  //   <div className={Styles.oldContainer}>
  //     <h3>Konfirmasi</h3>
  //     <p>Apa kamu yakin untuk <span className={Styles[type]}>{type}</span> kandidat berikut ini?</p>
  //     <div className={Styles.profile}>
  //       <StackedProfile
  //         profilePicture={data?.profilePicture || Images.AVA_DEFAULT}
  //         title={data?.name}
  //       />
  //     </div>
  //     <div className={Styles.actions}>
  //       <button
  //         onClick={onClose}
  //         disabled={loading}
  //       >
  //         Kembali
  //       </button>
  //       <button disabled={loading} onClick={handleAction}>Ya, <span>{type}</span></button>
  //     </div>
  //   </div>
  // )
}