import Images from "@Theme/Images";
import Styles from "./style.module.scss";
import { useCallback, useMemo, useState } from "react";
import Icon from "@Atom/Icon";
import { candidateRecommendationApproval } from "@Services/officer/recruitment";
import { useLocation } from "react-router-dom";
import { makeRandomString } from "@Helpers/makeRandomString";

export default function CandidateDetailModal({
  candidate,
  setTrigger,
  noAction,
  additionalComponent,
}) {
  const url = candidate?.cvUrl;
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadCV = () => {
    setIsDownloading(true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"CV_" + candidate?.name}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .then(() => setIsDownloading(false));
  };

  const { pathname } = useLocation();

  const descTemplate = useMemo(() => {
    if (pathname?.includes("candidate-recomendation")) {
      return [
        {
          title: "Posisi",
          value: candidate?.position,
        },
        {
          title: "Email",
          value: candidate?.email,
        },
        {
          title: "Domisili",
          value: candidate?.domicile,
        },
        {
          title: "Alamat",
          value: candidate?.address,
        },
      ];
    }
    return [
      {
        title: "Posisi",
        value: candidate?.position,
      },
      {
        title: "Email",
        value: candidate?.email,
      },
      {
        title: "Nomor Handphone",
        value: candidate?.phoneNumber,
      },
      {
        title: "Domisili",
        value: candidate?.domicile,
      },
      {
        title: "Alamat",
        value: candidate?.address,
      },
    ];
  }, [candidate, pathname]);

  const handleAction = useCallback(
    async (type) => {
      try {
        await candidateRecommendationApproval(
          candidate?.id,
          type === "accept" ? "Accepted" : "Declined"
        );
        setTrigger(makeRandomString(5));
      } catch (err) {
        console.log(err);
      }
    },
    [candidate, setTrigger]
  );

  return (
    <div
      className={`${Styles.container} ${noAction ? Styles.extraPadding : ""}`}
    >
      <div className={Styles.topLineWrapper}>
        <div className={Styles.topLine} />
      </div>
      <div className={Styles.content}>
        <div className={Styles.profileWrapper}>
          <div className={Styles.profileCard}>
            <img
              src={candidate?.profilePicture || Images?.AVA_DEFAULT}
              alt=""
            />
            <span>{candidate?.name}</span>
          </div>
        </div>

        <div className={Styles.descWrapper}>
          {descTemplate?.map((el, i) => (
            <div key={i} className={Styles.desc}>
              <span>{el?.title}</span>
              <span>{el?.value}</span>
            </div>
          ))}
          {!noAction && url && (
            <button disabled={isDownloading} onClick={handleDownloadCV}>
              <span>{isDownloading ? "Downloading CV" : "CV"}</span>
              <Icon icon="download" size={20} />
            </button>
          )}
        </div>
        {!noAction &&
          (candidate?.invitationTag?.toLowerCase() === "declined" ? (
            <div className={`${Styles.actionsWrapper} ${Styles.full}`}>
              {candidate?.rejectionNote ? (
                <button className={Styles.rejectedButton}>
                  <span>Rejected</span>
                  <Icon icon={"cross"} size={20} />
                </button>
              ) : (
                <button className={Styles.rejected}>Tidak Hadir</button>
              )}
            </div>
          ) : !candidate?.tag ? (
            // candidate?.invitationTag?.toLowerCase() === 'accepted'
            //   ?
            <div className={Styles.actionsWrapper}>
              <button onClick={() => handleAction("reject")}>
                <Icon icon="cross" size={20} />
              </button>
              <button onClick={() => handleAction("accept")}>
                <Icon icon="check" size={20} />
              </button>
            </div>
          ) : (
            // :
            // <div className={Styles.actionsWrapper}>
            //   <button className={Styles.noresponse}>Belum Konfirmasi Kehadiran</button>
            // </div>

            <div className={Styles.actionsWrapper}>
              {candidate?.tag === "REJECTED" ? (
                <button className={Styles.rejectedButton}>
                  <span>Rejected</span>
                  <Icon icon={"cross"} size={20} />
                </button>
              ) : (
                <button className={Styles.acceptedButton}>
                  <span>Accepted</span>
                  <Icon icon={"check"} size={20} />
                </button>
              )}
            </div>
          ))}

        {additionalComponent && additionalComponent}
      </div>
    </div>
  );
}
