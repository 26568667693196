import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import { useMemo } from 'react'

export default function CompletionIndicatorIcons({
  times = 0
}) {
  const timesToMap = useMemo(() => {
    const arr = []
    for (let i = 0; i < times; i++) {
      arr.push('')
    }
    return arr
  }, [times])
  return (
    <div className={Styles.container}>
      {timesToMap?.map((time, i) => (
        <Icon key={i} icon="check-circle" size={20} color={'#27BB83'} />
      ))}
    </div>
  )
}