import { API } from "@Config/api";

export function getTipsList(limit, page, sort, startDate, endDate, status, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/tips?limit=${limit}&page=${page}&sort=${sort}&startDate=${startDate}&endDate=${endDate}&status=${status}&search=${search}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}