import ChatLayout from '@Organism/Recruiter/Chat'
import Styles from './style.module.scss'
import { Helmet } from 'react-helmet-async'
import UnderMaintenacePage from '@Organism/Utils/UnderMaintenance'

export default function ChatPage() {
  const underMaintenance = true

  if (underMaintenance) {
    return <UnderMaintenacePage />
  }
  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Chat</title>
      </Helmet>
      <ChatLayout />
    </div>
  )
}