import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import useQuery from "@Hooks/useQuery";
import EditCompanyInfoModal from "@Molecule/_modal/EditCompanyInfo";
import EditDocumentsModal from "@Molecule/_modal/EditDocumentsModal";
import { getClientDetail } from "@Services/manager/client";
import Images from "@Theme/Images";
import { Avatar } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import CandidateListSection from "./CandidateList";
import ViewDetailCandidateModal from "./CandidateList/ViewEmployeeModal";
import ContactPersonSection from "./ContactPerson";
import DocumentSection from "./DocumentsSection";
import InformationContact from "./InformationContact";
import AddContactInfoModal from "./InformationContact/AddInformationContact";
import ManPowerSection from "./ManPowerSection";
import ViewDetailModal from "./ManPowerSection/ViewDetailModal";
import ProfileSection from "./ProfileSection";
import WorkSchemeSection from "./WorkSchemes";
import Styles from "./style.module.scss";
import OrdersSection from "./Orders";
import { useDebounce } from "@Hooks/useDebounce";
import NewManPowerSection from "./NewManPowerSection";
import FLBHistoryModal from "@Molecule/_modal/FLBHistoryModal";

export default function ClientDetailLayout({
  defaultData,
  // cities,
  activeTab = "",
  type = "companyInfo",
  value = 10,
  setValue = () => {},
  selectedPage = 1,
  setSelectedPage = () => {},
  search = "",
  setSearch = () => {},
  verify = "no",
  setVerify = () => {},
  rbmView = false,
  sync,
  state,
}) {
  const [selectedFLB, setSelectedFLB] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [data, setData] = useState(defaultData);
  const query = useQuery();
  const code = query.get("c");
  const name = query.get("n");
  const navigate = useNavigate();

  const [editInfoMode, setEditInfoMode] = useState(false);

  const [cName, setCName] = useState("");
  const [cValue, setCValue] = useState(10);
  const [selectedCPage, setSelectedCPage] = useState(1);

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [selectedFLBForLog, setSelectedFLBForLog] = useState(null);

  const [trigger, setTrigger] = useState(null);

  const debouncedSearch = useDebounce(search, 300);

  // eslint-disable-next-line eqeqeq
  const isClientService = query.get("tt") == 2;

  const handlePagination = useCallback(async () => {
    try {
      const { response } = await getClientDetail(
        code,
        value,
        selectedPage,
        cValue,
        selectedCPage,
        cName,
        type,
        debouncedSearch,
        verify
      );
      setData(response);
    } catch (err) {
      console.log(err);
    }
  }, [
    code,
    value,
    selectedPage,
    cValue,
    selectedCPage,
    cName,
    type,
    debouncedSearch,
    verify,
  ]);

  // const memoizedManpowerOptions = useMemo(() => {
  //   const manPowers = data?.manPowersChart;
  //   return manPowers?.map((obj) => obj);
  // }, [data]);

  // useEffect(() => {
  //   // setManPowerOptions(memoizedManpowerOptions);
  // }, [memoizedManpowerOptions]);

  const manPowerOrderData = useMemo(() => {
    const allOrders = data?.manPowersTable?.data;
    const arr = [];

    for (let i = 0; i < allOrders?.length; i++) {
      const job = allOrders[i];
      const each = {
        id: job?.id,
        position: job?.name,
        requestAmount: job?.amount,
        contractedAmount: job?.hired,
        remainingAmount: +job?.amount - +job?.hired,
        contractedPercentage: 0,
        remainingPercentage: 100,
        invited: 0,
        interviewed: 0,
        onClient: 0,
        requestDate: job?.createdAt,
        fulfillmentTarget: job?.expectedFulfillmentDate,
        details: job,
      };
      arr.push(each);
    }
    return arr;
  }, [data]);

  const manPowerChartData = useMemo(() => {
    const allOrders = data?.manPowersChart;

    const arr = [];

    for (let i = 0; i < allOrders?.length; i++) {
      const job = allOrders[i];
      const each = {
        position: job?.name,
        requestAmount: job?.amount,
        pipeline: job?.pipeline,
        pipelinePercentage: 100 - (+job?.hired / +job?.amount) * 100,
        contractedAmount: job?.hired,
        remainingAmount: +job?.amount - +job?.hired,
        contractedPercentage: (+job?.hired / +job?.amount) * 100,
        remainingPercentage: 100 - (+job?.hired / +job?.amount) * 100,
        invited: 0,
        interviewed: 0,
        onClient: 0,
        requestDate: job?.createdAt,
        fulfillmentTarget: job?.expectedFulfillmentDate,
        details: job,
      };
      arr.push(each);
    }
    return arr;
  }, [data]);

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [editDocMode, setEditDocMode] = useState(false);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedPosition !== null) {
      if (boxOutsideClick === false) {
        setSelectedPosition(null);
      }
    }
    if (editInfoMode) {
      if (boxOutsideClick === false) {
        setEditInfoMode(false);
      }
    }
    if (selectedCandidate) {
      if (boxOutsideClick === false) {
        setSelectedCandidate(null);
      }
    }
  }, [boxOutsideClick, selectedPosition, editInfoMode, selectedCandidate]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const initial = useMemo(() => {
    if (
      data?.client?.name?.slice(0, 2)?.toLowerCase() === "cv" ||
      data?.client?.name?.slice(0, 2)?.toLowerCase() === "pt"
    ) {
      if (data?.client?.name[3] !== " ") {
        return data?.client?.name[3];
      } else {
        return data?.client?.name[4];
      }
    }
    return data?.client?.name[0];
  }, [data]);

  useEffect(() => {
    handlePagination();
    // eslint-disable-next-line
  }, [handlePagination, trigger]);

  const [files, setFiles] = useState([
    {
      type: "AKTA CLIENT",
      title: "Akta Perusahaan",
      file: null,
      url: null,
      preview: null,
      name: null,
    },
    {
      type: "NPWP CLIENT",
      title: "NPWP",
      file: null,
      url: null,
      preview: null,
      name: null,
    },
    {
      type: "SIUP CLIENT",
      title: "SIUP",
      file: null,
      url: null,
      preview: null,
      name: null,
    },
    {
      type: "DOMISILI CLIENT",
      title: "Domisili",
      file: null,
      url: null,
      preview: null,
      name: null,
    },
    // {
    //   type: "DOKUMEN SPK/PKS/WO/PO",
    //   title: "Dokumen SPK/PKS/WO/PO",
    //   file: null,
    //   url: null,
    //   preview: null,
    //   name: null,
    // },
  ]);

  const defaultDocuments = useMemo(() => {
    const arr = [];

    for (let i = 0; i < files.length; i++) {
      let temp;
      for (let j = 0; j < data?.client?.documents?.length; j++) {
        const isMatchToType = Boolean(
          data?.client?.documents[j]?.type?.toUpperCase() ===
            files[i]?.type?.toUpperCase()
        );

        if (isMatchToType) {
          temp = data?.client?.documents[j] || null;
        }
      }
      arr.push(temp);
    }
    return arr;
  }, [files, data]);

  const activeContent = useMemo(() => {
    switch (activeTab) {
      case "companyInfo":
        return (
          <div className={Styles.infoSection}>
            <ProfileSection
              data={data?.client}
              setEditInfoMode={setEditInfoMode}
              isClientService={isClientService}
            />
            <DocumentSection
              defaultDocuments={defaultDocuments}
              files={files}
              setTrigger={setTrigger}
              setEditMode={setEditDocMode}
              documents={data?.client?.documents}
              holderCode={data?.code}
              holderName={data?.name}
              isClientService={isClientService}
            />
          </div>
        );
      case "flb":
        return (
          <div className={Styles.ordersWrapper}>
            <OrdersSection
              data={data?.flb?.data}
              totalPage={data?.flb?.totalPage || 0}
              selectedPage={selectedCPage}
              setSelectedPage={setSelectedCPage}
              limit={value}
              setLimit={setValue}
              search={search}
              setSearch={setSearch}
              setSelectedFLB={setSelectedPage}
              setSelectedFLBForLog={setSelectedFLBForLog}
              selectedPageFLB={selectedPage}
              verify={verify}
              setVerify={setVerify}
              rbmView={rbmView}
              isClientService={isClientService}
              // totalPage={t}
              state={state}
            />
          </div>
        );
      case "mp":
        return (
          // <div className={Styles.mpWrapper}>
          <NewManPowerSection
            manPowerChartData={manPowerChartData}
            manPowerTableData={data?.manPowersTable?.data}
            selectedPageFLB={selectedPage}
            setSelectedPageFLB={setSelectedPage}
            limit={value}
            setLimit={setValue}
            search={search}
            setSearch={setSearch}
            totalPage={data?.manPowersTable?.totalPage}
            flbData={data?.flb?.data}
            flbTotalPage={data?.flb?.totalPage}
            orderData={[]}
            selectedFLB={selectedFLB}
            setSelectedFLB={setSelectedFLB}
            setSelectedPosition={setSelectedPosition}
          />
          // </div>
        );
      case "contact":
        return (
          <div className={Styles.informationContactWrapper}>
            <InformationContact
              setTrigger={setTrigger}
              data={data?.contact}
              setData={setData}
              setShowAdd={setShowAdd}
              setShowEdit={setShowEdit}
              setSelectedData={setSelectedData}
              rbmView={rbmView}
            />
          </div>
        );
      case "delegation":
        return (
          <div className={Styles.delegationWrapper}>
            <div className={Styles.contactPerson}>
              <ContactPersonSection data={data?.allDelegated} />
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }, [
    activeTab,
    data?.allDelegated,
    data?.client,
    data?.code,
    data?.contact,
    data?.flb?.data,
    data?.flb?.totalPage,
    data?.manPowersTable?.data,
    data?.manPowersTable?.totalPage,
    data?.name,
    defaultDocuments,
    files,
    isClientService,
    manPowerChartData,
    rbmView,
    search,
    selectedCPage,
    selectedFLB,
    selectedPage,
    setSearch,
    setSelectedPage,
    setValue,
    setVerify,
    value,
    verify,
  ]);

  const isNew = true;

  if (isNew) {
    return (
      <div className={Styles.container}>
        <div className={Styles.detailHeader}>
          <img src={Images.CLIENT_HEADER} alt="building" />
          {!selectedFLB ? (
            <div className={Styles.headerItems}>
              <div className={Styles.defaultLogo}>
                <Avatar className={Styles.initial}>{initial}</Avatar>
              </div>
              <div className={Styles.headerDesc}>
                <span>{data?.client?.name}</span>
                <p>
                  {data?.client?.phoneNumber} •{" "}
                  {data?.client?.email || "no email"}
                </p>
                <div
                  className={
                    query?.get("tt") === "1" ? Styles.owner : Styles.service
                  }
                >
                  {query?.get("tt") === "1" ? "My Client" : "Service Client"}
                </div>
              </div>
              {!rbmView && !isClientService && (
                <button
                  className={Styles.addButton}
                  onClick={() =>
                    navigate(`/clients/add-new-order?n=${name}&c=${code}`)
                  }
                >
                  <Icon icon={"add-circle"} size={20} />
                  <span>Tambah FLB</span>
                </button>
              )}
            </div>
          ) : (
            <div className={Styles.headerItems}>
              <button
                className={Styles.backButton}
                onClick={() => setSelectedFLB(null)}
              >
                <Icon icon={"arrow-left"} size={20} />
                <span>Kembali</span>
              </button>
            </div>
          )}
        </div>
        <div className={Styles.newContent}>{activeContent}</div>

        <div
          className={`${Styles.modalWrapperV2} ${
            windowSize[0] > 768 || !isMobile ? Styles.desktop : ""
          } ${editInfoMode ? Styles.showModal : Styles.hideModal}`}
        >
          <EditCompanyInfoModal
            data={data}
            setShow={setEditInfoMode}
            setTrigger={setTrigger}
            industries={sync?.industries || []}
          />
        </div>

        {showAdd && (
          <AddContactInfoModal
            setTrigger={setTrigger}
            clientName={data?.name}
            clientCode={data?.code}
            setShowAdd={setShowAdd}
          />
        )}

        {showEdit && (
          <AddContactInfoModal
            setTrigger={setTrigger}
            setShowAdd={setShowEdit}
            editMode={true}
            defaultValue={selectedData}
            clientCode={data?.code}
            clientName={data?.name}
          />
        )}

        {editDocMode && (
          <EditDocumentsModal
            type="desktop"
            files={files}
            setFiles={setFiles}
            defaultDocuments={defaultDocuments}
            setShow={setEditDocMode}
            setTrigger={setTrigger}
          />
        )}
        <div
          className={`${Styles.modalWrapper} ${
            selectedCandidate ? Styles.showModal : Styles.hideModal
          }`}
        >
          <ViewDetailCandidateModal
            data={selectedCandidate}
            setData={setSelectedCandidate}
          />
        </div>

        {selectedFLBForLog && (
          <FLBHistoryModal
            selectedFLB={selectedFLBForLog}
            onClose={() => setSelectedFLBForLog(null)}
          />
        )}

        <div
          ref={boxRef}
          className={`${Styles.overlay} ${
            selectedPosition || editInfoMode || selectedCandidate
              ? Styles.visible
              : ""
          }`}
        />
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.detailHeader}>
        <img src={Images.CLIENT_HEADER} alt="building" />
        <div className={Styles.headerItems}>
          <div className={Styles.defaultLogo}>
            <Avatar className={Styles.initial}>{initial}</Avatar>
          </div>
          <div className={Styles.headerDesc}>
            <span>{data?.client?.name}</span>
            <p>
              {data?.client?.phoneNumber} • {data?.client?.email || "no email"}
            </p>
          </div>
          <button
            className={Styles.addButton}
            onClick={() =>
              navigate(`/clients/add-new-order?n=${name}&c=${code}`)
            }
          >
            <Icon icon={"add-circle"} size={20} />
            <span>Add Order</span>
          </button>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.contentContainer}>
          <div className={Styles.profileSection}>
            <ProfileSection
              data={data?.client}
              setEditInfoMode={setEditInfoMode}
            />
          </div>
          {data?.client?.orders?.length > 0 && (
            <div className={Styles.chartSection}>
              <WorkSchemeSection
                data={manPowerChartData}
                // cities={cities}
                orderData={data?.client?.orders}
                windowSize={windowSize}
              />
            </div>
          )}

          {manPowerOrderData?.length > 0 && (
            <div className={Styles.manPowerSection}>
              <ManPowerSection
                general={data}
                data={manPowerOrderData}
                setSelectedPosition={setSelectedPosition}
                handlePagination={handlePagination}
                trigger={trigger}
                value={value}
                setValue={setValue}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </div>
          )}

          <CandidateListSection
            data={data}
            setCValue={setCValue}
            setSelectedCPage={setSelectedCPage}
            setSelectedCandidate={setSelectedCandidate}
            setCName={setCName}
          />

          <div className={Styles.informationContact}>
            <InformationContact
              setTrigger={setTrigger}
              data={data?.client?.contacts}
              setData={setData}
              setShowAdd={setShowAdd}
              setShowEdit={setShowEdit}
              setSelectedData={setSelectedData}
            />
          </div>
          <div className={Styles.documentSection}>
            <DocumentSection
              defaultDocuments={defaultDocuments}
              files={files}
              setTrigger={setTrigger}
              setEditMode={setEditDocMode}
              documents={data?.client?.documents}
              holderCode={data?.code}
              holderName={data?.name}
            />
          </div>
          {data?.client?.delegation?.length > 0 && (
            <div className={Styles.contactPerson}>
              <ContactPersonSection data={data?.client?.delegation} />
            </div>
          )}
        </div>
      </div>
      <div
      // className={`${Styles.modalWrapper} ${selectedPosition ? Styles.showModal : Styles.hideModal
      //   }`}
      >
        <ViewDetailModal
          data={selectedPosition}
          setData={setSelectedPosition}
        />
      </div>
      <div
        className={`${Styles.modalWrapperV2} ${
          windowSize[0] > 768 || !isMobile ? Styles.desktop : ""
        } ${editInfoMode ? Styles.showModal : Styles.hideModal}`}
      >
        <EditCompanyInfoModal
          data={data}
          setShow={setEditInfoMode}
          setTrigger={setTrigger}
          industries={sync?.industries || []}
        />
      </div>

      {showAdd && (
        <AddContactInfoModal
          setTrigger={setTrigger}
          clientName={data?.name}
          clientCode={data?.code}
          setShowAdd={setShowAdd}
        />
      )}

      {showEdit && (
        <AddContactInfoModal
          setTrigger={setTrigger}
          setShowAdd={setShowEdit}
          editMode={true}
          defaultValue={selectedData}
          clientCode={data?.code}
          clientName={data?.name}
        />
      )}

      {editDocMode && (
        <EditDocumentsModal
          type="desktop"
          files={files}
          setFiles={setFiles}
          defaultDocuments={defaultDocuments}
          setShow={setEditDocMode}
          setTrigger={setTrigger}
        />
      )}
      <div
        className={`${Styles.modalWrapper} ${
          selectedCandidate ? Styles.showModal : Styles.hideModal
        }`}
      >
        <ViewDetailCandidateModal
          data={selectedCandidate}
          setData={setSelectedCandidate}
        />
      </div>

      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedPosition || editInfoMode || selectedCandidate
            ? Styles.visible
            : ""
        }`}
      />
    </div>
  );
}
