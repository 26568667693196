import { useMemo } from "react";
import Styles from "./styles.module.scss";
import { Tooltip } from "@mui/material";
import moment from "moment";

export default function DetailJobs({ data }) {
  const startIndex = 0;
  const endIndex = 1;
  const maxLengthAddress = 28;

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function changeFormatPlacement(str) {
    return str.replaceAll("::", ", ");
  }

  const slicedItems = useMemo(() => {
    if (data?.placement?.length > 1) {
      return data?.placement?.slice(endIndex);
    }
  }, [data?.placement]);

  const selectedItems = useMemo(() => {
    if (data?.placement?.length > 0) {
      return data?.placement?.slice(startIndex, endIndex);
    }
    return [];
  }, [data?.placement]);

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }
  return (
    <div className={Styles.container}>
      <div>
        <span>Posisi</span>
        <span>{data?.title?.toUpperCase() || "-"}</span>
      </div>

      <div>
        <span>Lokasi</span>
        {Array.isArray(data?.placement) ? (
          <Tooltip
            title={
              <span>
                {toTitleCase(
                  changeFormatPlacement(
                    data?.placement?.map((item) => item.city).join(", ")
                  )
                ) || "-"}
              </span>
            }
          >
            <div className={Styles.placement}>
              <span>
                {selectedItems?.length > 0
                  ? selectedItems.map((item) => item.city).join(", ")?.length >
                    maxLengthAddress
                    ? toTitleCase(
                        changeFormatPlacement(
                          selectedItems.map((item) => item.city).join(", ")
                        )
                      )?.substring(0, maxLengthAddress) + "..."
                    : toTitleCase(
                        changeFormatPlacement(
                          selectedItems.map((item) => item.city).join(", ")
                        )
                      )
                  : "-"}
              </span>

              {data?.placement?.length > 1 ? (
                <span className={Styles.slice}>+{slicedItems?.length}</span>
              ) : null}
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <span>{toTitleCase(changeFormatPlacement(data?.placement))}</span>
            }
          >
            <span>
              {" "}
              {data?.placement
                ? data?.placement.length > maxLengthAddress
                  ? toTitleCase(
                      changeFormatPlacement(data?.placement)
                    ).substring(0, maxLengthAddress) + "..."
                  : toTitleCase(changeFormatPlacement(data?.placement))
                : "-"}
            </span>
          </Tooltip>
        )}
      </div>
      <div>
        <span>Tipe Kontrak</span>
        <span>{data?.contractType || "-"}</span>
      </div>
      <div>
        <span>Range Gaji</span>
        <span>
          Rp
          {data?.salary?.split("-")?.map((s, is, arr) => (
            <span key={is}>
              {formatNumber(
                +s
                  ?.replace(".", ",")
                  .replace(/\d{3}(?=(\d{3})*,)/g, function (s) {
                    return "." + s;
                  })
              )}{" "}
              {arr.length > 1 && is === 0 ? " - " : is !== 0 ? " /bln" : "/bln"}
            </span>
          ))}
        </span>
      </div>

      <div>
        <span>Jumlah Kebutuhan</span>
        <span>{data?.amount || "-"} TKO</span>
      </div>
      <div>
        <span>Target tanggal pemenuhan dari klien</span>
        <span>
          {data?.fullFillmentDate
            ? moment(new Date(data?.fullFillmentDate)).format("DD MMMM YYYY")
            : "-"}
        </span>
      </div>

      <div>
        <span>Kriteria</span>
        {data?.requirements?.length > 0 ? (
          <div>
            {data?.requirements?.map((el, index) => {
              return (
                <div key={index}>
                  <div className={Styles.circle}></div>
                  <span>{el?.value || "-"}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
}
