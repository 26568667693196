import Icon from "@Atom/Icon";
import Breadcrumb from "@Molecule/BreadCrumbs";
import AddClientLayout from "@Organism/Manager/Client/AddClient";
import { addClientSync } from "@Services/manager/client";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { Suspense } from "react";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const search = url.searchParams.get("n") || "";
  const packageAddClientSyncPromise = addClientSync(search);

  return defer({
    packageAddClientSync: packageAddClientSyncPromise,
  });

}
export default function AddNewClient() {
  const { packageSync } = useLoaderData()
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate(`/clients`);
  };

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Add New Client</title>
      </Helmet>
      <div className={Styles.breadcrumbWrapper}>
        <span onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
        </span>
        <Breadcrumb>
          <span onClick={handleClickBack} className={Styles.breadcrumbText}>
            Client
          </span>
          <span className={Styles.breadcrumbText2}>New Client</span>
        </Breadcrumb>
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageSync}
            errorElement={
              <AsyncErrorBoundary />
            }
          >
            {(packageSync) => (
              <AddClientLayout
                sync={packageSync?.response}
              />
            )}

          </Await>
        </Suspense>
        {/* <AddClientLayout /> */}
      </div>
    </div>
  );
}
