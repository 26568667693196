import Icon from "@Atom/Icon";
import UseQuery from "@Hooks/useQuery";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import RecruitmentDetailLayout from "@Organism/Recruiter/Recruitment/RecruitmentDetail";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import {
  getInvitationSync,
  getRecruitmentSync,
} from "@Services/officer/recruitment";
import { Suspense, useState } from "react";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import LoadingAnimation from "@Atom/LoadingAnimation";
import useWindowSize from "@Hooks/useWindowSize";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const code = url.searchParams.get("c");
  const limit = url.searchParams.get("l") || 5;
  const page = url.searchParams.get("p") || 1;
  const query = `limit=${limit}&page=${page}&status=RO-INVITE`;

  const recruitmentSyncPromise = getRecruitmentSync(code, query);
  const invitationSyncPromise = getInvitationSync("");
  return defer({
    packageRecruitmentSync: recruitmentSyncPromise,
    packageInvitationSync: invitationSyncPromise,
  });
}

export default function RecruitmentDetail() {
  const { packageRecruitmentSync, packageInvitationSync } = useLoaderData();

  const query = UseQuery();
  const navigate = useNavigate();

  const name = query.get("n");
  const code = query.get("c");

  const handleClickBack = () => {
    navigate(`/rekrutmen`);
  };

  const { width } = useWindowSize();

  const [offeringMode, setOfferingMode] = useState(false);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Recruitment Details</title>
      </Helmet>
      <div className={Styles.breadcrumbWrapper}>
        <span onClick={handleClickBack}>
          <Icon
            icon="arrow-left-rounded-primary"
            color="#0a0a0a"
            size={width <= 480 ? 16 : 20}
          />
        </span>
        {!offeringMode ? (
          <CustomBreadCrumb>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>
              Rekrutmen
            </span>
            <span className={Styles.breadcrumbText2}>Detail Lamaran</span>
          </CustomBreadCrumb>
        ) : (
          <CustomBreadCrumb>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>
              Rekrutmen
            </span>
            <span
              className={
                offeringMode ? Styles.breadcrumbText : Styles.breadcrumbText2
              }
            >
              Detail Lamaran
            </span>
            <span className={Styles.breadcrumbText2}>Kirim Offering</span>
          </CustomBreadCrumb>
        )}
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageRecruitmentSync}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageRecruitmentSync) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await
                  resolve={packageInvitationSync}
                  errorElement={
                    <div className={Styles.errorWrapper}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageInvitationSync) => (
                    <RecruitmentDetailLayout
                      name={name}
                      code={code}
                      roSource={[]}
                      clientSource={[]}
                      offeringSource={[]}
                      defaultSyncData={packageRecruitmentSync?.response}
                      invitationSyncData={packageInvitationSync?.response}
                      offeringMode={offeringMode}
                      setOfferingMode={setOfferingMode}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
