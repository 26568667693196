/* eslint-disable no-unused-vars */
import SearchBar from "@Atom/SearchBar";
import Styles from "./style.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Checkbox } from "@mui/material";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";

const Desktop = (props) => {
  const {
    searchTerm,
    onChange,
    isSelected,
    handleSelectedQualification,
    onCancel,
    onAdd,
    qualifications,
  } = props;

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h4>Add Qualification</h4>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={onChange}
              placeholder="Search Qualification"
            />
          </div>
        </div>
        <div className={Styles.content}>
          {qualifications?.filter((el) =>
            el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
          )?.length > 0 ? (
            <>
              {qualifications
                ?.filter((el) =>
                  el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                ?.map((each, i) => {
                  const isItemSelected = Boolean(isSelected(each));
                  return (
                    <div
                      key={i}
                      className={Styles.each}
                      onClick={() => handleSelectedQualification(each)}
                    >
                      <label>{each?.name}</label>
                      <Checkbox
                        checked={isItemSelected}
                        sx={{ margin: 0, padding: 0, zIndex: 0 }}
                        icon={
                          <Icon
                            icon="radio-button-default"
                            size={20}
                            className={Styles.unchecked}
                          />
                        }
                        checkedIcon={
                          <Icon
                            icon="radio-button-active"
                            size={20}
                            className={Styles.checked}
                          />
                        }
                      />
                    </div>
                  );
                })}
            </>
          ) : (
            <div className={Styles.noOption}>No such location</div>
          )}
        </div>
        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel}>
            <span>Cancel</span>
          </button>
          <button onClick={onAdd}>
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const Mobile = (props) => {
  const {
    isSelected,
    handleSelectedQualification,
    onCancel,
    onAdd,
    qualifications,
  } = props;

  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Add Qualification</h3>
        </div>
      </div>
      <div className={Styles.content}>
        {qualifications?.map((each, i) => {
          const isItemSelected = Boolean(isSelected(each));
          return (
            <div
              key={i}
              className={Styles.each}
              onClick={() => handleSelectedQualification(each)}
            >
              <label>{each?.name}</label>
              <Checkbox
                checked={isItemSelected}
                sx={{ margin: 0, padding: 0, zIndex: 0 }}
                icon={
                  <Icon
                    icon="radio-button-default"
                    size={20}
                    className={Styles.unchecked}
                  />
                }
                checkedIcon={
                  <Icon
                    icon="radio-button-active"
                    size={20}
                    className={Styles.checked}
                  />
                }
              />
            </div>
          );
        })}
      </div>
      <div className={Styles.actionsWrapper}>
        <button onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button onClick={onAdd}>
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};

export default function AddQualificationModal(props) {
  const {
    type,
    setShowAdd,
    form,
    setForm,
    locationIndex,
    manPowerIndex,
    qualifications,
    trigger,
    setTrigger,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [qualificationArr, setQualificationArr] = useState([]);

  useEffect(() => {
    setQualificationArr(
      form[locationIndex]?.jobs[manPowerIndex]?.qualifications
    );
  }, [form, locationIndex, manPowerIndex, trigger]);

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedQualification = (option) => {
    const data = [...qualificationArr];
    const isExist = data?.find((item) => item?.name === option?.name);

    if (!isExist) {
      data.push({ ...option });
    } else {
      const index = data?.findIndex((object) => {
        return object.name === option?.name;
      });
      data.splice(index, 1);
    }

    setQualificationArr(data);
  };

  const onCancel = () => {
    setShowAdd(false);
  };

  const onAdd = () => {
    form[locationIndex].jobs[manPowerIndex].qualifications = qualificationArr;
    setShowAdd(false);
  };

  const isSelected = (option) =>
    qualificationArr?.find((item) => item?.name === option?.name);
  switch (type) {
    case "mobile":
      return (
        <Mobile
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedQualification={handleSelectedQualification}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          qualifications={qualifications}
        />
      );
    case "desktop":
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedQualification={handleSelectedQualification}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          qualifications={qualifications}
        />
      );
    default:
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedQualification={handleSelectedQualification}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          qualifications={qualifications}
        />
      );
  }
}

export const AddQualificationModalV2 = ({
  show = false,
  setShow = () => { },
  options = [],
  // values = [],
  setValues = () => { }

}) => {
  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState('')

  console.log(options, 'ini opsi')

  useEffect(() => {
    setSelected([])
  }, [])

  const getFound = useCallback((c) => {
    return selected?.find(obj => obj?.name === c?.name) ? true : false
  }, [selected])


  const onSubmit = () => {
    setValues(selected)
    setShow(false)
  }

  const sortedOptions = useMemo(() => {
    const lainnya = options?.find(obj => obj?.name === 'Lainnya')
    console.log(lainnya, 'la')
    return lainnya ? [...options?.filter(obj => obj?.name !== 'Lainnya')?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)), lainnya] : options?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }, [options])


  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={Styles.v2Container}>
        <div className={Styles.header}>
          <h3>Add Qualification</h3>
          <div className={Styles.searchWrapper}>
            <SearchBar
              value={search}
              placeholder={'Search qualification'}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
        </div>
        <div className={Styles.content}>
          {sortedOptions
            ?.filter(obj => obj?.name?.toLowerCase()?.includes(search?.toLowerCase()))
            ?.map((c, cI) => {
              const found = getFound(c)
              return (
                <div key={cI} className={Styles.city} >
                  <CheckboxJobPosting
                    checked={found}
                    onChange={(e) => {
                      if (found) {
                        setSelected(selected.filter(obj => obj?.id?.toLowerCase() !== c?.id?.toLowerCase()))
                      } else {
                        setSelected([...selected, c])
                      }
                    }}
                  />
                  <span
                    onClick={() => {
                      if (found) {
                        setSelected(selected.filter(obj => obj?.id !== c?.id))
                      } else {
                        setSelected([...selected, c])
                      }
                    }}>{c?.name}</span>
                </div>)
            })
          }
        </div>
        <div className={Styles.actions}>
          <button onClick={() => setShow(false)}>Cancel</button>
          <button disabled={!selected?.length} onClick={onSubmit}>Add</button>
        </div>
      </div>
    </ModalWrapper>
  )
}
