import TipsAndTrickArticleDetail from "@Organism/Recruiter/TipsAndTrickArticleDetail";
import TipsAndTrickVideoDetail from "@Organism/Recruiter/TipsAndTrickVideoDetail";

const TipsAndTrickDetailPage = ({ type, ...props }) => {
  // console.log(type, "TYPE");

  if (type === "article") {
    return (
      <>
        <TipsAndTrickArticleDetail />
      </>
    );
  }

  return (
    <>
      <TipsAndTrickVideoDetail />
    </>
  );
};

export default TipsAndTrickDetailPage;
