import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import debounce from "lodash.debounce";
import SearchBar from "@Atom/SearchBar";

export default function PipelineListController({ searchTerm, setSearchTerm }) {
  const [temp, setTemp] = useState();

  const onChange = useCallback(() => {
    setSearchTerm(temp);
  }, [setSearchTerm, temp]);

  const debouncedChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debouncedChange();
  }, [debouncedChange]);

  return (
    <div className={Styles.container}>
      <div className={Styles.searchBarWrapper}>
        <SearchBar
          placeholder={"Search client name"}
          value={temp}
          onChange={(e) => setTemp(e.target.value)}
        />
      </div>
    </div>
  );
}
