
import Icon from "@Atom/Icon";
import DocumentCard from "@Molecule/DocumentCard";
import Styles from "./style.module.scss";


export default function DocumentSection({ defaultDocuments, files, setEditMode, isClientService }) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Company Documents</h3>
        {
          !isClientService
          &&
          <button onClick={() => setEditMode(true)}>
            <Icon icon="edit" size={17} />
            <span>Update & Edit</span>
          </button>
        }
      </div>
      <div className={Styles.content}>
        {defaultDocuments?.map((each, i) => {
          return (
            <DocumentCard
              key={i}
              title={files[i]?.title}
              document={each}
            />
          )
        })}
      </div>
    </div>
  );
}


