import checkIcon from "@Assets/Images/check-mark.png";
import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import CustomTable from "@Molecule/CustomTable";
import moment from "moment";
import UpdateContractModal from "@Molecule/_modal/UpdateContractModal";
import Images from "@Theme/Images";
import { makeRandomString } from "@Helpers/makeRandomString";
import { fileBaseUrl } from "@Config/api";
import ModalWrapper from "@Atom/ModalWrapper";
import DetailRemarkModal from "@Molecule/_modal/DetailRemarkModal";

export default function FirstStep(props) {
  const {
    types,
    onChange,
    form,
    onNext,
    contracts = [],
    setContracts = () => {},
    alertMessage = "",
  } = props;
  const [showAddNewContracts, setShowAddNewContracts] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [showRemark, setShowRemark] = useState(null);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const columns = [
    {
      name: "docNumber",
      label: "Document Number",
    },
    {
      name: "docType",
      label: "Document Type",
    },
    {
      name: "uploadedAt",
      label: "Upload Date & Time",
      renderData: (row) => (
        <span>
          {moment(row?.uploadedAt)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "startDate",
      label: "Start Date",
      renderData: (row) => (
        <span>
          {moment(row?.startDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "endDate",
      label: "End Date",
      renderData: (row) => (
        <span>
          {moment(row?.endDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "attachment",
      label: "Attachment",
      renderData: (row) => (
        <span
          className={`${Styles.seeFile} ${!row?.file && Styles.disabled}`}
          onClick={() => {
            if (row?.file) {
              window
                .open(
                  row?.file instanceof File
                    ? URL.createObjectURL(row?.file)
                    : fileBaseUrl + row?.file,
                  "_blank"
                )
                .focus();
            }
          }}
        >
          Lihat File
        </span>
      ),
    },
    {
      name: "remark",
      label: "Catatan",
      renderData: (row) => (
        <div
          className={`${Styles.remarkRow} ${!row?.remark && Styles.disabled}`}
          onClick={() => {
            if (row?.remark) {
              setShowRemark(row);
            }
          }}
        >
          {row?.remark ? "Lihat Catatan" : "-"}
        </div>
      ),
    },
    {
      name: "",
      label: "",
      renderData: (row) => (
        <div className={Styles.rowActions}>
          <button
            className={Styles.deleteButton}
            onClick={() => {
              setSelectedContract(row);
              setShowAddNewContracts(true);
            }}
          >
            <Icon icon={"edit"} size={20} color={"#1571DE"} />
          </button>
          <button
            className={Styles.deleteButton}
            onClick={() =>
              setContracts(contracts?.filter((obj) => obj?.id !== row?.id))
            }
          >
            <Icon icon={"trash"} size={20} color={"#FF3E13"} />
          </button>
        </div>
      ),
    },
  ];

  // console.log(selectedContract, "tasya", "test");

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.header}>
        <h3>
          Choose Contract Type<span className={Styles.asterisk}>&#x2a;</span>
        </h3>
        <span>Pilih tipe kontrak sesuai kebutuhan anda</span>
      </div>
      <div className={Styles.content}>
        {types.map((type, it) => {
          const isType = Boolean(type?.title === form?.contract);
          return (
            <div
              key={it}
              onClick={() => onChange(type)}
              className={`${Styles.card} ${isType && Styles.active}`}
            >
              <div
                className={`${Styles.iconWrapper} ${
                  isType ? Styles.selected : Styles.notSelected
                }`}
              >
                <Icon
                  icon="check-circle"
                  size={25}
                  className={Styles.checkIcon}
                />
              </div>
              <div className={Styles.cardHeader}>
                <h4>{type?.title}</h4>
                <span>{type?.label}</span>
              </div>
              <div className={Styles.cardContent}>
                {type?.rules?.map((rule, ir) => {
                  return (
                    <div key={ir} className={Styles.Rule}>
                      <img src={checkIcon} alt="check" />
                      <p>{rule?.desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`${Styles.contracts} ${
          alertMessage && !contracts?.length ? Styles.errorBorder : ""
        }`}
      >
        <div className={Styles.cHeader}>
          <h3>Dokumen Legal</h3>
          {!!contracts?.length && (
            <button
              className={Styles.contractButton}
              onClick={() => setShowAddNewContracts(true)}
            >
              Update Dokumen
            </button>
          )}
        </div>
        <div className={Styles.table}>
          <CustomTable
            redBorder={
              alertMessage?.includes("Mohon untuk memasukkan attachment") ||
              alertMessage?.includes(
                "Mohon untuk memasukkan document number"
              ) ||
              alertMessage?.includes("Mohon untuk memasukkan document type")
            }
            noPagination
            minHeight="250px"
            data={contracts}
            columns={columns}
            oddRowClassName={Styles.odd}
            customEmptyHandler={
              <div className={Styles.empty}>
                <img src={Images.TIME_MANAGEMENT} alt="" />
                <div>
                  <span>Belum Ada Dokumen Ditambahkan</span>
                  <p>
                    Anda belum menambahkan dokumen legal untuk kebutuhan orderan
                    klien.
                  </p>
                </div>
                <button
                  className={Styles.contractButton}
                  onClick={() => setShowAddNewContracts(true)}
                >
                  Contract Update
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className={Styles.buttonWrapper}>
        <button onClick={onNext}>Next</button>
      </div>
      {showAddNewContracts && (
        <UpdateContractModal
          show={showAddNewContracts}
          defaultValue={selectedContract}
          onClose={() => {
            setShowAddNewContracts(false);
            setSelectedContract(null);
          }}
          onAdd={({
            docType,
            docNumber,
            startDate,
            endDate,
            file,
            preview,
            remark,
          }) => {
            if (!selectedContract) {
              setContracts([
                ...contracts,
                {
                  id: makeRandomString(5),
                  docType,
                  docNumber,
                  startDate,
                  endDate,
                  file,
                  uploadedAt: new Date(),
                  preview,
                  remark,
                },
              ]);
            } else {
              setContracts(
                contracts?.map((obj) => {
                  if (obj?.id === selectedContract?.id) {
                    return {
                      ...obj,
                      docType,
                      docNumber,
                      startDate,
                      endDate,
                      file,
                      preview,
                      remark,
                    };
                  }
                  return obj;
                })
              );
            }
          }}
        />
      )}

      <ModalWrapper show={showRemark} handleClose={() => {}}>
        <DetailRemarkModal
          data={showRemark}
          handleClose={() => setShowRemark(null)}
        />
      </ModalWrapper>
    </div>
  );
}
