import { API } from "@Config/api";

export function fetchContracts(page, limit, search = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/contracts?p=${page}&limit=${limit}&name=${search}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchExpiringContracts(page, limit, search = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/contracts/expiring-soon`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
        params: { page, limit, search }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function fetchExpiringContractsToContinue(page, limit, search = '') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/ios-employee`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
        params: { page, limit, search }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}