import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getInvitationSync,
  sendInvitation,
} from "@Services/officer/recruitment";
import Dropdown from "@Atom/DropDown";
import InputDate from "@Atom/InputDate";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import Input from "@Atom/Input";
import Textarea from "@Atom/Textarea";
import moment from "moment";

export default function InvitationModal({
  type,
  handleClose,
  showInterviewRO,
  refetchDataCandidate,
  // setSelectedPage,
  dataOptions,
  setSuccessModal,
}) {
  // const [isLoadingData, setIsLoadingData] = useState(false);
  //   const [dataOptions, setDataOptions] = useState(null);
  const [valueTypeInterview, setValueTypeInterview] = useState("");
  const [valueDate, setValueDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("");
  const [valueLinkOnline, setValueLinkOnline] = useState("");
  const [valueOptionAddress, setValueOptionAddress] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [buildingAddress, setBuildingAddress] = useState("");
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const timeRef = useRef();

  //   const refetchData = useCallback(async () => {
  //     try {
  //       setIsLoadingData(true);
  //       const { response } = await getInvitationSync();
  //       setDataOptions(response);
  //       setIsLoadingData(false);
  //     } catch (err) {
  //       setIsLoadingData(false);
  //       console.log(err);
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (showInterviewRO?.name === "RO") {
  //       refetchData();
  //     }
  //   }, [showInterviewRO?.name, refetchData]);

  const interviewOptions = useMemo(() => {
    if (dataOptions) {
      return dataOptions?.dropdownOptions
        ?.filter((obj) => obj?.type?.toLowerCase() === "interview method")
        ?.map((obj) => {
          return {
            name: obj?.value,
          };
        });
    }

    return [];
  }, [dataOptions]);

  const locationOptions = useMemo(() => {
    if (dataOptions) {
      return dataOptions?.dropdownOptions
        ?.filter((obj) =>
          obj?.type?.toLowerCase()?.includes("interview address")
        )
        ?.map((obj) => {
          return {
            name: obj?.value,
          };
        });
    }

    return [];
  }, [dataOptions]);

  const locationValue = useMemo(() => {
    if (dataOptions) {
      return dataOptions?.dropdownOptions
        ?.filter((obj) =>
          obj?.type?.toLowerCase()?.includes("interview address")
        )
        ?.map((obj) => obj?.value);
    }

    return [];
  }, [dataOptions]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      await sendInvitation({
        candidateID: showInterviewRO?.data?.candidateId,
        method: valueTypeInterview,
        date: moment(new Date(valueDate)).format("YYYY-MM-DD"),
        recruiterID: dataOptions?.recruitmentOfficer?.employeeId,
        via:
          valueTypeInterview?.toLowerCase() === "offline"
            ? valueOptionAddress?.toLowerCase()?.includes("custom")
              ? `${buildingName}::${buildingAddress}`
              : locationValue?.find((obj) =>
                  obj
                    ?.toLowerCase()
                    ?.includes(valueOptionAddress?.toLowerCase())
                )
            : valueLinkOnline,
        addressType: valueOptionAddress?.toLowerCase()?.includes("custom")
          ? "CUSTOM"
          : "NON-CUSTOM",
        time: interviewTime?.replace(":", "."),
        notes: remark || "",
        ordinal: 1,
      });
      setSubmitting(false);
      //   setTrigger(Math.random());
      //   setIsSuccess(true);
      setSuccessModal({
        name: "RO",
      });
      // setSelectedPage(1);
      handleClose();
      refetchDataCandidate();
    } catch (err) {
      setSubmitting(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setError("Check inputted data and make sure to fill all the fields");
      } else {
        setError(errorText);
      }
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <span>{showInterviewRO?.data?.name || "-"}</span>
          <Icon
            icon={"cross"}
            size={24}
            className={Styles.iconCross}
            onClick={handleClose}
          />
        </div>

        <div className={Styles.bottom}>
          {/* <div className={Styles.backButton}>
            <Icon icon={"arrow-left-back"} size={16} color={"#1571DE"} />
            <span>Kembali</span>
          </div> */}

          <span>Undangan Interview RO</span>
        </div>
      </div>

      <div className={Styles.contents}>
        <div className={Styles.profileCandidate}>
          <div className={Styles.profile}>
            <div>
              <img src={Images.INVITE_USER} alt="name" />
            </div>
            <div>
              <span>Kandidat</span>
              <span>{showInterviewRO?.data?.name || "-"}</span>
            </div>
          </div>

          <div className={Styles.profile}>
            <div>
              <img src={Images.INVITE_JOB} alt="name" />
            </div>
            <div>
              <span>Job</span>
              <span>{showInterviewRO?.data?.position || "-"}</span>
            </div>
          </div>
        </div>

        <div className={Styles.inputBox}>
          <div className={Styles.top}>
            <div>
              <span>Metode</span>
              <div className={Styles.dropdown}>
                <Dropdown
                  placeholder={"Pilih Metode"}
                  options={interviewOptions || []}
                  value={valueTypeInterview}
                  setValue={setValueTypeInterview}
                  autoClose
                />
              </div>
            </div>
            <div>
              <span>Tanggal & Waktu</span>
              <div className={Styles.dateInputBox}>
                <div>
                  <InputDate
                    // title={"Tanggal Lahir"}
                    placeholder={"Pilih tanggal"}
                    iconColor={"#1571DE"}
                    // value={form?.birthDate}
                    value={valueDate}
                    setValue={setValueDate}
                    required
                  />
                </div>
                <div>
                  <div className={Styles.inputWrapper}>
                    <input
                      className={`${!interviewTime ? Styles.empty : ""}`}
                      ref={timeRef}
                      type="time"
                      value={interviewTime}
                      onChange={(e) => setInterviewTime(e.target.value)}
                    />
                    <button
                      onClick={() => timeRef.current.showPicker()}
                      type="button"
                      className={Styles.endAdornment}
                    >
                      <Icon icon="time" size={20} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {valueTypeInterview ? (
            valueTypeInterview === "Offline" ? (
              <div className={Styles.second}>
                <span>Alamat</span>
                <AutocompleteV2
                  placeholder={"Pilih Lokasi"}
                  //   disabled={!dataListClient || !selectedFlb}
                  options={
                    locationOptions?.map((obj) => {
                      return {
                        name: obj?.name?.split("::")[0],
                      };
                    }) || []
                  }
                  value={valueOptionAddress}
                  setValue={setValueOptionAddress}
                  isRecruitment={true}
                />
                {valueOptionAddress === "Custom Address" ? (
                  <div className={Styles.customAddressBox}>
                    <Input
                      value={buildingName}
                      onChange={(e) => setBuildingName(e?.target?.value)}
                      placeholder={
                        "Nama tempat, contoh : Gedung Permata Indonesia"
                      }
                    />
                    <Textarea
                      value={buildingAddress}
                      onChange={(e) => setBuildingAddress(e?.target?.value)}
                      placeholder={"Alamat"}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className={Styles.second}>
                <span>Link</span>
                <div className={Styles.inputWrapper}>
                  <input
                    type="text"
                    value={valueLinkOnline}
                    onChange={(e) => setValueLinkOnline(e.target.value)}
                    placeholder="Paste link here"
                  />
                </div>
              </div>
            )
          ) : null}

          <div className={Styles.third}>
            <span>Catatan</span>
            <Textarea
              value={remark}
              onChange={(e) => setRemark(e?.target?.value)}
              placeholder={"Masukan catatan anda"}
            />
          </div>
        </div>
      </div>

      <div className={Styles.buttonSection}>
        <div>
          {error && <span className={Styles.error}>*{error}</span>}
          <div
            className={`${
              !valueDate ||
              submitting ||
              !interviewTime ||
              !valueTypeInterview ||
              (valueTypeInterview !== "Online" && !valueOptionAddress) ||
              (valueTypeInterview !== "Online" &&
                valueOptionAddress === "Custom Address" &&
                !buildingAddress) ||
              (valueTypeInterview !== "Online" &&
                valueOptionAddress === "Custom Address" &&
                !buildingName) ||
              (valueTypeInterview === "Online" && !valueLinkOnline)
                ? Styles.disabled
                : ""
            }`}
            onClick={
              !valueDate ||
              submitting ||
              !interviewTime ||
              !valueTypeInterview ||
              (valueTypeInterview !== "Online" && !valueOptionAddress) ||
              (valueTypeInterview !== "Online" &&
                valueOptionAddress === "Custom Address" &&
                !buildingAddress) ||
              (valueTypeInterview !== "Online" &&
                valueOptionAddress === "Custom Address" &&
                !buildingName) ||
              (valueTypeInterview === "Online" && !valueLinkOnline)
                ? null
                : handleSubmit
            }
          >
            {submitting ? "Loading..." : "Kirim"}
          </div>
        </div>
      </div>
    </div>
  );
}
