import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useRef } from "react";
import Styles from "./style.module.scss";

export default function PositionListModal({ data, setShow, handleChange }) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  //   const [selected, setSelected] = useState(null);

  const handleClose = () => {
    setShow(null);
  };

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxOutsideClick]);

  return (
    <div className={Styles.background}>
      <div className={Styles.container} ref={boxRef}>
        <div className={Styles.header}>
          <p>Daftar Posisi</p>
          <Icon icon={"cross"} size={24} onClick={handleClose} />
        </div>
        <div className={Styles.body}>
          <div className={Styles.listWrapper}>
            {data.map((el, idx) => (
              <div
                className={Styles.list}
                key={idx}
                onClick={() => handleChange(el)}
              >
                <p>{el.position}</p>
                <div>
                  <p>{el.amount}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
