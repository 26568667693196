/* eslint-disable eqeqeq */
import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss'
import useWindowSize from '@Hooks/useWindowSize';
import { DeleteButton, EmptyPlaceholder, LogoCard } from '../Third';
import Images from '@Theme/Images';
import { makeRandomString } from '@Helpers/makeRandomString';
import { Card, RightColumn } from '../Second';
import InputYearField from '@Molecule/InputYearField';
import InputField from '@Molecule/InputField';
import TextareaField from '@Molecule/TextAreaField';

export default function FourthStep({
  jobData = [],
  setJobData = () => { },
  stepperHeight = '',
  errorKeys = []
}) {

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const handleChangeJobData = (id, name, value) => {
    const newData = jobData?.map(obj => {
      if (obj?.id === id) {
        return {
          ...obj,
          [name]: value
        }
      }
      return obj
    })
    setJobData(newData)
  }

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const { width } = useWindowSize()

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef} style={{
        position: width > 768 ? 'sticky' : 'absolute',
        // top: `calc(${stepperHeight}px)`,
        top: 0
      }}>
        Data Pekerjaan
      </h3>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <LogoCard
            style={{
              // top: `calc(${headerHeight + stepperHeight}px)`,
              top: `calc(${headerHeight}px)`,
            }}
            img={Images.JOB_BANNER}
            addTitle={'Data Pekerjaan'}
            onAdd={() => {
              const newData = [...jobData, {
                id: makeRandomString(5),
                yearFrom: '',
                yearTo: '',
                companyName: '',
                jobTitle: '',
                salary: '',
                companyContact: '',
                description: ''
              }]
              setJobData(newData)
            }}
            headerHeight={headerHeight}
            stepperHeight={stepperHeight}
          />
        </div>
        <RightColumn className={Styles.right}>
          {!jobData?.length
            ?
            <EmptyPlaceholder
              title={'Belum ada Data Pekerjaan Ditambahkan'}
              desc={'Tambahkan data pekerjaan anda, klik pada tombol tambah data pekerjaan'}
              img={Images.JOB_BANNER}
            />
            :
            jobData?.map((ed, eI) => (
              <Card key={eI} className={`${Styles.card}`}>
                {
                  width <= 768
                  &&
                  <span>{`Data Pekerjaan ${eI + 1}`}</span>
                }
                <div className={Styles.grid}>
                  <InputYearField
                    title={'Dari Tahun'}
                    value={ed?.yearFrom}
                    setValue={(newVal) => handleChangeJobData(ed?.id, 'yearFrom', newVal)}
                    placeholder={"Pilih tahun"}
                    iconColor={"#1571DE"}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'yearFrom' && obj?.split('-')[1] == eI) && !ed?.yearFrom}
                    errorText='Tahun Mulai belum diisi'

                  />
                  <InputYearField
                    title={'Sampai Tahun'}
                    value={ed?.yearTo}
                    setValue={(newVal) => handleChangeJobData(ed?.id, 'yearTo', newVal)}
                    placeholder={"Pilih tahun"}
                    iconColor={"#1571DE"}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'yearTo' && obj?.split('-')[1] == eI) && !ed?.yearTo}
                    errorText='Tahun Selesai belum diisi'
                  />
                  <InputField
                    title={'Nama Perusahaan'}
                    value={ed?.companyName?.toUpperCase()}
                    onChange={(e) => handleChangeJobData(ed?.id, 'companyName', e?.target?.value)}
                    placeholder={'Nama perusahaan'}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'companyName' && obj?.split('-')[1] == eI) && !ed?.companyName}
                    errorText='Nama Perusahaan belum diisi'
                  />
                  <InputField
                    title={'Jabatan'}
                    value={ed?.jobTitle}
                    onChange={(e) => handleChangeJobData(ed?.id, 'jobTitle', e?.target?.value)}
                    placeholder={'Jabatan anda'}
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'jobTitle' && obj?.split('-')[1] == eI) && !ed?.jobTitle}
                    errorText='Jabatan belum diisi'
                  />
                  <InputField
                    title={'Gaji yang Diterima'}
                    value={ed?.salary || ''}
                    onChange={(newVal) => handleChangeJobData(ed?.id, 'salary', newVal)}
                    placeholder={'0'}
                    isCurrency
                    required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'salary' && obj?.split('-')[1] == eI) && !ed?.salary}
                    errorText='Gaji belum diisi'
                  />

                  <InputField
                    title={'Kontak Perusahaan (HRD/Nomor Perusahaan)'}
                    value={ed?.companyContact}
                    onChange={(e) => handleChangeJobData(ed?.id, 'companyContact', e?.target?.value)}
                    placeholder={'8xxx'}
                    floatingStartAdornment={<span className={Styles.adornment}>+62</span>}
                    // required
                    isError={errorKeys?.find(obj => obj?.split('-')[0] === 'companyContact' && obj?.split('-')[1] == eI) && !ed?.companyContact}
                    errorText='Kontak Perusahaan belum diisi'
                    onKeyDown={(e) => {
                      var key = e.which || e.keyCode;
                      const value = e.target.value;
                      if (!value?.length) {
                        if (key && key != 56) {
                          e.preventDefault()
                        }
                      } else {
                        if (key && (key <= 47 || key >= 58) && key != 8) {
                          if (key != 9) {
                            e.preventDefault();
                          }
                        }
  
                      }
                    }}
                    maxLength={12}
                  />
                </div>
                <TextareaField
                  title={'Deskripsi Pekerjaan'}
                  value={ed?.description?.toUpperCase()}
                  onChange={(e) => handleChangeJobData(ed?.id, 'description', e?.target?.value)}
                  placeholder={'Tulis deskripsi pekerjaan anda'}
                  // required
                  isError={errorKeys?.find(obj => obj?.split('-')[0] === 'description' && obj?.split('-')[1] == eI) && !ed?.description}
                  errorText='Deskripsi Pekerjaan belum diisi'
                />
                <DeleteButton
                  onDelete={() => setJobData(prev => prev?.filter(obj => obj?.id !== ed?.id))}
                />
              </Card>
            ))
          }
        </RightColumn>
      </div>

    </div>
  )
}