import React, { useState, useRef, useEffect, useCallback } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const SortSelect = ({ options, setValue, value, ...props }) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  // const [currentValue, setCurrentValue] = useState(null);

  const activateDropdown = () => {
    setIsActive(!isActive);
  };

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  const selectValue = (option) => {
    setValue(option);
    setIsActive(false);
    return null;
  };

  //   useEffect(() => {
  //     if (disabled) {
  //       setCurrentValue(null);
  //     }
  //   }, [disabled]);

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }`}
        onClick={activateDropdown}
      >
        <div className={Styles.LabelWrapper}>
          <Icon icon={value.label} size={20} />
          <p className={Styles.valueText}>{value?.name}</p>
        </div>
        {isActive ? (
          <Icon icon="arrow-up" size="20px" />
        ) : (
          <Icon icon="arrow-down" size="20px" />
        )}
      </div>
      {isActive ? (
        <div className={Styles.options}>
          {options?.map((option, id) => {
            return (
              <div className={Styles.optionWrapper} key={id}>
                <div
                  className={Styles.radioWrapper}
                  onClick={() => selectValue(option)}
                >
                  <div className={Styles.option}>{option?.name}</div>
                </div>
                {id === options?.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SortSelect;
