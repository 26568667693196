import ContractListLayout from '@Organism/BusinessSupport/Contracts/ContractList'
import Styles from './style.module.scss'

export default function ContractList() {

  return (
    <div className={Styles.container}>
      <ContractListLayout />
    </div>
  )
}