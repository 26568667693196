import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import {
  createTemplate,
  updateTemplate,
  duplicateTemplate,
} from "@Services/hc/contract";
import { useState } from "react";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import LoadingAnimation from "@Atom/LoadingAnimation";
import KONTRAK from "../../../../assets/Images/kontrak.png";

const RightSide = ({
  setModalOpen,
  contractData,
  type,
  id,
  setSuccessModal,
  setErrorModal,
  setErrorMessage,
  ...props
}) => {
  const [pdf, setPdf] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);

  const saveTemplate = async () => {
    try {
      let data;
      const payload = contractData;
      if (type === "PKM" || type === "PKWT" || type === "ADDITIONAL") {
        data = await createTemplate(payload);
      }
      if (data) {
        setSuccessModal(true);
        console.log(data, "<<<< DATA");
      }
    } catch (error) {
      setErrorModal(true);
      setErrorMessage("Kode kontrak klien sudah digunakan.");
      console.log(error, "<<<< ERROR");
    }
  };

  const updateTemplateFunction = async () => {
    try {
      const payload = contractData;
      const data = await updateTemplate(payload, id);

      if (data) {
        setSuccessModal(true);
        console.log(data, "<<<< DATA UPDATE DRAFT");
      }
    } catch (error) {
      setErrorModal(true);
      setErrorMessage("Kode kontrak klien sudah digunakan.");
      console.log(error, "<<<< ERROR UPDATE DRAFT");
    }
  };

  const duplicateTemplateFunction = async () => {
    const payload = {
      name: contractData?.name,
      contractCode: contractData?.contractCode,
      template: contractData?.template,
      clientContractID: contractData?.clientContractID,
    };

    try {
      const data = await duplicateTemplate(payload, id);

      if (data) {
        setSuccessModal(true);
        console.log(data, "<<<< DATA DUPLICATE DRAFT");
      }
    } catch (error) {
      setErrorModal(true);
      setErrorMessage("Kode kontrak klien sudah digunakan.");
      console.log(error, "<<<< ERROR DUPLICATE DRAFT");
    }
  };

  const saveAction = () => {
    if (type === "PKM" || type === "PKWT" || type === "ADDITIONAL") {
      return saveTemplate();
    }
    if (type === "UPDATE_CONTRACT" || type === "UPDATE_ADDITIONAL") {
      return updateTemplateFunction();
    }
    if (type === "DUPLICATE_CONTRACT") {
      return duplicateTemplateFunction();
    }
    return null;
  };

  const handlePreviewTemplate = async () => {
    setLoadingPdf(true);
    const pdfUrl = await convertHtmlToPdf(contractData.template, type);
    setPdf(pdfUrl);
    setLoadingPdf(false);
  };

  return (
    <div className={Styles.rightSide}>
      <div className={Styles.header}>
        <div className={Styles.secondaryButton} onClick={setModalOpen}>
          <div className={Styles.label}>Daftar Parameter</div>
          <Icon icon="list-unordered" size="20" className={Styles.icon} />
        </div>
        <div
          className={Styles.secondaryButton}
          onClick={() => handlePreviewTemplate()}
        >
          <div className={Styles.label}>Preview Template</div>
          <Icon icon="eye-visible" size="20" className={Styles.icon} />
        </div>
        <div className={Styles.primaryButton} onClick={saveAction}>
          <div className={Styles.label}>Simpan</div>
          <Icon icon="save" size="20" className={Styles.icon} />
        </div>
      </div>
      {!loadingPdf && !pdf && (
        <div className={Styles.noPdfWrapper}>
          <img src={KONTRAK} alt="" />
          <div className={Styles.textWrapper}>
            <div className={Styles.title}>Preview Template</div>
            <div className={Styles.information}>
              Silakan pilih tombol pratinjau di sisi atas untuk menampilkan pdf
              template.
            </div>
          </div>
        </div>
      )}
      {loadingPdf ? (
        <div className={Styles.loaderWrapper}>
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <div className={`${pdf ? Styles.embedWrapper : Styles.hidden}`}>
            <embed src={`${pdf}#navpanes=0`} type="application/pdf"></embed>
          </div>
        </>
      )}
    </div>
  );
};

export default RightSide;
