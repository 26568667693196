/* eslint-disable no-unused-vars */
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ProgressBar from "@Atom/ProgressBar";
import UploadBox from "@Atom/UploadBox";
import { useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./uploadCard.module.scss";
import ErrorBubble from "@Atom/ErrorBubble";

const SinglePDFFile = ({
  title,
  defaultPreview,
  defaultName,
  fileImage,
  fileName,
  uploading,
  submitting,
  isUploaded,
  placeholder,
  withInputText,
  showButton,
  onChange,
  onDrop,
  inputFileRef,
  onClickButton,
  alwaysDisabled,
  errorText = "",
  disabled = false,
  ...props
}) => {
  const onClick = () => {
    // inputFileRef.current.click();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
    maxSize: 1048576 * 5,
  });

  return (
    <div className={`${Styles.container} ${disabled && Styles.disabled}`}>
      <div className={Styles.titleWrapper}>
        <div className={Styles.title}>{title}</div>
        <div
          className={`${Styles.input} ${
            withInputText ? null : Styles.withoutInput
          }`}
        >
          <Input placeholder={placeholder} onChange={() => {}} />
        </div>
      </div>

      <div className={Styles.commandText}>Taruh file di kotak bawah ini</div>
      {disabled ? (
        <div className={Styles.uploadBoxWrapper}>
          <UploadBox
            onClick={onClick}
            fileName={fileName}
            fileImage={fileImage}
            defaultPreview={defaultPreview}
            defaultName={defaultName}
            uploading={uploading}
            isUploaded={isUploaded}
            isError={errorText}
            disabled={disabled}
          />
        </div>
      ) : (
        <div {...getRootProps()} className={Styles.uploadBoxWrapper}>
          <UploadBox
            onClick={onClick}
            fileName={fileName}
            fileImage={fileImage}
            defaultPreview={defaultPreview}
            defaultName={defaultName}
            uploading={uploading}
            isUploaded={isUploaded}
            isError={errorText}
          />
          <input
            {...getInputProps()}
            type="file"
            // ref={inputFileRef}
            name="file"
            // onChange={onChange}
            hidden
          />
        </div>
      )}

      {errorText && (
        <div className={Styles.errorWrapper}>
          <ErrorBubble errorText={errorText} />
        </div>
      )}
      {showButton && (
        <div className={Styles.buttonWrapper}>
          <div
            className={Styles.button}
            onClick={isUploaded ? onClickButton : () => {}}
          >
            <Button
              title={
                alwaysDisabled
                  ? isUploaded
                    ? "Uploaded"
                    : "Upload File"
                  : submitting
                  ? "Submitting..."
                  : "Submit"
              }
              disabled={
                alwaysDisabled ? true : !isUploaded || submitting ? true : false
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

const MultiplePDFFile = (props) => {
  const { files, setFiles, progressBar } = props;

  const inputFileRef = useRef();

  const onClick = () => {
    if (progressBar) {
      if (!isOnProgress) {
        // inputFileRef.current.click();
      }
    } else {
      // inputFileRef.current.click();
    }
  };

  const [errorFileReject, setErrorFileReject] = useState({});
  const onDrop = (acceptedFiles, fileRejections) => {
    if (progressBar) {
      if (!isOnProgress) {
        setRunning([...running, true]);
        setProgress([...progress, 0]);
        if (acceptedFiles.length > 0) {
          const newFiles = [...files];
          newFiles.push(acceptedFiles[0]);
          setFiles(newFiles);
        }
      }
    } else {
      if (acceptedFiles?.length > 0) {
        const newFiles = [...files];
        for (let i = 0; i < acceptedFiles?.length; i++) {
          newFiles.push(acceptedFiles[i]);
        }
        setFiles(newFiles);
        setErrorFileReject({});
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }
    }
  };

  const onChange = (e) => {
    if (progressBar) {
      if (!isOnProgress) {
        setRunning([...running, true]);
        setProgress([...progress, 0]);
        if (e.target.value) {
          const newFiles = [...files];
          newFiles.push(e.target.value);
          setFiles(newFiles);
        }
      }
    } else {
      if (e?.target?.input?.files?.length > 0) {
        const newFiles = [...files];
        newFiles.push(e?.target?.input?.files[0]);
        setFiles(newFiles);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: true,
    maxSize: 1048576 * 5,
  });

  const onDeleteFile = (index) => {
    const newFiles = [...files];
    if (progressBar) {
      const newRunning = [...running];
      const newProgress = [...progress];
      newRunning.splice(index, 1);
      newProgress.splice(index, 1);
      setRunning(newRunning);
      setProgress(newProgress);
    }
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const [running, setRunning] = useState([]);
  const [progress, setProgress] = useState([]);

  const handleRunning = (newVal, index) => {
    const newRunning = [...running];
    newRunning[index] = newVal;
    setRunning(newRunning);
  };
  const handleProgress = (newVal, index) => {
    const newProgress = [...progress];
    newProgress[index] = newVal;
    setProgress(newProgress);
  };

  const isOnProgress = useMemo(() => {
    const found = running.find((obj) => obj === true);
    if (found) {
      return true;
    } else {
      return false;
    }
  }, [running]);

  return (
    <div className={`${Styles.container} ${Styles.noPadding}`}>
      <div className={Styles.commandText}>Taruh file di kotak bawah ini</div>
      <div
        {...getRootProps()}
        className={`${Styles.uploadBoxWrapper} ${Styles.extraHeight}`}
      >
        <UploadBox
          type="light"
          onClick={onClick}
          uploading={false}
          isUploaded={false}
        />
        <input
          {...getInputProps()}
          disabled={progressBar ? isOnProgress : false}
          type="file"
          // ref={inputFileRef}
          name="file"
          // onChange={onChange}
          hidden
        />
      </div>
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : null}
      <div className={Styles.filesWrapper}>
        {files?.map((file, i) => {
          return (
            <div key={i} className={Styles.fileCard}>
              <div className={Styles.left}>
                <Icon icon="excel" size={22} className={Styles.fileIcon} />
                <div>
                  <span>{file?.name}</span>
                  {progressBar && running[i] && (
                    <ProgressBar
                      progress={progress[i]}
                      running={running[i]}
                      handleProgress={handleProgress}
                      handleRunning={handleRunning}
                      index={i}
                    />
                  )}
                </div>
              </div>
              <button disabled={isOnProgress} onClick={(e) => onDeleteFile(i)}>
                <Icon icon="cross-circle" size={22} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UploadCard = ({
  type,
  withUploadBar,
  title,
  defaultPreview,
  defaultName,
  fileImage,
  fileName,
  uploading,
  submitting,
  isUploaded,
  placeholder,
  withInputText,
  showButton,
  onChange,
  onDrop,
  inputFileRef,
  onClickButton,
  files,
  setFiles,
  alwaysDisabled,
  errorText = "",
  disabled = false,
  ...props
}) => {
  switch (type) {
    case "multiple-pdf":
      return (
        <MultiplePDFFile
          files={files}
          setFiles={setFiles}
          progressBar={withUploadBar}
          alwaysDisabled={alwaysDisabled}
        />
      );
    case "single-pdf":
      return (
        <SinglePDFFile
          title={title}
          defaultPreview={defaultPreview}
          defaultName={defaultName}
          fileImage={fileImage}
          fileName={fileName}
          uploading={uploading}
          submitting={submitting}
          isUploaded={isUploaded}
          placeholder={placeholder}
          withInputText={withInputText}
          showButton={showButton}
          onChange={onChange}
          onDrop={onDrop}
          inputFileRef={inputFileRef}
          onClickButton={onClickButton}
          alwaysDisabled={alwaysDisabled}
          errorText={errorText}
          disabled={disabled}
        />
      );
    default:
      return (
        <SinglePDFFile
          title={title}
          defaultPreview={defaultPreview}
          defaultName={defaultName}
          fileImage={fileImage}
          fileName={fileName}
          uploading={uploading}
          submitting={submitting}
          isUploaded={isUploaded}
          placeholder={placeholder}
          withInputText={withInputText}
          showButton={showButton}
          onChange={onChange}
          onDrop={onDrop}
          inputFileRef={inputFileRef}
          onClickButton={onClickButton}
          alwaysDisabled={alwaysDisabled}
          errorText={errorText}
          disabled={disabled}
        />
      );
  }
};

export default UploadCard;
