import { API } from "@Config/api";

export function getPipelines(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/pipeline?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPipelineOrder(clientCode, query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/pipeline/${clientCode}${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getPipelineOrderDetails(orderId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/update/${orderId}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateOrder(orderId, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/pipeline/order/${orderId}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}