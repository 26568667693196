import { useEffect, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import SearchBar from "@Atom/SearchBar";

export default function FilterFLBVerificationList({
  handleClose,
  data,
  isLoading,
  client,
  setClient,
  flb,
  setFLB,
  docNumber,
  setDocNumber,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const [valueClient, setValueClient] = useState("");
  const [valueFLB, setValueFLB] = useState("");
  const [valueDocNumber, setValueDocNumber] = useState("");

  useEffect(() => {
    setValueClient(client || "");
    setValueFLB(flb || "");
    setValueDocNumber(docNumber || "");
  }, [client, flb, docNumber]);

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
  }, [boxOutsideClick, handleClose]);

  const [error, setError] = useState("");

  const handleSave = () => {
    setError("");
    setClient(valueClient || "");
    setFLB(valueFLB || "");
    setDocNumber(valueDocNumber || "");
    handleClose();
  };

  // console.log(valueFLB);

  const handleInputChange = (e) => {
    const { value } = e.target;
    // Filter out non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");
    // Only update the state if the filtered value changes
    if (numericValue !== value) {
      e.target.value = numericValue; // Directly modify the input's value
    }
    setValueFLB(numericValue);
  };

  return (
    <div className={Styles.container}>
      <div ref={boxRef}>
        <div className={Styles.top}>
          <div className={Styles.header}>
            <span className={Styles.filter}>Filter</span>
            {client || flb || docNumber ? (
              <span
                className={Styles.reset}
                onClick={() => {
                  setClient("");
                  setFLB("");
                  setDocNumber("");
                  handleClose();
                }}
              >
                Reset
              </span>
            ) : null}
          </div>
          <div className={Styles.inputBox}>
            <span>Nama Klien</span>
            <div className={Styles.autoComplete}>
              <AutocompleteV2
                options={data?.clients?.map((el) => {
                  return {
                    name: el,
                  };
                })}
                placeholder={"Cari Nama Klien"}
                value={valueClient}
                setValue={setValueClient}
                isLoading={isLoading}
              />
            </div>
          </div>

          <div className={Styles.inputBox}>
            <span>Nomor FLB</span>
            <div className={Styles.searchBar}>
              <SearchBar
                placeholder={"Cari No FLB"}
                value={valueFLB}
                onChange={handleInputChange}
                type={"none"}
              />
            </div>
          </div>

          <div className={`${Styles.inputBox} ${Styles.last}`}>
            <span>Nomor Dokumen</span>
            <div className={Styles.autoComplete}>
              <AutocompleteV2
                options={data?.contracts?.map((el) => {
                  return {
                    name: el,
                  };
                })}
                placeholder={"Cari Nomor Dokumen"}
                value={valueDocNumber}
                setValue={setValueDocNumber}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>

        <div className={Styles.bottom}>
          {error && <span>*{error}</span>}
          <div
            onClick={
              !valueClient && !valueDocNumber && !valueFLB ? null : handleSave
            }
            className={`${
              !valueClient && !valueDocNumber && !valueFLB && Styles.disabled
            }`}
          >
            Terapkan
          </div>
        </div>
      </div>
    </div>
  );
}
