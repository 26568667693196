/* eslint-disable no-unused-vars */
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import FormErrorMessage from "@Atom/FormErrorMessage";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import useQuery from "@Hooks/useQuery";
import CustomTable from "@Molecule/CustomTable";
import InputField, { InputTitle } from "@Molecule/InputField";
import {
  addClientContact,
  getAllContactsByClientCode,
} from "@Services/manager/client";
import Images from "@Theme/Images";
import { Avatar } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { validateEmail } from "@Helpers/validateEmail";
import ErrorBubble from "@Atom/ErrorBubble";
import Rotation from "@Atom/Rotation";

export default function AddContactInfoModal({
  show,
  setShow,
  contacts = [],
  setContacts = () => {},
  clientContacts = [],
}) {
  const [tempContacts, setTempContacts] = useState(contacts);
  const [search, setSearch] = useState("");
  const query = useQuery();
  const [addMode, setAddMode] = useState(false);

  const [form, setForm] = useState({
    name: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    workUnit: "",
    clientCode: query?.get("c"),
  });

  const [workUnits, setWorkUnits] = useState([]);
  const [otherValue, setOtherValue] = useState("");

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (workUnits?.find((obj) => obj === "Lainnya")) {
      setOtherValue("");
    }
  }, [workUnits]);

  useEffect(() => {
    setShowAlert(false);
  }, [show, addMode]);

  useEffect(() => {
    if (workUnits?.find((obj) => obj === "Lainnya")) {
      handleChangeForm("workUnit", otherValue?.toUpperCase());
    } else {
      if (workUnits?.length === 2) {
        handleChangeForm("workUnit", "INVOICE & PAYROLL");
      } else {
        handleChangeForm("workUnit", workUnits[0]);
      }
    }
  }, [otherValue, workUnits]);

  const handleCheckWorkUnits = (newVal) => {
    if (!workUnits?.find((obj) => obj === newVal)) {
      if (!workUnits?.length) {
        setWorkUnits([newVal]);
      } else {
        if (workUnits?.find((obj) => obj === "Lainnya")) {
          setWorkUnits([newVal]);
        } else {
          if (newVal === "Lainnya") {
            setWorkUnits([newVal]);
          } else {
            setWorkUnits([...workUnits, newVal]);
          }
        }
      }
    } else {
      setWorkUnits(workUnits?.filter((obj) => obj !== newVal));
    }
  };

  const handleChangeForm = (name, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: name === "email" ? value : value?.toUpperCase(),
      };
    });
  };
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmiting] = useState(false);

  const [clientContactsData, setClientContactsData] = useState(clientContacts);

  const refetchContacts = useCallback(async () => {
    try {
      setIsLoading(true);
      const { response } = await getAllContactsByClientCode(query.get("c"));
      setClientContactsData(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err?.response?.data?.error || "Something went wrong");
    }
  }, [query]);

  const handleCheck = (row) => {
    if (tempContacts?.find((obj) => obj?.id === row?.id)) {
      setTempContacts(tempContacts?.filter((obj) => obj?.id !== row?.id));
    } else {
      setTempContacts([...tempContacts, row]);
    }
  };

  const handleAddNew = async (e) => {
    setIsSubmiting(true);
    try {
      e.preventDefault();
      let fixedForm = { ...form };
      fixedForm.phoneNumber = "62" + form.phoneNumber;
      await addClientContact(fixedForm);
      setIsSubmiting(false);
      setAddMode(false);
      refetchContacts();
      setForm({
        name: "",
        jobTitle: "",
        email: "",
        phoneNumber: "",
        workUnit: "",
        clientCode: query?.get("c"),
      });
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
      setIsSubmiting(false);
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      renderData: (row) => (
        <div
          className={Styles.contact}
          // onClick={() => handleCheck(row)}
        >
          <CheckboxJobPosting
            checked={tempContacts?.find((obj) => obj?.id === row?.id)}
            onChange={(e) => {
              handleCheck(row);
            }}
          />
          <Avatar
            onClick={() => handleCheck(row)}
            src={Images.AVA_DEFAULT}
            sx={{ width: 24, height: 24, objectFit: "cover" }}
          />
          <span onClick={() => handleCheck(row)}>{row?.name}</span>
        </div>
      ),
    },
    {
      name: "phoneNumber",
      label: "Phone",
    },
    {
      name: "workUnit",
      label: "Unit Kerja",
    },
  ];

  useEffect(() => {
    setTempContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    refetchContacts();
  }, [refetchContacts]);

  const alertFound = useMemo(() => {
    if (!addMode) {
      if (!tempContacts?.length) {
        return "Harap pilih kontak terlebih dahulu";
      }
    } else {
      if (
        !form?.name ||
        !form?.jobTitle ||
        !form?.email ||
        !form?.phoneNumber ||
        !form?.workUnit
      ) {
        return "Harap lengkapi form terlebih dahulu";
      }

      if (form?.phoneNumber && form?.phoneNumber?.length < 8) {
        return "Format nomor handphone tidak sesuai";
      }
    }
  }, [
    addMode,
    form?.email,
    form?.jobTitle,
    form?.name,
    form?.phoneNumber,
    form?.workUnit,
    tempContacts?.length,
  ]);

  const handleSubmit = (e) => {
    if (alertFound) {
      setShowAlert(true);
    } else {
      setShowAlert(false);

      if (!addMode) {
        setContacts(tempContacts);
        setShow(false);
      } else {
        handleAddNew(e);
      }
    }
  };

  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Add Contact Information</h3>
          <div>
            <span>{!addMode ? "Kontak Klien" : "Tambah Kontak Klien"}</span>
            {!addMode && (
              <button
                onClick={() => {
                  setAddMode(true);
                }}
              >
                <span>Tambah Kontak Klien</span>
                <Icon icon={"add"} size={20} />
              </button>
            )}
          </div>
        </div>
        {!addMode && (
          <div className={Styles.searchbarWrapper}>
            <OutlinedSearchBar
              placeholder={"Search"}
              value={search}
              setValue={setSearch}
            />
          </div>
        )}
        {!addMode ? (
          <CustomTable
            data={clientContactsData?.filter((obj) =>
              obj?.name?.toUpperCase()?.includes(search?.toUpperCase())
            )}
            columns={columns}
            // isLoading={isLoading}
            isLoadingSkeleton={isLoading}
            noPagination
            isError={showAlert && !tempContacts?.length}
          />
        ) : (
          <div className={Styles.form}>
            <InputField
              title={"Nama"}
              value={form?.name}
              onChange={(e) => handleChangeForm("name", e?.target?.value)}
              placeholder={"Tulis name"}
              isError={showAlert && alertFound && !form?.name}
              isErrorV2
              errorText="Nama belum diisi"
              required
            />
            <InputField
              title={"Posisi"}
              value={form?.jobTitle}
              onChange={(e) => handleChangeForm("jobTitle", e?.target?.value)}
              placeholder={"Tulis posisi"}
              isError={showAlert && alertFound && !form?.jobTitle}
              isErrorV2
              errorText="Posisi belum diisi"
              required
            />
            <InputField
              title={"Email"}
              value={form?.email}
              onChange={(e) => handleChangeForm("email", e?.target?.value)}
              placeholder={"Tulis email"}
              isError={
                showAlert &&
                alertFound &&
                (!form?.email || !validateEmail(form?.email))
              }
              isErrorV2
              errorText={
                !form?.email ? "Email belum diisi" : "Email tidak sesuai"
              }
              required
            />
            <InputField
              title={"Nomor Handphone"}
              value={form?.phoneNumber}
              onChange={(e) =>
                handleChangeForm("phoneNumber", e?.target?.value)
              }
              placeholder={"8xxx"}
              type={"phone"}
              isError={
                (showAlert &&
                  alertFound?.includes("Harap lengkapi form terlebih dahulu") &&
                  !form?.phoneNumber) ||
                (alertFound?.includes("Format nomor handphone tidak sesuai") &&
                  form?.phoneNumber?.length < 8)
              }
              isErrorV2
              errorText={
                alertFound?.includes("Format nomor handphone tidak sesuai")
                  ? "Format nomor handphone tidak sesuai"
                  : "Nomor handphone belum diisi"
              }
              required
            />
            <div className={Styles.workUnitChooser}>
              <InputTitle title={"Unit Kerja"} required />
              <div className={Styles.options}>
                {[
                  {
                    title: "Payroll",
                  },
                  {
                    title: "Invoice",
                  },
                  {
                    title: "Lainnya",
                  },
                ]?.map((t, i) => (
                  <div
                    key={i}
                    className={`${Styles.option} ${
                      showAlert && !workUnits?.length ? Styles.error : ""
                    }`}
                  >
                    <div
                      className={Styles.cover}
                      onClick={() => handleCheckWorkUnits(t?.title)}
                    />
                    <CheckboxJobPosting
                      checked={workUnits?.find((obj) => obj === t?.title)}
                      // onChange={() => handleCheckWorkUnits(t?.title)}
                    />
                    <span
                    //  onClick={() => handleCheckWorkUnits(t?.title)}
                    >
                      {t?.title}
                    </span>
                  </div>
                ))}
              </div>
              {showAlert && !workUnits?.length && (
                <div>
                  <ErrorBubble errorText="Unit kerja belum dipilih" />
                </div>
              )}
            </div>
            <div className={Styles.other}>
              <p>
                Definisikan Lainnya
                <span>isi bila "Lainnya" kamu contreng</span>
              </p>
              <Input
                value={otherValue}
                onChange={(e) => setOtherValue(e?.target?.value)}
                placeholder={"Deskripsi"}
                disabled={!workUnits?.find((obj) => obj === "Lainnya")}
                isError={
                  showAlert &&
                  alertFound &&
                  !form?.workUnit &&
                  workUnits[0] === "Lainnya"
                }
                isErrorV2
                errorText="Definisi belum diisi"
                required
              />
            </div>
          </div>
        )}
        <div className={Styles.errorWrapper}>
          <FormErrorMessage message={showAlert ? alertFound : ""} />
        </div>
        <div className={Styles.actions}>
          <button
            disabled={isSubmitting}
            onClick={() => (!addMode ? setShow(false) : setAddMode(false))}
          >
            Batalkan
          </button>
          <button
            disabled={isSubmitting}
            // disabled={!addMode ? !tempContacts?.length : (!allFilledObjChecker(form) || !validateEmail(form?.email))}
            // onClick={(e) => {
            //   if (!addMode) {
            //     setContacts(tempContacts)
            //     setShow(false)
            //   } else {
            //     handleAddNew(e)
            //   }
            // }}
            onClick={handleSubmit}
          >
            {!isSubmitting ? (
              !addMode ? (
                "Tambahkan"
              ) : (
                "Simpan"
              )
            ) : (
              <Rotation type="white" />
            )}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
