import Icon from "@Atom/Icon";
import AutocompleteField from "@Molecule/AutocompleteField";
import InputField from "@Molecule/InputField";
import InputYearField from "@Molecule/InputYearField";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function EducationData({ data, form, setForm, options, errorField }) {
  var index = useMemo(() => {
    return form.findIndex((x) => x?.id === data?.id);
  }, [data, form]);

  const [startingYearTemp, setStartingYearTemp] = useState(
    form[index]?.startingYear
  );
  const [finishingYearTemp, setFinishingYearTemp] = useState(
    form[index]?.finishingYear
  );
  const [educationTypeTemp, setEducationTypeTemp] = useState(
    form[index]?.educationType
  );

  useEffect(() => {
    setEducationTypeTemp(form[index]?.educationType);
    setStartingYearTemp(form[index]?.startingYear);
    setFinishingYearTemp(form[index]?.finishingYear);
  }, [form, index]);

  const handleChange = useCallback(
    (e, inputName) => {
      setForm([
        ...form?.slice(0, index),
        Object.assign({}, form[index], {
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        }),
        ...form?.slice(index + 1),
      ]);
    },
    [index, form, setForm]
  );

  useEffect(() => {
    setForm([
      ...form?.slice(0, index),
      Object.assign({}, form[index], {
        educationType: educationTypeTemp,
        startingYear: startingYearTemp,
        finishingYear: finishingYearTemp,
      }),
      ...form?.slice(index + 1),
    ]);

    // eslint-disable-next-line
  }, [index, educationTypeTemp, startingYearTemp, finishingYearTemp]);

  const handleDelete = useCallback(() => {
    const newForm = [...form];
    newForm?.splice(index, 1);
    setForm(newForm);
  }, [form, setForm, index]);

  return (
    <div className={Styles.container}>
      <h3>Data Pendidikan {index + 1}</h3>
      <div className={Styles.content}>
        <InputYearField
          title={"Dari Tahun"}
          placeholder={"Pilih tahun"}
          value={startingYearTemp}
          setValue={setStartingYearTemp}
          iconColor={"#1571DE"}
          isError={errorField && !startingYearTemp}
          errorText={"Tahun belum diisi"}
        />
        <InputYearField
          title={"Sampai Tahun"}
          placeholder={"Pilih tahun"}
          value={finishingYearTemp}
          setValue={setFinishingYearTemp}
          iconColor={"#1571DE"}
          isError={errorField && !finishingYearTemp}
          errorText={"Tahun belum diisi"}
        />
        <AutocompleteField
          title={"Jenis Pendidikan"}
          placeholder={"Jenis Pendidikan"}
          searchOptions={options?.educations}
          value={educationTypeTemp}
          setValue={setEducationTypeTemp}
          iconColor={"#1571DE"}
          isError={errorField && !educationTypeTemp}
          errorText={"Jenis Pendidikan belum diisi"}
        />
        <InputField
          title={"Nama Penyelenggara"}
          placeholder={"Penyelenggara"}
          value={data?.institution}
          onChange={(e) => handleChange(e, "institution")}
          isError={errorField && !data?.institution}
          errorText={"Penyelenggara belum diisi"}
        />
        <InputField
          title={"Fakultas/Jurusan"}
          placeholder={"Tulis nama fakultas/jurusan"}
          value={data?.faculty}
          onChange={(e) => handleChange(e, "faculty")}
          isError={errorField && !data?.faculty}
          errorText={"Fakultas/Jurusan belum diisi"}
        />
        <InputField
          title={"Nomor Ijazah"}
          placeholder={"000"}
          value={data?.certificateNumber}
          onChange={(e) => handleChange(e, "certificateNumber")}
          isError={errorField && !data?.certificateNumber}
          errorText={"Nomor belum diisi"}
        />
        <InputField
          title={"Kota"}
          placeholder={"Tulis nama kota"}
          value={data?.city}
          onChange={(e) => handleChange(e, "city")}
          isError={errorField && !data?.city}
          errorText={"Kota belum diisi"}
        />
      </div>
      <button onClick={handleDelete}>
        <Icon icon="delete" size={20} />
        <span>Hapus</span>
      </button>
    </div>
  );
}
