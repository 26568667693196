import Icon from '@Atom/Icon'
import Styles from './style.module.scss'
import defaultImage from '@Assets/Images/job-offer-online.png'

export default function DefaultManPower(props) {
  const { handleShowAddManPower, locationIndex } = props

  return (
    <div className={Styles.container}>
      <img src={defaultImage} alt="default" />
      <h4>Belum Ada Data yang Dimasukan</h4>
      <p>Masukan daftar pekerjaan yang dibutuhkan</p>
      <button className={Styles.desktop} onClick={()=> handleShowAddManPower(locationIndex, "desktop")}><Icon icon="circle-plus" size={17} /><span>Man Power</span></button>
      <button className={Styles.mobile} onClick={()=> handleShowAddManPower(locationIndex, "mobile")}><Icon icon="circle-plus" size={17} /><span>Man Power</span></button>
    </div>
  )
}