import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import WARNING from "@Assets/Images/exclamation.png";
import APPROVE from "@Assets/Images/approval.png";
import REJECT from "@Assets/Images/reject.png";
import { useNavigate } from "react-router-dom";
import Textarea from "@Atom/Textarea";

function Modal({ type, handleCloseModal, handleSubmit, errorStatus }) {
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
  }, []);

  const handleSubmitConfirmation = async (type) => {
    try {
      setIsSubmitting(true);
      await handleSubmit(type, type === "Declined" ? remark : "");
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      // setError(error?.response?.data?.error || "Something went wrong");
    }
  };

  switch (type) {
    case "confirm-approve":
      return (
        <div className={`${Styles.ModalWrapper} ${Styles.ConfirmModal}`}>
          <div className={`${Styles.ConfirmLogo} ${Styles.SuccessLogo}`}>
            <Icon
              icon="check-circle"
              size={32}
              className={Styles.SuccessLogo}
            />
          </div>
          <div className={Styles.ConfirmText}>
            <p>Konfirmasi Setujui Kontrak</p>
            <p>Apakah anda yakin menyetujui kontrak ini?</p>
          </div>

          <div className={Styles.bottom}>
            {errorStatus && (
              <span className={Styles.errorStatus}>{errorStatus}</span>
            )}
            <div className={Styles.ConfirmAction}>
              <Button
                type={"secondary-black"}
                title={<p className={Styles.ButtonText}>Batal</p>}
                onClick={() => handleCloseModal()}
              />
              <Button
                type={"primary"}
                disabled={isSubmitting}
                title={
                  <p className={Styles.ButtonText}>
                    {isSubmitting ? "Approving" : "Approve"}
                  </p>
                }
                onClick={() => handleSubmitConfirmation("Accepted")}
              />
            </div>
          </div>
        </div>
      );
    case "success-approve":
      return (
        <div className={`${Styles.ModalWrapper} ${Styles.SuccessModal}`}>
          <div className={Styles.SuccessLogo}>
            <img src={APPROVE} alt="approve" />
          </div>
          <div className={Styles.SuccessText}>
            <p>Kontrak Disetujui</p>
            <p>Kontrak kandidat berhasil disetujui</p>
          </div>
          <div className={Styles.SuccessAction}>
            <Button
              type={"secondary-blue"}
              title={<p className={Styles.ButtonText}>Kembali</p>}
              onClick={() => {
                navigate(".", { replace: true });
                handleCloseModal();
              }}
            />
          </div>
        </div>
      );
    case "confirm-reject":
      return (
        <div className={`${Styles.ModalWrapper} ${Styles.ConfirmModal}`}>
          <div className={`${Styles.ConfirmLogo} ${Styles.RejectLogo}`}>
            <img src={WARNING} alt="reject" />
          </div>
          <div className={Styles.ConfirmText}>
            <p>Konfirmasi Tolak Kontrak</p>
            <p>Apakah anda yakin menolak kontrak ini?</p>
          </div>
          <div className={Styles.remarkWrapper}>
            <Textarea
              value={remark}
              onChange={(e) => setRemark(e?.target?.value)}
              placeholder={"Tulis catatan anda"}
            />
          </div>

          <div className={Styles.bottom}>
            {errorStatus && (
              <span className={Styles.errorStatus}>{errorStatus}</span>
            )}
            <div className={Styles.ConfirmAction}>
              <Button
                type={"secondary-black"}
                title={<p className={Styles.ButtonText}>Batal</p>}
                onClick={() => handleCloseModal()}
              />
              <button
                className={Styles.primaryButton}
                onClick={() => handleSubmitConfirmation("Declined")}
                disabled={!remark || isSubmitting}
              >
                <div className={Styles.text}>
                  {isSubmitting ? "Rejecting..." : "Reject"}
                </div>
              </button>
            </div>
          </div>
        </div>
      );
    case "success-reject":
      return (
        <div className={`${Styles.ModalWrapper} ${Styles.SuccessModal}`}>
          <div className={Styles.SuccessLogo}>
            <img src={REJECT} alt="approve" />
          </div>
          <div className={Styles.SuccessText}>
            <p className={Styles.RejectText}>Kontrak Ditolak</p>
            <p>Kontrak kandidat berhasil ditolak</p>
          </div>
          <div className={Styles.SuccessAction}>
            <Button
              type={"secondary-blue"}
              title={<p className={Styles.ButtonText}>Kembali</p>}
              onClick={() => {
                navigate(".", { replace: true });
                handleCloseModal();
              }}
            />
          </div>
        </div>
      );
    default:
      return (
        <div className={`${Styles.ModalWrapper}`}>
          <p>Modal Not Found</p>
        </div>
      );
  }
}

export default function ContractViewModal({
  type,
  handleCloseModal,
  handleSubmit,
  errorStatus,
}) {
  return (
    <div className={Styles.Container}>
      <Modal
        type={type}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        errorStatus={errorStatus}
      />
    </div>
  );
}
