import Checkbox from '@Atom/Checkbox'
import Styles from './style.module.scss'

export default function CheckboxField({
  title,
  value,
  setValue
}) {

  return (
    <div className={Styles.container}>
      <Checkbox
        isChecked={value}
        setIsChecked={setValue}
      />
      <span>{title}</span>
    </div>
  )
}