/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import PICTURE_DEFAULT from "@Assets/Images/picture-default.png";
import { useDropzone } from "react-dropzone";
import Images from "@Theme/Images";
import useWindowSize from "@Hooks/useWindowSize";
import ErrorModal from "@Molecule/_modal/ErrorModal";

export default function VideosFiles({
  setFilesVideos,
  fileVideos,
  setPreviewVideos,
  previewVideos,
  setTypeVideos,
  typeVideos,
  setAcceptedFiles,
  validate,
}) {
  const { width } = useWindowSize();
  const [showImagesUpload, setShowImagesUpload] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [muted, setMuted] = useState(false);
  const [error, setError] = useState("");
  const [showerror, setShowError] = useState(false);
  const handleToggleMute = () => setMuted((current) => !current);
  const inputFileRef = useRef();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (width > 768) {
        if (acceptedFiles.length > 0) {
          setFilesVideos(acceptedFiles[0]);
        } else {
          setShowError(true);
          setError("Konten telah melebihi maximal size yang telah ditentukan");
          console.log("errr");
        }
      }
    },
    [setFilesVideos, width]
  );

  const onChangeResponsive = (e) => {
    const file = e.target.files[0];
    if (file?.size < 16777216) {
      setFilesVideos(file);

      // setShowError(false);
    } else {
      setShowError(true);
      setError("Konten telah melebihi maximal size yang telah ditentukan");
    }

    // console.log(e.target.files);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,

    accept: {
      "image/*": [],
      "video/*": [],
    },
    multiple: false,
    minSize: 0,
    maxSize: 16777216,
  });

  // 16777216
  // useEffect(() => {
  //   if (acceptedFiles[0]?.size > 16777216) {
  //     setShowError(true);
  //     setError("Konten telah melebihi minimal size yang telah ditentukan");
  //     console.log('errr');
  //   }
  // }, [acceptedFiles]);
  // console.log(acceptedFiles);

  const onChange = (e) => {
    if (e?.target?.input?.files?.length > 0) {
      setFilesVideos(e?.target?.input?.files[0]);
    }
  };

  useEffect(() => {
    if (fileVideos) {
      const objectUrl = URL.createObjectURL(fileVideos);
      setPreviewVideos(objectUrl);

      // return () => URL.revokeObjectURL(objectUrl)
    }
  }, [fileVideos, setPreviewVideos]);

  useEffect(() => {
    if (fileVideos) {
      if (fileVideos?.type?.includes("video")) {
        setTypeVideos("Personal Video");
      } else if (fileVideos?.type?.includes("image")) {
        setTypeVideos("Personal Image");
      }
    } else {
      setTypeVideos("");
    }
  }, [fileVideos, setTypeVideos]);

  // console.log(typeVideos, "iniiiiiiiiiii");

  const deleteImages = () => {
    setPreviewVideos(null);
    setFilesVideos(null);
    setTypeVideos("");
  };

  const [key, setKey] = useState(0);
  const handleFileDelete = () => {
    setPreviewVideos(null);
    setFilesVideos(null);
    setTypeVideos("");
    setKey((prevKey) => prevKey + 1);
    document.getElementById("fileInput").value = "";
  };

  const videoRef = useRef();

  const handlePlay = () => {
    videoRef.current.play();
    setPlayVideo(true);
  };

  const handlePause = () => {
    videoRef.current.pause();
    setPlayVideo(false);
  };

  useEffect(() => {
    if (fileVideos) {
      setAcceptedFiles(fileVideos?.type);
    }
  }, [fileVideos, setAcceptedFiles]);

  // const inputFileRef = useRef();
  // console.log(fileVideos, "ini test");

  return (
    <div className={Styles.rightVideoFiles}>
      <div className={Styles.textTitle}>
        <span className={Styles.textTop}>
          {" "}
          Upload Konten Berupa Gambar atau Video Untuk 365 Personal <span>*</span>
        </span>
        <div className={Styles.warningBox}>
          <div className={Styles.boxWarn}>
            <Icon icon={"information-solid"} size={20} color={"#1571DE"} />
            <span className={Styles.textWarn}>
              Unggah konten berupa gambar/video dengan format potrait, maximal
              size konten sebesar 16 MB
              {/* piksel Unggah video dalam format portrait, minimal size video 16MB */}
            </span>
          </div>
        </div>
      </div>
      <div className={Styles.uploadBox}>
        {previewVideos ? (
          <div>
            {fileVideos?.type.includes("image") ? (
              <div className={Styles.imagesPreview}>
                <img src={previewVideos} alt="" />
              </div>
            ) : (
              <div className={Styles.imagesPreview}>
                <video
                  src={previewVideos}
                  className={Styles.video}
                  controls
                  // ref={videoRef}
                  // muted={muted}
                  // controls
                ></video>
                {/* <div className={Styles.iconbox}>
                  <div
                    className={Styles.iconPlay}
                    onClick={playVideo === false ? handlePlay : handlePause}
                  >
                    {playVideo === false ? (
                      <Icon icon={"play"} size={20} color={"black"} />
                    ) : (
                      <Icon icon={"pause"} size={20} color={"black"} />
                    )}
                  </div>

                  <div onClick={handleToggleMute} className={Styles.iconPlay}>
                    {muted === false ? (
                      <Icon icon={"sound-on"} size={20} color={"black"} />
                    ) : (
                      <Icon icon={"sound-off"} size={20} color={"black"} />
                    )}
                  </div>
                </div> */}
              </div>
            )}
          </div>
        ) : (
          <div>
            {width <= 768 ? (
              <div
                className={`${Styles.middleText} ${
                  validate?.filesVideos && !fileVideos && Styles.redborder
                }`}
                onClick={() => inputFileRef?.current?.click()}
              >
                <input
                  type="file"
                  // accept="video/*"
                  key={key}
                  ref={inputFileRef}
                  name="file"
                  id="fileInput"
                  onChange={onChangeResponsive}
                  hidden
                  accept="image/*, video/*"
                />
                <div className={Styles.imagesBoxUpload}>
                  <img src={PICTURE_DEFAULT} alt="" />
                </div>
                <span className={Styles.dragAndDrop}>
                  Pilih file Foto/Video ke sini untuk upload
                </span>
                <span className={Styles.p}>pilih file dengan</span>
                <span className={Styles.route}>Klik di sini</span>
              </div>
            ) : (
              <div
                className={`${Styles.middleText} ${
                  validate?.filesVideos && !fileVideos && Styles.redborder
                }`}
                {...getRootProps()}
              >
                <input
                  {...getInputProps()}
                  type="file"
                  id="fileInput"
                  key={key}
                  name="file"
                  hidden
                />
                <div className={Styles.imagesBoxUpload}>
                  <img src={PICTURE_DEFAULT} alt="" />
                </div>
                <span className={Styles.dragAndDrop}>
                  Drag dan drop file Foto/Video ke sini untuk upload
                </span>
                <span className={Styles.p}>atau pilih file dengan</span>
                <span className={Styles.route}>Klik di sini</span>
              </div>
            )}
          </div>
        )}
      </div>

      {previewVideos ? (
        <div className={Styles.actionImages}>
          {width <= 768 ? (
            <div
              className={Styles.uploadAgain}
              onClick={() => inputFileRef?.current?.click()}
            >
              <input
                // {...getInputProps()}
                type="file"
                accept="image/*, video/*"
                ref={inputFileRef}
                name="file"
                onChange={onChangeResponsive}
                hidden
              />
              <Icon icon={"edit"} size={20} color={"#1571DE"} />
              <span className={Styles.routesEdit}>Ubah Konten</span>
            </div>
          ) : (
            <div className={Styles.uploadAgain} {...getRootProps()}>
              <input
                {...getInputProps()}
                type="file"
                // accept="video/*"
                // ref={inputFileRef}
                name="file"
                onChange={onChange}
                hidden
              />
              <Icon icon={"edit"} size={20} color={"#1571DE"} />
              <span className={Styles.routesEdit}>Ubah Konten</span>
            </div>
          )}

          <div className={Styles.deleteFiles} onClick={handleFileDelete}>
            <Icon icon={"trash"} size={20} color={"#FF3E13"} />
            <span className={Styles.deleteText}>Hapus</span>
          </div>
        </div>
      ) : null}

      {showerror && (
        <ErrorModal
          error={error}
          onBack={() => setShowError(false)}
          handleDone={() => setShowError(false)}
        />
      )}
    </div>
  );
}
