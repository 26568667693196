import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import UploadCard from "@Molecule/UploadCard";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

const PDFJS = require("pdfjs-dist/webpack");

export default function DocumentSection({
  errorFileReject = {},
  alert,
  onChange,
  items,
  uploadings,
  onDrop,
  onDrop0,
  onDrop1,
  onDrop2,
  onDrop3,
  onDrop4,
  type,
  existingType,
  client,
  errorFile0,
  errorFile1,
  errorFile2,
  errorFile3,
  errorFile4,
}) {
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();

  useEffect(() => {
    sessionStorage.setItem("clientDocuments", JSON.stringify(items));
  }, [items]);

  const defaultViewOnlyDocuments = useMemo(() => {
    return client?.documents?.map((obj) => {
      return {
        isLoading: true,
        type: obj?.type,
        format: obj?.format,
        url: fileBaseUrl + obj?.url,
      };
    });
  }, [client?.documents]);

  const [viewOnlyDocuments, setViewOnlyDocuments] = useState(
    [...Array(defaultViewOnlyDocuments?.length)]?.map((obj) => "")
  );

  useEffect(() => {
    const loadDefaultImages = async () => {
      const arr = [];
      const defaultCopy = [...defaultViewOnlyDocuments];
      for (let i = 0; i < defaultCopy.length; i++) {
        const images = [];
        if (defaultCopy[i]?.format === "pdf") {
          if (defaultCopy[i] === undefined) {
            arr.push({ ...defaultCopy[i], url: "" });
          } else {
            // setViewOnlyDocuments()
            // defaultCopy[i].isLoading = true
            // setViewOnlyDocuments(defaultCopy)
            const uri = defaultCopy[i].url;
            var pdf = await PDFJS.getDocument({ url: uri }).promise;
            const canvas = document.createElement("canvas");
            for (let i = 0; i < pdf.numPages; i++) {
              const page = await pdf.getPage(i + 1);
              const viewport = page.getViewport({ scale: 1 });
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              await page.render({ canvasContext: context, viewport: viewport })
                .promise;
              images.push(canvas.toDataURL());
            }
            canvas.remove();
            arr?.push(images[0]);
          }
        } else {
          arr.push(defaultCopy[i]?.url);
        }
      }

      setViewOnlyDocuments(arr);
    };
    if (defaultViewOnlyDocuments?.length) {
      loadDefaultImages();
    }
  }, [defaultViewOnlyDocuments]);

  useEffect(() => {
    setViewOnlyDocuments(
      [...Array(defaultViewOnlyDocuments?.length)]?.map((obj) => "")
    );
  }, [defaultViewOnlyDocuments]);

  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"doc"} size={20} />
      </div>
      <div className={Styles.header}>
        <h3>Company Documents</h3>
        <span className={alert ? Styles.alert : Styles.normal}>
          Harap lampirkan file (min 1)
        </span>
        {errorFileReject?.message ? (
          <span className={Styles.alert}>*{errorFileReject?.message}</span>
        ) : null}
      </div>
      {existingType !== "k365" ? (
        <div className={Styles.content}>
          <div className={Styles.divider}>
            <UploadCard
              inputFileRef={ref0}
              onDrop={onDrop0}
              onChange={(e) => onChange(e, 0)}
              fileImage={items[0]?.preview}
              fileName={items[0]?.name}
              uploading={uploadings[0]}
              isUploaded={items[0]?.preview}
              title="Akta Perusahaan"
              showButton={false}
              // errorText={E}
              errorText={errorFile0}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
            />
            <UploadCard
              inputFileRef={ref1}
              onDrop={onDrop1}
              onChange={(e) => onChange(e, 1)}
              fileImage={items[1]?.preview}
              fileName={items[1]?.name}
              uploading={uploadings[1]}
              isUploaded={items[1]?.preview}
              title="NPWP"
              // withInputText={true}
              placeholder="Nomor NPWP"
              showButton={false}
              errorText={errorFile1}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
            />
          </div>
          <div className={Styles.divider}>
            <UploadCard
              inputFileRef={ref2}
              onDrop={onDrop2}
              onChange={(e) => onChange(e, 2)}
              fileImage={items[2]?.preview}
              fileName={items[2]?.name}
              uploading={uploadings[2]}
              isUploaded={items[2]?.preview}
              title="SIUP"
              showButton={false}
              errorText={errorFile2}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
            />
            <UploadCard
              inputFileRef={ref3}
              onDrop={onDrop3}
              onChange={(e) => onChange(e, 3)}
              fileImage={items[3]?.preview}
              fileName={items[3]?.name}
              uploading={uploadings[3]}
              isUploaded={items[3]?.preview}
              title="Domisili"
              showButton={false}
              errorText={errorFile3}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
            />
          </div>
          {/* <div className={Styles.divider}>
            <UploadCard
              inputFileRef={ref4}
              onDrop={onDrop4}
              onChange={(e) => onChange(e, 4)}
              fileImage={items[4]?.preview}
              fileName={items[4]?.name}
              uploading={uploadings[4]}
              isUploaded={items[4]?.preview}
              title="Dokumen SPK/PKS/WO/PO"
              showButton={false}
              errorText={errorFile4}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
            />
          </div> */}
        </div>
      ) : defaultViewOnlyDocuments?.length > 0 ? (
        <div className={Styles.viewOnly}>
          {defaultViewOnlyDocuments?.map((each, i) => (
            <div key={i} className={Styles.card}>
              <span>{each?.type}</span>
              {viewOnlyDocuments[i] ? (
                <img
                  src={viewOnlyDocuments[i] ? viewOnlyDocuments[i] : ""}
                  alt=""
                />
              ) : (
                <div className={Styles.imgPlaceholder}></div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className={Styles.content}>
          <div className={Styles.divider}>
            <div className={Styles.documentNotFound}>
              <span>Akta Perusahaan</span>
              <div className={Styles.images}>
                <img src={Images.CHARACTER_INJECT} alt="" />
                <span>Dokumen Tidak Tersedia</span>
              </div>
            </div>

            <div className={Styles.documentNotFound}>
              <span>NPWP</span>

              <div className={Styles.images}>
                <img src={Images.CHARACTER_INJECT} alt="" />
                <span>Dokumen Tidak Tersedia</span>
              </div>
            </div>
          </div>

          <div className={Styles.divider}>
            <div className={Styles.documentNotFound}>
              <span>SIUP</span>

              <div className={Styles.images}>
                <img src={Images.CHARACTER_INJECT} alt="" />
                <span>Dokumen Tidak Tersedia</span>
              </div>
            </div>

            <div className={Styles.documentNotFound}>
              <span>Domisili</span>

              <div className={Styles.images}>
                <img src={Images.CHARACTER_INJECT} alt="" />
                <span>Dokumen Tidak Tersedia</span>
              </div>
            </div>
          </div>

          {/* <div className={Styles.divider}>
            <div className={Styles.documentNotFound}>
              <span>Dokumen SPK/PKS/WO/PO</span>
              <div className={Styles.images}>
                <img src={Images.CHARACTER_INJECT} alt="" />
                <span>Dokumen Tidak Tersedia</span>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
}
