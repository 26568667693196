/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function AutocompleteV2({
  value,
  setValue,
  options,
  placeholder,
  disabled,
}) {
  const boxRef = useRef();
  const [expandOptions, setExpandOptions] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  // const [disabledValue, setDisabledValue] = useState("");
  const activateDropdown = () => {
    if (!disabled) {
      setExpandOptions(!expandOptions);
    }
  };
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      setExpandOptions(false);
    }
  }, [boxOutsideClick]);

  useEffect(() => {
    if (disabled) {
      setValue([]);
      setValueSearch("");
    }
  }, [disabled, setValue]);
  const filteredOptions = useMemo(() => {
    return options?.filter((el) =>
      el?.name?.toLowerCase()?.includes(valueSearch?.toLowerCase())
    );
  }, [options, valueSearch]);

  useEffect(() => {
    if (!expandOptions) {
      if (
        !options?.find(
          (el) => el?.name?.toLowerCase() === valueSearch?.toLowerCase()
        )
      ) {
        setValueSearch("");
      }
    }
  }, [expandOptions, options, valueSearch]);

  // const handleChecked = (item) => {
  //   let isSelected = false;
  //   for (let i = 0; i < value?.length; i++) {
  //     if (value[i]?.name === item?.name) {
  //       isSelected = true;
  //       // break;
  //     } else if (value[i]?.name !== item?.name) {
  //       isSelected = false;
  //       // break;
  //     }
  //   }
  //   return isSelected;
  // };

  const handleCheckboxChange = (item) => {
    let isSelected = false;
    for (let i = 0; i < value?.length; i++) {
      if (value[i]?.name === item?.name) {
        isSelected = true;
        break;
      } else if (value[i]?.name !== item?.name) {
        isSelected = false;
        // break;
      }
    }
    // let isSelected = handleChecked(item);
    if (isSelected) {
      setValue(value?.filter((selected) => selected?.name !== item?.name));
      // setIsActive(false);
    } else {
      setValue([...value, item]);

      // setIsActive(false);
    }
  };

  return (
    <div
      onClick={() => setExpandOptions(true)}
      className={`${Styles.selectWrapper} ${
        expandOptions ? Styles.active : ""
      } ${disabled ? Styles.disableInput : ""}`}
      ref={boxRef}
    >
      <input
        value={valueSearch}
        onChange={(e) => {
          setExpandOptions(true);
          setValueSearch(e?.target?.value);
        }}
        placeholder={placeholder}
        disabled={disabled}
        className={`${disabled ? Styles.disableInput : ""}`}
      />

      {expandOptions && valueSearch && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setValueSearch("");
          }}
          type="button"
          className={Styles.clearButton}
        >
          <Icon icon={"cross"} size={15} />
        </button>
      )}

      <button
        onClick={(e) => {
          e.stopPropagation();
          setExpandOptions(!expandOptions);
        }}
        type="button"
        className={Styles.expandButton}
      >
        {disabled ? (
          <Icon icon={"arrow-down"} size={20} />
        ) : (
          <Icon icon={expandOptions ? "arrow-up" : "arrow-down"} size={20} />
        )}
      </button>
      {expandOptions && !disabled ? (
        <div className={Styles.optionsWrapper}>
          {filteredOptions?.length ? (
            filteredOptions?.map((obj, i) => (
              <div
                key={i}
                onClick={(e) => {
                  handleCheckboxChange(obj);
                  // e?.preventDefault();
                  // e?.stopPropagation();
                  // setExpandOptions(false);
                  // setValue(obj?.name);
                }}
              >
                <span>{obj?.name}</span>
              </div>
            ))
          ) : (
            <div>no option</div>
          )}
        </div>
      ) : null}
    </div>
  );
}
