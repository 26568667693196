import { useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function CustomAutocompleteField({
  title,
  options,
  value,
  setValue,
  placeholder,
  withPagination,
  page,
  setPage,
  totalPage,
  required,
  disabled = false,
  requiredLabel = "*",
  isError = false,
  errorText = "Please fill the field",
}) {
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (!expand) {
      if (!options?.find((el) => el?.toLowerCase() === value?.toLowerCase())) {
        setValue("");
      } else {
        setValue(
          options?.find((el) => el?.toLowerCase() === value?.toLowerCase())
        );
      }
    }
  }, [expand, options, value, setValue]);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expand) {
      setExpand();
    }
  }, [boxOutsideClick, expand]);

  return (
    <div ref={boxRef} className={Styles.container}>
      <div className={Styles.title}>
        <p>{title}</p>
        {required && <span className={Styles.redStar}>{requiredLabel}</span>}
      </div>
      <div
        onClick={() => !disabled && setExpand(true)}
        className={`${Styles.selectWrapper} ${
          disabled ? Styles.disabled : ""
        } ${expand ? Styles.active : ""} 
        ${isError ? Styles.error : ""}`}
      >
        <input
          value={value}
          onChange={(e) => {
            setExpand(true);
            setValue(e?.target?.value);
          }}
          placeholder={placeholder}
          disabled={disabled}
        />
        {expand && value && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setValue("");
            }}
            type="button"
            className={Styles.clearButton}
          >
            <Icon icon={"cross"} size={15} />
          </button>
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            setExpand(!expand);
          }}
          type="button"
          className={Styles.expandButton}
        >
          <Icon icon={expand ? "arrow-up" : "arrow-down"} size={20} />
        </button>
        {expand && (
          <div className={Styles.optionsWrapper}>
            {withPagination && options?.length ? (
              <div
                className={Styles.pageIndicator}
              >{`Page ${page} of ${totalPage}`}</div>
            ) : (
              ""
            )}
            {options?.length ? (
              options?.map((option, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setExpand(false);
                    setValue(option);
                  }}
                >
                  <span>{option}</span>
                </div>
              ))
            ) : (
              <div>no option</div>
            )}
            {withPagination && options?.length ? (
              <div className={Styles.actionsWrapper}>
                <button
                  disabled={page === 1 || totalPage === 0}
                  onClick={() => setPage(page - 1)}
                >
                  <Icon icon="arrow-left" size={16} />
                  <span>Prev</span>
                </button>
                <button
                  disabled={page === totalPage || totalPage === 0}
                  onClick={() => setPage(page + 1)}
                >
                  <span>Next</span>
                  <Icon icon="arrow-right" size={16} />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isError && !disabled && (
        <span className={Styles.errorText}>{errorText}</span>
      )}
    </div>
  );
}
