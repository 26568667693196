import Images from "@Theme/Images";
import Styles from "./styles.module.scss";

export default function CriteriaJobs({ data }) {
  // data?.placement?.map((item) => item.city).join(", ")
  return !data?.experience &&
    data?.tags?.length === 0 &&
    data?.categories?.length === 0 &&
    data?.evaluations?.length === 0 &&
    !data?.description ? (
    <div className={Styles.containerDataNull}>
      <div>
        <div>
          <img src={Images.AMICO3} alt="" />
        </div>
        <span>
          Tidak ada data yang dapat ditampilkan, silahkan lengkapi data job
          posting terlebih dahulu.
        </span>
      </div>
    </div>
  ) : (
    <div className={Styles.container}>
      <div>
        <span>Pengalaman</span>
        <span>{data?.experience || "-"}</span>
      </div>

      <div>
        <span>Tag Pekerjaan untuk 365 Personal</span>
        <span>
          {data?.tags?.length > 0
            ? data?.tags?.map((item) => item?.value).join(", ")
            : "-"}
        </span>
      </div>

      <div>
        <span>Kategori</span>
        <span>
          {data?.categories?.length > 0
            ? data?.categories?.map((item) => item?.value).join(", ")
            : "-"}
        </span>
      </div>

      <div>
        <span>Penilaian</span>
        <span>
          {" "}
          {data?.evaluations?.length > 0
            ? data?.evaluations?.map((item) => item?.label).join(", ")
            : "-"}
        </span>
      </div>

      <div>
        <span>Deskripsi Pekerjaan</span>
        <span>{data?.description || "-"}</span>
      </div>
    </div>
  );
}
