/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import { candidateTransferType } from "@Services/officer/recruitment";
import { useEffect, useMemo, useState } from "react";
import TableCandidateToJobPost from "./TableCandidateToJobPost";
import TableCandidateToRecruiter from "./TableCandidateToRecruiter";
import Styles from "./styles.module.scss";

export default function TransferModal({
  mode,
  type,
  data,
  setData,
  setShowModalTransfer,
  setShowModalDone,
  setDoneText,
  setDoneTitle,
  setTrigger,
  isRecruitment,
  refetchDataAllCandidate,
}) {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [errorText, setTextError] = useState(null);

  // useEffect(() => {
  //     console.log('posisi', selectedPosition)
  //     console.log('recruiter', selectedRecruiter)
  //     console.log('data', data)
  // }, [selectedPosition, selectedRecruiter])

  const title = useMemo(() => {
    let headerTitle = "";

    switch (type) {
      case "RegisterToJobPosting":
        headerTitle = "Daftarkan Ke Job Posting";
        break;
      case "TransferToJobPosting":
        headerTitle = "Alihkan Kandidat Ke Job Posting Lain";
        break;
      case "TransferToAnotherRecruiter":
        headerTitle = "Alihkan Kandidat Untuk Recruiter Lain";
        break;
      default:
        headerTitle = "Daftarkan Ke Job Posting";
    }
    return headerTitle;
  }, [type]);

  const contentTable = useMemo(() => {
    switch (type) {
      case "RegisterToJobPosting":
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
      case "TransferToJobPosting":
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
      case "TransferToAnotherRecruiter":
        return (
          <TableCandidateToRecruiter
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            selectedRecruiter={selectedRecruiter}
            setSelectedRecruiter={setSelectedRecruiter}
          />
        );
      default:
        return (
          <TableCandidateToJobPost
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
    }
  }, [type, selectedPosition, selectedRecruiter]);

  const onCancel = () => {
    setData(null);
    setSelectedPosition(null);
    setSelectedRecruiter(null);
    if (isRecruitment) {
      setShowModalTransfer(null);
    } else {
      setShowModalTransfer(false);
    }
  };

  // console.log(data?.lastPosition);

  const handleSubmit = async () => {
    try {
      setIsSubmiting(true);
      let payload = {
        positionID: selectedPosition?.positionID,
        recruiterID: selectedPosition?.recruiterID,
        jobPostingID: selectedPosition?._id,
        jobPostingTitle: selectedPosition?.title,
      };
      let tempType = "JobPosting";
      if (selectedPosition?.positionID == 8612) {
        tempType = "Private";
      } else {
        tempType = "JobPosting";
      }

      await candidateTransferType(
        data?.id,
        tempType,
        encodeURIComponent(data?.lastPosition),
        payload
      );

      setShowModalDone(true);
      setDoneText(
        `Kandidat berhasil dialihkan ke job posting lain untuk posisi ${selectedPosition?.title} di ${selectedPosition?.clientName}`
      );
      setDoneTitle("Berhasil Mengalihkan Kandidat");
      setIsSubmiting(false);
      setSelectedPosition(null);
      setSelectedRecruiter(null);

      if (isRecruitment) {
        setShowModalTransfer(null);
        refetchDataAllCandidate();
      } else {
        setShowModalTransfer(false);
        setTrigger(Math.random());
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      setTextError(errorMessage);
      setIsSubmiting(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTextError(null);
    }, 3000);
  }, [errorText]);

  const disableButton = useMemo(() => {
    let temp;
    switch (type) {
      case "RegisterToJobPosting":
        if (!selectedPosition) {
          temp = true;
        } else {
          temp = false;
        }
        break;
      case "TransferToJobPosting":
        if (!selectedPosition) {
          temp = true;
        } else {
          temp = false;
        }
        break;
      case "TransferToAnotherRecruiter":
        if (selectedPosition && selectedRecruiter) {
          temp = false;
        } else {
          temp = true;
        }
        break;
      default:
        temp = true;
    }
    return temp;
  }, [type, selectedPosition, selectedRecruiter]);

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>
            <span>{title}</span>
          </h3>
          <button onClick={onCancel}>
            <Icon icon={"cross"} size={"24"} />
          </button>
        </div>
        <div className={Styles.normal}>
          <div className={Styles.detailWrapper}>
            <div className={Styles.detailBox}>
              <div className={Styles.iconDash}>
                <Icon icon={"user-circle"} size={"20"} />
              </div>
              <div className={Styles.contentDetailBox}>
                <span>Candidate</span>
                <span>{data?.name || "-"}</span>
              </div>
            </div>
            <div className={Styles.detailBox}>
              <div className={Styles.iconDash}>
                <Icon icon={"work-scheme"} size={"20"} />
              </div>
              <div className={Styles.contentDetailBox}>
                <span>Job</span>
                <span>{data?.lastPosition || "-"}</span>
              </div>
            </div>
          </div>
          {contentTable}
        </div>
        <div className={`${Styles.buttonsWrapper}`}>
          {mode === "public" ? (
            <button onClick={onCancel}>Batal</button>
          ) : (
            <></>
          )}
          <button
            className={`${mode !== "public" ? Styles.submitOnly : ""} ${
              isSubmiting ? Styles.process : ""
            }`}
            disabled={disableButton}
            onClick={handleSubmit}
          >
            {mode === "public" ? (
              !isSubmiting ? (
                <span>Daftarkan</span>
              ) : (
                <span>Mendaftarkan...</span>
              )
            ) : !isSubmiting ? (
              <span>Simpan</span>
            ) : (
              <span>Menyimpan...</span>
            )}
          </button>
        </div>
        {errorText && (
          <div className={Styles.errorWrapper}>
            <span>{errorText}</span>
          </div>
        )}
      </div>
    </div>
  );
}
