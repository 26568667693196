import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import { useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import SignaturePad from "react-signature-canvas";
import Styles from "../style.module.scss";

export default function SignContract({ state, setState, setStep }) {
  const sigCanvas = useRef({});
  const initialCanvas = useRef({});
  let inputFileRef = useRef();
  const [errorFileReject, setErrorFileReject] = useState({});
  const [errorTypes, setErrorTypes] = useState("");

  const onDrop = (acceptedFiles, fileRejections) => {
    if (acceptedFiles?.length > 0) {
      setState((prev) => ({ ...prev, image: acceptedFiles[0] }));
      setErrorFileReject({});
      setErrorTypes("");
    } else {
      setErrorTypes("Format harus JPEG atau PNG");
      setErrorFileReject({});
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorTypes("");
            setErrorFileReject({
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          }
        });
      });
    }
  };

  const onChange = (e) => {
    if (e?.target?.files?.length > 0) {
      // console.log(e?.target?.files, "test");
      if (
        e?.target?.files[0]?.type === "image/jpeg" ||
        e?.target?.files[0]?.type === "image/png"
      ) {
        setState((prev) => ({ ...prev, image: e?.target?.files[0] }));
        setErrorTypes("");
      } else {
        setState((prev) => ({ ...prev, image: null }));
        setErrorTypes("Format harus JPEG atau PNG");
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxSize: 1048576 * 5,
  });

  const handleChangeState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const clearSign = () => {
    if (state.signature) {
      handleChangeState("signature", "");
    } else {
      sigCanvas.current.clear();
    }
  };

  const clearInitial = () => {
    if (state.initials) {
      handleChangeState("initials", "");
    } else {
      initialCanvas.current.clear();
    }
  };

  const saveSign = () =>
    handleChangeState(
      "signature",
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

  const saveInitials = () =>
    handleChangeState(
      "initials",
      initialCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

  const disableButton = useMemo(
    () =>
      state.image === "" ||
      state.longitude === 0 ||
      state.latitude === 0 ||
      state.initials === "" ||
      state.signature === "",
    [state]
  );

  return (
    <div className={Styles.ContractSignContainer}>
      <div className={Styles.Header}>
        <img src={Images.KERJA365_LIGHT} alt="" />
        <div className={Styles.Logo}>
          <div>
            <img src={Images.PERMATA_LOGO} alt="permata-logo" />
          </div>
          <div>
            <span>Powered by</span>
            <p>Permata Indo Sejahtera</p>
          </div>
        </div>
      </div>

      <div className={Styles.SectionWrapper}>
        <div className={Styles.SignCanvasWrapper}>
          <p>Tanda Tangan</p>
          {state.signature ? (
            <div className={Styles.Canvas}>
              <div className={Styles.CanvasImage}>
                <img src={state.signature} alt="sign" />
              </div>
              <p className={Styles.CanvasText}>
                Tanda tangan harus sesui dengan KTP
              </p>
              <div className={Styles.CanvasClear}>
                <Icon icon="edit" size={24} onClick={clearSign} />
              </div>
            </div>
          ) : (
            <div className={Styles.Canvas}>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: Styles.SignCanvas }}
              />
              <p className={Styles.CanvasText}>
                Tanda tangan harus sesui dengan KTP
              </p>
              <div className={Styles.CanvasAction}>
                <Icon icon="eraser" size={24} onClick={clearSign} />
                <Button title={"Submit"} type="primary" onClick={saveSign} />
              </div>
            </div>
          )}
        </div>
        <div className={Styles.SignCanvasWrapper}>
          <p>Paraf</p>
          {state.initials ? (
            <div className={Styles.Canvas}>
              <div className={Styles.CanvasImage}>
                <img src={state.initials} alt="sign" />
              </div>
              <div className={Styles.CanvasClear}>
                <Icon icon="edit" size={24} onClick={clearInitial} />
              </div>
            </div>
          ) : (
            <div className={Styles.Canvas}>
              <SignaturePad
                ref={initialCanvas}
                canvasProps={{ className: Styles.SignCanvas }}
              />
              <div className={Styles.CanvasAction}>
                <Icon icon="eraser" size={24} onClick={clearInitial} />
                <Button
                  title={"Submit"}
                  type="primary"
                  onClick={saveInitials}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.SectionWrapper}>
        <div className={Styles.TitleSection}>
          <p>Ambil Foto</p>
        </div>
        <div />
        <div>
          {state.image ? (
            <div className={Styles.Section}>
              <div
                className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}
                {...getRootProps()}
              >
                <img src={URL.createObjectURL(state.image)} alt="imageid" />
                <div className={Styles.Footer}>
                  <Icon icon={"camera"} size="24px" />
                  <p>Foto Ulang</p>
                  <input
                    {...getInputProps()}
                    type="file"
                    ref={inputFileRef}
                    name="file"
                    onChange={onChange}
                    hidden
                  />
                </div>
              </div>
              <div
                className={`${Styles.ImageidContainer} ${Styles.showOnMobile}`}
              >
                <label
                  htmlFor="camera"
                  className={`${Styles.ImageidContainer}`}
                >
                  <img src={URL.createObjectURL(state.image)} alt="imageid" />
                  <div className={Styles.Footer}>
                    <Icon icon={"camera"} size="24px" />
                    <p>Foto Ulang</p>
                    <input
                      type="file"
                      // ref={inputFileRef}
                      capture="user"
                      name="file"
                      onChange={onChange}
                      hidden
                      multiple={false}
                      id="camera"
                    />
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div className={`${Styles.Section}`}>
              <div
                className={`${Styles.ImageidContainer} ${Styles.hideOnMobile}`}
              >
                <div
                  className={`${Styles.DropdownSection}`}
                  {...getRootProps()}
                >
                  <Icon icon="camera" size={28} color="#1571DE" />
                  <p>Ambil Foto Disini</p>
                  <input
                    {...getInputProps()}
                    type="file"
                    // ref={inputFileRef}
                    capture="user"
                    name="file"
                    onChange={onChange}
                    hidden
                  />
                </div>
              </div>
              <div
                className={`${Styles.DropdownSection} ${Styles.showOnMobile}`}
              >
                <label htmlFor="camera" className={`${Styles.DropdownSection}`}>
                  <Icon icon="camera" size={28} color="#1571DE" />
                  <p>Ambil Foto Disini</p>
                  <input
                    type="file"
                    // ref={inputFileRef}
                    capture="user"
                    name="file"
                    onChange={onChange}
                    hidden
                    multiple={false}
                    id="camera"
                  />
                </label>
              </div>
              {/* <div className={Styles.DropdownSection} {...getRootProps()}>
                <Icon icon="camera" size={28} color="#1571DE" />
                <p>Ambil Foto Disini</p>
                <input
                  {...getInputProps()}
                  type="file"
                  ref={inputFileRef}
                  name="file"
                  onChange={onChange}
                  hidden
                />
              </div> */}
            </div>
          )}
          {errorFileReject?.message ? (
            <span className={Styles.errorText}>
              *{errorFileReject?.message}
            </span>
          ) : errorTypes ? (
            <span className={Styles.errorText}>*{errorTypes}</span>
          ) : null}

          <p className={Styles.Description}>
            Foto dengan KTP, pastikan KTP terlihat dengan jelas pada foto
          </p>
        </div>
        <div>
          <div className={`${Styles.Section} ${Styles.noPadding}`}>
            <img src={Images.ID_PHOTO_EXAMPLE} alt="example" />
          </div>
          <p className={Styles.Description}>Contoh foto</p>
        </div>
      </div>
      {/* <div className={Styles.FullSectionWrapper}>
        <div className={Styles.locationBox}>
          <div className={Styles.blueBackground}></div>
          <div className={Styles.locationTextBox}>
            <div className={Styles.LocationWrapper}>
              {state?.longitude === 0 && state?.latitude === 0 ? (
                <Spinner />
              ) : (
                <Icon icon="pin" size={17} />
              )}
              {state?.longitude === 0 && state?.latitude === 0 ? (
                <span className={Styles.processText}>
                  Sedang dalam proses pengambilan lokasi anda
                </span>
              ) : (
                <span className={Styles.longLat}>
                  Longitude: {state?.longitude}, Latitude: {state?.latitude}{" "}
                </span>
              )}
            </div>
            <span className={`${Styles.TextNeutral}`}>
              Pengambilan lokasi akan otomatis terambil oleh sistem, Pastikan
              lokasi telah ada saat anda mengirim.
            </span>
          </div>
        </div>
      </div> */}
      <div
        className={`${Styles.SectionWrapper} ${Styles.NonBreak}`}
        style={{ height: "40px" }}
      >
        <Button
          title={"Kembali"}
          type="secondary-blue"
          onClick={() => setStep(2)}
        />
        <Button
          title={"Preview"}
          type="primary"
          onClick={() => {
            setStep(4);
          }}
          disabled={disableButton}
        />
      </div>
    </div>
  );
}
