import LoadingAnimation from "@Atom/LoadingAnimation";
import CandidateContractLayout from "@Organism/Additional/CandidateContract";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getContractApproval } from "@Services/contract/contract";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader({ params }) {
  const hash = params.hash;

  const getContractData = await getContractApproval(hash);

  return defer({
    contractData: getContractData,
  });
}

export default function CandidateContract() {
  const { contractData } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Candidate Contract</title>
      </Helmet>

      <Suspense
        fallback={
          <div
            style={{
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <div style={{ width: "250px", aspectRatio: 1 }}>
              <LoadingAnimation />
            </div>
          </div>
        }
      >
        <Await
          resolve={contractData}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(contractData) => (
            <Suspense
              fallback={
                <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100vh",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "250px", aspectRatio: 1 }}>
                    <LoadingAnimation />
                  </div>
                </div>
              }
            >
              <Await
                resolve={contractData}
                errorElement={
                  <div style={{ height: "calc(100vh - 220px)" }}>
                    <AsyncErrorBoundary />
                  </div>
                }
              >
                {(contractData) => (
                  <CandidateContractLayout data={contractData} />
                )}
              </Await>
            </Suspense>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
