/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./style.module.scss";

import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import ProgressBar from "@Atom/ProgressBar";
import { readFile } from "@Helpers/readFile";
import { useDebounce } from "@Hooks/useDebounce";
import CustomTable from "@Molecule/CustomTable";
import {
  addCandidateByExcel,
  fetchAllPrivateJobPost,
} from "@Services/officer/recruitment";
import moment from "moment";
import { excelDateToJSDate } from "@Helpers/excelDateToJSDate";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Spinner from "@Atom/Spinner";

const excelTemplate = require("@Assets/CANDIDATE_UPLOAD.xlsx");

export default function UploadExcelModal({
  show,
  setShow,
  setIsSuccess,
  // positionID,
  setTrigger = () => {},
  isSuccessAdding,
}) {
  const [file, setFile] = useState(null);
  const [fileRead, setFileRead] = useState(null);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(1);

  const [searchPosition, setSearchPosition] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [isLoadingPositin, setIsloadingPosition] = useState(false);
  const [dataPosition, setDataPosition] = useState(null);
  const [limitPosition, setLimitPosition] = useState(5);
  const [pagePosition, setPagePosition] = useState(1);
  const [error, setError] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setIsUploading(true);
  }, [file]);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const debouncedSearchPositionTerm = useDebounce(searchPosition, 300);

  const template = useMemo(() => {
    return {
      data: fileRead?.length
        ? fileRead
            ?.filter((obj) =>
              obj?.NAMA?.toLowerCase()?.includes(
                debouncedSearchTerm?.toLowerCase()
              )
            )
            ?.map((obj) => {
              return {
                // ...obj,
                name: obj?.NAMA,
                gender: obj?.GENDER,
                email: obj?.EMAIL,
                phoneNumber: obj?.NOMOR_HP,
                birthDate: obj?.TANGGAL_LAHIR
                  ? obj?.TANGGAL_LAHIR instanceof Date
                    ? moment(new Date(obj?.TANGGAL_LAHIR))?.format("ll")
                    : moment(
                        new Date(excelDateToJSDate(obj?.TANGGAL_LAHIR))
                      )?.format("ll")
                  : "",
                lastEducation: obj?.PENDIDIKAN_TERAKHIR,
                lastPosition: obj?.POSISI_TERAKHIR,
                domicile: obj?.DOMISILI,
                address: obj?.ALAMAT,
                personID: obj?.NOMOR_KTP,
              };
            })
            ?.slice((page - 1) * limit, page * limit)
        : [],
      columns: [
        {
          name: "name",
          label: "Nama Kandidat",
          tdClassName: Styles.stickyLeft,
        },
        {
          name: "gender",
          label: "Gender",
        },
        {
          name: "personID",
          label: "No KTP",
        },
        {
          name: "email",
          label: "Email",
        },
        {
          name: "phoneNumber",
          label: "Nomor HP",
        },
        {
          name: "birthDate",
          label: "Tanggal Lahir",
        },
        {
          name: "lastEducation",
          label: "Pendidikan Terakhir",
        },
        {
          name: "lastPosition",
          label: "Posisi Terakhir",
        },
        {
          name: "domicile",
          label: "Domisili",
        },
        {
          name: "address",
          label: "Alamat",
          minWidth: "261px",
        },
      ],
    };
  }, [debouncedSearchTerm, fileRead, limit, page]);

  useEffect(() => {
    setFile(null);
    setFileRead(null);
    setIsUploading(false);
    setProgress(0);
  }, [show]);

  useEffect(() => {
    if (file) {
      readFile(file, setFileRead);
    } else {
      setFileRead(null);
    }
  }, [file]);

  // const inputRef = useRef()

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    open,
  } = useDropzone({
    onDrop: (files) => {
      if (files?.length) {
        setFile(files[0]);
        // readUploadFile(files)
      }
    },
    noClick: true,
    disabled: file && isUploading ? true : false,
    accept: {
      "application/vnd.ms-excel": [".xlsx", ".numbers", ".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,
  });

  const baseStyle = useMemo(() => {
    if (file && isUploading) {
      return {
        backgroundColor: "#fff",
      };
    } else {
      return {
        backgroundColor: "#F5F5F5",
      };
    }
  }, [file, isUploading]);

  const focusedStyle = useMemo(() => {
    return {
      // borderColor: '#2196f3',
    };
  }, []);

  const acceptStyle = useMemo(() => {
    return {
      // borderColor: '#00e676',
      // backgroundColor: '#E9ECEF',
      // backgroundColor: 'green'
    };
  }, []);

  const rejectStyle = useMemo(() => {
    return {
      // borderColor: '#ff1744',
      backgroundColor: "#FFBBBB",
    };
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragActive ? { backgroundColor: "#E9ECEF" } : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      baseStyle,
      isFocused,
      focusedStyle,
      isDragAccept,
      acceptStyle,
      isDragReject,
      rejectStyle,
      isDragActive,
    ]
  );

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await addCandidateByExcel(
        selectedPosition?.positionID,
        file,
        selectedPosition?.name?.toLowerCase() === "lainnya"
          ? ""
          : selectedPosition?.id,
        selectedPosition?.name?.toLowerCase() === "lainnya"
          ? "Private"
          : "JobPosting"
      );
      setTrigger(Math.random());
      setIsSubmitting(false);
      setFile(null);
      setIsSuccess(true);
      setError("");
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      setError(err?.response?.data?.error);
    }
  };

  const disableSubmit = useMemo(() => {
    let isValid = false;
    if (step === 1) {
      if (!fileRead || isUploading || isSubmitting) {
        isValid = true;
      }
    } else {
      if (!fileRead || isUploading || isSubmitting || !selectedPosition) {
        isValid = true;
      }
    }

    return isValid;
  }, [fileRead, isSubmitting, isUploading, step, selectedPosition]);

  // const onChange = (e) => {
  //   if (e?.target?.input?.files?.length > 0) {
  //     setFile(e?.target?.input?.files[0]);
  //   }
  // };

  const refetchDataPosition = useCallback(async () => {
    try {
      setIsloadingPosition(true);
      const previousPage = pagePosition;
      const query = `limit=${limitPosition}&page=${pagePosition}&search=${
        debouncedSearchPositionTerm || ""
      }`;
      const { response } = await fetchAllPrivateJobPost(query);
      setDataPosition(response);
      setIsloadingPosition(false);
      if (response?.totalPage === 1 || response?.totalPage === 0) {
        setPagePosition(1);
      } else {
        const validPage = Math.max(
          1,
          Math.min(previousPage, response?.totalPage)
        );
        setPagePosition(validPage);
      }
    } catch (err) {
      setIsloadingPosition(false);
      console.log(err);
    }
  }, [limitPosition, pagePosition, debouncedSearchPositionTerm]);

  useEffect(() => {
    if (show) {
      refetchDataPosition();
    }
  }, [show, refetchDataPosition]);

  const templatePosition = useMemo(() => {
    return {
      data: dataPosition?.data?.map((el) => {
        return {
          id: el?._id,
          name: el?.title,
          client: el?.clientName,
          // amount:
          cityCode: el?.cityCode,
          cityName: el?.cityName,
          positionID: el?.positionID,
          recruiterID: el?.recruiterID,
        };
      }),
      columns: [
        {
          name: "position",
          label: "Posisi",
          tdClassName: Styles.stickyLeft,
          renderData: (raw) => (
            <div className={Styles.positionRaw}>
              <div className={Styles.checkbox}>
                <CheckboxJobPosting
                  checked={raw?.id === selectedPosition?.id ? true : false}
                  onChange={() => {
                    setSelectedPosition(raw);
                  }}
                  type={"radio-section"}
                />
              </div>
              <span>{raw?.name || "-"}</span>
            </div>
          ),
        },
        {
          name: "location",
          label: "Lokasi Kerja",
          renderData: (raw) => (
            <div className={Styles.cityBoxRaw}>
              <span>{raw?.cityName || "-"}</span>
            </div>
          ),
        },
        {
          name: "clientName",
          label: "Klien",
          minWidth: "261px",
          renderData: (raw) => (
            <div className={Styles.clientBoxRaw}>
              {raw?.name?.toLowerCase() === "lainnya" ? (
                <div className={Styles.iconBox}>
                  <Icon
                    icon={"information-solid"}
                    size={16}
                    color={"#1571DE"}
                  />
                </div>
              ) : null}
              <div
                className={`${Styles.clientName} ${
                  raw?.name?.toLowerCase() === "lainnya" && Styles.blue
                }`}
              >
                {raw?.name?.toLowerCase() === "lainnya"
                  ? "Kandidat hanya tersimpan di daftar private"
                  : raw?.client}
              </div>
            </div>
          ),
        },
      ],
    };
  }, [selectedPosition, dataPosition?.data]);

  return isSuccessAdding ? (
    <div className={Styles.modalSuccess}>
      <div className={Styles.icon}>
        <Icon icon={"check-circle"} size={86} color={"#1571DE"} />
      </div>

      <span>Done!</span>
      <span>File has been uploaded</span>

      <div
        onClick={() => {
          setShow(false);
          setIsSuccess(false);
          setStep(1);
        }}
      >
        Close
      </div>
    </div>
  ) : (
    <div
      className={`${Styles.container} ${
        fileRead && !isUploading ? Styles.wider : ""
      }`}
    >
      <div className={Styles.header}>
        <div className={Styles.title}>
          <h3>Unggah Data Excel</h3>
          <Icon icon={"upload"} size={24} />
        </div>

        <div className={Styles.titleBox}>
          <span>
            {step === 1 ? "Unggah Data Kandidat" : "Pilih Posisi Kandidat"}
          </span>
          <span>Step {step || 1}/2</span>
        </div>

        {step === 1 ? (
          <a
            href={excelTemplate}
            download="CANDIDATE_LIST"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <div className={Styles.template}>
              <Icon icon={"excel"} size={20} />
              <span>Download Template Excel</span>
              <Icon icon={"download"} size={24} color={"#1571DE"} />
            </div>
          </a>
        ) : (
          <div className={Styles.searchBox}>
            <OutlinedSearchBar
              value={searchPosition}
              setValue={setSearchPosition}
              placeholder={"Cari nama posisi"}
            />
          </div>
        )}
      </div>

      {step === 1 ? (
        <div className={Styles.content}>
          {fileRead && file && !isUploading ? null : (
            <div className={Styles.infoBox}>
              <div className={Styles.icon}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FFAA00"}
                  />
                </div>
                <span>Info</span>
              </div>

              <div className={Styles.descTex}>
                <span>
                  1. Pastikan nama kandidat ditulis dengan huruf{" "}
                  <span>KAPITAL</span> pada saat pengisian nama kandidat baru.
                </span>
                <span>
                  2. Foto kandidat tidak akan tersedia bila kamu menggunakan
                  metode upload excel untuk menambah kandidat baru. Jika
                  kandidat harus mengikuti interview klien/user, foto selfie
                  kandidat tidak akan muncul pada undangan interview klien/user.
                </span>
              </div>
            </div>
          )}

          {(!fileRead || (fileRead && isUploading)) && (
            <span>
              {isDragReject
                ? "Format file tidak sesuai"
                : "Taruh file di kotak bawah ini"}
            </span>
          )}
          {fileRead && file && !isUploading ? (
            <div className={Styles.withFile}>
              <div className={Styles.upper}>
                <span>File terunggah</span>
                <div className={Styles.label}>
                  <Icon icon={"excel"} size={20} />
                  <span>{file?.name}</span>
                  <button onClick={open}>
                    <Icon icon={"edit"} size={24} color={"#1571DE"} />
                  </button>
                  <button onClick={() => setFile(null)}>
                    <Icon icon={"trash"} size={24} color={"#FF3E13"} />
                  </button>
                </div>
              </div>
              <div className={Styles.lower}>
                <OutlinedSearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholder={"Cari nama kandidat"}
                />
                <CustomTable
                  columns={template?.columns}
                  data={template?.data || []}
                  totalData={fileRead?.length || 0}
                  type={"sticky-left"}
                  value={limit || 5}
                  setValue={setLimit}
                  selectedPage={page}
                  setSelectedPage={setPage}
                  totalPage={Math.ceil(fileRead?.length / limit) || 0}
                  noPageList
                  stickyTop
                />
              </div>
            </div>
          ) : (
            // <div
            //   {...getRootProps({ style })}
            //   className={`${Styles.field}`}>
            //   <input
            //     {...getInputProps()}
            //     type='file'
            //     hidden
            //   />
            //   <Icon icon={'upload'} size={40} color={'#C2C2C2'} />
            //   <p>{'Drag file ke sini untuk upload'}</p>
            //   {/* <Icon icon={file ? 'excel' : 'upload'} size={40} color={'#C2C2C2'} />
            //   <p>{file?.name || 'Drag file ke sini untuk upload'}</p> */}
            //   {!file
            //     &&
            //     <span>atau pilih file dengan</span>
            //   }
            //   {file && isUploading
            //     &&
            //     <div className={Styles.progressWrapper}>
            //       <Icon icon={'excel'} size={24} />
            //       <div className={Styles.progress}>
            //         <span>{file?.name}</span>
            //         <ProgressBar
            //           progress={progress}
            //           handleProgress={() => {
            //             setTimeout(function () {
            //               setIsUploading(false);
            //             }, 500);
            //           }}
            //           running={isUploading}
            //           speed={30}
            //         />

            //       </div>
            //       <button
            //         onClick={() => {
            //           setFile(null)
            //           setFileRead(null)
            //           setProgress(0)
            //         }}
            //       >
            //         <Icon icon={'cross-circle'} size={20} color={'#000'} />
            //       </button>
            //     </div>
            //   }
            //   {!file
            //     &&
            //     <button
            //     // onClick={() => rootRef?.current?.click()}
            //     >{!file ? 'Klik di sini' : 'Ubah File'}</button>

            //   }
            // </div>
            <></>
          )}
          <div
            {...getRootProps({ style })}
            className={`${Styles.field} ${
              fileRead && !isUploading ? Styles.hide : ""
            } ${file && isUploading && Styles.height}`}
            onClick={(e) => e?.stopPropagation()}
          >
            <input {...getInputProps()} type="file" hidden />
            {!file && <Icon icon={"upload"} size={40} color={"#C2C2C2"} />}
            {!file && <p>{"Drag file ke sini untuk upload"}</p>}
            {!file && <span>atau pilih file dengan</span>}
            {file && isUploading && (
              <div className={Styles.progressWrapper}>
                <Icon icon={"excel"} size={24} />
                <div className={Styles.progress}>
                  <span>{file?.name}</span>
                  <ProgressBar
                    progress={progress}
                    handleProgress={() => {
                      setTimeout(function () {
                        setIsUploading(false);
                      }, 500);
                    }}
                    running={isUploading}
                    speed={30}
                  />
                </div>
                <button
                  onClick={() => {
                    setFile(null);
                    setFileRead(null);
                    setProgress(0);
                  }}
                >
                  <Icon icon={"cross-circle"} size={20} color={"#000"} />
                </button>
              </div>
            )}
            {!file && (
              <button onClick={open}>
                {!file ? "Klik di sini" : "Ubah File"}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={Styles.content}>
          <CustomTable
            columns={templatePosition?.columns}
            data={templatePosition?.data || []}
            totalData={dataPosition?.totalData || 0}
            type={"sticky-left"}
            value={limitPosition || 5}
            setValue={setLimitPosition}
            selectedPage={pagePosition}
            setSelectedPage={setPagePosition}
            totalPage={dataPosition?.totalPage || 0}
            noPageList
            stickyTop
            isLoading={isLoadingPositin}
          />
        </div>
      )}

      <div className={Styles.actions}>
        {error && <span>{error}</span>}
        <div>
          <button
            onClick={() => {
              if (step === 1) {
                setShow(false);
              } else {
                setStep(1);
              }
            }}
            disabled={isSubmitting}
          >
            {step === 1 ? "Cancel" : "Back"}
          </button>
          <button
            onClick={() => {
              if (step === 1) {
                setStep(2);
              } else {
                handleSubmit();
              }
            }}
            disabled={disableSubmit}
          >
            {step === 1 ? "Next" : isSubmitting ? <Spinner /> : "Upload File"}
          </button>
        </div>
      </div>
    </div>
  );
}
