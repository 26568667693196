import CandidatesRecommendationSPALayout from '@Organism/Additional/CandidatesRecommendation';
import { getCandidateRecommendation } from '@Services/officer/recruitment';
import { Helmet } from 'react-helmet-async';
import Styles from './style.module.scss';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingAnimation from '@Atom/LoadingAnimation';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';

export async function loader({ params }) {
  const hash = params.hash;

  const packageRecommendationPromise = getCandidateRecommendation(hash, 10, 1, '', '');

  return defer({
    packageRecommendation: packageRecommendationPromise
  })
}

export default function CandidatesRecommendationSPA() {
  const { packageRecommendation } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Interview Invitation</title>
      </Helmet>
      <div className={Styles.content}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>}
        >
          <Await
            resolve={packageRecommendation}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageRecommendation) => (
              <CandidatesRecommendationSPALayout
                defaultData={packageRecommendation?.response}
              />

            )}

          </Await>

        </Suspense>
      </div>
    </div>
  )
}