import { useEffect, useMemo, useRef } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function OtherListDropdown({
  handleDone = () => { },
  value,
  setValue,
  onSubmit,
  onBack,
  type,
  disabled,
  isLimited = false,
  maxChars = 17
  //   doneButton,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  const textTemplate = useMemo(() => {
    switch (type?.toLowerCase()) {
      case 'criteria':
        return {
          headerTitle: 'Tambah Kriteria Lain',
          inpuTitle: 'Nama Kriteria',
          inputPlaceholder: 'Tulis kriteria yang dibutuhkan'
        }
      case 'tag':
        return {
          headerTitle: 'Tag Pekerjaan',
          inpuTitle: 'Deskripsi Tag',
          inputPlaceholder: 'Tulis tag'
        }
      default:
        return {
          headerTitle: 'Buat Nama Posisi',
          inpuTitle: 'Nama Posisi',
          inputPlaceholder: 'Tulis posisi yang dibutuhkan'
        }
    }
  }, [type])

  //   useEffect(() => {
  //     if (!doneButton) {
  //       setTimeout(function () {
  //         handleDone();
  //       }, 3000);
  //     }
  //   }, [doneButton, handleDone]);
  return (
    <div className={Styles.containerModal}>
      <div className={Styles.boxModal} ref={boxRef}>
        <div className={Styles.top}>
          <span className={Styles.textModal}>
            {textTemplate?.headerTitle}
          </span>
          <div className={Styles.inputForm}>
            <span className={Styles.label}>
              {textTemplate?.inpuTitle}
            </span>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              type="text"
              name="name"
              placeholder={textTemplate?.inputPlaceholder}
              maxLength={isLimited ? maxChars : 'auto'}
            />
            {isLimited
              &&
              <div className={Styles.limitIndicator}>
                <span>{`Maksimal ${maxChars} karakter`}</span>
                <span>{`(${value?.length}/${maxChars})`}</span>
              </div>
            }

          </div>
        </div>
        <div className={Styles.buttonBox}>
          <div className={Styles.null}></div>
          <div className={Styles.button}>
            <div className={Styles.cancel} onClick={onBack}>
              Batalkan
            </div>
            {disabled ? (
              <div className={Styles.saveDisabled}>Simpan</div>
            ) : (
              <div className={Styles.save} onClick={onSubmit}>
                Simpan
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
