import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import ManPowerRequestSummaryNavController from '@Organism/Manager/Client/ManPowerRequestSummary/NavController'
import ManPowerRequestSummaryContent from '@Organism/Manager/Client/ManPowerRequestSummary/Content'
import { getManPowerDetailNeed } from '@Services/manager/client';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { Suspense } from 'react';
import LoadingAnimation from '@Atom/LoadingAnimation';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const id = url.searchParams.get("i");


  const packageSummaryPromise = getManPowerDetailNeed(id, 10, 1, '', null)

  return defer({
    packageSummary: packageSummaryPromise
  });
}

export default function ManPowerRequestSummary() {
  const { packageSummary } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Clients</title>
      </Helmet>
      <div className={Styles.navControlWrapper}>
        <ManPowerRequestSummaryNavController />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packageSummary}
            errorElement={
              <div style={{ height: 'calc(100vh - 220px)' }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageSummary) => (
              <ManPowerRequestSummaryContent
                defaultData={packageSummary?.response}
              />
            )}

          </Await>
        </Suspense>
      </div>
    </div>
  );
}
