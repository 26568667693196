/* eslint-disable no-unused-vars */
import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import { useEffect, useState } from 'react'
import Images from '@Theme/Images'
import Lottie from 'lottie-react'
import success from "@Assets/Lotties/success.json";
import { sendRenewData } from '@Services/officer/recruitment'
import FormErrorMessage from '@Atom/FormErrorMessage'


export default function RenewDataConfirmationModal({
  show,
  onClose,
  onCancel,
  formToSend,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      await sendRenewData(formToSend)
      setIsSuccess(true)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setError(err?.response?.data?.error || "Something went wrong");
    }
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  }, [error, setError]);

  return (
    <ModalWrapper
      show={show}
    >
      <div className={Styles.container}>
        <div className={Styles.logo} style={{ paddingBottom: isSuccess ? 0 : '24px' }}>
          {
            isSuccess
              ?
              <div className={Styles.lottieWrapper}>
                <Lottie loop={false} animationData={success} height={150} width={150} />
              </div>
              :
              <img src={Images.FINISHED_V2} alt='' />

          }
        </div>
        {
          isLoading
          &&
          <div className={Styles.loader}>
            <img src={Images.LOADER} alt='' />
            <span>Mengirim...</span>
          </div>
        }

        {
          !isLoading
          &&
          <div className={Styles.text}>
            <h3>{isSuccess ? 'Terkirim' : 'Konfirmasi Kirim'}</h3>
            <p>{isSuccess ? 'Form anda telah berhasil dikirim' : 'Apakah anda yakin telah mengisi data dengan benar?'}</p>
          </div>
        }
        {
          error
          &&
          <div style={{
            paddingBottom: '12px',
            textAlign: 'left',
            width: '100%',
          }}>
            <FormErrorMessage message={error} />

          </div>
        }
        {
          !isLoading
          &&
          <div className={`${Styles.actions} ${isSuccess ? Styles.flex : ''}`}>
            <button onClick={isSuccess ? onClose : onCancel} className={Styles.back}>{!isSuccess ? 'Batalkan' : 'Tutup'}</button>
            {
              !isSuccess
              &&
              <button onClick={handleSubmit} className={Styles.submit}>Ya, kirim sekarang</button>
            }
          </div>
        }
      </div>
    </ModalWrapper>
  )
}