import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const options = {
  disableDefaultUI: false,
  zoomControl: false,
  fullScreenControl: false,
  streetViewControl: false,
};

const libraries = ["places"];

const center = { lat: 0, lng: 0 };

export default function PinMaps({ lat, lng, mapHeight, mapWidth }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    libraries,
  });
  const [rendered, setRendered] = useState(false);

  const mapContainerStyle = {
    height: mapHeight,
    width: mapWidth,
  };

  const mapRef = useRef();
  const didMount = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef?.current?.panTo({ lat, lng });
    mapRef?.current?.setZoom(20);
  }, []);

  useEffect(() => {
    if (rendered) {
      panTo({ lat, lng });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, rendered]);

  const onMapLoad = (map) => {
    mapRef.current = map;
    setRendered(true);
    // panTo({
    //   lat: lat,
    //   lng: lng,
    // });
    didMount.current = true;
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div className="w-full flex justify-center">
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={20}
        center={center}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {lat && lng && (
          <>
            <Marker position={{ lat: lat, lng: lng }} />
          </>
        )}
      </GoogleMap>
    </div>
  );
}
