import Styles from "./toggleButton.module.scss";

const ToggleButtonPIC = ({
  // setTrue,
  // defaultTrue,
  // type,
  isPIC,
  handleIsPIC,
  handleNotPIC,
}) => {
  // const [isTrue, setIstrue] = useState(defaultTrue);

  return (
    <>
      {isPIC ? (
        <div onClick={handleNotPIC} className={Styles.containerActive}>
          <div className={Styles.toggleActive}></div>
        </div>
      ) : (
        <div onClick={handleIsPIC} className={Styles.container}>
          <div className={Styles.toggle}></div>
        </div>
      )}
    </>
  );
};

export default ToggleButtonPIC;
