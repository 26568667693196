/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import StaticProgressBar from "@Atom/StaticProgressBar";
import Tooltip from "@Atom/Tooltip";
import { fileBaseUrl } from "@Config/api";
import useQuery from "@Hooks/useQuery";
import useWindowSize from "@Hooks/useWindowSize";
import CustomTable from "@Molecule/CustomTable";
import DashboardSyncComponent from "@Molecule/DashboardSyncComponent";
import Images from "@Theme/Images";
import Styles from "./style.module.scss";
import SearchBar from "@Atom/SearchBar";
import { useCallback, useMemo, useState } from "react";
import { downloadBM, downloadClients } from "@Services/manager/bm";

export default function RBMDashboardLayout({
  syncData,
  isLoadingSync = false,
  isLoadingTable = false,
  isLoadingTable2 = false,
  totalPage = 0,
  value = 10,
  setValue,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  pages,
  setPages,
  searchTerm,
  setSearchTerm,
  data = [],
  totalPage2 = 0,
  value2 = 10,
  setValue2,
  selectedPage2,
  setSelectedPage2,
  pageList2,
  setPageList2,
  pages2,
  setPages2,
  searchTerm2,
  setSearchTerm2,
  data2,
}) {
  const query = useQuery();

  const { width } = useWindowSize();
  // const navigate = useNavigate()
  // const { pathname } = useLocation()

  const tableLoading = useMemo(() => {
    return (
      isLoadingTable ||
      query?.get("n") !== searchTerm ||
      selectedPage != query?.get("p") ||
      value != query?.get("l")
    );
  }, [isLoadingTable, query, searchTerm, selectedPage, value]);
  const table2Loading = useMemo(() => {
    return (
      isLoadingTable2 ||
      query?.get("n2") !== searchTerm2 ||
      selectedPage2 != query?.get("p2") ||
      value2 != query?.get("l2")
    );
  }, [isLoadingTable2, query, searchTerm2, selectedPage2, value2]);

  const columns = [
    {
      name: "businessManager",
      label: "Business Manager",
      renderData: (row) => (
        <div className={Styles.ppWrapper}>
          <div className={Styles.pp}>
            <img
              src={
                row?.User?.profilePicture
                  ? row?.User?.profilePicture?.includes("http")
                    ? row?.User?.profilePicture?.replace(
                        "https://myworkspace",
                        "https://api1.myworkspace"
                      )
                    : fileBaseUrl + row?.User?.profilePicture
                  : Images.AVA_DEFAULT
              }
              alt=""
            />
            <div>
              <span>{row?.User?.name}</span>
              <span>{row?.User?.jobLevel}</span>
            </div>
          </div>
          <span>{row?.totalClient}</span>
        </div>
      ),
      minWidth: "300px",
      maxWidth: "300px",
      tdClassName: Styles.sticky,
      thClassName: Styles.sticky,
    },
    // {
    //   name: 'totalClient',
    //   label: 'Jumlah Klien',
    //   align: 'right'
    // },
    {
      name: "",
      hideOnMobile: true,
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.tooltipWrapper}>
          <Tooltip
            content={
              <div className={Styles.tooltip}>
                <p>
                  <span>Jumlah Terpenuhi</span>
                  <span>:</span>
                  <span>{`${row?.fullfillment || 0} (${
                    row?.percentage
                  })`}</span>
                </p>
                <p>
                  <span>Vendor Lain</span>
                  <span>:</span>
                  <span>{row?.vendor || 0}</span>
                </p>
                <p>
                  <span>Vacant</span>
                  <span>:</span>
                  <span>{row?.vacant || 0}</span>
                </p>
              </div>
            }
            direction="top"
            backgroundColor="#fff"
            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
            tooltipMargin="80px"
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={row?.fullfillment || 0}
              middleFulfilledText={`${row?.percentage}`}
              // fulfilledText={`${row?.fulfillment || 0} (${Math.floor(+row?.fulfillment / +row?.quota * 100)}%)`}
              candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
              candidatesText={row?.vendor || 0}
              requestAmount={row?.quota || 0}
              vacantAmount={row?.vacant || 0}
              isV3
              borderRadius="0px"
              height={width > 768 ? "32px" : "28px"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const columns2 = [
    {
      name: "businessManager",
      label: "Clients",
      renderData: (row) => (
        <div className={Styles.clientWrapper}>
          <div className={Styles.logo}>
            <Icon icon="company" size={24} />
          </div>
          <div className={Styles.desc}>
            <span>{row?.name}</span>
            <div className={Styles.clientName}>
              <img
                alt=""
                src={
                  row?.Region?.User?.profilePicture
                    ? row?.Region?.User?.profilePicture
                        ?.toLowerCase()
                        ?.includes("http")
                      ? row?.Region?.User.profilePicture?.replace(
                          "https://myworkspace",
                          "https://api1.myworkspace"
                        )
                      : fileBaseUrl + row?.Region?.User?.profilePicture
                    : Images?.AVA_DEFAULT
                }
              />
              <span>{row?.Region?.User?.name}</span>
            </div>
          </div>
        </div>
      ),
      minWidth: "300px",
      maxWidth: "300px",
      tdClassName: Styles.sticky,
      thClassName: Styles.sticky,
    },
    // {
    //   name: 'totalClient',
    //   label: 'Jumlah Klien',
    //   align: 'right'
    // },
    {
      name: "",
      hideOnMobile: true,
      label: "Jumlah Permintaan & Terpenuhi",
      renderData: (row) => (
        <div className={Styles.tooltipWrapper}>
          <Tooltip
            content={
              <div className={Styles.tooltip}>
                <p>
                  <span>Jumlah Terpenuhi</span>
                  <span>:</span>
                  <span>{`${row?.fullfillment || 0} (${
                    row?.percentage
                  })`}</span>
                </p>
                <p>
                  <span>Vendor Lain</span>
                  <span>:</span>
                  <span>{row?.vendor || 0}</span>
                </p>
                <p>
                  <span>Vacant</span>
                  <span>:</span>
                  <span>{row?.vacant || 0}</span>
                </p>
              </div>
            }
            direction="top"
            backgroundColor="#fff"
            boxShadow="0px 6px 24px 0px rgba(0, 0, 0, 0.15)"
            tooltipMargin="80px"
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={row?.fullfillment || 0}
              middleFulfilledText={`${row?.percentage}`}
              // fulfilledText={`${row?.fulfillment || 0} (${Math.floor(+row?.fulfillment / +row?.quota * 100)}%)`}
              candidatesAmount={row?.fulfillment || 0 + row?.vendor || 0}
              candidatesText={row?.vendor || 0}
              requestAmount={row?.quota || 0}
              vacantAmount={row?.vacant || 0}
              isV3
              borderRadius="0px"
              height={width > 768 ? "32px" : "28px"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const [isDownloadingBM, setIsDownloadingBM] = useState(false);
  const [isDownloadingClients, setIsDownloadingClients] = useState(false);

  const handleDownload = useCallback(async (type) => {
    try {
      type === "bm" ? setIsDownloadingBM(true) : setIsDownloadingClients(true);
      const res = type === "bm" ? await downloadBM() : await downloadClients();
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}-report.xlsx`);
      document.body.appendChild(link);
      link.click();
      setIsDownloadingBM(false);
      setIsDownloadingClients(false);
    } catch (err) {
      setIsDownloadingBM(false);
      setIsDownloadingClients(false);
      const errMsg = err?.response?.data?.error || err?.message;
      console.log(errMsg);
    }
  }, []);
  return (
    <div className={Styles.container}>
      <DashboardSyncComponent
        syncData={syncData}
        isLoading={isLoadingSync}
        withBMAmount
      />
      <div className={Styles.tablesWrapper}>
        {[
          {
            title: "Daftar BM",
            search: searchTerm,
            setSearch: setSearchTerm,
            placeholder: "Search BM name",
            onDownload: () => handleDownload("bm"),
            isDownloading: isDownloadingBM,
            component: (
              <CustomTable
                data={data}
                columns={columns}
                isLoading={tableLoading}
                isLoadingSkeleton={tableLoading}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                value={value}
                setValue={setValue}
                totalPage={totalPage}
                stickyTop
                height="690px"
                isFulfillment
              />
            ),
          },
          {
            title: "Daftar Klien",
            search: searchTerm2,
            setSearch: setSearchTerm2,
            placeholder: "Search client name",
            onDownload: () => handleDownload("clients"),
            isDownloading: isDownloadingClients,
            component: (
              <CustomTable
                data={data2}
                columns={columns2}
                // isLoading={isLoading}
                isLoadingSkeleton={table2Loading}
                selectedPage={selectedPage2}
                setSelectedPage={setSelectedPage2}
                value={value2}
                setValue={setValue2}
                totalPage={totalPage2}
                stickyTop
                height="690px"
                isFulfillment
              />
            ),
          },
        ]?.map((t, tI) => (
          <div key={tI} className={Styles.section}>
            <div className={Styles.sHeader}>
              <div className={Styles.shTop}>
                <h3>{t?.title}</h3>
                <div className={Styles.legends}>
                  {[
                    {
                      label: <p>Jumlah Terpenuhi</p>,
                      bgColor: "#1571DE",
                    },
                    {
                      label: (
                        <p>
                          <span>Vendor</span> Lain
                        </p>
                      ),
                      bgColor: "#91BDF1",
                    },
                    {
                      label: (
                        <p>
                          <span>Vacant</span>
                        </p>
                      ),
                      bgColor: "#E6E3E3",
                    },
                  ]?.map((i, iI) => (
                    <div key={iI} className={Styles.legend}>
                      <div style={{ backgroundColor: i?.bgColor }} />
                      {i?.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className={Styles.shBottom}>
                <div>
                  <SearchBar
                    value={t?.search}
                    onChange={(e) => t?.setSearch(e?.target?.value)}
                    placeholder={t?.placeholder}
                  />
                </div>
                <button onClick={t?.onDownload} disabled={t?.isDownloading}>
                  <span>Download Report</span>
                  <Icon icon={"download"} size={20} />
                </button>
              </div>
            </div>
            {t?.component}
          </div>
        ))}
      </div>
    </div>
  );
}
