import AutocompleteV2 from '@Atom/AutocompleteV2'
import Icon from '@Atom/Icon'
import InputDate from '@Atom/InputDate'
import { useMemo, useState } from 'react'
import Styles from './style.module.scss'
import useQuery from '@Hooks/useQuery'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'

export default function DownloadExcelFilterPopup({ setShow, options }) {
  const query = useQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [dateFrom, setDateFrom] = useState(query?.get('df') || '')
  const [dateTo, setDateTo] = useState(query?.get('dt') || '')
  const [pks, setPks] = useState(query?.get('dn') || '')



  const allQueries = useMemo(() => {
    const arr = []
    for (const param of query) {
      arr.push(param);
    }

    const newArr = !arr?.find(obj => obj[0] === 'df')
      ?
      (
        !arr?.find(obj => obj[0] === 'dt')
          ?
          (
            !arr?.find(obj => obj[0] === 'dn')
              ?
              [...arr, ['df', dateFrom ? moment(new Date(dateFrom))?.format('YYYY-MM-DD') : ''], ['dt', dateTo ? moment(new Date(dateTo))?.format('YYYY-MM-DD') : ''], ['dn', pks]]
              :
              [...arr, ['df', dateFrom ? moment(new Date(dateFrom))?.format('YYYY-MM-DD') : ''], ['dt', dateTo ? moment(new Date(dateTo))?.format('YYYY-MM-DD') : '']]

          )
          :
          [...arr, ['df', dateFrom ? moment(new Date(dateFrom))?.format('YYYY-MM-DD') : ''], ['dt', dateTo ? moment(new Date(dateTo))?.format('YYYY-MM-DD') : '']]

      )
      : [...arr]

    return newArr
  }, [dateFrom, dateTo, pks, query])

  const fixedQuery = useMemo(() => {
    let str = ''

    const raw = allQueries.join(',')?.split(',')

    const strArr = []

    for (let i = 0; i < raw.length / 2; i++) {
      strArr.push({
        key: raw[i + i],
        value: raw[i + i] === 'df'
          ?
          (dateFrom ? moment(new Date(dateFrom))?.format('YYYY-MM-DD') : '')
          :
          (raw[i + i] === 'dt'
            ?
            (dateTo ? moment(new Date(dateTo))?.format('YYYY-MM-DD') : '')
            :
            (
              raw[i + i] === 'dn'
                ?
                pks
                :
                raw[i + i + 1]
            )

          )



      })
    }


    for (let i = 0; i < strArr.length; i++) {
      const s = strArr[i]
      str += (i === 0 ? `${s?.key}=${s?.value}` : `&${s?.key}=${s?.value}`)
    }


    return str

  }, [allQueries, dateFrom, dateTo, pks])


  const pksOptions = useMemo(() => {
    return options?.map(obj => {
      return {
        ...obj,
        name: obj?.docNumber
      }
    })
  }, [options])


  const handleReset = () => {
    setDateFrom('')
    setDateTo('')
    setPks('')
  }
  const handleSave = () => {
    navigate(`${pathname}?${fixedQuery}`, { replace: true })
    setShow(false)
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Filter</h3>
        <button onClick={handleReset}>Reset</button>
      </div>
      <div className={Styles.content}>
        <div className={Styles.dateWrapper}>
          <InputDate
            value={dateFrom}
            setValue={setDateFrom}
            placeholder={'Pilih tanggal'}
          />
          <Icon icon={'arrow-right-next'} size={20} />
          <InputDate
            value={dateTo}
            setValue={setDateTo}
            placeholder={'Pilih tanggal'}
          />
        </div>
        <AutocompleteV2
          options={pksOptions}
          value={pks}
          setValue={setPks}
          placeholder={'Pilih PKS'}
        />
        <button onClick={handleSave}>Simpan</button>
      </div>
    </div>
  )
}