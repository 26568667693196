import { API } from "@Config/api";

export function getContractList(limit, page, type, search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/hr-access/contract-template?limit=${limit}&page=${page}&type=${type}&search=${search}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getTemplateSync(search, type = "CONTRACT") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/hr-access/contract-sync?search=${search ? search : ""}&type=${type}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createTemplate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/hr-access/contract-template`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createTemplateParameter(payload) {
  // let dataToSend = {
  //   code: payload.map((el) => el.code),
  //   label: payload.map((el) => el.label),
  //   type: payload.map((el) => "Parameter Tambahan"),
  // };
  const params = new URLSearchParams();
  for (let i = 0; i < payload.length; i++) {
    params.append(`code[${i}]`, `{${payload[i].code[0]}}`);
    params.append(`label[${i}]`, payload[i].label[0]);
    params.append(`type[${i}]`, "Parameter Tambahan");
  }

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/hr-access/parameter`, params, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
          // "content-type": "application/x-www-form-urlencoded",
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function duplicateTemplate(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/hr-access/duplicate-template/${id}`,
        payload,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateTemplate(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/hr-access/contract-template/${id}`,
        payload,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
