import EducationData from "./EducationData";
import Styles from "./style.module.scss";

export default function NotEmpty({ form, setForm, options, errorField }) {
  return (
    <div className={Styles.container}>
      {form?.map((each, i) => (
        <EducationData
          key={i}
          options={options}
          data={each}
          form={form}
          setForm={setForm}
          errorField={errorField}
        />
      ))}
    </div>
  );
}
