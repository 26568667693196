import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import useQuery from "@Hooks/useQuery";
import Images from "@Theme/Images";
import { useNavigate } from "react-router-dom";

export default function PipelineOrderNavigator() {
  const query = useQuery();
  const navigate = useNavigate();

  const name = query.get("n");

  const handleClickBack = () => {
    navigate("/pipeline");
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbWrapper}>
        <button onClick={handleClickBack}>
          <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
        </button>

        <CustomBreadCrumb>
          <span onClick={handleClickBack} className={Styles.breadcrumbText}>
            Pipeline
          </span>
          <span className={Styles.breadcrumbText}>{name}</span>
          <span className={Styles.breadcrumbTextBold}>Order</span>
        </CustomBreadCrumb>
      </div>
      <div className={Styles.infoWrapper}>
        <img src={Images.RED_LIST} alt="info" />
        <div className={Styles.info}>
          <h3>Info</h3>
          <span>Lengkapi data yang masih belum terisi</span>
        </div>
      </div>
    </div>
  );
}
