import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import ContractTableHeader from "@Molecule/ContractTableHeader";
import CustomTable from "@Molecule/CustomTable";
import MenuListPopup from "@Molecule/MenuListPopup";
import SelectContractTemplate from "@Molecule/_modal/HumanCapital/SelectContractTemplate";
import Images from "@Theme/Images";
// import moment from "moment";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import moment from "moment";

export default function HumanCapitalContractListLayout({
  limit,
  setLimit,
  page,
  setPage,
  type,
  setType,
  search,
  setSearch,
  debouncedSearch,
  data,
}) {
  const navigate = useNavigate();
  const [expandPopup, setExpandPopup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const contractOption = [
    {
      name: "Kontrak",
      value: "CONTRACT",
    },
    {
      name: "Kontrak Tambahan",
      value: "ADDITIONAL",
    },
  ];

  const actionOption = [
    {
      title: "Edit Template",
      icon: "edit",
    },
    {
      title: "Duplikat Template",
      icon: "duplicate-document",
    },
    {
      title: "Preview Template Kontrak",
      icon: "eye-visible",
    },
  ];

  const handleExpandPopup = (id) => {
    if (expandPopup === id) {
      setExpandPopup(null);
    } else {
      setExpandPopup(id);
    }
  };

  const template = useMemo(() => {
    if (type === "CONTRACT") {
      return {
        data: data.response.data,
        columns: [
          {
            name: "name",
            label: "Nama Kontrak",
            renderData: (row) => (
              <div className={Styles.TableNameWrapper}>
                <div className={Styles.IconWrapper}>
                  <Icon
                    icon="document-text"
                    size={20}
                    className={Styles.Icon}
                  />
                </div>
                <span>{row?.name}</span>
              </div>
            ),
          },
          {
            name: "updatedAt",
            label: "Tanggal",
            hideOnMobile: true,
            renderData: (row) => moment(row.updatedAt).format("DD MMMM YYYY"),
          },
          {
            name: "contractType",
            label: "Tipe Kontrak",
            hideOnMobile: true,
          },
          {
            name: "action",
            label: "",
            renderData: (row) => {
              const edit = () => {
                navigate(`update-draft?t?t=${type}`, {
                  state: { data: row, type: "UPDATE_CONTRACT" },
                });
              };

              const duplicate = () => {
                navigate(`/new-draft?t=${type}`, {
                  state: { data: row, type: "DUPLICATE_CONTRACT" },
                });
              };

              const updateActionOptions = actionOption.map((item) => {
                if (item?.title === "Edit Template") {
                  return {
                    ...item,
                    action: edit,
                  };
                }
                if (item?.title === "Duplikat Template") {
                  return {
                    ...item,
                    action: duplicate,
                  };
                }
                return item;
              });

              return (
                <div className={Styles.actionsWrapper}>
                  <button onClick={() => handleExpandPopup(row?.id)}>
                    <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                  </button>
                  {expandPopup === row.id && (
                    <div className={Styles.PopupMenu}>
                      <MenuListPopup
                        title="Aksi"
                        options={updateActionOptions}
                        withIcon
                      />
                    </div>
                  )}
                </div>
              );
            },
            //   renderData: (row) => (
            //     <div className={Styles.actionsWrapper}>
            //       <button>
            //         <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
            //       </button>
            //     </div>
            //   ),
          },
        ],
      };
    } else {
      return {
        data: data.response.data,
        columns: [
          {
            name: "name",
            label: "Nama Kontrak",
            renderData: (row) => (
              <div className={Styles.TableNameWrapper}>
                <div className={Styles.IconWrapper}>
                  <Icon
                    icon="document-text"
                    size={20}
                    className={Styles.Icon}
                  />
                </div>
                <span>{row?.name}</span>
              </div>
            ),
          },
          {
            name: "createdAt",
            label: "Tanggal Pembuatan",
            hideOnMobile: true,
            renderData: (row) => moment(row.updatedAt).format("DD MMMM YYYY"),
          },
          {
            name: "action",
            label: "",
            renderData: (row) => {
              const edit = () => {
                navigate(`update-draft?t=${type}`, {
                  state: { data: row, type: "UPDATE_ADDITIONAL" },
                });
              };

              const duplicate = () => {
                navigate(`/new-draft?t=${type}`, {
                  state: { data: row, type: "DUPLICATE_CONTRACT" },
                });
              };

              const updateActionOptions = actionOption.map((item) => {
                if (item?.title === "Edit Template") {
                  return {
                    ...item,
                    action: edit,
                  };
                }
                if (item?.title === "Duplikat Template") {
                  return {
                    ...item,
                    action: duplicate,
                  };
                }
                return item;
              });

              return (
                <div className={Styles.actionsWrapper}>
                  <button onClick={() => handleExpandPopup(row?.id)}>
                    <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                  </button>
                  {expandPopup === row.id && (
                    <div className={Styles.PopupMenu}>
                      <MenuListPopup
                        title="Aksi"
                        options={updateActionOptions}
                        withIcon
                      />
                    </div>
                  )}
                </div>
              );
            },
          },
        ],
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, expandPopup]);

  //   const additionalTemplate = useMemo(() => {
  //     return {
  //       data: data.response.data,
  //       columns: [
  //         {
  //           name: "name",
  //           label: "Nama Kontrak",
  //           renderData: (row) => (
  //             <div className={Styles.TableNameWrapper}>
  //               <div className={Styles.IconWrapper}>
  //                 <Icon icon="document-text" size={20} className={Styles.Icon} />
  //               </div>
  //               <span>{row?.name}</span>
  //             </div>
  //           ),
  //         },
  //         {
  //           name: "createdAt",
  //           label: "Tanggal Pembuatan",
  //           hideOnMobile: true,
  //         },
  //         {
  //           name: "action",
  //           label: "",
  //           renderData: (row) => (
  //             <div className={Styles.actionsWrapper}>
  //               <button onClick={() => handleExpandPopup(row?.id)}>
  //                 <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
  //               </button>
  //               {expandPopup === row.id && (
  //                 <div className={Styles.PopupMenu}>
  //                   <MenuListPopup title="Aksi" options={actionOption} withIcon />
  //                 </div>
  //               )}
  //             </div>
  //           ),
  //         },
  //       ],
  //     };
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [data.response.data, expandPopup]);

  const handleClickAddTemplate = () => {
    if (type === "CONTRACT") {
      setShowModal(true);
    } else {
      navigate("/new-draft", { state: { type: "ADDITIONAL" } });
    }
  };

  return (
    <div className={Styles.Main}>
      <div className={Styles.header}>
        <div>
          <ContractTableHeader
            title={"Total Template Kontrak"}
            amount={data?.response?.totalData}
            withSearchbar={false}
          />
        </div>
        <div className={Styles.ControlSectionWrapper}>
          <div className={Styles.ButtonWrapper}>
            {contractOption.map((el, idx) => (
              <Button
                type={"candidate-tab"}
                title={el?.name}
                isActive={type === el?.value}
                onClick={() => setType(el?.value)}
              />
            ))}
          </div>
          <div className={Styles.SearchWrapper}>
            <div className={Styles.searchbar}>
              <OutlinedSearchBar
                value={search}
                setValue={setSearch}
                placeholder={"Cari template kontrak"}
              />
            </div>
          </div>
          <div>
            <Button
              type={"button-icon"}
              title={"Template Kontrak"}
              icon={"add-circle"}
              onClick={() => handleClickAddTemplate()}
            />
          </div>
        </div>
      </div>
      <div className={`${Styles.TableWrapper}`}>
        <CustomTable
          data={template?.data || []}
          columns={template?.columns || []}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          // totalPage={data?.response?.totalPage || 1}
          totalPage={data?.response?.totalPage && typeof data?.response?.totalPage === 'number' ? +data?.response?.totalPage : 0}
          isV2
          noContainerBorder
          // activeController={stage ? null : (status || 'BS-REGISTERED')}
          // setActiveController={setStatus}
          // searchTerm={search}
          // setSearchTerm={setSearch}
          // noStickyPagination={windowSize[0] <= 480 ? true : false}
          // stickyTop
          totalData={data?.response?.totalData || 0}
        />
      </div>
      {showModal
        &&
        <div>
          <ModalWrapper show={showModal} type={"normal"}>
            <SelectContractTemplate handleClose={() => setShowModal(false)} />
          </ModalWrapper>
        </div>
      }
    </div>
  );
}
