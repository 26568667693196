import Icon from "@Atom/Icon";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import StackedProfile from "@Atom/StackedProfile";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";
// import { Checkbox } from "@mui/material";
import CandidateResumeLayout from "@Molecule/_recruitment/CandidateResumeLayout";
import Pagination from "@Molecule/Pagination";
import { useDebounce } from "@Hooks/useDebounce";
import { addExisting, getAddExistingSync } from "@Services/officer/recruitment";
import { omitKey } from "@Helpers/omitKey";
import { makeRandomString } from "@Helpers/makeRandomString";
import Input from "@Atom/Input";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function AddCandidateExistingModal({
  onClose,
  selectedPosition,
  setTrigger = () => { },
  handleDone = () => { },
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const profilePicture = localStorage.getItem("profilePicture");
  const role = localStorage.getItem("role");
  const userName = localStorage.getItem("username");
  // const profilePicture = sessionStorage.getItem("profilePicture");
  // const role = sessionStorage.getItem("role");
  // const userName = sessionStorage.getItem("username");
  const [activeTab, setActiveTab] = useState("private");
  const [selectedCV, setSelectedCV] = useState(null);
  const [searchTerm, setSearchTerm] = useState(
    selectedPosition?.position || ""
  );
  const [domicileSearch, setDomicileSearch] = useState("");
  const debouncedDomicileSearch = useDebounce(domicileSearch, 500);
  const debouncedSearch = useDebounce(searchTerm, 500);

  const [error, setError] = useState(null);
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  useEffect(() => {
    setSearchTerm(selectedPosition?.position);
  }, [selectedPosition]);

  const [data, setData] = useState([]);

  // const [value, setValue] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  // const [pages, setPages] = useState(`1-${value}`);
  // const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const fetchList = useCallback(async () => {
    try {
      setIsLoading(true);
      setData([]);
      const { response } = await getAddExistingSync(
        `page=${activeTab?.toLowerCase() === "existing"
          ? +selectedPage - 1
          : selectedPage
        }&limit=10&type=${activeTab?.toUpperCase()}&position=${debouncedSearch}&domicile=${debouncedDomicileSearch}`
      );
      setData(response?.data);
      setTotalPage(response?.totalPage);
      setTotalData(response?.totalData);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [activeTab, debouncedDomicileSearch, debouncedSearch, selectedPage]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const candidates = useMemo(() => {
    return data?.map((obj, i) => {
      return {
        ...obj,
        id: obj?.id || i,
      };
    });
  }, [data]);

  const [checkedData, setCheckedData] = useState([]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const columns = useMemo(() => {
    return [
      {
        name: "name",
        label: "Nama Kandidat",
        align: "left",
        minWidth: "304px",
        renderCell: (row) => (
          <div className={Styles.profile}>
            {/* <Checkbox
              isChecked={checkedData?.find(obj => obj?.id === row?.id) ? true : false}
              setIsChecked={() => {
                if (checkedData?.find(obj => obj?.id === row?.id)) {
                  setCheckedData(checkedData?.filter(obj => obj?.id !== row?.id))
                } else {
                  setCheckedData([...checkedData, row])
                }
              }}
            /> */}
            <CheckboxJobPosting
              onChange={() => {
                if (checkedData?.find((obj) => obj?.id === row?.id)) {
                  setCheckedData(
                    checkedData?.filter((obj) => obj?.id !== row?.id)
                  );
                } else {
                  setCheckedData([...checkedData, row]);
                }
              }}
              checked={
                checkedData?.find((obj) => obj?.id === row?.id) ? true : false
              }
              sx={{ margin: 0, padding: 0 }}
              icon={<Icon icon="unchecked-checkbox" size={23} />}
              checkedIcon={<Icon icon="checked-checkbox" size={23} />}
            />
            <StackedProfile
              profilePicture={
                row?.profilePicture
                  ? fileBaseUrl + row?.profilePicture
                  : Images.AVA_DEFAULT
              }
              title={row?.name}
              onClick={() => {
                if (activeTab?.toLowerCase() !== "existing") {
                  setSelectedCV(row)
                }
              }}
            />
          </div>
        ),
      },
      {
        name: "position",
        label: "Posisi",
        align: "left",
      },
      {
        name: "portal",
        label: "Sumber",
        align: "left",
      },
      {
        name: "email",
        label: "Email",
        align: "left",
      },
      {
        name: "phoneNumber",
        label: "Nomor Handphone",
        align: "left",
      },
      {
        name: "domicile",
        label: "Domisili",
        align: "left",
        minWidth: "170px",
      },
      {
        name: "address",
        label: "Alamat",
        align: "left",
        minWidth: "300px",
      },
      {
        name: "lastEducation",
        label: "Pendidikan",
        align: "left",
      },
    ];
  }, [activeTab, checkedData]);

  const tabs = [
    {
      title: "Private",
      query: "private",
    },
    {
      title: "Publik",
      query: "public",
    },
    {
      title: "Existing",
      query: "existing",
    },
  ];

  const disableSave = useMemo(() => {
    if (!checkedData?.length || submitting) {
      return true;
    }
    return false;
  }, [checkedData?.length, submitting]);

  const handleSave = async () => {
    try {
      setSubmitting(true);
      let payload = {
        positionID: selectedPosition?.positionID,
        type: "PUBLIC",
        candidates: [],
      };
      if (activeTab?.toLowerCase() === "public") {
        payload.candidates = checkedData?.map((obj) => obj?.id);
      } else if (activeTab?.toLowerCase() === "private") {
        payload.type = "PRIVATE";
        payload.candidates = checkedData?.map((obj) => obj?.id);
      } else {
        payload.type = "EXISTING";
        payload.candidates = checkedData?.map((obj) => omitKey("id", obj));
      }
      await addExisting(payload);
      setTrigger(makeRandomString(5));
      setSubmitting(false);
      onClose();
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
      console.log(err);
      setSubmitting(false);
    }
  };

  const mainTemplate = useMemo(() => {
    return [
      {
        title: "Pilih posisi",
        component: (
          <OutlinedSearchBar
            value={searchTerm}
            setValue={setSearchTerm}
            placeholder={"Cari posisi"}
            disabled={false}
          />
        ),
      },
      {
        title: "Pilih kandidat",
        componentSearch: (
          <div>
            {" "}
            <Input
              value={domicileSearch}
              onChange={(e) => setDomicileSearch(e?.target?.value)}
              floatingStartAdornment={<Icon icon={"map-outline"} size={20} />}
              startAdornmentDefaultPaddingLeft={"40px"}
              placeholder={"Domisili"}
            />
          </div>
        ),
        component: (
          <div className={Styles.tableWrapper}>
            <table>
              <thead>
                <tr>
                  {columns?.map((each, i) => (
                    <th key={i} style={{ minWidth: each?.minWidth || "auto" }}>
                      {each?.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {candidates?.map((each, i) => (
                  <tr key={i}>
                    {columns?.map((eachC, iC) => (
                      <td key={iC}>
                        {eachC?.renderCell
                          ? eachC?.renderCell(each)
                          : each[eachC?.name] || "-"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {!candidates?.length ? (
              <div className={Styles.noData}>
                <span>
                  {!searchTerm
                    ? "Posisi Belum Dipilih"
                    : isLoading
                      ? "Tunggu sebentar"
                      : "Data Kosong"}
                </span>
                <span>
                  {!searchTerm
                    ? "Pilih posisi untuk menampilkan candidates kandidat"
                    : isLoading
                      ? "Data sedang dimuat"
                      : `Data tidak ada atau data belum ada`}
                </span>
              </div>
            ) : (
              <div className={Styles.filler} />
            )}
            <div className={Styles.pagination}>
              <Pagination
                total={totalData}
                totalPage={totalPage}
                value={10}
                setValue={() => { }}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                noPageList
                noValueSetter
              />
            </div>
          </div>
        ),
      },
    ];
  }, [
    searchTerm,
    domicileSearch,
    columns,
    candidates,
    isLoading,
    totalData,
    totalPage,
    selectedPage,
  ]);

  const [anchorEl, setAnchorEl] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogout = (isClient) => {
    sessionStorage.clear();
    localStorage.clear();
    if (
      role === "BUSINESS MANAGER" ||
      role === "BUSINESS DIRECTOR" ||
      role === "RECRUITMENT OFFICER"
    ) {
      if (isClient) {
        navigate("/login");
      } else {
        navigate("/sign-in");
      }
    } else {
      navigate("/");
    }
  };

  return (
    <div
      className={`${Styles.bodyModal} ${fullscreen && Styles.fullSecreenBody}`}
    >
      <div
        className={`${Styles.container} ${fullscreen && Styles.fullScreenContainer
          }`}
        ref={boxRef}
      >
        <div className={Styles.stickyBox}>
          {fullscreen ? (
            <>
              <div className={Styles.navbarModal}>
                <div className={Styles.leftNavbarResp}>
                  <div
                    className={Styles.imagesBox}
                    onClick={() => {
                      if (anchorEl === false) {
                        setAnchorEl(true);
                        setOpen(false);
                      } else {
                        setAnchorEl(false);
                        setOpen(false);
                      }
                    }}
                  >
                    <img
                      src={
                        profilePicture && profilePicture !== "null"
                          ? profilePicture?.includes("http")
                            ? profilePicture?.replace(
                              "https://myworkspace",
                              "https://api1.myworkspace"
                            )
                            : fileBaseUrl + profilePicture
                          : Images.AVA_DEFAULT
                      }
                      alt="ava-default"
                    />
                  </div>
                  <Icon icon={"bell"} size={22} />
                  {anchorEl && (
                    <div className={Styles.Popover}>
                      <div className={Styles.StackedTop}>
                        <Icon icon="user" size={17} />
                        <span>Profile</span>
                      </div>

                      <div className={Styles.Stacked} onClick={() => handleLogout((localStorage?.getItem('isClient') === 'true' || localStorage?.getItem('isClient') === true) ? true : false)}>
                        <Icon icon="log-out" size={17} color="#FF3E13" />
                        <span>Log Out</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className={Styles.leftNavbar}>
                  <div className={Styles.images365}>
                    <img
                      className={Styles.logo}
                      src={Images.KERJA365_LIGHT}
                      alt="KERJA_365_LOGO"
                      onClick={() => navigate("/")}
                    />
                  </div>
                  <span className={Styles.rekrutment}>Rekrutmen</span>
                </div>
                <div className={Styles.burgerWrapper}>
                  {open ? (
                    <Icon
                      icon={open ? "cross" : ""}
                      size={20}
                      onClick={() => {
                        if (open === true) {
                          setOpen(false);
                          setAnchorEl(false);
                        }
                      }}
                    />
                  ) : (
                    <Icon
                      icon="burger-icon"
                      size={20}
                      onClick={() => {
                        if (open === false) {
                          setOpen(true);
                          setAnchorEl(false);
                        }
                      }}
                    />
                  )}
                </div>
                <div className={Styles.rightNavbar}>
                  <Icon icon={"bell"} size={22} />
                  <div className={Styles.divider}></div>
                  <div
                    className={Styles.profile}
                    onClick={() => {
                      if (anchorEl === false) {
                        setAnchorEl(true);
                      } else {
                        setAnchorEl(false);
                      }
                    }}
                  >
                    <div className={Styles.profileImages}>
                      <img
                        src={
                          profilePicture && profilePicture !== "null"
                            ? profilePicture?.includes("http")
                              ? profilePicture?.replace(
                                "https://myworkspace",
                                "https://api1.myworkspace"
                              )
                              : fileBaseUrl + profilePicture
                            : Images.AVA_DEFAULT
                        }
                        alt="ava-default"
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className={Styles.descBox}>
                      <div className={Styles.nameDesc}>
                        {userName ? toTitleCase(userName) : "-"}
                      </div>
                      <div className={Styles.roleDesc}>
                        {role ? toTitleCase(role) : "-"}
                      </div>
                    </div>
                  </div>
                  {anchorEl && (
                    <div className={Styles.Popover}>
                      <div className={Styles.StackedTop}>
                        <Icon icon="user" size={17} />
                        <span>Profile</span>
                      </div>

                      <div className={Styles.Stacked} onClick={() => handleLogout((localStorage?.getItem('isClient') === 'true' || localStorage?.getItem('isClient') === true) ? true : false)}>
                        <Icon icon="log-out" size={17} color="#FF3E13" />
                        <span>Log Out</span>
                      </div>
                    </div>
                  )}
                </div>
                {open && (
                  <div className={Styles.dropdownNavbar}>
                    <div className={Styles.box}>
                      <Link
                        to={"/"}
                        className={`${Styles.navLink} ${pathname === "/" && Styles.navLinkActive
                          }`}
                      >
                        <Icon
                          icon="dashboard-outline"
                          color="#757575"
                          size={20}
                        />
                        <span className={Styles.routeText}>Halaman Utama</span>
                      </Link>
                      <Link
                        to={"/job-posting"}
                        className={`${Styles.navLink} ${pathname === "/job-posting" && Styles.navLinkActive
                          }`}
                      >
                        <Icon icon="report-outline" color="#757575" size={20} />
                        <span className={Styles.routeText}>Job Posting</span>
                      </Link>
                      <Link
                        to={"/rekrutmen"}
                        className={`${Styles.navLink} ${pathname === "/rekrutmen" ||
                          pathname === "/rekrutmen/details"
                          ? Styles.navLinkActive
                          : ""
                          }`}
                      >
                        <Icon
                          icon="recruitment-outline"
                          color="#757575"
                          size={20}
                        />
                        <span className={Styles.routeText}>Rekrutmen</span>
                      </Link>
                      <Link
                        to={"/klien"}
                        className={`${Styles.navLink} ${pathname === "/klien" && Styles.navLinkActive
                          }`}
                      >
                        <Icon
                          icon="company-outline"
                          color="#757575"
                          size={20}
                        />
                        <span className={Styles.routeText}>Klien</span>
                      </Link>
                      <Link
                        to={"/chat"}
                        className={`${Styles.navLink} ${pathname === "/chat" && Styles.navLinkActive
                          }`}
                      >
                        <Icon icon="chat" color="#757575" size={20} />
                        <span className={Styles.routeText}>Chat</span>
                      </Link>
                      <Link
                        to={"/tips-&-trick"}
                        className={`${Styles.navLink} ${pathname === "/tips-&-trick" && Styles.navLinkActive
                          }`}
                      >
                        <Icon icon="bulb" color="#757575" size={20} />
                        <span className={Styles.routeText}>Tips & Trick</span>
                      </Link>
                    </div>
                    <div
                      className={Styles.null}
                      onClick={() => setOpen(false)}
                    ></div>
                  </div>
                )}
              </div>
            </>
          ) : null}
          <div
            className={`${Styles.header} ${!fullscreen && Styles.stickyHeader}`}
            onClick={() => setAnchorEl(false)}
          >
            <h3>Tambah Kandidat </h3>
            <div className={Styles.leftButton}>
              {fullscreen ? (
                <Icon
                  icon={"zoom-out"}
                  size={20}
                  color={"#000000"}
                  className={Styles.fullScreen}
                  onClick={() => setFullscreen(false)}
                />
              ) : (
                <Icon
                  icon={"fullscreen-mode"}
                  size={20}
                  color={"#000000"}
                  className={Styles.fullScreen}
                  onClick={() => setFullscreen(true)}
                />
              )}

              <button onClick={onClose}>
                <Icon icon={"cross"} size={24} />
              </button>
            </div>
          </div>
        </div>

        <div className={Styles.content} onClick={() => setAnchorEl(false)}>
          <div className={Styles.tabs}>
            {tabs?.map((obj, i) => (
              <div
                key={i}
                className={activeTab === obj?.query ? Styles.active : ""}
                onClick={() => setActiveTab(obj?.query)}
              >
                {obj?.title}
              </div>
            ))}
          </div>
          {!selectedCV ? (
            <div className={Styles.main}>
              {mainTemplate?.map((each, i) => (
                <div key={i} className={Styles.each}>
                  <div>{i + 1}</div>
                  <div>
                    <div className={Styles.topTitleCandidate}>
                      <span>{each?.title}</span>
                      {each?.componentSearch}
                    </div>

                    {each?.component}
                  </div>
                  {i !== mainTemplate?.length - 1 && (
                    <div className={Styles.line} />
                  )}
                </div>
              ))}
              <div
                className={`${Styles.errorWrapper} ${error ? Styles.visible : Styles.hide
                  }`}
              >
                <span>{error || "error placeholder"}</span>
              </div>
              <button disabled={disableSave} onClick={handleSave}>
                {!submitting ? "Simpan" : "Menyimpan..."}
              </button>
            </div>
          ) : (
            <div className={Styles.cv}>
              <button onClick={() => setSelectedCV(null)}>
                <Icon icon={"arrow-left-back"} size={24} />
                <span>Kembali</span>
              </button>
              <CandidateResumeLayout candidates={selectedCV} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
