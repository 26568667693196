import { useEffect, useRef } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Spinner from "@Atom/Spinner";

export default function ResendWa({
  handleClose,
  action,
  handleDone,
  isLoading,
  error,
}) {
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);
  return (
    <div className={Styles.bodyResend}>
      <div className={Styles.container} ref={boxRef}>
        <div className={Styles.iconWrapper}>
          <div className={Styles.title}>Kirim Ulang</div>
          <div className={Styles.text}>
            Apa kamu yakin untuk mengirim ulang?
          </div>
        </div>

        <div className={Styles.bottom}>
          {error ? <span className={Styles.error}>{error}</span> : null}
          <div className={Styles.buttons}>
            <div className={Styles.secondaryButton} onClick={handleClose}>
              <div className={Styles.text}>Batal</div>
            </div>
            <div
              className={`${Styles.primaryButton}`}
              onClick={isLoading ? null : action}
            >
              <div className={`${Styles.text}`}>Ya, Kirim ulang</div>
              {isLoading ? <Spinner /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
