import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useState } from "react";
import ArticleForm from "./ArticleForm";
import VideoForm from "./VideoForm";
import TipsAndTricksPostOption from "@Molecule/TipsAndTricksPostOption";

const CreateTipsAndTricks = () => {
  const [type, setType] = useState("Artikel");

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <div className={Styles.backButton}>
            <Icon icon="arrow-left" size="24" className={Styles.icon} />
          </div>
          <div className={Styles.navigation}>
            <div className={Styles.previousPageText}>Tips & Trick</div>
            <Icon icon="arrow-right" size="18" className={Styles.icon} />
            <div className={Styles.currentPageText}>New Post</div>
          </div>
        </div>
        <div className={Styles.title}>Buat Postingan</div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.form}>
          <div className={Styles.topField}>
            <div className={Styles.fieldTitle}>Tipe Postingan</div>
            <div className={Styles.optionsWrapper}>
              <TipsAndTricksPostOption
                title="Video"
                icon="article"
                onClick={() => setType("Video")}
                isActive={type === "Video"}
              />
              <TipsAndTricksPostOption
                title="Article"
                icon="article"
                onClick={() => setType("Artikel")}
                isActive={type === "Artikel"}
              />
            </div>
            {/* <Dropdown
              value={type}
              setValue={setType}
              placeholder={"Sortir"}
              options={[
                {
                  name: "Artikel",
                },
                {
                  name: "Video",
                },
              ]}
              autoClose
            /> */}
          </div>
          {type === "Artikel" ? <ArticleForm /> : <VideoForm />}
        </div>
        <div className={Styles.buttons}>
          <div className={Styles.secondaryButton}>
            <div className={Styles.text}>Draft</div>
          </div>
          <div className={Styles.primaryButton}>
            <div className={Styles.text}>Simpan</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTipsAndTricks;
