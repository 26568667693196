import Dropdown from '@Atom/DropDown'
import Icon from '@Atom/Icon'
import DecorativeInputField from '@Molecule/DecorativeInputField'
import DinamicDecorativeInputField from '@Molecule/DinamicDecorativeInputField'
import MultiSelectSmallLabel from '@Molecule/MultiSelectSmallLabel'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'


export default function FirstSubStep(props) {
  const {
    steps,
    options,
    form,
    setForm,
    locationIndex,
    manPowerIndex,
    // eslint-disable-next-line no-unused-vars
    trigger,
    setTrigger,
    debounceTrigger,
    onNext
  } = props;
  const [contractType, setContractType] = useState(form[locationIndex]?.jobs[manPowerIndex]?.contractType)
  const defaultSalary = useMemo(() => {
    return form[locationIndex]?.umk
  }, [form, locationIndex])
  const [salary, setSalary] = useState(form[locationIndex]?.jobs[manPowerIndex]?.salary || defaultSalary)
  const [salaryPeriod, setSalaryPeriod] = useState(
    form[locationIndex]?.jobs[manPowerIndex]?.salaryPeriod || ''
  );

  const [personalEquipments, setPersonalEquipments] = useState(form[locationIndex]?.jobs[manPowerIndex]?.personalEquipments)
  const [allowances, setAllowances] = useState(form[locationIndex]?.jobs[manPowerIndex]?.allowances)

  const fixedAllowanceOptions = useMemo(() => {
    const allowancesOptions = options?.allowance

    return allowancesOptions?.map((each) => {
      const existing = allowances?.find(el => el?.name === each.name)
      return {
        name: each.name,
        value: each.value,
        id: existing?.id
      }
    })
  }, [allowances, options])

  const fixedPersonalEquipmentOptions = useMemo(() => {
    const allowancesOptions = options?.personalWorkEquipment

    return allowancesOptions?.map((each) => {
      const existing = personalEquipments?.find(el => el?.name === each.name)
      return {
        name: each.name,
        value: each.value,
        id: existing?.id
      }
    })
  }, [personalEquipments, options])

  const handleChangeAllowance = useCallback((name, value, allowanceIndex, locationIndex) => {
    const data = [...allowances]
    const index = data.findIndex(obj => {
      return obj.name === name
    })
    let parsedValue;
    if (value?.length > 0) {
      parsedValue = value
    } else {
      parsedValue = 0
    }
    data[index].value = parsedValue
    setAllowances(data)
  }, [allowances])

  useEffect(() => {
  }, [allowances?.length, personalEquipments?.length])


  const handleDeleteAllowance = useCallback((name) => {
    const data = [...allowances]
    const filtered = data.filter((el) => el.name !== name)
    setAllowances(filtered)
    setTrigger(Math.random())
  }, [allowances, setTrigger])

  const handleChangePersonalEquipment = useCallback((name, value, equipmentIndex) => {
    const newPersonalEquipments = [...personalEquipments]
    let parsedValue;
    if (value?.length > 0) {
      parsedValue = value
    } else {
      parsedValue = 0
    }
    newPersonalEquipments[equipmentIndex].value = parsedValue
    setPersonalEquipments(newPersonalEquipments)
  }, [personalEquipments])

  const handleDeletePersonalEquipments = useCallback((name, equipmentIndex, locationIndex) => {
    const newEquipments = [...personalEquipments]
    newEquipments.splice(equipmentIndex, 1)
    setPersonalEquipments(newEquipments)
    setTrigger(Math.random())
  }, [personalEquipments, setTrigger])

  useEffect(() => {
    if (allowances?.length > 0) {
      form[locationIndex].jobs[manPowerIndex].allowances = allowances
    } else {
      form[locationIndex].jobs[manPowerIndex].allowances = []
    }
  }, [form, locationIndex, manPowerIndex, allowances, setForm])

  useEffect(() => {
    if (personalEquipments?.length > 0) {
      form[locationIndex].jobs[manPowerIndex].personalEquipments = personalEquipments
    } else {
      form[locationIndex].jobs[manPowerIndex].personalEquipments = []
    }
  }, [form, locationIndex, manPowerIndex, personalEquipments])

  useEffect(() => {
    form[locationIndex].jobs[manPowerIndex].contractType = contractType
    form[locationIndex].jobs[manPowerIndex].salary = salary
    form[locationIndex].jobs[manPowerIndex].salaryPeriod = salaryPeriod;
  }, [form, contractType, salary, locationIndex, manPowerIndex, setForm, setTrigger, salaryPeriod])

  const disableNext = useMemo(() => {
    if (!contractType || !salary || !salaryPeriod) {
      return true
    } else if (allowances?.length > 0) {
      for (let i = 0; i < allowances.length; i++) {
        if (!allowances[i]?.value) {
          return true
        }
      }
    }

    if (personalEquipments?.length > 0) {
      for (let i = 0; i < personalEquipments.length; i++) {
        if (!personalEquipments[i]?.value || personalEquipments[i]?.value === 0 || personalEquipments[i]?.value === '') {
          return true
        }
      }
    }
    return false
  }, [contractType, salary, salaryPeriod, allowances, personalEquipments])

  useEffect(() => {
    const newSteps = [...steps]
    if (disableNext) {
      newSteps[0].isDone = false
    } else {
      newSteps[0].isDone = true
    }
    setTrigger(Math.random())

    //eslint-disable-next-line
  }, [steps, disableNext])

  useEffect(() => {
    debounceTrigger()
  }, [debounceTrigger, contractType, salary, allowances, personalEquipments])

  return (
    <div className={Styles.container}>
      <div className={Styles.boxInput}>
        <div className={Styles.label}>Contract Type<span className={Styles.asterisk}>&#x2a;</span></div>
        <div className={Styles.selectWrapper}>
          <Dropdown
            placeholder="Select"
            options={options?.contractTypeWorker}
            value={contractType}
            setValue={setContractType}
            autoClose={true}
            required
          />
        </div>
      </div>
      <div className={Styles.boxInput}>
        <div className={Styles.dualInputWrapper}>
          <DecorativeInputField
            value={salary}
            setValue={setSalary}
            title="Salary"
            adornment="Rp"
            type="currency"
            required
          />
          <Dropdown
            options={['Harian', 'Mingguan', 'Bulanan']?.map(obj => {
              return {
                name: obj
              }
            })}
            placeholder={'Harian/mingguan/bulanan'}
            value={salaryPeriod}
            setValue={setSalaryPeriod}
          />
        </div>
      </div>
      <div className={Styles.boxInput}>
        <div className={Styles.selectWrapper}>
          <MultiSelectSmallLabel
            title="Allowance"
            placeholder="Select"
            options={fixedAllowanceOptions}
            labels={allowances}
            setLabels={setAllowances}
            preventDeleteIndicator={"id"}
          />
        </div>
        {allowances?.length > 0 && (
          <div className={Styles.allowancesWrapper}>
            {
              allowances?.map((each, i) => {
                const index = allowances.findIndex(x => x.name === each?.name)
                return (
                  <div key={i} className={Styles.inputWrapper}>
                    <DinamicDecorativeInputField
                      onChange={handleChangeAllowance}
                      onDelete={() => handleDeleteAllowance(each?.name)}
                      name={each?.name}
                      value={each?.value}
                      index={index}
                      locationIndex={locationIndex}
                      title={each?.name}
                      adornment="Rp"
                      type="currency"
                      preventDelete={each?.id ? true : false}
                    />
                  </div>
                )
              })
            }
          </div>
        )}
      </div>
      <div className={Styles.boxInput}>
        <div className={Styles.selectWrapper}>
          <MultiSelectSmallLabel
            title="Personal Work Equipment"
            placeholder="Select"
            options={fixedPersonalEquipmentOptions}
            labels={personalEquipments}
            setLabels={setPersonalEquipments}
            preventDeleteIndicator={"id"}
          />
        </div>
        {personalEquipments?.length > 0 && (
          <div className={Styles.equipmentsWrapper}>
            {
              personalEquipments?.map((each, i) => {
                return (
                  <div key={i} className={Styles.inputWrapper}>
                    <DinamicDecorativeInputField
                      onChange={handleChangePersonalEquipment}
                      onDelete={handleDeletePersonalEquipments}
                      name={each?.name}
                      value={each?.value || ''}
                      index={i}
                      locationIndex={locationIndex}
                      title={each?.name}
                      adornment="Rp"
                      type="currency"
                      preventDelete={each?.id ? true : false}
                    />
                  </div>
                )
              })
            }
          </div>
        )}
      </div>
      <div />
      <div className={Styles.buttonWrapper}>
        <button disabled={disableNext} onClick={onNext}>
          <span>Next</span>
          <Icon icon="arrow-right" size={18} />
        </button>
      </div>
    </div>
  )
}