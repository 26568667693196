/* eslint-disable no-unused-vars */
import ImagesFiles from "./Images";
import VideosFiles from "./Videos";
import Styles from "./styles.module.scss";

export default function UploadBody({
  fileVideos,
  setFilesVideos,
  previewVideos,
  setPreviewVideos,
  fileImages,
  setFileImages,
  previewImages,
  setPreviewImages,
  typeImages,
  setTypeImages,
  typeVideos,
  setTypeVideos,
  dataListClient,
  setAcceptedFiles,
  validate,
}) {
  return (
    <div className={Styles.containerUpload}>
      <ImagesFiles
        dataListClient={dataListClient}
        setFileImages={setFileImages}
        fileImages={fileImages}
        setPreviewImages={setPreviewImages}
        previewImages={previewImages}
        typeImages={typeImages}
        setTypeImages={setTypeImages}
        validate={validate}
      />
      <VideosFiles
        setTypeVideos={setTypeVideos}
        typeVideos={typeVideos}
        setFilesVideos={setFilesVideos}
        fileVideos={fileVideos}
        setPreviewVideos={setPreviewVideos}
        previewVideos={previewVideos}
        setAcceptedFiles={setAcceptedFiles}
        validate={validate}
      />
    </div>
  );
}
