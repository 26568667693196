/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import Images from "@Theme/Images";

export default function ModalDetailClient({ handleClose, data }) {
  const [selectedButton, setSelectedButton] = useState("Informasi Perusahaan");
  const maxLength = 20;

  // console.log(data);

  const setActiveButton = (idButton) => {
    setSelectedButton(idButton);
    // sessionStorage.setItem("idButtonAssignment", idButtonAssignment);
  };

  useEffect(() => {
    if (selectedButton) {
      setSelectedButton(selectedButton);
    } else {
      setSelectedButton("Informasi Perusahaan");
    }
  }, [selectedButton]);

  // console.log(data);

  return (
    <div className={Styles.body}>
      <div>
        <div>
          <div className={Styles.left}>
            <div>
              <Icon icon={"building-office"} size={20} color={"#9E9E9E"} />
            </div>
            <div>Detail Klien</div>
          </div>

          <Icon
            icon={"cross"}
            size={20}
            color={"#000000"}
            onClick={handleClose}
            className={Styles.close}
          />
        </div>

        <div>
          <div
            className={`${
              selectedButton === "Informasi Perusahaan" && Styles.active
            }`}
            onClick={() => setActiveButton("Informasi Perusahaan")}
          >
            Informasi Perusahaan
          </div>
          <div
            className={`${
              selectedButton === "Dokumen Perusahaan" && Styles.active
            }`}
            onClick={() => setActiveButton("Dokumen Perusahaan")}
          >
            Dokumen Perusahaan
          </div>
          <div
            className={`${selectedButton === "Lainnya" && Styles.active}`}
            onClick={() => {
              setActiveButton("Lainnya");
            }}
          >
            Lainnya
          </div>
        </div>
      </div>

      {selectedButton === "Informasi Perusahaan" && (
        <div className={Styles.firstStep}>
          <div>
            <span>Nama</span>
            <span>{data?.clientProject?.name?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>Nama Resmi Perusahaan (terdaftar secara legal)</span>
            <span>{data?.clientProject?.legalName?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>Nama Grup</span>
            <span>{data?.clientProject?.groupName?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>Nama Brand</span>
            <span>{data?.clientProject?.brandName?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>Industri</span>
            <span>{data?.clientProject?.industry?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>NPWP</span>
            <span>{data?.clientProject?.taxNumber || "-"}</span>
          </div>
          <div>
            <span>Provinsi</span>
            <span>{data?.clientProject?.province?.toUpperCase() || "-"}</span>
          </div>

          <div>
            <span>Alamat</span>
            <span>{data?.clientProject?.address?.toUpperCase() || "-"}</span>
          </div>
          <div>
            <span>Kode Pos</span>
            <span>{data?.clientProject?.postal || "-"}</span>
          </div>
          <div>
            <span>Nomor Telpon</span>
            <span>{data?.clientProject?.phoneNumber || "-"}</span>
          </div>
          <div>
            <span>Email</span>
            <span>{data?.clientProject?.email || "-"}</span>
          </div>
          <div>
            <span>Website</span>
            <span>{data?.clientProject?.website || "-"}</span>
          </div>
        </div>
      )}

      {selectedButton === "Dokumen Perusahaan" && (
        <div className={Styles.secondStep}>
          <div className={Styles.cards}>
            <div>
              <div className={Styles.card}>
                <span>Akta Perusahaan</span>
                <div>
                  <div className={Styles.images}>
                    <img
                      src={
                        data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "akta client"
                        )
                          ? Images.NOTE_SHEET
                          : Images.FOLDER
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    {data?.humanResources?.clientDocuments?.find(
                      (el) => el?.type?.toLowerCase() === "akta client"
                    )
                      ? data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "akta client"
                        )?.url
                      : "No Files Yet"}
                  </div>

                  {data?.humanResources?.clientDocuments?.find(
                    (el) => el?.type?.toLowerCase() === "akta client"
                  ) ? (
                    <div
                      onClick={() =>
                        window.open(
                          `/view?u=${
                            data?.humanResources?.clientDocuments?.find(
                              (el) => el?.type?.toLowerCase() === "akta client"
                            )?.url
                          }`,
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      }
                    >
                      See File
                    </div>
                  ) : (
                    <div className={Styles.noActive}>
                      Still no uploaded file
                    </div>
                  )}
                </div>
              </div>
              <div className={Styles.card}>
                <span>NPWP</span>
                <div>
                  <div className={Styles.images}>
                    <img
                      src={
                        data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "npwp client"
                        )
                          ? Images.NOTE_SHEET
                          : Images.FOLDER
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    {" "}
                    {data?.humanResources?.clientDocuments?.find(
                      (el) => el?.type?.toLowerCase() === "npwp client"
                    )
                      ? data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "npwp client"
                        )?.url
                      : "No Files Yet"}
                  </div>
                  {data?.humanResources?.clientDocuments?.find(
                    (el) => el?.type?.toLowerCase() === "npwp client"
                  ) ? (
                    <div
                      onClick={() =>
                        window.open(
                          `/view?u=${
                            data?.humanResources?.clientDocuments?.find(
                              (el) => el?.type?.toLowerCase() === "npwp client"
                            )?.url
                          }`,
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      }
                    >
                      See File
                    </div>
                  ) : (
                    <div className={Styles.noActive}>
                      Still no uploaded file
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className={Styles.card}>
                <span>SIUP</span>
                <div>
                  <div className={Styles.images}>
                    <img
                      src={
                        data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "siup client"
                        )
                          ? Images.NOTE_SHEET
                          : Images.FOLDER
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    {" "}
                    {data?.humanResources?.clientDocuments?.find(
                      (el) => el?.type?.toLowerCase() === "siup client"
                    )
                      ? data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "siup client"
                        )?.url
                      : "No Files Yet"}
                  </div>
                  {data?.humanResources?.clientDocuments?.find(
                    (el) => el?.type?.toLowerCase() === "siup client"
                  ) ? (
                    <div
                      onClick={() =>
                        window.open(
                          `/view?u=${
                            data?.humanResources?.clientDocuments?.find(
                              (el) => el?.type?.toLowerCase() === "siup client"
                            )?.url
                          }`,
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      }
                    >
                      See File
                    </div>
                  ) : (
                    <div className={Styles.noActive}>
                      Still no uploaded file
                    </div>
                  )}
                </div>
              </div>
              <div className={Styles.card}>
                <span>Domisili</span>
                <div>
                  <div className={Styles.images}>
                    <img
                      src={
                        data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "domisili client"
                        )
                          ? Images.NOTE_SHEET
                          : Images.FOLDER
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    {" "}
                    {data?.humanResources?.clientDocuments?.find(
                      (el) => el?.type?.toLowerCase() === "domisili client"
                    )
                      ? data?.humanResources?.clientDocuments?.find(
                          (el) => el?.type?.toLowerCase() === "domisili client"
                        )?.url
                      : "No Files Yet"}
                  </div>
                  {data?.humanResources?.clientDocuments?.find(
                    (el) => el?.type?.toLowerCase() === "domisili client"
                  ) ? (
                    <div
                      onClick={() =>
                        window.open(
                          `/view?u=${
                            data?.humanResources?.clientDocuments?.find(
                              (el) =>
                                el?.type?.toLowerCase() === "domisili client"
                            )?.url
                          }`,
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      }
                    >
                      See File
                    </div>
                  ) : (
                    <div className={Styles.noActive}>
                      Still no uploaded file
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedButton === "Lainnya" && (
        <div className={Styles.thirdStep}>
          <div>
            <span>Remarks</span>
            <span>{data?.clientProject?.remark || "-"}</span>
          </div>
        </div>
      )}
    </div>
  );
}
