import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { fileBaseUrl } from "@Config/api";
import Pagination from "@Molecule/Pagination";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";

export default function ListModeRecruitment({
  data,
  isLoading,
  setShowModalDetail,
  value,
  setValue,
  pages,
  setPages,
  pageList,
  setPageList,
  selectedPage,
  setSelectedPage,
  totalPage,
  setTotalPage,
  selectedButtonStepRecruitment,
  isDisabled,
  selectedButton,
  selectedButtonStepContract,
  setShowDetailCandidate,
  setInterviewRO,
  setShowStepBack,
  showNotif,
  setShowNotif,
}) {
  // const [value, setValue] = useState(5);
  // const [pages, setPages] = useState(`1-${value}`);
  // const [pageList, setPageList] = useState([]);
  // const [selectedPage, setSelectedPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(1);
  // const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [selectedTransfer, setSelectedTransfer] = useState(false);
  const [selectedbackStep, setSelectedBackStep] = useState(false);
  const maxLength = 18;

  const ref = useRef(null);
  const refChild = useRef(null);
  const refBackStep = useRef(null);

  // useEffect(() => {
  //   if (!selectedDropdown) {
  //     setSelectedTransfer(false);
  //     setSelectedBackStep(false);
  //   }
  // }, [selectedDropdown]);

  const boxOutsideClick = UseOutsideClick(ref);
  const boxOutsideChild = UseOutsideClick(refChild);
  const boxOutsideBackStep = UseOutsideClick(refBackStep);

  useEffect(() => {
    if (selectedDropdown && boxOutsideClick) {
      setSelectedDropdown(null);
    }
  }, [selectedDropdown, boxOutsideClick]);

  useEffect(() => {
    if (selectedTransfer && boxOutsideChild) {
      setSelectedTransfer(false);
    }
  }, [selectedTransfer, boxOutsideChild]);

  useEffect(() => {
    if (selectedbackStep && boxOutsideBackStep) {
      setSelectedBackStep(false);
    }
  }, [selectedbackStep, boxOutsideBackStep]);

  const backupRef = useRef();
  const TABLE_HEAD = useMemo(() => {
    if (selectedButton === "Rekrutmen") {
      if (
        selectedButtonStepRecruitment === "Undang Interview Klien" ||
        selectedButtonStepRecruitment === "Kirim Rekomendasi"
      ) {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkJobInterview",
            label: "Undangan Interview 1",
            align: "left",
          },
          {
            id: "linkJobInterviewClient",
            label: "Undangan Interview 2",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          { id: "action", label: "", align: "center" },
        ];
      } else if (selectedButtonStepRecruitment === "Kirim Offering") {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkJobInterview",
            label: "Link Offering",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          { id: "action", label: "", align: "center" },
        ];
      } else {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkJobInterview",
            label: "Undangan Interview 1",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          { id: "action", label: "", align: "center" },
        ];
      }
    } else {
      if (selectedButtonStepContract === "TTD Kontrak") {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          // { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkOffering",
            label: "Link Offering",
            align: "left",
          },
          {
            id: "linkContract",
            label: "Link TTD Kontrak",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          // { id: "action", label: "", align: "center" },
        ];
      } else if (selectedButtonStepContract === "Joined") {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          // { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkOffering",
            label: "Link Offering",
            align: "left",
          },
          {
            id: "linkContract",
            label: "Link TTD Kontrak",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          // { id: "action", label: "", align: "center" },
        ];
      } else if (selectedButtonStepContract === "Verifikasi Kedua") {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkOffering",
            label: "Link Offering",
            align: "left",
          },
          {
            id: "linkContract",
            label: "Link TTD Kontrak",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          // { id: "action", label: "", align: "center" },
        ];
      } else {
        return [
          { id: "name", label: "Nama Kandidat", align: "left" },
          { id: "status", label: "Status", align: "left" },
          { id: "sumber", label: "Sumber", align: "left" },
          { id: "company", label: "Perusahaan", align: "left" },
          { id: "placement", label: "Lokasi Kerja", align: "left" },
          {
            id: "linkJobPosting",
            label: "Link Job Posting",
            align: "left",
          },
          // { id: "location", label: "Lokasi", align: "left" },
          {
            id: "linkOffering",
            label: "Link Offering",
            align: "left",
          },
          { id: "email", label: "Email & Nomor Handpone", align: "left" },
          { id: "domicile", label: "Domisili", align: "left" },
          { id: "address", label: "Alamat", align: "left" },
          // { id: "action", label: "", align: "center" },
        ];
      }
    }
  }, [
    selectedButtonStepRecruitment,
    selectedButton,
    selectedButtonStepContract,
  ]);

  const handleOpenJobPost = (link) => {
    window.open(link);
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setShowNotif(true);
      setTimeout(() => {
        setShowNotif(false);
      }, 1000);
    });
  };
  return (
    <div
      ref={backupRef}
      className={`${Styles.tableWrapper}  ${
        data?.data?.length === 0 || isLoading ? Styles.grow : ""
      }`}
    >
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.data?.length === 0 || isLoading ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={"headcCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`${Styles.tableHeadCell} ${
                    !isDisabled
                      ? eachH?.id === "action"
                        ? Styles.status
                        : ""
                      : ""
                  } ${
                    !isDisabled
                      ? iH !== 0 && iH !== TABLE_HEAD?.length - 1
                        ? Styles.hideOnMobile
                        : ""
                      : iH !== 0
                      ? Styles.hideOnMobile
                      : ""
                  }`}
                >
                  <div>
                    <>
                      <span>{eachH?.label}</span>
                    </>
                  </div>
                  {/* {expandStatus && eachH?.id === "status" && (
                    <div className={Styles.statusOption}>option</div>
                  )} */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {data?.data?.length > 0 && !isLoading && (
            <TableBody className={Styles.tableBody}>
              {data?.data?.map((eachD, iD) => {
                return (
                  <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                    <TableCell
                      className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                      style={{
                        zIndex: selectedDropdown?.id === eachD?.id ? 1 : 0,
                      }}
                    >
                      <div className={Styles.showMobile}>
                        <button
                          className={Styles.stackedProfile}
                          onClick={() => {
                            setShowDetailCandidate(eachD);
                          }}
                          // onClick={() => handleViewInfo(eachD)}
                        >
                          {eachD?.CandidateDocuments ? (
                            <Avatar
                              alt={eachD?.name}
                              src={fileBaseUrl + eachD?.CandidateDocuments.url}
                              sx={{ width: 36, height: 36 }}
                            />
                          ) : (
                            <Avatar sx={{ width: 36, height: 36 }}>
                              {eachD?.name[0]}
                            </Avatar>
                          )}
                          <div className={Styles.profile}>
                            <span>{eachD?.name}</span>
                            <div className={Styles.positionBox}>
                              <span>
                                {eachD?.position
                                  ? eachD?.position?.length > maxLength
                                    ? eachD?.position.substring(0, maxLength) +
                                      "..."
                                    : eachD?.position
                                  : "-"}
                              </span>
                              <div></div>
                              <span>{eachD?.contractType || "-"}</span>
                            </div>
                          </div>
                        </button>
                        <div
                          className={Styles.buttonDetail}
                          onClick={() => setShowModalDetail(eachD)}
                        >
                          Detail
                          {/* <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" /> */}
                        </div>
                      </div>
                    </TableCell>

                    {(selectedButtonStepContract === "TTD Kontrak" &&
                      selectedButton === "Kontrak") ||
                    (selectedButtonStepContract === "Joined" &&
                      selectedButton === "Kontrak") ? null : (
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        <div className={Styles.statusBox}>
                          <div
                            className={`${Styles.statusSpan} ${
                              !eachD?.Status && Styles.noStatus
                            } ${
                              eachD?.Status === "Menolak Undangan" && Styles.red
                            } ${
                              eachD?.Status === "Diundang" ||
                              eachD?.Status === "Dikirim"
                                ? Styles.green
                                : ""
                            } ${
                              eachD?.Status === "Tidak Respon" ||
                              eachD?.Status === "Tidak Hadir" ||
                              eachD?.Status === "Tidak Lolos" ||
                              eachD?.Status === "Perbaikan Data" ||
                              eachD?.Status === "Perbaikan Kontrak" ||
                              eachD?.Status === "Gagal Inject" ||
                              eachD?.Status === "Penawaran Ditolak"
                                ? Styles.red
                                : ""
                            } ${
                              eachD?.Status === "Hold" ||
                              eachD?.Status === "Belum Generate" ||
                              eachD?.Status === "Contracted"
                                ? Styles.grey
                                : ""
                            } ${
                              eachD?.Status === "Req. Reschedule" && Styles.pink
                            }`}
                          >
                            {eachD?.Status || "-"}
                          </div>
                        </div>
                      </TableCell>
                    )}

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <span>{eachD?.sumber || "-"}</span>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <span>{eachD?.company || "-"}</span>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <span>{eachD?.placement || "-"}</span>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <div
                        className={Styles.linkText}
                        onClick={() => handleOpenJobPost(eachD?.jobPostLink)}
                      >
                        Lihat Detail
                      </div>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <div
                        className={`${Styles.linkText} ${
                          !eachD?.linkInterviewRO && Styles.disabledLink
                        }`}
                        onClick={() => {
                          if (eachD?.linkInterviewRO) {
                            handleOpenJobPost(eachD?.linkInterviewRO);
                          }
                        }}
                      >
                        Lihat Detail
                      </div>
                    </TableCell>

                    {(selectedButton === "Kontrak" &&
                      selectedButtonStepContract === "TTD Kontrak") ||
                    (selectedButton === "Kontrak" &&
                      selectedButtonStepContract === "Joined") ||
                    (selectedButton === "Kontrak" &&
                      selectedButtonStepContract === "Verifikasi Kedua") ? (
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        <div
                          className={`${Styles.linkText} ${
                            !eachD?.linkContract && Styles.disabledLink
                          }`}
                          onClick={() => {
                            if (eachD?.linkContract) {
                              handleOpenJobPost(eachD?.linkContract);
                            }
                          }}
                        >
                          Lihat Detail
                        </div>
                      </TableCell>
                    ) : null}

                    {(selectedButtonStepRecruitment ===
                      "Undang Interview Klien" &&
                      selectedButton === "Rekrutmen") ||
                    (selectedButtonStepRecruitment === "Kirim Rekomendasi" &&
                      selectedButton === "Rekrutmen") ? (
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        <div
                          className={`${Styles.linkText} ${
                            !eachD?.linkInterviewClient && Styles.disabledLink
                          }`}
                          onClick={() => {
                            if (eachD?.linkInterviewClient) {
                              handleOpenJobPost(eachD?.linkInterviewClient);
                            }
                          }}
                        >
                          Lihat Detail
                        </div>
                      </TableCell>
                    ) : null}

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <div className={Styles.emailBox}>
                        <div>
                          <span>{eachD?.email || "-"}</span>
                          <Icon
                            icon={"duplicate"}
                            size={16}
                            color={"#9E9E9E"}
                            className={Styles.iconCopy}
                            onClick={() => {
                              handleCopy(eachD?.email);
                            }}
                          />
                        </div>
                        <div>
                          <span>{eachD?.phoneNumber || "-"}</span>
                          <Icon
                            icon={"duplicate"}
                            size={16}
                            color={"#9E9E9E"}
                            className={Styles.iconCopy}
                            onClick={() => {
                              handleCopy(eachD?.phoneNumber);
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <span>{eachD?.domicili || "-"}</span>
                    </TableCell>

                    <TableCell
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <span>{eachD?.address ? eachD?.address : "-"}</span>
                    </TableCell>

                    {isDisabled ? null : (
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.stickyRight} ${Styles.status}`}
                        align="center"
                        style={{
                          zIndex: selectedDropdown?.id === eachD?.id ? 1 : 0,
                        }}
                      >
                        <div className={`${Styles.actionBox}`}>
                          <div
                            className={`${
                              eachD?.Status === "Contracted" ||
                              eachD?.Status === "Hold" ||
                              (selectedButton === "Rekrutmen" &&
                                selectedButtonStepRecruitment ===
                                  "Kirim Rekomendasi" &&
                                eachD?.Status === "Dikirim")
                                ? Styles.disabled
                                : ""
                            }`}
                            onClick={() => {
                              if (selectedButton === "Rekrutmen") {
                                if (
                                  selectedButtonStepRecruitment ===
                                  "Kirim Rekomendasi"
                                ) {
                                  if (
                                    eachD?.Status !== "Contracted" &&
                                    eachD?.Status !== "Hold" &&
                                    eachD?.Status !== "Dikirim"
                                  ) {
                                    setSelectedDropdown(eachD);
                                  }
                                } else {
                                  if (
                                    eachD?.Status !== "Contracted" &&
                                    eachD?.Status !== "Hold"
                                  ) {
                                    setSelectedDropdown(eachD);
                                  }
                                }
                              } else {
                                if (
                                  eachD?.Status !== "Contracted" &&
                                  eachD?.Status !== "Hold"
                                ) {
                                  setSelectedDropdown(eachD);
                                }
                              }
                            }}
                          >
                            <Icon
                              icon={"three-dots"}
                              size={16}
                              color={
                                eachD?.Status === "Contracted" ||
                                eachD?.Status === "Hold"
                                  ? "#9E9E9E"
                                  : "#000"
                              }
                            />
                          </div>

                          {selectedDropdown?.id === eachD?.id &&
                          selectedButton === "Rekrutmen" &&
                          selectedButtonStepRecruitment ===
                            "Undang Interview" ? (
                            <div
                              className={`${Styles.selectedDropdown} ${
                                data?.data?.length > 2 &&
                                iD === data?.data?.length - 1 &&
                                Styles.Position
                              }`}
                              ref={ref}
                            >
                              <div
                                onClick={() => {
                                  if (eachD?.Status === "Diundang") {
                                    setInterviewRO(null);
                                    setShowStepBack({
                                      status: "Kirim Ulang Undangan",
                                      name: "Interview RO",
                                      step: 1,
                                      data: eachD,
                                    });
                                  } else {
                                    setInterviewRO({ data: eachD, name: "RO" });
                                  }
                                }}
                              >
                                <span>
                                  {eachD?.Status === "Diundang"
                                    ? "Kirim Ulang Undangan"
                                    : "Undang Interview"}
                                </span>
                              </div>
                              {/* <div
                                onClick={() => {
                                  setSelectedTransfer(true);
                                }}
                              >
                                <span>Alihkan</span>
                                <Icon
                                  icon={"arrow-right"}
                                  size={16}
                                  color={"#1571de"}
                                />

                                {selectedTransfer && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refChild}
                                  >
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToJobPosting",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Job Posting Lain</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Private",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Private</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Public",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Public</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToAnotherRecruiter",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Recruiter Lain</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}
                            </div>
                          ) : null}

                          {selectedDropdown?.id === eachD?.id &&
                          selectedButton === "Rekrutmen" &&
                          selectedButtonStepRecruitment ===
                            "Penilaian Interview" ? (
                            <div
                              className={`${Styles.selectedDropdown} ${
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 1) ||
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 2)
                                  ? Styles.PositionPenilaian
                                  : ""
                              }`}
                              ref={ref}
                            >
                              {eachD?.Status === "Butuh Penilaian" ? (
                                <div
                                  onClick={() => {
                                    setInterviewRO({
                                      name: "LOLOS",
                                      data: eachD,
                                    });
                                  }}
                                >
                                  <span>Lolos</span>
                                </div>
                              ) : null}

                              {eachD?.Status === "Butuh Penilaian" ? (
                                <div
                                  onClick={() => {
                                    setInterviewRO({
                                      name: "TIDAK LOLOS",
                                      data: eachD,
                                    });
                                  }}
                                >
                                  <span>Tidak Lolos</span>
                                </div>
                              ) : null}

                              {eachD?.Status === "Butuh Penilaian" ? (
                                <div
                                  onClick={() => {
                                    setShowStepBack({
                                      status: "Absent",
                                      name: "Tidak Hadir",
                                      step: 2,
                                      data: eachD,
                                    });
                                  }}
                                >
                                  <span>Tidak Hadir</span>
                                </div>
                              ) : null}

                              {/* <div
                                onClick={() => {
                                  setSelectedTransfer(true);
                                }}
                              >
                                <span>Alihkan</span>
                                <Icon
                                  icon={"arrow-right"}
                                  size={16}
                                  color={"#1571de"}
                                />

                                {selectedTransfer && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refChild}
                                  >
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToJobPosting",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Job Posting Lain</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Private",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Private</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Public",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Public</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToAnotherRecruiter",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Recruiter Lain</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className={`${Styles.stepBackBox}`}>
                                <div
                                  onClick={() => {
                                    setSelectedBackStep(true);
                                  }}
                                  className={Styles.back}
                                >
                                  <span>Mundur Step</span>
                                  <Icon
                                    icon={"arrow-right"}
                                    size={16}
                                    color={"#1571de"}
                                  />
                                </div>

                                {selectedbackStep && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.PositionPeralihanStepBack
                                        : ""
                                    }`}
                                    ref={refBackStep}
                                  >
                                    <div className={`${Styles.selected}`}>
                                      <span>Step 1</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Undang Interview",
                                            step: 1,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Undang Interview
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}

                          {selectedDropdown?.id === eachD?.id &&
                          selectedButton === "Rekrutmen" &&
                          selectedButtonStepRecruitment ===
                            "Undang Interview Klien" ? (
                            <div
                              className={`${Styles.selectedDropdown} ${
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 1) ||
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 2)
                                  ? Styles.PositionInterviewClient
                                  : ""
                              }`}
                              ref={ref}
                            >
                              <div
                                onClick={() => {
                                  if (eachD?.Status === "Diundang") {
                                    setInterviewRO(null);
                                    setShowStepBack({
                                      status: "Kirim Ulang Undangan",
                                      name: "Interview Klien",
                                      step: 2,
                                      data: eachD,
                                    });
                                  } else {
                                    setInterviewRO({
                                      data: eachD,
                                      name: "CLIENT",
                                    });
                                  }
                                }}
                              >
                                <span>
                                  {eachD?.Status === "Diundang"
                                    ? "Kirim Ulang"
                                    : "Undang Interview"}
                                </span>
                              </div>

                              {/* <div
                                onClick={() => {
                                  setSelectedTransfer(true);
                                }}
                              >
                                <span>Alihkan</span>
                                <Icon
                                  icon={"arrow-right"}
                                  size={16}
                                  color={"#1571de"}
                                />

                                {selectedTransfer && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refChild}
                                  >
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToJobPosting",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Job Posting Lain</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Private",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Private</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Public",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Public</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToAnotherRecruiter",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Recruiter Lain</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className={`${Styles.stepBackBox}`}>
                                <div
                                  onClick={() => {
                                    setSelectedBackStep(true);
                                  }}
                                  className={Styles.back}
                                >
                                  <span>Mundur Step</span>
                                  <Icon
                                    icon={"arrow-right"}
                                    size={16}
                                    color={"#1571de"}
                                  />
                                </div>

                                {selectedbackStep && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refBackStep}
                                  >
                                    <div className={`${Styles.selected}`}>
                                      <span>Step 1</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Undang Interview",
                                            step: 1,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Undang Interview
                                      </span>
                                    </div>

                                    <div className={`${Styles.selected}`}>
                                      <span>Step 2</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Penilaian Interview",
                                            step: 2,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Penilaian Interview
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}

                          {selectedDropdown?.id === eachD?.id &&
                          selectedButton === "Rekrutmen" &&
                          selectedButtonStepRecruitment ===
                            "Kirim Rekomendasi" ? (
                            <div
                              className={`${Styles.selectedDropdown} ${
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 1) ||
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 2)
                                  ? Styles.PositionInterviewClient
                                  : ""
                              }`}
                              ref={ref}
                            >
                              {eachD?.Status === "Belum Dikirim" ? (
                                <div
                                  onClick={() => {
                                    setInterviewRO({
                                      name: "KIRIM REKOMENDASI PER CANDIDATE",
                                      data: eachD,
                                    });
                                  }}
                                >
                                  <span>Kirim Ke Klien</span>
                                </div>
                              ) : null}

                              {/* <div
                                onClick={() => {
                                  setSelectedTransfer(true);
                                }}
                              >
                                <span>Alihkan</span>
                                <Icon
                                  icon={"arrow-right"}
                                  size={16}
                                  color={"#1571de"}
                                />

                                {selectedTransfer && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refChild}
                                  >
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToJobPosting",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Job Posting Lain</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Private",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Private</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Public",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Public</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToAnotherRecruiter",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Recruiter Lain</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className={`${Styles.stepBackBox}`}>
                                <div
                                  onClick={() => {
                                    setSelectedBackStep(true);
                                  }}
                                  className={Styles.back}
                                >
                                  <span>Mundur Step</span>
                                  <Icon
                                    icon={"arrow-right"}
                                    size={16}
                                    color={"#1571de"}
                                  />
                                </div>

                                {selectedbackStep && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.PositionRecommanded
                                        : ""
                                    }`}
                                    ref={refBackStep}
                                  >
                                    <div className={`${Styles.selected}`}>
                                      <span>Step 1</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Undang Interview",
                                            step: 1,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Undang Interview
                                      </span>
                                    </div>

                                    <div className={`${Styles.selected}`}>
                                      <span>Step 2</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Penilaian Interview",
                                            step: 2,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Penilaian Interview
                                      </span>
                                    </div>

                                    <div className={`${Styles.selected}`}>
                                      <span>Step 3</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Undang Interview Klien",
                                            step: 3,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Undang Interview Klien
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}

                          {selectedDropdown?.id === eachD?.id &&
                          selectedButton === "Rekrutmen" &&
                          selectedButtonStepRecruitment === "Kirim Offering" ? (
                            <div
                              className={`${Styles.selectedDropdown} ${
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 1) ||
                                (data?.data?.length > 2 &&
                                  iD === data?.data?.length - 2)
                                  ? Styles.PositionInterviewClient
                                  : ""
                              }`}
                              ref={ref}
                            >
                              <div
                                onClick={() => {
                                  if (eachD?.Status !== "Dikirim") {
                                    setInterviewRO({
                                      name: "KIRIM OFFERING",
                                      data: eachD,
                                    });
                                  } else {
                                    setInterviewRO({
                                      name: "KIRIM OFFERING",
                                      data: eachD,
                                    });
                                  }
                                }}
                              >
                                <span>
                                  {eachD?.Status === "Dikirim"
                                    ? "Revisi Offering"
                                    : "Kirim Offering"}
                                </span>
                              </div>

                              {/* <div
                                onClick={() => {
                                  setSelectedTransfer(true);
                                }}
                              >
                                <span>Alihkan</span>
                                <Icon
                                  icon={"arrow-right"}
                                  size={16}
                                  color={"#1571de"}
                                />

                                {selectedTransfer && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 1) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refChild}
                                  >
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToJobPosting",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Job Posting Lain</span>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Private",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Private</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "Public",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Public</span>
                                    </div>

                                    <div
                                      onClick={() => {
                                        setInterviewRO({
                                          name: "TransferToAnotherRecruiter",
                                          data: eachD,
                                        });
                                      }}
                                    >
                                      <span>Recruiter Lain</span>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className={`${Styles.stepBackBox}`}>
                                <div
                                  onClick={() => {
                                    setSelectedBackStep(true);
                                  }}
                                  className={Styles.back}
                                >
                                  <span>Mundur Step</span>
                                  <Icon
                                    icon={"arrow-right"}
                                    size={16}
                                    color={"#1571de"}
                                  />
                                </div>

                                {selectedbackStep && (
                                  <div
                                    className={`${Styles.dropdwonChild} ${
                                      (eachD?.isInterviewClient !== null &&
                                        data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (eachD?.isInterviewClient !== null &&
                                        data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (eachD?.isInterviewClient !== null &&
                                        data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3) ||
                                      (eachD?.isInterviewClient !== null &&
                                        data?.data?.length > 2 &&
                                        iD === data?.data?.length - 4)
                                        ? Styles.PositionOffering
                                        : ""
                                    } ${
                                      (eachD?.isInterviewClient === null &&
                                        data?.data?.length > 1 &&
                                        iD === data?.data?.length - 1) ||
                                      (eachD?.isInterviewClient === null &&
                                        data?.data?.length > 2 &&
                                        iD === data?.data?.length - 2) ||
                                      (eachD?.isInterviewClient === null &&
                                        data?.data?.length > 2 &&
                                        iD === data?.data?.length - 3)
                                        ? Styles.Position
                                        : ""
                                    }`}
                                    ref={refBackStep}
                                  >
                                    <div className={`${Styles.selected}`}>
                                      <span>Step 1</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Undang Interview",
                                            step: 1,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Undang Interview
                                      </span>
                                    </div>

                                    <div className={`${Styles.selected}`}>
                                      <span>Step 2</span>
                                      <span
                                        onClick={() => {
                                          setShowStepBack({
                                            status: "Revert",
                                            name: "Penilaian Interview",
                                            step: 2,
                                            data: eachD,
                                          });
                                        }}
                                      >
                                        Penilaian Interview
                                      </span>
                                    </div>

                                    {eachD?.isInterviewClient !== null ? (
                                      <div className={`${Styles.selected}`}>
                                        <span>Step 3</span>
                                        <span
                                          onClick={() => {
                                            setShowStepBack({
                                              status: "Revert",
                                              name: "Undang Interview Klien",
                                              step: 3,
                                              data: eachD,
                                            });
                                          }}
                                        >
                                          Undang Interview Klien
                                        </span>
                                      </div>
                                    ) : null}

                                    {eachD?.isInterviewClient !== null ? (
                                      <div className={`${Styles.selected}`}>
                                        <span>Step 4</span>
                                        <span
                                          onClick={() => {
                                            setShowStepBack({
                                              status: "Revert",
                                              name: "Kirim Rekomendasi",
                                              step: 4,
                                              data: eachD,
                                            });
                                          }}
                                        >
                                          Kirim Rekomendasi
                                        </span>
                                      </div>
                                    ) : null}

                                    {/* isInterviewClient */}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isLoading && (
        <TableEmptyHandler
          title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
          desc={
            isLoading
              ? "Data sedang dimuat"
              : "Tidak ada data atau data belum ada"
          }
        />
      )}

      {!data?.data?.length && !isLoading && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}

      <div className={Styles.paginationWrapper}>
        <Pagination
          total={data?.totalCandidates}
          totalPage={Math?.ceil(data?.totalCandidates / value) || 0}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </div>
  );
}
