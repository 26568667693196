import Styles from './styles.module.scss';
import { useEffect, useRef } from 'react';
import UseOutsideClick from '@Hooks/useOutsideClick';
import Images from "@Theme/Images";

export default function ErrorSorryModal({ 
  title, 
  error, 
  handleDone, 
  buttonTitle = "Tutup",
  show
}) {
    const boxRef = useRef();
    const boxOutsideClick = UseOutsideClick(boxRef);

    useEffect(() => {
      if (show) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.removeProperty('overflow')
      }
    }, [show])

    useEffect(() => {
        if (boxOutsideClick) {
            handleDone();
        }
    }, [boxOutsideClick, handleDone]);

    return (
        <div className={Styles.background}>
            <div className={Styles.container} ref={boxRef}>
                <img src={Images.SORRY} alt="error_sorry" />
                <h4>{title}</h4>
                <p>{error}</p>
                <button onClick={handleDone}>{buttonTitle}</button>
            </div>
        </div>
    );
}
