/* eslint-disable no-unused-vars */
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import UseOutsideClick from "@Hooks/useOutsideClick";
import ReinvitationConfirmationModal from "@Molecule/_modal/ReinvitationConfirmationModal";
import CandidateDetailModal from "@Molecule/_recruitment/CandidateDetailModal";
import CandidateInformationModal from "@Molecule/_recruitment/CandidateInformationModal";
import ClientInterviewTable from "@Molecule/_recruitment/ClientInterviewTable";
import { sendOffering, updateTag } from "@Services/officer/recruitment";
import { useEffect, useMemo, useRef, useState } from "react";
import SendTable from "./SendTable";
import Styles from "./style.module.scss";
import Button from "./SendTable/button";
import { RefreshButton } from "@Atom/Button";
import { makeRandomString } from "@Helpers/makeRandomString";

const controlOptions = [
  {
    id: 1,
    title: "Kirim",
  },
  {
    id: 2,
    title: "Tidak Lanjut",
  },
];

export default function ThirdStage({
  source,
  locationFilter,
  positionFilter,
  trigger,
  setTrigger,
  setOfferingMode,
  positionIDFilter,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentController] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [expand, setExpand] = useState(false);
  // const [expandTag, setExpandTag] = useState(false);
  const [checkedSendArr, setCheckedSendArr] = useState([]);
  const [checkedDiscontinuedArr, setCheckedDiscontinuedArr] = useState([]);
  // const [checkedFinishedArr, setCheckedFinishedArr] = useState([]);
  const [resendModal, setResendModal] = useState(false);
  const [resentData, setResentData] = useState({
    id: null,
    e: null,
  });

  const selectedTag = useMemo(() => {
    return controlOptions[currentController - 1];
  }, [currentController]);

  const resendInvitation = (e, id) => {
    setResendModal(true);
    setResentData((prev) => ({
      ...prev,
      id: id,
      e: e,
    }));
  };

  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);

  const boxRef = useRef();
  const reinviteBoxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  const reinviteBoxOutsideClick = UseOutsideClick(reinviteBoxRef);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const handleSend = async (e, data) => {
    setOfferingMode(data);

    // try {
    //   e.preventDefault();
    //   await sendOffering(id, sessionStorage.getItem("id"));
    //   setTrigger(Math.random());
    //   // setIsDone(true);
    //   setResendModal(false);
    //   setSelectedCandidateDetail(null);
    // } catch (err) {
    //   console.log(err, "err");
    // }
  };

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showCandidateInfo, setShowCandidateInfo] = useState(false);
  const [showCandidateInfoMobile, setShowCandidateInfoMobile] = useState(false);

  const [defaultExpandId, setDefaultExpandId] = useState(1);

  const handleViewInfo = (data) => {
    setDefaultExpandId(2);

    if (viewType === "desktop") {
      setSelectedCandidate(data);

      setShowCandidateInfo(true);
    } else {
      setSelectedCandidate(data);

      setShowCandidateInfoMobile(true);
    }
  };
  const handleCloseInfo = () => {
    setDefaultExpandId(1);
    if (showCandidateInfo) {
      setShowCandidateInfo(false);
    } else {
      setShowCandidateInfoMobile(false);
    }
    setSelectedCandidate(null);
  };

  useEffect(() => {
    if (selectedCandidateDetail !== null) {
      if (boxOutsideClick === false) {
        setSelectedCandidateDetail(null);
      }
    }
    if (showCandidateInfoMobile) {
      if (boxOutsideClick === false) {
        setShowCandidateInfoMobile(false);
      }
    }
  }, [boxOutsideClick, selectedCandidateDetail, showCandidateInfoMobile]);

  useEffect(() => {
    if (resendModal) {
      if (reinviteBoxOutsideClick === true) {
        setResendModal(false);
      }
    }
  }, [reinviteBoxOutsideClick, resendModal]);

  // eslint-disable-next-line no-unused-vars
  const handleMark = async (newTag, arr) => {
    const payload = {
      status: newTag,
      candidateIDs: arr,
    };

    try {
      await updateTag(payload);
      setTrigger(Math.random());
    } catch (err) {
      console.log(err);
    }
  };

  const [data, setData] = useState([]);

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <div className={Styles.header}>
          <div className={Styles.titleWrapper}>
            <h4>Offering</h4>
            <RefreshButton onClick={() => setTrigger(makeRandomString(5))} />
          </div>
          <div className={Styles.searchBarWrapper}>
            <OutlinedSearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={"Search Candidate"}
            />
          </div>
        </div>
      </div>
      <div className={Styles.main}>
        {currentController === 1 && (
          <SendTable
            data={data}
            setData={setData}
            checkedArr={checkedSendArr}
            setCheckedArr={setCheckedSendArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            trigger={trigger}
            setTrigger={setTrigger}
            handleViewInfo={handleViewInfo}
            setOfferingMode={setOfferingMode}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentController === 2 && (
          <ClientInterviewTable
            data={data}
            setData={setData}
            checkedArr={checkedDiscontinuedArr}
            setCheckedArr={setCheckedDiscontinuedArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            tag="OFFER-DISQUALIFIED"
            trigger={trigger}
            handleViewInfo={handleViewInfo}
            positionIDFilter={positionIDFilter}
          />
        )}
        {/* {currentController === 3 && (
          <ClientInterviewTable
            data={source?.finished}
            checkedArr={checkedFinishedArr}
            setCheckedArr={setCheckedFinishedArr}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
            tag="OFFER-QUALIFIED"
            trigger={trigger}
            handleViewInfo={handleViewInfo}
          />
        )} */}
      </div>

      <div
        className={`${Styles.modalWrapper} ${
          selectedCandidateDetail ? Styles.showModal : Styles.hideModal
        }`}
      >
        <CandidateDetailModal
          data={selectedCandidateDetail}
          setData={setSelectedCandidateDetail}
          additionalButtonComponent={
            selectedCandidateDetail?.tag?.toLowerCase() !== "declined" && (
              <Button
                recruiterId={selectedCandidateDetail?.recruiterID}
                tag={selectedCandidateDetail?.tag}
                onClick={(e) =>
                  selectedCandidateDetail?.tag === "Invited"
                    ? resendInvitation(e, selectedCandidateDetail?.id)
                    : handleSend(e, selectedCandidateDetail)
                }
                updatedAt={selectedCandidateDetail?.updatedAt}
              />
            )
          }
          inviteOption={false}
          // reinviteAction={resendInvitation}
          // inviteData={selectedCandidateDetail}
          // inviteAction={handleSend}
        />
      </div>
      {showCandidateInfo && selectedCandidate && (
        <CandidateInformationModal
          defaultExpandId={defaultExpandId || 2}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          inviteOption={false}
          type={"desktop"}
        />
      )}
      <div
        className={`${Styles.modalWrapper} ${
          showCandidateInfoMobile ? Styles.showModal : Styles.hideModal
        }`}
      >
        {/* <CandidateInformationModal
          defaultExpandId={defaultExpandId}
          data={selectedCandidate}
          handleClose={handleCloseInfo}
          inviteOption={false}
          type={"mobile"}
        /> */}
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedCandidateDetail || showCandidateInfoMobile
            ? Styles.visible
            : ""
        }`}
      />
      {resendModal && (
        <ModalWrapper
          show={true}
          forceCenter
          type="fullscreen"
          handleClose={() => setResendModal(false)}
        >
          <div ref={reinviteBoxRef} className={Styles.reinviteModalWrapper}>
            <ReinvitationConfirmationModal
              action={() => handleSend(resentData?.e, resentData?.id)}
              handleClose={() => setResendModal(false)}
            />
          </div>
        </ModalWrapper>
      )}
    </div>
  );
}
