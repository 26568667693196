/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./style.module.scss";
import { formatBytes } from "@Helpers/formatBytes";

export default function ImageUploadField({
  title,
  file,
  setFile,
  required = false,
  withPreview = false,
  isError,
}) {
  const [preview, setPreview] = useState(null);
  const [errorFileReject, setErrorFileReject] = useState({});
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragReject,
    isDragAccept,
    fileRejections,
    acceptedFiles,
    open,
  } = useDropzone({
    // onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
    multiple: false,
    noClick: true,
    maxSize: 1048576 * 5,
    // maxSize: 1048576 * 3
  });

  useEffect(() => {
    if (acceptedFiles?.length) {
      setFile(acceptedFiles[0]);
      setPreview(URL.createObjectURL(acceptedFiles[0]));
      setErrorFileReject({});
    }
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject({
              message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          }
        });
      });
    }
  }, [acceptedFiles, setFile, fileRejections]);

  const baseStyle = useMemo(() => {
    return {
      // backgroundColor: 'transparent',
    };
  }, []);

  const focusedStyle = useMemo(() => {
    return {
      // borderColor: '#2196f3',
    };
  }, []);

  const acceptStyle = useMemo(() => {
    return {
      // borderColor: '#00e676',
      // backgroundColor: '#E9ECEF',
      // backgroundColor: 'green'
    };
  }, []);

  const rejectStyle = useMemo(() => {
    return {
      // borderColor: '#ff1744',
      backgroundColor: "#FFBBBB",
    };
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragActive ? { backgroundColor: "#F5F7FE" } : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      baseStyle,
      isFocused,
      focusedStyle,
      isDragAccept,
      acceptStyle,
      isDragReject,
      rejectStyle,
      isDragActive,
    ]
  );

  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        {title}
        {required && <span className={Styles.redStar}>*</span>}
      </div>
      <div
        {...getRootProps({ style })}
        className={`${Styles.field} ${file ? Styles.withFile : ""} ${
          isError && Styles.isError
        }`}
        onClick={(e) => e?.stopPropagation()}
      >
        <input {...getInputProps()} type="file" hidden />
        {!file ? (
          <div className={Styles.noPreview}>
            <Icon
              icon={"photo"}
              size={40}
              color={!file ? "#9E9E9E" : "#1571DE"}
            />
            <p>
              {!file ? "Drag and Drop File Disini" : file?.name || "file.jpg"}
            </p>
            <span>Atau</span>
            <button onClick={open}>{!file ? "Pilih file" : "Ubah file"}</button>
          </div>
        ) : !withPreview ? (
          <div className={Styles.noPreview}>
            <Icon
              icon={"photo"}
              size={40}
              color={!file ? "#9E9E9E" : "#1571DE"}
            />
            <p>
              {!file ? "Drag and Drop File Disini" : file?.name || "file.jpg"}
            </p>
            <span>Atau</span>
            <button onClick={open}>{!file ? "Pilih file" : "Ubah file"}</button>
          </div>
        ) : (
          <div className={Styles.withPreview}>
            <img src={preview} alt="" />
            <div>
              <span>{file?.name}</span>
              <span>{formatBytes(file?.size)}</span>
            </div>
            <button onClick={() => setFile(null)}>
              <Icon icon={"trash"} size={24} color={"#FF3E13"} />
            </button>
          </div>
        )}
      </div>
      {errorFileReject?.message ? (
        <span className={Styles.errorText}>*{errorFileReject?.message}</span>
      ) : null}
    </div>
  );
}
