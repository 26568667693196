/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Spinner from "@Atom/Spinner";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import { fileBaseUrl } from "@Config/api";
import Images from "@Theme/Images";

export default function AutocompleteV2({
  onReset,
  value = "",
  setValue = () => {},
  options = [],
  placeholder,
  disabled,
  type,
  isLoading = false,
  customClassName = "",
  iconName = "",
  arrowPositioning = {
    right: "12px",
    top: "10.5px",
  },
  iconPositioning = {
    left: "12px",
    top: "8.5px",
  },
  inputPaddingLeft = "14px",
  errorOnFetching,
  onKeyDown = () => {},
  onClickOption = () => {},
  setSelectedPlaces,
  selectedPlaces,
  withLabel = false,
  valueLabel = "",
  forceValue = false,
  viewOnly,
  setSelected,
  isRecruitment,
  isRecommended,
  setSearchNameClientTable,
  isRecommendedPosition,
  isRecommendedCandidate,
  isError = false,
  isErrorText = false,
  isHc = false,
  setValueHc = () => {},
}) {
  const [expandOptions, setExpandOptions] = useState(false);
  const startIndex = 0;
  const endIndex = 1;

  // eslint-disable-next-line no-unused-vars
  const activateDropdown = () => {
    if (!disabled || !viewOnly) {
      setExpandOptions(!expandOptions);
    }
  };

  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expandOptions) {
      setExpandOptions(false);
    }
  }, [boxOutsideClick, expandOptions]);

  useEffect(() => {
    if (disabled) {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);
  const filteredOptions = useMemo(() => {
    if (isRecruitment) {
      return [
        ...options?.filter((el) =>
          el?.name?.toLowerCase()?.includes(value?.toLowerCase())
        ),
        {
          name: "Custom Address",
        },
      ];
    } else {
      return options?.filter((el) =>
        el?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
    }
  }, [options, value, isRecruitment]);

  useEffect(() => {
    if (isRecruitment === false) {
      if (value) {
        if (!expandOptions) {
          if (
            !options?.find(
              (el) => el?.name?.toLowerCase() === value?.toLowerCase()
            )
          ) {
            if (!forceValue) {
              setValue("");
            }
          } else {
            if (valueLabel) {
              onClickOption(
                options?.find(
                  (el) =>
                    el?.name?.toLowerCase() === value?.toLowerCase() &&
                    el?.label?.toLowerCase() === valueLabel?.toLowerCase()
                )
              );
            } else {
              onClickOption(
                options?.find(
                  (el) => el?.name?.toLowerCase() === value?.toLowerCase()
                )
              );
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandOptions, options, value, setValue, isRecruitment]);

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedPlaces.length; i++) {
      if (selectedPlaces[i] === item?.name) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };
  // const handleCheckboxChange = (item) => {
  //   let isSelected = handleChecked(item);
  //   if (isSelected) {
  //     setSelectedPlaces(
  //       selectedPlaces.filter((selected) => selected !== item.name)
  //     );
  //   } else {
  //     setSelectedPlaces([...selectedPlaces, item.name]);
  //   }
  // };

  if (type === "job-seeker-placement" || type === "job-seeker-detail") {
    return (
      <div
        onClick={() => setExpandOptions(true)}
        className={`${Styles.selectWrapper} ${
          expandOptions ? Styles.active : ""
        } ${disabled ? Styles.disableInput : ""}`}
        ref={boxRef}
      >
        <Icon
          icon={"send-outline"}
          size={20}
          color={"#0A0A0A"}
          className={Styles.iconSend}
        />
        <input
          className={Styles.inputSearchJobSeeker}
          value={value || ""}
          onChange={(e) => {
            setExpandOptions(true);
            setValue(e?.target?.value || "");
          }}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        {expandOptions && value && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setValue("");
            }}
            type="button"
            className={Styles.clearButton}
          >
            <Icon icon={"cross"} size={15} />
          </button>
        )}
        {type === "job-seeker-detail" && !expandOptions && (
          <div className={Styles.iconClose} onClick={onReset}>
            <Icon icon={"cross-circle"} size={20} />
          </div>
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            setExpandOptions(!expandOptions);
          }}
          type="button"
          className={Styles.expandButton}
          style={arrowPositioning}
        >
          {disabled ? (
            <Icon icon={"arrow-down"} size={20} />
          ) : (
            <Icon icon={expandOptions ? "arrow-up" : "arrow-down"} size={20} />
          )}
        </button>
        {expandOptions && !disabled ? (
          <div
            className={`${Styles.optionsWrapper} ${
              isHc === true && Styles.optionsWrapperHC
            }`}
          >
            {filteredOptions?.length ? (
              filteredOptions?.map((obj, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    setExpandOptions(false);
                    setValue(obj?.name);
                  }}
                >
                  <span>{obj?.name}</span>
                </div>
              ))
            ) : (
              <div>{isLoading ? "Loading..." : "no option"}</div>
            )}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div
        onClick={() => setExpandOptions(true)}
        className={`${Styles.selectWrapper} ${
          isRecommended || isRecommendedPosition || isRecommendedCandidate
            ? Styles.selectWrapperRecommanded
            : ""
        } ${expandOptions ? Styles.active : ""} ${
          disabled || viewOnly ? Styles.disableInput : ""
        } ${customClassName} ${isError ? Styles.error : ""}`}
        ref={boxRef}
      >
        {iconName && (
          <Icon
            icon={iconName}
            style={iconPositioning}
            size={20}
            className={Styles.icon}
          />
        )}
        <input
          value={value || ""}
          onChange={(e) => {
            setExpandOptions(true);
            setValue(e?.target?.value);
          }}
          onKeyDown={onKeyDown}
          style={{ paddingLeft: inputPaddingLeft }}
          placeholder={placeholder}
          disabled={disabled || viewOnly}
          className={`${disabled || viewOnly ? Styles.disableInput : ""}`}
          type="text"
        />
        {withLabel && valueLabel && value && (
          <div className={Styles.labelWrapper}>
            <p className={Styles.label}>{valueLabel}</p>
          </div>
        )}

        {expandOptions && value && !viewOnly && (
          <button
            onClick={(e) => {
              if (type === "recruitment") {
                e.stopPropagation();
                setValue("");
                setSelected("");
              } else if (
                isRecommended ||
                isRecommendedPosition ||
                isRecommendedCandidate
              ) {
                e.stopPropagation();
                setValue("");
                setSearchNameClientTable("");
              } else if (isHc) {
                e.stopPropagation();
                setValue("");
                setValueHc("");
              } else {
                e.stopPropagation();
                setValue("");
              }
            }}
            type="button"
            className={Styles.clearButton}
          >
            <Icon icon={"cross"} size={15} />
          </button>
        )}

        {isLoading && (
          <button className={`${Styles.spinner} ${isHc && Styles.spinnerHc}`}>
            <Spinner />
          </button>
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            setExpandOptions(!expandOptions);
          }}
          type="button"
          className={Styles.expandButton}
          style={arrowPositioning}
        >
          {isRecommended || isRecommendedPosition || isRecommendedCandidate ? (
            <Icon icon={"search"} size={20} color={"#9E9E9E"} />
          ) : disabled || viewOnly ? (
            <Icon icon={"arrow-down"} size={20} />
          ) : (
            <Icon icon={expandOptions ? "arrow-up" : "arrow-down"} size={20} />
          )}
        </button>
        {expandOptions && !disabled && !viewOnly ? (
          <div
            className={`${Styles.optionsWrapper} ${
              isRecommended || isRecommendedPosition || isRecommendedCandidate
                ? Styles.optionsWrapperRecommended
                : ""
            } ${isHc === true && Styles.optionsWrapperHC}`}
          >
            {filteredOptions?.length ? (
              filteredOptions?.map((obj, i) => (
                <div
                  key={i}
                  onClick={(e) => {
                    if (type === "recruitment") {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setExpandOptions(false);
                      onClickOption(obj);
                      setValue(obj?.name);
                      setSelected(obj);
                    } else if (
                      isRecommended ||
                      isRecommendedPosition ||
                      isRecommendedCandidate
                    ) {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setExpandOptions(false);
                      onClickOption(obj);
                      setValue(obj?.name);
                      setSearchNameClientTable(obj?.name);
                    } else if (isHc) {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setExpandOptions(false);
                      onClickOption(obj);
                      setValueHc(obj?.name);
                      setValue(obj?.name);
                    } else {
                      e?.preventDefault();
                      e?.stopPropagation();
                      setExpandOptions(false);
                      onClickOption(obj);
                      setValue(obj?.name);
                    }
                  }}
                >
                  {isRecommended ? (
                    <div className={Styles.optionRecommended}>
                      <div>
                        <Icon icon={"building"} size={20} color={"#1571DE"} />
                      </div>
                      <div>
                        <span>{obj?.name || "-"}</span>
                        <span>
                          {obj?.cities?.slice(startIndex, endIndex)?.length > 0
                            ? obj?.cities
                                ?.slice(startIndex, endIndex)
                                ?.join(", ")
                            : "-"}

                          {obj?.cities?.length > 1 ? (
                            <span>+{obj?.cities?.slice(endIndex)?.length}</span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  ) : isRecommendedPosition ? (
                    <div className={`${Styles.recommendedPosition}`}>
                      <span>{obj?.name || "-"}</span>
                      <span>{obj?.City?.name || "-"}</span>
                    </div>
                  ) : isRecommendedCandidate ? (
                    <div className={Styles.recommendedCandidate}>
                      <div>
                        <img
                          src={
                            obj?.CandidateDocuments
                              ? fileBaseUrl + obj?.CandidateDocuments?.url
                              : Images?.AVA_DEFAULT
                          }
                          alt=""
                        />
                      </div>

                      <div>
                        <span>{obj?.name || "-"}</span>
                        <span>
                          {obj?.ExpectedPosition?.name || "-"} -{" "}
                          {obj?.ExpectedPosition?.contractType}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <span>{obj?.name}</span>
                  )}
                  {withLabel && <p className={Styles.label}>{obj?.label}</p>}
                </div>
              ))
            ) : (
              <div>{errorOnFetching ? errorOnFetching : "no option"}</div>
            )}
          </div>
        ) : null}
      </div>

      {isErrorText ? (
        <span className={Styles.errorDesc}>Please fill the field</span>
      ) : null}
    </div>
  );
}
