import Styles from "./style.module.scss";

export default function ViewDetailModal({ data, setData }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>Client Information</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Location</h4>
          <p>{data?.province}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Email</h4>
          <p>{data?.email || "no email"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Phone</h4>
          <p>{data?.phoneNumber}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Total Man Power</h4>
          <p>{data?.totalManPowers || 0}</p>
        </div>
      </div>
    </div>
  );
}
