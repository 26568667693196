import Styles from "./style.module.scss";
import Autocomplete from "@Atom/Autocomplete";

const AutocompleteWithLabel = ({
  value,
  setValue = () => { },
  title,
  iconColor,
  required,
  placeholder,
  loading,
  options,
  setSearchTerm = () => { },
  searchTerm,
  onChange,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.title}>
        {title}
        {required && <span className={Styles.asterisk}>&#x2a;</span>}
      </div>
      <Autocomplete
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        loading={loading}
        options={options}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        onChange={onChange}
        iconColor={iconColor}
      />
    </div>
  );
};

export default AutocompleteWithLabel;
