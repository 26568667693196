import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ModalWrapper from "@Atom/ModalWrapper";
import { fileBaseUrl } from "@Config/api";
import { useDebounce } from "@Hooks/useDebounce";
import UseOutsideClick from "@Hooks/useOutsideClick";
import CustomTable from "@Molecule/CustomTable";
import CandidateDetailModal from "@Molecule/_modal/CandidateDetailModal";
import PreviewCVModal from "@Molecule/_modal/PreviewCVModal";
import RecommendationConfirmationModal from "@Molecule/_modal/RecommendationConfirmationModal";
import { getCandidateRecommendation } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import InterviewResultModal from "@Molecule/_modal/InterviewResultModal";
import { calculateAverage } from "@Helpers/calculateAverage";

export default function CandidatesRecommendationSPALayout({ defaultData }) {
  const [data, setData] = useState(defaultData);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [trigger, setTrigger] = useState(null);

  const [expandContact, setExpandContact] = useState(false);
  const [expandRequest, setExpandRequest] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const contact = useMemo(() => {
    return {
      name: data?.candidateRecommendation?.contactName,
      phoneNumber: data?.candidateRecommendation?.contactPhone,
    };
  }, [data]);

  const details = useMemo(() => {
    return {
      amount: data?.candidateRecommendation?.requestTotal,
      positions: data?.candidateRecommendation?.requestPositions?.replace(
        ",",
        ", "
      ),
      locations: data?.candidateRecommendation?.requestCities?.replace(
        ",",
        ", "
      ),
    };
  }, [data]);

  const [locationFilter, setLocationFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");

  const positionOptions = useMemo(() => {
    return details?.positions?.split(", ")?.map((el) => {
      return {
        name: el,
      };
    });
  }, [details]);

  // const [cvUrl, setCVUrl] = useState(null);
  const [showCV, setShowCV] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);
  // const [candidateName, setCandidateName] = useState(null);

  const [selectedCandidate, setSelectedCandidate] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleShowCV = (row) => {
    // setCVUrl(url);
    // setCandidateName(name);

    setShowCV(row);
  };

  const handleCloseCVPreview = () => {
    setShowCV(null);
  };

  const handleAction = async (data, type) => {
    setSelectedData(data);
    setRecommendationType(type);
  };

  const template = useMemo(() => {
    return {
      intro: [
        {
          title: "Contact Details",
          labels: [
            {
              logo: contact?.profilePicture || Images.AVA_DEFAULT,
              label: contact?.name,
              isImage: true,
            },
            {
              logo: "phone",
              label: contact?.phoneNumber,
            },
          ],
        },
        {
          title: "Request Details",
          labels: [
            {
              logo: "employee",
              label: `Total Request: ${details?.amount || "-"}`,
            },
            {
              logo: "bag-job",
              label: details?.positions,
            },
            {
              logo: "building",
              label: details?.locations,
            },
          ],
        },
      ],
      data: data?.candidates?.data?.map((el) => {
        return {
          ...el,
          id: el?.id,
          name: el?.name,
          cvUrl: el?.CandidateDocuments?.length
            ? fileBaseUrl +
              el.CandidateDocuments?.find((d) => d?.type === "CV").url
            : null,
          position: el?.ExpectedPosition?.name,
          location: el?.ExpectedPosition?.City?.name,
          email: el?.email,
          phoneNumber: el?.phoneNumber,
          domicile: el?.domicile,
          address: el?.address,
          education: el?.lastEducation,
          lastExperience: el?.lastPosition,
          tag:
            el?.status === "OFFER-INVITE"
              ? "ACCEPTED"
              : el?.status === "OFFER-DISQUALIFIED" ||
                el?.status === "CLIENT-DISQUALIFIED"
              ? "REJECTED"
              : null,
          status: el?.status,
          invitationTag: el?.tag,
        };
      }),
      columns: [
        {
          name: "name",
          label: "Nama Kandidat",
          minWidth: windowSize[0] > 768 ? "300px" : "170px",
          maxWidth: windowSize[0] > 768 ? "300px" : "170px",
          renderData: (row) => (
            <div className={Styles.profile}>
              <img
                src={row?.profilePicture || Images?.AVA_DEFAULT}
                alt="profilePicture"
              />
              <span>{row?.name}</span>
            </div>
          ),
          // renderLabel:
        },
        {
          name: "cv",
          label: "CV",
          hideOnMobile: true,
          minWidth: "96px",
          thClassName: Styles.stickyLeft,
          tdClassName: Styles.stickyLeft,
          showRightSeparator: true,
          renderLabel: (row) => (
            <div className={Styles.customLabel}>
              <Icon icon="recruitment-outline" size={18} color={"#d2d2d2"} />
              <span>{row?.label}</span>
            </div>
          ),
          renderData: (row) => (
            <button
              className={Styles.previewCVButton}
              onClick={() => handleShowCV(row)}
            >
              <span>CV</span>
              <Icon icon={"report"} size={20} />
            </button>
          ),
        },
        {
          name: "position",
          label: "Posisi",
          minWidth: "96px",
          hideOnMobile: true,
        },
        {
          name: "domicile",
          label: "Domisili",
          hideOnMobile: true,
        },
        {
          name: "address",
          label: "Alamat",
          minWidth: "180px",
          hideOnMobile: true,
        },
        {
          name: "interviewResult",
          label: "Hasil Interview",
          // minWidth: "180px",
          align: "center",
          // hideOnMobile: true,
          renderData: (row) => (
            <button
              className={Styles.checkButton}
              onClick={() => setSelectedResult(row)}
            >
              {calculateAverage(
                row?.CandidateEvaluation?.EvaluationScores?.length
                  ? row?.CandidateEvaluation?.EvaluationScores?.map(
                      (obj) => +obj?.score
                    )
                  : []
              )}
            </button>
          ),
        },

        {
          name: "action",
          label: "",
          align: "right",
          showOnMobile: true,
          renderData: (row) => (
            <div className={Styles.burgerButton}>
              <button onClick={() => setSelectedCandidate(row)}>
                <Icon icon="burger-icon" size={32} />
              </button>
            </div>
          ),
        },
        {
          name: "final",
          label: "Final",
          hideOnMobile: true,
          minWidth: windowSize[0] > 768 ? "188px" : "auto",
          align: "center",
          thClassName: Styles.stickyLeft,
          tdClassName: Styles.stickyLeft,
          showRightSeparator: true,
          renderLabel: (row) => (
            <div className={Styles.customLabel}>
              <Icon icon="recruitment-outline" size={18} color={"#d2d2d2"} />
              <span>{row?.label}</span>
            </div>
          ),
          renderData: (row) =>
            row?.invitationTag?.toLowerCase() === "declined" ? (
              <div className={Styles.actionsWrapper}>
                {row?.rejectionNote ? (
                  <button className={Styles.rejectedButton}>
                    <span>Rejected</span>
                    <Icon icon={"cross"} size={20} />
                  </button>
                ) : (
                  <button className={Styles.rejected}>Tidak Hadir</button>
                )}
              </div>
            ) : !row?.tag ? (
              // row?.invitationTag?.toLowerCase() === 'accepted'
              //   ?
              <div className={Styles.actionsWrapper}>
                <button
                  className={Styles.rejectButton}
                  onClick={() => handleAction(row, "reject")}
                  // disabled={row?.invitationTag?.toLowerCase() !== "accepted"}
                >
                  <span className={Styles.hideOnMobile}>Reject</span>
                  <Icon icon={"cross"} size={20} />
                </button>
                <button
                  className={Styles.acceptButton}
                  onClick={() => handleAction(row, "accept")}
                  // disabled={row?.invitationTag?.toLowerCase() !== "accepted"}
                >
                  <span className={Styles.hideOnMobile}>Accept</span>
                  <Icon icon={"check"} size={20} />
                </button>
              </div>
            ) : (
              // :
              // <div className={Styles.actionsWrapper}>
              //   <button className={Styles.noresponse}>Belum Konfirmasi Kehadiran</button>
              // </div>

              <div className={Styles.actionsWrapper}>
                {row?.tag === "REJECTED" ? (
                  <button className={Styles.rejectedButton}>
                    <span>Rejected</span>
                    <Icon icon={"cross"} size={20} />
                  </button>
                ) : (
                  <button className={Styles.acceptedButton}>
                    <span>Accepted</span>
                    <Icon icon={"check"} size={20} />
                  </button>
                )}
              </div>
            ),
        },
      ],
    };
  }, [contact, details, data, windowSize]);

  const [recommendationType, setRecommendationType] = useState("");
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (selectedCandidate) {
      const found = template?.data?.find(
        (obj) => obj?.id === selectedCandidate?.id
      );
      setSelectedCandidate(found);
    }
  }, [selectedCandidate, template]);

  const { hash } = useParams();

  const debouncedLocationFilter = useDebounce(locationFilter, 300);

  const refetchData = useCallback(async () => {
    const { response } = await getCandidateRecommendation(
      hash,
      limit,
      page,
      positionFilter,
      debouncedLocationFilter
    );
    setData(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, limit, page, positionFilter, debouncedLocationFilter, trigger]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && selectedCandidate) {
      setSelectedCandidate(null);
    }
  }, [boxOutsideClick, selectedCandidate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h1>Rekomendasi Kandidat</h1>
        <div className={Styles.right}>
          <div>
            <img src={Images.INITIAL_LOGO} alt="logo" />
          </div>
          <h3>PT Permata Indo Sejahtera</h3>
        </div>
      </div>
      <div className={Styles.intro}>
        <div className={Styles.left}>
          <div className={Styles.card}>
            {template?.intro?.map((each, i) => {
              const type = each?.title?.toLowerCase()?.includes("contact")
                ? "contact"
                : "request";
              const shouldExpand =
                type === "contact"
                  ? expandContact
                    ? true
                    : false
                  : expandRequest
                  ? true
                  : false;
              return (
                <div key={i} className={Styles.each}>
                  <div className={Styles.introHeader}>
                    <h5>{each?.title}</h5>
                    <button
                      onClick={() => {
                        if (each?.title?.toLowerCase()?.includes("contact")) {
                          setExpandContact(!expandContact);
                        } else {
                          setExpandRequest(!expandRequest);
                        }
                      }}
                    >
                      <Icon
                        icon={shouldExpand ? "arrow-up" : "arrow-down"}
                        size={20}
                      />
                    </button>
                  </div>
                  <div
                    className={`${Styles.content} ${
                      shouldExpand ? Styles.show : ""
                    }`}
                  >
                    {each?.labels?.map((label, iL) => (
                      <div key={iL} className={Styles.label}>
                        {label?.isImage ? (
                          <img
                            src={label?.logo}
                            alt="label"
                            className={Styles.labelImage}
                          />
                        ) : (
                          <Icon
                            icon={label?.logo}
                            size={20}
                            className={Styles.labelIcon}
                          />
                        )}
                        <span>{label?.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={Styles.right}>
          <div className={Styles.notionWrapper}>
            {/* <p>
              Setelah memilih <span className={Styles.accept}>Accept</span> atau <span className={Styles.reject}>Reject</span> kandidat akan masuk secara otomatis ke tahap <span className={Styles.offering}>Offering</span>
            </p> */}
            <p>
              silahkan <span className={Styles.accept}>Accept</span> atau{" "}
              <span className={Styles.reject}>Reject</span> jika anda sudah
              melalukan proses interview
            </p>
          </div>
          <div className={Styles.filterWrapper}>
            <Input
              value={locationFilter}
              onChange={(e) => setLocationFilter(e?.target?.value)}
              placeholder={"Lokasi"}
              floatingStartAdornment={<Icon icon="map" size={20} />}
              startAdornmentDefaultPaddingLeft="38px"
            />
            <Dropdown
              placeholder={"Posisi"}
              options={positionOptions}
              value={positionFilter}
              setValue={setPositionFilter}
              defaultText={"Semua posisi"}
              handleSetDefault={() => setPositionFilter("")}
              iconName={"filter"}
              withDefaultSetter
              withIcon
              autoClose
            />
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <CustomTable
          columns={template?.columns}
          data={template?.data || []}
          type={"sticky-left-right"}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={data?.candidates?.totalPage}
        />
      </div>
      <ModalWrapper show={showCV ? true : false} onClose={handleCloseCVPreview}>
        {showCV && (
          <PreviewCVModal
            // url={cvUrl}
            // candidateName={candidateName}
            data={showCV}
            onClose={handleCloseCVPreview}
            isV2
          />
        )}
      </ModalWrapper>
      <ModalWrapper
        show={selectedCandidate}
        handleClose={() => setSelectedCandidate(null)}
        hideOutsideClick
      >
        {selectedCandidate && (
          <div ref={boxRef}>
            <CandidateDetailModal
              candidate={selectedCandidate}
              setTrigger={setTrigger}
            />
          </div>
        )}
      </ModalWrapper>
      <ModalWrapper
        show={selectedData && recommendationType}
        handleClose={() => setSelectedData(null)}
      >
        <div
          style={{
            visibility:
              selectedData && recommendationType ? "visible" : "hidden",
          }}
        >
          <RecommendationConfirmationModal
            type={recommendationType}
            onClose={() => {
              setRecommendationType("");
              setSelectedData(null);
            }}
            data={selectedData}
            setTrigger={setTrigger}
            isV2
          />
        </div>
      </ModalWrapper>
      <ModalWrapper
        show={selectedResult}
        handleClose={() => setSelectedResult(null)}
      >
        {selectedResult && (
          <InterviewResultModal
            data={selectedResult}
            onClose={() => setSelectedResult(null)}
          />
        )}
      </ModalWrapper>
    </div>
  );
}
