/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import AddCandidateExistingModal from "@Molecule/_modal/AddCandidateExistingModal";
import DoneModal from "@Molecule/_modal/DoneModal";
import UploadExcelModal from "@Molecule/_modal/UploadExcelModal";
import { updateTag } from "@Services/officer/recruitment";
import { useMemo, useState } from "react";
import AcceptedList from "./Accepted";
import InvitationTable from "./InvitationTable";
import RejectedList from "./Rejected";
import Styles from "./style.module.scss";
import { useDebounce } from "@Hooks/useDebounce";
import { RefreshButton } from "@Atom/Button";
import { makeRandomString } from "@Helpers/makeRandomString";

const controlOptions = [
  {
    id: 1,
    title: "Undang",
  },
  {
    id: 2,
    title: "Tidak Lolos",
  },
  // {
  //   id: 3,
  //   title: "Lolos",
  // },
];

const markOptions1 = ["Tidak Lolos", "Lolos"];
const addOptions = ["Manual", "Upload Excel", "Existing & Publik"];

export default function ListMode({
  setAddMode,
  invitationSource,
  rejectedSource,
  acceptedSource,
  locationFilter,
  positionFilter,
  contacts,
  defaultCandidates,
  defaultTotalPage,
  trigger,
  setTrigger,
  requestDetails,
  clientContacts,
  invitationSyncData,
  selectedPosition,
  evaluationParameters,
  positionIDFilter,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentController, setCurrentController] = useState(1);
  const [expand, setExpand] = useState(false);
  const [expandAdd, setExpandAdd] = useState(false);
  const [expandTag, setExpandTag] = useState(false);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddExisting, setShowAddExisting] = useState(false);

  const [isSuccessAdding, setIsSuccessAdding] = useState(false);

  const [domicileSearch, setDomicileSearch] = useState("");
  const debouncedDomicileSearch = useDebounce(domicileSearch, 300);

  const [showSend, setShowSend] = useState(false);
  const selectedTag = useMemo(() => {
    return controlOptions[currentController - 1];
  }, [currentController]);

  const [checkedInvitationArr, setCheckedInvitationArr] = useState([]);
  const [checkedRejectedArr, setCheckedRejectedArr] = useState([]);
  const [checkedAcceptedArr, setCheckedAccepteddArr] = useState([]);

  const [disableToSend, setDisableToSend] = useState(true);

  const handleMark = async (newTag, arr) => {
    const payload = {
      status: newTag,
      candidateIDs: arr,
    };

    try {
      await updateTag(payload);
      setTrigger(Math.random());
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendToClient = () => {
    if (!disableToSend) {
      setShowSend(true);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.top}>
        <div className={Styles.header}>
          <div className={Styles.titleWrapper}>
            <h4>Interview RO</h4>
            <RefreshButton onClick={() => setTrigger(makeRandomString(5))} />
          </div>
          <div className={Styles.searchBarWrapper}>
            <OutlinedSearchBar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={"Search Candidate"}
            />
          </div>
        </div>
        <div className={Styles.controller}>
          <div className={Styles.left}>
            {controlOptions?.map((each, i) => {
              return (
                <button
                  key={i}
                  className={`${
                    each?.id === currentController ? Styles.active : ""
                  } ${Styles.hideOnMobile}`}
                  onClick={() => {
                    setCurrentController(each?.id);
                    setExpand(false);
                  }}
                >
                  {each?.title}
                </button>
              );
            })}
            <button
              className={`${Styles.active} ${Styles.showOnMobile}`}
              onClick={() => setExpandTag(!expandTag)}
            >
              <span>{selectedTag?.title}</span>
              <Icon icon={expandTag ? "arrow-up" : "arrow-down"} size={18} />
              {expandTag && (
                <div className={Styles.options}>
                  {controlOptions?.map((each, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => setCurrentController(each?.id)}
                      >
                        {each?.title}
                        <div className={Styles.separator} />
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
          </div>
          <div className={Styles.right}>
            {currentController === 1 && (
              <>
                <Input
                  // customClassName={Styles.locationFilter}
                  // // arrowPositioning={{ right: '12px', top: '8.5px' }}
                  // iconPositioning={{ left: '8px', top: '10px' }}
                  // iconName="map-outline"
                  // inputPaddingLeft="37px"
                  value={domicileSearch}
                  onChange={(e) => setDomicileSearch(e?.target?.value)}
                  floatingStartAdornment={
                    <Icon icon={"map-outline"} size={20} />
                  }
                  startAdornmentDefaultPaddingLeft={"40px"}
                  placeholder={"Domisili"}
                />
                <button
                  className={`${Styles.button} ${Styles.add}`}
                  onClick={() => setExpandAdd(!expandAdd)}
                >
                  <Icon icon="page-plus" size={18} />
                  <span className={Styles.hideOnMobile}>Tambah kandidat </span>
                  <Icon
                    icon={expandAdd ? "arrow-up" : "arrow-down"}
                    size={18}
                  />
                  {expandAdd && (
                    <div className={Styles.options}>
                      {addOptions?.map((each, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              if (each?.toLowerCase() === "manual") {
                                setAddMode(true);
                              } else if (
                                each?.toLowerCase()?.includes("upload")
                              ) {
                                setShowAddModal(true);
                              } else {
                                setShowAddExisting(true);
                              }
                            }}
                          >
                            {each}
                            <div className={Styles.separator} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.main}>
        {currentController === 1 && (
          <InvitationTable
            trigger={trigger}
            setTrigger={setTrigger}
            checkedArr={checkedInvitationArr}
            setCheckedArr={setCheckedInvitationArr}
            defaultCandidates={defaultCandidates}
            defaultTotalPage={defaultTotalPage}
            data={invitationSource}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            invitationSyncData={invitationSyncData}
            selectedPosition={selectedPosition}
            evaluationParameters={evaluationParameters}
            domicileSearch={debouncedDomicileSearch}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentController === 2 && (
          <RejectedList
            trigger={trigger}
            setTrigger={setTrigger}
            checkedArr={checkedRejectedArr}
            setCheckedArr={setCheckedRejectedArr}
            data={rejectedSource}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentController === 3 && (
          <AcceptedList
            trigger={trigger}
            setTrigger={setTrigger}
            checkedArr={checkedAcceptedArr}
            setCheckedArr={setCheckedAccepteddArr}
            data={acceptedSource}
            showSend={showSend}
            setShowSend={setShowSend}
            contacts={contacts}
            searchTerm={searchTerm}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            setDisableToSend={setDisableToSend}
            requestDetails={requestDetails}
            clientContacts={clientContacts}
            positionIDFilter={positionIDFilter}
          />
        )}
      </div>

      {showAddExisting && (
        <AddCandidateExistingModal
          onClose={() => setShowAddExisting(false)}
          selectedPosition={selectedPosition}
          setTrigger={setTrigger}
          handleDone={() => setShowAddExisting(false)}
        />
      )}

      <ModalWrapper
        show={showAddModal}
        // forceCenter
        // hideOutsideClick
        handleClose={() => setShowAddModal(false)}
      >
        <UploadExcelModal
          setShow={setShowAddModal}
          setIsSuccess={setIsSuccessAdding}
          positionID={selectedPosition?.positionID}
          setTrigger={setTrigger}
          show={showAddModal}
        />
      </ModalWrapper>
      {isSuccessAdding && (
        <DoneModal
          title="Done!"
          description={"File has been uploaded"}
          show={isSuccessAdding}
          handleDone={() => setIsSuccessAdding(false)}
        />
      )}
    </div>
  );
}
