/* eslint-disable eqeqeq */
import Icon from "@Atom/Icon";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import { isValidEmail } from "@Helpers/isValidEmail";
import { addRecruiterCandidate } from "@Services/officer/recruitment";
import { useEffect, useMemo, useState } from "react";
import TableCandidateToJobPost from "../TransferModal/TableCandidateToJobPost";
import TableCandidateToRecruiter from "../TransferModal/TableCandidateToRecruiter";
import AddForm from "./AddForm";
import Styles from './styles.module.scss';
import { useDropzone } from "react-dropzone";
import { fileToBase64 } from "@Helpers/convertFileToBase64";
import PhotoForm from "./PhotoForm";

export default function AddModal({
    setShowModalAdd,
    setDoneText,
    setDoneTitle,
    setShowModalDone,
    domicileOptions,
    educationOptions,
    setTrigger
}){

    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedRecruiter, setSelectedRecruiter] = useState(null);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [errorText, setTextError] = useState(null)
    const [type, setType] = useState("AddForm")
    const [value, setValue] = useState({
        nik: '',
        name: '',
        email: '',
        lastPosition: '',
        phoneNumber: '',
        domicile: '',
        address: '',
        lastEducation: ''
    })
    const [errorField, setErrorField] = useState(false);
    const [domicileTemp, setDomicileTemp] = useState(value?.domicile);
    const [educationTemp, setEducationTemp] = useState(value?.lastEducation);
    const [errorFileReject, setErrorFileReject] = useState({});

    const onDropImage = (acceptedFiles, fileRejections) => {
        if (acceptedFiles?.length > 0) {
          fileToBase64(acceptedFiles[0], (base64Data) => {
            setValue((prev) => ({
              ...prev,
              candidatePhoto: acceptedFiles[0],
            }));
            fileToBase64(acceptedFiles[0], async (base64Data) => {
              sessionStorage.setItem("candidatePhoto", base64Data);
            });
          });
          setErrorFileReject({});
        }
        if (fileRejections?.length) {
          fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
              if (err.code === "file-too-large") {
                setErrorFileReject({
                  key: "candidatePhoto",
                  message: "File terlalu besar. Ukuran maksimal file adalah 5MB !",
                });
              }
            });
          });
        }
      };

    const dropdownImage = useDropzone({
        onDrop: onDropImage,
        accept: {
          "image/jpeg": [],
          "image/png": [],
        },
        multiple: false,
        maxSize: 1048576 * 5,
      });

      const handleChangePhoto = (e) => {
        if (e?.target?.files?.length > 0) {
          fileToBase64(e?.target?.files[0], (base64Data) => {
            setValue((prev) => ({
              ...prev,
              candidatePhoto: e?.target?.files[0],
            }));
            fileToBase64(e?.target?.files[0], async (base64Data) => {
              sessionStorage.setItem("candidatePhoto", base64Data);
            });
          });
        }
      };

    const contentTable = useMemo(() => {
        switch(type) {
            case 'AddForm':
                return (
                    <AddForm
                        value={value}
                        setValue={setValue}
                        educationOptions={educationOptions}
                        domicileOptions={domicileOptions}
                        domicileTemp={domicileTemp}
                        educationTemp={educationTemp}
                        setDomicileTemp={setDomicileTemp}
                        setEducationTemp={setEducationTemp}
                        errorField={errorField}
                    />
                )
            case 'PhotoForm':
                return (
                    <PhotoForm
                        value={value}
                        dropdownImage={dropdownImage}
                        handleChangePhoto={handleChangePhoto}
                        errorFileReject={errorFileReject}
                    />)
            case 'AddToJobPosting':
                return (
                    <TableCandidateToJobPost
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                    />)
            case 'AddToAnotherRecruiter':
                return (
                    <TableCandidateToRecruiter
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                        selectedRecruiter={selectedRecruiter}
                        setSelectedRecruiter={setSelectedRecruiter}
                    />)
            default:
                return (
                    <TableCandidateToJobPost
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                    />)
        }

    }, [type, selectedPosition, selectedRecruiter, value, setValue, domicileOptions, educationOptions,domicileTemp, educationTemp, errorField, dropdownImage, errorFileReject])

    const onCancel = () => {
        setValue({
            nik: '',
            name: '',
            email: '',
            lastPosition: '',
            phoneNumber: '',
            domicile: '',
            address: '',
            lastEducation: ''
        })
        setSelectedPosition(null)
        setSelectedRecruiter(null)
        setDomicileTemp(null)
        setEducationTemp(null)
        setShowModalAdd(false)
        setErrorField(false)
        setErrorFileReject({})
        setType('AddForm')
    }

    const onBack = () => {
        if (type === 'AddForm') {
            setValue({
                nik: '',
                name: '',
                email: '',
                lastPosition: '',
                phoneNumber: '',
                domicile: '',
                address: '',
                lastEducation: ''
            })
            setSelectedPosition(null)
            setSelectedRecruiter(null)
            setDomicileTemp(null)
            setEducationTemp(null)
            setShowModalAdd(false)
            setErrorField(false)
        } else if (type === 'PhotoForm') {
            setErrorFileReject({})
            setSelectedPosition(null)
            setSelectedRecruiter(null)
            setType('AddForm')
        } else if ((type === "AddToJobPosting" || type === "AddToAnotherRecruiter")){
            setErrorFileReject({})
            setSelectedPosition(null)
            setSelectedRecruiter(null)
            setType('PhotoForm')
        } else {
            setErrorFileReject({})
            setSelectedPosition(null)
            setSelectedRecruiter(null)
            setType('AddForm')
        }
    };

    const handleSubmit = async () => {
        try {
            if (
                allFilledObjChecker(value) && 
                value?.nik?.length == 16 && 
                isValidEmail(value?.email)
            ) {
                setErrorField(false)
                if (type === "AddForm"){    
                    setType("PhotoForm")
                } else if (type === "PhotoForm"){
                    setType("AddToJobPosting")
                } else {
                    setIsSubmiting(true)
                    let tempType = ''
                    let doneMessage = ''
    
                    if (type === "AddToJobPosting"){
                        tempType = "JobPosting"
                        doneMessage = `Kandidat berhasil ditambahkan ke Job Posting ${selectedPosition?.title}`
                    } else if (type === "AddToAnotherRecruiter"){
                        tempType = "OtherRO"
                        doneMessage = `Kandidat berhasil ditambahkan ke RO ${selectedRecruiter?.name}`
                    } else tempType = ''
                    
                    const formData = new FormData()
                    formData.append("idNumber", value?.nik)
                    formData.append("name", value?.name)
                    formData.append("email", value?.email)
                    formData.append("lastPosition", value?.lastPosition)
                    formData.append("phoneNumber", `62${value?.phoneNumber}`)
                    formData.append("domicile", value?.domicile)
                    formData.append("address", value?.address)
                    formData.append("lastEducation", value?.lastEducation)

                    formData.append("positionID", selectedPosition?.positionID)
                    formData.append("type", tempType)
                    formData.append("roID", selectedRecruiter?.employeeId || null)
                    formData.append("jobPostingID", selectedPosition?._id)
                    formData.append("jobPostingTitle", selectedPosition?.title)
                    formData.append("fileType", "SELF PICTURE")
                    formData.append("file", value?.candidatePhoto)

                    // let payload = {
                    //     "idNumber": value?.nik,
                    //     "name": value?.name,
                    //     "email": value?.email,
                    //     "lastPosition": value?.lastPosition,
                    //     "phoneNumber": `62${value?.phoneNumber}`,
                    //     "domicile": value?.domicile,
                    //     "address": value?.address,
                    //     "lastEducation": value?.lastEducation,
    
                    //     "positionID": selectedPosition?.positionID,
                    //     "type": tempType,
                    //     "roID": selectedRecruiter?.employeeId || null, // diisi kalau kandidat didaftarkan ke RO lain
                    //     "jobPostingID": selectedPosition?._id,
                    //     "jobPostingTitle": selectedPosition?.title
                    // }
                    await addRecruiterCandidate(formData);
        
                    setShowModalDone(true)
                    setDoneTitle("Berhasil")
                    setDoneText(doneMessage)
                    setIsSubmiting(false)
                    setSelectedPosition(null)
                    setSelectedRecruiter(null)
                    setShowModalAdd(false)
                    setDomicileTemp(null)
                    setEducationTemp(null)
                    setValue({
                        nik: '',
                        name: '',
                        email: '',
                        lastPosition: '',
                        phoneNumber: '',
                        domicile: '',
                        address: '',
                        lastEducation: ''
                    })
                    setType('AddForm')
                    setTrigger(Math.random());
                }
            } else {
                setErrorField(true)
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || "something went wrong";
            setTextError(errorMessage)
            setIsSubmiting(false)
            console.log(error)   
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setTextError(null)
        }, 3000)
    }, [errorText])

    const disableButton = useMemo(() => {
        let temp;
        switch (type) {
            case 'PhotoForm':
                   if (
                        allFilledObjChecker(value) && 
                        value?.nik?.length <= 16 && 
                        isValidEmail(value?.email) &&
                        value?.candidatePhoto
                    ) temp = false
                   else temp = true
                break;
            case 'AddToJobPosting':
                if (!selectedPosition) {
                    temp = true;
                } else {
                    temp = false;
                }
                break;
            case 'AddToAnotherRecruiter':
                if (selectedPosition && selectedRecruiter) {
                    temp = false;
                } else {
                    temp = true;
                }
                break;
            default:
                temp = false;
        }
        return temp;
    }, [type, selectedPosition, selectedRecruiter, value]);


    return (
        <div className={Styles.background}>
            <div className={Styles.container}>
                <div className={Styles.header}>
                    <h3>
                        <span>{"Tambah Kandidat"}</span>
                    </h3>
                    <button onClick={onCancel}>
                        <Icon icon={'cross'} size={'24'} />
                    </button>
                </div>
                <div className={Styles.normal}>
                    <div className={Styles.topWrapper}>
                        {(type === "AddForm" || type === "PhotoForm") ?
                            <span>Detail Kandidat</span> :
                            type === "AddToJobPosting" ? 
                                <span>Daftarkan ke Job Posting</span> :
                                <span>Daftarkan ke RO Lain</span> 
                        }
                        {type === "AddForm" && <span className={Styles.pageNumber}>Step 1/3</span> }
                        {type === "PhotoForm" && <span className={Styles.pageNumber}>Step 2/3</span>}
                        {(type === "AddToJobPosting" || type === "AddToAnotherRecruiter") && <span className={Styles.pageNumber}>Step 3/3</span>}
                    </div>
                    {(type === "AddToJobPosting" || type === "AddToAnotherRecruiter") && 
                    <div className={Styles.detailWrapper}>
                        <div className={`${Styles.detailBox} ${type === "AddToJobPosting" ? Styles.active : ''}`}
                            onClick={() => {
                                setType("AddToJobPosting")
                                setSelectedPosition(null)
                                setSelectedRecruiter(null)
                            }}
                        >
                            <div className={Styles.iconDash}>
                                {type === "AddToJobPosting" ?
                                    <Icon icon={'radio-button-active'} color={"#1571DE"} size={'24'} /> :
                                    <Icon icon={'radio-button-default'} color={"#C2C2C2"} size={'24'} />
                                }
                            </div>
                            <div className={Styles.contentWrapper}>
                                <div className={Styles.iconDash}>
                                    {type === "AddToJobPosting" ?
                                        <Icon icon={'work-scheme'} color={"#1571DE"} size={'24'} /> :
                                        <Icon icon={'work-scheme'} size={'24'} />
                                    }
                                </div>
                                <div className={Styles.contentDetailBox}>
                                    <span>Tambahkan Ke</span>
                                    <span>Job Posting</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.detailBox} ${type === "AddToAnotherRecruiter" ? Styles.active : ''}`}
                            onClick={() => {
                                setType("AddToAnotherRecruiter")
                                setSelectedPosition(null)
                                setSelectedRecruiter(null)
                            }}
                        >
                            <div className={Styles.iconDash}>
                                {type === "AddToAnotherRecruiter" ?
                                    <Icon icon={'radio-button-active'} color={"#1571DE"} size={'24'} /> :
                                    <Icon icon={'radio-button-default'} color={"#C2C2C2"} size={'24'} />
                                }
                            </div>
                            <div className={Styles.contentWrapper}>
                                <div className={Styles.iconDash}>
                                    {type === "AddToAnotherRecruiter" ?
                                        <Icon icon={'people'} color={"#1571DE"} size={'24'} /> :
                                        <Icon icon={'people'} size={'24'} />
                                    }
                                </div>
                                <div className={Styles.contentDetailBox}>
                                    <span>Tambahkan Ke</span>
                                    <span>RO Lain</span>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {contentTable}
                </div>
                <div className={`${Styles.buttonsWrapper}`}>
                    <button onClick={onBack}>
                        {type === 'AddForm' ?
                            "Batal" : "Kembali"
                        }
                    </button>
                    <button
                        className={`${isSubmiting ? Styles.process : ''}`}
                        disabled={disableButton}
                        onClick={handleSubmit}>
                        {(type === "AddForm" || type === "PhotoForm") ? 
                            (!isSubmiting ? <span>Lanjutkan</span> : <span>Mendaftarkan...</span> ) :
                            (!isSubmiting ? <span>Simpan</span> : <span>Menyimpan...</span>)}
                    </button>
                </div>
                {errorText && 
                    <div className={Styles.errorWrapper}>
                        <span>{errorText}</span>
                    </div>
                }

            </div>
        </div>
    );
}