import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import ModalWrapper from "@Atom/ModalWrapper";
import { fileBaseUrl } from "@Config/api";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import useQuery from "@Hooks/useQuery";
import CustomTable from "@Molecule/CustomTable";
import DoneModal from "@Molecule/_modal/DoneModal";
import PreviewCVModal from "@Molecule/_modal/PreviewCVModal";
import { sendCandidatesRecommendation } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import CandidateDetailModal from "@Molecule/_modal/CandidateDetailModal";
import InterviewResultModal from "@Molecule/_modal/InterviewResultModal";
import { makeRandomString } from "@Helpers/makeRandomString";
import { joinArrayToString } from "@Helpers/joinArrayToString";

export default function CandidatePreview({
  data,
  setShow,
  setShowSend,
  selectedContact,
  requestDetails,
  setTrigger,
}) {
  const query = useQuery();
  const clientName = query.get("n");
  const handleClickBack = () => {
    setShow(false);
  };

  // const [cvUrl, setCVUrl] = useState(null);
  // const [showCV, setShowCV] = useState(false);
  // const [candidateName, setCandidateName] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null)
  const [selectedCV, setSelectedCV] = useState(null)

  const handleCloseCVPreview = () => {
    // setCVUrl(null);
    // setShowCV(false);
    setSelectedCV(null)
  };

  const [expandContact, setExpandContact] = useState(false);
  const [expandRequest, setExpandRequest] = useState(false);

  const positionOptions = useMemo(() => {
    let arr = [];
    const positionsArr = requestDetails?.positions;
    for (let i = 0; i < positionsArr.length; i++) {
      arr.push({
        name: positionsArr[i],
      });
    }
    return arr;
  }, [requestDetails]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [locationFilter, setLocationFilter] = useState("");
  const [positionFilter, setPositionFilter] = useState("");

  const introToMap = useMemo(() => {
    return [
      {
        title: "Contact Details",
        labels: [
          {
            logo: selectedContact?.profilePicture
              ? selectedContact?.profilePicture?.includes("http")
                ? selectedContact?.profilePicture?.replace('https://myworkspace', 'https://api1.myworkspace')
                : fileBaseUrl + selectedContact?.profilePicture
              : Images.AVA_DEFAULT,
            label: selectedContact?.name,
            isImage: true,
          },
          {
            logo: "building",
            label: clientName,
          },
          {
            logo: "phone",
            label: selectedContact?.phoneNumber,
          },
        ],
      },
      {
        title: "Request Details",
        labels: [
          {
            logo: "employee",
            label: `Total Request: ${requestDetails?.amount || "-"}`,
          },
          {
            logo: "bag-job",
            label: requestDetails?.positions
              ?.map((el) => capitalizedFirstLetter(el))
              ?.join(", "),
          },
          {
            logo: "building",
            label: requestDetails?.locations
              ?.map((el) => capitalizedFirstLetter(el))
              ?.join(", "),
          },
        ],
      },
    ];
  }, [clientName, selectedContact, requestDetails]);

  const handleShowCV = (row) => {
    // setCVUrl(url);
    // setCandidateName(name);

    // setShowCV(true);
    setSelectedCV(row)
  };

  const template = useMemo(() => {
    return {
      data: data
        ?.map((el) => {
          return {
            ...el,
            id: el?.id,
            name: el?.name,
            position: el?.ExpectedPosition?.name,
            location: el?.ExpectedPosition?.City?.name,
            cvUrl: el?.CandidateDocuments?.length
              ? fileBaseUrl + el.CandidateDocuments[0].url
              : null,
            email: el?.email,
            domicile: el?.domicile,
            address: el?.address,
            education: el?.lastEducation,
            lastExperience: el?.lastPosition,
          };
        })
        ?.filter((el) =>
          el?.location?.toLowerCase()?.includes(locationFilter?.toLowerCase())
        )
        ?.filter((el) =>
          el?.position?.toLowerCase()?.includes(positionFilter?.toLowerCase())
        )
        ?.slice((page - 1) * limit, page * limit),
      columns: [
        {
          name: "name",
          label: "Nama Kandidat",
          minWidth: windowSize[0] > 768 ? "300px" : "170px",
          maxWidth: windowSize[0] > 768 ? "300px" : "170px",
          renderData: (row) => (
            <button
              className={Styles.profile}
              onClick={() => handleShowCV(row)}
            >
              <img
                src={row?.profilePicture || Images?.AVA_DEFAULT}
                alt="profilePicture"
              />
              <span>{row?.name}</span>
            </button>
          ),
        },
        {
          name: "position",
          label: "Posisi",
          minWidth: "96px",
          hideOnMobile: true,
        },
        // {
        //   name: "location",
        //   label: "Lokasi",
        //   minWidth: "96px",
        //   hideOnMobile: true,
        // },
        // {
        //   name: "email",
        //   label: "Email",
        //   hideOnMobile: true,
        //   renderData: (row) => (
        //     <a className={Styles.emailLink} href={`mailto:${row?.email}`}>
        //       {row?.email}
        //     </a>
        //   ),
        // },
        {
          name: "domicile",
          label: "Domisili",
          hideOnMobile: true,
        },
        {
          name: "address",
          label: "Alamat",
          minWidth: "180px",
          hideOnMobile: true,
        },
        {
          name: 'interviewResult',
          label: 'Hasil Interview',
          // minWidth: '180px',
          // align: 'center',
          hideOnMobile: true,
          renderData: (row) => (
            <button
              className={Styles.checkButton}
              onClick={() => setSelectedResult(row)}
            >
              Cek Detail
            </button>
          )
        },
        // {
        //   name: "education",
        //   label: "Pendidikan",
        //   hideOnMobile: true,
        // },
        // {
        //   name: "lastExperience",
        //   label: "Pengalaman Terakhir",
        //   hideOnMobile: true,
        // },
        {
          name: "action",
          label: "",
          align: "right",
          showOnMobile: true,
          renderData: (row) => (
            <div className={Styles.burgerButton}>
              <button onClick={() => setSelectedCandidate(row)}>
                <Icon icon="burger-icon" size={32} />
              </button>
            </div>
          ),
        },
      ],
    };
  }, [data, locationFilter, positionFilter, limit, page, windowSize]);

  const totalPage = useMemo(() => {
    return Math.ceil(template?.data?.length / limit);
  }, [template, limit]);

  const handleSendToClient = async () => {
    setIsSending(true);
    try {
      await sendCandidatesRecommendation({
        contactName: selectedContact?.name,
        contactClient: query.get("n"),
        contactPhone: selectedContact?.phoneNumber,
        requestTotal: requestDetails?.amount,
        requestPositions: requestDetails?.positions?.join(","),
        requestCities: requestDetails?.locations?.join(","),
        candidates: data?.map((el) => el?.id)?.join(","),
        clientCode: query.get("c"),
        contactEmail: selectedContact?.email,
        via: joinArrayToString(selectedContact?.via)
      });
      setTrigger(makeRandomString(5));
      setIsSending(false);
      setIsSuccess(true);
    } catch (err) {
      setIsSending(false);
      console.log(err);
    }
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && selectedCandidate) {
      setSelectedCandidate(null);
    }
  }, [boxOutsideClick, selectedCandidate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <button onClick={handleClickBack}>
          <Icon icon="arrow-left" size={24} />
          <span>Back</span>
        </button>
        <div className={Styles.bottom}>
          <h1>Rekomendasi Kandidat</h1>
          <div className={Styles.right}>
            <div>
              <img src={Images.INITIAL_LOGO} alt="logo" />
            </div>
            <h3>PT Permata Indo Sejahtera</h3>
          </div>
        </div>
      </div>
      <div className={Styles.intro}>
        <div className={Styles.left}>
          <div className={Styles.card}>
            {introToMap?.map((each, i) => {
              const type = each?.title?.toLowerCase()?.includes("contact")
                ? "contact"
                : "request";
              const shouldExpand =
                type === "contact"
                  ? expandContact
                    ? true
                    : false
                  : expandRequest
                    ? true
                    : false;
              return (
                <div key={i} className={Styles.each}>
                  <div className={Styles.introHeader}>
                    <h5>{each?.title}</h5>
                    <button
                      onClick={() => {
                        if (each?.title?.toLowerCase()?.includes("contact")) {
                          setExpandContact(!expandContact);
                        } else {
                          setExpandRequest(!expandRequest);
                        }
                      }}
                    >
                      <Icon
                        icon={shouldExpand ? "arrow-up" : "arrow-down"}
                        size={20}
                      />
                    </button>
                  </div>
                  <div
                    className={`${Styles.content} ${shouldExpand ? Styles.show : ""
                      }`}
                  >
                    {each?.labels?.map((label, iL) => (
                      <div key={iL} className={Styles.label}>
                        {label?.isImage ? (
                          <img
                            src={label?.logo}
                            alt="label"
                            className={Styles.labelImage}
                          />
                        ) : (
                          <Icon
                            icon={label?.logo}
                            size={20}
                            className={Styles.labelIcon}
                          />
                        )}
                        <span>{label?.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={Styles.right}>
          <div className={Styles.buttonWrapper}>
            <button onClick={handleSendToClient} disabled={isSending}>
              <span>
                {isSending ? "Mengirimkan ke klien..." : "Kirim ke klien"}
              </span>
              <Icon icon="page-plus" size={20} />
            </button>
          </div>
          <div className={Styles.filterWrapper}>
            <Input
              value={locationFilter}
              onChange={(e) => setLocationFilter(e?.target?.value)}
              placeholder={"Lokasi"}
              floatingStartAdornment={<Icon icon="map" size={20} />}
              startAdornmentDefaultPaddingLeft="38px"
            />
            <Dropdown
              placeholder={"Posisi"}
              options={positionOptions}
              value={positionFilter}
              setValue={setPositionFilter}
              defaultText={"Semua posisi"}
              handleSetDefault={() => setPositionFilter("")}
              iconName={"filter"}
              withDefaultSetter
              withIcon
              autoClose
            />
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <CustomTable
          columns={template?.columns}
          data={template?.data || []}
          type={"sticky-left"}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={totalPage}
        />
      </div>
      <ModalWrapper
        show={selectedCV ? true : false}
        handleClose={() => setSelectedCV(null)}
      >
        {selectedCV
          &&
          <PreviewCVModal
            // url={cvUrl}
            // candidateName={candidateName}
            data={selectedCV}
            onClose={handleCloseCVPreview}
            isV2
          />
        }
      </ModalWrapper>
      {isSuccess && (
        <DoneModal
          handleDone={() => {
            setShow(false);
            setShowSend(false);
            setIsSuccess(false);
          }}
          title="Sent!"
          description={"Recommendation successfully sent to client!"}
          doneButton
          doneTitle={"Ok"}
        />
      )}

      <ModalWrapper
        show={selectedCandidate}
        handleClose={() => setSelectedCandidate(null)}
        hideOutsideClick
      >
        {selectedCandidate && (
          <div ref={boxRef}>
            <CandidateDetailModal
              candidate={selectedCandidate}
              setTrigger={setTrigger}
              noAction
            />
          </div>
        )}
      </ModalWrapper>
      <ModalWrapper
        show={selectedResult}
        handleClose={() => setSelectedResult(null)}
      >
        {selectedResult
          &&
          <InterviewResultModal
            data={selectedResult}
            onClose={() => setSelectedResult(null)}
          />
        }

      </ModalWrapper>
    </div>
  );
}
