import Styles from './style.module.scss'

export default function FormEmptyPlaceholder({
  title,
  description
}) {

  return (
    <div className={Styles.container}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}