/* eslint-disable no-unused-vars */
import Styles from "./style.module.scss";
import { useState, useEffect, useMemo } from "react";
import InputField from "@Molecule/InputField";
// import { EditorState, convertToRaw } from "draft-js";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import AutocompleteWithLabel from "@Molecule/AutocompleteWithLabel";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import { convertFromHTML } from "draft-convert";
import { getTemplateSync } from "@Services/hc/contract";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const LeftSide = ({
  type,
  contractData,
  setContractData,
  clientContracts,
  parameters,
  ...props
}) => {
  const blocksFromHTML = htmlToDraft(contractData?.template);
  const [contractCodeList, setContractCodeList] = useState([]);
  const [codeInputValue, setCodeInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const getInitialState = (content) => {
    const blocksFromHtml = htmlToDraft(`<div>${content}</div>`);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(
    getInitialState(contractData?.template)
  );

  const [clientCode, setClientCode] = useState(
    type === "UPDATE_CONTRACT" || type === "UPDATE_ADDITIONAL"
      ? contractData
      : ""
  );

  const options = useMemo(() => {
    const data = contractCodeList?.map((item) => ({
      ...item,
      name: item?.docNumber,
    }));
    return data;
  }, [contractCodeList]);

  const updatedParameters = useMemo(() => {
    const data = parameters?.map((item) => ({
      ...item,
      value: item?.value.substring(1),
    }));
    return data;
  }, [parameters]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const newStr = html.replace(/<[\p]+><\/[\p]+>/g, "<p><br></p>");
    setContractData((contractData) => ({
      ...contractData,
      template: newStr,
    }));
  }, [editorState, setContractData]);

  const handleInputChange = (event) => {
    setCodeInputValue(event.target.value);
  };

  useEffect(() => {
    const refetchData = async () => {
      setLoading(true);
      try {
        const { response } = await getTemplateSync(codeInputValue, "CONTRACT");

        if (response) {
          setLoading(false);
          setContractCodeList(response?.clientContracts);
        }
      } catch (error) {
        setLoading(false);
        console.log(error, "Error saat refetch data");
      }
    };
    refetchData();
  }, [codeInputValue]);

  useEffect(() => {
    setContractData((contractData) => ({
      ...contractData,
      contractCode: clientCode?.contractCode || clientCode?.docNumber || "",
      clientContractID:
        clientCode?.clientContractID ||
        clientCode?.id ||
        contractData?.id ||
        "",
    }));
  }, [clientCode, setContractData]);

  return (
    <div className={Styles.leftSide}>
      <div className={Styles.header}>
        <InputField
          title="Nama Template"
          placeholder={`Contoh: TEST-${type}1`}
          value={contractData?.name}
          onChange={(e) =>
            setContractData((contractData) => ({
              ...contractData,
              name: e.target.value,
            }))
          }
        />
        {type === "ADDITIONAL" && (
          <AutocompleteWithLabel
            value={clientCode?.contractCode || clientCode?.docNumber}
            setValue={setClientCode}
            searchTerm={codeInputValue}
            setSearchTerm={setCodeInputValue}
            options={options}
            title="Klien"
            onChange={handleInputChange}
            placeholder={`Contoh: ${type} - CLIENT-CODE1`}
            loading={loading}
          />
        )}
      </div>
      <div className={Styles.editorWrapper}>
        <Editor
          editorState={editorState}
          mention={{
            separator: " ",
            trigger: "{",
            suggestions: updatedParameters,
          }}
          // editorStyle={{margin:'0'}}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName={Styles.editor}
          toolbarClassName={Styles.toolbar}
          toolbar={{
            options: [
              "inline",
              // "blockType",
              "fontSize",
              "list",
              "textAlign",
              "history",
              "remove",
              // "fontFamily",
            ],
            inline: { inDropdown: true },
            list: {
              inDropdown: true,
            },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            // fontFamily: {
            //   options: ["Calibri"],
            //   className: undefined,
            //   component: undefined,
            //   dropdownClassName: undefined,
            // },
          }}
        />
      </div>
    </div>
  );
};

export default LeftSide;
