import { useRef, useState } from "react";
import Styles from "./styles.module.scss";
import { rupiahFormat } from "@Helpers/formatRupiah";
import Icon from "@Atom/Icon";

export default function UpdateLogs({ data, selectedLogs, setSelectedLogs }) {
  const timeoutIdRef = useRef(null);

  return (
    <div className={Styles.updateLogsBox}>
      <div className={Styles.headerLogs}>
        <span>List Update</span>
        <span>{data?.humanResources?.updateLogs?.length || 0} perubahan</span>
      </div>

      <div
        className={`${Styles.contentLogs} ${
          selectedLogs && Styles.overFlowHidden
        }`}
      >
        {data?.humanResources?.updateLogs?.map((el, idx) => {
          return (
            <div
              className={`${Styles.logs} ${
                selectedLogs?.data?.id === el?.id ? Styles.active : ""
              }`}
              onClick={() => {
                if (timeoutIdRef.current) {
                  clearTimeout(timeoutIdRef.current); // Batalkan timeout yang ada jika ada
                }

                if (el?.remark === "Perubahan Tipe Kontrak") {
                  document
                    .getElementById("Tipe Kontrak")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark === "Perubahan NPWP Klien") {
                  document
                    .getElementById("Tipe Kontrak")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark === "Perubahan Tipe Kontrak Kerja") {
                  document
                    .getElementById("Tipe Kontrak")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark?.includes("Flow Invoice •")) {
                  document
                    .getElementById("Flow Invoice")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (
                  el?.remark?.includes("Rekening Penerima Invoice •")
                ) {
                  document
                    .getElementById("Flow Invoice")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (
                  el?.remark === "Perubahan Absen Dipotong Bila" ||
                  el?.remark === "Perubahan Keterangan Lainnya"
                ) {
                  document
                    .getElementById("Other")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark?.includes("Tax •")) {
                  document
                    .getElementById("Taxs")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark === "Perubahan Remarks Posisi") {
                  document
                    .getElementById("Remark")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark?.includes("BPJS •")) {
                  document
                    .getElementById("BPJS")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (
                  el?.remark === "Perubahan Penagihan Manfee" ||
                  el?.remark === "Perubahan Penagihan THR" ||
                  el?.remark === "Perubahan Penagihan UAK" ||
                  el?.remark === "Perubahan Rekening Wajib TKO"
                ) {
                  document
                    .getElementById("Manfee")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.remark,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.table === "ClientContractLog") {
                  if (
                    el?.remark === "Penambahan Dokumen Kontrak Baru" ||
                    el?.remark?.includes("Perubahan") ||
                    el?.remark?.includes("Dokumen Baru Telah Disetujui") ||
                    el?.remark?.includes("Expired")
                  ) {
                    document
                      .getElementById("Daftar Kontrak")
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  }
                } else if (el?.remark === "Perubahan Management Fee") {
                  document
                    .getElementById("Management Fee")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.table,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.remark === "Perubahan Term of Payment") {
                  document
                    .getElementById("Term of Payment")
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.table,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (
                  el?.remark?.includes("Kota Baru Ditambahkan untuk Posisi")
                ) {
                  document
                    .getElementById(`${el?.positionID}-${el?.updateValue}`)
                    .scrollIntoView({ behavior: "smooth" });
                  setSelectedLogs({
                    key: el?.table,
                    remark: el?.remark,
                    data: el,
                  });

                  timeoutIdRef.current = setTimeout(() => {
                    setSelectedLogs(null);
                  }, 5000);
                } else if (el?.table === "ExpectedPosition") {
                  if (
                    el?.remark?.includes("Perubahan Jumlah Permintaan Manpower")
                  ) {
                    document
                      .getElementById(
                        `${el?.positionID}-${el?.remark?.split(" untuk ")[2]}`
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  } else if (
                    el?.remark?.includes("Perubahan Salary") ||
                    el?.remark?.includes("Perubahan Expected Date")
                  ) {
                    document
                      .getElementById(
                        `${el?.positionID}-${el?.remark?.split(" untuk ")[1]}`
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  } else if (el?.remark?.includes("Ditambahkan")) {
                    document
                      .getElementById(el?.updateValue?.toUpperCase())
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  }
                } else if (el?.table === "ExpectedQualification") {
                  if (el?.remark?.includes("Ditambahkan")) {
                    document
                      .getElementById(
                        el?.remark
                          ?.split(" Posisi ")[1]
                          ?.split(" Ditambahkan")[0]
                          ?.toUpperCase()
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  } else if (el?.remark?.includes("Perubahan Kualifikasi")) {
                    document
                      .getElementById(
                        el?.remark?.split(" Posisi ")[1]?.toUpperCase()
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  }
                } else if (el?.table === "ExpectedEquipment") {
                  if (!el?.remark?.includes("Penghapusan")) {
                    if (!el?.remark?.includes("Penghapusan")) {
                      if (el?.remark?.includes("Ditambahkan")) {
                        document
                          .getElementById(
                            `${el?.positionID}-${
                              el?.remark
                                ?.split(" untuk ")[1]
                                ?.split(" Ditambahkan")[0]
                            }-Equipments`
                          )
                          .scrollIntoView({ behavior: "smooth" });
                        setSelectedLogs({
                          key: el?.table,
                          remark: el?.remark,
                          data: el,
                        });

                        timeoutIdRef.current = setTimeout(() => {
                          setSelectedLogs(null);
                        }, 5000);
                      } else if (el?.remark?.includes("Perubahan")) {
                        document
                          .getElementById(
                            `${el?.positionID}-${
                              el?.remark?.split(" untuk kota ")[1]
                            }-Equipments`
                          )
                          .scrollIntoView({ behavior: "smooth" });
                        setSelectedLogs({
                          key: el?.table,
                          remark: el?.remark,
                          data: el,
                        });

                        timeoutIdRef.current = setTimeout(() => {
                          setSelectedLogs(null);
                        }, 5000);
                      }
                    }
                  }
                } else if (el?.table === "ExpectedAllowance") {
                  if (el?.remark?.includes("Ditambahkan")) {
                    document
                      .getElementById(
                        `${el?.positionID}-${el?.remark
                          ?.split(" untuk ")[1]
                          ?.split(" Ditambahkan")[0]
                          ?.trim()}-Allowances`
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  } else if (el?.remark?.includes("Perubahan Allowance")) {
                    document
                      .getElementById(
                        `${el?.positionID}-${el?.remark
                          ?.split(" untuk ")[1]
                          ?.trim()}-Allowances`
                      )
                      .scrollIntoView({ behavior: "smooth" });
                    setSelectedLogs({
                      key: el?.table,
                      remark: el?.remark,
                      data: el,
                    });

                    timeoutIdRef.current = setTimeout(() => {
                      setSelectedLogs(null);
                    }, 5000);
                  }
                }
              }}
              key={idx}
            >
              <div>
                <Icon icon={"arrow-right"} color={"#fff"} size={14} />
              </div>
              <div>
                {el?.remark ? (
                  el?.table === "ExpectedPosition" ? (
                    el?.remark?.includes("Ditambahkan") ? (
                      <span className={Styles.remark}>
                        Posisi{" "}
                        <span>{el?.updateValue?.toUpperCase() || "-"}</span>{" "}
                        Ditambahkan
                      </span>
                    ) : el?.remark?.includes("Dihapus") ? (
                      <span className={Styles.remark}>
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split("Posisi ")[1]
                            ?.split(" Dihapus")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        Dihapus
                      </span>
                    ) : el?.remark?.includes("Perubahan") ? (
                      <span className={Styles.remark}>
                        {el?.remark?.split(" Posisi ")[0]} Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[1] || "-"}
                        </span>{" "}
                      </span>
                    ) : (
                      <span className={Styles.remark}>{el?.remark}</span>
                    )
                  ) : el?.table === "ExpectedQualification" ? (
                    el?.remark?.includes("Ditambahkan") ? (
                      <span className={Styles.remark}>
                        Kualifikasi Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" Ditambahkan")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        Ditambahkan
                      </span>
                    ) : el?.remark?.includes("Dihapus") ? (
                      <span className={Styles.remark}>
                        Kualifikasi Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" Dihapus")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        Dihapus
                      </span>
                    ) : el?.remark?.includes("Perubahan Kualifikasi Posisi") ? (
                      <span className={Styles.remark}>
                        Perubahan Kualifikasi Posisi{" "}
                        <span>
                          {el?.remark?.split(" Posisi ")[1]?.toUpperCase() ||
                            "-"}
                        </span>{" "}
                      </span>
                    ) : (
                      <span className={Styles.remark}>{el?.remark}</span>
                    )
                  ) : el?.table === "ExpectedAllowance" ? (
                    el?.remark?.includes("Ditambahkan") ? (
                      <span className={Styles.remark}>
                        Allowance{" "}
                        <span>
                          {el?.remark
                            ?.split("Allowance ")[1]
                            ?.split(" Posisi")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[1]
                            ?.split(" Ditambahkan")[0] || "-"}
                        </span>{" "}
                        Ditambahkan
                      </span>
                    ) : el?.remark?.includes("Penghapusan Allowance") ? (
                      <span className={Styles.remark}>
                        Penghapusan Allowance{" "}
                        <span>
                          {el?.remark
                            ?.split(" Allowance ")[1]
                            ?.split(" Posisi ")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
                      </span>
                    ) : el?.remark?.includes("Perubahan Allowance") ? (
                      <span className={Styles.remark}>
                        Perubahan Allowance{" "}
                        <span>
                          {el?.remark
                            ?.split(" Allowance ")[1]
                            ?.split(" Posisi ")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
                      </span>
                    ) : (
                      <span className={Styles.remark}>{el?.remark}</span>
                    )
                  ) : el?.table === "ExpectedEquipment" ? (
                    el?.remark?.includes(
                      "Perubahan Personal Work Equipment"
                    ) ? (
                      <span className={Styles.remark}>
                        Perubahan Personal Work Equipment{" "}
                        <span>
                          {el?.remark
                            ?.split(" Equipment ")[1]
                            ?.split(" Posisi ")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>
                          {el?.remark?.split(" untuk kota ")[1] || "-"}
                        </span>{" "}
                      </span>
                    ) : el?.remark?.includes(
                        "Penghapusan Personal Work Equipment"
                      ) ? (
                      <span className={Styles.remark}>
                        Penghapusan Personal Work Equipment{" "}
                        <span>
                          {el?.remark
                            ?.split(" Equipment ")[1]
                            ?.split(" Posisi ")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>{el?.remark?.split(" untuk ")[1] || "-"}</span>{" "}
                      </span>
                    ) : el?.remark?.includes(
                        "Penambahan Personal Work Equipment"
                      ) ? (
                      <span className={Styles.remark}>
                        Personal Work Equipment{" "}
                        <span>
                          {el?.remark
                            ?.split(" Equipment ")[1]
                            ?.split(" Posisi ")[0] || "-"}
                        </span>{" "}
                        Posisi{" "}
                        <span>
                          {el?.remark
                            ?.split(" Posisi ")[1]
                            ?.split(" untuk ")[0]
                            ?.toUpperCase() || "-"}
                        </span>{" "}
                        untuk{" "}
                        <span>
                          {el?.remark
                            ?.split(" untuk ")[1]
                            ?.split(" Ditambahkan")[0] || "-"}
                        </span>{" "}
                        Ditambahkan
                      </span>
                    ) : (
                      <span className={Styles.remark}>{el?.remark}</span>
                    )
                  ) : (
                    <span className={Styles.remark}>{el?.remark}</span>
                  )
                ) : (
                  <span className={Styles.remark}>-</span>
                )}

                {el?.table === "ClientContractLog" ? (
                  el?.remark?.includes("Penambahan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Dokumen Kontrak{" "}
                        {el?.updateValue
                          ? el?.updateValue?.split("::")[0]
                          : "-"}{" "}
                        dengan nomor{" "}
                        <span>
                          {el?.updateValue
                            ? el?.updateValue?.split("::")[1]
                            : "-"}{" "}
                        </span>
                        ditambahkan
                      </span>
                    </div>
                  ) : el?.remark?.includes("Expired") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Masa berlaku dokumen{" "}
                        {el?.updateValue
                          ? el?.updateValue?.split("::")[0]
                          : "-"}{" "}
                        dengan{" "}
                        <span>
                          {el?.updateValue
                            ? el?.updateValue?.split("::")[1]
                            : "-"}{" "}
                        </span>
                        telah habis.
                      </span>
                    </div>
                  ) : (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>: {el?.prevValue || "-"}</span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>: {el?.updateValue || "-"}</span>
                      </span>
                    </div>
                  )
                ) : el?.table === "ExpectedPosition" ? (
                  el?.remark?.includes("Ditambahkan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Posisi {el?.updateValue || "-"} telah ditambahkan
                      </span>
                    </div>
                  ) : el?.remark?.includes("Dihapus") ? (
                    <div className={Styles.descBox}>
                      <span>Posisi {el?.prevValue || "-"} telah dihapus</span>
                    </div>
                  ) : el?.remark?.includes("Perubahan Salary Posisi") ? (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>
                          :{" "}
                          {el?.prevValue
                            ? rupiahFormat(el?.prevValue?.split("::")[0])
                            : "-"}{" "}
                          •{" "}
                          {el?.prevValue ? el?.prevValue?.split("::")[1] : "-"}
                        </span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>
                          {" "}
                          :{" "}
                          {el?.updateValue
                            ? rupiahFormat(el?.updateValue?.split("::")[0])
                            : "-"}{" "}
                          •{" "}
                          {el?.updateValue
                            ? el?.updateValue?.split("::")[1]
                            : "-"}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>: {el?.prevValue || "-"}</span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>: {el?.updateValue || "-"}</span>
                      </span>
                    </div>
                  )
                ) : el?.table === "ExpectedQualification" ? (
                  el?.remark?.includes("Ditambahkan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Kualifikasi{" "}
                        <span>
                          "{el?.updateValue?.replace("::", ":") || "-"}"{" "}
                        </span>{" "}
                        ditambahkan
                      </span>
                    </div>
                  ) : el?.remark?.includes("Dihapus") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Kualifikasi{" "}
                        <span>
                          "{el?.prevValue?.replace("::", ":") || "-"}"
                        </span>{" "}
                        dihapus
                      </span>
                    </div>
                  ) : (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>: {el?.prevValue || "-"}</span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>: {el?.updateValue || "-"}</span>
                      </span>
                    </div>
                  )
                ) : el?.table === "ExpectedEquipment" ? (
                  el?.remark?.includes("Penambahan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Personal Work Equipment{" "}
                        {el?.updateValue?.split("::")[0] || "-"} telah
                        ditambahkan dengan nominal{" "}
                        {el?.updateValue
                          ? rupiahFormat(el?.updateValue?.split("::")[1])
                          : "-"}
                      </span>
                    </div>
                  ) : el?.remark?.includes("Penghapusan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Salah satu Personal Work Equipment telah dihapus
                      </span>
                    </div>
                  ) : (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>
                          : {el?.prevValue ? rupiahFormat(el?.prevValue) : "-"}
                        </span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>
                          :{" "}
                          {el?.updateValue
                            ? rupiahFormat(el?.updateValue)
                            : "-"}
                        </span>
                      </span>
                    </div>
                  )
                ) : el?.table === "ExpectedAllowance" ? (
                  el?.remark?.includes("Ditambahkan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Allowance {el?.updateValue?.split("::")[0] || "-"} telah
                        ditambahkan dengan nominal{" "}
                        {el?.updateValue
                          ? rupiahFormat(el?.updateValue?.split("::")[1])
                          : "-"}
                      </span>
                    </div>
                  ) : el?.remark?.includes("Penghapusan") ? (
                    <div className={Styles.descBox}>
                      <span>
                        Allowance {el?.prevValue || "-"} telah dihilangkan
                      </span>
                    </div>
                  ) : (
                    <div className={Styles.desc}>
                      <span>
                        <span> Before </span>
                        <span>
                          : {el?.prevValue ? rupiahFormat(el?.prevValue) : "-"}
                        </span>
                      </span>
                      <span>
                        <span>After</span>
                        <span>
                          :{" "}
                          {el?.updateValue
                            ? rupiahFormat(el?.updateValue)
                            : "-"}
                        </span>
                      </span>
                    </div>
                  )
                ) : (
                  <div className={Styles.desc}>
                    <span>
                      <span> Before </span>
                      <span>
                        :{" "}
                        {el?.prevValue
                          ? el?.remark === "Perubahan Management Fee"
                            ? el?.prevValue?.includes("%")
                              ? el?.prevValue
                              : rupiahFormat(el?.prevValue)
                            : el?.prevValue
                          : "-"}
                      </span>
                    </span>
                    <span>
                      <span>After</span>
                      <span>
                        :{" "}
                        {el?.updateValue
                          ? el?.remark === "Perubahan Management Fee"
                            ? el?.updateValue?.includes("%")
                              ? el?.updateValue
                              : rupiahFormat(el?.updateValue)
                            : el?.updateValue
                          : "-"}
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
