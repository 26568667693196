import { API } from "@Config/api";

export function getApprovals(page = '1', limit = '10', search = '', isApproved = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/bm-approval/${!isApproved ? 'recommendations' : 'approved'}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken')
          Authorization: localStorage.getItem('accessToken')
        },
        params: { page, limit, search }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getApprovalDetails(candidateID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/bm-approval/approved/${candidateID}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function candidateApproval(candidateID, file, remark) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/bm-approval/recommendations`, {
        candidateID,
        file,
        remark
      }, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function candidateReject(candidateID, tag) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/bm-approval/rejected/${candidateID}`, {
        tag
      }, {
        headers: {
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}