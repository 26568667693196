import { useState } from "react";
import Styles from "./style.module.scss";
import ConfirmContractType from "@Molecule/_modal/BusinessSupport/ConfirmContractType";

export default function ContractTypeSelector({
  selectedContractType,
  setSelectedContractType,
}) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState([]);

  const handleSelected = (type) => {
    if (type !== selectedContractType) {
      setShow(true);
      setValue([selectedContractType, type]);
    }
  };
  const handleSubmit = () => {
    if (value && value.length) {
      setSelectedContractType(value[1]);
      setShow(false);
    }
  };

  return (
    <div className={Styles.container}>
      <div>
        <p className={Styles.title}>
          <span>Pilih jenis kontrak karyawan</span>
        </p>
      </div>

      <div className={Styles.content}>
        <button
          className={`${Styles.buttonWrap} ${
            selectedContractType === "PKM" ? Styles.selectedButton : ""
          }`}
          onClick={() => handleSelected("PKM")}
        >
          <span>PKM</span>
        </button>
        <button
          className={`${Styles.buttonWrap} ${
            selectedContractType === "PKWT" ? Styles.selectedButton : ""
          }`}
          onClick={() => handleSelected("PKWT")}
        >
          <span>PKWT</span>
        </button>
      </div>

      {show && (
        <ConfirmContractType
          message={value}
          handleDone={handleSubmit}
          show={show}
          setShow={setShow}
        />
      )}
    </div>
  );
}
