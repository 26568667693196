import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const TipsAndTricksPostOption = ({
  title,
  icon,
  isActive,
  onClick,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      className={isActive ? Styles.selectedContainer : Styles.container}
    >
      <div className={Styles.left}>
        <Icon icon={icon} size="40" className={Styles.icon} />
        <div className={Styles.title}>{title}</div>
      </div>
      <Icon
        icon={isActive ? "radio-button-active" : "radio-button-default"}
        size="24"
        className={Styles.radioButton}
      />
    </div>
  );
};

export default TipsAndTricksPostOption;
