import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import { useEffect, useMemo, useState } from "react";
import CustomTable from "@Molecule/CustomTable";
import { fileBaseUrl } from "@Config/api";
import ModalWrapper from "@Atom/ModalWrapper";
import InterviewResultModal from "@Molecule/_modal/InterviewResultModal";
import DetailCandidateRecruitment from "../../DetailCandidateRekrutment";
import { joinArrayToString } from "@Helpers/joinArrayToString";
import { sendCandidatesRecommendation } from "@Services/officer/recruitment";
import DoneModal from "@Molecule/_modal/DoneModal";

export default function PreviewRecommended({
  handleClose,
  selectedCandidates,
  selectedContact,
  selectedClient,
  selectedPosition,
  refetchDataAllCandidate,
  handleDone,
  setShowSendRecommended,
}) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedResult, setSelectedResult] = useState(null);
  const [showDetailCandidate, setShowDetailCandidate] = useState(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  //   console.log(selectedCandidates);

  const Template = useMemo(()=> {
    return {
      data: selectedCandidates
        ?.map((el, idx) => {
          return {
            id: idx,
            candidateId: el?.id,
            name: el?.name,
            position: el?.ExpectedPosition?.name,
            domicile: el?.domicile,
            address: el?.address,
            interviewResult: el?.CandidateEvaluation?.EvaluationScores || [],
            profilePicture: el?.CandidateDocuments || null,
            CandidateEvaluation: el?.CandidateEvaluation || null,
          };
        })
        ?.slice((page - 1) * limit, page * limit),
      columns: [
        {
          name: "name",
          label: "Nama Kandidat",
          minWidth: windowSize[0] > 768 ? "300px" : "170px",
          maxWidth: windowSize[0] > 768 ? "300px" : "170px",
          renderData: (row) => (
            <div
              className={Styles.profileRow}
              onClick={() => {
                setShowDetailCandidate(row);
              }}
            >
              <div>
                <img
                  src={
                    row?.profilePicture
                      ? fileBaseUrl + row?.profilePicture?.url
                      : Images.AVA_DEFAULT
                  }
                  alt=""
                />
              </div>
              <div>{row?.name || "-"}</div>
            </div>
          ),
        },
        {
          name: "position",
          label: "Posisi",
          minWidth: "96px",
        },
        {
          name: "domicile",
          label: "Domisili",
          // hideOnMobile: true,
        },
        {
          name: "address",
          label: "Alamat",
          minWidth: "180px",
          // hideOnMobile: true,
        },
        {
          name: "interviewResult",
          label: "Hasil Interview",
          // minWidth: '180px',
          // align: 'center',
          renderData: (row) => (
            <span
              className={Styles.scoresRow}
              onClick={() => setSelectedResult(row)}
            >
              {row?.interviewResult?.length === 0
                ? 0
                : (
                    +row?.interviewResult?.reduce(
                      (acc, item) => acc + item.score,
                      0
                    ) / row?.interviewResult?.length
                  )?.toFixed(1)}
            </span>
          ),
        },
      ],
    }
  }, [limit, page, selectedCandidates, windowSize])

  const totalPage = useMemo(() => {
    return Math.ceil(Template?.data?.length / limit);
  }, [Template, limit]);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSendToClient = async () => {
    setIsSending(true);
    try {
      await sendCandidatesRecommendation({
        contactName: selectedContact?.name,
        contactClient: selectedClient?.name,
        contactPhone: selectedContact?.phoneNumber,
        requestTotal: selectedPosition?.amount,
        requestPositions: selectedPosition?.name,
        requestCities: selectedPosition?.City?.name,
        candidates: selectedCandidates?.map((el) => el?.id)?.join(","),
        clientCode: selectedClient?.code,
        contactEmail: selectedContact?.email,
        via: joinArrayToString(selectedContact?.via),
      });
      //   setTrigger(makeRandomString(5));
      setIsSending(false);
      setIsSuccess(true);
      refetchDataAllCandidate();
    } catch (err) {
      setIsSending(false);
      console.log(err);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <div onClick={handleClose}>
            <div>
              <Icon
                icon={"arrow-left-rounded-primary"}
                size={24}
                color={"#000"}
              />
            </div>
            <span>Back</span>
          </div>
        </div>

        <div className={Styles.middle}>
          <span>Rekomendasi Kandidat</span>
          <div>
            <div>
              <img src={Images.INITIAL_LOGO} alt="logo" />
            </div>
            <span>PT Permata Indo Sejahtera</span>
          </div>
        </div>

        <div className={Styles.bottom}>
          <div>
            <Icon icon={"information-solid"} size={16} color={"#1571DE"} />
            <span>
              Berikut adalah halaman preview rekomendasi kandidat, jika sudah
              sesuai silahkan kirim daftar ini ke klien{" "}
            </span>
          </div>
        </div>
      </div>

      <div className={Styles.contents}>
        <div>
          <div>
            <div>
              <span>Contact Details</span>
              <div className={Styles.details}>
                <div>
                  <div>
                    <img src={Images.AVA_DEFAULT} alt="" />
                  </div>
                  <span>{selectedContact?.name || "-"}</span>
                </div>

                <div>
                  <Icon icon={"building"} size={16} color={"#0A0A0A"} />
                  <span>{selectedClient?.name || "-"}</span>
                </div>

                <div>
                  <Icon icon={"phone"} size={16} color={"#0A0A0A"} />
                  <span>+{selectedContact?.phoneNumber || "-"}</span>
                </div>

                <div>
                  <Icon icon={"email"} size={16} color={"#0A0A0A"} />
                  <span>{selectedContact?.email || "-"}</span>
                </div>
              </div>
            </div>
            <div>
              <span>Request Details</span>
              <div className={Styles.details}>
                <div>
                  <Icon icon={"people"} size={16} color={"#0A0A0A"} />
                  <span>Total Request : {selectedPosition?.amount || 0}</span>
                </div>

                <div>
                  <Icon icon={"bag-job"} size={16} color={"#0A0A0A"} />
                  <span>{selectedPosition?.name || 0}</span>
                </div>

                <div>
                  <Icon icon={"building"} size={16} color={"#0A0A0A"} />
                  <span>{selectedPosition?.City?.name || "-"}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${
                !selectedClient ||
                !selectedPosition ||
                !selectedContact ||
                selectedCandidates?.length === 0 ||
                isSending
                  ? Styles.disabled
                  : ""
              }`}
              onClick={
                !selectedClient ||
                !selectedPosition ||
                !selectedContact ||
                selectedCandidates?.length === 0 ||
                isSending
                  ? null
                  : handleSendToClient
              }
            >
              <span>Kirim ke klien</span>
              <Icon icon={"page-plus"} size={16} color={"#FFFFFF"} />
            </div>
          </div>
        </div>

        <div className={Styles.tableContainer}>
          <CustomTable
            columns={Template?.columns}
            data={Template?.data || []}
            type={"sticky-left"}
            value={limit}
            setValue={setLimit}
            selectedPage={page}
            setSelectedPage={setPage}
            totalPage={totalPage}
          />
        </div>
      </div>

      {selectedResult && (
        <ModalWrapper
          show={selectedResult}
          handleClose={() => setSelectedResult(null)}
        >
          <InterviewResultModal
            data={selectedResult}
            onClose={() => setSelectedResult(null)}
          />
        </ModalWrapper>
      )}

      {showDetailCandidate && (
        <ModalWrapper show={showDetailCandidate} handleClose={() => {}}>
          <DetailCandidateRecruitment
            handleClose={() => {
              setShowDetailCandidate(null);
            }}
            showDetailCandidate={showDetailCandidate}
            isClient
          />
        </ModalWrapper>
      )}

      {isSuccess && (
        <DoneModal
          handleDone={() => {
            handleClose();
            handleDone();
            // setShow(false);
            // setShowSend(false);
            setIsSuccess(false);
            setShowSendRecommended(false);
          }}
          title="Sent!"
          description={"Recommendation successfully sent to client!"}
          doneButton
          doneTitle={"Ok"}
        />
      )}
    </div>
  );
}
