/* eslint-disable eqeqeq */
import AutocompleteV2 from "@Atom/AutocompleteV2";
import Icon from "@Atom/Icon";
import { useDebounce } from "@Hooks/useDebounce";
import InputField, { InputTitle } from "@Molecule/InputField";
import { addClientSync } from "@Services/manager/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Input from "@Atom/Input";
import { validateEmail } from "@Helpers/validateEmail";

export default function InfoSection({
  form,
  setForm,
  onChange,
  onChangeValue,
  alert,
  type,
  setType,
  resetForm,
  setContacts,
  existingType,
  setExistingType,
  setFiles,
  client,
  setClient,
  legalNameType = "",
  setLegalNameType = () => {},
  cities = [],
  industries = [],
  setRemark,
  validate,
}) {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandLegalNameType, setExpandLegalNameType] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [temporaryExistingType, setTemporaryExistingType] = useState("");

  const clientInfo = useMemo(() => {
    // return []
    return {
      left: [
        {
          title: "Nama Perusahaan",
          value: client?.companyName,
        },
        {
          title: "Kode Pos",
          value: client?.postal,
        },
        {
          title: "Alamat Perusahaan",
          value: client?.address,
        },
        {
          title: "No Telepon Perusahaan",
          value: client?.phoneNumber,
        },
        {
          title: "Website Perusahaan",
          value: client?.website,
        },
      ],
      right: [
        {
          title: "NPWP Perusahaan",
          value: client?.taxNumber,
        },
        {
          title: "Provinsi Perusahaan",
          value: client?.province,
        },
      ],
    };
  }, [
    client?.address,
    client?.companyName,
    client?.phoneNumber,
    client?.postal,
    client?.province,
    client?.taxNumber,
    client?.website,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorOnFetching, setErrorOnFetching] = useState("");

  const fetchClients = useCallback(async () => {
    if (debouncedSearchTerm) {
      // if(!client) {}
      try {
        setIsLoading(true);
        const { response } = await addClientSync(
          debouncedSearchTerm?.includes(" - ")
            ? debouncedSearchTerm.split(" - ")[1]
            : debouncedSearchTerm
        );
        setIsLoading(false);
        setTemporaryExistingType(response?.source);
        setClients(response?.client || []);
      } catch (err) {
        setIsLoading(false);
        setErrorOnFetching(
          err?.response?.data?.error || "Something went wrong"
        );
        console.log(err);
      }
    } else {
      setClient(null);
      // setClients([])
      // setExistingType('')
      // setIsLoading(false)
      // setSearchTerm('')
    }
  }, [debouncedSearchTerm, setClient]);

  // const [showModal, setShowModal] = useState(false);

  const onClickOption = (o) => {
    // setShowModal(true);
    setExistingType(temporaryExistingType);
    setClient({
      ...o,
      name: o?.companyName,
    });
    setSearchTerm(o?.taxNumber);
    onChangeValue(o?.name, "name");
    onChangeValue(o?.companyName, "companyName");
    onChangeValue(o?.postal, "postal");
    onChangeValue(o?.province, "province");
    onChangeValue(o?.address, "address");
    onChangeValue(o?.phoneNumber, "phoneNumber");
    onChangeValue(o?.taxNumber, "taxNumber");
    onChangeValue(o?.legalName?.replace(/^\S+\s*/, ""), "legalName");
    onChangeValue(o?.groupName, "groupName");
    onChangeValue(o?.brandName, "brandName");
    onChangeValue(o?.email, "email");
    setContacts(
      o?.contacts?.length
        ? o?.contacts?.map((obj) => {
            return {
              ...obj,
              name: obj?.name,
              jobLevel:
                obj?.jobTitle && obj?.jobTitle !== "undefined"
                  ? obj?.jobTitle
                  : "",
              email: obj?.email,
              phoneNumber: obj?.phoneNumber,
              workUnit: obj?.workUnit,
              selectedUnit:
                obj?.workUnit?.toLowerCase() === "payroll" ||
                obj?.workUnit?.toLowerCase() === "invoice" ||
                obj?.workUnit?.toLowerCase() === "payroll & invoice" ||
                obj?.workUnit?.toLowerCase() === "invoice & payroll"
                  ? obj?.workUnit
                      ?.split(" ")
                      .map((word) => {
                        return word.charAt(0) + word.slice(1).toLowerCase();
                      })
                      .join(" ")
                      ?.split(" & ")
                  : ["Lainnya"],
              viewOnly: true,
            };
          })
        : [
            {
              name: "",
              jobLevel: "",
              email: "",
              phoneNumber: "",
              workUnit: "",
              selectedUnit: [],
            },
          ]
    );
    setRemark(o?.remark);
    setForm((prev) => {
      return {
        ...prev,
        industry: o?.industry,
      };
    });
  };

  useEffect(() => {
    if (type === "EXISTING") {
      fetchClients();
    }
  }, [fetchClients, type]);

  useEffect(() => {
    sessionStorage.setItem("clientExistingType", existingType);
  }, [existingType]);

  useEffect(() => {
    sessionStorage.setItem("clientInformationForm", JSON.stringify(form));
  }, [form]);

  useEffect(() => {
    if (type === "NEW") {
      if (!form?.name) {
        setType("EXISTING");
        setExistingType("");
        setClient(null);
      }
    } else {
      if (!searchTerm) {
        setExistingType("");
        setClient(null);
      }
    }
  }, [form?.name, searchTerm, setClient, setExistingType, setType, type]);

  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"company"} size={20} />
      </div>
      <div className={Styles.header}>
        <h3>Informasi Perusahaan</h3>
        <span className={alert ? Styles.alert : Styles.normal}>
          Harap lengkapi informasi perusahaan
        </span>
      </div>
      {type === "NEW" || (type === "EXISTING" && existingType !== "k365") ? (
        <div className={Styles.content}>
          <div className={Styles.section}>
            <span>Nama & Informasi Kontak</span>
            <div className={Styles.sectionContent}>
              {type === "NEW" ? (
                <InputField
                  isError={validate?.taxNumber === true && !form?.name}
                  value={form?.name}
                  onChange={(e) => {
                    onChange(e, "name");
                  }}
                  title={
                    <p>
                      NPWP Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Berdasarkan pendirian perusahaan"
                  onKeyDown={(e) => {
                    var key = e.which || e.keyCode;
                    // eslint-disable-next-line eqeqeq
                    if (
                      key &&
                      (key <= 47 || key >= 58) &&
                      key != 8 &&
                      key != 37 &&
                      key != 39 &&
                      key != 9
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              ) : (
                <div className={`${Styles.autocomplete} ${Styles.z2}`}>
                  <p>
                    NPWP Perusahaan
                    <span className={Styles.asterisk}>&#x2a;</span>
                  </p>
                  <div className={Styles.fieldWrapper}>
                    {Boolean(
                      !isLoading && debouncedSearchTerm && !clients?.length
                    ) && (
                      <button
                        className={Styles.addNewButton}
                        onClick={() => {
                          resetForm();
                          setForm((prev) => {
                            return {
                              ...prev,
                              name: debouncedSearchTerm,
                              taxNumber: debouncedSearchTerm,
                            };
                          });
                          setType("NEW");
                        }}
                      >
                        <Icon icon={"circle-plus"} size={16} />
                        <span>Tambah baru</span>
                      </button>
                    )}
                    <AutocompleteV2
                      isError={validate?.taxNumber === true && !searchTerm}
                      isErrorText={validate?.taxNumber === true && !searchTerm}
                      isLoading={isLoading}
                      forceValue
                      value={searchTerm}
                      setValue={setSearchTerm}
                      errorOnFetching={errorOnFetching}
                      onClickOption={onClickOption}
                      options={clients?.map((obj) => {
                        return {
                          ...obj,
                          name: obj?.name + " - " + obj?.taxNumber,
                          companyName: obj?.name,
                        };
                      })}
                      placeholder="Berdasarkan pendirian perusahaan"
                      onKeyDown={(e) => {
                        var key = e.which || e.keyCode;
                        // eslint-disable-next-line eqeqeq
                        if (
                          key &&
                          (key <= 47 || key >= 58) &&
                          key != 8 &&
                          key != 37 &&
                          key != 39 &&
                          key != 9
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              )}

              <div className={Styles.divider}>
                <InputField
                  isError={
                    validate?.companyName === true &&
                    form?.name &&
                    !form?.companyName
                  }
                  // value={type === 'NEW' ? form?.website || "" : ''}
                  value={form?.companyName?.toUpperCase()}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "companyName")}
                  title={
                    <p>
                      Nama Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Nama perusahaan"
                />
                <div className={Styles.legalNamewrapper}>
                  <InputTitle
                    title={
                      <p>
                        Nama Resmi Perusahaan (Terdaftar Secara Legal)
                        <span className={Styles.asterisk}>&#x2a;</span>
                      </p>
                    }
                  />
                  <div
                    className={`${Styles.sWrapper} ${
                      validate?.legalName === true &&
                      form?.name &&
                      !form?.legalName &&
                      Styles.borderRed
                    }`}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setExpandLegalNameType(!expandLegalNameType)
                      }
                    >
                      <span>{legalNameType}</span>
                      <Icon
                        icon={expandLegalNameType ? "arrow-up" : "arrow-down"}
                        size={16}
                      />
                    </button>
                    {expandLegalNameType && (
                      <div className={Styles.types}>
                        {["PT", "CV"]?.map((t, tI) => (
                          <div
                            key={tI}
                            onClick={() => {
                              setLegalNameType(t);
                              setExpandLegalNameType(false);
                            }}
                          >
                            {t}
                          </div>
                        ))}
                      </div>
                    )}
                    <Input
                      // value={type === 'NEW' ? form?.website || "" : ''}
                      value={form?.legalName?.toUpperCase()}
                      floatingStartAdornment={"PT"}
                      disabled={
                        type === "EXISTING" && existingType !== "mws" && !client
                      }
                      onChange={(e) => onChange(e, "legalName")}
                      placeholder="Nama resmi perusahaan"
                    />
                  </div>
                  {validate?.legalName === true &&
                  form?.name &&
                  !form?.legalName ? (
                    <span>Please fill the field </span>
                  ) : null}
                </div>
              </div>

              <div className={Styles.divider}>
                <InputField
                  isError={
                    validate?.groupName === true &&
                    form?.name &&
                    !form?.groupName
                  }
                  // value={type === 'NEW' ? form?.taxNumber || "" : ''}
                  value={form?.groupName?.toUpperCase()}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "groupName")}
                  title={
                    <p>
                      Nama grup perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Nama grup perusahaan"
                />
              </div>
              <div className={Styles.divider}>
                <InputField
                  isError={
                    validate?.brandName === true &&
                    form?.name &&
                    !form?.brandName
                  }
                  // value={type === 'NEW' ? form?.website || "" : ''}
                  value={form?.brandName?.toUpperCase()}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "brandName")}
                  title={
                    <p>
                      Nama brand perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Nama brand perusahaan"
                />
                <div className={Styles.autocomplete}>
                  <p>
                    Industri Perusahaan
                    <span className={Styles.asterisk}>&#x2a;</span>
                  </p>
                  <AutocompleteV2
                    isError={
                      validate?.industry === true &&
                      form?.name &&
                      !form?.industry
                    }
                    isErrorText={
                      validate?.industry === true &&
                      form?.name &&
                      !form?.industry
                    }
                    // value={type === 'NEW' ? form?.taxNumber || "" : ''}
                    value={form?.industry}
                    viewOnly={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                    setValue={(newVal) =>
                      setForm((prev) => {
                        return {
                          ...prev,
                          industry: newVal,
                        };
                      })
                    }
                    options={industries?.map((obj) => {
                      return {
                        ...obj,
                        name: obj?.bisnis_name,
                      };
                    })}
                    placeholder="Industri Perusahaan"
                  />
                </div>
              </div>
              <div className={Styles.divider}>
                <InputField
                  // value={type === 'NEW' ? form?.website || "" : ''}
                  value={form?.website}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "website")}
                  title={<p>Website Perusahaan</p>}
                  placeholder="Website resmi perusahaan"
                />
                {/* <InputField
              // value={type === 'NEW' ? form?.taxNumber || "" : ''}
              value={form?.taxNumber}
              disabled={
                type === "EXISTING" && existingType !== "mws" && !client
              }
              onChange={(e) => onChange(e, "taxNumber")}
              title={
                <p>
                  NPWP Perusahaan<span className={Styles.asterisk}>&#x2a;</span>
                </p>
              }
              placeholder="NPWP perusahaan anda"
            /> */}
              </div>
              <div className={Styles.divider}>
                <InputField
                  isError={
                    validate?.phoneNumber === true &&
                    form?.name &&
                    !form.phoneNumber
                  }
                  // value={type === 'NEW' ? form?.phoneNumber || "" : ''}
                  value={form.phoneNumber}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "phoneNumber")}
                  type="phone"
                  title={
                    <p>
                      Nomor Telepon Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="8 ***"
                  isAddClient={true}
                />

                <InputField
                  isError={
                    validate?.email === true &&
                    form?.name &&
                    (!form?.email || !validateEmail(form?.email))
                  }
                  value={form?.email?.toLowerCase()}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "email")}
                  title={
                    <p>
                      Email Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="example@mail.com"
                  errorText={
                    !form?.email
                      ? "Please fill the field"
                      : "Email is not in the correct format"
                  }
                />
              </div>
            </div>
          </div>

          <div className={Styles.section}>
            <span>Alamat</span>
            <div className={Styles.sectionContent}>
              <InputField
                isError={
                  validate?.address === true && form?.name && !form?.address
                }
                value={form?.address?.toUpperCase()}
                disabled={
                  type === "EXISTING" && existingType !== "mws" && !client
                }
                onChange={(e) => onChange(e, "address")}
                type="address"
                title={
                  <p>
                    Alamat Perusahaan
                    <span className={Styles.asterisk}>&#x2a;</span>
                  </p>
                }
                placeholder="Alamat lengkap perusahaan anda"
              />
              <div className={Styles.divider}>
                <InputField
                  isError={
                    validate?.province === true && form?.name && !form?.province
                  }
                  value={form?.province?.toUpperCase()}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "province")}
                  title={
                    <p>
                      Provinsi Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Provinsi perusahaan anda"
                />
                <InputField
                  isError={
                    validate?.postal === true && form?.name && !form?.postal
                  }
                  value={form?.postal}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  onChange={(e) => onChange(e, "postal")}
                  title={
                    <p>
                      Kode Pos<span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Kode pos daerah perusahaan"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={Styles.viewOnlyContent}>
          <div className={Styles.top}>
            <div className={Styles.info}>
              <Icon icon={"information-solid"} size={20} />
              <p>
                Silahkan isi nama perusahaan dan pilih perusahaan yang ingin
                ditambahkan, data akan terisi otomatis.
              </p>
            </div>
            <div className={`${Styles.autocomplete} ${Styles.z2}`}>
              <p>
                NPWP Perusahaan<span className={Styles.asterisk}>&#x2a;</span>
              </p>
              <AutocompleteV2
                isLoading={isLoading}
                value={searchTerm}
                setValue={setSearchTerm}
                errorOnFetching={errorOnFetching}
                onClickOption={onClickOption}
                forceValue
                options={clients?.map((obj) => {
                  return {
                    ...obj,
                    name: obj?.name + " - " + obj?.taxNumber,
                    companyName: obj?.name,
                  };
                })}
                placeholder="Berdasarkan pendirian perusahaan"
                onKeyDown={(e) => {
                  var key = e.which || e.keyCode;
                  // eslint-disable-next-line eqeqeq
                  if (
                    key &&
                    (key <= 47 || key >= 58) &&
                    key != 8 &&
                    key != 37 &&
                    key != 39 &&
                    key != 9
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className={Styles.additionalFields}>
              <div className={Styles.legalNamewrapper}>
                <InputTitle
                  title={
                    <p>
                      Nama Resmi Perusahaan (Terdaftar Secara Legal)
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                />
                <div
                  className={`${Styles.sWrapper} ${
                    validate?.legalName === true &&
                    form?.name &&
                    !form?.legalName &&
                    Styles.borderRed
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => setExpandLegalNameType(!expandLegalNameType)}
                  >
                    <span>{legalNameType}</span>
                    <Icon
                      icon={expandLegalNameType ? "arrow-up" : "arrow-down"}
                      size={16}
                    />
                  </button>
                  {expandLegalNameType && (
                    <div className={Styles.types}>
                      {["PT", "CV"]?.map((t, tI) => (
                        <div
                          key={tI}
                          onClick={() => {
                            setLegalNameType(t);
                            setExpandLegalNameType(false);
                          }}
                        >
                          {t}
                        </div>
                      ))}
                    </div>
                  )}
                  <Input
                    // value={type === 'NEW' ? form?.website || "" : ''}
                    value={form?.legalName?.toUpperCase()}
                    floatingStartAdornment={"PT"}
                    disabled={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                    onChange={(e) => onChange(e, "legalName")}
                    placeholder="Nama resmi perusahaan"
                  />
                </div>

                {validate?.legalName === true &&
                form?.name &&
                !form?.legalName ? (
                  <span>Please fill the field </span>
                ) : null}
              </div>
              <InputField
                isError={
                  validate?.groupName === true && form?.name && !form?.groupName
                }
                // value={type === 'NEW' ? form?.taxNumber || "" : ''}
                value={form?.groupName?.toUpperCase()}
                disabled={
                  type === "EXISTING" && existingType !== "mws" && !client
                }
                onChange={(e) => onChange(e, "groupName")}
                title={
                  <p>
                    Nama grup perusahaan
                    <span className={Styles.asterisk}>&#x2a;</span>
                  </p>
                }
                placeholder="Nama grup perusahaan"
              />
              <InputField
                isError={
                  validate?.brandName === true && form?.name && !form?.brandName
                }
                // value={type === 'NEW' ? form?.website || "" : ''}
                value={form?.brandName?.toUpperCase()}
                disabled={
                  type === "EXISTING" && existingType !== "mws" && !client
                }
                onChange={(e) => onChange(e, "brandName")}
                title={
                  <p>
                    Nama brand perusahaan
                    <span className={Styles.asterisk}>&#x2a;</span>
                  </p>
                }
                placeholder="Nama brand perusahaan"
              />
              <div className={Styles.autocomplete}>
                <p>
                  Industri Perusahaan
                  <span className={Styles.asterisk}>&#x2a;</span>
                </p>
                <AutocompleteV2
                  isError={
                    validate?.industry === true && form?.name && !form?.industry
                  }
                  isErrorText={
                    validate?.industry === true && form?.name && !form?.industry
                  }
                  // value={type === 'NEW' ? form?.taxNumber || "" : ''}
                  value={form?.industry}
                  disabled={
                    type === "EXISTING" && existingType !== "mws" && !client
                  }
                  setValue={(newVal) =>
                    setForm((prev) => {
                      return {
                        ...prev,
                        industry: newVal,
                      };
                    })
                  }
                  options={industries?.map((obj) => {
                    return {
                      ...obj,
                      name: obj?.bisnis_name,
                    };
                  })}
                  title={
                    <p>
                      Industri Perusahaan
                      <span className={Styles.asterisk}>&#x2a;</span>
                    </p>
                  }
                  placeholder="Industri Perusahaan"
                />
              </div>
            </div>
          </div>
          <div className={Styles.bottom}>
            <div className={Styles.left}>
              {clientInfo?.left?.map((obj) => (
                <div key={obj?.title}>
                  <span>{obj?.title}</span>
                  <span>{obj?.value || "-"}</span>
                </div>
              ))}
            </div>
            <div className={Styles.right}>
              {clientInfo?.right?.map((obj) => (
                <div key={obj?.title}>
                  <span>{obj?.title}</span>
                  <span>{obj?.value || "-"}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* {showModal && (
        <ModalWrapper
          show={showModal}
          forceCenter
          handleClose={() => setShowModal(false)}
        >
          <ExistingClientModal
            type={existingType}
            onClose={() => setShowModal(false)}
          />
        </ModalWrapper>
      )} */}
    </div>
  );
}
