import CreateContract from "@Organism/HumanCapital/CreateContract";
import { useLocation } from "react-router-dom";
import { getTemplateSync } from "@Services/hc/contract";
import { defer, useLoaderData, Await } from "react-router-dom";
import { Suspense } from "react";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const type = url.searchParams.get("t") || "CONTRACT";
  const createTemplateSyncPromise = getTemplateSync("", type);

  return defer({
    createTemplateSync: createTemplateSyncPromise,
    type,
  });
}

const CreateContractPage = () => {
  const location = useLocation();
  const { data } = location.state;
  // eslint-disable-next-line no-unused-vars
  const { createTemplateSync, type } = useLoaderData();
  const state = location.state;

  return (
    <Suspense>
      <Await resolve={createTemplateSync}>
        {(createTemplateSync) => (
          <CreateContract
            type={state.type}
            createTemplateSync={createTemplateSync}
            data={data}
          />
        )}
      </Await>
    </Suspense>
  );
};

export default CreateContractPage;
