import { Skeleton } from "@mui/material";
import Styles from "./style.module.scss";

export default function LoadingSkeleton() {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Skeleton
          variant="rectangular"
          animation={false}
          className={Styles.image}
        />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.logo}
        />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.name}
        />
        <Skeleton
          variant="rectangular"
          animation={"wave"}
          className={Styles.contact}
        />
      </div>
      <div className={Styles.content}>
        <div className={Styles.profile}>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.name}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.nameContent}
            />
          </div>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.website}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.websiteContent}
            />
          </div>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.phoneNumber}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.phoneNumberContent}
            />
          </div>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.address}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.addressContent}
            />
          </div>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.province}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.provinceContent}
            />
          </div>
          <div className={Styles.stacked}>
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.postal}
            />
            <Skeleton
              variant="rectangular"
              animation={"wave"}
              className={Styles.postalContent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
