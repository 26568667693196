import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Icon from "@Atom/Icon";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import Styles from "./dropdown.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";

const DropdownJobPosting = ({
  placeholder,
  disabled,
  options,
  value,
  setValue,
  autoClose,
  fixedHeight,
  iconColor,
  withDefaultSetter,
  defaultText,
  handleSetDefault,
  withIcon = false,
  iconName,
  openModal,
  valueName,
  addList,
  onEdit,
  type,
  selectedItems,
  setSelectedItems,
  handleKeydownEvaluation,
  addEvaluation,
  handleChangeInputEvaluation,
  valueEvaluation,
  disabledEvaluation,
  setValueCity,
  valueCity,
  valueCityCode,
  setValueCityCode,
  setValueId,
  valueId,
  ...props
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      setIsActive(false);
    }
  }, [boxOutsideClick]);

  const activateDropdown = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (type === "penilaian") {
      if (disabled) {
        setValue(null);
        // setValueCity(null);
        // setValueAdditional("");
      }
    } else {
      if (disabled) {
        setValue(null);
        setValueCity(null);
        setValueCityCode(null);
        setValueId(null);
        // setValueAdditional("");
      }
    }
  }, [disabled, setValue, type, setValueCity, setValueCityCode, setValueId]);

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]?.id === item?.id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  const handleCheckboxChange = (item) => {
    let isSelected = handleChecked(item);
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selected) => selected?.id !== item?.id)
      );
      // setIsActive(false);
    } else {
      setSelectedItems([...selectedItems, item]);
      // setIsActive(false);
    }
  };

  if (type === "penilaian") {
    return (
      <div style={{ position: "relative" }} ref={boxRef}>
        <div
          className={`${Styles.containerPenilaian} ${
            isActive ? Styles.activeContainer : null
          }
        ${disabledEvaluation ? Styles.containerColorDisabled : null}
        `}
          onClick={activateDropdown}
          style={{ height: fixedHeight || "auto" }}
        >
          {withIcon && (
            <div className={Styles.iconWrapper}>
              <Icon
                icon={iconName}
                size="20px"
                color={iconColor || "#0a0a0a"}
              />
            </div>
          )}
          <div className={value ? Styles.valueText : Styles.placeholderText}>
            {placeholder}
          </div>
          {/* {disabledEvaluation && (
            <Icon
              icon="arrow-down"
              size="20px"
              color={iconColor || "#0a0a0a"}
            />
          )} */}
          {isActive && !disabledEvaluation ? (
            <Icon icon="arrow-up" size="20px" color={iconColor || "#0a0a0a"} />
          ) : (
            <Icon
              icon="arrow-down"
              size="20px"
              color={iconColor || "#0a0a0a"}
            />
          )}
        </div>
        {isActive && !disabledEvaluation ? (
          <div className={Styles.options}>
            {withDefaultSetter && (
              <div className={Styles.optionWrapper}>
                <div
                  className={`${Styles.option} ${Styles.default}`}
                  onClick={() => {
                    if (autoClose) {
                      handleSetDefault();
                      setIsActive(false);
                    } else {
                      handleSetDefault();
                    }
                  }}
                >
                  {defaultText}
                </div>
                <div className={Styles.borderBottom}></div>
              </div>
            )}

            <div className={Styles.boxListDropdown}>
              {options?.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      // onClick={() => {
                      //   if (autoClose) {
                      //     setValue(option.name);
                      //     setIsActive(false);
                      //   } else {
                      //     setValue(option.name);
                      //   }
                      // }}
                    >
                      <div className={Styles.leftDropdown}>
                        <CheckboxJobPosting
                          checked={handleChecked(option)}
                          onChange={() => {
                            handleCheckboxChange(option);
                            // setIsActive(false);
                          }}
                        />
                        {option?.name}
                      </div>
                    </div>
                    {/* {id === options.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )} */}
                  </div>
                );
              })}
              {addEvaluation?.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      // onClick={() => {
                      //   if (autoClose) {
                      //     setValue(option.name);
                      //     setIsActive(false);
                      //   } else {
                      //     setValue(option.name);
                      //   }
                      // }}
                    >
                      <div className={Styles.leftDropdown}>
                        <CheckboxJobPosting
                          checked={handleChecked(option)}
                          onChange={() => {
                            handleCheckboxChange(option);
                            // setIsActive(false);
                          }}
                        />
                        {option?.name}
                      </div>
                    </div>
                    {/* {id === options.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )} */}
                  </div>
                );
              })}
            </div>

            <div className={Styles.otherChoose} onClick={openModal}>
              <span className={Styles.otherTitle}>Lainnya •</span>
              <textarea
                className={Styles.inputAddEvaluations}
                placeholder="Buat posisi sesuai kebutuhan"
                name="evaluation"
                onChange={handleChangeInputEvaluation}
                value={valueEvaluation}
                onKeyDown={handleKeydownEvaluation}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }} ref={boxRef}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
        onClick={activateDropdown}
        style={{ height: fixedHeight || "auto" }}
      >
        {withIcon && (
          <div className={Styles.iconWrapper}>
            <Icon icon={iconName} size="20px" color={iconColor || "#0a0a0a"} />
          </div>
        )}
        <div className={value ? Styles.valueText : Styles.placeholderText}>
          {disabled ? (
            "Select"
          ) : value ? (
            <div className={Styles.valueBox}>
              <span>{value}</span>
              {valueCity ? <span>{valueCity}</span> : null}
            </div>
          ) : (
            placeholder
          )}
        </div>
        {isActive ? (
          <Icon icon="arrow-up" size="20px" color={iconColor || "#0a0a0a"} />
        ) : (
          <Icon icon="arrow-down" size="20px" color={iconColor || "#0a0a0a"} />
        )}
      </div>
      {isActive && !disabled ? (
        <div className={Styles.options}>
          {withDefaultSetter && (
            <div className={Styles.optionWrapper}>
              <div
                className={`${Styles.option} ${Styles.default}`}
                onClick={() => {
                  if (autoClose) {
                    handleSetDefault();
                    setIsActive(false);
                  } else {
                    handleSetDefault();
                  }
                }}
              >
                {defaultText}
              </div>
              <div className={Styles.borderBottom}></div>
            </div>
          )}

          <div
            className={`${
              options.length === 0 || options.length < 3
                ? Styles.boxListDropdownNone
                : Styles.boxListDropdown
            }`}
          >
            {options?.map((option, id) => {
              return (
                <Fragment key={id}>
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          setValue(option.name);
                          setValueCity(option?.City?.name || "");
                          setValueCityCode(option?.City?.code || "");
                          setValueId(option?.id || "");
                          setIsActive(false);
                        } else {
                          setValue(option.name);
                          setValueCity(option?.City?.name || "");
                          setValueCityCode(option?.City?.code || "");
                          setValueId(option?.id || "-");
                        }
                      }}
                    >
                      <div className={Styles.nameoOptionBox}>
                        <span>{option?.name}</span>
                        {option?.City ? (
                          <span>
                            {option?.City?.name === "-"
                              ? ""
                              : option?.City?.name}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* {id === options.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )} */}
                  </div>
                </Fragment>
              );
            })}

            {options?.length === 0 ? (
              <div className={Styles.option}>no option</div>
            ) : null}
          </div>

          {/* <div className={Styles.otherChoose} onClick={openModal}>
            <span className={Styles.otherTitle}>Lainnya •</span>
            <span className={Styles.desc}>Buat posisi sesuai kebutuhan</span>
          </div> */}
        </div>
      ) : null}
    </div>
  );
};

export default DropdownJobPosting;
