/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import { rupiahFormat } from "@Helpers/formatRupiah";
import { timeAgo } from "@Helpers/timeAgo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { updateStatus } from "@Services/officer/jobPosting";
import { makeRandomString } from "@Helpers/makeRandomString";
import NotifJobPosting from "@Molecule/_modal/NotifJobPosting";
import useQuery from "@Hooks/useQuery";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import ModalWrapper from "@Atom/ModalWrapper";
import JobPostingLinkModal from "@Molecule/_modal/JobPostingLinkModal";
import Images from "@Theme/Images";
import { fileBaseUrl } from "@Config/api";

export default function JobPostingCard({
  data,
  showAction,
  onShow,
  setShowAction,
  refetchData,
  setTrigger,
}) {
  const maxLength = 20;
  const maxLengthAddress = 28;

  const [hover, setHover] = useState(null);

  const startIndex = 0;
  const endIndex = 1;

  const [showNotif, setShowNotif] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [statusNotif, setStatusNotif] = useState("");
  const [isloadingUpdate, setIsloadingUpdate] = useState(false);
  const [showModalLink, setShowModalLink] = useState(null);

  const navigate = useNavigate();

  const onUpdateStatus = async (e) => {
    e.preventDefault();
    try {
      setShowAction("");
      setIsloadingUpdate(true);
      await updateStatus(showAction, {
        status: statusNotif,
      });
      setIsSent(true);
      setIsloadingUpdate(false);
      setTrigger(makeRandomString(5));
      setStatusNotif("");
      setTimeout(() => {
        setIsSent(false);
      }, 2000);
      // refetchData();
    } catch (error) {
      console.log(error);
      setIsloadingUpdate(false);
    }
  };

  function formatNumber(num) {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(2).replace(/\.?0+$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(2).replace(/\.?0+$/, "") + "M";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2).replace(/\.?0+$/, "") + "jt";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2).replace(/\.?0+$/, "") + "rb";
    } else {
      return num.toString();
    }
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function changeFormatPlacement(str) {
    return str.replaceAll("::", ", ");
  }

  const slicedItems = useMemo(() => {
    if (data?.placement?.length > 1) {
      return data?.placement?.slice(endIndex);
    }
  }, [data?.placement]);

  const selectedItems = useMemo(() => {
    if (data?.placement?.length > 0) {
      return data?.placement?.slice(startIndex, endIndex);
    }
    return [];
  }, [data?.placement]);

  console.log(hover);

  // Draft
  return (
    <div className={Styles.containerJobPost}>
      <div
        className={`${Styles.header} ${
          !data?.type &&
          data?.recruiterType?.toLowerCase() === "dedicated" &&
          data?.medias?.length === 0 &&
          Styles.lightBlue
        } ${
          !data?.type &&
          data?.recruiterType?.toLowerCase() === "normal" &&
          Styles.grey
        } ${
          !data?.type &&
          data?.recruiterType?.toLowerCase() === "dedicated" &&
          data?.medias?.length > 0 &&
          Styles.darkBlue
        }`}
      >
        {data?.status?.toLowerCase() === "expired" ? (
          <div className={Styles.expiredFlag}>
            <span>Expired</span>
          </div>
        ) : null}
        <div className={Styles.left} onClick={() => setShowAction("")}>
          <div>
            <Tooltip title={<span>{data?.title || "-"}</span>}>
              <span>{data?.title || "-"}</span>
            </Tooltip>
            {data?.status?.toLowerCase() === "expired" ? (
              <Tooltip
                title={"Job Posting ini tidak dapat menerima kandidat baru"}
              >
                <div>
                  <Icon
                    icon={"alert-solid"}
                    size={20}
                    color={!data?.type ? "#FFFFFF" : "#e5dd1c"}
                  />
                </div>
              </Tooltip>
            ) : null}
          </div>
          <span>{data?.experience || "-"}</span>
        </div>

        {data?.status?.toLowerCase() === "expired" ? null : (
          <div
            className={Styles.right}
            onClick={() => {
              if (showAction === data._id) {
                setShowAction("");
              } else {
                setShowAction(data._id);
              }
            }}
          >
            <Icon
              icon={"three-dots"}
              size={20}
              color={
                !data?.type && data?.recruiterType !== null ? "#fff" : "#000"
              }
            />
          </div>
        )}

        {showAction === data?._id && (
          <div className={Styles.dropdownBox}>
            {!data?.type ? (
              <span
                onClick={() => {
                  setShowModalLink({
                    name: "Peralihan",
                    data: data,
                  });
                }}
              >
                Salin Link Job Posting <span>Peralihan</span>
              </span>
            ) : data?.type?.toLowerCase() === "normal" &&
              data?.status?.toLowerCase() === "draft" ? (
              <div
                className={Styles.box}
                onClick={() => {
                  setStatusNotif("Publish");
                }}
              >
                <Icon icon={"rocket"} size={20} color={"#1571DE"} />
                <span>Publish</span>
              </div>
            ) : data?.type?.toLowerCase() === "normal" &&
              data?.status?.toLowerCase() === "publish" ? (
              <div
                className={Styles.box}
                onClick={() => {
                  setStatusNotif("Draft");
                }}
              >
                <Icon icon={"save-to-draft"} size={20} color={"#E5DD1C"} />
                <span className={Styles.unPublish}>Unpublish</span>
              </div>
            ) : data?.type?.toLowerCase() === "peralihan" &&
              data?.status?.toLowerCase() === "active" ? (
              <div
                className={Styles.box}
                onClick={() => {
                  setStatusNotif("Inactive");
                }}
              >
                <Icon icon={"non-active"} size={20} color={"#FF3E13"} />
                <span className={Styles.nonActive}>Non Aktifkan</span>
              </div>
            ) : (data?.type?.toLowerCase() === "peralihan" &&
                data?.status?.toLowerCase() === "inactive") ||
              (data?.type?.toLowerCase() === "peralihan" &&
                data?.status?.toLowerCase() === "draft") ? (
              <div
                className={Styles.box}
                onClick={() => {
                  setStatusNotif("Active");
                }}
              >
                <Icon icon={"active"} size={20} color={"#96AB12"} />
                <span className={Styles.active}>Aktifkan</span>
              </div>
            ) : null}
            {!data?.type ? (
              <span
                onClick={() => {
                  if (
                    data?.medias?.length > 0 &&
                    data?.status?.toLowerCase() === "publish"
                  ) {
                    setShowModalLink({
                      name: "Normal",
                      data: data,
                    });
                  }
                }}
                className={`${
                  data?.medias?.length === 0 ||
                  data?.status?.toLowerCase() === "draft"
                    ? Styles.disabled
                    : ""
                }`}
              >
                Salin Link Job Posting <span>Reguler</span>
              </span>
            ) : data?.status?.toLowerCase() === "publish" ||
              data?.status?.toLowerCase() === "active" ? (
              <div
                className={Styles.box}
                onClick={() => {
                  if (data?.type?.toLowerCase() === "normal") {
                    setShowModalLink({
                      name: "Normal",
                      data: data,
                    });
                  } else if (data?.type?.toLowerCase() === "peralihan") {
                    setShowModalLink({
                      name: "Peralihan",
                      data: data,
                    });
                  }
                }}
              >
                <Icon icon={"link"} size={20} color={"#0A0A0A"} />
                <span className={Styles.copyLink}>Copy link</span>
              </div>
            ) : null}
            {!data?.type &&
            data?.recruiterType?.toLowerCase() === "dedicated" ? (
              data?.medias?.length > 0 ? (
                data?.status?.toLowerCase() === "publish" ? (
                  <span
                    className={Styles.red}
                    onClick={() => {
                      setStatusNotif("Draft");
                    }}
                  >
                    Unpublish
                  </span>
                ) : (
                  <span
                    className={Styles.blue}
                    onClick={() => {
                      setStatusNotif("Publish");
                    }}
                  >
                    Publish
                  </span>
                )
              ) : null
            ) : null}
          </div>
        )}
      </div>

      <div className={Styles.content} onClick={() => setShowAction("")}>
        <Tooltip
          title={
            <div className={Styles.tooltipClient}>
              <div>
                <span>Klien</span>
                <span>{data?.clientName?.toUpperCase() || "-"}</span>
              </div>
              <div>
                <span>No. Doc</span>
                <span>{data?.flb || "-"}</span>
              </div>
              <div>
                <span>No. FLB</span>
                <span>{data?.schemeID || "-"}</span>
              </div>
              <div>
                <span>Dedicated</span>
                <span>{data?.dedicatedRO?.name || "-"}</span>
              </div>
            </div>
          }
        >
          <div>
            <div>
              <span>Klien</span>
              <span>
                {data?.clientName
                  ? data?.clientName.length > maxLength
                    ? data?.clientName.substring(0, maxLength)?.toUpperCase() +
                      "..."
                    : data?.clientName?.toUpperCase()
                  : "-"}
              </span>
            </div>

            <div>
              <span>No. Doc</span>
              <span>
                {data?.flb
                  ? data?.flb?.length > maxLength
                    ? data?.flb?.substring(0, maxLength) + "..."
                    : data?.flb
                  : "-"}
              </span>
            </div>

            <div>
              <span>No. FLB</span>
              <span>
                {data?.schemeID
                  ? data?.schemeID?.length > maxLength
                    ? data?.schemeID?.substring(0, maxLength) + "..."
                    : data?.schemeID
                  : "-"}
              </span>
            </div>

            <div>
              <span>Dedicated</span>
              {data?.dedicatedRO?.phoneNumber ? (
                <div className={Styles.dedicatedbox}>
                  <div className={Styles.images}>
                    <img
                      src={
                        data?.dedicatedRO?.profilePicture
                          ? fileBaseUrl + data?.dedicatedRO?.profilePicture
                          : Images.AVA_DEFAULT
                      }
                      alt=""
                    />
                  </div>

                  <div
                    className={Styles.dedicated}
                    onMouseEnter={() => setHover(data?._id)}
                    onMouseLeave={() => setHover("")}
                  >
                    <span
                      className={Styles.textUp}
                      onClick={() => {
                        if (data?.dedicatedRO?.phoneNumber) {
                          window.open(
                            `https://wa.me/${data?.dedicatedRO?.phoneNumber}`
                          );
                        }
                      }}
                    >
                      Hubungi Wa{" "}
                      <Icon icon={"whatsapp"} size={16} color={"#1571DE"} />
                    </span>
                    <span className={Styles.hover} is-hovered={String(!!hover)}>
                      {data?.dedicatedRO?.name || "-"}
                    </span>
                  </div>
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        </Tooltip>

        <div>
          <div
            className={`${
              data?.status?.toLowerCase() === "draft" && Styles.yellow
            } ${
              data?.status?.toLowerCase() === "publish" ||
              data?.status?.toLowerCase() === "active"
                ? Styles.green
                : ""
            } ${data?.status?.toLowerCase() === "inactive" && Styles.grey} ${
              data?.type &&
              data?.type?.toLowerCase() === "peralihan" &&
              data?.status?.toLowerCase() === "draft" &&
              Styles.grey
            } ${data?.status?.toLowerCase() === "expired" && Styles.red}`}
          >
            <div className={Styles.dots}></div>
            <span>
              {data?.status
                ? data?.type &&
                  data?.type?.toLowerCase() === "peralihan" &&
                  data?.status?.toLowerCase() === "draft"
                  ? "Inactive"
                  : data?.status
                : "-"}
            </span>
          </div>

          <div>
            <Icon icon={"people"} size={16} />
            <span>{data?.amount || "-"}</span>
          </div>
        </div>

        <div>
          <div>
            {data?.workType ? <div>{data?.workType}</div> : null}
            {data?.workMethod ? <div>{data?.workMethod}</div> : null}
            {data?.contractType ? <div>{data?.contractType}</div> : null}
          </div>

          <div>
            <div className={Styles.iconPin}>
              <Icon icon={"pin"} size={16} />
            </div>
            {Array.isArray(data?.placement) ? (
              <Tooltip
                title={
                  <span>
                    {toTitleCase(
                      changeFormatPlacement(
                        data?.placement?.map((item) => item.city).join(", ")
                      )
                    ) || "-"}
                  </span>
                }
              >
                <div className={Styles.placement}>
                  <span>
                    {selectedItems?.length > 0
                      ? selectedItems.map((item) => item.city).join(", ")
                          ?.length > maxLengthAddress
                        ? toTitleCase(
                            changeFormatPlacement(
                              selectedItems.map((item) => item.city).join(", ")
                            )
                          )?.substring(0, maxLengthAddress) + "..."
                        : toTitleCase(
                            changeFormatPlacement(
                              selectedItems.map((item) => item.city).join(", ")
                            )
                          )
                      : "-"}
                  </span>

                  {data?.placement?.length > 1 ? (
                    <span className={Styles.slice}>+{slicedItems?.length}</span>
                  ) : null}
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <span>
                    {toTitleCase(changeFormatPlacement(data?.placement))}
                  </span>
                }
              >
                <span>
                  {" "}
                  {data?.placement
                    ? data?.placement.length > maxLengthAddress
                      ? toTitleCase(
                          changeFormatPlacement(data?.placement)
                        ).substring(0, maxLengthAddress) + "..."
                      : toTitleCase(changeFormatPlacement(data?.placement))
                    : "-"}
                </span>
              </Tooltip>
            )}
          </div>

          <div>
            <div className={Styles.iconMoney}>
              <Icon icon={"money"} size={16} />
            </div>
            <span>
              Rp
              {data?.salary?.split("-")?.map((s, is, arr) => (
                <span key={is}>
                  {formatNumber(
                    +s
                      ?.replace(".", ",")
                      .replace(/\d{3}(?=(\d{3})*,)/g, function (s) {
                        return "." + s;
                      })
                  )}{" "}
                  {arr.length > 1 && is === 0
                    ? " - "
                    : is !== 0
                    ? " /bln"
                    : "/bln"}
                </span>
              ))}
            </span>
          </div>
        </div>

        <div>
          <div
            className={`${
              data?.medias?.length === 0 ||
              data?.status?.toLowerCase() === "draft" ||
              data?.status?.toLowerCase() === "inactive" ||
              data?.status?.toLowerCase() === "active"
                ? Styles.disabled
                : ""
            }`}
            onClick={() => {
              if (
                (data?.medias?.length > 0 &&
                  data?.status?.toLowerCase() === "publish") ||
                (data?.medias?.length > 0 &&
                  data?.status?.toLowerCase() === "expired")
              ) {
                navigate(
                  `/rekrutmen?c=${data?.clientCode}&p=${encodeURIComponent(
                    data?.title
                  )}`
                );
              }
            }}
          >
            Pemenuhan
          </div>
          <div
            className={`${
              !data?.type &&
              data?.recruiterType?.toLowerCase() === "dedicated" &&
              data?.medias?.length === 0 &&
              Styles.darkblue
            } ${
              data?.status?.toLowerCase() === "expired" &&
              !data?.type &&
              data?.recruiterType?.toLowerCase() === "dedicated" &&
              data?.medias?.length === 0 &&
              Styles.blueDisabled
            }`}
            onClick={() => {
              if (
                !data?.type &&
                data?.recruiterType?.toLowerCase() === "dedicated" &&
                data?.medias?.length === 0
              ) {
                if (data?.status?.toLowerCase() !== "expired") {
                  navigate(`/job-posting/new/${data?._id}`);
                }
              } else {
                navigate(`/job-posting/${data?._id}`);
              }
            }}
          >
            {!data?.type &&
            data?.recruiterType?.toLowerCase() === "dedicated" &&
            data?.medias?.length === 0
              ? "Publish"
              : "Detail"}
          </div>
        </div>
      </div>

      <div className={`${Styles.notification} ${showNotif && Styles.show}`}>
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Link Berhasil Disalin
      </div>

      {statusNotif && (
        <NotifJobPosting
          handleCancel={() => setStatusNotif(false)}
          handleDone={() => setStatusNotif(false)}
          labelStatus={
            statusNotif === "Publish"
              ? "Publish"
              : statusNotif === "Active"
              ? "Aktifkan"
              : statusNotif === "Inactive"
              ? "Nonaktifkan"
              : "Draft"
          }
          labelWarning={
            statusNotif === "Publish"
              ? "mempublish"
              : statusNotif === "Active"
              ? "mengaktifkan"
              : statusNotif === "Inactive"
              ? "menonaktifkan"
              : "mendraft"
          }
          isloadingUpdate={isloadingUpdate}
          handleSave={onUpdateStatus}
        />
      )}

      <div className={`${Styles.notification} ${isSent && Styles.show}`}>
        <Icon icon={"check-circle-2"} size={20} color={"#fff"} />
        Status berhasil diubah
      </div>

      {showModalLink && (
        <ModalWrapper show={showModalLink} handleClose={() => {}}>
          <JobPostingLinkModal
            data={showModalLink}
            handleClose={() => setShowModalLink(null)}
            setShowNotif={setShowNotif}
            isRecrutiment
          />
        </ModalWrapper>
      )}
    </div>
  );
}
