import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import Textarea from "@Atom/Textarea";
import { fileBaseUrl } from "@Config/api";
import { minDate } from "@Helpers/minDate";
import UseOutsideClick from "@Hooks/useOutsideClick";
import { sendInvitation } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import AddClientContactModal from "../AddContactModal";
import Styles from "./style.module.scss";

export default function InterviewInvitationModal({
  data,
  setTrigger,
  handleClose = () => {},
  // interviewOptions = [],
  // locationOptions = [],
  // interviewerList = [],
  setIsSuccess = () => {},
  invitationSyncData,
  contacts,
  setContacts = () => {},
  // syncData
  isRecruitment,
  refetchData,
  // setSelectedPage,
  refetchDataOptionsInterview,
  setSuccessModal,
}) {
  const [expandMethod, setExpandMethod] = useState(false);
  const [expandInterviewer, setExpandInterviewer] = useState(false);
  const [expandAddress, setExpandAddress] = useState(false);
  const dateRef = useRef();
  const timeRef = useRef();

  const methodRef = useRef();
  const interviewerRef = useRef();
  const addressRef = useRef();

  const backupAddressRef = useRef();

  const outsideMethodClick = UseOutsideClick(methodRef);
  const outsideInterviewerClick = UseOutsideClick(interviewerRef);
  const outsideAddressClick = UseOutsideClick(addressRef);

  const [interviewerSearch, setInterviewerSearch] = useState("");

  const [buildingName, setBuildingName] = useState("");
  const [buildingAddress, setBuildingAddress] = useState("");

  const [interviewMethod, setInterviewMethod] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewTime, setInterviewTime] = useState("");
  const [remark, setRemark] = useState("");

  const [interviewerTitle, setInterviewerTitle] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const [link, setLink] = useState("");

  const [error, setError] = useState("");

  const interviewOptions = useMemo(() => {
    if (invitationSyncData) {
      return invitationSyncData?.dropdownOptions
        ?.filter((obj) => obj?.type?.toLowerCase() === "interview method")
        ?.map((obj) => obj?.value);
    }

    return [];
  }, [invitationSyncData]);

  const interviewerOptions = useMemo(() => {
    if (contacts?.length) {
      return contacts;
    }
    return [];
  }, [contacts]);

  const interviewerList = useMemo(() => {
    return interviewerOptions?.map((obj) => {
      return {
        ...obj,
        profilePicture: obj?.profilePicture
          ? obj?.profilePicture?.includes("http")
            ? obj?.profilePicture?.replace(
                "https://myworkspace",
                "https://api1.myworkspace"
              )
            : fileBaseUrl + obj?.profilePicture
          : null,
      };
    });
  }, [interviewerOptions]);

  // eslint-disable-next-line no-unused-vars
  const [showAddNew, setShowAddNew] = useState();

  const interviewerProfilePicture = useMemo(() => {
    const picture = interviewerList?.find(
      (el) => el?.name === interviewerSearch
    )?.profilePicture;
    if (picture) {
      if (picture?.toLowerCase()?.includes("http")) {
        return picture?.replace(
          "https://myworkspace",
          "https://api1.myworkspace"
        );
      } else {
        return fileBaseUrl + picture;
      }
    } else {
      return null;
    }
  }, [interviewerList, interviewerSearch]);

  useEffect(() => {
    if (!expandInterviewer) {
      if (
        !interviewerList?.find(
          (el) => el?.name?.toLowerCase() === interviewerSearch?.toLowerCase()
        )
      ) {
        setInterviewerSearch("");
      }
    }
  }, [expandInterviewer, interviewerList, interviewerSearch]);

  useEffect(() => {
    if (expandMethod && outsideMethodClick) {
      setExpandMethod(false);
    }
  }, [expandMethod, outsideMethodClick]);

  useEffect(() => {
    if (expandInterviewer && outsideInterviewerClick) {
      setExpandInterviewer(false);
    }
  }, [expandInterviewer, outsideInterviewerClick]);

  useEffect(() => {
    if (expandAddress && outsideAddressClick) {
      setExpandAddress(false);
    }
  }, [expandAddress, outsideAddressClick]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const ableSubmit = useMemo(() => {
    if (
      interviewMethod &&
      interviewDate &&
      interviewTime &&
      interviewerSearch
    ) {
      if (interviewMethod?.toLowerCase() === "online") {
        if (link) {
          return true;
        } else {
          return false;
        }
      } else {
        if (buildingName && buildingAddress) {
          return true;
        } else {
          return false;
        }
      }
    }

    return false;
  }, [
    interviewMethod,
    interviewDate,
    interviewTime,
    interviewerSearch,
    link,
    buildingName,
    buildingAddress,
  ]);

  const filteredInterviewer = useMemo(() => {
    return interviewerList?.filter((el) =>
      el?.name?.toLowerCase()?.includes(interviewerSearch?.toLowerCase())
    );
  }, [interviewerList, interviewerSearch]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitting(true);
      await sendInvitation({
        candidateID: isRecruitment ? data?.candidateId : data?.id,
        method: interviewMethod,
        date: moment(new Date(interviewDate)).format("YYYY-MM-DD"),
        recruiterID: invitationSyncData?.recruitmentOfficer?.employeeId,
        via:
          interviewMethod?.toLowerCase() === "offline"
            ? `${buildingName}::${buildingAddress}`
            : link,
        addressType: "CUSTOM",
        time: interviewTime?.replace(":", "."),
        notes: remark,
        ordinal: 2,
        interviewerName: interviewerSearch,
        interviewerTitle: interviewerTitle,
      });
      if (isRecruitment) {
        setSubmitting(false);
        refetchData();
        // setTrigger(Math.random());
        // setIsSuccess(true);
        handleClose();
        // setSelectedPage(1);
        setSuccessModal({
          name: "CLIENT",
        });
      } else {
        setSubmitting(false);
        setTrigger(Math.random());
        setIsSuccess(true);
        handleClose();
      }
    } catch (err) {
      setSubmitting(false);
      const errorText = err?.response?.data?.error;
      if (errorText?.toLowerCase()?.includes("fill all field")) {
        setError("Check inputted data and make sure to fill all the fields");
      } else {
        setError(errorText);
      }
    }
  };
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  // console.log(data);

  // const {width} = useWindowSize()

  if (showAddNew) {
    return (
      <AddClientContactModal
        setContacts={setContacts}
        setShowAdd={setShowAddNew}
        isRecruitment={true}
        data={data?.clientCode}
        refetchDataOptionsInterview={refetchDataOptionsInterview}
      />
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Undangan Interview Klien</h3>
        {/* {width > 768 
        && */}
        <button onClick={handleClose}>
          <Icon icon={"cross"} size={24} />
        </button>
        {/* } */}
      </div>
      <div className={Styles.content}>
        <div className={Styles.intro}>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_USER} alt="name" />
            </div>
            <div>
              <span>Candidate</span>
              <span>{toTitleCase(data?.name)}</span>
            </div>
          </div>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_JOB} alt="name" />
            </div>
            <div>
              <span>Job</span>
              <span>
                {isRecruitment
                  ? data?.position || "-"
                  : data?.ExpectedPosition?.name || "-"}
              </span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={`${Styles.section} ${Styles.half}`}>
            <div className={Styles.boxForm}>
              <label>Method</label>
              <div
                ref={methodRef}
                onClick={() => setExpandMethod(!expandMethod)}
                className={`${Styles.selectWrapper} ${
                  expandMethod ? Styles.active : ""
                }`}
              >
                <span className={`${interviewMethod ? Styles.filled : ""}`}>
                  {interviewMethod
                    ? interviewMethod === "Offline"
                      ? "Offline Interview (office)"
                      : "Online Interview"
                    : "Interview method"}
                </span>
                <button
                  onClick={() => setExpandMethod(!expandMethod)}
                  type="button"
                  className={Styles.expandButton}
                >
                  <Icon
                    icon={expandMethod ? "arrow-up" : "arrow-down"}
                    size={20}
                  />
                </button>
                {expandMethod && (
                  <div className={Styles.optionsWrapper}>
                    {interviewOptions?.map((option, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setInterviewMethod(option);
                          setExpandMethod(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                    {!interviewOptions?.length && <div>no option</div>}
                  </div>
                )}
              </div>
            </div>
            <div className={Styles.boxForm}>
              <label>Date & Time</label>
              <div className={Styles.stacked}>
                <div className={Styles.inputWrapper}>
                  {/* {!interviewDate && <span className={Styles.placeholderReplacement}>Date</span>} */}
                  <input
                    ref={dateRef}
                    className={`${
                      dateRef?.current?.value ? "has-value" : "no-value"
                    } ${"with-min"}`}
                    min={minDate(new Date())}
                    value={interviewDate}
                    onChange={(e) => setInterviewDate(e.target.value)}
                    type="date"
                    placeholder="Date"
                  />

                  <button
                    onClick={() => dateRef.current.showPicker()}
                    type="button"
                    className={Styles.endAdornment}
                  >
                    <Icon icon="calendar" size={20} />
                  </button>
                </div>
                <div className={Styles.inputWrapper}>
                  <input
                    className={`${!interviewTime ? Styles.empty : ""}`}
                    ref={timeRef}
                    type="time"
                    value={interviewTime}
                    onChange={(e) => setInterviewTime(e.target.value)}
                  />
                  <button
                    onClick={() => timeRef.current.showPicker()}
                    type="button"
                    className={Styles.endAdornment}
                  >
                    <Icon icon="time" size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {interviewMethod && (
            <div
              ref={expandAddress ? backupAddressRef : addressRef}
              className={`${Styles.section} ${Styles.smallerGap} ${Styles.address}`}
            >
              {interviewMethod &&
                (interviewMethod?.toLowerCase()?.includes("offline") ? (
                  <div className={Styles.boxForm}>
                    <label>Address</label>
                    {/* <div ref={expandAddress ? addressRef : backupAddressRef} onClick={() => setExpandAddress(true)} className={`${Styles.selectWrapper} ${expandAddress ? Styles.active : ''}`}>
                      <input
                        value={addressSearch}
                        onChange={(e) => {
                          setExpandAddress(true)
                          setAddressSearch(e?.target?.value)
                        }}
                        placeholder="Select location for interview"
                      />
                      {(expandAddress && addressSearch) && (
                        <button onClick={(e) => {
                          e.stopPropagation()
                          setAddressSearch('')
                        }
                        } type="button" className={Styles.clearButton}>
                          <Icon icon={'cross'} size={15} />
                        </button>
                      )}

                      <button onClick={(e) => {
                        e.stopPropagation()
                        setExpandAddress(!expandAddress)
                      }
                      } type="button" className={Styles.expandButton}>
                        <Icon icon={expandAddress ? 'arrow-up' : 'arrow-down'} size={20} />
                      </button>
                      {
                        expandAddress && (
                          <div className={Styles.optionsWrapper}>
                            {filteredAddress?.length ? filteredAddress?.map((address, i) => (
                              <div key={i} onClick={(e) => {
                                e?.preventDefault()
                                e?.stopPropagation()
                                setExpandAddress(false)
                                setAddressSearch(address)
                              }}>
                                <span>{address}</span>
                              </div>
                            )) : (
                              <div>
                                no option
                              </div>
                            )}
                          </div>
                        )
                      }
                    </div> */}
                  </div>
                ) : (
                  <div className={Styles.boxForm}>
                    <label>Link</label>
                    <div className={Styles.stacked}>
                      <div className={Styles.inputWrapper}>
                        <input
                          type="text"
                          value={link}
                          onChange={(e) => setLink(e.target.value)}
                          placeholder="Paste link here"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {interviewMethod?.toLowerCase()?.includes("offline") && (
                // && addressSearch?.toLowerCase()?.includes('custom')
                <div className={Styles.addressWrapper}>
                  {/* <div className={Styles.divider} /> */}
                  <Input
                    value={buildingName}
                    onChange={(e) => setBuildingName(e?.target?.value)}
                    placeholder={
                      "Nama tempat, contoh : Gedung Permata Indonesia"
                    }
                  />
                  <Textarea
                    value={buildingAddress}
                    onChange={(e) => setBuildingAddress(e?.target?.value)}
                    placeholder={"Alamat"}
                  />
                </div>
              )}
            </div>
          )}

          <div className={`${Styles.section} ${Styles.interviewer}`}>
            <div className={Styles.boxForm}>
              <label>Interviewer</label>
              <Dropdown
                options={[{ name: "Bapak" }, { name: "Ibu" }]}
                value={interviewerTitle}
                setValue={setInterviewerTitle}
                placeholder={"Bapak/Ibu"}
                autoClose
              />
              <div
                ref={interviewerRef}
                onClick={() => setExpandInterviewer(true)}
                className={`${Styles.selectWrapper} ${
                  expandInterviewer ? Styles.active : ""
                }`}
              >
                <img
                  src={interviewerProfilePicture || Images?.AVA_DEFAULT}
                  alt="pp"
                />
                <input
                  value={interviewerSearch}
                  onChange={(e) => {
                    setExpandInterviewer(true);
                    setInterviewerSearch(e?.target?.value);
                  }}
                  placeholder="Nama"
                />
                {expandInterviewer && interviewerSearch && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setInterviewerSearch("");
                    }}
                    type="button"
                    className={Styles.clearButton}
                  >
                    <Icon icon={"cross"} size={15} />
                  </button>
                )}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandInterviewer(!expandInterviewer);
                  }}
                  type="button"
                  className={Styles.expandButton}
                >
                  <Icon
                    icon={expandInterviewer ? "arrow-up" : "arrow-down"}
                    size={20}
                  />
                </button>
                {expandInterviewer && (
                  <div className={Styles.optionsWrapper}>
                    {filteredInterviewer?.length ? (
                      filteredInterviewer?.map((ro, i) => (
                        <div
                          key={i}
                          onClick={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            setExpandInterviewer(false);
                            setInterviewerSearch(ro?.name);
                          }}
                        >
                          <img
                            src={
                              ro?.profilePicture
                                ? ro?.profilePicture
                                    ?.toLowerCase()
                                    ?.includes("http")
                                  ? ro?.profilePicture
                                  : fileBaseUrl + ro?.profilePicture
                                : Images?.AVA_DEFAULT
                            }
                            alt={ro?.name}
                          />
                          <span>{ro?.name}</span>
                          {Math.abs(new Date() - new Date(ro?.createdAt)) /
                            36e5 <=
                            12 && <div className={Styles.newTag}>New</div>}
                        </div>
                      ))
                    ) : (
                      <div>no option</div>
                    )}
                    <div
                      className={Styles.addNew}
                      onClick={() => setShowAddNew(true)}
                    >
                      <span>Lainnya</span>
                      <span> • </span>
                      <span>Tambah Interviewer Baru</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={Styles.section}>
            <div className={Styles.boxForm}>
              <label htmlFor="">Catatan</label>
              <Textarea
                value={remark}
                onChange={(e) => setRemark(e?.target?.value)}
                placeholder={"Masukan catatan anda"}
              />
            </div>
          </div>
          <div className={Styles.section}>
            <div
              className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}
            >
              *{error || "error placeholder"}
            </div>
          </div>

          <button type="submit" disabled={!ableSubmit || error || submitting}>
            {submitting ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
}
