import Styles from './style.module.scss';
import UseOutsideClick from '@Hooks/useOutsideClick';
import { useEffect, useRef } from 'react';
import Images from '@Theme/Images';
import Icon from '@Atom/Icon';

export default function ConfirmContractType({ message=["PKWT", "PKM"], error, handleDone, show, setShow }) {
    const boxRef = useRef();
    const boxOutsideClick = UseOutsideClick(boxRef);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.removeProperty('overflow');
        }
    }, [show]);

    useEffect(() => {
        if (boxOutsideClick) {
            setShow(false);
        }
    }, [boxOutsideClick, setShow]);

    return (
        <div className={Styles.background}>
            <div className={Styles.container} ref={boxRef}>
                <div className={Styles.titleWrapper}>
                    <span className={Styles.title}>{"Ubah Jenis Kontrak"}</span>
                    <button className={Styles.buttonBack} onClick={() => setShow(false)}>
                        <Icon icon={'cross'} color={'#000000'} size={'24px'} />
                    </button>
                </div>

                <img src={Images.CONCEPT_OF_UNKNOWN_THINGS} alt="concept_of_unknown_things" />
                <p>Kontrak yang tertera pada job posting merupakan kontrak <span><strong>{message[0]}</strong></span>, Apakah kamu yakin ingin mengubah jenis kontrak menjadi <span><strong>{message[1]}</strong></span>?</p>
                <p className={Styles.error}>{error}</p>
                <div className={Styles.buttonWrapper}>
                    <button className={Styles.buttonDecline} onClick={() => setShow(false)}>
                        {'Batal'}
                    </button>
                    <button className={Styles.buttonAccept} onClick={handleDone}>
                        {'Ya'}
                    </button>
                </div>
            </div>
        </div>
    );
}
