import Icon from '@Atom/Icon'
import LoadingAnimation from '@Atom/LoadingAnimation'
import ModalWrapper from '@Atom/ModalWrapper'
import { getFLBLogs } from '@Services/manager/bm'
import Images from '@Theme/Images'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import Styles from './style.module.scss'

export default function FLBHistoryModal({ selectedFLB, onClose = () => { }, isEditScheme
  = false }) {
  const [onFirstLoad, setOnFirstLoad] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [logs, setLogs] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  console.log(selectedFLB, 'tes')

  const fetchLogs = useCallback(async (isMore = false) => {
    try {
      if (isMore) {
        setIsLoadingMore(true)
        // setPage(+page + 1)
      }
      const { response } = await getFLBLogs(selectedFLB?.schemeID, isMore ? (+page || 1) + 1 : page, 5, 'Pending')
      if (isMore) {
        setPage(page + 1)
      }
      setTotalPage(response?.totalPage)
      setLogs(isMore ? [...logs, ...response?.logs] : response?.logs)
      setIsLoadingMore(false)
      setOnFirstLoad(false)
      console.log(response, 'ini response')
    } catch (err) {
      setIsLoadingMore(false)
      setOnFirstLoad(false)
      console.log(err, 'err on fetching')
    }
  }, [logs, page, selectedFLB?.schemeID])

  useEffect(() => {
    if (onFirstLoad)
      fetchLogs()
  }, [fetchLogs, onFirstLoad])


  return (
    <ModalWrapper show={selectedFLB} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img src={Images.LOG_ICON} alt='' />
          <div className={Styles.middle}>
            <div className={Styles.mTop}>
              <h3>Riwayat Perubahan</h3>
              <button onClick={onClose}>
                <Icon icon={'cross'} size={24} />
              </button>
            </div>
            <div className={Styles.mBottom}>
              <p>Document Number : <span>{selectedFLB?.docNumber}</span></p>
              {
                !isEditScheme

                &&
                <div className={`${Styles.status} ${selectedFLB?.isExpired ? Styles.expired : (selectedFLB?.isVerified ? Styles.verified : Styles.waiting)}`}>
                  {
                    selectedFLB?.isExpired ? 'Expired' : (
                      selectedFLB?.isVerified
                        ?
                        'Active'
                        :
                        'Waiting'
                    )
                  }
                </div>
              }

            </div>
          </div>
        </div>
        <div className={Styles.content}>
          {
            onFirstLoad
            &&
            <div className={Styles.firstLoadLoading}>
              <LoadingAnimation />
            </div>

          }
          {
            !onFirstLoad && !logs?.length
            &&
            <div className={Styles.empty}>
              <img src={Images.LOG_ICON} alt='' />
              <span>Log Kosong</span>
              <span>Belum ada log yang tercatat</span>
            </div>

          }
          {
            !!logs?.length
            &&
            <div className={Styles.logs}>
              {logs?.map((each, i) => (
                <div key={i} className={Styles.each}>
                  <div className={Styles.overflowHider} />
                  <div className={Styles.bullet} />
                  <div className={Styles.main}>
                    <div>
                      {/* <span>{`${each?.prevValue && each?.prevValue !== 'null' ? 'Perubahan' : 'Penambahan'} ${each?.field?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}`}</span> */}
                      <span>{each?.remark}</span>
                      {
                        !isEditScheme
                        &&
                        <p>{moment(new Date(each?.updatedAt))?.format('ll, HH:mm')}</p>
                      }
                    </div>
                    <div>
                      {
                        each?.remark?.toLowerCase()?.includes('perubahan')
                        &&
                        <div className={Styles.changing}>
                          <p>
                            <span>Before</span>
                            <span>:</span>
                            <span>{each?.prevValue || '-'}</span>
                          </p>
                          <p>
                            <span>After</span>
                            <span>:</span>
                            <span>{each?.updateValue || '-'}</span>
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
          {totalPage > page && !isLoadingMore
            &&
            <div className={Styles.seeMoreWrapper}>
              <button onClick={() => fetchLogs(true)}>
                <span>Click to See more</span>
                <Icon icon={'arrow-down'} size={20} />
              </button>
            </div>
          }

          {
            isLoadingMore
            &&
            <div className={Styles.isLoadingMore}>
              <span>Loading...</span>
            </div>
          }
        </div>
      </div>

    </ModalWrapper>
  )
}