import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";
import { useEffect, useMemo } from "react";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import { validateEmail } from "@Helpers/validateEmail";

const dataUnit = [
  {
    id: 1,
    name: "Payroll",
    code: "Payroll",
  },
  {
    id: 2,
    name: "Invoice",
    code: "Invoice",
  },
  {
    id: 3,
    name: "Lainnya",
    code: "Lainnya",
  },
];

export default function ContactSection({
  contacts,
  onChange,
  onAdd,
  alert,
  type,
  existingType,
  client,
  setContacts,
  validate,
}) {
  useEffect(() => {
    sessionStorage.setItem("clientContacts", JSON.stringify(contacts));
  }, [contacts]);

  const viewOnlyTemplate = useMemo(() => {
    return {
      left: [
        {
          title: "Nama",
          name: "name",
        },
        {
          title: "Nomor Telepon",
          name: "phoneNumber",
        },
        {
          title: "Unit kerja",
          name: "workUnit",
        },
      ],
      right: [
        {
          title: "Posisi",
          name: "jobLevel",
        },
        {
          title: "Email",
          name: "email",
        },
      ],
    };
  }, []);

  const handleChecked = (contact, item) => {
    return contact?.selectedUnit?.includes(item.name);
  };

  const handleCheckboxChange = (index, contact, item) => {
    const isSelected = handleChecked(contact, item);

    setContacts((prevContacts) =>
      prevContacts.map((el, idx) => {
        if (idx === index) {
          let updatedSelectedUnit = [];
          let workUnit = "";
          if (item.name === "Lainnya") {
            updatedSelectedUnit = isSelected
              ? el.selectedUnit.filter((selected) => selected !== item.name)
              : [item.name];
          } else {
            workUnit = "";
            updatedSelectedUnit = isSelected
              ? el.selectedUnit.filter((selected) => selected !== item.name)
              : [
                  ...new Set([
                    ...el.selectedUnit.filter(
                      (selected) => selected !== "Lainnya"
                    ),
                    item.name,
                  ]),
                ];
          }
          return {
            ...el,
            selectedUnit: updatedSelectedUnit,
            workUnit: workUnit,
          };
        }
        return el;
      })
    );
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"phone"} size={20} />
      </div>
      <div className={Styles.header}>
        <h3>Informasi Kontak Perusahaan</h3>
        <span className={alert ? Styles.alert : Styles.normal}>
          Harap lengkapi informasi kontak
        </span>
      </div>
      <div
        className={`${Styles.content} ${
          !contacts?.length ? Styles.hidden : ""
        }`}
      >
        {contacts?.map((each, i) => {
          return !each?.viewOnly ? (
            <div key={i} className={Styles.card}>
              <div className={Styles.cardHeader}>
                <h4>Informasi Kontak {i + 1}</h4>
                {i !== 0 && (
                  <button
                    className={Styles.deleteButton}
                    onClick={() =>
                      setContacts((prev) => {
                        return prev?.filter((obj) => obj !== each);
                      })
                    }
                  >
                    <Icon icon={"trash"} size={24} color={"#FF3E13"} />
                  </button>
                )}
              </div>
              <div className={Styles.cardContent}>
                <div className={Styles.divider}>
                  <InputField
                    isError={validate?.[`name_${i}`] === true && !each?.name}
                    value={each?.name?.toUpperCase() || ""}
                    // disabled={type === 'EXISTING'}
                    disabled={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                    onChange={(e) => onChange(e, i, "name")}
                    title={
                      <p>
                        Nama<span className={Styles.asterisk}>&#x2a;</span>
                      </p>
                    }
                    placeholder="Nama"
                  />
                  <InputField
                    isError={
                      validate?.[`jobLevel_${i}`] === true && !each?.jobLevel
                    }
                    disabled={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                    value={each?.jobLevel?.toUpperCase() || ""}
                    // disabled={type === 'EXISTING'}
                    onChange={(e) => onChange(e, i, "jobLevel")}
                    title={
                      <p>
                        Posisi<span className={Styles.asterisk}>&#x2a;</span>
                      </p>
                    }
                    placeholder="Posisi pekerjaan"
                  />
                </div>
                <div className={Styles.divider}>
                  <InputField
                    isError={
                      validate?.[`email_${i}`] === true &&
                      (!each?.email || !validateEmail(each?.email))
                    }
                    value={each?.email?.toLowerCase() || ""}
                    // disabled={type === 'EXISTING'}
                    onChange={(e) => onChange(e, i, "email")}
                    title={
                      <p>
                        Email<span className={Styles.asterisk}>&#x2a;</span>
                      </p>
                    }
                    disabled={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                    placeholder="example@mail.com"
                    errorText={
                      !each?.email
                        ? "Please fill the field"
                        : "Email is not in the correct format"
                    }
                  />
                  <InputField
                    isError={
                      (validate?.[`phoneNumber_${i}`] === true &&
                        !each?.phoneNumber) ||
                      (validate?.[`phoneNumber_${i}`] === true &&
                        each?.phoneNumber)
                    }
                    errorText={
                      validate?.[`phoneNumber_${i}`] === true &&
                      each?.phoneNumber
                        ? "Invalid phone number"
                        : "Please fill the field"
                    }
                    value={each?.phoneNumber || ""}
                    // disabled={type === 'EXISTING'}
                    onChange={(e) => onChange(e, i, "phoneNumber")}
                    type="phone"
                    title={
                      <p>
                        Nomor Telepon
                        <span className={Styles.asterisk}>&#x2a;</span>
                      </p>
                    }
                    placeholder="8 ***"
                    disabled={
                      type === "EXISTING" && existingType !== "mws" && !client
                    }
                  />
                </div>
                <div className={Styles.unitBox}>
                  <div className={Styles.dividerUnit}>
                    <span className={Styles.text}>
                      Unit Kerja <span className={Styles.asterisk}>&#x2a;</span>
                    </span>
                    <div>
                      {dataUnit?.map((el, idx) => {
                        return (
                          <div
                            className={`${Styles.list} ${
                              each?.selectedUnit?.find(
                                (selected) => selected === el?.name
                              ) && Styles.borderActive
                            } ${
                              validate?.[`selectedUnit_${i}`] === true &&
                              each?.selectedUnit?.length === 0 &&
                              Styles.borderList
                            } ${
                              type === "EXISTING" &&
                              existingType !== "mws" &&
                              !client &&
                              Styles.disabled
                            }`}
                            key={idx}
                          >
                            <CheckboxJobPosting
                              checked={handleChecked(each, el)}
                              onChange={() => {
                                handleCheckboxChange(i, each, el);
                                // setIsActive(false);
                              }}
                              disabled={
                                type === "EXISTING" &&
                                existingType !== "mws" &&
                                !client
                              }
                            />
                            <div>{el?.name}</div>
                          </div>
                        );
                      })}
                    </div>

                    {validate?.[`selectedUnit_${i}`] === true &&
                    each?.selectedUnit?.length === 0 ? (
                      <span>Please fill the field</span>
                    ) : null}
                  </div>
                  <div className={Styles.dividerUnit}>
                    <InputField
                      isError={
                        validate?.[`workUnit_${i}`] === true &&
                        !each?.workUnit &&
                        each?.selectedUnit?.find(
                          (selected) => selected === "Lainnya"
                        ) &&
                        each?.selectedUnit?.length > 0
                      }
                      value={each?.workUnit?.toUpperCase() || ""}
                      // disabled={type === 'EXISTING'}
                      onChange={(e) => onChange(e, i, "workUnit")}
                      title={
                        <p>
                          Definisikan Lainnya{" "}
                          <span className={Styles.descInput}>
                            (isi bila “Lainnya” kamu contreng);
                          </span>
                        </p>
                      }
                      placeholder="Deskripsi"
                      disabled={
                        each?.selectedUnit?.find(
                          (selected) => selected !== "Lainnya"
                        ) || each?.selectedUnit?.length === 0
                      }
                    />

                    <div />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div key={i} className={Styles.viewOnlyCard}>
              <span>Informasi Kontak {i + 1}</span>
              <div className={Styles.viewOnlyContent}>
                <div className={Styles.left}>
                  {viewOnlyTemplate?.left?.map((obj) => (
                    <div key={obj?.title}>
                      <span>{obj?.title}</span>
                      <span>{each[obj?.name] || "-"}</span>
                    </div>
                  ))}
                </div>
                <div className={Styles.right}>
                  {viewOnlyTemplate?.right?.map((obj) => (
                    <div key={obj?.title}>
                      <span>{obj?.title}</span>
                      <span>{each[obj?.name] || "-"}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`${Styles.buttonWrapper} ${
          type === "EXISTING" &&
          existingType !== "mws" &&
          !client &&
          Styles.disabled
        }`}
        onClick={
          type === "EXISTING" && existingType !== "mws" && !client
            ? null
            : onAdd
        }
      >
        <Button
          type="secondary-blue-transparent"
          title={
            <span className={Styles.buttonText}>
              <Icon icon="add" size={20} /> Tambah informasi kontak
            </span>
          }
          disabled={type === "EXISTING" && existingType !== "mws" && !client}
        />
      </div>
    </div>
  );
}
