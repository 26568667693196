/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { Box, StyledEngineProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Pagination from '@Molecule/Pagination';
import Icon from '@Atom/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPipelines } from '@Services/manager/pipeline';
import ViewDetailModal from './ViewDetailModal';
import UseOutsideClick from '@Hooks/useOutsideClick';

const TABLE_HEAD = [
  { id: "name", label: "Client" },
  { id: "province", label: "Location" },
  { id: "email", label: "Email" },
  { id: "phone", numeric: true, label: "Phone" },
  { id: "action", label: "Action" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function PipelineData({ defaultData, searchTerm, defaultTotalPage }) {
  const [data, setData] = useState(defaultData)
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [value, setValue] = useState(10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(defaultTotalPage || 1)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const renewData = useCallback(async () => {
    const query = `limit=${value}&page=${selectedPage}&search=${searchTerm || ''}`
    const { response } = await getPipelines(query)

    setData(response?.data)
    setTotalPage(response?.totalPage)
  }, [value, selectedPage, searchTerm])

  useEffect(() => {
    renewData()
  }, [renewData])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleSeeDetails = (each) => {
    const orders = each?.orders
    let orderIds = []
    for (let i = 0; i < orders.length; i++) {
      orderIds.push(orders[i]?.id)
    }
    const stringIds = orderIds?.join('-')
    const detailsQuery = `${pathname}/order?n=${each?.name}&c=${each?.code}&o=${stringIds}`
    navigate(detailsQuery)
  }

  const [selectedClient, setSelectedClient] = useState(null)

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedClient !== null) {
      if (boxOutsideClick === false) {
        setSelectedClient(null);
      }
    }
  }, [boxOutsideClick, selectedClient]);

  const handleSeeMore = (each) => {
    setSelectedClient(each)
  }

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer className={Styles.tableContainer}>
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((each, i) => (
                <TableCell
                  key={i}
                  className={`${Styles.tableHeadCell} ${i === 0 ? Styles.firstHeadCell : Styles.hideOnMobile
                    }`}
                  padding="none"
                  align={each?.align || "left"}
                  sortDirection={orderBy === each.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === each.id}
                    direction={orderBy === each.id ? order : "asc"}
                    onClick={createSortHandler(each.id)}
                  >
                    <span>{each.label}</span>
                    {orderBy === each.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}

              <TableCell
                padding={"none"}
                className={`${Styles.showOnMobile} ${Styles.tableHeadCell}`}
                align="right"
              >
                <span>Information</span>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {stableSort(data, getComparator(order, orderBy))?.map((each, i) => {
              return (
                <TableRow
                  key={i}
                  className={`${i % 2 === 1 ? Styles.evenRow : Styles.oddRow} ${Styles.tableRow
                    }`}
                >
                  <TableCell
                    padding="none"
                    className={`${Styles.tableCell} ${Styles.firstCell}`}
                  >
                    <div className={Styles.stacked}>
                      <div>
                        <Icon icon="company" size={22} />
                      </div>
                      <span>{each?.name}</span>
                    </div>
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{each?.province}</span>
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{each?.email}</span>
                  </TableCell>
                  <TableCell
                    padding="none"
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{each?.phoneNumber}</span>
                  </TableCell>
                  <TableCell
                    padding='none'
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <button
                      onClick={() => handleSeeDetails(each)
                      }
                    >See detail</button>
                  </TableCell>
                  <TableCell
                    padding='none'
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button
                      onClick={() => handleSeeMore(each)
                      }
                    >See More</button>
                  </TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={Styles.paginationContainer}>
        <Pagination
          total={data?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      <div
        className={`${Styles.modalWrapper} ${selectedClient ? Styles.showModal : Styles.hideModal
          }`}
      >
        <ViewDetailModal data={selectedClient} setData={setSelectedClient} />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${selectedClient ? Styles.visible : ""}`}
      />
    </div>
  );
}
