import AutocompleteV2 from "@Atom/AutocompleteV2";
import Icon from "@Atom/Icon";
import { isValidEmail } from "@Helpers/isValidEmail";
import UseOutsideClick from "@Hooks/useOutsideClick";
import InputField, { InputTitle } from "@Molecule/InputField";
import FileUploadModal from "@Molecule/_modal/FileUpload";
import { addCandidate } from "@Services/officer/recruitment";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function AddMode({
  syncData,
  setAddMode,
  positions,
  locations,
  educations,
}) {
  const [showUpload, setShowUpload] = useState(false);
  const [showUploadMobile, setShowUploadMobile] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null)

  const [form, setForm] = useState({
    name: "",
    position: "",
    email: "",
    phoneNumber: "",
    domicile: "",
    address: "",
    education: "",
    lastExperience: "",
    cv: null,
  });

  const [cv, setCv] = useState(null);

  const handleChangeForm = (e, name) => {
    const value = e.target.value;

    if (name === "email") {
      setForm((prev) => ({
        ...prev,
        [name]: value?.toLowerCase().trim(),
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: !isNaN(value) ? value : value.toUpperCase(),
      }));
    }
  };
  const handleChangePosition = (newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        // position: newVal?.toUpperCase() || "",
        position: newVal
      };
    });
  };
  const handleChangeDomicile = (newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        domicile: newVal,
      };
    });
  };
  const handleChangeEducation = (newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        education: newVal,
      };
    });
  };
  const handleRemoveCV = () => {
    setCv(null);
    setForm((prev) => {
      return {
        ...prev,
        cv: null,
      };
    });
  };

  useEffect(() => {
    if (cv) {
      setForm((prev) => {
        return {
          ...prev,
          cv: cv,
        };
      });

    }
  }, [cv]);

  const positionsArr = useMemo(() => {
    return syncData?.expectedPositions;
  }, [syncData]);

  const [error, setError] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const disableSubmit = useMemo(() => {
    if (
      !form?.name ||
      !form?.email ||
      !form?.phoneNumber ||
      !form?.domicile ||
      !form?.address ||
      !form?.education ||
      !form?.lastExperience ||
      // !fixedPositionId ||
      // !form?.cv ||
      submitting
    ) {
      return true;
    }
    return false;
  }, [form, submitting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (isValidEmail(form?.email)) {
        setSubmitting(true);

        formData.append("name", form?.name);
        formData.append("positionID", selectedPosition?.positionID);
        formData.append("email", form?.email);
        formData.append("phoneNumber", "62" + form?.phoneNumber);
        formData.append("domicile", form?.domicile);
        formData.append("address", form?.address);
        formData.append("lastEducation", form?.education);
        formData.append("lastPosition", form?.lastExperience);
        formData.append("portal", "Manual");
        formData.append("status", "RO-INVITE");
        if (form?.cv) {
          formData.append("file", form?.cv);
          formData.append("type", "CV");
        }

        await addCandidate(formData);
        setSubmitting(false);
        setAddMode(false);
      } else {
        setError("Email format invalid");
      }
    } catch (err) {
      console.log(err?.response?.data?.error);
      setError(err?.response?.data?.error);
      setSubmitting(false);
    }
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const handleShowUpload = () => {
    if (viewType === "desktop") {
      setShowUpload(true);
    } else {
      setShowUploadMobile(true);
    }
  };

  useEffect(() => {
    if (showUploadMobile) {
      if (boxOutsideClick === false) {
        setShowUploadMobile(false);
      }
    }
  }, [boxOutsideClick, showUploadMobile]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  return (
    <form className={Styles.container} onSubmit={handleSubmit}>
      <div className={`${Styles.header} ${Styles.add}`}>
        <h4>Source & Interview RO</h4>
        <div className={Styles.primaryButton} onClick={() => setAddMode(false)}>
          <span className={Styles.hideOnMobile}>Kembali ke list</span>
          <Icon className={Styles.showOnMobile} icon="arrow-left" size={18} />
          <Icon icon="report" size={18} />
        </div>
      </div>
      <div className={`${Styles.controller} ${Styles.add}`}>
        <div className={Styles.left}>
          <h5>Tambah Kandidat</h5>
        </div>
        <div className={Styles.right}>
          {!form?.cv && (
            <button type="button" onClick={() => handleShowUpload(true)}>
              <span>CV</span>
              {/* <span className={Styles.redStar}>*</span> */}
              <Icon icon="upload" size={18} />
            </button>
          )}
          {form?.cv && (
            <div className={Styles.withCVWrapper}>
              <button type="button" onClick={() => handleShowUpload(true)}>
                <span className={Styles.hideOnMobile}>Change CV</span>
                <Icon icon="refresh" size={18} />
              </button>
              <button
                type="button"
                className={Styles.remove}
                onClick={handleRemoveCV}
              >
                <Icon icon="cross" size={18} />
              </button>
              <span className={Styles.hideOnMobile}>CV Uploaded</span>
              <Icon
                className={Styles.hideOnMobile}
                icon={"check-circle"}
                size={18}
                color="#1571DE"
              />
            </div>
          )}

          <button
            type="submit"
            onClick={handleSubmit}
            disabled={disableSubmit}
            className={`${Styles.filled} ${Styles.hideOnMobile1}`}
          >
            <span>{submitting ? "Menambahkan..." : "Tambah"}</span>
          </button>
        </div>
        {form?.cv && (
          <div className={`${Styles.showOnMobile} ${Styles.UploadedText}`}>
            <span>CV Uploaded</span>
            <Icon icon={"check-circle"} size={18} color="#1571DE" />
          </div>
        )}
      </div>
      <div className={Styles.main}>
        <div className={Styles.inputWrapper}>
          <InputField
            title={"Nama Kandidat"}
            value={form?.name}
            onChange={(e) => handleChangeForm(e, "name")}
            placeholder={"Nama sesuai KTP"}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputTitle
            title={'Posisi Pilihan'}
            required
          />
          <AutocompleteV2
            options={positionsArr?.map(p => {
              return {
                ...p,
                name: p?.position,
                label: p?.jobPost?.placement
              }
            })}
            value={form?.position}
            valueLabel={selectedPosition?.label}
            autoClose={true}
            setValue={handleChangePosition}
            onClickOption={(option) => setSelectedPosition(option)}
            placeholder={"Pilih salah satu"}
            required
            withLabel
          />
          {/* <SingleAutocompleteField
            title={"Posisi Pilihan"}
            options={positionsArr?.map((obj) => {
              return {
                name: `${obj?.position} (${obj?.jobPost?.placement} - ${obj?.positionID})`
              }
            })}
            value={form?.position}
            autoClose={true}
            setValue={handleChangePosition}
            placeholder={"Pilih salah satu"}
            required
          /> */}
        </div>
        <div className={Styles.inputWrapper}>
          <InputField
            title={"Email"}
            value={form?.email}
            onChange={(e) => handleChangeForm(e, "email")}
            placeholder={"example@mail.com"}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputField
            title={"Nomor Handphone"}
            value={form?.phoneNumber}
            onChange={(e) => handleChangeForm(e, "phoneNumber")}
            placeholder={"8xxxxxxxxxx"}
            type={"phone"}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputTitle
            title={'Domisili'}
            required
          />
          <AutocompleteV2
            options={locations?.map((obj) => {
              return {
                name: obj
              }
            })}
            value={form?.domicile}
            setValue={handleChangeDomicile}
            placeholder={"Kota tempat tinggal"}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputField
            title={"Alamat"}
            value={form?.address}
            onChange={(e) => handleChangeForm(e, "address")}
            placeholder={"Alamat utama"}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputTitle
            title={'Pendidikan'}
            required
          />
          <AutocompleteV2
            options={educations?.map((obj) => {
              return {
                name: obj
              }
            })}
            value={form?.education}
            setValue={handleChangeEducation}
            placeholder={"Pilih salah satu"}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.inputWrapper}>
          <InputField
            title={"Pengalaman Terakhir"}
            value={form?.lastExperience}
            onChange={(e) => handleChangeForm(e, "lastExperience")}
            placeholder={"Pengalaman kerja terakhir"}
            required
          />
        </div>
        {error && <div className={Styles.errorWrapper}>{error}</div>}
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={disableSubmit}
          className={`${Styles.filled} ${Styles.showOnMobile}`}
        >
          <span>{submitting ? "Menambahkan..." : "Tambah"}</span>
        </button>
      </div>
      <div className={Styles.footer} />
      {showUpload && (
        <FileUploadModal
          setShow={setShowUpload}
          files={cv}
          setFiles={setCv}
          title={"Upload CV"}
          type="desktop"
        />
      )}

      <div
        className={`${Styles.modalWrapper} ${showUploadMobile ? Styles.showModal : Styles.hideModal
          }`}
      >
        <FileUploadModal
          setShow={setShowUploadMobile}
          files={cv}
          setFiles={setCv}
          title={"Upload CV"}
          type="mobile"
        />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${showUploadMobile ? Styles.visible : ""
          }`}
      />
    </form>
  );
}
