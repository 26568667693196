import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";
import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import { useEffect, useMemo, useState } from "react";
import { makeRandomString } from "@Helpers/makeRandomString";

const bankOptions = [
  {
    name: "BCA",
    number: "1783050505",
  },
  {
    name: "BNI",
    number: "0195900064",
  },
  // {
  //   name: 'BNI CONTIGENCY',
  //   number: '8000177898'
  // },
  {
    name: "BRI",
    number: "037601000660300",
  },
  {
    name: "BTPN",
    number: "4843000059",
  },
  {
    name: "CIMB NIAGA",
    number: "800007679100",
  },
  {
    name: "DANAMON",
    number: "000071968697",
  },
  {
    name: "BANK DBS IND",
    number: "3320129862",
  },
  {
    name: "DKI",
    number: "41208000408",
  },
  {
    name: "MANDIRI",
    number: "1280004585938",
  },
  {
    name: "MANDIRI MCM",
    number: "0700006667153",
  },
  {
    name: "MAYBANK",
    number: "2026165210",
  },
  {
    name: "MEGA",
    number: "010940011002145",
  },
  {
    name: "MNC BANK",
    number: "100010000103026",
  },
  {
    name: "NOBU BANK",
    number: "17830003001",
  },
  {
    name: "PERMATA",
    number: "701583183",
  },
  {
    name: "SAMPOERNA",
    number: "1021555106",
  },
  {
    name: "SINARMAS",
    number: "0045791556",
  },
  {
    name: "OCBC NISP",
    number: "417800003234",
  },
];

export default function AccountSection(props) {
  const { form, setForm, setTrigger } = props;
  const [bankName, setBankName] = useState(form?.recipientsAccount?.bankName);
  const bankRecipient = "PT Permata Indo Sejahtera";

  const bankNumber = useMemo(() => {
    return bankOptions.find((el) => el?.name === bankName)?.number;
  }, [bankName]);

  useEffect(() => {
    const newForm = form;
    newForm.recipientsAccount.bankRecipient = bankRecipient;
    newForm.recipientsAccount.bankName = bankName;
    newForm.recipientsAccount.bankNumber = bankNumber;
    setForm(newForm);
    setTrigger(makeRandomString(5));
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
  }, [form, setForm, bankRecipient, bankName, bankNumber, setTrigger]);

  return (
    <div className={Styles.container}>
      <h3>Rekening Penerima Invoice</h3>

      <div className={Styles.content}>
        <div className={Styles.wrapper}>
          <InputField
            title="Perusahaan Penerima"
            value={bankRecipient}
            disabled={true}
          />
        </div>
        <div className={Styles.wrapper}>
          <SelectOptionsToggle
            title="Bank"
            placeholder="Select Bank"
            options={bankOptions}
            value={bankName}
            setValue={setBankName}
            autoClose={true}
            required
          />
        </div>
        <div className={Styles.wrapper}>
          <InputField
            title="Nomor Rekening"
            value={bankNumber}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}
