import Icon from "@Atom/Icon";
import { makeRandomString } from "@Helpers/makeRandomString";
import ImageUploadField from "@Molecule/ImageUploadField";
import InputField from "@Molecule/InputField";
import {
  candidateEvaluationAccept,
  candidateEvaluationReject,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function ResultModal({
  mode,
  data,
  onClose,
  setTrigger,
  defaultScores = [],
}) {
  const [rejectRemark, setRejectRemark] = useState("");
  const [resultRemark, setResultRemark] = useState("");
  const [documentation, setDocumentation] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [formMode, setFormMode] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const template = useMemo(() => {
    switch (mode) {
      case "accept":
        return {
          logo: Images.RESULT_ACCEPTED_ICON,
          title: "Lolos",
          description:
            "Apakah anda yakin kandidat ini lolos dan lanjut ke tahap interview klien?",
          buttonText: "Lanjutkan",
          buttonOnClick: async () => {
            setFormMode(true);
          },
          disableButton: () => {
            if (submitting) {
              return true;
            }
            return false;
          },
        };
      default:
        return {
          logo: Images.RESULT_REJECTED_ICON,
          title: "Tidak Lolos",
          description: "Tulis catatan mengapa kandidat tidak lolos",
          withRemark: true,
          buttonText: !submitting ? "Proses" : "Memproses...",
          buttonOnClick: async () => {
            try {
              setSubmitting(true);
              await candidateEvaluationReject(data?.id, rejectRemark);
              setSubmitting(false);
              setTrigger(makeRandomString(5));
              onClose();
            } catch (err) {
              console.log(err);
              setSubmitting(false);
            }
          },
          disableButton: () => {
            if (submitting || !rejectRemark) {
              return true;
            }
            return false;
          },
        };
    }
  }, [data?.id, mode, onClose, rejectRemark, setTrigger, submitting]);

  const [scores, setScores] = useState(defaultScores || []);

  useEffect(() => {
    setScores(
      defaultScores?.map((obj) => {
        return {
          ...obj,
          value: "",
        };
      })
    );
  }, [defaultScores, data]);

  const scoreIndicators = [1, 2, 3, 4, 5];

  const disableSend = useMemo(() => {
    if (
      scores?.find((obj) => !obj?.value) ||
      !resultRemark ||
      !documentation ||
      submitting
    ) {
      return true;
    }
    return false;
  }, [documentation, resultRemark, scores, submitting]);

  const handleSend = async () => {
    try {
      setSubmitting(true);

      const formData = new FormData();

      formData?.append("candidateID", data?.id);
      formData?.append("note", resultRemark);
      for (let i = 0; i < scores.length; i++) {
        formData?.append(`labels[${i}]`, scores[i]?.name);
        formData?.append(`scores[${i}]`, +scores[i]?.value);
      }
      formData?.append("file", documentation);

      await candidateEvaluationAccept(formData);
      setSubmitting(false);
      setTrigger(makeRandomString(5));
      onClose();
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
      console.log(err);
      setSubmitting(false);
    }
  };
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  if (formMode) {
    return (
      <div className={Styles.formContainer}>
        <div className={Styles.header}>
          <h3>Hasil Interview</h3>
          <button onClick={() => onClose()}>
            <Icon icon={"cross"} size={24} />
          </button>
        </div>
        <div className={Styles.content}>
          <div className={Styles.intro}>
            <div className={Styles.stackedIntro}>
              <div>
                <img src={Images.INVITE_USER} alt="name" />
              </div>
              <div>
                <span>Candidate</span>
                <span>{toTitleCase(data?.name)}</span>
              </div>
            </div>
            <div className={Styles.stackedIntro}>
              <div>
                <img src={Images.INVITE_JOB} alt="name" />
              </div>
              <div>
                <span>Job</span>
                <span>{data?.ExpectedPosition?.name}</span>
              </div>
            </div>
          </div>
          <div className={Styles.scoring}>
            <span>Penilaian</span>
            <div className={Styles.card}>
              {scores?.map((each) => (
                <div key={each?.id} className={Styles.each}>
                  <span>{each?.name}</span>
                  <div className={Styles.indicators}>
                    {scoreIndicators?.map((s, i) => (
                      <div
                        key={i}
                        className={`${Styles.indicator} ${
                          each?.value === s ? Styles.active : ""
                        }`}
                        onClick={() => {
                          const newScores = [...scores];
                          const objIndex = scores?.findIndex(
                            (obj) => obj?.name === each?.name
                          );
                          newScores[objIndex].value = s;
                          setScores(newScores);
                        }}
                      >
                        {s}
                      </div>
                    ))}
                    <div className={Styles.line} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <InputField
            type={"desc"}
            title={"Catatan"}
            placeholder={"Masukkan catatan anda"}
            value={resultRemark}
            onChange={(e) => setResultRemark(e?.target?.value)}
          />
          <ImageUploadField
            title={"Foto Interview"}
            file={documentation}
            setFile={setDocumentation}
            withPreview
          />
        </div>
        <div
          className={`${Styles.errorWrapper} ${
            error ? Styles.visible : Styles.hide
          }`}
        >
          <span>{error || "error placeholder"}</span>
        </div>
        <div className={Styles.actions}>
          <button onClick={onClose}>Batal</button>
          <button disabled={disableSend} onClick={handleSend}>
            {submitting ? "Memproses" : "Proses"}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <img src={template?.logo} alt="" />
        <div className={Styles.desc}>
          <h3>{template?.title}</h3>
          <p>{template?.description}</p>
        </div>
        {template?.withRemark && (
          <InputField
            type={"desc"}
            title={"Catatan"}
            placeholder={"Masukkan catatan anda"}
            value={rejectRemark}
            onChange={(e) => setRejectRemark(e?.target?.value)}
          />
        )}
      </div>

      <div className={Styles.actions}>
        <button onClick={onClose}>Batal</button>
        <button
          disabled={template?.disableButton()}
          onClick={template?.buttonOnClick}
        >
          {template?.buttonText}
        </button>
      </div>
    </div>
  );
}
