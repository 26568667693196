import PERMATA_LOGO from "@Assets/Images/permata-logo-2.png";
import { getSchemeDetail, getSchemeDetailRO } from "@Services/manager/client";
import { useCallback, useEffect, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import SchemeDetailContent from "@Organism/Manager/Client/WorkSchemes/SchemeDetail/Layout";
import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";

export default function SchemeDetailModal({
  showDetailFlb,
  setShowDetailFlb,
  selectedFlb,
  handleDone = () => {},
  onBack,
  isBM = false,
}) {
  const [data, setData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const refetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { response } = isBM
        ? await getSchemeDetail(showDetailFlb)
        : await getSchemeDetailRO(showDetailFlb);
      setData(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, [isBM, showDetailFlb]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  // const [dataQualification, setDataQualification] = useState(null);
  // const refetchDataQualifications = useCallback(async () => {
  //   try {
  //     setIsLoading(true)
  //     const {response} = await orderSync
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);

  // console.log(data);

  return (
    <ModalWrapper show={showDetailFlb} handleClose={handleDone}>
      <div className={Styles.body}>
        <div className={Styles.header}>
          <div className={Styles.logoPermata}>
            <div className={Styles.leftLogo}>
              <div className={Styles.imagesBox}>
                <img src={PERMATA_LOGO} alt="" />
              </div>
              <span className={Styles.nameLogo}>PT Permata Indo Sejahtera</span>
            </div>
            <div className={Styles.iconClose} onClick={onBack}>
              <Icon icon={"cross"} size={24} />
            </div>
          </div>
          <div className={Styles.flbNumberBox}>
            <span className={Styles.title}>Nomor FLB</span>
            <span className={Styles.docNum}>
              {selectedFlb?.docNumber || data?.contract?.docNumber}
            </span>
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 220px)",
              display: "grid",
              placeItems: "center",
            }}
          >
            <LoadingAnimation />
          </div>
        ) : (
          <SchemeDetailContent data={data} />
        )}
      </div>
    </ModalWrapper>
  );
}
