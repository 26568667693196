import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Styles from "./style.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import ViewDetailModal from "./ViewDetailModal";
import UseOutsideClick from "@Hooks/useOutsideClick";

const TABLE_HEAD = [
  { id: "title", label: "Man power", align: "left", disablePadding: true },
  { id: "demand", label: "Total", align: "center", disablePadding: false },
  { id: "salary", label: "Salary", align: "center", disablePadding: false },
  {
    id: "salaryInMonth",
    label: "Salary in Month",
    align: "center",
    disablePadding: false,
  },
  {
    id: "personalEquipments",
    numeric: true,
    label: "Personal Work Equipment",
    align: "center",
    disablePadding: false,
  },
  {
    id: "allowance",
    numeric: true,
    label: "Allowance",
    align: "center",
    disablePadding: false,
  },
  {
    id: "bpjs",
    numeric: true,
    label: "BPJS",
    align: "center",
    disablePadding: false,
  },
  {
    id: "totalCost",
    numeric: true,
    label: "Total Cost",
    align: "right",
    disablePadding: true,
  },
];

export default function PerArea({
  data,
  manfee,
  ppn,
  pph21,
  selectedLogs,
  isHC,
  updateLogs,
}) {
  let IDR = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // eslint-disable-next-line
  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "1000px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "150px";
    }
    return width;
  }, [windowSize]);

  const [selectedManPower, setSelectedManPower] = useState(null);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (selectedManPower !== null) {
      if (boxOutsideClick === false) {
        setSelectedManPower(null);
      }
    }
  }, [boxOutsideClick, selectedManPower]);

  const handleClickSee = (selected) => {
    setSelectedManPower(selected);
  };

  const getPeriodText = (periodStr) => {
    switch (periodStr) {
      case "Harian":
        return "Harian x 20";
      case "Mingguan":
        return "Mingguan x 4";
      default:
        return "Bulanan";
    }
  };

  return (
    <div className={Styles.container}>
      <h4>{data?.cityName}</h4>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table
            className={Styles.table}
            // sx={{ minWidth: tableMinWidth }}
          >
            <TableHead
              sx={{
                "& tr": {
                  backgroundColor: "#fff",
                },
              }}
              className={Styles.tableHead}
            >
              <TableRow className={Styles.tableHeadRow}>
                {TABLE_HEAD.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.align ? headCell?.align : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    className={
                      index === 0
                        ? `${Styles.stickyLeft} ${Styles.tableHeadCell}`
                        : index === TABLE_HEAD.length - 1
                        ? `${Styles.stickyRight} ${Styles.hideOnMobile} ${Styles.tableHeadCell}`
                        : `${Styles.hideOnMobile} ${Styles.tableHeadCell}`
                    }
                    sx={{ minWidth: firstColMinWidth }}
                  >
                    <span className={Styles.headcellLabel}>
                      {headCell.label}
                    </span>
                  </TableCell>
                ))}
                <TableCell
                  key={Math.random()}
                  padding={"none"}
                  className={`${Styles.showOnMobile}`}
                  align="center"
                >
                  <span>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={Styles.tableBody}>
              {data?.manPowerCosts?.map((eachD, iD) => {
                return (
                  <TableRow
                    key={iD}
                    className={`${Styles.tableRow} ${
                      iD === eachD.length - 1 && Styles.LastRow
                    }`}
                  >
                    <TableCell
                      padding="none"
                      className={`${Styles.tableCell} ${Styles.stickyLeft}`}
                    >
                      {/* <span>{eachD?.name}</span> */}
                      {eachD?.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {eachD?.amount}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      <div className={Styles.salary}>
                        <span>
                          {" "}
                          {IDR.format(`${eachD?.salary}`?.split("::")[0] || 0)}
                        </span>
                        <span> {getPeriodText(`${eachD?.salaryDenom}`)}</span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {IDR.format(eachD?.salaryPermonth || "0")}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {IDR.format(eachD?.personalEquipmentCost || 0)}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {IDR.format(eachD?.allowances || 0)}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                    >
                      {IDR.format(eachD?.bpjs || 0)}
                    </TableCell>
                    <TableCell
                      align="right"
                      padding="none"
                      className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                    >
                      {IDR.format(eachD?.totalCosts || 0)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                    >
                      <button onClick={() => handleClickSee(eachD)}>
                        See More
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={Styles.summary}>
        <div className={Styles.preCalc}>
          <div className={Styles.stacked}>
            <span>Sub Total</span>
            <span>{IDR.format(data?.subTotal || 0)}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Area Work Equipment</span>
            <span>{IDR.format(data?.areaEquipmentCost || 0)}</span>
          </div>
          <div className={Styles.stacked}>
            <span
              className={`${
                isHC &&
                updateLogs?.length > 0 &&
                updateLogs?.find(
                  (log) => log?.remark === "Perubahan Management Fee"
                ) &&
                Styles.borderYellow
              }`}
            >
              Management Fee {manfee?.includes("%") && `(${manfee})`}
            </span>
            <span>{IDR.format(data?.manfee || 0)}</span>
          </div>
        </div>
        <div className={Styles.calc}>
          <div className={Styles.stacked}>
            <span>Total biaya</span>
            <span>{IDR.format(data?.total || 0)}</span>
          </div>
          <div className={Styles.stacked}>
            <span>PPN 11% {ppn}</span>
            <span>{IDR.format(data?.ppn || 0)}</span>
          </div>
          <div className={Styles.stacked}>
            <span>PPH 2% {pph21}</span>
            <span>{IDR.format(data?.pph || 0)}</span>
          </div>
          <div className={Styles.stacked}>
            <span>Grand total</span>
            <span>{IDR.format(data?.grandTotal || 0)}</span>
          </div>
        </div>
        <div></div>
      </div>

      <div
        className={`${Styles.modalWrapper} ${
          selectedManPower ? Styles.showModal : Styles.hideModal
        }`}
      >
        <ViewDetailModal
          data={selectedManPower}
          setData={setSelectedManPower}
        />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedManPower ? Styles.visible : ""
        }`}
      />
    </div>
  );
}
