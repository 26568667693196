import InputDateField from "@Molecule/InputDateField";
import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";
import { useCallback, useEffect } from "react";
import moment from "moment";
import Icon from "@Atom/Icon";

export default function FourthStep({
  form,
  setForm,
  values,
  setValues,
  contractType,
}) {
  // const defaultDate = moment("2099-12-31").format("LL");
  // const defaultDateForm = new Date("2099-12-31");

  const handleChangeValues = useCallback(
    (newVal, code) => {
      setValues((prev) =>
        prev?.map((obj) => {
          if (obj?.code === code) {
            return {
              ...obj,
              value: newVal,
            };
          }
          return obj;
        })
      );
    },
    [setValues]
  );

  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  useEffect(() => {
    if (contractType?.toLowerCase() === "pkm") {
      if (form?.contractStartDate) {
        setForm((f) => ({
          ...f,
          contractEndDate: addMonths(form?.contractStartDate, 3) || "",
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType, form?.contractStartDate, setForm]);

  useEffect(() => {
    handleChangeValues(
      form?.contractStartDate
        ? new Date(form?.contractStartDate).toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        : "",
      "{CONTRACT_STARTDATE}"
    );
    handleChangeValues(
      form?.contractEndDate
        ? new Date(form?.contractEndDate).toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        : "",
      "{CONTRACT_ENDDATE}"
    );
  }, [
    form?.contractEndDate,
    form?.contractStartDate,
    handleChangeValues,
    // contractType,
    // defaultDate,
  ]);
  // console.log(values);

  return (
    <div className={Styles.container}>
      <div className={Styles.information}>
        <Icon icon={"information-solid"} size={"24px"} color={"#1571de"} />
        <p>
          {
            "Pastikan anda memasukan tanggal perpanjangan kontrak karyawan yang baru"
          }
        </p>
      </div>
      <div className={Styles.dateWrapper}>
        <h4>Informasi Tanggal Kontrak</h4>
        <div className={Styles.field}>
          <InputDateField
            type={"date"}
            title={"Tanggal Dibuat Kontrak"}
            value={form?.contractDate}
            setValue={(newVal) =>
              setForm((f) => ({ ...f, contractDate: newVal }))
            }
            // placeholder={'Select date'}
            placeholder={moment(new Date())?.format("DD/MM/YYYY")}
            disabled
          />
          <InputDateField
            type={"date"}
            title={"Tanggal Mulai Kontrak"}
            value={form?.contractStartDate}
            // setValue={(newVal) => handleChangeValues(newVal, "{CONTRACT_ENDDATE}")}
            setValue={(newVal) => {
              setForm((f) => ({ ...f, contractStartDate: newVal }));
            }}
            placeholder={"Select date"}
          />
          <InputDateField
            type={"date"}
            title={"Tanggal Berakhir Kontrak"}
            value={form?.contractEndDate}
            setValue={(newVal) =>
              setForm((f) => ({ ...f, contractEndDate: newVal }))
            }
            // setValue={(newVal) => handleChangeValues(newVal, "{CONTRACT_STARTDATE}")}
            placeholder={"Select date"}
            // disabledDate={contractType === "PKM" ? true : false}
            min={form?.contractStartDate}
          />
        </div>
      </div>
      <InputField
        title={"Catatan"}
        value={form?.remark}
        onChange={(e) => setForm((f) => ({ ...f, remark: e?.target?.value }))}
        placeholder={"Tulis catatan anda disini"}
        type={"desc"}
      />
    </div>
  );
}
