import { useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'
import { addClientContact } from '@Services/manager/client'
import Icon from '@Atom/Icon'
import InputField from '@Molecule/InputField'
import FormErrorMessage from '@Atom/FormErrorMessage'
import { allFilledObjChecker } from '@Helpers/allFilledObjChecker'
import useQuery from '@Hooks/useQuery'
import { validateEmail } from '@Helpers/validateEmail'

export default function AddClientContactModal({
  setContacts = () => {},
  setTrigger = () => {},
  setShowAdd = () => {},
  isRecruitment,
  data,
  refetchDataOptionsInterview,
}) {
  const query = useQuery();

  const clientCode = query.get("c");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  const [form, setForm] = useState({
    name: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    workUnit: "",
    clientCode: isRecruitment ? data : clientCode,
  });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { response } = await addClientContact({
        ...form,
        phoneNumber: "62" + form?.phoneNumber,
      });
      setContacts((prev) => {
        return [...prev, response];
      });
      // await refetchSync()
      if (isRecruitment) {
        refetchDataOptionsInterview();
      }
      setIsSubmitting(false);
      setShowAdd(false);
    } catch (err) {
      setIsSubmitting(false);
      const errorText = err?.response?.data?.error;
      setError(errorText);
    }
  };

  const template = useMemo(() => {
    return [
      {
        name: "name",
        title: "Nama",
        placeholder: "Tulis nama",
      },
      {
        name: "jobTitle",
        title: "Posisi",
        placeholder: "Tulis nama posisi",
      },
      {
        name: "email",
        title: "Email",
        placeholder: "Tulis email",
      },
      {
        name: "phoneNumber",
        title: "No Hp",
        placeholder: "8xxx",
        type: "phone",
      },
      {
        name: "workUnit",
        title: "Unit Kerja",
        placeholder: "Tulis unit kerja",
      },
    ];
  }, []);
  return (
    <div className={Styles.newContactContainer}>
      <div className={Styles.newHeader}>
        <button onClick={() => setShowAdd(false)}>
          <Icon icon={"arrow-left"} size={24} />
        </button>
        <span>Tambah Kontak Klien</span>
      </div>
      <div className={Styles.newContent}>
        {template?.map((each, i) => (
          <InputField
            key={i}
            type={each?.type || ""}
            title={each?.title}
            placeholder={each?.placeholder}
            value={form?.[each?.name]}
            onChange={(e) =>
              setForm((prev) => {
                return {
                  ...prev,
                  [each?.name]: e?.target?.value,
                };
              })
            }
          />
        ))}
      </div>
      <FormErrorMessage message={error} />
      <div className={Styles.buttonsWrapper}>
        <button onClick={() => setShowAdd(false)}>Batalkan</button>
        <button disabled={isSubmitting || !allFilledObjChecker(form) || !validateEmail(form?.email)} onClick={handleSubmit}>
          {isSubmitting ? 'Menambahkan...' : 'Tambahkan'}
        </button>
      </div>
    </div>
  );
}
