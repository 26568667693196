import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import InputDate from "@Atom/InputDate";
import ModalWrapper from "@Atom/ModalWrapper";
import StackedProfile from "@Atom/StackedProfile";
import { fileBaseUrl } from "@Config/api";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import { makeRandomString } from "@Helpers/makeRandomString";
import useQuery from "@Hooks/useQuery";
import OfferingAddAllowanceModal from "@Molecule/_modal/OfferingAddAllowanceModal";
import PositionListModal from "@Molecule/_recruitment/PositionListModal";
import {
  getRecruitmentSync,
  sendOffering,
} from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import FirstStage from "./First";
import SecondStage from "./Second";
import ThirdStage from "./Third";
import Styles from "./style.module.scss";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";

const steps = [
  {
    id: 1,
    title: "Source & Interview RO",
  },
  {
    id: 2,
    title: "Interview Klien",
  },
  {
    id: 3,
    title: "Offering",
  },
];

const allowanceOptions = [
  "Uang makan",
  "Tunjangan transportasi",
  "Lembur",
  "Tunjangan komunikasi",
  "Tunjangan BBM",
  "Tunjangan skills/keahlian",
  "Tunjangan operasional",
  "Tunjangan posisi/jabatan",
  "Tunjangan service kendaraan",
  "Tunjangan kehadiran",
  "Tunjangan kesehatan",
  "Tunjangan kedisiplinan",
  "Tunjangan kerajinan",
  "Tunjangan tidak tetap lainnya",
  "Tunjangan insentif/shift",
  "Kos",
  "Rumah",
  "Pekerjaan",
];

export default function RecruitmentDetailLayout({
  name,
  code,
  roSource,
  clientSource,
  contacts,
  offeringSource,
  defaultSyncData,
  invitationSyncData,
  offeringMode,
  setOfferingMode,
}) {
  const query = useQuery();
  const defaultTrigger = query.get("t") || "";
  const [syncData, setSyncData] = useState(defaultSyncData);
  const [currentStep, setCurrentStep] = useState(1);
  const [positionFilter, setPositionFilter] = useState([
    syncData?.expectedPositions[0]?.position,
  ]);

  const [positionIDFilter, setPositionIDFilter] = useState([
    syncData?.expectedPositions[0]?.positionID,
  ]);
  // eslint-disable-next-line no-unused-vars
  const [locationFilter, setLocationFilter] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showPositionModal, setShowPositionModal] = useState(false);
  const [syncTrigger, setSyncTrigger] = useState(defaultTrigger);
  const [positionData, setPositionData] = useState([]);

  const [showAllowancesModal, setShowAllowancesModal] = useState(false);

  // console.log(syncData);

  const refetchSync = useCallback(async () => {
    const limit = query.get("l") || 5;
    const page = query.get("p") || 1;

    try {
      const queryStr = `limit=${limit}&page=${page}&status=RO-INVITE`;
      const { response } = await getRecruitmentSync(
        query?.get("c") || "",
        queryStr
      );
      setSyncData(response);
    } catch (err) {
      console.log(err);
    }
  }, [query]);

  useEffect(() => {
    setSyncData(defaultSyncData);
  }, [defaultSyncData]);

  const [offeringForm, setOfferingForm] = useState({
    recruiterID: localStorage.getItem("id") || "",
    contract: {
      startDate: "",
      endDate: "",
      baseSalary: "",
      denom: "",
    },
    allowances: [],
  });

  const filterSyncData = useMemo(() => {
    if (offeringMode && syncData) {
      return syncData?.expectedPositions?.find(
        (el) => el?.positionID === offeringMode?.positionID
      );
    }
  }, [offeringMode, syncData]);

  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  // console.log(positionFilter, "test");

  const formatDate = useCallback(() => {
    if (filterSyncData?.jobPost?.contractType?.toLowerCase() === "pkm") {
      if (offeringForm?.contract?.startDate) {
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            startDate: offeringForm?.contract?.startDate,
            endDate: addMonths(offeringForm?.contract?.startDate, 3) || "",
            baseSalary: "",
            denom: "",
          },
        }));
      }
    }
  }, [
    filterSyncData?.jobPost?.contractType,
    offeringForm?.contract?.startDate,
  ]);

  useEffect(() => {
    formatDate();
  }, [formatDate]);

  // useEffect(() => {
  //   if (offeringMode?.ExpectedPosition?.contractType === "PKM") {
  //     setOfferingForm((prev) => ({
  //       ...prev,
  //       contract: {
  //         startDate: "",
  //         endDate: new Date(),
  //         baseSalary: "",
  //         denom: "",
  //       },
  //     }));
  //   }
  // }, [offeringMode?.ExpectedPosition?.contractType]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const disableSubmitOffering = useMemo(() => {
    if (isSubmitting) {
      return true;
    } else {
      if (!allFilledObjChecker(offeringForm?.contract)) {
        return true;
      } else {
        for (let i = 0; i < offeringForm?.allowances?.length; i++) {
          const a = offeringForm?.allowances[i];
          if (!a?.value || !a?.denom) {
            return true;
          }
        }
      }
      return false;
    }
  }, [offeringForm, isSubmitting]);

  const handleSubmitOffering = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      await sendOffering(offeringMode?.id, {
        ...offeringForm,
        startDate:
          moment(new Date(offeringForm.startDate))?.format("YYYY-MM-DD") || "",
        endDate:
          moment(new Date(offeringForm.endDate))?.format("YYYY-MM-DD") || "",
      });
      setIsSubmitting(false);
      setSyncTrigger(makeRandomString(5));
      setCurrentStep(3);
      setOfferingMode(false);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
    }
  };

  const requestDetails = useMemo(() => {
    let amount = 0;
    let positionsArray = [];
    let locationsArray = syncData?.locations;
    for (let i = 0; i < syncData?.expectedPositions?.length; i++) {
      let position = syncData?.expectedPositions[i];
      amount += +position?.amount;
      positionsArray?.push(position?.position);
    }

    return {
      amount: amount,
      positions: positionsArray,
      locations: locationsArray,
    };
  }, [syncData]);

  const handleShowPositionDetail = (data, type) => {
    setSelectedPosition(data);
    setPositionFilter([data.position]);
    setPositionIDFilter([data.positionID]);
  };

  // const defaultPositionName = query?.get("ps");
  const defaultId = query?.get("id");

  // eslint-disable-next-line no-unused-vars
  // const [positionName, setPositionName] = useState(defaultPositionName);
  // eslint-disable-next-line no-unused-vars
  const [positionID, setPositionID] = useState(defaultId);

  useEffect(() => {
    setPositionData(syncData?.expectedPositions);

    if (!positionID) {
      if (syncData?.expectedPositions.length > 0) {
        setSelectedPosition(syncData?.expectedPositions[0]);
        setPositionFilter([syncData?.expectedPositions[0].position]);
        setPositionIDFilter([syncData?.expectedPositions[0].positionID]);
      }
    } else {
      setSelectedPosition(
        syncData?.expectedPositions?.find(
          (obj) => obj?.jobPost?._id === positionID
        )
      );
      setPositionFilter([
        syncData?.expectedPositions?.find(
          (obj) => obj?.jobPost?._id === positionID
        )?.position,
      ]);
      setPositionIDFilter([
        syncData?.expectedPositions?.find(
          (obj) => obj?.jobPost?._id === positionID
        )?.positionID,
      ]);
      setPositionData((prev) => {
        return [
          syncData?.expectedPositions?.find(
            (obj) => obj?.jobPost?._id === positionID
          ),
          ...prev?.filter((obj) => obj?.jobPost?._id !== positionID),
        ];
      });
    }
  }, [syncData, positionID]);

  // console.log(positionID, positionFilter, selectedPosition);

  const positions = useMemo(() => {
    const arr = [];
    for (let i = 0; i < syncData?.expectedPositions?.length; i++) {
      arr.push(syncData?.expectedPositions[i]?.position);
    }
    return arr;
  }, [syncData]);

  const handleChangePosition = (data) => {
    const temp = [...positionData];
    const filtered = temp.filter((el) => el?.positionID !== data?.positionID);
    setSelectedPosition(data);
    setPositionData([data, ...filtered]);
    setPositionFilter([data.position]);
    setPositionIDFilter([data.positionID]);
    setShowPositionModal(false);
  };

  const evaluationParameters = useMemo(() => {
    const evaluationArr =
      syncData?.expectedPositions?.find(
        (obj) => obj?.positionID === selectedPosition?.positionID
      )?.jobPost?.evaluations || [];
    return evaluationArr?.map((obj) => {
      return {
        ...obj,
        id: obj?._id,
        name: obj?.label,
        value: "",
      };
    });
  }, [selectedPosition?.positionID, syncData?.expectedPositions]);

  const isThroughClientInterview = useMemo(() => {
    if (selectedPosition?.jobPost?.isThroughClientInterview === false) {
      return false;
    }
    return true;
  }, [selectedPosition?.jobPost?.isThroughClientInterview]);

  const filteredSteps = useMemo(() => {
    if (isThroughClientInterview) {
      return steps;
    }
    return steps.filter((obj) => !obj?.title?.toLowerCase()?.includes("klien"));
  }, [isThroughClientInterview]);

  useEffect(() => {
    setCurrentStep(1);
  }, [selectedPosition]);

  if (offeringMode) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>{`${name} • ${offeringMode?.ExpectedPosition?.name}`}</h3>
        </div>
        <div className={Styles.content}>
          <div className={Styles.form}>
            <div className={Styles.formHeader}>
              <StackedProfile
                titleClassName={Styles.candidateName}
                imgSize="60px"
                profilePicture={
                  offeringMode?.CandidateDocuments?.find(
                    (obj) => obj?.type?.toLowerCase() === "self picture"
                  )?.url
                    ? fileBaseUrl +
                      offeringMode?.CandidateDocuments?.find(
                        (obj) => obj?.type?.toLowerCase() === "self picture"
                      )?.url
                    : Images.AVA_DEFAULT
                }
                title={`${offeringMode?.name} • ${offeringMode?.ExpectedPosition?.name}`}
                label={
                  <p className={Styles.label}>
                    <span>
                      {offeringMode?.phoneNumber
                        ? `+${offeringMode?.phoneNumber}`
                        : "-"}
                    </span>
                    <Icon icon={"whatsapp"} size={20} color={"#27BB83"} />
                  </p>
                }
              />
            </div>
            <div className={Styles.formContent}>
              <div className={Styles.contractDate}>
                <span>Tanggal Kontrak</span>
                <div className={Styles.range}>
                  <InputDate
                    value={offeringForm?.contract?.startDate}
                    placeholder={"Tanggal mulai kontrak"}
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.startDate = newVal;
                      setOfferingForm(newForm);
                    }}
                  />
                  <div className={Styles.separator}>-</div>
                  <InputDate
                    value={offeringForm?.contract?.endDate}
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.endDate = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Tanggal berakhir kontrak"}
                    disabled={!offeringForm?.contract?.startDate}
                    // disabledDate={
                    //   filterSyncData?.jobPost?.contractType?.toLowerCase() ===
                    //   "pkm"
                    //     ? true
                    //     : false
                    // }
                  />
                </div>
              </div>
              <div className={Styles.salary}>
                <span>Gaji Pokok / Bantuan Operasional</span>
                <div className={Styles.requirements}>
                  <Input
                    value={offeringForm?.contract?.baseSalary}
                    onChange={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.baseSalary = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Gaji"}
                    isCurrency
                  />
                  <Dropdown
                    autoClose
                    value={offeringForm?.contract?.denom}
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.denom = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Harian/mingguan/bulanan"}
                    options={[
                      { name: "Harian" },
                      { name: "Mingguan" },
                      { name: "Bulanan" },
                    ]}
                  />
                </div>
              </div>
              <div className={Styles.allowances}>
                <div className={Styles.allowanceHeader}>
                  <span>Tunjangan</span>
                  {!!offeringForm?.allowances?.length && (
                    <button
                      onClick={() => setShowAllowancesModal(true)}
                      className={`${Styles.addAllowance}`}
                    >
                      <span>Tambah Tunjangan</span>
                      <Icon icon={"circle-plus"} size={20} />
                    </button>
                  )}
                </div>

                {!offeringForm?.allowances?.length ? (
                  <div className={Styles.empty}>
                    <span>Belum ada tunjangan ditambahkan</span>
                    <button
                      onClick={() => setShowAllowancesModal(true)}
                      className={`${Styles.addAllowance}`}
                    >
                      <span>Tambah Tunjangan</span>
                      <Icon icon={"circle-plus"} size={20} />
                    </button>
                  </div>
                ) : (
                  <div className={Styles.fieldsWrapper}>
                    {offeringForm?.allowances?.map((o, oI) => (
                      <div key={oI} className={Styles.each}>
                        <span>{o?.label}</span>
                        <div className={Styles.fields}>
                          <Input
                            value={o?.value}
                            onChange={(newVal) => {
                              setOfferingForm((prev) => {
                                return {
                                  ...prev,
                                  allowances: prev?.allowances?.map((obj) => {
                                    return {
                                      ...obj,
                                      value:
                                        obj?.label === o?.label
                                          ? newVal
                                          : obj?.value,
                                    };
                                  }),
                                };
                              });
                            }}
                            placeholder={"000"}
                            isCurrency
                          />
                          <Dropdown
                            value={o?.denom}
                            setValue={(newVal) => {
                              setOfferingForm((prev) => {
                                return {
                                  ...prev,
                                  allowances: prev?.allowances?.map((obj) => {
                                    return {
                                      ...obj,
                                      denom:
                                        obj?.label === o?.label
                                          ? newVal
                                          : obj?.denom,
                                    };
                                  }),
                                };
                              });
                            }}
                            autoClose
                            placeholder={"Harian/mingguan/bulanan"}
                            options={[
                              {
                                name: "Harian",
                              },
                              {
                                name: "Mingguan",
                              },
                              {
                                name: "Bulanan",
                              },
                            ]}
                          />
                          <button
                            onClick={() => {
                              setOfferingForm((prev) => {
                                return {
                                  ...prev,
                                  allowances: prev?.allowances?.filter(
                                    (obj) => obj?.label !== o?.label
                                  ),
                                };
                              });
                            }}
                          >
                            <Icon icon={"trash"} size={24} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={Styles.formActions}>
              <button
                onClick={() => {
                  setOfferingMode(false);
                }}
              >
                Kembali
              </button>
              <button
                disabled={disableSubmitOffering}
                onClick={handleSubmitOffering}
              >
                {isSubmitting ? "Mengirim data..." : "Kirim"}
              </button>
            </div>
          </div>
        </div>
        {showAllowancesModal && (
          <ModalWrapper
            show={showAllowancesModal}
            handleClose={() => setShowAllowancesModal(false)}
          >
            <OfferingAddAllowanceModal
              options={allowanceOptions}
              selectedOptions={offeringForm?.allowances?.map(
                (obj) => obj?.label
              )}
              onSubmit={(arr) => {
                setOfferingForm((prev) => {
                  return {
                    ...prev,
                    allowances: [
                      ...prev?.allowances,
                      ...arr?.map((obj) => {
                        return {
                          label: obj,
                          value: "",
                          denom: "",
                        };
                      }),
                    ],
                  };
                });
                setShowAllowancesModal(false);
              }}
              onClose={() => setShowAllowancesModal(false)}
            />
          </ModalWrapper>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          <h3>{name}</h3>
          <div className={Styles.infoContractWrapper}>
            <span>
              {capitalizedFirstLetter(
                selectedPosition?.position || positionData[0]
              )}
            </span>
            <div className={Styles.contractTypeWrapper}>
              <Icon icon={"document"} size={16} color={"#1571DE"} />
              <p>Kontrak</p>
              <p>:</p>
              <span className={Styles.contractType}>
                {selectedPosition?.jobPost?.contractType}
              </span>
            </div>
          </div>
        </div>
        <div className={Styles.right}>
          <div className={Styles.fulfillmentDateWrapper}>
            <Icon icon="calendar" color={"#1571DE"} size={20} />
            <span>Tanggal Pemenuhan</span>
            <span>:</span>
            <span>
              {selectedPosition?.expectedFulfillmentDate
                ? moment(
                    new Date(selectedPosition?.expectedFulfillmentDate)
                  )?.format("LL")
                : "-"}
            </span>
          </div>
        </div>
      </div>
      <div className={Styles.stepsController}>
        <h4 className={Styles.mobileSubTitle}>Tahap</h4>
        <div className={Styles.controllers}>
          {filteredSteps?.map((step, i) => {
            return (
              <div key={i} className={`${Styles.step}`}>
                <button
                  onClick={() => setCurrentStep(step?.id)}
                  className={step?.id === currentStep ? Styles.Active : ""}
                >
                  <span>{i + 1}</span>
                  <span>{step?.title}</span>
                </button>
                <div
                  className={`${Styles.separator} ${
                    step?.id === steps?.length && Styles.hidden
                  }`}
                />
              </div>
            );
          })}
        </div>
        <div className={Styles.position}>
          {positionData.map(
            (label, i) =>
              i < 2 && (
                <div
                  key={i}
                  className={`${Styles.label} ${
                    selectedPosition?.positionID === label?.positionID
                      ? Styles.selectedPosition
                      : ""
                  }`}
                  onClick={() => handleShowPositionDetail(label, "desktop")}
                >
                  <span>{label?.position}</span>
                  <span>{label?.amount}</span>
                </div>
              )
          )}
          {syncData?.expectedPositions.length > 2 && (
            <div
              className={Styles.morePosition}
              onClick={() => setShowPositionModal(true)}
            >
              <p>{syncData?.expectedPositions?.length - 2}+</p>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.content}>
        {currentStep === 1 && (
          <FirstStage
            positions={positions}
            locations={syncData?.locations}
            educations={syncData?.educations?.map((el) => el?.value)}
            invitationSource={roSource?.invitation}
            rejectedSource={roSource?.rejected}
            acceptedSource={roSource?.accepted}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            positionIDFilter={positionIDFilter}
            contacts={contacts}
            defaultCandidates={syncData?.candidates?.data}
            defaultTotalPage={syncData?.candidates?.totalPage}
            cities={syncData?.cities}
            syncData={syncData}
            invitationSyncData={invitationSyncData}
            trigger={syncTrigger}
            setTrigger={setSyncTrigger}
            clientContacts={syncData?.clientContacts}
            requestDetails={requestDetails}
            selectedPosition={selectedPosition}
            evaluationParameters={evaluationParameters}
          />
        )}
        {currentStep === 2 && (
          <SecondStage
            source={clientSource}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            setCurrentStep={setCurrentStep}
            trigger={syncTrigger}
            setTrigger={setSyncTrigger}
            clientContacts={syncData?.clientContacts}
            // requestDetails={requestDetails}
            invitationSyncData={invitationSyncData}
            syncData={syncData}
            refetchSync={refetchSync}
            positionIDFilter={positionIDFilter}
          />
        )}
        {currentStep === 3 && (
          <ThirdStage
            source={offeringSource}
            locationFilter={locationFilter}
            positionFilter={positionFilter}
            trigger={syncTrigger}
            setTrigger={setSyncTrigger}
            setOfferingMode={setOfferingMode}
            positionIDFilter={positionIDFilter}
          />
        )}
      </div>
      {/* {showPositionModal && (
        <PositionDetailModal
          setShow={setShowPositionModal}
          data={selectedPosition}
          setData={setSelectedPosition}
          type="desktop"
        />
      )} */}
      {showPositionModal && (
        <PositionListModal
          setShow={setShowPositionModal}
          data={syncData?.expectedPositions}
          setData={setPositionData}
          handleChange={handleChangePosition}
        />
      )}
    </div>
  );
}
