import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import Images from "@Theme/Images";
import { useCallback, useEffect, useState } from "react";
import {
  candidateEvaluationAccept,
  candidateEvaluationReject,
  getCandidateEvaluations,
} from "@Services/officer/recruitment";
import LoadingAnimation from "@Atom/LoadingAnimation";
import InputField from "@Molecule/InputField";
import ImageUploadField from "@Molecule/ImageUploadField";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";

export default function EvaluationFormModal({
  data,
  handleClose,
  setSelectedButtonStepRecruitment,
  refetchDataAllCandidate,
  isReject,
}) {
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const [isLoadingData, setIsLoadingData] = useState(false);
  //   const [dataEvaluation, setDataEvaluation] = useState(null);
  const [scores, setScores] = useState([]);
  const [resultRemark, setResultRemark] = useState("");
  const scoreIndicators = [1, 2, 3, 4, 5];
  const [documentation, setDocumentation] = useState(null);
  const [selectedButton, setSelectedButton] = useState("");

  const refetchData = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getCandidateEvaluations(
        data?.data?.candidateId
      );
      //   setDataEvaluation(response);
      setScores(
        response?.evaluations?.map((el) => {
          return {
            ...el,
            id: el?._id,
            name: el?.label,
            value: "",
          };
        })
      );
      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [data?.data?.candidateId]);

  useEffect(() => {
    if (data?.name === "LOLOS") {
      refetchData();
    }
  }, [data?.name, refetchData]);

  const [validate, setValidate] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [rejectRemark, setRejectRemark] = useState("");

  const handleSend = async () => {
    try {
      if (
        !resultRemark ||
        !documentation ||
        scores?.find((obj) => !obj?.value) ||
        !selectedButton ||
        scores?.length === 0
      ) {
        if (!resultRemark) {
          setValidate((prev) => ({
            ...prev,
            resultRemark: true,
          }));
        } else {
          setValidate((prev) => ({
            ...prev,
            resultRemark: false,
          }));
        }

        if (!documentation) {
          setValidate((prev) => ({
            ...prev,
            documentation: true,
          }));
        } else {
          setValidate((prev) => ({
            ...prev,
            documentation: false,
          }));
        }

        if (scores?.length === 0) {
          setValidate((prev) => ({
            ...prev,
            values: true,
          }));
        } else {
          if (scores?.find((obj) => !obj?.value)) {
            setValidate((prev) => ({
              ...prev,
              values: true,
            }));
            //   scores?.forEach((el, idx) => {
            //     if (!el?.value) {
            //       setValidate((prev) => ({
            //         ...prev,
            //         [`values${idx}`]: true,
            //       }));
            //     } else {
            //       setValidate((prev) => ({
            //         ...prev,
            //         [`values${idx}`]: false,
            //       }));
            //     }
            //   });
          } else {
            setValidate((prev) => ({
              ...prev,
              values: false,
            }));
          }
        }

        if (!selectedButton) {
          setValidate((prev) => ({
            ...prev,
            selectedButton: true,
          }));
        } else {
          setValidate((prev) => ({
            ...prev,
            selectedButton: false,
          }));
        }
      } else {
        setSubmitting(true);
        const formData = new FormData();
        formData?.append("candidateID", data?.data?.candidateId);
        formData?.append("note", resultRemark);
        formData?.append("type", selectedButton);
        for (let i = 0; i < scores.length; i++) {
          formData?.append(`labels[${i}]`, scores[i]?.name);
          formData?.append(`scores[${i}]`, +scores[i]?.value);
        }

        formData?.append("file", documentation);
        await candidateEvaluationAccept(formData);
        setSubmitting(false);
        setIsSuccess(true);
      }
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
      console.log(err);
      setSubmitting(false);
    }
  };

  const [submittingReject, setSubmittingReject] = useState(false);
  const [errorReject, setErrorReject] = useState("");

  const handleReject = async () => {
    try {
      setSubmittingReject(true);
      await candidateEvaluationReject(data?.data?.candidateId, rejectRemark);
      setSubmittingReject(false);
      handleClose();
      refetchDataAllCandidate();
    } catch (err) {
      console.log(err);
      setErrorReject(err?.response?.data?.error || "Something went wrong");
      setSubmittingReject(false);
    }
  };

  if (isSuccess) {
    return (
      <div className={Styles.containerNotif}>
        <div>
          <div>
            <img src={Images.PRODUCT_QUALITY} alt="" />
          </div>
          <div>
            <span>{data?.data?.name || "-"} Berhasil Lolos Interview RO</span>
            <span>
              Kandidat {data?.data?.name || "-"} telah berhasil lolos dan telah
              dipindahkan ke{" "}
              {selectedButton === "Offering"
                ? "step 5."
                : "step 3 (undang interview klien)."}
            </span>
          </div>
        </div>

        <div>
          <div
            onClick={() => {
              handleClose();
              setIsSuccess(false);
              refetchDataAllCandidate();
            }}
          >
            Tutup
          </div>
          <div
            onClick={() => {
              if (selectedButton === "Offering") {
                setSelectedButtonStepRecruitment("Kirim Offering");
                // refetchDataAllCandidate();
                setIsSuccess(false);
                handleClose();
              } else {
                setSelectedButtonStepRecruitment("Undang Interview Klien");
                // refetchDataAllCandidate();
                setIsSuccess(false);
                handleClose();
              }
            }}
          >
            Lihat Kandidat
          </div>
        </div>
      </div>
    );
  }

  if (isReject) {
    return (
      <div className={Styles.containerReject}>
        <div className={Styles.content}>
          <img src={Images.RESULT_REJECTED_ICON} alt="" />
          <div className={Styles.desc}>
            <h3>Tidak Lolos</h3>
            <p>Tulis catatan mengapa kandidat tidak lolos</p>
          </div>

          <InputField
            type={"desc"}
            title={"Catatan"}
            placeholder={"Masukkan catatan anda"}
            value={rejectRemark}
            onChange={(e) => setRejectRemark(e?.target?.value)}
          />
        </div>

        <div className={Styles.buttonBox}>
          {errorReject && <span>*error</span>}
          <div className={Styles.actions}>
            <button onClick={handleClose}>Batal</button>
            <button
              disabled={!rejectRemark || submittingReject}
              onClick={submittingReject ? null : handleReject}
              // disabled={template?.disableButton()}
              // onClick={template?.buttonOnClick}
            >
              {!submittingReject ? "Proses" : "Loading..."}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.containerModal}>
      <div className={Styles.header}>
        <div>
          <span>Hasil Interview</span>
          <Icon
            icon={"cross"}
            className={Styles.iconCross}
            size={24}
            onClick={handleClose}
          />
        </div>

        <span>Harap isi seluruh kolom penilaian yang ada</span>
      </div>

      {isLoadingData ? (
        <div
          style={{
            // placeItems: 'center'
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      ) : (
        <div className={Styles.contents}>
          <div className={Styles.intro}>
            <div className={Styles.stackedIntro}>
              <div>
                <img src={Images.INVITE_USER} alt="name" />
              </div>
              <div>
                <span>Candidate</span>
                <span>{toTitleCase(data?.data?.name) || "-"}</span>
              </div>
            </div>
            <div className={Styles.stackedIntro}>
              <div>
                <img src={Images.INVITE_JOB} alt="name" />
              </div>
              <div>
                <span>Job</span>
                <span>{data?.data?.position || "-"}</span>
              </div>
            </div>
          </div>

          <div className={Styles.formBox}>
            <div className={Styles.scoring}>
              <span>Penilaian</span>
              <div
                className={`${Styles.card} ${
                  (validate?.values === true &&
                    scores?.find((obj) => !obj?.value)) ||
                  (validate?.values === true && scores?.length === 0)
                    ? Styles.redBorder
                    : ""
                }`}
              >
                {scores?.length === 0 ? (
                  <span>Penilaian belum ditemukan</span>
                ) : (
                  scores?.map((each) => (
                    <div key={each?.id} className={Styles.each}>
                      <span>{each?.name}</span>
                      <div className={Styles.indicators}>
                        {scoreIndicators?.map((s, i) => (
                          <div
                            key={i}
                            className={`${Styles.indicator} ${
                              each?.value === s ? Styles.active : ""
                            }`}
                            onClick={() => {
                              const newScores = [...scores];
                              const objIndex = scores?.findIndex(
                                (obj) => obj?.name === each?.name
                              );
                              newScores[objIndex].value = s;
                              setScores(newScores);
                            }}
                          >
                            {s}
                          </div>
                        ))}
                        <div className={Styles.line} />
                      </div>
                    </div>
                  ))
                )}
              </div>

              {(validate?.values === true &&
                scores?.find((obj) => !obj?.value)) ||
              (validate?.values === true && scores?.length === 0) ? (
                <span>Penilaian wajib diisi</span>
              ) : null}
            </div>

            <div className={Styles.desc}>
              <InputField
                isError={validate?.resultRemark === true && !resultRemark}
                type={"desc"}
                title={"Catatan"}
                placeholder={"Masukkan catatan anda"}
                value={resultRemark}
                onChange={(e) => setResultRemark(e?.target?.value)}
                errorText={"Catatan wajib diisi"}
              />
            </div>

            <div className={Styles.imagesBox}>
              <ImageUploadField
                title={"Foto Interview"}
                file={documentation}
                setFile={setDocumentation}
                withPreview
                isError={validate?.documentation === true && !documentation}
              />

              {validate?.documentation === true && !documentation ? (
                <span>Foto interview wajib diisi</span>
              ) : null}
            </div>

            <div className={Styles.stepper}>
              <span>Lanjutkan Kandidat ke Tahap</span>
              <div>
                <div
                  className={`${
                    validate?.selectedButton === true &&
                    !selectedButton &&
                    Styles.borderRed
                  }`}
                >
                  <CheckboxJobPosting
                    type={"radio-section"}
                    checked={selectedButton === "Interview Klien"}
                    onChange={() => {
                      if (selectedButton === "Interview Klien") {
                        setSelectedButton("");
                      } else {
                        setSelectedButton("Interview Klien");
                      }
                    }}
                  />

                  <span>Interview Klien</span>
                </div>

                <div
                  className={`${
                    validate?.selectedButton === true &&
                    !selectedButton &&
                    Styles.borderRed
                  }`}
                >
                  <CheckboxJobPosting
                    type={"radio-section"}
                    checked={selectedButton === "Offering"}
                    onChange={() => {
                      if (selectedButton === "Offering") {
                        setSelectedButton("");
                      } else {
                        setSelectedButton("Offering");
                      }
                    }}
                  />

                  <span>Offering (Tanpa interview Klien)</span>
                </div>
              </div>

              {validate?.selectedButton === true && !selectedButton ? (
                <span>Kelanjutan proses kandidat wajib diisi</span>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <div className={Styles.buttonBox}>
        {error && <span>*{error}</span>}

        <div className={Styles.buttonSection}>
          <div onClick={handleClose}>Batal</div>
          <div
            className={`${submitting && Styles.disabled}`}
            onClick={submitting ? null : handleSend}
          >
            {submitting ? "Loading..." : "Proses"}
          </div>
        </div>
      </div>
    </div>
  );
}
