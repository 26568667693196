import Styles from "./style.module.scss";
import BLUE_BUBBLE from "@Assets/Images/blue-bubble-bg.png";
// import THUMBNAIL from "@Assets/Images/irsyad-fajri.png";
import Icon from "@Atom/Icon";

const TipsAndTricksThumbnailOption = ({
  isSelected,
  onClick,
  image,
  ...props
}) => {
  return (
    <div
      className={isSelected ? Styles.selectedContainer : Styles.container}
      onClick={onClick}
    >
      <div className={Styles.thumbnail}>
        {isSelected && (
          <div className={Styles.tes}>
            <Icon size="20" icon="check-circle" className={Styles.icon} />
          </div>
        )}
        <div className={Styles.right}>
          <img alt="" src={BLUE_BUBBLE} />
          <div className={Styles.thumbnail}>
            <img alt="" src={image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipsAndTricksThumbnailOption;
