import { API, APIIOS } from "@Config/api";

export function getCandidates(
  limit,
  page,
  status,
  // stage,
  // search,
  // roID,
  clientCode,
  subStatus,
  recruiterID,
  positionName,
  cityCode,
  candidateID
) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("limit", limit);
    formData.append("status", status);
    if (subStatus?.length > 0) {
      for (let index = 0; index < subStatus?.length; index++) {
        const element = subStatus[index];
        formData.append(`subStatus[${index}]`, element || "");
      }
    } else {
      formData.append(`subStatus`, []);
    }

    formData.append("clientCode", clientCode || "");
    formData.append("recruiterID", recruiterID || "");
    formData.append("positionName", positionName || "");
    formData.append("cityCode", cityCode || "");
    formData.append("candidateID", candidateID || "");

    const queryString = new URLSearchParams(formData).toString();

    try {
      const { data } = await API.get(
        `/business-support/candidates?${queryString}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// /business-support/resend-contract

export function resendContract(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/business-support/resend-contract`,
        payload,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidatesSync(candidateName = "?") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/business-support/candidates-sync?candidateName=${candidateName}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getLatestCandidates(search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/business-support/candidates/latest?search=${search}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidateDetails(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/candidates/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCandidatesDataForExport(
  status,
  stage,
  search,
  type,
  startDate,
  endDate,
  docNumber,
  recuiterID
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/business-support/candidates/export?status=${status}&stage=${stage}&search=${search}&type=${type}&startDate=${startDate}&endDate=${endDate}&docNumber=${docNumber}&recuiterID=${recuiterID}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function verifyCandidate(tag, stage, id, remark, action) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/candidate-verification/${id}`,
        {
          tag: tag,
          stage: stage,
          remark,
          action,
        },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getContractSync(id, idsArr, contract) {
  return new Promise(async (resolve, reject) => {
    try {
      const c2cContract = idsArr
        .filter((el) => el.source === "C2C")
        .map((el) => el.template)
        .join(",");
      const k365additional = idsArr
        .filter((el) => el.source === "365")
        .map((el) => el.id)
        .join(",");
      if (idsArr?.length) {
        const { data } = await API.get(
          `/business-support/contract-sync/${id}?source=${contract.source}&contractName=${contract.name}&additionalID=${k365additional}&additionalName=${c2cContract}&contractTemplateID=${contract.id}&businessManagerID=${contract.businessManagerID}&contractType=${contract.contractType}`,
          {
            headers: {
              // Authorization: sessionStorage.getItem("accessToken"),
              Authorization: localStorage.getItem("accessToken"),
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            // params: payload
          }
        );
        resolve(data);
      } else {
        const { data } = await API.get(
          `/business-support/contract-sync/${id}?source=${contract.source}&contractName=${contract.name}&contractTemplateID=${contract.id}&businessManagerID=${contract.businessManagerID}&contractType=${contract.contractType}`,
          {
            headers: {
              // Authorization: sessionStorage.getItem("accessToken"),
              Authorization: localStorage.getItem("accessToken"),
            },
          }
        );
        resolve(data);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function generateContract(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/candidates-contract/${id}`,
        payload,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function requestApprovalBm(id, businessManagerID) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/business-support/request-approval-contract/${id}`,
        { businessManagerID: businessManagerID },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function rejectJoinCandidate(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/candidate/offer`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getContractListSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/template-sync`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data.response);
    } catch (error) {
      reject(error);
    }
  });
}

export function revertStage(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/revert-stage/${id}`,
        {},
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}


// Extend Contracts
export function getContractFromIOS(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await APIIOS.get(`/get-contract-365/${employeeId}`, {});
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getUMKFromIOS() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await APIIOS.get(`/get-umk`, {});
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getExtendCandidateDetail(employeeId) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/candidates-extend/${employeeId}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function generateContractExtend(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/candidates-contract-extend/${id}`,
        payload,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getExtendCandidates(
  limit,
  page,
  status,
  clientCode,
  subStatus,
  recruiterID,
  positionName,
  cityCode,
  candidateID,
  region,
  employeementStatus
) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("limit", limit);
    formData.append("status", status);
    if (subStatus?.length > 0) {
      for (let index = 0; index < subStatus?.length; index++) {
        const element = subStatus[index];
        formData.append(`subStatus[${index}]`, element || "");
      }
    } else {
      formData.append(`subStatus`, []);
    }

    formData.append("clientCode", clientCode || "");
    formData.append("recruiterID", recruiterID || "");
    formData.append("positionName", positionName || "");
    formData.append("cityCode", cityCode || "");
    formData.append("candidateID", candidateID || "");
    if (region?.length > 0) {
      for (let index = 0; index < region?.length; index++) {
        const element = region[index];
        formData.append(`region[${index}]`, element || "");
      }
    } else {
      formData.append(`region`, []);
    }
    formData.append("employeementStatus", employeementStatus || "");

    const queryString = new URLSearchParams(formData).toString();

    try {
      const { data } = await API.get(
        `/business-support/candidates?${queryString}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getExtendCandidatesSync(candidateName = "?") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/business-support/candidates-sync?employeementStatus=Extend&candidateName=${candidateName}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getExtendCandidatesDataForExport(
  status,
  stage,
  search,
  type,
  startDate,
  endDate,
  docNumber,
  recuiterID
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/business-support/candidates/export?status=${status}&stage=${stage}&search=${search}&type=${type}&startDate=${startDate}&endDate=${endDate}&docNumber=${docNumber}&recuiterID=${recuiterID}&employeementStatus=Extend`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}