import Images from "@Theme/Images";
import Styles from "./styles.module.scss";

export default function Zero({
  setCurrentStep,
  disableClick,
  offeringData,
  refetch,
}) {
  
  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <div className={Styles.topWrapper}>
          <div className={Styles.logoWrapper}>
            <img src={Images.INITIAL_LOGO} alt="logo" />
          </div>
          <span>PT Permata Indo Sejahtera</span>
        </div>
        <div className={Styles.box}>
          <img src={Images.CANDIDATE_FORM_BANNER} alt="banner" />
          <div className={Styles.textLeft}>
            <div className={Styles.nameOffer}>
              <h3>Halo...</h3>
            </div>
            <p>
              Selamat, selangkah lagi kamu akan segera bergabung bersama PT
              Permata Indo Sejahtera untuk posisi{" "}
              <span className={Styles.position}>
                {offeringData?.position?.name}.
              </span>{" "}
              Silahkan isi data diri anda untuk proses selanjutnya 🎉
            </p>
          </div>
        </div>
      </div>
      <div className={Styles.right}>
        <span className={Styles.H4}>
          Yuk! lanjut ke pengisian form untuk mempercepat proses rekrutmen kamu
        </span>
        <p>Klik tombol dibawah ini untuk melanjutkan ke pengisian formulir</p>
        <div className={Styles.buttonBox}>
          <button
            className={Styles.buttonNext}
            disabled={disableClick}
            onClick={() => setCurrentStep(1)}
          >
            {!disableClick ? "Selanjutnya" : "Form sudah diisi"}
          </button>
        </div>
      </div>
    </div>
  );
}
