import Textarea from '@Atom/Textarea'
import { InputTitle } from '@Molecule/InputField'
import Styles from './style.module.scss'

export default function TextareaField({
  title,
  required,
  value,
  onChange = () => { },
  placeholder = '',
  disabled = false,
  isError = false,
  errorText = '',

}) {

  return (
    <div className={Styles.container}>
      {
        title
        &&
        <InputTitle
          title={title}
          required={required}
        />
      }
      <Textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        isError={isError}
        errorText={errorText}
      />
    </div>
  )
}