import Icon from "@Atom/Icon";
import Switch from "@Atom/Switch";
import InputField from "@Molecule/InputField";
import Styles from "./style.module.scss";

export default function ContractInclude({
  name,
  isChecked = false,
  setIsChecked = () => {},
  filteredAdditionalValues,
  handleChangeValues,
  body,
}) {
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.SelectWrapper}>
        <Icon icon={"document"} size={20} color="#1571DE" />
        <span>{name}</span>
        <Switch isChecked={isChecked} setIsChecked={setIsChecked} />
      </div>
      <div
        className={`${Styles.InputContainer} ${
          isChecked && filteredAdditionalValues.length > 0
            ? Styles.ShowInput
            : ""
        }`}
      >
        <div>
          {filteredAdditionalValues.map((each, idx) => (
            <InputField
              key={idx}
              title={each.label}
              value={
                each?.codeLocal === "{POSITION_SGF}" ||
                each?.codeLocal === "{AUTO_MONTH_ROMAWI}" ||
                each?.codeLocal === "{BM_POSITION}" ||
                each?.codeLocal === "{POSITION}"
                  ? typeof each.value === "string"
                    ? each.value?.toUpperCase()
                    : each.value
                  : typeof each.value === "string"
                  ? toTitleCase(each.value)
                  : each.value
              }
              onChange={(e) => handleChangeValues(each, body, e?.target?.value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
