import SendModal from "@Organism/Recruiter/Recruitment/RecruitmentDetail/First/ListMode/Accepted/SendModal";
import {
  getCandidateRecommend,
  getClientRecommendation,
} from "@Services/officer/recruitment";
import { useCallback, useEffect, useMemo, useState } from "react";
import PreviewRecommended from "../PreviewRecommended";

export default function SendRecomendationPerCandidate({
  showInterviewRO,
  // handleDone={() => setInterviewRO(null)}
  setInterviewRO,
  showSendRecommended,
  setShowPreviewPerCandidate,
  showPreviewPerCandidate,
  refetchDataAllCandidate,
}) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const viewType = useMemo(() => {
    if (windowSize[0] > 768) {
      return "desktop";
    } else if (windowSize[0] <= 768) {
      return "mobile";
    }
  }, [windowSize]);

  const [isLoadingDataCandidate, setIsLoadingDataCandidate] = useState(false);
  const [dataCandidate, setDataCandidate] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isLoadingData, setIsloadingData] = useState(false);
  const [data, setData] = useState(null);

  const refetchData = useCallback(async () => {
    try {
      setIsloadingData(true);
      const { response } = await getClientRecommendation();
      setData(
        response?.clients
          ?.find((el) => el?.code === showInterviewRO?.data?.clientCode)
          ?.positions?.find(
            (position) => position?.id === showInterviewRO?.data?.positionID
          )
      );
      setIsloadingData(false);
    } catch (err) {
      setIsloadingData(false);
      console.log(err);
    }
  }, [showInterviewRO]);

  const refetchDataCandidate = useCallback(async () => {
    try {
      setIsLoadingDataCandidate(true);
      const { response } = await getCandidateRecommend(
        showInterviewRO?.data?.positionID,
        showInterviewRO?.data?.clientCode
      );
      setDataCandidate(response);
      setIsLoadingDataCandidate(false);
    } catch (err) {
      setIsLoadingDataCandidate(false);
      console.log(err);
    }
  }, [showInterviewRO?.data?.positionID, showInterviewRO?.data?.clientCode]);

  useEffect(() => {
    if (showInterviewRO?.name === "KIRIM REKOMENDASI PER CANDIDATE") {
      refetchDataCandidate();
      refetchData();
    }
  }, [showInterviewRO, refetchDataCandidate, refetchData]);

  useEffect(() => {
    if (dataCandidate) {
      setContacts(dataCandidate?.clientContacts || []);
    } else {
      setContacts([]);
    }
  }, [dataCandidate]);

  useEffect(() => {
    if (dataCandidate) {
      setSelectedCandidates(
        dataCandidate?.candidates?.filter(
          (el) => el?.id === showInterviewRO?.data?.candidateId
        ) || []
      );
    } else {
      setSelectedCandidates([]);
    }
  }, [dataCandidate, showInterviewRO]);

  //   console.log(showInterviewRO);

  if (showPreviewPerCandidate) {
    return (
      <PreviewRecommended
        handleClose={() => setShowPreviewPerCandidate(false)}
        selectedCandidates={selectedCandidates}
        selectedContact={selectedContact}
        selectedClient={{
          code: showInterviewRO?.data?.clientCode,
          name: showInterviewRO?.data?.company,
        }}
        selectedPosition={data || null}
        refetchDataAllCandidate={refetchDataAllCandidate}
        handleDone={() => setInterviewRO(null)}
        setShowSendRecommended={() => {}}
      />
    );
  }

  return (
    <SendModal
      selectedContact={selectedContact}
      setSelectedContact={setSelectedContact}
      setShowPreview={setShowPreviewPerCandidate}
      setShow={setInterviewRO}
      contacts={contacts}
      setContacts={setContacts}
      type={viewType}
      selectedClient={{
        code: showInterviewRO?.data?.clientCode,
        name: showInterviewRO?.data?.company,
      }}
      typeSender={"recommended"}
      isLoadingDataCandidate={isLoadingDataCandidate}
    />
  );
}
