import LoadingAnimation from "@Atom/LoadingAnimation";
// import { useDebounce } from "@Hooks/useDebounce";
// import useQuery from "@Hooks/useQuery";
import CandidateListLayout from "@Organism/BusinessSupport/Candidates/CandidateList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getCandidates } from "@Services/support/candidate";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import {
  Await,
  defer,
  useLoaderData,
  // useLocation,
  // useNavigate,
} from "react-router-dom";
import Styles from "./styles.module.scss";

export async function loader(route) {
  const packageCandidateListPromise = getCandidates(
    10,
    1,
    "Verifikasi Pertama",
    "",
    [],
    "",
    "",
    "",
    ""
  );
  // const packageCandidateSyncPromise = getCandidatesSync();

  return defer({
    packageCandidateList: packageCandidateListPromise,
    // packageCandidateSync: packageCandidateSyncPromise,
  });
}

export default function CandidateList() {
  const { packageCandidateList } = useLoaderData();

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Candidates</title>
      </Helmet>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageCandidateList}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageCandidateList) => (
              <CandidateListLayout
                initialData={packageCandidateList?.response}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
