import { InputTitle } from '@Molecule/InputField'
import Styles from './style.module.scss'
import Dropdown from '@Atom/DropDown'

export default function DropdownField({
  title,
  required,
  value,
  setValue = () => { },
  options = [],
  iconColor = '',
  placeholder = '',
  autoClose = false,
  isError = false,
  errorText = ''
}) {

  return (
    <div className={Styles.container}>
      {
        title
        &&
        <InputTitle
          title={title}
          required={required}
        />
      }
      <Dropdown
        value={value}
        setValue={setValue}
        options={options}
        iconColor={iconColor}
        placeholder={placeholder}
        autoClose={autoClose}
        isError={isError}
        errorText={errorText}
      />
    </div>
  )
}