import PipelineOrderContent from '@Organism/Manager/Pipeline/PipelineOrder/Content'
import PipelineOrderNavigator from '@Organism/Manager/Pipeline/PipelineOrder/Navigator'
import { Helmet } from 'react-helmet-async'
import Styles from './style.module.scss'
import { Await, defer, useLoaderData } from 'react-router-dom';
import { getPipelineOrder } from '@Services/manager/pipeline';
import { Suspense } from 'react';
import LoadingAnimation from '@Atom/LoadingAnimation';
import AsyncErrorBoundary from '@Organism/Utils/AsyncErrorBoundary';

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const order = params?.get("o");
  const clientCode = params?.get("c");

  const fixedOrder = order?.replace(/-/g, '&order=')

  const orderQuery = '?order=' + fixedOrder

  const pipelineOrderPromise = getPipelineOrder(clientCode, orderQuery)

  return defer({
    packagePipelineOrder: pipelineOrderPromise,
  });
}

export default function PipelineOrder() {
  const { packagePipelineOrder } = useLoaderData()

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Pipeline</title>
      </Helmet>
      <div className={Styles.navigatorWrapper}>
        <PipelineOrderNavigator />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }>
          <Await
            resolve={packagePipelineOrder}
            errorElement={
              <div style={{ height: 'calc(100vh - 220px)' }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packagePipelineOrder) => (
              <PipelineOrderContent
                data={packagePipelineOrder?.response?.client}
              />

            )}

          </Await>

        </Suspense>
      </div>
    </div>
  );
}
