import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import InputDate from "@Atom/InputDate";
import SearchBar from "@Atom/SearchBar";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function JobPostingListHeader({
  data,
  trigger,
  setShowAction,
  showAction,
}) {
  const query = useQuery();
  const searchTermQuery = query.get("s") || "";
  const sortQuery = query.get("sr") || "";
  const statusQuery = query.get("st") || "";
  const dateFromQuery = query.get("df")
    ? moment(new Date(query?.get("df")))?.format("YYYY-MM-DD")
    : "";
  const dateToQuery = query.get("dt")
    ? moment(new Date(query?.get("dt")))?.format("YYYY-MM-DD")
    : "";
  const pageQuery = query.get("p") || "1";
  const [page, setPage] = useState(pageQuery);
  const [sort, setSort] = useState(sortQuery);
  const [status, setStatus] = useState(statusQuery);
  const [dateFrom, setDateFrom] = useState(dateFromQuery);
  const [dateTo, setDateTo] = useState(dateToQuery);
  const [searchTerm, setSearchTerm] = useState(searchTermQuery);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const range = useMemo(() => {
    if (data) {
      return `${(+pageQuery - 1) * 15 + 1} - ${
        (+pageQuery - 1) * 15 + data?.data?.length
      }`;
    } else {
      return "0 - 0";
    }
  }, [data, pageQuery]);

  const debouncedSearch = useDebounce(searchTerm, 300);

  useEffect(() => {
    navigate(
      `${pathname}?p=${page}&s=${debouncedSearch}&sr=${sort}&st=${status}&tr=${trigger}&df=${
        dateFrom ? moment(new Date(dateFrom))?.format("YYYY-MM-DD") : ""
      }&dt=${dateTo ? moment(new Date(dateTo))?.format("YYYY-MM-DD") : ""}`,
      { replace: true }
    );
  }, [
    dateFrom,
    dateTo,
    debouncedSearch,
    navigate,
    page,
    pathname,
    sort,
    status,
    trigger,
  ]);

  // console.log(data, "test");

  // useEffect(() => {
  //   setDateFrom(dateFromQuery)
  // }, [dateFromQuery])

  return (
    <div
      className={Styles.container}
      onClick={() => {
        if (showAction) {
          setShowAction("");
        }
      }}
    >
      {/* <h1>Job Posting</h1> */}
      <div>
        {/* <span>hhahha</span> */}
        <div className={Styles.top}>
          <span className={Styles.titleResponsive}>Job Posting</span>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              placeholder={"Cari job posting"}
              onChange={(e) => setSearchTerm(e?.target?.value)}
            />
          </div>
          <div className={Styles.paging}>
            <span>
              {range} dari {data?.totalData || 0}
            </span>
            <button
              disabled={+pageQuery === 1}
              onClick={() => setPage(+pageQuery - 1)}
            >
              <Icon icon={"arrow-left"} size={24} />
            </button>
            <button
              disabled={+pageQuery === data?.totalPage}
              onClick={() => setPage(+pageQuery + 1)}
            >
              <Icon icon={"arrow-right"} size={24} />
            </button>
          </div>
        </div>
        <div className={Styles.bottom}>
          <div className={Styles.left}>
            <div className={Styles.dropdownBox}>
              <Dropdown
                value={sort}
                setValue={setSort}
                placeholder={"Sortir"}
                autoClose
                withIcon
                iconName={"sort"}
                options={[
                  {
                    name: "latest",
                  },
                  {
                    name: "oldest",
                  },
                ]}
              />
            </div>
            <div className={Styles.dropdownBox}>
              <Dropdown
                value={status}
                setValue={setStatus}
                placeholder={"Status"}
                autoClose
                withIcon
                withReset
                iconName={"flag"}
                options={[
                  {
                    name: "Active",
                  },
                  {
                    name: "Inactive",
                  },
                  {
                    name: "Draft",
                  },
                  {
                    name: "Publish",
                  },
                  {
                    name: "Unpublish",
                  },
                ]}
              />
            </div>
            <div className={Styles.dropdownBox}>
              <InputDate
                value={dateFrom}
                setValue={setDateFrom}
                placeholder={"Dari"}
              />
            </div>
            <div className={Styles.dropdownBox}>
              <InputDate
                value={dateTo}
                setValue={setDateTo}
                placeholder={"Ke"}
              />
            </div>
          </div>

          <div className={Styles.searchBoxResp}>
            <div className={Styles.searchbarResponsive}>
              <SearchBar
                value={searchTerm}
                placeholder={"Cari job posting"}
                onChange={(e) => setSearchTerm(e?.target?.value)}
              />
            </div>
          </div>

          <div className={Styles.descCard}>
            <div>
              <div>
                <div></div>
                <span>Job Posting Saya</span>
              </div>

              <div>
                <div></div>
                <span>Job Posting Harus Dilengkapi</span>
              </div>
            </div>

            <div>
              <div>
                <div></div>
                <span>Job Posting Bayangan</span>
              </div>

              <div>
                <div></div>
                <span>Job Posting Lama</span>
              </div>
            </div>
          </div>

          <div className={Styles.paging}>
            <span>
              {range} dari {data?.totalData || 0}
            </span>
            <button
              disabled={+pageQuery === 1}
              onClick={() => setPage(+pageQuery - 1)}
            >
              <Icon icon={"arrow-left"} size={24} />
            </button>
            <button
              disabled={+pageQuery === data?.totalPage}
              onClick={() => setPage(+pageQuery + 1)}
            >
              <Icon icon={"arrow-right"} size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
