import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import InputYearField from "@Molecule/InputYearField";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function JobData({ data, form, setForm, errorField }) {
  var index = useMemo(() => {
    return form.findIndex((x) => x?.id === data?.id);
  }, [data, form]);

  const [startingYearTemp, setStartingYearTemp] = useState(
    form[index]?.startYear
  );
  const [finishingYearTemp, setFinishingYearTemp] = useState(
    form[index]?.endYear
  );

  useEffect(() => {
    setStartingYearTemp(form[index]?.startYear);
    setFinishingYearTemp(form[index]?.endYear);
  }, [form, index]);

  const handleChange = useCallback(
    (e, inputName) => {
      setForm([
        ...form?.slice(0, index),
        Object.assign({}, form[index], {
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        }),
        ...form?.slice(index + 1),
      ]);
    },
    [index, form, setForm]
  );

  useEffect(() => {
    setForm([
      ...form?.slice(0, index),
      Object.assign({}, form[index], {
        startYear: startingYearTemp,
        endYear: finishingYearTemp,
      }),
      ...form?.slice(index + 1),
    ]);

    // eslint-disable-next-line
  }, [index, startingYearTemp, finishingYearTemp]);

  const handleDelete = useCallback(() => {
    const newForm = [...form];
    newForm?.splice(index, 1);
    setForm(newForm);
  }, [form, setForm, index]);

  return (
    <div className={Styles.container}>
      <h3>Data Pekerjaan {index + 1}</h3>
      <div className={Styles.content}>
        <InputYearField
          title={"Dari Tahun"}
          placeholder={"Pilih tahun"}
          value={startingYearTemp}
          setValue={setStartingYearTemp}
          iconColor={"#1571DE"}
          isError={errorField && !startingYearTemp}
          errorText={"Tahun belum diisi"}
        />
        <InputYearField
          title={"Sampai Tahun"}
          placeholder={"Pilih tahun"}
          value={finishingYearTemp}
          setValue={setFinishingYearTemp}
          iconColor={"#1571DE"}
          isError={errorField && !finishingYearTemp}
          errorText={"Tahun belum diisi"}
        />
        <InputField
          title={"Nama Perusahaan"}
          placeholder={"Nama perusahaan "}
          value={data?.companyName}
          onChange={(e) => handleChange(e, "companyName")}
          isError={errorField && !data?.companyName}
          errorText={"Nama perusahaan belum diisi"}
        />
        <InputField
          title={"Jabatan"}
          placeholder={"Jabatan anda"}
          value={data?.position}
          onChange={(e) => handleChange(e, "position")}
          isError={errorField && !data?.position}
          errorText={"Jabatan belum diisi"}
        />
      </div>
      <div className={Styles.descWrapper}>
        <InputField
          title={"Deskripsi Pekerjaan"}
          placeholder={"Tulis deskripsi pekerjaan anda"}
          type={"desc"}
          value={data?.description}
          onChange={(e) => handleChange(e, "description")}
          isError={errorField && !data?.description}
          errorText={"Deskripsi belum diisi"}
        />
      </div>
      <button onClick={handleDelete}>
        <Icon icon="delete" size={20} />
        <span>Hapus</span>
      </button>
    </div>
  );
}
