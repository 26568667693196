import Icon from "@Atom/Icon";
import styled from "@emotion/styled";
import { Autocomplete, TextField, autocompleteClasses } from "@mui/material";
import { useEffect, useState } from "react";
import Styles from "./inputDate.module.scss";



const InputYear = ({ value, setValue, iconColor, placeholder, disabled, isError = false,
  errorText = '',
  ...props }) => {

  const MuiTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      color: '#0a0a0a',
      fontSize: '14px',
      fontWeight: 400,
      background: '#ffffff',
      borderRadius: 8,
      padding: '8px 12px',

      '& .MuiAutocomplete-input': {
        padding: 0
      },
      "& fieldset": {
        borderRadius: 8,
        border: `1px solid ${isError ? '#F5610D' : '#E0E0E0'} `,
      },
      '&:hover fieldset': {
        border: `1px solid ${isError ? '#F5610D' : '#E0E0E0'} `,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${isError ? '#F5610D' : '#1571DE'} `,
        padding: '8px 12px',
      }
    },

    '& .MuiOutlinedInput-root.Mui-disabled': {
      background: '#EDEDED',
      "& fieldset": {
        borderRadius: 8,
        border: '1px solid #E0E0E0',
      },
    },

    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '20px',
      color: '#757575!important',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '30px',
      padding: 0,
    },

  });

  function generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max - 50
    var years = []

    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  const [yearOptions, setYearOptions] = useState([])
  const yearTemp = generateArrayOfYears()

  useEffect(() => {
    if (yearTemp?.length) {
      const newArr = []
      for (var i = 0; i < yearTemp?.length; i++) {
        newArr.push(yearTemp[i]?.toString())
      }
      setYearOptions(newArr)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={Styles.container}>
      <div className={Styles.inputWrapper}>
        <Autocomplete
          disablePortal
          freeSolo
          forcePopupIcon
          // autoSelect
          selectOnFocus
          clearOnBlur
          getOptionDisabled={(option) => option && option.toLowerCase() === 'no option'}
          filterOptions={(options, state) => {
            let newOptions = [];
            options?.forEach((element) => {
              if (
                element
                  ?.replace(",", "")
                  ?.toLowerCase()
                  ?.includes(state?.inputValue?.toLowerCase())
              )
                newOptions.push(element);
            });
            if (newOptions.length) {
              return newOptions;
            } else {
              return ['no option']
            }
          }}
          id="combo-box-demo"
          isOptionEqualToValue={(option, value) => option === value}
          value={value || ''}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          options={yearOptions}
          popupIcon={<Icon icon="calendar-v2" size={20} color={iconColor ? (!disabled ? iconColor : '#757575') : '#0a0a0a'} />}
          sx={{
            width: '100%',
            [`& .${autocompleteClasses.popupIndicator}`]: {
              transform: "none"
            }
          }}
          renderInput={(params) => (
            <MuiTextField {...params} placeholder={placeholder || 'Select'} />
          )}
          disabled={disabled}
        />
      </div>
      {
        isError && !disabled && <span className={Styles.errorText}>{errorText}</span>
      }
    </div>
  );
};

export default InputYear;
