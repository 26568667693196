import { useEffect, useRef } from "react";
import Styles from "./styles.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import loadingAnimationData from "@Assets/Lotties/loading.json";
import Lottie from "lottie-react";

export default function NotifJobPosting({
  handleDone,
  labelStatus,
  labelWarning,
  handleCancel,
  handleSave,
  isloadingUpdate,
}) {
  const boxRef = useRef();

  const boxOutsideClick = UseOutsideClick(boxRef);
  useEffect(() => {
    if (boxOutsideClick) {
      handleDone();
    }
  }, [boxOutsideClick, handleDone]);

  return (
    <div className={Styles.container}>
      <div className={Styles.modalNotif} ref={boxRef}>
        {isloadingUpdate ? (
          <div className={Styles.animationWrapper}>
            <Lottie
              animationData={loadingAnimationData}
              height={30}
              width={30}
            />
          </div>
        ) : (
          <>
            {" "}
            <div className={Styles.topNotif}>
              <span className={Styles.warningText}>{labelStatus} Post</span>
              <span className={Styles.textP}>
                Apa kamu yakin untuk {labelWarning} job posting ini?
              </span>
            </div>
            <div className={Styles.buttonBox}>
              <div onClick={handleCancel} className={Styles.cancel}>
                Batal
              </div>
              <div onClick={handleSave} className={Styles.save}>
                Ya, {labelStatus}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
