import AutocompleteField from "@Molecule/AutocompleteField";
import InputDateField from "@Molecule/InputDateField";
import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function MemberData({
  data,
  form,
  setForm,
  options,
  errorField,
}) {
  var index = useMemo(() => {
    return form.findIndex((x) => x?.id === data?.id);
  }, [data, form]);

  const [relationshipTemp, setRelationshipTemp] = useState(
    form[index]?.relationship
  );
  const [birthDateTemp, setBirthDateTemp] = useState(form[index]?.birthDate);
  const [genderTemp, setGenderTemp] = useState(form[index]?.gender);

  useEffect(() => {
    setRelationshipTemp(form[index]?.relationship);
    setBirthDateTemp(form[index]?.birthDate);
    setGenderTemp(form[index]?.gender);
  }, [form, index]);

  const handleChange = useCallback(
    (e, inputName) => {
      if (inputName === "nik") {
        const numericValue = /^[0-9]*$/;
        if (!numericValue?.test(e.target.value)) {
          // Jika nilai tidak valid, kita tidak mengubah state
          return;
        }
        setForm([
          ...form?.slice(0, index),
          Object.assign({}, form[index], {
            [inputName]: !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase(),
          }),
          ...form?.slice(index + 1),
        ]);
      } else {
        setForm([
          ...form?.slice(0, index),
          Object.assign({}, form[index], {
            [inputName]: !isNaN(e.target.value)
              ? e.target.value
              : e.target.value.toUpperCase(),
          }),
          ...form?.slice(index + 1),
        ]);
      }
    },
    [index, form, setForm]
  );

  useEffect(() => {
    setForm([
      ...form?.slice(0, index),
      Object.assign({}, form[index], {
        relationship: relationshipTemp,
        birthDate: birthDateTemp,
        gender: genderTemp,
      }),
      ...form?.slice(index + 1),
    ]);

    // eslint-disable-next-line
  }, [index, relationshipTemp, birthDateTemp, genderTemp]);

  // const handleDelete = useCallback(() => {
  //   const newForm = [...form]
  //   newForm?.splice(index, 1)
  //   setForm(newForm)
  // }, [form, setForm, index])

  return (
    <div className={Styles.container}>
      {/* <span className={Styles.textH4}>Anggota Keluarga {index + 1}</span> */}
      {!!data?.isSelf && <span className={Styles.textH3}>Data Diri Anda</span>}
      {!data?.isSelf && (
        <span className={Styles.textH3}>Data Keluarga {index}</span>
      )}
      {!data?.isSelf && (
        <div className={Styles.contentNotSelf}>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <AutocompleteField
                title={"Hubungan Keluarga"}
                placeholder={"Hubungan keluarga"}
                searchOptions={options?.relationships?.filter(
                  (el) =>
                    el?.toLowerCase().includes("suami") ||
                    el?.toLowerCase() === "anak" ||
                    el?.toLowerCase().includes("istri")
                )}
                value={relationshipTemp}
                setValue={setRelationshipTemp}
                iconColor={"#1571DE"}
                isError={errorField && !relationshipTemp}
                errorText={"Hubungan belum diisi"}
              />
            </div>
            <div className={Styles.input}>
              <InputField
                title={"Nama"}
                placeholder={"Nama anggota keluarga"}
                value={data?.name}
                onChange={(e) => handleChange(e, "name")}
                // disabled={data?.isSelf}
                isError={errorField && !data?.name}
                errorText={"Nama belum diisi"}
              />
            </div>
          </div>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <InputField
                title={"Tempat Lahir"}
                placeholder={"Tempat Lahir"}
                value={data?.birthPlace}
                onChange={(e) => handleChange(e, "birthPlace")}
                // disabled={data?.isSelf}
                isError={errorField && !data?.birthPlace}
                errorText={"Tempat lahir belum diisi"}
              />
            </div>
            <div className={Styles.input}>
              <InputDateField
                title={"Tanggal Lahir"}
                // placeholder={"Tanggal Lahir"}
                value={birthDateTemp || data?.birthDate}
                setValue={setBirthDateTemp}
                iconColor={"#1571DE"}
                // readOnly={data?.isSelf}
                isError={errorField && !data?.birthDate}
                errorText={"Tanggal lahir belum diisi"}
              />
            </div>
          </div>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <InputField
                title={"NIK"}
                placeholder={"000"}
                value={data?.nik}
                onChange={(e) => {
                  if (e.target.value.length < 17) {
                    handleChange(e, "nik");
                  }
                }}
                // disabled={data?.isSelf}
                isError={errorField && !data?.nik}
                errorText={"NIK belum diisi"}
              />
            </div>
            <div className={Styles.input}>
              <AutocompleteField
                title={"Jenis Kelamin"}
                placeholder={"Pilih jenis kelamin"}
                searchOptions={options?.genders}
                value={genderTemp}
                setValue={setGenderTemp}
                iconColor={"#1571DE"}
                // disabled={data?.isSelf}
                isError={errorField && !genderTemp}
                errorText={"Jenis Kelamin belum diisi"}
              />
            </div>
          </div>
        </div>
      )}

      {data?.isSelf && (
        <div className={Styles.contentNotSelf}>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <InputField
                title={"Nama"}
                placeholder={"Nama anggota keluarga"}
                value={data?.name}
                onChange={(e) => handleChange(e, "name")}
                disabled={data?.isSelf}
              />
            </div>
          </div>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <InputField
                title={"Tempat Lahir"}
                placeholder={"Tempat Lahir"}
                value={data?.birthPlace}
                onChange={(e) => handleChange(e, "birthPlace")}
                disabled={data?.isSelf}
              />
            </div>
            <div className={Styles.input}>
              <InputDateField
                title={"Tanggal Lahir"}
                placeholder={"Tanggal Lahir"}
                value={birthDateTemp || data?.birthDate}
                setValue={setBirthDateTemp}
                iconColor={"#1571DE"}
                readOnly={data?.isSelf}
              />
            </div>
          </div>
          <div className={Styles.top}>
            <div className={Styles.input}>
              <InputField
                title={"NIK"}
                placeholder={"000"}
                value={data?.nik}
                onChange={(e) => {
                  if (e.target.value.length < 17) {
                    handleChange(e, "nik");
                  }
                }}
                disabled={data?.isSelf}
              />
            </div>
            <div className={Styles.input}>
              <AutocompleteField
                title={"Jenis Kelamin"}
                placeholder={"Pilih jenis kelamin"}
                searchOptions={options?.genders}
                value={genderTemp}
                setValue={setGenderTemp}
                iconColor={"#1571DE"}
                disabled={data?.isSelf}
              />
            </div>
          </div>
        </div>
      )}
    </div>
    // <div className={Styles.container}>
    //   {/* <h3>Anggota Keluarga {index + 1}</h3> */}
    //   {!!data?.isSelf && <h3>Data Diri Anda</h3>}
    //   {!data?.isSelf && <h2>Data Keluarga {index}</h2>}
    //   <div className={Styles.content}>
    //     <div className={`${Styles.top} ${data?.isSelf ? Styles.full : ""}`}>
    //       {!!!data?.isSelf && (
    //         <AutocompleteField
    //           title={"Hubungan Keluarga"}
    //           placeholder={"Hubungan keluarga"}
    //           searchOptions={options?.relationships}
    //           value={relationshipTemp}
    //           setValue={setRelationshipTemp}
    //           iconColor={"#1571DE"}
    //         />
    //       )}

    //       <InputField
    //         title={"Nama"}
    //         placeholder={"Nama anggota keluarga"}
    //         value={data?.name}
    //         onChange={(e) => handleChange(e, "name")}
    //         disabled={data?.isSelf}
    //       />
    //     </div>
    //     <div className={Styles.rest}>
    //       <InputField
    //         title={"Tempat Lahir"}
    //         placeholder={"Tempat Lahir"}
    //         value={data?.birthPlace}
    //         onChange={(e) => handleChange(e, "birthPlace")}
    //         disabled={data?.isSelf}
    //       />
    //       <InputDateField
    //         title={"Tanggal Lahir"}
    //         placeholder={"Tanggal Lahir"}
    //         value={birthDateTemp || data?.birthDate}
    //         setValue={setBirthDateTemp}
    //         iconColor={"#1571DE"}
    //         readOnly={data?.isSelf}
    //       />
    //       <InputField
    //         title={"NIK"}
    //         placeholder={"000"}
    //         value={data?.nik}
    //         onChange={(e) => {
    //           if (e.target.value.length < 17) {
    //             handleChange(e, "nik");
    //           }
    //         }}
    //         disabled={data?.isSelf}
    //       />
    //       <AutocompleteField
    //         title={"Jenis Kelamin"}
    //         placeholder={"Pilih jenis kelamin"}
    //         searchOptions={options?.genders}
    //         value={genderTemp}
    //         setValue={setGenderTemp}
    //         iconColor={"#1571DE"}
    //         disabled={data?.isSelf}
    //       />
    //     </div>
    //   </div>
    //   {/* <button onClick={handleDelete}>
    //     <Icon icon="delete" size={20} />
    //     <span>Hapus</span>
    //   </button> */}
    // </div>
  );
}
