/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import useQuery from "@Hooks/useQuery";
import debounce from "lodash.debounce";
import FirstStep from "./First";
import SecondStep from "./Second";
import ThirdStep from "./Third";
import FourthStep from "./Fourth";
import FifthStep from "./Fifth";
import SixthStep from "./Sixth";
import SeventhStep from "./Seventh";
import { updateOrder } from "@Services/manager/pipeline";
import { editOrderAsDraft } from "@Services/manager/client";

const bankOptions = [
  {
    name: "BCA",
    number: "1783050505",
  },
  {
    name: "BNI",
    number: "0195900064",
  },
  // {
  //   name: 'BNI CONTIGENCY',
  //   number: '8000177898'
  // },
  {
    name: "BRI",
    number: "037601000660300",
  },
  {
    name: "BTPN",
    number: "4843000059",
  },
  {
    name: "CIMB NIAGA",
    number: "800007679100",
  },
  {
    name: "DANAMON",
    number: "000071968697",
  },
  {
    name: "BANK DBS IND",
    number: "3320129862",
  },
  {
    name: "DKI",
    number: "41208000408",
  },
  {
    name: "MANDIRI",
    number: "1280004585938",
  },
  {
    name: "MANDIRI MCM",
    number: "0700006667153",
  },
  {
    name: "MAYBANK",
    number: "2026165210",
  },
  {
    name: "MEGA",
    number: "010940011002145",
  },
  {
    name: "MNC BANK",
    number: "100010000103026",
  },
  {
    name: "NOBU BANK",
    number: "17830003001",
  },
  {
    name: "PERMATA",
    number: "701583183",
  },
  {
    name: "SAMPOERNA",
    number: "1021555106",
  },
  {
    name: "SINARMAS",
    number: "0045791556",
  },
  {
    name: "OCBC NISP",
    number: "417800003234",
  },
];

export default function PipelineOrderDetailsContent({
  syncData,
  defaultOrderForm,
  steps,
  setSteps,
  currentStep,
  setCurrentStep,
  setMaxStep,
  setContractType,
  setIsCompleted,
}) {
  const [trigger, setTrigger] = useState(null);

  const defaultContractForm = useMemo(() => {
    return {
      contract: defaultOrderForm?.contractType?.contractType,
    };
  }, [defaultOrderForm]);
  const defaultWorkSchemeForm = useMemo(() => {
    const workSchemeDefaults = defaultOrderForm?.workScheme;
    const manfeeArr = workSchemeDefaults?.PengenaanManfee?.split("::")?.map(
      (each) => {
        return { name: each };
      }
    );
    const tkoBanksArr = workSchemeDefaults?.RekeningWajibTKO?.split("::");
    return {
      taxs: {
        pph21:
          workSchemeDefaults?.PPH21 && workSchemeDefaults?.PPH21 !== "null"
            ? workSchemeDefaults?.PPH21
            : null,
        pph23:
          workSchemeDefaults?.PPH23 && workSchemeDefaults?.PPH23 !== "null"
            ? workSchemeDefaults?.PPH23
            : null,
        ppn:
          workSchemeDefaults?.PPN && workSchemeDefaults?.PPN !== "null"
            ? workSchemeDefaults?.PPN
            : null,
      },
      bpjs: {
        registration:
          workSchemeDefaults?.PendaftaranBPJS &&
          workSchemeDefaults?.PendaftaranBPJS !== "null"
            ? workSchemeDefaults?.PendaftaranBPJS
            : null,
        paymentProof:
          workSchemeDefaults?.BuktiPendaftaranBPJS &&
          workSchemeDefaults?.BuktiPendaftaranBPJS !== "null"
            ? workSchemeDefaults?.BuktiPendaftaranBPJS
            : null,
        jkk:
          workSchemeDefaults?.JKK && workSchemeDefaults?.JKK !== "null"
            ? workSchemeDefaults?.JKK
            : null,
        jkm:
          workSchemeDefaults?.JKM && workSchemeDefaults?.JKM !== "null"
            ? workSchemeDefaults?.JKM
            : null,
        jht:
          workSchemeDefaults?.JHT && workSchemeDefaults?.JHT !== "null"
            ? workSchemeDefaults?.JHT
            : null,
        health:
          workSchemeDefaults?.KS && workSchemeDefaults?.KS !== "null"
            ? workSchemeDefaults?.KS
            : null,
        akhdk:
          workSchemeDefaults?.AKDHK && workSchemeDefaults?.AKDHK !== "null"
            ? workSchemeDefaults?.AKDHK
            : null,
        jp:
          workSchemeDefaults?.JP && workSchemeDefaults?.JP !== "null"
            ? workSchemeDefaults?.JP
            : null,
        cutOffBPJSTKO: workSchemeDefaults?.PotonganBPJSTKO || null,
      },
      others: {
        manfee: manfeeArr || [],
        thr: workSchemeDefaults?.PenagihanTHR || null,
        uak: workSchemeDefaults?.PenagihanUAK || null,
        tkoBank: tkoBanksArr || [],
      },
    };
  }, [defaultOrderForm]);
  const defaultAdditionalForm = undefined;

  const defaultManPowerForm = useMemo(() => {
    const arr = [];
    const manPowerDefaults = defaultOrderForm?.manPowers;
    const cities = syncData?.cities;

    for (let i = 0; i < manPowerDefaults.length; i++) {
      const perLocation = manPowerDefaults[i];
      const city = cities?.find((c) => c?.code === perLocation?.cityCode);
      const areaEquipments = perLocation?.areaEquipments?.map((obj) => {
        return {
          id: obj?.id,
          name: obj?.name,
          value: obj?.price,
        };
      });
      let each = {
        id: perLocation?.cityCode,
        location: perLocation?.cityCode,
        umk: city?.umk || 0,
        jobs: [],
        areaWorkEquipments: areaEquipments || [],
      };
      const jobArr = [];
      for (let j = 0; j < perLocation?.manPowers?.length; j++) {
        const job = perLocation?.manPowers[j];
        const allowances = job?.allowances?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
          };
        });
        const personalEquipments = job?.personalEquipments?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.price,
          };
        });
        const qualifications = job?.qualifications?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
          };
        });
        const eachJob = {
          id: job?.id,
          title: job?.name,
          contractType: job?.contractType || null,
          salary: `${job?.salary}`?.split("::")[0] || "",
          salaryPeriod: `${job?.salary}`?.split("::")[1] || "",
          personalEquipments: personalEquipments || [],
          allowances: allowances || [],
          demand: +job?.amount,
          fulfillmentDate: job?.expectedFulfillmentDate || null,
          qualifications: qualifications || [],
        };
        jobArr.push(eachJob);
      }
      each.jobs = jobArr;
      arr.push(each);
    }

    return arr;
  }, [syncData, defaultOrderForm]);

  const defaultManfee = useMemo(() => {
    return defaultOrderForm?.paymentMethodEstimation?.setManfee;
  }, [defaultOrderForm]);
  const defaultTOP = useMemo(() => {
    return defaultOrderForm?.paymentMethodEstimation?.setTop;
  }, [defaultOrderForm]);
  const defaultRemark = defaultOrderForm?.remark || null;
  const defaultFiles = undefined;

  const [contractForm, setContractForm] = useState({
    contract: defaultContractForm?.contract
      ? defaultContractForm?.contract
      : null,
  });

  const onChangeStep1 = (type) => {
    setContractForm((prev) => {
      return {
        ...prev,
        contract: type?.title,
      };
    });

    setContractType(type);
  };

  const contractTypeOptions = useMemo(() => {
    let arr = [];
    const options = syncData?.options;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (
        option?.type === "contractTypeClient" &&
        option?.value === "Jasa Pemborongan"
      ) {
        arr.push({
          type: "PEMBORONGAN",
          title: option?.value,
          label: "PPN Dari Total Biaya",
          rules: [
            {
              id: 11,
              desc: "Status Karyawan PKWT atau Mitra",
            },
            {
              id: 12,
              desc: "PPh 21 dihitung dari penghasilan per bulan",
            },
            {
              id: 13,
              desc: "BPJS Kesehatan dihitung dari bantuan operasional (minimum UMP)",
            },
            {
              id: 14,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, atau JKK & JKM Dihitung dari bantuan operasional",
            },
            {
              id: 15,
              desc: "Manajemen Fee dihitung dari selisih nilai kontrak dengan biaya yang dikeluarkan",
            },
          ],
        });
      } else if (
        option?.type === "contractTypeClient" &&
        option?.value === "Penyedia Jasa Tenaga Kerja"
      ) {
        arr.push({
          type: "PJTK",
          title: option?.value,
          label: "PPN dari Manfee & PPN Fasilitas dari Total",
          rules: [
            {
              id: 21,
              desc: "Status Karyawan PKWT",
            },
            {
              id: 22,
              desc: "PPH 21 dihitung dari penghasilan yang disetahunkan",
            },
            {
              id: 23,
              desc: "BPJS Kesehatan dihitung dari Gapok + Tunjangan tetap (minimum UMP)",
            },
            {
              id: 24,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, JP Dihitung dari Gapok + Tunjangan tetap",
            },
            {
              id: 25,
              desc: "Manajemen Fee dihitung dari prosentase atau nominal yang disepakati",
            },
          ],
        });
      }
    }
    return arr;
  }, [syncData]);

  const firstStepDoneIndicator = useMemo(() => {
    if (contractForm?.contract) {
      return true;
    }
    return false;
  }, [contractForm]);

  const [files, setFiles] = useState(defaultFiles || []);

  const [workSchemeForm, setWorkSchemeForm] = useState({
    taxs: {
      pph21: defaultWorkSchemeForm?.taxs?.pph21
        ? defaultWorkSchemeForm?.taxs?.pph21
        : null,
      pph23: defaultWorkSchemeForm?.taxs?.pph23
        ? defaultWorkSchemeForm?.taxs?.pph23
        : null,
      ppn: defaultWorkSchemeForm?.taxs?.ppn
        ? defaultWorkSchemeForm?.taxs?.ppn
        : null,
    },
    bpjs: {
      registration: defaultWorkSchemeForm?.bpjs?.registration
        ? defaultWorkSchemeForm?.bpjs?.registration
        : null,
      paymentProof: defaultWorkSchemeForm?.bpjs?.paymentProof
        ? defaultWorkSchemeForm?.bpjs?.paymentProof
        : null,
      jkk: defaultWorkSchemeForm?.bpjs?.jkk
        ? defaultWorkSchemeForm?.bpjs?.jkk
        : null,
      jkm: defaultWorkSchemeForm?.bpjs?.jkm
        ? defaultWorkSchemeForm?.bpjs?.jkm
        : null,
      jht: defaultWorkSchemeForm?.bpjs?.jht
        ? defaultWorkSchemeForm?.bpjs?.jht
        : null,
      health: defaultWorkSchemeForm?.bpjs?.health
        ? defaultWorkSchemeForm?.bpjs?.health
        : null,
      akhdk: defaultWorkSchemeForm?.bpjs?.akhdk
        ? defaultWorkSchemeForm?.bpjs?.akhdk
        : null,
      jp: defaultWorkSchemeForm?.bpjs?.jp
        ? defaultWorkSchemeForm?.bpjs?.jp
        : null,
      cutOffBPJSTKO: defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        ? defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        : null,
    },
    others: {
      manfee: defaultWorkSchemeForm?.others?.manfee
        ? defaultWorkSchemeForm?.others?.manfee
        : [],
      thr: defaultWorkSchemeForm?.others?.thr
        ? defaultWorkSchemeForm?.others?.thr
        : null,
      uak: defaultWorkSchemeForm?.others?.uak
        ? defaultWorkSchemeForm?.others?.uak
        : null,
      tkoBank: defaultWorkSchemeForm?.others?.tkoBank
        ? defaultWorkSchemeForm?.others?.tkoBank
        : [],
    },
  });
  const secondStepDoneIndicator = useMemo(() => {
    if (workSchemeForm?.taxs?.pph21 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.pph23 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.ppn === null) {
      return false;
    } else if (workSchemeForm?.others?.manfee?.length === 0) {
      return false;
    } else if (workSchemeForm?.others?.thr === null) {
      return false;
    } else if (workSchemeForm?.others?.uak === null) {
      return false;
    } else if (workSchemeForm?.others?.tkoBank?.length === 0) {
      return false;
    } else {
      return true;
    }
    // eslint-disable-next-line
  }, [workSchemeForm, trigger]);

  const fixedWorkSchemeManFee = useMemo(() => {
    let arr = [];

    const manfeeArr = workSchemeForm?.others?.manfee;
    for (let i = 0; i < manfeeArr?.length; i++) {
      const manfee = manfeeArr[i];
      arr.push(manfee?.name);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.manfee]);

  const fixedTkoBank = useMemo(() => {
    let arr = [];

    const banksArr = workSchemeForm?.others?.tkoBank;
    for (let i = 0; i < banksArr?.length; i++) {
      const bank = banksArr[i];
      arr.push(bank);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.tkoBank]);

  const fixedBpjsCut = useMemo(() => {
    return workSchemeForm?.bpjs?.cutOffBPJSTKO;
  }, [workSchemeForm?.bpjs?.cutOffBPJSTKO]);

  const workSchemeOptions = useMemo(() => {
    const arr = [];

    const taxs = [];
    const pph21 = [];
    const pph23 = [];
    const ppn = [];

    const bpjs = [];
    const registration = [];
    const paymentProof = [];
    const jkk = [];
    const jkm = [];
    const jht = [];
    const health = [];
    const akdhk = [];
    const jp = [];
    const cutOffBPJSTKO = [];

    const others = [];
    const manfee = [];
    const uak = [];
    const thr = [];
    const tkoBank = [];

    for (let i = 0; i < syncData?.options?.length; i++) {
      const option = syncData?.options[i];
      if (option?.type === "PPH21") {
        pph21.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPH23") {
        pph23.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPN") {
        ppn.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pendaftaran BPJS") {
        registration.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Bukti Pendaftaran BPJS") {
        paymentProof.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKK") {
        jkk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKM") {
        jkm.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JHT") {
        jht.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JP") {
        jp.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "KS") {
        health.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pengenaan Manfee") {
        manfee.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan UAK") {
        uak.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan THR") {
        thr.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Potongan BPJS TKO") {
        cutOffBPJSTKO.push({
          id: option.id,
          name: option?.value,
        });
      }
    }

    for (let i = 0; i < syncData?.banks.length; i++) {
      tkoBank.push({
        id: i,
        name: syncData?.banks[i],
      });
    }

    taxs.push({
      pph21: pph21,
      pph23: pph23,
      ppn: ppn,
    });
    bpjs.push({
      registration: registration,
      paymentProof: paymentProof,
      jkk: jkk,
      jkm: jkm,
      jht: jht,
      health: health,
      akdhk: akdhk,
      jp: jp,
      cutOffBPJSTKO: cutOffBPJSTKO,
    });
    others.push({
      manfee: manfee,
      uak: uak,
      thr: thr,
      tkoBank: tkoBank,
    });

    arr.push({
      taxs: taxs[0],
      bpjs: bpjs[0],
      others: others[0],
    });

    return arr[0];
  }, [syncData]);

  const [additionalForm, setAdditionalForm] = useState({
    recipientsAccount: {
      bankName: defaultAdditionalForm?.recipientsAccount?.bankName
        ? defaultAdditionalForm?.recipientsAccount?.bankName
        : null,
      bankNumber: defaultAdditionalForm?.recipientsAccount?.bankNumber
        ? defaultAdditionalForm?.recipientsAccount?.bankNumber
        : null,
      bankRecipient: "PT Permata Indo Sejahtera",
    },
    invoiceFlow: {
      dateOfReceiptOfData: defaultAdditionalForm?.invoiceFlow
        ?.dateOfReceiptOfData
        ? defaultAdditionalForm?.invoiceFlow?.dateOfReceiptOfData
        : null,
      UP: defaultAdditionalForm?.invoiceFlow?.UP
        ? defaultAdditionalForm?.invoiceFlow?.UP
        : null,
      dateOfFillingOfSoftCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfSoftCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy
        : null,
      dateOfFillingOfHardCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfHardCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfHardCopy
        : null,
      payrollDate: defaultAdditionalForm?.invoiceFlow?.payrollDate
        ? defaultAdditionalForm?.invoiceFlow?.payrollDate
        : null,
      cutOffAttendance: defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        ? defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        : null,
      supportingDocument: defaultAdditionalForm?.invoiceFlow?.supportingDocument
        ? defaultAdditionalForm?.invoiceFlow?.supportingDocument
        : null,
    },
    cooperationPeriod: {
      startDate:
        defaultOrderForm?.additional?.periodeKerjasama[0]?.startDate ||
        defaultOrderForm?.addtional?.periodeKerjasama[0]?.startDate ||
        null,
      endDate: defaultAdditionalForm?.cooperationPeriod?.endDate
        ? defaultAdditionalForm?.cooperationPeriod?.endDate
        : null,
      documentNumber:
        defaultOrderForm?.additional?.periodeKerjasama[0]?.docNumber ||
        defaultOrderForm?.addtional?.periodeKerjasama[0]?.docNumber ||
        null,
      id:
        defaultOrderForm?.additional?.periodeKerjasama[0]?.id ||
        defaultOrderForm?.addtional?.periodeKerjasama[0]?.id ||
        null,
    },
    others: {
      deductedAttendance: defaultAdditionalForm?.others?.deductedAttendance
        ? defaultAdditionalForm?.others?.deductedAttendance
        : null,
      others: defaultAdditionalForm?.others?.others
        ? defaultAdditionalForm?.others?.others
        : null,
    },
  });

  const thirdStepDoneIndicator = useMemo(() => {
    if (!additionalForm?.cooperationPeriod?.documentNumber) {
      return false;
    } else if (!additionalForm?.cooperationPeriod?.startDate) {
      return false;
    } else if (!additionalForm?.cooperationPeriod?.endDate) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfReceiptOfData) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.UP) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.payrollDate) {
      return false;
    } else if (!additionalForm?.invoiceFlow?.cutOffAttendance) {
      return false;
    } else if (!additionalForm?.recipientsAccount?.bankNumber) {
      return false;
    } else {
      return true;
    }
    // eslint-disable-next-line
  }, [trigger, additionalForm]);

  const [manPowerForm, setManPowerForm] = useState(
    defaultManPowerForm?.length > 0 ? defaultManPowerForm : []
  );

  const fourthStepDoneIndicator = useMemo(() => {
    let temp;
    if (manPowerForm?.length === 0) {
      return false;
    } else {
      for (let i = 0; i < manPowerForm?.length; i++) {
        const perLocation = manPowerForm[i];
        if (perLocation?.areaWorkEquipments?.length > 0) {
          for (let j = 0; j < perLocation?.areaWorkEquipments?.length; j++) {
            if (
              perLocation?.areaWorkEquipments[j]?.value &&
              perLocation?.areaWorkEquipments[j]?.value !== 0
            ) {
              temp = true;
            } else {
              return false;
            }
          }
        }
        if (perLocation?.jobs?.length > 0) {
          for (let j = 0; j < perLocation?.jobs?.length; j++) {
            const perJob = perLocation?.jobs[j];
            if (
              !perJob?.title ||
              !perJob?.contractType ||
              !perJob?.fulfillmentDate
            ) {
              return false;
            }

            if (perJob?.qualifications?.length < 1) {
              return false;
            } else {
              for (let k = 0; k < perJob?.qualifications?.length; k++) {
                if (
                  !perJob?.qualifications[k]?.value ||
                  perJob?.qualifications[k]?.value === 0 ||
                  perJob?.qualifications[k]?.value === ""
                ) {
                  return false;
                } else {
                  temp = true;
                }
              }
            }

            for (let k = 0; k < perJob?.allowances?.length; k++) {
              if (
                !perJob?.allowances[k]?.value ||
                perJob?.allowances[k]?.value === 0 ||
                perJob?.allowances[k]?.value === "0" ||
                perJob?.allowances[k]?.value === ""
              ) {
                return false;
              } else {
                temp = true;
              }
            }

            for (let k = 0; k < perJob?.personalEquipments?.length; k++) {
              if (
                !perJob?.personalEquipments[k]?.value ||
                perJob?.personalEquipments[k]?.value === 0 ||
                perJob?.personalEquipments[k]?.value === "0" ||
                perJob?.personalEquipments[k]?.value === ""
              ) {
                return false;
              } else {
                temp = true;
              }
            }
          }
        } else {
          return false;
        }
      }
    }

    return temp;
    // eslint-disable-next-line
  }, [manPowerForm, trigger]);

  const manPowerOptions = useMemo(() => {
    const arr = [];

    const contractTypeWorker = [];
    const personalWorkEquipment = [];
    const allowance = [];
    const areaWorkEquipment = [];

    for (let i = 0; i < syncData?.options.length; i++) {
      const option = syncData?.options[i];

      if (option?.type === "contractTypeWorker") {
        contractTypeWorker.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Personal Work Equipment") {
        personalWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Allowance") {
        allowance.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Area Work Equipment") {
        areaWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      }
    }

    arr.push({
      contractTypeWorker: contractTypeWorker,
      personalWorkEquipment: personalWorkEquipment,
      allowance: allowance,
      areaWorkEquipment: areaWorkEquipment,
    });

    return arr[0];
  }, [syncData]);

  const qualificationOptions = useMemo(() => {
    const arr = [];
    for (let i = 0; i < syncData?.qualifications.length; i++) {
      const qualification = syncData?.qualifications[i];

      arr.push({
        name: qualification?.value,
        denom: qualification.denom,
        value: null,
      });
    }

    return arr;
  }, [syncData?.qualifications]);

  const [manfee, setManFee] = useState(defaultManfee || "");
  const [TOP, setTOP] = useState(defaultTOP || "");
  const [remark, setRemark] = useState(defaultRemark || "");

  const defaultDelegations = undefined;

  const [delegations, setDelegations] = useState({
    businessManagers: defaultDelegations?.businessManagers || [],
    accountManagers: defaultDelegations?.accountManagers || [],
    admins: defaultDelegations?.admins || [],
    recruiters: defaultDelegations?.recruiters || [],
  });

  const fixedDelegationsBackup = useMemo(() => {
    const arr = [];

    const businessManagers = delegations?.businessManagers;
    for (let i = 0; i < businessManagers?.length; i++) {
      const bm = businessManagers[i];
      arr.push({
        employeeId: bm?.employeeId,
        cityCode: bm?.locationId,
      });
    }

    const accountManagers = delegations?.accountManagers;
    for (let i = 0; i < accountManagers?.length; i++) {
      const am = accountManagers[i];
      arr.push({
        employeeId: am?.employeeId,
        cityCode: am?.locationId,
      });
    }

    const admins = delegations?.admins;
    for (let i = 0; i < admins?.length; i++) {
      const admin = admins[i];
      arr.push({
        employeeId: admin?.employeeId,
        cityCode: admin?.locationId,
      });
    }

    const recruiters = delegations?.recruiters;
    for (let i = 0; i < recruiters?.length; i++) {
      const ro = recruiters[i];
      arr.push({
        employeeId: ro?.employeeId,
        cityCode: ro?.locationId,
      });
    }

    return arr;
    // eslint-disable-next-line
  }, [delegations, trigger]);

  const fixedDelegations = useMemo(() => {
    const arr = [];

    const businessManagers = delegations?.businessManagers;
    for (let i = 0; i < businessManagers?.length; i++) {
      const bm = businessManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === bm?.locationId)) {
        arr.push({
          employeeId: bm?.employeeId,
          cityCode: bm?.locationId,
        });
      }
    }

    const accountManagers = delegations?.accountManagers;
    for (let i = 0; i < accountManagers?.length; i++) {
      const am = accountManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === am?.locationId)) {
        arr.push({
          employeeId: am?.employeeId,
          cityCode: am?.locationId,
        });
      }
    }

    const admins = delegations?.admins;
    for (let i = 0; i < admins?.length; i++) {
      const admin = admins[i];
      if (manPowerForm?.find((obj) => obj?.location === admin?.locationId)) {
        arr.push({
          employeeId: admin?.employeeId,
          cityCode: admin?.locationId,
        });
      }
    }

    const recruiters = delegations?.recruiters;
    for (let i = 0; i < recruiters?.length; i++) {
      const ro = recruiters[i];
      if (manPowerForm?.find((obj) => obj?.location === ro?.locationId)) {
        arr.push({
          employeeId: ro?.employeeId,
          cityCode: ro?.locationId,
        });
      }
    }

    return arr;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegations, manPowerForm, trigger]);

  const sixthStepDoneIndicator = useMemo(() => {
    if (fixedDelegations?.length > 0) {
      const cityCodeArr = fixedDelegations?.map((each) => each.cityCode);
      const locationArr = manPowerForm?.map((each) => each.location);
      return locationArr.every((element) => {
        return cityCodeArr.indexOf(element) !== -1;
      });
    }
    return false;
  }, [fixedDelegations, manPowerForm]);

  const query = useQuery();

  const clientCode = query.get("c");

  const form = useMemo(() => {
    return {
      contractType: contractForm?.contract,
      clientCode: clientCode,
      setManFee: manfee,
      setTOP: TOP,
      remark: remark,
      workSchemes: workSchemeForm,
      additional: additionalForm,
      manPowers: manPowerForm,
      files: files,
      delegations: fixedDelegations,
    };
  }, [
    contractForm,
    workSchemeForm,
    clientCode,
    additionalForm,
    manPowerForm,
    files,
    TOP,
    manfee,
    remark,
    fixedDelegations,
  ]);

  const renewTrigger = useCallback(() => {
    setTrigger(Math.random());
  }, []);

  const debounceTrigger = useMemo(
    () => debounce(renewTrigger, 500),
    [renewTrigger]
  );

  const clearStorage = () => {};

  const onPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = (isDone) => {
    const newSteps = [...steps];
    if (isDone) {
      newSteps[currentStep - 1].isDone = true;
      setSteps(newSteps);
      setCurrentStep(currentStep + 1);
    }
  };

  const [submittingType, setSubmittingType] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [submittingDraft, setSubmittingDraft] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleSubmit = async (e, type) => {
    if (type === "send") {
      setSubmittingType("send");
      setSubmitting(true);
    } else {
      setSubmittingType("draft");
      setSubmittingDraft(true);
    }
    try {
      e.preventDefault();

      const formData = new FormData();

      formData.append("contractType", form?.contractType);
      formData.append("clientCode", form?.clientCode);
      formData.append("setManFee", form?.setManFee);
      formData.append("setTOP", form?.setTOP);
      formData.append("remark", form?.remark);

      formData.append(
        "workScheme[orderComprehensiveID]",
        defaultOrderForm?.workScheme?.id
      );
      formData.append(
        "workScheme[taxs][pph21]",
        form?.workSchemes?.taxs?.pph21
      );
      formData.append(
        "workScheme[taxs][pph23]",
        form?.workSchemes?.taxs?.pph23
      );
      formData.append("workScheme[taxs][ppn]", form?.workSchemes?.taxs?.ppn);
      formData.append(
        "workScheme[bpjs][registration]",
        form?.workSchemes?.bpjs?.registration
      );
      formData.append(
        "workScheme[bpjs][paymentProof]",
        form?.workSchemes?.bpjs?.paymentProof
      );
      formData.append("workScheme[bpjs][jkk]", form?.workSchemes?.bpjs?.jkk);
      formData.append("workScheme[bpjs][jkm]", form?.workSchemes?.bpjs?.jkm);
      formData.append("workScheme[bpjs][jht]", form?.workSchemes?.bpjs?.jht);
      formData.append(
        "workScheme[bpjs][akhdk]",
        form?.workSchemes?.bpjs?.akhdk
      );
      formData.append("workScheme[bpjs][jp]", form?.workSchemes?.bpjs?.jp);
      formData.append(
        "workScheme[bpjs][health]",
        form?.workSchemes?.bpjs?.health
      );
      formData.append(
        "workScheme[bpjs][cutOffBPJSTKO]",
        form?.workSchemes?.bpjs?.cutOffBPJSTKO
      );
      formData.append("workScheme[others][manfee]", fixedWorkSchemeManFee);
      formData.append(
        "workScheme[others][thr]",
        form?.workSchemes?.others?.thr
      );
      formData.append(
        "workScheme[others][uak]",
        form?.workSchemes?.others?.uak
      );
      formData.append("workScheme[others][tkoBank]", fixedTkoBank);

      formData.append(
        "additional[recipientsAccount][bankName]",
        form?.additional?.recipientsAccount?.bankName
      );
      formData.append(
        "additional[recipientsAccount][bankNumber]",
        form?.additional?.recipientsAccount?.bankNumber
      );
      formData.append(
        "additional[recipientsAccount][bankRecipient]",
        form?.additional?.recipientsAccount?.bankRecipient
      );
      formData.append(
        "additional[invoiceFlow][dateOfReceiptOfData]",
        form?.additional?.invoiceFlow?.dateOfReceiptOfData
      );
      formData.append(
        "additional[invoiceFlow][UP]",
        form?.additional?.invoiceFlow?.UP
      );
      formData.append(
        "additional[invoiceFlow][dateOfFillingOfSoftCopy]",
        form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy
      );
      formData.append(
        "additional[invoiceFlow][dateOfFillingOfHardCopy]",
        form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy
      );
      formData.append(
        "additional[invoiceFlow][payrollDate]",
        form?.additional?.invoiceFlow?.payrollDate
      );
      formData.append(
        "additional[invoiceFlow][cutOffAttendance]",
        form?.additional?.invoiceFlow?.cutOffAttendance
      );
      formData.append(
        "additional[invoiceFlow][supportingDocument]",
        form?.additional?.invoiceFlow?.supportingDocument || ""
      );
      formData.append(
        "additional[cooperationPeriod][startDate]",
        form?.additional?.cooperationPeriod?.startDate
          ? formatDate(form?.additional?.cooperationPeriod?.startDate)
          : null
      );
      formData.append(
        "additional[cooperationPeriod][endDate]",
        form?.additional?.cooperationPeriod?.endDate
          ? formatDate(form?.additional?.cooperationPeriod?.endDate)
          : null
      );
      formData.append(
        "additional[cooperationPeriod][documentNumber]",
        form?.additional?.cooperationPeriod?.documentNumber
      );
      formData.append(
        "additional[others][deductedAttendance]",
        form?.additional?.others?.deductedAttendance || ""
      );
      formData.append(
        "additional[others][others]",
        form?.additional?.others?.others || ""
      );

      const manPowersArr = form?.manPowers;
      const delegationsArr = fixedDelegations;
      const filesArr = form?.files;

      for (let i = 0; i < manPowersArr?.length; i++) {
        const perlocationArr = form?.manPowers[i];
        const jobsArr = perlocationArr?.jobs;
        const areaEquipmentsArr = perlocationArr?.areaWorkEquipments;

        formData.append(`manPowers[${i}][location]`, perlocationArr?.location);

        for (let j = 0; j < areaEquipmentsArr.length; j++) {
          const equipment = areaEquipmentsArr[j];
          if (equipment?.id) {
            formData?.append(
              `manPowers[${i}][areaWorkEquipments][${j}][equipmentID]`,
              equipment?.id
            );
          }
          formData?.append(
            `manPowers[${i}][areaWorkEquipments][${j}][name]`,
            equipment?.name
          );
          formData?.append(
            `manPowers[${i}][areaWorkEquipments][${j}][price]`,
            equipment?.value
          );
        }

        for (let j = 0; j < jobsArr?.length; j++) {
          const allowancesArr = jobsArr[j]?.allowances;
          const qualificationsArr = jobsArr[j]?.qualifications;
          const personalEquipmentsArr = jobsArr[j]?.personalEquipments;

          const job = jobsArr[j];
          if (job?.id) {
            formData?.append(
              `manPowers[${i}][jobs][${j}][positionID]`,
              job?.id
            );
          }
          formData?.append(`manPowers[${i}][jobs][${j}][title]`, job?.title);
          formData?.append(
            `manPowers[${i}][jobs][${j}][contractType]`,
            job?.contractType
          );
          formData?.append(
            `manPowers[${i}][jobs][${j}][salary]`,
            `${job?.salary}::${job?.salaryPeriod}`
          );
          formData?.append(
            `manPowers[${i}][jobs][${j}][fulfilmentDate]`,
            job?.fulfillmentDate ? formatDate(job?.fulfillmentDate) : null
          );
          formData?.append(`manPowers[${i}][jobs][${j}][demand]`, job?.demand);

          for (let k = 0; k < allowancesArr.length; k++) {
            if (allowancesArr[k]?.id) {
              formData?.append(
                `manPowers[${i}][jobs][${j}][allowances][${k}][allowanceID]`,
                allowancesArr[k]?.id
              );
            }
            formData?.append(
              `manPowers[${i}][jobs][${j}][allowances][${k}][name]`,
              allowancesArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][allowances][${k}][value]`,
              allowancesArr[k]?.value
            );
          }

          for (let k = 0; k < qualificationsArr.length; k++) {
            if (qualificationsArr[k]?.id) {
              formData?.append(
                `manPowers[${i}][jobs][${j}][qualifications][${k}][qualificationID]`,
                qualificationsArr[k]?.id
              );
            }
            formData?.append(
              `manPowers[${i}][jobs][${j}][qualifications][${k}][name]`,
              qualificationsArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][qualifications][${k}][value]`,
              qualificationsArr[k]?.value
            );
          }

          for (let k = 0; k < personalEquipmentsArr.length; k++) {
            if (personalEquipmentsArr[k]?.id) {
              formData?.append(
                `manPowers[${i}][jobs][${j}][personalEquipments][${k}][equipmentID]`,
                personalEquipmentsArr[k]?.id
              );
            }
            formData?.append(
              `manPowers[${i}][jobs][${j}][personalEquipments][${k}][name]`,
              personalEquipmentsArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][personalEquipments][${k}][price]`,
              personalEquipmentsArr[k]?.value
            );
          }
        }
      }

      for (let i = 0; i < delegationsArr.length; i++) {
        const delegation = delegationsArr[i];
        formData?.append(`delegations[${i}][cityCode]`, delegation?.cityCode);
        formData?.append(
          `delegations[${i}][employeeId]`,
          delegation?.employeeId
        );
      }

      for (let i = 0; i < filesArr.length; i++) {
        const file = filesArr[i];
        formData?.append("files", file);
      }

      if (type === "send") {
        await updateOrder(query.get("i"), formData);
      } else {
        await editOrderAsDraft(formData, query.get("i"));
      }
      setSubmitting(false);
      setSubmittingDraft(false);
      setIsSuccess(true);
    } catch (err) {
      setSubmitting(false);
      setSubmittingDraft(false);
      console.log(err.response.data.error);
      setError({
        code: err?.response?.data?.code,
        message: err?.response?.data?.error,
      });
    }
  };

  const renewWarning = useCallback(() => {
    const newSteps = [...steps];

    if (firstStepDoneIndicator === true) {
      newSteps[0].warning = false;
      setSteps(newSteps);
    } else {
      newSteps[0].warning = true;
      setSteps(newSteps);
    }

    if (secondStepDoneIndicator === true) {
      newSteps[1].warning = false;
      setSteps(newSteps);
    } else {
      newSteps[1].warning = true;
      setSteps(newSteps);
    }
    if (thirdStepDoneIndicator === true) {
      newSteps[2].warning = false;
      setSteps(newSteps);
    } else {
      newSteps[2].warning = true;
      setSteps(newSteps);
    }
    if (fourthStepDoneIndicator === true) {
      newSteps[3].warning = false;
      setSteps(newSteps);
    } else {
      newSteps[3].warning = true;
      setSteps(newSteps);
    }

    if (!manfee || !TOP) {
      newSteps[4].warning = true;
      setSteps(newSteps);
    } else {
      newSteps[4].warning = false;
      setSteps(newSteps);
    }

    if (sixthStepDoneIndicator) {
      newSteps[5].warning = false;
      setSteps(newSteps);
    } else {
      newSteps[5].warning = true;
      setSteps(newSteps);
    }

    // eslint-disable-next-line
  }, [
    firstStepDoneIndicator,
    secondStepDoneIndicator,
    thirdStepDoneIndicator,
    fourthStepDoneIndicator,
    manfee,
    TOP,
    sixthStepDoneIndicator,
  ]);

  useEffect(() => {
    renewWarning();
  }, [renewWarning]);

  const disableSubmit = useMemo(() => {
    if (
      !firstStepDoneIndicator ||
      !secondStepDoneIndicator ||
      !thirdStepDoneIndicator ||
      !fourthStepDoneIndicator ||
      !manfee ||
      !TOP ||
      !sixthStepDoneIndicator
    ) {
      return true;
    }
    return false;
  }, [
    firstStepDoneIndicator,
    secondStepDoneIndicator,
    thirdStepDoneIndicator,
    fourthStepDoneIndicator,
    manfee,
    TOP,
    sixthStepDoneIndicator,
  ]);

  useEffect(() => {
    if (!disableSubmit) {
      setIsCompleted(true);
    }
  }, [disableSubmit, setIsCompleted]);
  return (
    <div className={Styles.container}>
      {currentStep === 1 && (
        <FirstStep
          debounceTrigger={debounceTrigger}
          types={contractTypeOptions}
          onChange={onChangeStep1}
          form={contractForm}
          onNext={() => onNext(firstStepDoneIndicator)}
        />
      )}
      {currentStep === 2 && (
        <SecondStep
          debounceTrigger={debounceTrigger}
          bankOptions={bankOptions}
          setTrigger={setTrigger}
          isDone={secondStepDoneIndicator}
          options={workSchemeOptions}
          form={workSchemeForm}
          setForm={setWorkSchemeForm}
          onNext={() => onNext(secondStepDoneIndicator)}
          onPrevious={onPrevious}
        />
      )}
      {currentStep === 3 && (
        <ThirdStep
          debounceTrigger={debounceTrigger}
          setTrigger={setTrigger}
          isDone={thirdStepDoneIndicator}
          form={additionalForm}
          setForm={setAdditionalForm}
          files={files}
          setFiles={setFiles}
          onNext={() => onNext(true)}
          onPrevious={onPrevious}
        />
      )}
      {currentStep === 4 && (
        <FourthStep
          debounceTrigger={debounceTrigger}
          isDone={fourthStepDoneIndicator}
          trigger={trigger}
          setTrigger={setTrigger}
          options={manPowerOptions}
          cities={syncData?.cities}
          qualifications={qualificationOptions}
          form={manPowerForm}
          setForm={setManPowerForm}
          remark={remark}
          setRemark={setRemark}
          onNext={() => onNext(true)}
          onPrevious={onPrevious}
        />
      )}
      {currentStep === 5 && (
        <FifthStep
          debounceTrigger={debounceTrigger}
          manfeeArr={workSchemeForm?.others?.manfee}
          fixedManfee={fixedWorkSchemeManFee}
          fixedBpjsCut={fixedBpjsCut}
          manfee={manfee}
          setManfee={setManFee}
          TOP={TOP}
          setTOP={setTOP}
          form={manPowerForm}
          workSchemeForm={workSchemeForm}
          setForm={setManPowerForm}
          cities={syncData?.cities}
          onNext={() => onNext(true)}
          onPrevious={onPrevious}
        />
      )}
      {currentStep === 6 && (
        <SixthStep
          debounceTrigger={debounceTrigger}
          isDone={sixthStepDoneIndicator}
          setTrigger={setTrigger}
          form={manPowerForm}
          cities={syncData?.cities}
          delegations={delegations}
          setDelegations={setDelegations}
          users={syncData?.users}
          onNext={() => onNext(sixthStepDoneIndicator)}
          onPrevious={onPrevious}
        />
      )}
      {currentStep === 7 && (
        <SeventhStep
          disableSubmit={disableSubmit}
          clearStorage={clearStorage}
          isSubmitting={submitting}
          onPrevious={onPrevious}
          onSubmit={handleSubmit}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          error={error}
          setError={setError}
        />
      )}
    </div>
  );
}
