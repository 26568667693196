import CreateContract from "@Organism/HumanCapital/CreateContract";
import { useLocation } from "react-router-dom";
import { getTemplateSync } from "@Services/hc/contract";
import { defer, useLoaderData, Await } from "react-router-dom";
import { Suspense } from "react";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const type = url.searchParams.get("t") || "CONTRACT";
  const editTemplateSyncPromise = getTemplateSync("", type);

  return defer({
    editTemplateSync: editTemplateSyncPromise,
    type,
  });
}

const EditTemplatePage = () => {
  const location = useLocation();
  const state = location.state;
  // eslint-disable-next-line no-unused-vars
  const { editTemplateSync, types } = useLoaderData();


  return (
    <Suspense>
      <Await resolve={editTemplateSync}>
        {(editTemplateSync) => (
          <CreateContract
            createTemplateSync={editTemplateSync}
            data={state.data}
            type={state.type || "CONTRACT"}
          />
        )}
      </Await>
    </Suspense>
  );
};

export default EditTemplatePage;
