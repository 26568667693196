import Icon from "@Atom/Icon";
import { verifyCandidate } from "@Services/support/candidate";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Spinner from "@Atom/Spinner";

const RejectVerificationModal = ({ setShowReject, setTrigger, stage, customId = '' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await verifyCandidate(
        "Declined",
        stage === "REGISTER" || stage === "FIRST-VERIFICATION"
          ? "FIRST-VERIFICATION"
          : "SECOND-VERIFICATION",
        customId || id,
        remark,
        "VERIFICATION"
      );
      setTrigger(Math.random());
      setIsLoading(false);
      setShowReject(3);
    } catch (err) {
      console.log(err, "err");
      setIsLoading(false);
      setError(err?.response?.data?.error);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <div className={Styles.iconWrapper}>
          <Icon icon="shield-exclamation" size="48" className={Styles.icon} />
        </div>
        <div className={Styles.textWrapper}>
          <div className={Styles.title}>Reject Verifikasi</div>
          <div className={Styles.text}>Catatan akan dikirimkan ke kandidat</div>
        </div>
      </div>
      <div className={Styles.textareaWrapper}>
        <InputField
          type="desc"
          value={remark}
          placeholder={"Tulis catatan Anda"}
          onChange={(e) => {
            setRemark(e.target.value);
          }}
        />
      </div>
      {/* <div className={Styles.textareaWrapper}>
        <input
          placeholder="Tulis catatan Anda"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
        />
      </div>*/}

      <div className={Styles.buttonBox}>
        {error ? <span>{error}</span> : null}

        <div className={Styles.buttons}>
          <button
            className={Styles.secondaryButton}
            onClick={() => setShowReject(0)}
          >
            <span className={Styles.text}>Batal</span>
          </button>
          <button
            className={`${Styles.primaryButton} ${
              !remark || isLoading ? Styles.disabledButton : ""
            }`}
            onClick={isLoading || !remark ? null : handleSubmit}
            disabled={!remark || isLoading}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <span className={Styles.text}>Submit</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectVerificationModal;
