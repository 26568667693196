import React, { useEffect, useRef } from "react";
import Styles from "./style.module.scss";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Images from "@Theme/Images";

const privacyPolicy = [
  {
    title: "Pengumpulan Jenis Data Pribadi",
    body: [
      "Informasi terbatas yang Diberikan oleh Pengguna: Kami dapat mengumpulkan informasi pribadi yang Anda berikan secara sukarela ketika Anda menggunakan layanan kami,termasuk namun tidak terbatas pada nomor registrasi karyawan, nomor telepon, data swafoto dan informasi demografis lainnya.",
      "Data Lokasi. Kami mengumpulkan informasi tentang lokasi Anda saat Anda menggunakan Layanan Kami. Lokasi Anda dapat ditentukan dengan berbagai tingkat akurasi menggunakan GPS, alamat IP, data sensor dari perangkat Anda, Informasi tentang yang ada di dekat perangkat Anda, seperti titik akses Wi-Fi dan menara seluler.",
    ],
    type: "arr",
  },
  {
    title: "Pengumpulan Data Pribadi Anda",
    body: [
      "Penggunaan fitur-fitur yang terdapat pada Layanan Kami. Sebagai contoh untuk rekrutmen kerja, maka Kami dapat menggunakan Data Pribadi Anda berupa swafoto untuk keperluan pencatatan kehadiran.",
      "Mendaftarkan Anda sebagai pengguna pada Platform Kami, dan juga untuk mengadministrasikan dan/atau mengelola akun Anda.",
      "Menginformasikan Anda atas aktivitas yang terjadi dalam Platform Kami atau platform/sistem lain yang terhubung dengan Platform Kami.",
      "Berinteraksi langsung dengan Anda serta memberitahu Anda mengenai segala pembaruan atau perubahan pada Layanan",
      "Mengirimkan Anda informasi sehubungan dengan penggunaan Layanan",
      "Melakukan pemeliharaan peningkatan, pemecahan masalah, pengembangan dan personalisasi/kustomisasi Layanan untuk memenuhi kebutuhan dan preferensi Anda",
      "Memberikan bantuan atau menyelesaikan aduan sehubungan dengan masalah operasional yang terjadi pada Layanan",
    ],
    type: "arr",
  },
  {
    title: "Keamanan Informasi",
    body: "Kami mengambil langkah-langkah yang wajar untuk melindungi informasi pribadi Anda dari akses yang tidak sah atau penggunaan yang tidak sah. Kami menggunakan tindakan keamanan fisik, teknis, dan administratif yang sesuai untuk melindungi informasi pribadi Anda.",
    type: "string",
  },
  {
    title: "Pengungkapan Informasi Kepada Pihak Ketiga",
    body: "Kami tidak akan menjual, menyewakan, atau menukar informasi pribadi Anda kepada pihak ketiga tanpa izin Anda, kecuali jika diwajibkan oleh hukum. Kami dapat mengungkapkan informasi kepada penyedia layanan pihak ketiga yang bekerja atas nama kami untuk memberikan layanan kepada Anda, tetapi mereka diwajibkan untuk menjaga kerahasiaan informasi tersebut",
    type: "string",
  },
  {
    title: "Pengendalian Privasi Anda",
    body: `Anda memiliki hak untuk mengakses dan memperbarui. Jika Anda ingin melakukan perubahan pada informasi Anda atau memiliki pertanyaan tentang kebijakan privasi kami, silakan hubungi kami menggunakan informasi yang tercantum di bagian "Hubungi Kami" di bawah ini.`,
    type: "string",
  },
  {
    title: "Perubahan Kebijakan Privasi",
    body: "Kami dapat mengubah kebijakan privasi ini dari waktu ke waktu. Perubahan akan diberlakukan saat kebijakan privasi yang diperbarui diposting di situs kami. Kami mendorong Anda untuk memeriksa kebijakan privasi ini secara berkala untuk memahami bagaimana informasi pribadi Anda dilindungi.",
    type: "string",
  },
  {
    title: "Persetujuan Anda",
    body: "Dengan menggunakan layanan kami, Anda menyetujui pengumpulan dan penggunaan informasi Anda sesuai dengan kebijakan privasi ini.",
    type: "string",
  },
  {
    title: "Hubungi Kami",
    body: "Jika Anda memiliki pertanyaan atau masalah mengenai kebijakan privasi kami, silakan hubungi kami melalui alamat email atau alamat surat yang tercantum di bawah ini:\n\nEmail	: info@permataindonesia.com\nTelepon : (021) 24-101-355\nKantor	: Jl. Kebayoran Lama No. 226, Kode Pos 12220, Jakarta Selatan, Indonesia",
    type: "string",
  },
];

export default function PrivacyPolicyModal({ handleClose }) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxOutsideClick]);
  return (
    <div className={Styles.background}>
      <div className={Styles.container} ref={boxRef}>
        <div className={Styles.headWrapper}>
          <div className={Styles.header}>
            <div className={Styles.logoWrapper}>
              <div>
                <img src={Images?.INITIAL_LOGO} alt="logo" />
              </div>
              <span>PT Permata Indonesia Sejahtera</span>
            </div>
            <div className={Styles.watermarkWrapper}>
              <p>Powered by</p>
              <img src={Images.KERJA365_LIGHT} alt="watermark" />
            </div>
          </div>
        </div>
        <div className={Styles.body}>
          <p>Kebijakan Privasi (PRIVACY POLICY)</p>
          <div className={Styles.pharagraph}>
            <p className={Styles.text}>
              Kami sangat menghargai privasi Anda dan berkomitmen untuk
              melindungi informasi pribadi yang Anda berikan kepada kami.
              Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan,
              menggunakan, dan melindungi informasi pribadi Anda. Dengan
              menggunakan layanan kami, Anda menyetujui penggunaan informasi
              Anda sesuai dengan kebijakan ini. Harap membaca kebijakan privasi
              ini secara menyeluruh sebelum menggunakan layanan kami.
            </p>
          </div>
          <div className={Styles.pharagraphListWrapper}>
            {privacyPolicy.map((el, idx) => (
              <div className={Styles.pharagraphList} key={idx}>
                <p className={Styles.textHead}>
                  {idx + 1}. {el.title}
                </p>
                {el.type === "string" ? (
                  <p className={Styles.textList}>{el.body}</p>
                ) : (
                  <ul>
                    {el.body.map((text, idx) => (
                      <li key={idx} className={Styles.textList}>
                        <p>{text}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          <div className={Styles.pharagraph}>
            <p className={Styles.text}>
              Kebijakan privasi ini berlaku untuk informasi yang dikumpulkan
              melalui layanan kami dan tidak berlaku untuk informasi yang
              dikumpulkan oleh pihak ketiga yang mungkin kami tautkan di situs
              kami. Kami tidak bertanggung jawab atas praktik privasi pihak
              ketiga tersebut. Terima kasih telah membaca kebijakan privasi
              kami.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
