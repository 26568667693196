import { useCallback, useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { getOfferTransitionOfferingData } from "@Services/officer/recruitment";
import Zero from "./Zero";
import OnProgress from "./OnProgress";

export default function OfferTransitionComponent({
  currentStep,
  setCurrentStep,
  syncData,
  offeringData,
}) {
  const [data, setData] = useState(offeringData);
  const { hash } = useParams();

  const [trigger, setTrigger] = useState(null);

  const refetch = useCallback(async () => {
    const { response } = await getOfferTransitionOfferingData(hash);
    setData(response);
  }, [hash]);

  useEffect(() => {
    refetch();
  }, [trigger, refetch]);
  return (
    <div className={Styles.container}>
      {currentStep === 0 ? (
        <Zero
          setCurrentStep={setCurrentStep}
          //   disableClick={data?.status !== "OFFER-INVITE" ? true : false}
          offeringData={data}
          refetch={refetch}
        />
      ) : (
        <OnProgress
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          syncData={syncData}
          offeringData={data}
          setTriggerDefault={setTrigger}
        />
      )}
    </div>
  );
}
