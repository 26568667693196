import RecruitmentList from "@Organism/Recruiter/Recruitment/RecruitmentList";
import { Helmet } from "react-helmet-async";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getAllCandidateSync } from "@Services/officer/recruitment";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader() {
  const packageRecruitmentPromise = getAllCandidateSync(
    1,
    5,
    "Rekrutmen",
    "Undang Interview",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  return defer({
    packageRecruitment: packageRecruitmentPromise,
  });
}

export default function RecruitmentListPage() {
  const { packageRecruitment } = useLoaderData();

  return (
    <div>
      <Helmet>
        <title>Kerja 365 | Recruitment</title>
      </Helmet>

      <Suspense
        fallback={
          <div
            style={{
              height: "99vh",
              // placeItems: 'center'
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageRecruitment}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageRecruitment) => (
            <RecruitmentList initialData={packageRecruitment?.response} />
          )}
        </Await>
      </Suspense>
    </div>
  );
}
