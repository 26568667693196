export const downloadFileFromUrl = (url, title, format, setIsDownloading = () => { }) => {
  setIsDownloading(true)
  fetch(url, {
    method: "GET",
    headers: {}
  })
    .then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.${format}`);
        document.body.appendChild(link);
        link.click();
      })
    })
    .then(() => {
      setIsDownloading(false)
    })
    .catch(err => {
      console.log(err);
    });
};