import Styles from "./style.module.scss";
import ICON from "../../../../../assets/Images/verified.png";

const ApproveConfirmationModal = ({
  setShowAccept
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.greenBg}>
          <img src={ICON} alt="" height={60} width={60} />
        </div>
      </div>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Konfirmasi Approve</div>
        <div className={Styles.text}>
          Apakah anda yakin mengapprove applicant ini?
        </div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={() => setShowAccept(0)}>
          <div className={Styles.text}>Batal</div>
        </div>
        <div className={Styles.primaryButton} onClick={() => setShowAccept(2)}>
          <div className={Styles.text}>Approve</div>
        </div>
      </div>
    </div>
  );
};

export default ApproveConfirmationModal;
