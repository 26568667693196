/* eslint-disable no-unused-vars */
import LoadingAnimation from "@Atom/LoadingAnimation";
import AddJobPostingContents from "@Organism/Recruiter/JobPosting/AddJobPosting/Contents";
import AddJobPostingHeader from "@Organism/Recruiter/JobPosting/AddJobPosting/Header";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import {
  getJobPostPerID,
} from "@Services/officer/jobPosting";
import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
// import FooterResponsive from "@Organism/Recruiter/JobPosting/AddJobPosting/FooterResponsive";

export async function loader({ params }) {
  const id = params.id;
  const packageJobPostingSyncPromise = getJobPostPerID(id);
  // const candidateSyncPromise = getCandidateSync();

  return defer({
    // packageCandidateSync: candidateSyncPromise,
    packageJobPostingSync: packageJobPostingSyncPromise,
  });
}

export default function AddJobPostingPage() {
  const { packageJobPostingSync } = useLoaderData();
  const [disablePreview, setDisablePreview] = useState(true);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedButtonTypePosting, setSelectedButtoTypePosting] =
    useState("Normal");

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Add Job Posting</title>
      </Helmet>

      <div className={Styles.headerWrapper}>
        <AddJobPostingHeader
          disablePreview={disablePreview}
          setOpenPreview={setOpenPreview}
          selectedButtonTypePosting={selectedButtonTypePosting}
        />
      </div>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div
              style={{
                height: "calc(100vh - 220px)",
                display: "grid",
                placeItems: "center",
              }}
            >
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageJobPostingSync}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageJobPostingSync) => (
              <AddJobPostingContents
                setOpenPreview={setOpenPreview}
                openPreview={openPreview}
                sync={packageJobPostingSync?.response}
                setDisablePreview={setDisablePreview}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}