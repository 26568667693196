import ContactPersonCard from "@Atom/ContactPersonCard";
import Icon from "@Atom/Icon";
import Styles from "./contactPerson.module.scss";

const ContactPerson = ({ name, data, title, ...props }) => {
  return (
    <>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.container}>
        {data?.map((contactPerson, i) => {
          return (
            <div key={i} className={Styles.cardWrapper}>
              <ContactPersonCard
                name={contactPerson?.name}
                picture={contactPerson?.picture}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContactPersonWithContact = ({ name, data, title, ...props }) => {
  return (
    <>
      <div className={Styles.title}>{title}</div>
      <div className={Styles.container}>
        {data?.map((contactPerson, i) => {
          return (
            <div key={i} className={Styles.cardWrapper}>
              <ContactPersonCard
                name={contactPerson?.name}
                picture={contactPerson?.picture}
              />
              <div className={Styles.Detail}>
                <div className={Styles.Contact}>
                  <Icon icon={"phone-outline"} size="13px" />
                  <p>
                    <span>+62</span>
                    {contactPerson?.phoneNumber?.split("").slice(2)}
                  </p>
                </div>
                <p className={Styles.Divider} />
                <div className={Styles.Contact}>
                  <Icon icon={"email-outline"} size="13px" />
                  <p>{contactPerson?.email}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContactPersonComponent = ({ type, name, data, title, ...props }) => {
  switch (type) {
    case "withContact":
      return <ContactPersonWithContact name={name} data={data} title={title} />;
    default:
      return <ContactPerson name={name} data={data} title={title} />;
  }
};

export default ContactPersonComponent;
