import CheckboxJobPosting from '@Atom/CheckboxJobPosting'
import Icon from '@Atom/Icon'
import LoadingAnimation from '@Atom/LoadingAnimation'
import ModalWrapper from '@Atom/ModalWrapper'
import SearchBar from '@Atom/SearchBar'
import { fileBaseUrl } from '@Config/api'
import { downloadFileFromUrl } from '@Helpers/downloadFileFromUrl'
import { formatBytes } from '@Helpers/formatBytes'
import { getUrlFileSize } from '@Helpers/getUrlFileSize'
import { useDebounce } from '@Hooks/useDebounce'
import useQuery from '@Hooks/useQuery'
import useWindowSize from '@Hooks/useWindowSize'
import InvitationTable from '@Organism/Recruiter/Recruitment/RecruitmentDetail/First/ListMode/InvitationTable'
import ErrorBoundary from '@Organism/Utils/ErrorBoundary'
import { getCandidatePipeline, getCandidatePipelineDetails } from '@Services/manager/client'
import { Avatar } from '@mui/material'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Styles from './style.module.scss'

export const statusOptions = [
  {
    type: 'RO STATUS',
    subTypes: [
      {
        subType: 'Interview RO',
        options: [
          {
            name: 'Belum Diundang',
            value: 'RO-UNINVITED'
          },
          {
            name: 'Mengunggu Konfirmasi',
            value: 'RO-PENDING'
          },
          {
            name: 'Hadir',
            value: 'RO-ACCEPTED'
          },
          {
            name: 'Tidak Hadir',
            value: 'RO-DECLINED'
          },
          {
            name: 'Belum Dinilai',
            value: 'RO-UNASSESSED'
          }
        ]
      },
      {
        subType: 'Interview Klien',
        options: [
          {
            name: 'Belum Diundang',
            value: 'CLIENT-UNINVITED'
          },
          {
            name: 'Menggu Konfirmasi',
            value: 'CLIENT-PENDING'
          },
          {
            name: 'Hadir',
            value: 'CLIENT-ACCEPTED'
          },
          {
            name: 'Tidak Hadir',
            value: 'CLIENT-DECLINED'
          },
          {
            name: 'Diproses Klien',
            value: 'CLIENT-PROCESSED'
          },
          {
            name: 'Tidak Lolos',
            value: 'CLIENT-DISQUALIFIED'
          }
        ]
      },
      {
        subType: 'Offering',
        options: [
          {
            name: 'Belum Dikirim',
            value: 'OFFER-UNINVITED'
          },
          {
            name: 'Menunggu Pengisian Form',
            value: 'OFFER-UNFILLED'
          },
          {
            name: 'Tidak Join',
            value: 'OFFER-DECLINED'
          }
        ]
      }
    ]
  },
  {
    type: 'BS Status',
    subTypes: [
      {
        subType: '',
        options: [
          {
            name: 'Teregistrasi',
            value: 'REGISTER'
          },
          {
            name: 'Verifikasi Pertama',
            value: 'FIRST-VERIFICATION'
          },
          {
            name: 'Perbaikan Verifikasi Pertama',
            value: 'FIRST-VERIFICATION-CORRECTION'
          },
          {
            name: 'Generate Kontrak',
            value: 'GENERATE-CONTRACT'
          },
          {
            name: 'Menunggu Approval',
            value: 'APPROVE-CONTRACT'
          },
          {
            name: 'Mengunggu TTD Kontrak',
            value: 'SIGN-CONTRACT'
          },
          {
            name: 'Sudah TTD Kontrak',
            value: 'SIGNED-CONTRACT'
          },
          {
            name: 'Verifikasi Kedua',
            value: 'SECOND-VERIFICATION'
          },
          {
            name: 'Perbaikan Verifikasi Kedua',
            value: 'SECOND-VERIFICATION-CORRECTION'
          },
          {
            name: 'Inject Ulang IOS',
            value: 'IOS-FAILED'
          },
          {
            name: 'Selesai & Masuk ke HR Sistem',
            value: 'IOS-SUCCESS'
          },
        ]
      }
    ]
  }
]

export default function ManPowerRequestDetailModal({
  data,
  onClose = () => { }
}) {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const query = useQuery()
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useState('')
  const [statusSearch, setStatusSearch] = useState('')
  const [candidates, setCandidates] = useState([])

  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [candidateData, setCandidateData] = useState()
  const [errorCandidateData, setErrorCandidateData] = useState("")

  const [isLoadingCandidateData, setIsLoadingCandidateData] = useState(true)

  const fetchCandidateData = useCallback(async () => {
    try {
      setIsLoadingCandidateData(true)
      const { response } = await getCandidatePipelineDetails(selectedCandidate?.id)
      setCandidateData(response)
      setIsLoadingCandidateData(false)
    } catch (err) {
      setIsLoadingCandidateData(false)
      setErrorCandidateData(err?.response?.data?.error || "Error while fetching candidate data");
      console.log(err, 'error on fetching candidate data')
    }
  }, [selectedCandidate?.id])

  useEffect(() => {
    if (selectedCandidate) {
      fetchCandidateData()
    }
  }, [fetchCandidateData, selectedCandidate])

  const [selectedPage, setSelectedPage] = useState(1);
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(0);
  const [jpLink, setJPLink] = useState('')

  const [showFilter, setShowFilter] = useState(false)


  const fetchCandidates = useCallback(async () => {
    try {
      const { response } = await getCandidatePipeline(limit, selectedPage, debouncedSearch, data?.id, status)
      setCandidates(response?.data)
      setJPLink(response?.jobPostingLink)
      setTotalPage(response?.totalPage)
    } catch (err) {
      console.log(err)
    }
  }, [data?.id, debouncedSearch, limit, selectedPage, status])

  useEffect(() => {
    fetchCandidates()
  }, [fetchCandidates])

  useEffect(() => {
    setSelectedPage(1)
  }, [status, debouncedSearch])

  const { width } = useWindowSize()

  const [activeMenu, setActiveMenu] = useState('Personal Data')

  const personalDataTemplate = useMemo(() => {
    return [
      {
        name: 'Nama',
        value: candidateData?.name
      },
      {
        name: 'Tempat Lahir',
        value: candidateData?.CandidateComprehensive?.placeOfBirth
      },
      {
        name: 'Tanggal Lahir',
        value: candidateData?.CandidateComprehensive?.dateOfBirth ? moment(new Date(candidateData?.CandidateComprehensive?.dateOfBirth))?.format('LL') : moment(new Date(candidateData?.dateOfBirth))?.format('LL')
      },
      {
        name: 'Jenis Kelamin',
        value: candidateData?.CandidateComprehensive?.gender || candidateData?.gender
      },
      {
        name: 'Agama',
        value: candidateData?.CandidateComprehensive?.religion
      },
      {
        name: 'Golongan Darah',
        value: candidateData?.CandidateComprehensive?.bloodType
      },
      {
        name: 'Tinggi Badan',
        value: candidateData?.CandidateComprehensive?.bodyHeight ? candidateData?.CandidateComprehensive?.bodyHeight + 'cm' : ''
      },
      {
        name: 'Berat Badan',
        value: candidateData?.CandidateComprehensive?.bodyWeight ? candidateData?.CandidateComprehensive?.bodyWeight + 'kg' : ''
      },
      {
        name: 'Status Kawin',
        value: candidateData?.CandidateComprehensive?.maritalStatus
      },
      {
        name: 'Nomor Kartu Keluarga',
        value: candidateData?.CandidateComprehensive?.familyId
      },
      {
        name: 'Nomor KTP',
        value: candidateData?.CandidateComprehensive?.personId || candidateData?.personId
      },
      {
        name: 'Masa Berlaku KTP',
        value: candidateData?.CandidateComprehensive?.personIdPeriod
      },
      {
        name: 'Nomor NPWP',
        value: candidateData?.CandidateComprehensive?.taxId
      },
      {
        name: 'Email',
        value: candidateData?.CandidateComprehensive?.email || candidateData?.email
      },
      {
        name: 'Nomor HP',
        value: candidateData?.CandidateComprehensive?.phoneNumber || candidateData?.phoneNumber
      },
      {
        name: 'Nama Ibu Kandung',
        value: candidateData?.CandidateComprehensive?.motherName
      }
    ]
  }, [candidateData?.CandidateComprehensive?.bloodType, candidateData?.CandidateComprehensive?.bodyHeight, candidateData?.CandidateComprehensive?.bodyWeight, candidateData?.CandidateComprehensive?.dateOfBirth, candidateData?.CandidateComprehensive?.email, candidateData?.CandidateComprehensive?.familyId, candidateData?.CandidateComprehensive?.gender, candidateData?.CandidateComprehensive?.maritalStatus, candidateData?.CandidateComprehensive?.motherName, candidateData?.CandidateComprehensive?.personId, candidateData?.CandidateComprehensive?.phoneNumber, candidateData?.CandidateComprehensive?.placeOfBirth, candidateData?.CandidateComprehensive?.religion, candidateData?.CandidateComprehensive?.taxId, candidateData?.CandidateComprehensive?.personIdPeriod, candidateData?.dateOfBirth, candidateData?.email, candidateData?.gender, candidateData?.name, candidateData?.personId, candidateData?.phoneNumber])

  const familyDataTemplate = useMemo(() => {
    return [
      {
        type: 'Pribadi',
        data: [
          {
            name: candidateData?.CandidateComprehensive?.name || candidateData?.name || '-',
            placeOfBirth: candidateData?.CandidateComprehensive?.placeOfBirth || candidateData?.placeOfBirth || '-',
            dateOfBirth: candidateData?.CandidateComprehensive?.dateOfBirth
              ? moment(new Date(candidateData?.CandidateComprehensive?.dateOfBirth))?.format('LL')
              : (candidateData?.dateOfBirth ? moment(new Date(candidateData?.dateOfBirth))?.format('LL') : '-'),
            personId: candidateData?.CandidateComprehensive?.personId || candidateData?.personId || '-',
            gender: candidateData?.CandidateComprehensive?.gender || candidateData?.gender || '-'
          }
        ]
      },
      {
        type: 'Keluarga',
        data: candidateData?.CandidateFamilyMembers?.filter(obj => obj?.relation !== '-')
          ?.map(obj => {
            return {
              relation: obj?.relation,
              name: obj?.name,
              placeOfBirth: obj?.placeOfBirth,
              dateOfBirth: obj?.dateOfBirth ? moment(new Date(obj?.dateOfBirth))?.format('LL') : '',
              personId: obj?.nik,
              gender: obj?.gender
            }
          })
      }
    ]
  }, [candidateData?.CandidateComprehensive?.dateOfBirth, candidateData?.CandidateComprehensive?.gender, candidateData?.CandidateComprehensive?.name, candidateData?.CandidateComprehensive?.personId, candidateData?.CandidateComprehensive?.placeOfBirth, candidateData?.CandidateFamilyMembers, candidateData?.dateOfBirth, candidateData?.gender, candidateData?.name, candidateData?.personId, candidateData?.placeOfBirth])

  const educationDataTemplate = useMemo(() => {
    return [
      {
        type: 'FORMAL',
        data: candidateData?.CandidateEducations?.filter(obj => obj?.type === 'FORMAL')?.map(obj => {
          return {
            period: `${obj?.startYear} - ${obj?.endYear}`,
            name: obj?.name,
            mastery: obj?.mastery,
          }
        })
      },
      {
        type: 'NON FORMAL',
        data: candidateData?.CandidateEducations?.filter(obj => obj?.type !== 'FORMAL')?.map(obj => {
          return {
            period: `${obj?.startYear} - ${obj?.endYear}`,
            name: obj?.name,
            mastery: obj?.mastery,
          }
        })
      },
    ]
  }, [candidateData?.CandidateEducations])

  const jobDataTemplate = useMemo(() => {
    return candidateData?.CandidateWorkExperiences?.map(obj => {
      return {
        period: `${obj?.startYear} - ${obj?.endYear}`,
        companyName: obj?.companyName,
        jobTitle: obj?.position,
        jobDescription: obj?.description
      }
    })
  }, [candidateData?.CandidateWorkExperiences])

  const achievementDataTemplate = useMemo(() => {
    return candidateData?.CandidateAchievements?.map(obj => {
      return {
        year: `${obj?.year}`,
        achievement: obj?.achievement,
        remark: obj?.remark,
      }
    })
  }, [candidateData?.CandidateAchievements])

  const [activeDocumentType, setActiveDocumentType] = useState('Mandatory')

  const documentData = useMemo(() => {
    switch (activeDocumentType) {
      case 'Mandatory':
        return candidateData?.CandidateDocuments?.filter(obj =>
          obj?.type?.toUpperCase() === 'CV'
          || obj?.type?.toUpperCase() === 'SELF PICTURE'
          || obj?.type?.toUpperCase() === 'SURAT LAMARAN'
          || obj?.type?.toUpperCase() === 'KTP'
          || obj?.type?.toUpperCase() === 'IJAZAH'
          || obj?.type?.toUpperCase() === 'TRANSKRIP NILAI'
          || obj?.type?.toUpperCase() === 'KARTU KELUARGA'
          || obj?.type?.toUpperCase() === 'REKENING'
          || obj?.type?.toUpperCase() === 'NPWP'
        ) || []
      case 'Non-Mandatory':
        return candidateData?.CandidateDocuments?.filter(obj =>
          obj?.type?.toUpperCase() === 'JAMSOSTEK'
          || obj?.type?.toUpperCase() === 'SURAT REFERENSI KERJA'
          || obj?.type?.toUpperCase() === 'SURAT NIKAH'
          || obj?.type?.toUpperCase() === 'AKTA LAHIR'
          || obj?.type?.toUpperCase() === 'SURAT PERSETUJUAN KELUARGA'
          || obj?.type?.toUpperCase() === 'SKCK'
          || obj?.type?.toUpperCase() === 'SURAT KETERANGAN BERBADAN SEHAT'
        ) || []
      case 'Lainnya':
        return candidateData?.CandidateDocuments?.filter(obj =>
          obj?.type?.toUpperCase() !== 'CV'
          && obj?.type?.toUpperCase() !== 'SELF PICTURE'
          && obj?.type?.toUpperCase() !== 'SURAT LAMARAN'
          && obj?.type?.toUpperCase() !== 'KTP'
          && obj?.type?.toUpperCase() !== 'IJAZAH'
          && obj?.type?.toUpperCase() !== 'TRANSKRIP NILAI'
          && obj?.type?.toUpperCase() !== 'KARTU KELUARGA'
          && obj?.type?.toUpperCase() !== 'REKENING'
          && obj?.type?.toUpperCase() !== 'NPWP'
          && obj?.type?.toUpperCase() !== 'JAMSOSTEK'
          && obj?.type?.toUpperCase() !== 'SURAT REFERENSI KERJA'
          && obj?.type?.toUpperCase() !== 'SURAT NIKAH'
          && obj?.type?.toUpperCase() !== 'AKTA LAHIR'
          && obj?.type?.toUpperCase() !== 'SURAT PERSETUJUAN KELUARGA'
          && obj?.type?.toUpperCase() !== 'SKCK'
          && obj?.type?.toUpperCase() !== 'SURAT KETERANGAN BERBADAN SEHAT'
        ) || []
      default:
        return []
    }
  }, [activeDocumentType, candidateData?.CandidateDocuments])

  const content = useMemo(() => {
    if (isLoadingCandidateData) {
      return (
        <div className={Styles.loadingWrapper}>
          <LoadingAnimation />
        </div>
      )
    } else if (errorCandidateData) {
      return (
        <ErrorBoundary
          height='autog'
          message={errorCandidateData}
        />
      )
    } else {
      switch (activeMenu) {
        case 'Personal Data':
          return (
            <div className={`${Styles.menuContent} ${Styles.personalData}`}>
              {personalDataTemplate?.map((t, tI) => (
                <DataDetails
                  key={tI}
                  name={t?.name}
                  value={t?.value}
                />
              ))}
            </div>
          )
        case 'Data Keluarga':
          return (
            <div className={`${Styles.menuContent} ${Styles.familyData}`}>
              {familyDataTemplate
                ?.filter(obj => obj?.data?.length)
                ?.map((t, tI) => (
                  <div key={tI} className={Styles.fType}>
                    <h5>{t?.type}</h5>
                    {t?.data?.map((d, dI) => (
                      <div key={dI} className={Styles.fData}>
                        <DataDetails name='Nama' value={d?.name} />
                        {t?.type === 'Keluarga'
                          &&
                          <DataDetails name='Hubungan Keluarga' value={d?.relation} />
                        }
                        <DataDetails name='Tempat Lahir' value={d?.placeOfBirth} />
                        <DataDetails name='Tanggal Lahir' value={d?.dateOfBirth} />
                        <DataDetails name='NIK' value={d?.personId} />
                        <DataDetails name='Jenis Kelamin' value={d?.gender} />
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          )
        case 'Data Pendidikan':
          return (
            <div className={`${Styles.menuContent} ${Styles.educationData} ${!candidateData?.CandidateEducations?.length ? Styles.center : ''}`}>
              {
                candidateData?.CandidateEducations?.length
                  ?
                  educationDataTemplate
                    ?.filter(obj => obj?.data?.length)
                    ?.map((t, tI) => (
                      <div key={tI} className={Styles.fType}>
                        <h5>{t?.type}</h5>
                        {t?.data?.map((d, dI) => (
                          <div key={dI} className={Styles.fData}>
                            <DataDetails name='Periode' value={d?.period} />
                            <DataDetails name='Nama Universitas/Instansi' value={d?.name} />
                            <DataDetails name='Fakultas/Jurusan' value={d?.mastery} />
                          </div>
                        ))}
                      </div>
                    ))
                  :
                  <EmptyPlaceholder />
              }
            </div>
          )
        case 'Data Pekerjaan':
          return (
            <div className={`${Styles.menuContent} ${Styles.educationData} ${!jobDataTemplate?.length ? Styles.center : ''}`}>
              {
                jobDataTemplate?.length
                  ?
                  jobDataTemplate
                    ?.map((d, dI) => (
                      <div key={dI} className={Styles.fData}>
                        <DataDetails name='Periode' value={d?.period} />
                        <DataDetails name='Nama Perusahaan' value={d?.companyName} />
                        <DataDetails name='Jabatan' value={d?.jobTitle} />
                        <DataDetails name='Deskripsi Pekerjaan' value={d?.jobDescription} />
                      </div>
                    ))
                  :
                  <EmptyPlaceholder />
              }
            </div>
          )
        case 'Data Prestasi':
          return (
            <div className={`${Styles.menuContent} ${Styles.educationData} ${!achievementDataTemplate?.length ? Styles.center : ''}`}>
              {
                achievementDataTemplate?.length
                  ?
                  achievementDataTemplate
                    ?.map((d, dI) => (
                      <div key={dI} className={Styles.fData}>
                        <DataDetails name='Tahun' value={d?.startYear} />
                        <DataDetails name='Prestasi' value={d?.achievement} />
                        <DataDetails name='Ketarangan' value={d?.remark} />
                      </div>
                    ))
                  :
                  <EmptyPlaceholder />
              }
            </div>
          )
        case 'Dokumen':
          return (
            <div className={`${Styles.documentData} ${!documentData?.length ? Styles.center : ''}`}>

              {documentData.length
                ?
                <div className={Styles.documents}>
                  {documentData?.map((d, dI) => (
                    <DocCard
                      key={dI}
                      title={d?.type}
                      format={d?.format}
                      url={fileBaseUrl + d?.url}
                    />
                  ))}
                </div>
                :
                <EmptyPlaceholder
                  text='No Document Yet'
                />
              }
            </div>
          )
        default:
          return (
            <div className={`${Styles.documentData} ${Styles.center}`}>
              <EmptyPlaceholder
                text='Coming Soon'
              />
            </div>
          )
      }
    }
  }, [achievementDataTemplate, activeMenu, candidateData?.CandidateEducations?.length, documentData, educationDataTemplate, errorCandidateData, familyDataTemplate, isLoadingCandidateData, jobDataTemplate, personalDataTemplate])


  return (
    <ModalWrapper show={data ? true : false} handleClose={onClose}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <h3>Detail Man Power Request - {`${data?.name}`}</h3>
            <button disabled={!jpLink} className={Styles.jpButton} onClick={() => window.open(jpLink, '_blank')}>
              <Icon icon={'web'} size={20} />
              <span>Lihat Job Posting</span>
            </button>
            <span />
            <button className={Styles.cButton} onClick={onClose}>
              <Icon icon={'cross'} size={24} />
            </button>
          </div>
          <div className={Styles.clientName}>
            <Icon icon={'company'} size={24} />
            <span>{query.get('n') || ''}</span>
          </div>
        </div>
        {
          !selectedCandidate
            ?
            <div className={Styles.content}>
              <div className={Styles.cHeader}>
                <h4>Daftar TKO</h4>
                <div className={Styles.cHRight}>
                  <div className={Styles.searchWrapper}>
                    <SearchBar
                      value={search}
                      placeholder={'Cari nama kandidat'}
                      onChange={(e) => setSearch(e?.target?.value)}
                    />
                  </div>
                  <button onClick={() => setShowFilter(true)}>
                    <span>Filter Status</span>
                    <Icon icon={'adjustment'} size={20} />
                  </button>

                </div>
              </div>
              <InvitationTable
                type={'mp-request-details'}
                typeData={candidates}
                typeLimit={limit}
                setTypeLimit={setLimit}
                typeTotalPage={totalPage}
                typeSelectedPage={selectedPage}
                setTypeSelectedPage={setSelectedPage}
                onClickCandidate={setSelectedCandidate}
              />
            </div>
            :
            <div className={Styles.details}>
              <div className={Styles.dHeader}>
                <button onClick={() => setSelectedCandidate(null)}>
                  <Icon icon={'arrow-left'} size={24} />
                  <span>Detail TKO</span>
                </button>
              </div>
              <div className={Styles.dContent}>
                <div className={Styles.dLeft}>
                  <div className={Styles.dLTop}>
                    {
                      candidateData?.CandidateDocuments?.find(obj => obj?.type?.includes('FOTO'))
                        ?
                        <Avatar
                          src={fileBaseUrl + candidateData?.CandidateDocuments?.find(obj => obj?.type?.includes('FOTO'))?.url}
                          sx={{ width: width > 768 ? 60 : 40, height: width > 768 ? 60 : 40 }}
                        />
                        :
                        <Avatar sx={{ width: width > 768 ? 60 : 40, height: width > 768 ? 60 : 40 }}>{selectedCandidate?.name[0]}</Avatar>
                    }
                    <div className={Styles.name}>
                      <span>{selectedCandidate?.name}</span>
                      <p>{data?.name}</p>
                    </div>
                  </div>
                  <div className={Styles.dLBottom}>
                    {
                      [
                        'Personal Data',
                        'Data Keluarga',
                        'Data Pendidikan',
                        'Data Pekerjaan',
                        'Data Prestasi',
                        'Dokumen',
                        'Riwayat Lamaran Kerja'
                      ]
                        ?.map((m, mI) => (
                          <button
                            key={mI}
                            className={`${Styles.menu} ${activeMenu === m ? Styles.active : ''}`}
                            onClick={() => setActiveMenu(m)}
                          >
                            {m}
                          </button>
                        ))
                    }
                  </div>
                </div>
                <div className={`${Styles.dRight} ${(isLoadingCandidateData || errorCandidateData) ? Styles.center : ''}`}>
                  {!isLoadingCandidateData && !errorCandidateData &&
                    <div className={Styles.dRightHeader}>
                      <h4>{activeMenu}</h4>
                      {activeMenu === 'Dokumen'
                        &&
                        <div className={Styles.switcher}>
                          {[
                            'Mandatory',
                            'Non-Mandatory',
                            'Lainnya'
                          ]
                            ?.map((s, sI) => (
                              <button
                                key={sI}
                                onClick={() => setActiveDocumentType(s)}
                                className={`${Styles.switchButton} ${activeDocumentType === s ? Styles.active : ''}`}
                              >
                                {s}
                              </button>
                            ))
                          }
                        </div>
                      }
                    </div>
                  }

                  {content}
                </div>
              </div>
            </div>
        }

      </div>
      <ModalWrapper show={showFilter} handleClose={() => setShowFilter(false)}>
        <div className={`${Styles.filter} ${showFilter ? Styles.show : ''}`}>
          <div className={Styles.header}>
            <div className={Styles.statusSearch}>
              <SearchBar
                value={statusSearch}
                onChange={(e) => setStatusSearch(e?.target?.value)}
                placeholder={'Cari status'}
              />
            </div>
          </div>
          <div className={Styles.content}>
            {statusOptions?.map((t, tI) => (
              <div key={tI} className={Styles.type}>
                <h4>{t?.type}</h4>
                <div className={Styles.subTypes}>
                  {t?.subTypes?.map((s, sI) => (
                    <div key={sI} className={Styles.subType}>
                      {!!s?.subType
                        &&
                        <span>{s?.subType}</span>
                      }
                      {
                        s?.options?.map((o, oI) => (
                          <div key={oI} className={Styles.option}>
                            <CheckboxJobPosting
                              checked={status === o?.value}
                              onChange={() => {
                                if (status === o?.value) {
                                  setStatus('')
                                } else {
                                  setStatus(o?.value)
                                }
                                setShowFilter(false)
                              }} />
                            <span
                              onClick={() => {
                                if (status === o?.value) {
                                  setStatus('')
                                } else {
                                  setStatus(o?.value)
                                }
                                setShowFilter(false)
                              }}
                            >{o?.name}</span>
                          </div>
                        ))
                      }
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalWrapper>
    </ModalWrapper>
  )
}

const DataDetails = ({ name, value }) => {
  return (
    <div className={Styles.dataDetails}>
      <span>{name}</span>
      <span>:</span>
      <span>{value || '-'}</span>
    </div>
  )
}

const EmptyPlaceholder = ({
  text = 'No Data Yet'
}) => {

  return (
    <div className={Styles.empty}>
      <span>{text}</span>
    </div>
  )
}

const DocCard = ({
  format,
  title,
  url
}) => {
  const [size, setSize] = useState(0)

  useEffect(() => {
    getUrlFileSize(url, setSize)
  }, [url])

  const [isDownloading, setIsDownloading] = useState(false)

  return (
    <div className={Styles.docCard}>
      <div className={Styles.preview}>
        {
          format === 'png'
            || format === 'jpg'
            || format === 'jpeg'
            ?
            <img src={url} alt='' />
            :
            <Icon icon='pdf-type' size={27} />
        }
      </div>
      <div className={Styles.desc}>
        <span>{title}</span>
        <p>{formatBytes(size)}</p>
      </div>
      <button disabled={isDownloading} onClick={() => downloadFileFromUrl(url, title, format, setIsDownloading)}>
        <Icon icon='download' size={22} />
      </button>
    </div>
  )
}