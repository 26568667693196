import LoadingAnimation from "@Atom/LoadingAnimation";
import JobApplicationFormLayout from "@Organism/Additional/JobApplicationForm";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getRegisterSync } from "@Services/officer/recruitment";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from './style.module.scss'

export async function loader({ params }) {
  
    const packageRegisterSyncPromise = getRegisterSync(10, 1, '', params?.hash);
    return defer({
      packageRegisterSync: packageRegisterSyncPromise,
    });
  

}

export default function JobApplicationForm() {
  const { packageRegisterSync } = useLoaderData()
  return (
    <Suspense
      fallback={
        <div className={Styles.loadingWrapper}>
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={packageRegisterSync}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageAssignData) => (
          <JobApplicationFormLayout syncData={packageAssignData?.response} />
        )}
      </Await>

    </Suspense>
  )
}
