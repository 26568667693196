import React, { useState, useRef, useEffect, useCallback } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import Spinner from "@Atom/Spinner";

const Autocomplete = ({
  placeholder,
  disabled,
  options,
  value,
  setValue,
  autoClose,
  fixedHeight,
  loading,
  onChange,
  suggestions,
  type,
  searchTerm,
  setSearchTerm,
  ...props
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  const selectedItem = (item) => {
    setValue(item);
    setIsActive(false);
    setSearchTerm("");
  };

  const handleResetFilter = () => {
    setValue("");
    setSearchTerm("");
    setIsActive(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (disabled) {
      setValue(null);
    }
  }, [disabled, setValue]);

  useEffect(() => {
    if (searchTerm) {
      setIsActive(true);
    }
  }, [searchTerm]);


  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
        style={{ height: fixedHeight || "auto" }}
      >
        <input
          type="text"
          value={value ? value : searchTerm}
          placeholder={placeholder}
          onChange={onChange}
          className={`${Styles.searchInput}`}
        />
        {loading && <Spinner />}
        {value && (
          <div onClick={handleResetFilter} className={Styles.iconCloseWrapper}>
            <Icon icon="cross" size="22" className={Styles.iconClose} />
          </div>
        )}
      </div>
      {isActive && !disabled ? (
        <div className={Styles.options}>
          {options?.map((option, id) => {
            return (
              <div className={Styles.optionWrapper} key={id}>
                <div
                  className={Styles.option}
                  onClick={() => {
                    if (autoClose) {
                      selectedItem(option);
                      setIsActive(false);
                    } else {
                      selectedItem(option);
                    }
                  }}
                >
                  {option?.name}
                </div>
                {id === options?.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Autocomplete;
