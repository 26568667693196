import AutocompleteField from "@Molecule/AutocompleteField";
import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";

export default function OthersSection({ form, setForm, options, disableInput, errorField }) {
  const [bankNameTemp, setBankNameTemp] = useState(form?.bankName || "");

  const handleChange = useCallback(
    (e, inputName) => {
      let targetValue;
      const re = /^[0-9\b]+$/;
      if (inputName === "bankAccountNumber" || inputName === "roCode") {
        if (e.target.value === "" || re.test(e.target.value)) {
          targetValue = e.target.value;
        }
      } else if (inputName === "nearestHealthFacility") {
        targetValue = !isNaN(e.target.value)
          ? e.target.value
          : e.target.value.toUpperCase();
      } else {
        targetValue = e.target.value;
      }

      if (targetValue || targetValue === "") {
        setForm({
          ...form,
          [inputName]: targetValue,
        });
      }
    },
    [form, setForm]
  );

  useEffect(() => {
    setForm({
      ...form,
      bankName: bankNameTemp,
    });

    // eslint-disable-next-line
  }, [bankNameTemp]);

  useEffect(()=>{
    setBankNameTemp(form?.bankName || "")
  }, [form?.bankName])

  return (
    <div className={Styles.container}>
      <h3>Data Lainnya</h3>
      <div className={Styles.section}>
        <div className={Styles.content}>
          <AutocompleteField
            title={"Bank"}
            placeholder={"Pilih bank"}
            searchOptions={options?.banks}
            value={bankNameTemp}
            setValue={setBankNameTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={errorField && !bankNameTemp}
            errorText={"*Nama Bank belum diisi"}
          />
          <InputField
            title={"Nomor Rekening"}
            placeholder={"000"}
            value={form?.bankAccountNumber}
            onChange={(e) => handleChange(e, "bankAccountNumber")}
            required
            disabled={disableInput}
            isError={errorField && !form?.bankAccountNumber}
            errorText={"*Nomor belum diisi"}
          />
          <InputField
            title={"Kode RO"}
            placeholder={"000"}
            value={form?.roCode}
            onChange={(e) => handleChange(e, "roCode")}
            required
            // readOnly
            disabled
          />
          <InputField
            title={"Klinik/Puskesmas Terdekat"}
            placeholder={"Tulis nama klinik/puskesmas"}
            value={form?.nearestHealthFacility}
            onChange={(e) => handleChange(e, "nearestHealthFacility")}
            required
            disabled={disableInput}
            isError={errorField && !form?.nearestHealthFacility}
            errorText={"*Faskes belum diisi"}
          />
        </div>
      </div>
      <div className={Styles.section}>
        <h4>Sosial Media</h4>
        <div className={Styles.content}>
          <InputField
            title={"Facebook"}
            placeholder={"Usename"}
            value={form?.fbUsername}
            onChange={(e) => handleChange(e, "fbUsername")}
            disabled={disableInput}
          />
          <InputField
            title={"Instagram"}
            placeholder={"Usename"}
            value={form?.igUsername}
            onChange={(e) => handleChange(e, "igUsername")}
            disabled={disableInput}
          />
          <InputField
            title={"Twitter"}
            placeholder={"Usename"}
            value={form?.twitterUsername}
            onChange={(e) => handleChange(e, "twitterUsername")}
            disabled={disableInput}
          />
          <InputField
            title={"Linkedin"}
            placeholder={"Usename"}
            value={form?.linkedinUsername}
            onChange={(e) => handleChange(e, "linkedinUsername")}
            disabled={disableInput}
          />
        </div>
      </div>
    </div>
  );
}
