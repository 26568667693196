import StaticProgressBar from "@Atom/StaticProgressBar";
import Pagination from "@Molecule/Pagination";
import ManPowerRequestDetailModal from "@Molecule/_modal/ManPowerRequestDetailModal";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import TableEmptyHandler from "@Atom/TableEmptyHandler";

const TABLE_HEAD = [
  { id: "position", label: "Man Power", alignRight: false },
  { id: "requestDate", label: "Tanggal Permintaan", alignRight: false },
  { id: "fulfillmentTarget", label: "Target Pemenuhan", alignRight: false },
  { id: "requestAmount", numeric: true, label: "Jumlah Permintaan & Jumlah Terpenuhi", alignRight: false },
  { id: "action", numeric: true, label: "Action", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, firstColMinWidth } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0
                ? `${Styles.stickyLeft}`
                : `${Styles.hideOnMobile}`
            }
            sx={{ minWidth: index === 0 ? firstColMinWidth : "" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <span>Information</span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function ManPowerSection({
  general,
  data,
  trigger,
  setSelectedPosition,
  handlePagination,
  totalPage = 0,
  value = 5,
  setValue,
  selectedPage,
  setSelectedPage,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);

  const [selectedManPower, setSelectedManPower] = useState(null)

  // const query = useQuery()
  // const clientName = query.get('n')
  // const clientCode = query.get('c')

  // const { pathname } = useLocation()
  // const navigate = useNavigate()


  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "1200px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "250px";
    }
    return width;
  }, [windowSize]);

  var sorted_data = data
    ?.sort((a, b) => {
      return a.name - b.name;
    })
    ?.reverse();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const sliceIndex = useMemo(() => {
  //   const initial = pages.split("-");

  //   var nums = initial?.map(function (str) {
  //     return parseInt(str) - 1;
  //   });

  //   return nums;
  // }, [pages]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Man Power Request Summary</h3>
        <div className={Styles.indicators}>
          <div className={Styles.fulfilled}>
            <div />
            <span>Jumlah Terpenuhi</span>
          </div>
          <div className={Styles.candidates}>
            <div />
            <span>Total Kandidat</span>
          </div>
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              values={sorted_data}
              firstColMinWidth={firstColMinWidth}
            />
            <TableBody>
              {stableSort(sorted_data, getComparator(order, orderBy))
                // ?.slice(sliceIndex[0], sliceIndex[1] + 1)
                ?.map((each, i) => {
                  return (
                    <TableRow
                      key={i}
                      className={`${i % 2 === 1 ? Styles.evenRow : Styles.oddRow
                        } ${Styles.tableRow}`}
                    >
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.stickyLeft}`}
                      >
                        {each?.name}
                      </TableCell>
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        {moment(new Date(each?.createdAt))?.format("LL")}
                      </TableCell>
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        {moment(new Date(each?.expectedFulfillmentDate))?.format(
                          "LL"
                        )}
                      </TableCell>
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        <StaticProgressBar fulfilledAmount={each?.hired} candidatesAmount={each?.total || 0} requestAmount={each?.amount} />
                      </TableCell>
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        <button
                          className={Styles.NoBg}
                          // onClick={() => navigate(`${pathname}/man-power-request?n=${clientName}&c=${clientCode}&i=${each?.id}&p=${each?.position}`)}
                          onClick={() => setSelectedManPower(each)}
                        >See Detail</button>
                      </TableCell>
                      <TableCell
                        className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                        align="right"
                      >
                        {/* <button onClick={() => setSelectedPosition(each)}>
                          See More
                        </button> */}
                        <button
                          className={Styles.NoBg}
                          // onClick={() => navigate(`${pathname}/man-power-request?n=${clientName}&c=${clientCode}&i=${each?.id}&p=${each?.position}`)}
                          onClick={() => setSelectedManPower(each)}
                        >See Detail</button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!data?.length
          &&
          <div style={{ height: '350px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TableEmptyHandler />
          </div>
        }
        <div className={Styles.PaginationContainer}>
          <Pagination
            total={sorted_data?.length || 0}
            totalPage={totalPage || 0}
            value={value}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </div>
      </div>
      {
        selectedManPower
        &&
        <ManPowerRequestDetailModal
          data={selectedManPower}
          onClose={() => setSelectedManPower(null)}
          setData={setSelectedManPower}
        />
      }
    </div>
  );
}
