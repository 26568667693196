import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { useCallback, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Pagination from "@Molecule/Pagination";
import SearchBar from "@Atom/SearchBar";
import moment from "moment";
import Icon from "@Atom/Icon";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip from "@Atom/Tooltip";
import useQuery from "@Hooks/useQuery";

export default function OrdersSection({
  data = [],
  totalPage = 0,
  limit = 10,
  setLimit = () => {},
  selectedPage = 1,
  setSelectedPage = () => {},
  search = "",
  setSearch = () => {},
  setTrigger = () => {},
  setSelectedFLB,
  selectedPageFLB,
  setSelectedFLBForLog = () => {},
  verify = "no",
  setVerify = () => {},
  rbmView = false,
  isClientService,
  state,
}) {
  const navigate = useNavigate();
  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);
  const query = useQuery();
  const [expandedActions, setExpandedActions] = useState("");
  const { id } = useParams();

  const isNewFLB = useMemo(() => {
    return query?.get("tt") === "2" && data?.find((obj) => obj?.isNew);
  }, [data, query]);

  // console.log(state);

  const handleEdit = useCallback(
    (defaultValue) => {
      // setShowEdit(true);
      // setSelectedData(defaultValue);
      if (rbmView) {
        navigate(
          `/clients/orders/edit?n=${query?.get("n") || ""}&c=${
            query?.get("c") || ""
          }&i=${defaultValue?.schemeID}&eId=${id || ""}&u=${query.get("u")}`
        );
      } else {
        navigate(
          `/clients/orders/edit?n=${query?.get("n") || ""}&c=${
            query?.get("c") || ""
          }&i=${defaultValue?.schemeID}`
        );
      }
    },
    [navigate, query, rbmView, state]
  );

  const columns = useMemo(() => {
    return [
      {
        name: "no",
        title: "No",
        renderData: (row) => {
          return (
            <div className={Styles.docNumber}>
              <span>{data.indexOf(row) + 1}</span>
            </div>
          );
        },
      },
      ,
      {
        name: "schemeID",
        title: "FLB Number",
        renderData: (row) => (
          <div className={Styles.docNumber}>
            {row?.isNew && isNewFLB && (
              <Icon icon={"information-solid"} size={24} color={"#FFC43B"} />
            )}
            <span>{row?.schemeID || "-"}</span>
          </div>
        ),
      },
      {
        name: "docNumber",
        title: "Document Number",
        renderData: (row) => (
          <div className={Styles.docNumber}>
            <span>{row?.docNumber || "-"}</span>
          </div>
        ),
      },
      {
        name: "",
        title: "Manpower",
        renderData: (row) => <span>{row?.manPowers?.join(", ")}</span>,
        withTooltip: true,
      },
      {
        name: "",
        title: "Contract Type",
        renderData: (row) => <span>{row?.contractType?.join(", ")}</span>,
      },
      {
        name: "",
        title: "Location",
        renderData: (row) => <span>{row?.cities?.join(", ")}</span>,
        width: "150px",
        withTooltip: true,
      },
      {
        name: "",
        title: "Created Date",
        renderData: (row) => (
          <span>
            {row?.createdAt
              ? moment(new Date(row?.createdAt))?.format("ll")
              : "-"}
          </span>
        ),
      },
      {
        name: "",
        title: "Status",
        renderData: (row) => (
          <div
            className={`${Styles.status} ${
              row?.isVerified === "Expired"
                ? Styles.expired
                : row?.isVerified === null
                ? Styles.waiting
                : Styles.verified
            }`}
          >
            {row?.isVerified === "Expired"
              ? "Expired"
              : row?.isVerified === null
              ? "Waiting"
              : "Active"}
          </div>
        ),
      },
      {
        name: "",
        title: "Action",
        align: "left",
        renderData: (row) => (
          <div className={Styles.actions}>
            <button
              disabled={
                query?.get("tt") === "2" && row?.isVerified === "Expired"
              }
              onClick={(e) => {
                if (query?.get("tt") === "2" && row?.isVerified === "Expired") {
                  return;
                } else {
                  e.stopPropagation();
                  setExpandedActions(
                    row?.id !== expandedActions ? row?.id : ""
                  );
                }
              }}
            >
              <Icon icon={"three-dots"} size={24} />
            </button>
            {expandedActions === row?.id && (
              <div className={Styles.options}>
                {(row?.isVerified ||
                  (!row?.isVerified && query?.get("tt") !== "2")) && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (query?.get("tt") === "2") {
                        navigate(
                          `/clients/orders/proceed?n=${
                            query?.get("n") || ""
                          }&c=${query?.get("c") || ""}&tt=${
                            query?.get("tt") || "1"
                          }&i=${row?.schemeID}`
                        );
                      } else {
                        handleEdit(row);
                      }
                    }}
                  >
                    <span style={{ color: "#1571DE" }}>Edit</span>
                    <Icon icon="edit" size="20px" color={"#1571DE"} />
                  </div>
                )}

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    // if (query?.get('tt') === '2') {
                    //   navigate(
                    //     `/clients/orders/proceed?n=${query?.get("n") || ""}&c=${query?.get("c") || ""
                    //     }&tt=${query?.get('tt') || '1'}&i=${row?.schemeID}`
                    //   );
                    // } else {
                    //   handleEdit(row);
                    // }
                    setSelectedFLBForLog(row);
                  }}
                >
                  <span style={{ color: "#1571DE" }}>Log History</span>
                  <Icon icon="clock-back" size="20px" color={"#1571DE"} />
                </div>

                {query?.get("tt") === "2" && !row?.isVerified && (
                  <div
                    className={Styles.proceedButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/clients/orders/proceed?n=${query?.get("n") || ""}&c=${
                          query?.get("c") || ""
                        }&tt=${query?.get("tt") || "1"}&i=${row?.schemeID}`
                      );
                    }}
                  >
                    <span>Proses</span>
                  </div>
                )}
              </div>
            )}
          </div>
        ),
      },
    ];
  }, [
    expandedActions,
    handleEdit,
    isNewFLB,
    navigate,
    query,
    setSelectedFLBForLog,
    data,
  ]);
  const columnsToMap = useMemo(() => {
    return rbmView && isClientService
      ? columns?.filter((obj) => obj?.title !== "Action")
      : columns;
  }, [columns, rbmView, isClientService]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Form Layanan Bisnis</h3>
        <div className={Styles.searchbarWrapper}>
          <SearchBar
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
            placeholder={"Search Doc Number"}
          />
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        {isNewFLB ? (
          <div className={Styles.newInfo}>
            <Icon icon={"information-solid"} size={24} color={"#FFC43B"} />
            <span>
              Terdapat FLB baru yang telah didelegasikan untuk kamu. Silahkan
              proses FLB tersebut
            </span>
          </div>
        ) : (
          <div className={Styles.switcher}>
            {/* {
                [
                  {
                    title: 'Active',
                    value: 'yes',
                    amount: data?.length,
                  },
                  {
                    title: 'Waiting for Approval',
                    value: 'no',
                    amount: data?.length,
                  }
                ]
                  ?.map((each, i) => (
                    <div className={verify === each?.value ? Styles.active : ''} key={i} onClick={() => setVerify(each?.value)}>
                      <span>{each?.title}</span>
                    </div>
                  ))
              } */}
          </div>
        )}

        <div className={Styles.content}>
          <div
            className={Styles.tableWrapper}
            style={{ flexGrow: data?.length ? 1 : "auto" }}
          >
            <table>
              <thead>
                <tr>
                  {columnsToMap?.map((each, i) => (
                    <th
                      key={i}
                      style={{
                        textAlign: each?.align || "left",
                        width: each?.width || "auto",
                      }}
                    >
                      {each?.title}
                    </th>
                  ))}
                </tr>
              </thead>
              {!!data?.length && (
                <tbody>
                  {data?.map((each, i) => (
                    <tr
                      key={i}
                      className={`${i % 2 === 0 ? Styles.odd : ""} ${
                        each?.isNew && isNewFLB ? Styles.new : ""
                      }`}
                      onClick={() =>
                        navigate(
                          `/clients/orders/details/${each?.schemeID}?n=${
                            query?.get("n") || ""
                          }&c=${query?.get("c") || ""}`
                        )
                      }
                    >
                      {columnsToMap?.map((c, iC) => (
                        <td key={iC} style={{ textAlign: c?.align || "left" }}>
                          {c?.withTooltip ? (
                            <Tooltip
                              content={
                                c?.renderData
                                  ? c?.renderData(each)
                                  : each[c?.name]
                              }
                              direction="right"
                              width="150px"
                            >
                              {c?.renderData ? (
                                <div className={Styles.div}>
                                  {c?.renderData(each)}
                                </div>
                              ) : (
                                <div
                                  style={{ width: c?.width || "auto" }}
                                  className={Styles.div}
                                >
                                  {" "}
                                  {each[c?.name]}{" "}
                                </div>
                              )}
                            </Tooltip>
                          ) : (
                            <div className={!c?.renderData ? Styles.div : ""}>
                              {c?.renderData
                                ? c?.renderData(each)
                                : each[c?.name]}
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>

          {!data?.length && (
            <div className={Styles.emptyWrapper}>
              <TableEmptyHandler />
            </div>
          )}
          {/* {data?.map((each, i) => {
          return (
            <InformationContact
              key={i}
              title={`Contact ${i + 1}`}
              name={each?.name}
              position={each?.jobTitle}
              email={each?.email}
              phone={each?.phoneNumber}
              groupName={each?.workUnit}
              onDelete={(e) => handleDelete(e, each?.id)}
              onEdit={(e) => handleEdit(each)}
            />
          );
        })} */}
          <div className={Styles.paginationWrapper}>
            <Pagination
              total={data?.length || 0}
              totalPage={totalPage || 0}
              value={limit}
              setValue={setLimit}
              pages={pages}
              setPages={setPages}
              pageList={pageList}
              setPageList={setPageList}
              selectedPage={selectedPageFLB}
              setSelectedPage={setSelectedFLB}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
