import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/api";
import { calculateAverage } from "@Helpers/calculateAverage";
import Images from "@Theme/Images";
import { useMemo } from "react";
import Styles from "./style.module.scss";

export default function InterviewResultModal({ data, onClose = () => {} }) {
  const details = useMemo(() => {
    return {
      name: data?.name,
      position: data?.position,
      scores: data?.CandidateEvaluation?.EvaluationScores?.map((obj) => {
        return {
          ...obj,
          name: obj?.label,
          value: obj?.score,
        };
      }),
      notes: data?.CandidateEvaluation?.note,
      documentation: data?.CandidateEvaluation?.image
        ? fileBaseUrl + data?.CandidateEvaluation?.image
        : "",
    };
  }, [
    data?.CandidateEvaluation?.EvaluationScores,
    data?.CandidateEvaluation?.image,
    data?.CandidateEvaluation?.note,
    data?.name,
    data?.position,
  ]);

  const template = useMemo(() => {
    return [
      {
        title: "Penilaian",
        component: (
          <table className={Styles.scores}>
            <thead>
              <tr>
                <th>Deskripsi</th>
                <th>Nilai</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {details?.scores?.map((obj) => (
                <tr key={obj?.name}>
                  <td>{obj?.name}</td>
                  <td className={Styles.bold}>{(+obj?.value)?.toFixed(1)}</td>
                  <td></td>
                </tr>
              ))}
              <tr>
                <td>Rata-rata skor</td>
                <td>
                  <span>
                    {calculateAverage(
                      details?.scores?.map((obj) => +obj?.value)
                    )}
                  </span>
                </td>
                <td>
                  dari <span>5.0</span>
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      {
        title: "Catatan",
        component: <p>{details?.notes}</p>,
      },
      {
        title: "Foto Interview",
        component: <img src={details?.documentation} alt="" />,
      },
    ];
  }, [details?.documentation, details?.notes, details?.scores]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Hasil Interview</h3>
        <button onClick={onClose}>
          <Icon icon={"cross"} size={24} />
        </button>
      </div>
      <div className={Styles.content}>
        <div className={Styles.intro}>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_USER} alt="name" />
            </div>
            <div>
              <span>Candidate</span>
              <span>{toTitleCase(data?.name)}</span>
            </div>
          </div>
          <div className={Styles.stackedIntro}>
            <div>
              <img src={Images.INVITE_JOB} alt="name" />
            </div>
            <div>
              <span>Job</span>
              <span>{details?.position || "-"}</span>
            </div>
          </div>
        </div>

        {template?.map((obj) => (
          <div key={obj?.title} className={Styles.component}>
            <span>{obj?.title}</span>
            {obj?.component}
          </div>
        ))}
      </div>
    </div>
  );
}
