import CandidateStatusIndicator from '@Atom/CandidateStatusIndicator';
import CompletionIndicatorIcons from '@Atom/CompletionIndicatorIcons';
import Icon from '@Atom/Icon';
import ModalWrapper from '@Atom/ModalWrapper';
import OutlinedSearchBar from '@Atom/OutlinedSearchBar';
import StackedProfile from '@Atom/StackedProfile';
import Tooltip from '@Atom/Tooltip';
import { fileBaseUrl } from '@Config/api';
import { capitalizedFirstLetter } from '@Helpers/capitalizedFirstLetter';
import UseOutsideClick from '@Hooks/useOutsideClick';
import useQuery from '@Hooks/useQuery';
import CustomTable from '@Molecule/CustomTable';
import FilterSlider from '@Molecule/FilterSlider';
import MenuListPopup from '@Molecule/MenuListPopup';
import Stepper from '@Molecule/Stepper';
import CancelJoinModal from '@Molecule/_modal/BusinessSupport/CancelJoinModal';
import LogModal from '@Molecule/_modal/BusinessSupport/LogModal';
import RejoinModal from '@Molecule/_modal/BusinessSupport/RejoinModal';
import DownloadExcelFilterPopup from '@Molecule/_popup/DownloadExcelFilterPopup';
import {
    getExtendCandidates,
    getCandidatesDataForExport,
    getExtendCandidatesSync,
    resendContract,
} from '@Services/support/candidate';
import Images from '@Theme/Images';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Styles from './style.module.scss';
import ResendWa from '@Molecule/_modal/ResendContratWaConfirm';
import SentContractModal from '@Molecule/_modal/BusinessSupport/SentContractModal';
import SecondVerificationModal from '@Molecule/_modal/BusinessSupport/SecondVerificationModal';
import RevertStageModal from '@Molecule/_modal/BusinessSupport/RevertStageModal';
import { Tooltip as TooltipMUI } from '@mui/material';
import { useDebounce } from '@Hooks/useDebounce';
import SearchBar from '@Atom/SearchBar';
import moment from 'moment';

export default function CompleteContractListLayout({ initialData }) {

    const [expandFilterSlider, setExpandFilterSlider] = useState(false);

    const maxLength = 20;
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('');

    const [stage, setStage] = useState('');
    const [search, setSearch] = useState('');
    const [searchName, setSearchName] = useState('');

    const [clientSearch, setClienteSearch] = useState('');
    const [selectedClient, setSelecetedClient] = useState(null);

    const [roSearch, setRoSearch] = useState('');
    const [selectedRo, setSelectedRo] = useState(null);

    const [statusCandidate, setStatusCandidate] = useState([]);
    const [regionCandidate, setRegionCandidate] = useState([]);

    const [placement, setPlacement] = useState('');
    const [placementObj, setPlacementObj] = useState(null);

    const [positionName, setPossitionName] = useState('');
    const [valueIdCandiate, setValueIdCandidate] = useState(null);

    const [trigger, setTrigger] = useState('');
    const [data, setData] = useState(initialData || null);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const debouncedSearch = useDebounce(search, 300);
    const debouncedSearchName = useDebounce(searchName || '', 300);
    const [dataSync, setDataSync] = useState(null);
    const [isLoadingSync, setIsLoadingSync] = useState(false);
    const [selectedButtonStepContract, setSelectedButtonStepContract] = useState('Joined');

    useEffect(() => {
        if (selectedButtonStepContract) {
            setSelectedButtonStepContract(selectedButtonStepContract);
        } else {
            setSelectedButtonStepContract('Joined');
        }
    }, [selectedButtonStepContract]);

    const refetchDataSync = useCallback(async () => {
        try {
            setIsLoadingSync(true);
            const { response } = await getExtendCandidatesSync(debouncedSearchName || '?');
            setDataSync(response);
            setIsLoadingSync(false);
        } catch (err) {
            setIsLoadingSync(false);
            console.log(err);
        }
    }, [debouncedSearchName]);

    useEffect(() => {
        refetchDataSync();
    }, [refetchDataSync]);

    const refetchData = useCallback(async () => {
        try {
            setIsFetchingData(true);
            const { response } = await getExtendCandidates(
                limit,
                page,
                "Joined",
                selectedClient?.code || '',
                statusCandidate || [],
                selectedRo?.employeeId || '',
                positionName || '',
                placementObj?.code || '',
                valueIdCandiate?.id || '',
                regionCandidate || [],
                "Extend"
            );
            setData(response);
            setIsFetchingData(false);
        } catch (err) {
            setIsFetchingData(false);
            console.log(err);
        }
    }, [
        limit,
        page,
        // selectedButtonStepContract,
        selectedClient?.code,
        statusCandidate,
        regionCandidate,
        selectedRo?.employeeId,
        positionName,
        placementObj?.code,
        valueIdCandiate?.id,
    ]);

    useEffect(() => {
        refetchData();
    }, [refetchData, trigger]);


    const candidatesTemplate = useMemo(() => {
        return {
            data: data?.data?.map((obj) => ({
                ...obj,
                id: obj.id,
                name: obj.name,
                profilePicture: obj.CandidateDocuments
                    ? obj?.CandidateDocuments?.url?.includes('http')
                        ? obj?.CandidateDocuments?.url?.replace('https://myworkspace', 'https://api1.myworkspace')
                        : fileBaseUrl + obj?.CandidateDocuments?.url
                    : Images.AVA_DEFAULT,
                phoneNumber: obj.phoneNumber,
                roName: obj?.recruiter?.name,
                roProfilePicture: obj?.recruiter?.profilePicture
                    ? obj?.recruiter?.profilePicture?.includes('http')
                        ? obj?.recruiter?.profilePicture?.replace('https://myworkspace', 'https://api1.myworkspace')
                        : fileBaseUrl + obj?.recruiter?.profilePicture
                    : Images.AVA_DEFAULT,
                region: obj?.ContractDocument?.Region?.name,
                stage: obj?.stage,
                step: obj?.step,
                status: obj?.status,
                jobLevel: obj?.ExpectedPosition?.name,
                clientName: obj?.ExpectedPosition?.OrderScheme?.Client?.name,
                tag: obj?.tag,
                roPhoneNumber: obj?.recruiter?.phoneNumber,
                personId: obj?.ContractDocument ? obj?.ContractDocument?.employeeId : null,
                portal: obj?.portal?.includes('Peralihan') ? 'Peralihan' : 'Normal',
                stepStatus: obj?.stepStatus,
                startDate: obj?.startDate ? obj?.startDate?.replaceAll("/", "-") : "",
                endDate: obj?.endDate ? obj?.endDate?.replaceAll("/", "-") : "",
            })),
            columns: [
                {
                    name: 'name',
                    label: 'Nama',
                    renderData: (row) => (
                        <div className={Styles.stackedProfileRow}>
                            <StackedProfile
                                profilePicture={row?.profilePicture}
                                title={row?.name}
                                label={`${row?.phoneNumber} • ${row?.personId || '-'}`}
                            />
                        </div>
                    ),
                },
                {
                    name: 'region',
                    label: 'Region',
                    hideOnMobile: true,
                },
                {
                    name: 'clientName',
                    label: 'Klien',
                    hideOnMobile: true,
                    renderData: (row) => (
                        <div className={Styles.clientNameRow}>
                            <TooltipMUI title={<span>{row?.clientName || '-'}</span>}>
                                <span>
                                    {row?.clientName
                                        ? row?.clientName?.length > maxLength
                                            ? row?.clientName?.substring(0, maxLength)?.toUpperCase() + '...'
                                            : row?.clientName?.toUpperCase()
                                        : '-'}
                                </span>
                            </TooltipMUI>
                        </div>
                    ),
                },
                {
                    name: "startDate",
                    label: "Tanggal Kontrak",
                    renderData: (row) => (
                      <div className={Styles.dates}>
                        <div>
                          <span>Start</span>
                          <span> : {row?.startDate ? row?.startDate : "-"}</span>
                          {/* <span> : {row?.startDate ? moment(row?.startDate)?.format('DD-MM-YYYY') : "-"}</span> */}
                        </div>
                        <div>
                          <span>End</span>
                          <span> : {row?.endDate ? row?.endDate : "-"}</span>
                          {/* <span> : {row?.endDate ? moment(row?.endDate)?.format('DD-MM-YYYY') : "-"}</span> */}
                        </div>
                      </div>
                    ),
                  },
            ],
        };
    }, [
        // selectedButtonStepContract,
        data,
        // fileBaseUrl,
        // Images,
        maxLength,
        // navigate,
        // pathname,
        // setExpandAction,
        // setShowConfirmResendBM,
        // setShowConfirmResendCandidate,
        // setDataToInject,
        // setIdToRejoin,
        // setIdToCancel,
        // setSelectedLog,
        // setShowLog,
        // setIdToRevert,
        // getType,
    ]);

    const onClickOption = (status, stage, tag, jobType, obj, name) => {
        if (
            (status === 'BS-REGISTERED' && stage === 'REGISTER') ||
            (status === 'BS-REGISTERED' && tag === 'Declined' && stage === 'FIRST-VERIFICATION') ||
            (status?.includes('BS-REGISTERED-HOLD') && stage === 'REGISTER') ||
            (status?.includes('BS-REGISTERED-HOLD') && tag === 'Declined' && stage === 'FIRST-VERIFICATION') ||
            (status?.includes('BS-REGISTERED-CONTRACTED') && stage === 'REGISTER') ||
            (status?.includes('BS-REGISTERED-CONTRACTED') && tag === 'Declined' && stage === 'FIRST-VERIFICATION')
        ) {
            setSelectedButtonStepContract('Verifikasi Pertama');
        } else if (
            (status === 'BS-PRE-CONTRACT' && stage === 'GENERATE-CONTRACT' && tag !== 'Declined') ||
            (status === 'BS-PRE-CONTRACT' && tag === 'Declined' && stage === 'GENERATE-CONTRACT') ||
            (status === 'BS-PRE-CONTRACT' && stage === 'APPROVE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && stage === 'GENERATE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && tag === 'Declined' && stage === 'GENERATE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && stage === 'APPROVE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && stage === 'GENERATE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && tag === 'Declined' && stage === 'GENERATE-CONTRACT') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && stage === 'APPROVE-CONTRACT')
        ) {
            setSelectedButtonStepContract('Generate Kontrak');
        } else if (
            (status === 'BS-PRE-CONTRACT' && stage === 'SIGN-CONTRACT' && tag === 'Invited') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && stage === 'SIGN-CONTRACT' && tag === 'Invited') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && stage === 'SIGN-CONTRACT' && tag === 'Invited')
        ) {
            setSelectedButtonStepContract('TTD Kontrak');
        } else if (
            (status === 'BS-PRE-CONTRACT' && stage === 'SIGN-CONTRACT' && tag === 'Accepted') ||
            (status === 'BS-PRE-CONTRACT' && tag === 'Declined' && stage === 'SECOND-VERIFICATION') ||
            (status === 'BS-CONTRACT' && stage === 'DONE') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && stage === 'SIGN-CONTRACT' && tag === 'Accepted') ||
            (status?.includes('BS-PRE-CONTRACT-HOLD') && tag === 'Declined' && stage === 'SECOND-VERIFICATION') ||
            (status?.includes('BS-CONTRACT-HOLD') && stage === 'DONE') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && stage === 'SIGN-CONTRACT' && tag === 'Accepted') ||
            (status?.includes('BS-PRE-CONTRACT-CONTRACTED') && tag === 'Declined' && stage === 'SECOND-VERIFICATION') ||
            (status?.includes('BS-CONTRACT-CONTRACTED') && stage === 'DONE')
        ) {
            setSelectedButtonStepContract('Verifikasi Kedua');
        } else if (stage === 'IOS-SUCCESS') {
            setSelectedButtonStepContract('Joined');
        }
    };

    return (
        <div className={Styles.container}>
            <div className={Styles.stickyTop}>
                <div className={Styles.controllerWrapper}>
                    <div className={Styles.topSearch}>
                        <div className={Styles.total}>
                            <h4>Total Kontrak Diperpanjang</h4>
                            <div className={Styles.divider} />
                            <div>{data?.totalData || '0'}</div>
                        </div>
                        <div className={Styles.searchWrapper}>
                            <button className={Styles.filterButton} onClick={() => setExpandFilterSlider(true)}>
                                <span>Filter</span>
                                <Icon icon={'adjustment'} size={24} />
                            </button>

                            <div className={Styles.searchbar}>
                                <SearchBar
                                    placeholder={'Cari berdasarkan nama/nomor hp'}
                                    value={searchName}
                                    onChange={setSearchName}
                                    isRecruitment={true}
                                    isBS={true}
                                    options={dataSync?.candidates || []}
                                    type={'none'}
                                    isLoadingFilter={isLoadingSync}
                                    onClickOption={onClickOption}
                                    setValueIdCandidate={setValueIdCandidate}
                                    valueIdCandidate={valueIdCandiate}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className={Styles.lastHeader}>
                        <div className={`${Styles.stepperBox}`}>
                            {dataStep?.map((el, index) => {
                                return (
                                    <div
                                        className={`${Styles.step}`}
                                        key={index}
                                        onClick={() => {
                                            setSelectedButtonStepContract(el?.code);
                                            if (selectedButtonStepContract !== el?.code) {
                                                setStatusCandidate([]);
                                                setRegionCandidate([]);
                                                setPage(1);
                                            }
                                        }}>
                                        {index === 0 ? (
                                            <img
                                                src={
                                                    selectedButtonStepContract === el?.code
                                                        ? Images.STEPPER_ACTIVE_1
                                                        : Images.STEPPER_1
                                                }
                                                alt=""
                                            />
                                        ) : index === dataStep?.length - 1 ? (
                                            <img
                                                src={
                                                    selectedButtonStepContract === el?.code
                                                        ? Images.STEPPER_ACTIVE_3
                                                        : Images.STEPPER_3
                                                }
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    selectedButtonStepContract === el?.code
                                                        ? Images.STEPPER_ACTIVE_2
                                                        : Images.STEPPER_2
                                                }
                                                alt=""
                                            />
                                        )}
                                        <div className={`${index === 0 && Styles.leftPlace}`}>
                                            <div>{el?.id}</div>
                                            <span>{el?.name}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div> */}

                    <div className={Styles.controllers}>
                        <div className={Styles.activeFilterWrapper}>
                            {statusCandidate?.length === 0 &&
                            regionCandidate?.length === 0 &&
                            !roSearch &&
                            !clientSearch &&
                            !placement &&
                            !positionName ? null : (
                                <span className={Styles.result}>
                                    {data?.totalData} {data?.totalData > 1 ? 'Results' : 'Result'}
                                </span>
                            )}

                            {statusCandidate?.length === 0 &&
                            regionCandidate?.length === 0 &&
                            !roSearch &&
                            !clientSearch &&
                            !placement &&
                            !positionName ? null : (
                                <span className={Styles.active}>Active Filter:</span>
                            )}

                            {statusCandidate?.length > 0 ? (
                                <span className={Styles.status}>
                                    <span>Status: {statusCandidate?.join(', ')}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setStatusCandidate([]);
                                        }}
                                    />
                                </span>
                            ) : null}
                            
                            {regionCandidate?.length > 0 ? (
                                <span className={Styles.status}>
                                    <span>Region: {regionCandidate?.join(', ')}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setRegionCandidate([]);
                                        }}
                                    />
                                </span>
                            ) : null}

                            {roSearch ? (
                                <span className={Styles.status}>
                                    <span>RO: {roSearch}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setRoSearch('');
                                            setSelectedRo(null);
                                        }}
                                    />
                                </span>
                            ) : null}

                            {clientSearch ? (
                                <span className={Styles.status}>
                                    <span>Klien: {clientSearch}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setClienteSearch('');
                                            setSelecetedClient(null);
                                        }}
                                    />
                                </span>
                            ) : null}

                            {placement ? (
                                <span className={Styles.status}>
                                    <span>Kota: {placement}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setPlacement('');
                                            setPlacementObj(null);
                                        }}
                                    />
                                </span>
                            ) : null}

                            {positionName ? (
                                <span className={Styles.status}>
                                    <span>Posisi: {positionName}</span>
                                    <Icon
                                        icon={'cross'}
                                        size={16}
                                        className={Styles.icon}
                                        onClick={() => {
                                            setPossitionName('');
                                        }}
                                    />
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className={Styles.tableWrapper}>
                <CustomTable
                    data={candidatesTemplate?.data}
                    columns={candidatesTemplate?.columns}
                    value={limit}
                    setValue={setLimit}
                    selectedPage={page}
                    setSelectedPage={setPage}
                    totalPage={data?.totalPage}
                    isV2
                    noContainerBorder
                    isLoading={isFetchingData}
                    // activeController={stage ? null : (status || 'BS-REGISTERED')}
                    // setActiveController={setStatus}
                    // searchTerm={search}
                    // setSearchTerm={setSearch}
                    // noStickyPagination={windowSize[0] <= 480 ? true : false}
                    stickyTop
                    totalData={data?.totalData}
                    isBs={true}
                />
            </div>

            <FilterSlider
                show={expandFilterSlider}
                type="completeextend"
                onOutsideClick={() => setExpandFilterSlider(false)}
                dataSync={dataSync}
                selectedButtonStepContract={selectedButtonStepContract}
                isLoadingSync={isLoadingSync}
                setRegionFilter={setRegionCandidate}
                regionFilter={regionCandidate}
                setClientFilter={setClienteSearch}
                clientFilter={clientSearch}
                setRoSearchFilter={setRoSearch}
                roSearchFilter={roSearch}
                setStatusFilter={setStatusCandidate}
                statusFilter={statusCandidate}
                placementFilter={placement}
                setPlacementFilter={setPlacement}
                positionNameFilter={positionName}
                setPossitionNameFilter={setPossitionName}
                selectedClient={selectedClient}
                setSelecetedClient={setSelecetedClient}
                selectedRo={selectedRo}
                setSelectedRo={setSelectedRo}
                placementObj={placementObj}
                setPlacementObj={setPlacementObj}
            />
        </div>
    );
}
