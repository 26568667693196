import Images from '@Theme/Images'
import Styles from './style.module.scss'
import Icon from '@Atom/Icon'

export default function LogModal({
  data = [],
  setShow = () => { }
}) {

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.iconWrapper}>
          <img src={Images.LOG_ICON} alt='' />
        </div>
        <span>Riwayat Log</span>
        <button onClick={() => setShow(false)}><Icon icon={'cross'} size={24} /></button>
      </div>
      {data?.length
        ?
        <div className={Styles.content}>
          {data?.map((each, i) => (
            <div key={i} className={Styles.each}>
              <div className={Styles.overflowHider} />
              <div className={Styles.bullet} />
              <div className={Styles.left}>
                <span>{each?.label}</span>
                {each?.remark
                  && <span>{each?.remark}</span>
                }
              </div>
              <span>{each?.value}</span>
            </div>
          ))}
        </div>
        :
        <div className={Styles.empty}>
          <img src={Images.LOG_ICON} alt='' />
          <span>Log Kosong</span>
          <span>Belum ada log yang tercatat</span>
        </div>
      }

    </div>
  )
}