import { useCallback, useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import Images from '@Theme/Images'
import Icon from '@Atom/Icon'
import FormEmptyPlaceholder from '@Molecule/_candidate/EmptyPlaceholder/FormEmptyPlaceholder'
import NotEmpty from './NotEmpty'

export default function FifthStep({
  form,
  setForm,
  errorField
}) {

  const onAdd = useCallback(() => {
    setForm([
      ...form,
      {
        id: Math.random(),
        year: null,
        performance: null,
        description: null
      }
    ])
  }, [form, setForm])

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);


  return (
    <div className={Styles.container}>
      <h3 ref={headerRef}>Data Prestasi</h3>
      <div className={Styles.content}>
        <div style={{ position: 'sticky', top: `${headerHeight}px` }} className={`${Styles.left} ${form?.length ? Styles.zero : ''}`}>
          <img src={Images?.PERFORMANCE_BANNER} alt="banner" className={form?.length ? Styles.none : ''} />
          <div className={Styles.buttonWrapper}>
            <button onClick={onAdd}>
              <Icon icon="add-circle" size={20} />
              <span>Data Prestasi</span>
            </button>
          </div>
        </div>
        <div className={Styles.right}>
          {!form?.length
            ? <FormEmptyPlaceholder
              title={"Belum ada Data Prestasi Ditambahkan"}
              description={"Tambahkan data prestasi anda, klik pada tombol tambah data pendidikan"}
            />
            : <NotEmpty
              form={form}
              setForm={setForm}
              errorField={errorField}
            />
          }
        </div>
      </div>
    </div>
  )
}