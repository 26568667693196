import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { makeRandomString } from "@Helpers/makeRandomString";
import { deleteClientContact } from "@Services/manager/client";
import { useState } from "react";
import Styles from "./style.module.scss";

export default function InformationContactSection({
  data,
  setData,
  setTrigger,
  setShowAdd,
  setShowEdit,
  setSelectedData,
  rbmView = false
}) {
  const [isProcessing, setIsProcessing] = useState(false)

  const handleDelete = async (e, id) => {
    try {
      e.preventDefault();
      setIsProcessing(true)
      await deleteClientContact(id);
      setIsProcessing(false)
      setTrigger(makeRandomString(5));
    } catch (err) {
      setIsProcessing(false)
      console.log(err);
    }
  };

  const handleEdit = (defaultValue) => {
    setShowEdit(true);
    setSelectedData(defaultValue);
  };

  // const isNew = true;

  const columns = [
    {
      name: 'name',
      title: 'Name',
    },
    {
      name: 'jobTitle',
      title: 'Position',
    },
    {
      name: 'email',
      title: 'Email'
    },
    {
      name: 'phoneNumber',
      title: 'Phone'
    },
    {
      name: 'workUnit',
      title: 'Unit Kerja'
    },
    {
      name: '',
      title: 'Action',
      align: 'right',
      renderData: (row) => (
        <div className={Styles.actions}>
          <button disabled={isProcessing} onClick={() => handleEdit(row)} >
            <Icon
              icon="edit"
              size="20px"
              color={'#1571DE'}
            />
          </button>
          <button disabled={isProcessing} onClick={(e) => handleDelete(e, row?.id)} >
            <Icon
              icon="trash"
              size="20px"
              color={'#FF3E13'}
            />
          </button>
        </div>
      )
    }
  ]

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Information Contact</h3>
        {!rbmView
          &&
          <div className={Styles.topButton} onClick={() => setShowAdd(true)}>
            <Button
              type="primary"
              title={
                <span>
                  <Icon icon="circle-plus" size={20} color="white" /> Information
                  Contact
                </span>
              }
            />
          </div>
        }

      </div>
      <div className={Styles.content}>
        <table>
          <thead>
            <tr>
              {columns?.map((each, i) => (
                <th key={i} style={{ textAlign: each?.align || 'left' }}>
                  {each?.title}
                </th>
              ))}
            </tr>
          </thead>
          {!!data?.length
            &&
            <tbody>
              {data?.map((each, i) => (
                <tr key={i} className={i % 2 === 0 ? Styles.odd : ''}>
                  {columns?.map((c, iC) => (
                    <td key={iC} style={{ textAlign: c?.align || 'left' }}>
                      {c?.renderData ? c?.renderData(each) : each[c?.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          }
        </table>
        {!data?.length
          &&
          <TableEmptyHandler />
        }
        {/* {data?.map((each, i) => {
          return (
            <InformationContact
              key={i}
              title={`Contact ${i + 1}`}
              name={each?.name}
              position={each?.jobTitle}
              email={each?.email}
              phone={each?.phoneNumber}
              groupName={each?.workUnit}
              onDelete={(e) => handleDelete(e, each?.id)}
              onEdit={(e) => handleEdit(each)}
            />
          );
        })} */}
      </div>
      <div className={Styles.bottomButton} onClick={() => setShowAdd(true)}>
        <Button
          type="primary"
          title={
            <span>
              <Icon icon="circle-plus" size={20} color="white" /> Information
              Contact
            </span>
          }
        />
      </div>
    </div>
  );
}
