import moment from "moment";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import { useEffect, useRef, useState } from "react";
import ModalWrapper from "@Atom/ModalWrapper";
import ResultModal from "@Molecule/_modal/ResultModal";
import UseOutsideClick from "@Hooks/useOutsideClick";
import MoveCandidateModal from "@Molecule/_modal/MoveCandidateModal";
import { default as ClientInterviewButton } from "@Organism/Recruiter/Recruitment/RecruitmentDetail/Second/button";
import Button from "@Organism/Recruiter/Recruitment/RecruitmentDetail/First/ListMode/InvitationTable/button";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";

export default function CandidateDetailModal({
  data,
  inviteData,
  inviteOption,
  moveOption = false,
  setData,
  handleInvite,
  reinviteAction,
  inviteAction,
  additionalButtonComponent,
  setTrigger,
  evaluationParameters = [],
  type = "",
  handleResend = () => {},
  setCandidateToInvite = () => {},
}) {
  const [expandResult, setExpandResult] = useState(false);
  const [resultMode, setResultMode] = useState("");

  const [showMove, setShowMove] = useState(false);

  const resultRef = useRef();

  const backupRef = useRef();

  const outsideResultClick = UseOutsideClick(resultRef);

  const resultOptions = ["Lolos", "Tidak Lolos"];

  useEffect(() => {
    if (expandResult && outsideResultClick) {
      setExpandResult("");
    }
  }, [expandResult, outsideResultClick]);

  const handleClickInvite = () => {
    handleInvite(inviteData);
    setData(null);
  };

  const handleOpenInterviewInvitation = (link) => {
    window.open(link);
  };

  return (
    <div
      ref={expandResult ? backupRef : resultRef}
      className={Styles.container}
    >
      {/* <div className={Styles.TopLine} /> */}
      <div className={Styles.header}>
        <h3>Informasi kandidat</h3>
      </div>
      <h4>{data?.name?.toUpperCase()}</h4>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Posisi</h4>
          <p>{data?.ExpectedPosition?.name?.toUpperCase()}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Waktu Interview</h4>
          <p>
            {data?.interviewDate
              ? moment(new Date(data?.interviewDate))?.format("LL")
              : "-"}
          </p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Undang Interview</h4>
          {type === "client" ? (
            data?.interviewLink &&
            data?.CandidateInvitations?.ordinal === "2" ? (
              <div
                className={`${Styles.detail} ${
                  data?.status.toLowerCase().includes("hold") && Styles.inactive
                }`}
                onClick={() => {
                  if (!data?.status.toLowerCase().includes("hold")) {
                    handleOpenInterviewInvitation(data?.interviewLink);
                  }
                }}
              >
                Lihat Detail
              </div>
            ) : (
              <div className={Styles.nullLink}>Belum Tersedia</div>
            )
          ) : type === "ro" ? (
            data?.interviewLink ? (
              <div
                className={`${Styles.detail} ${
                  data?.status.toLowerCase().includes("hold") && Styles.inactive
                }`}
                onClick={() => {
                  if (!data?.status.toLowerCase().includes("hold")) {
                    handleOpenInterviewInvitation(data?.interviewLink);
                  }
                }}
              >
                Lihat Detail
              </div>
            ) : (
              <div className={Styles.nullLink}>Belum Tersedia</div>
            )
          ) : data?.offeringLink ? (
            <div
              className={`${Styles.detail} ${
                data?.status.toLowerCase().includes("hold") && Styles.inactive
              }`}
              onClick={() => {
                if (!data?.status.toLowerCase().includes("hold")) {
                  handleOpenInterviewInvitation(data?.offeringLink);
                }
              }}
            >
              Lihat Detail
            </div>
          ) : (
            <div className={Styles.nullLink}>Belum Tersedia</div>
          )}
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Email</h4>
          <p>{data?.email}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Nomor Handphone</h4>
          <p>{data?.phoneNumber || "-"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Domisili</h4>
          <p>{data?.domicile?.toUpperCase() || "-"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Alamat</h4>
          <p>{data?.address?.toUpperCase() || "-"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Pendidikan</h4>
          <p>{data?.lastEducation?.toUpperCase() || "-"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Pengalaman Terakhir</h4>
          <p>{data?.lastPosition?.toUpperCase() || 0}</p>
        </div>
        {data?.tag === "Invited" && (
          <div className={Styles.stackedInfo}>
            <h4>Tgl Kirim Invitation</h4>
            <p>{`${moment(data?.updatedAt).format("ll")} ${moment(
              data?.updatedAt
            ).format("LT")}`}</p>
          </div>
        )}
      </div>
      {inviteOption &&
        data?.tag?.toLowerCase() !== "declined" &&
        (!data?.status?.toLowerCase()?.includes("hold") &&
        !data?.status?.toLowerCase()?.includes("contracted") ? (
          data?.tag?.toLowerCase() !== "accepted" ? (
            <div className={Styles.buttonWrapper}>
              {type ? (
                type === "ro" ? (
                  <Button
                    recruiterId={data?.recruiterID}
                    tag={data?.tag}
                    onClick={(e) =>
                      data?.tag && data?.recruiterID
                        ? handleResend(data)
                        : // resendInvitation(e, data?.id)
                          handleInvite(data)
                    }
                    updatedAt={data?.updatedAt}
                    isROInterview
                  />
                ) : (
                  <ClientInterviewButton
                    recruiterId={data?.recruiterID}
                    tag={data?.tag}
                    onClick={(e) =>
                      data?.tag && data?.recruiterID
                        ? handleResend(data)
                        : // resendInvitation(e, data?.id)
                          setCandidateToInvite(data)
                    }
                    updatedAt={data?.updatedAt}
                    isROInterview
                  />
                )
              ) : (
                <button
                  disabled={data?.tag === "Invited"}
                  onClick={(e) =>
                    inviteAction
                      ? inviteAction(e, data?.id)
                      : handleClickInvite()
                  }
                >
                  {data?.tag?.toLowerCase() === "accepted" &&
                  data?.CandidateInvitations?.remark
                    ? "Reschedule"
                    : "Undang"}
                </button>
              )}
            </div>
          ) : (
            <div className={Styles.buttonWrapper}>
              {data?.CandidateInvitations?.remark ? (
                <button
                  disabled={data?.tag === "Invited"}
                  onClick={(e) =>
                    inviteAction
                      ? inviteAction(e, data?.id)
                      : handleClickInvite()
                  }
                >
                  {data?.tag?.toLowerCase() === "accepted" &&
                  data?.CandidateInvitations?.remark
                    ? "Reschedule"
                    : "Undang"}
                </button>
              ) : (
                <div
                  onClick={() => {
                    setExpandResult(!expandResult);
                  }}
                  className={`${Styles.result} ${Styles.button}`}
                >
                  <span>Result</span>
                  <Icon
                    icon={expandResult ? "arrow-up" : "arrow-down"}
                    size={20}
                  />
                  {expandResult && (
                    <div ref={resultRef} className={Styles.resultPopup}>
                      {resultOptions?.map((obj, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setResultMode(
                              obj === "Lolos" ? "accept" : "reject"
                            );
                          }}
                        >
                          {obj}

                          {i === 0 && <div className={Styles.divider} />}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        ) : (
          <div className={Styles.buttonWrapper}>
            <span className={Styles.holdText}>
              {capitalizedFirstLetter(
                data?.status?.split("-")[data?.status?.split("-")?.length - 1]
              )}
            </span>
          </div>
        ))}
      {data?.tag?.toLowerCase() === "declined" &&
        (moveOption ? (
          <div className={Styles.buttonWrapper}>
            <button onClick={() => setShowMove(true)}>Alihkan</button>
          </div>
        ) : (
          <div className={Styles.buttonWrapper}>
            {!data?.status?.toLowerCase()?.includes("hold") &&
            !data?.status?.toLowerCase()?.includes("contracted") ? (
              <button className={Styles.rejected}>Tidak Hadir</button>
            ) : (
              <span className={Styles.holdText}>
                {capitalizedFirstLetter(
                  data?.status?.split("-")[data?.status?.split("-")?.length - 1]
                )}
              </span>
            )}
          </div>
        ))}
      {
        <div className={Styles.buttonWrapper}>
          {additionalButtonComponent && additionalButtonComponent}
        </div>
      }

      {/* {inviteOption && data?.tag === "Invited" && (
        <div className={Styles.buttonWrapper}>
          <div
            className={Styles.secondaryButton}
            onClick={(e) => reinviteAction(e, data?.id)}
          >
            Kirim Ulang
          </div>
        </div>
      )} */}
      {/* <div className={Styles.bottomLine} /> */}
      {resultMode && (
        <ModalWrapper show={resultMode ? true : false}>
          {resultMode && (
            <ResultModal
              mode={resultMode}
              data={data}
              onClose={() => setResultMode("")}
              setTrigger={setTrigger}
              defaultScores={evaluationParameters}
            />
          )}
        </ModalWrapper>
      )}

      {showMove && (
        <ModalWrapper show={showMove} handleClose={() => setShowMove(null)}>
          {showMove && (
            <MoveCandidateModal
              candidate={data}
              onClose={() => setShowMove(false)}
              setTrigger={setTrigger}
            />
          )}
        </ModalWrapper>
      )}
    </div>
  );
}
