/*eslint-disable no-useless-escape */

import Icon from '@Atom/Icon';
import UploadCard from '@Molecule/UploadCard';
import Styles from './style.module.scss';

export default function FileUploadSection(props) {
  const {
    files,
    setFiles
  } = props;


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Upload a File</h3>
        <Icon icon="upload" size={20} className={Styles.uploadIcon} />
      </div>
      <div className={Styles.content}>
        <UploadCard
          type="multiple-pdf"
          files={files}
          setFiles={setFiles}
          withUploadBar={false}
        />
      </div>
    </div>
  )
}