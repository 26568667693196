import Styles from './style.module.scss';
import loadingAnimationData from '@Assets/Lotties/loading.json';
import Lottie from 'lottie-react';

export default function LoadingModal() {
    return (
        <div className={Styles.background}>
            <div className={Styles.container}>
                <div className={Styles.animationWrapper}>
                    <Lottie animationData={loadingAnimationData} height={30} width={30} />
                </div>
            </div>
        </div>
    );
}
