import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import SearchBar from "@Atom/SearchBar";
import { makeRandomString } from "@Helpers/makeRandomString";
import { Checkbox } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

const Desktop = (props) => {
  const {
    searchTerm,
    onChange,
    isSelected,
    handleSelectedLocation,
    onCancel,
    onAdd,
    cities,
  } = props;

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h4>Add New Location</h4>
          <div className={Styles.searchbarWrapper}>
            <SearchBar
              value={searchTerm}
              onChange={onChange}
              placeholder="Search Location"
            />
          </div>
        </div>
        <div className={Styles.content}>
          {cities?.filter((el) =>
            el?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
          )?.length > 0 ? (
            <>
              {cities
                ?.filter((el) =>
                  el?.name?.toLowerCase().includes(searchTerm.toLowerCase())
                )
                ?.map((each, i) => {
                  const isItemSelected = Boolean(isSelected(each));
                  return (
                    <div
                      key={i}
                      className={Styles.each}
                      onClick={() => handleSelectedLocation(each)}
                    >
                      <label>{each?.name}</label>
                      <Checkbox
                        checked={isItemSelected}
                        sx={{ margin: 0, padding: 0, zIndex: 0 }}
                        icon={
                          <Icon
                            icon="radio-button-default"
                            size={20}
                            className={Styles.unchecked}
                          />
                        }
                        checkedIcon={
                          <Icon
                            icon="radio-button-active"
                            size={20}
                            className={Styles.checked}
                          />
                        }
                      />
                    </div>
                  );
                })}
            </>
          ) : (
            <div className={Styles.noOption}>No such location</div>
          )}
        </div>
        <div className={Styles.actionsWrapper}>
          <button onClick={onCancel}>
            <span>Cancel</span>
          </button>
          <button onClick={onAdd}>
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const Mobile = (props) => {
  const { isSelected, handleSelectedLocation, onCancel, onAdd, cities } = props;

  return (
    <div className={Styles.mobileContainer}>
      <div className={Styles.topWrapper}>
        <div className={Styles.topLine} />
        <div className={Styles.header}>
          <h3>Add New Location</h3>
        </div>
      </div>
      <div className={Styles.content}>
        {cities?.map((each, i) => {
          const isItemSelected = Boolean(isSelected(each));
          return (
            <div
              key={i}
              className={Styles.each}
              onClick={() => handleSelectedLocation(each)}
            >
              <label>{each?.name}</label>
              <Checkbox
                checked={isItemSelected}
                sx={{ margin: 0, padding: 0, zIndex: 0 }}
                icon={
                  <Icon
                    icon="radio-button-default"
                    size={20}
                    className={Styles.unchecked}
                  />
                }
                checkedIcon={
                  <Icon
                    icon="radio-button-active"
                    size={20}
                    className={Styles.checked}
                  />
                }
              />
            </div>
          );
        })}
      </div>
      <div className={Styles.actionsWrapper}>
        <button onClick={onCancel}>
          <span>Cancel</span>
        </button>
        <button onClick={onAdd}>
          <span>Add</span>
        </button>
      </div>
    </div>
  );
};

export default function AddLocationModal(props) {
  // eslint-disable-next-line
  const { type, setShowAdd, form, setForm, cities, trigger, setTrigger } =
    props;
  const [searchTerm, setSearchTerm] = useState("");
  const [formTemps, setFormTemps] = useState([]);
  const [locationArr, setLocationArr] = useState([]);


  let inactiveCities = useMemo(() => {
    const activeIds = form?.map((item) => item?.location)
    return cities.filter(function (item) {
      return activeIds.indexOf(item.code) === -1;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities, form, trigger])

  useEffect(() => {
    setLocationArr(form);
    setFormTemps(form);
  }, [form, trigger]);

  useEffect(() => {
    sessionStorage.setItem("manPowerForm", JSON.stringify(form));
  }, [form]);

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedLocation = (option) => {
    const data = [...locationArr];
    const isExist = data.find((item) => item?.location === option?.code);
    if (!isExist) {
      data.push({
        location: option?.code,
        jobs: [],
        areaWorkEquipments: [],
        umk: option?.umk,
      });
    }
    // else {
    //   const index = data?.findIndex(object => {
    //     return object.name === option?.name;
    //   });
    //   data.splice(index, 1)
    // }
    setLocationArr(data);
  };

  const onCancel = () => {
    setLocationArr(form);
    setFormTemps(form);
    setShowAdd(false);
  };

  const onAdd = () => {
    const filtered = locationArr?.filter((item) => !formTemps?.includes(item));
    if (filtered?.length !== 0) {
      for (let i = 0; i < filtered.length; i++) {
        form.push(filtered[i]);
      }
    } else {
      for (let i = 0; i < locationArr.length; i++) {
        form.push(locationArr[i]);
      }
    }
    setShowAdd(false);
    setTrigger(makeRandomString(5));
  };

  const isSelected = (option) =>
    locationArr.find((item) => item?.location === option?.code);

  switch (type) {
    case "mobile":
      return (
        <Mobile
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={inactiveCities}
        />
      );
    case "desktop":
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={inactiveCities}
        />
      );
    default:
      return (
        <Desktop
          searchTerm={searchTerm}
          onChange={onChange}
          handleSelectedLocation={handleSelectedLocation}
          onCancel={onCancel}
          onAdd={onAdd}
          isSelected={isSelected}
          cities={inactiveCities}
        />
      );
  }
}

export const AddLocationModalV2 = ({
  show,
  setShow = () => { },
  cities,
  setValues = () => { }
}) => {
  const [selectedCities, setSelectedCities] = useState([])
  const [search, setSearch] = useState('')

  const onSubmit = () => {
    setValues(selectedCities)
    setShow(false)
  }

  useEffect(() => {
    setSelectedCities([])
    setSearch('')
  }, [show])

  const getFound = useCallback((c) => {
    return selectedCities?.find(obj => obj?.code === c?.code) ? true : false
  }, [selectedCities])

  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={Styles.v2Container}>
        <div className={Styles.header}>
          <h3>Add New Location</h3>
          <div className={Styles.searchWrapper}>
            <SearchBar
              value={search}
              placeholder={'Search location'}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
        </div>
        <div className={Styles.content}>
          {cities
            ?.filter(obj => obj?.name?.toLowerCase()?.includes(search?.toLowerCase()))
            ?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            ?.map((c, cI) => {
              const found = getFound(c)
              return (
                <div key={cI} className={Styles.city} >
                  <CheckboxJobPosting
                    checked={found}
                    onChange={(e) => {
                      if (found) {
                        setSelectedCities(selectedCities.filter(obj => obj?.code?.toLowerCase() !== c?.code?.toLowerCase()))
                      } else {
                        setSelectedCities([...selectedCities, c])
                      }
                    }}
                  />
                  <span
                    onClick={() => {
                      if (found) {
                        setSelectedCities(selectedCities.filter(obj => obj?.code !== c?.code))
                      } else {
                        setSelectedCities([...selectedCities, c])
                      }
                    }}>{c?.name}</span>
                </div>)
            })
          }
        </div>
        <div className={Styles.actions}>
          <button onClick={() => setShow(false)}>Cancel</button>
          <button disabled={!selectedCities?.length} onClick={onSubmit}>Add</button>
        </div>
      </div>
    </ModalWrapper>
  )
}
