import AutocompleteField from '@Molecule/AutocompleteField';
import InputField from '@Molecule/InputField';
import { useCallback, useEffect } from 'react';
import Styles from './styles.module.scss';
import { isValidEmail } from '@Helpers/isValidEmail';

export default function AddForm({ value, setValue, educationTemp, setEducationTemp, domicileTemp, setDomicileTemp, errorField, educationOptions, domicileOptions }) {

    const handleChange = useCallback(
        (e, inputName) => {
            let targetValue;

            const re = /^[0-9\b]+$/;
            if (
                inputName === 'phoneNumber' ||
                inputName === 'nik'
            ) {
                if (inputName === 'phoneNumber') {
                    if (e.target.value === '' || (re.test(e.target.value) && e.target.value.startsWith('8'))) {
                        targetValue = !isNaN(e.target.value) ? e.target.value : e.target.value.toUpperCase();
                    }
                } else if (inputName === 'nik'){
                    if (e.target.value?.length <= 16){
                        if (e.target.value === '' || re.test(e.target.value)) {
                            targetValue = !isNaN(e.target.value) ? e.target.value : e.target.value.toUpperCase();
                        }
                    }
                } else {
                    if (e.target.value === '' || re.test(e.target.value)) {
                        targetValue = !isNaN(e.target.value) ? e.target.value : e.target.value.toUpperCase();
                    }
                }
            } else if (inputName === 'email') {
                targetValue = e.target.value.toLowerCase().trim();
            } else {
                targetValue = !isNaN(e.target.value) ? e.target.value : e.target.value.toUpperCase();
            }

            if (targetValue || targetValue === '') {
                setValue({
                    ...value,
                    [inputName]: targetValue,
                });
            }
        },
        [value, setValue]
    );

    useEffect(() => {
        setValue({
          ...value,
          lastEducation: educationTemp,
          domicile: domicileTemp
        });
        // eslint-disable-next-line
      }, [
        educationTemp,
        domicileTemp
      ]);

    return (
        <div className={Styles.formWrapper}>
            <InputField
                title={'Nomor NIK'}
                placeholder={'Nomor NIK'}
                value={value?.nik}
                required
                onChange={(e) => handleChange(e, 'nik')}
                isError={errorField && (!value?.nik || value?.nik?.length < 16)}
                errorText="*Nik belum diisi atau belum 16 karakter"
            />
            <InputField
                title={'Nama sesuai KTP'}
                placeholder={'Nama sesuai KTP'}
                value={value?.name}
                required
                onChange={(e) => handleChange(e, 'name')}
                isError={errorField && !value?.name}
                errorText="*Nama belum diisi"
            />
            <InputField
                type="email"
                title={'Email'}
                placeholder={'example@email.com'}
                value={value?.email}
                required
                onChange={(e) => handleChange(e, 'email')}
                isError={errorField && (!value?.email || !isValidEmail(value?.email))}
                errorText={"*Email belum diisi atau tidak sesuai format"}
            />
            <InputField
                title={'Pengalaman Terakhir'}
                placeholder={'Pengalaman Kerja Terakhir'}
                value={value?.lastPosition}
                required
                onChange={(e) => handleChange(e, 'lastPosition')}
                isError={errorField && !value?.lastPosition}
                errorText="*Pengalaman belum diisi"
            />
            <InputField
                type="phone"
                title={'Nomor Handphone'}
                placeholder={'8***'}
                value={value?.phoneNumber}
                onChange={(e) => handleChange(e, 'phoneNumber')}
                required
                isError={errorField && !value?.phoneNumber}
                errorText={'*Nomor HP belum diisi'}
            />
            <AutocompleteField
                title={'Domisili'}
                placeholder={'Select'}
                searchOptions={domicileOptions}
                value={value?.domicile}
                setValue={setDomicileTemp}
                iconColor={'#1571DE'}
                required
                isError={errorField && !value?.domicile}
                errorText={'*Domisili belum diisi'}
            />
            <InputField
                title={'Alamat'}
                placeholder={'Alamat utama'}
                value={value?.address}
                required
                onChange={(e) => handleChange(e, 'address')}
                isError={errorField && !value?.address}
                errorText="*Alamat belum diisi"
            />
            <AutocompleteField
                title={'Pendidikan'}
                placeholder={'Select'}
                searchOptions={educationOptions}
                value={value?.lastEducation}
                setValue={setEducationTemp}
                iconColor={'#1571DE'}
                required
                isError={errorField && !value?.lastEducation}
                errorText={'*Pendidikan belum diisi'}
            />
        </div>
    );
}
