import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";
import useQuery from "@Hooks/useQuery";
import ApproveConfirmationModal from "@Molecule/_modal/BusinessSupport/ApproveConfirmationModal";
import ApproveSuccessModal from "@Molecule/_modal/BusinessSupport/ApproveSuccessModal";
import ApproveVerificationModal from "@Molecule/_modal/BusinessSupport/ApproveVerificationModal";
import RejectConfirmationModal from "@Molecule/_modal/BusinessSupport/RejectConfirmationModal";
import RejectSuccessModal from "@Molecule/_modal/BusinessSupport/RejectSuccessModal";
import RejectVerificationModal from "@Molecule/_modal/BusinessSupport/RejectVerificationModal";
import CandidateDetailsLayout from "@Organism/BusinessSupport/Candidates/CandidateDetails";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import {
  getCandidateDetails,
  getContractListSync,
} from "@Services/support/candidate";
import { Suspense, useMemo, useState } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";

export async function loader({ params }) {
  const { id } = params;

  const candidateDetailsPromise = getCandidateDetails(id);
  const contractSyncListPromise = getContractListSync();

  return defer({
    packageCandidateDetails: candidateDetailsPromise,
    packageContractListSync: contractSyncListPromise,
  });
}

export default function CandidateDetails() {
  // eslint-disable-next-line no-unused-vars
  const { packageCandidateDetails, packageContractListSync } = useLoaderData();
  const query = useQuery();

  const [showReject, setShowReject] = useState(0);
  const [showAccept, setShowAccept] = useState(0);
  const [trigger, setTrigger] = useState("");

  const [stage, setStage] = useState("");

  const [generateContractMode, setGenerateContractMode] = useState(
    query.get("g") ? true : false
  );

  const acceptModal = useMemo(() => {
    switch (showAccept) {
      case 1:
        return <ApproveConfirmationModal setShowAccept={setShowAccept} />;
      case 2:
        return (
          <ApproveVerificationModal
            setShowAccept={setShowAccept}
            setTrigger={setTrigger}
            stage={stage}
          />
        );
      case 3:
        return (
          <ApproveSuccessModal
            setShowAccept={setShowAccept}
            setGenerateContractMode={setGenerateContractMode}
            stage={stage}
          />
        );
      default:
        return "";
    }
  }, [showAccept, stage]);

  const rejectModal = useMemo(() => {
    switch (showReject) {
      case 1:
        return <RejectConfirmationModal setShowReject={setShowReject} />;
      case 2:
        return (
          <RejectVerificationModal
            setShowReject={setShowReject}
            setTrigger={setTrigger}
            stage={stage}
          />
        );
      case 3:
        return (
          <RejectSuccessModal setShowReject={setShowReject} stage={stage} />
        );
      default:
        return "";
    }
  }, [showReject, stage]);

  return (
    <div className={Styles.container}>
      <Suspense
        fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageCandidateDetails}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageCandidateDetails) => (
            <Suspense
              fallback={
                <div className={Styles.loadingWrapper}>
                  <LoadingAnimation />
                </div>
              }
            >
              <Await
                resolve={packageContractListSync}
                errorElement={
                  <div style={{ height: "calc(100vh - 220px)" }}>
                    <AsyncErrorBoundary />
                  </div>
                }
              >
                {(packageContractListSync) => (
                  <CandidateDetailsLayout
                    setShowAccept={setShowAccept}
                    setShowReject={setShowReject}
                    defaultData={packageCandidateDetails?.response}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    stage={stage}
                    setStage={setStage}
                    generateContractMode={generateContractMode}
                    setGenerateContractMode={setGenerateContractMode}
                    defaultSync={null}
                    contractList={packageContractListSync}
                  />
                )}
              </Await>
            </Suspense>
          )}
        </Await>
      </Suspense>

      <div>
        <ModalWrapper
          show={showAccept !== 0 || showReject !== 0}
          forceCenter={showAccept === 2 ? false : true}
          handleClose={() => setShowAccept(0)}
        >
          {acceptModal || rejectModal}
        </ModalWrapper>
      </div>
    </div>
  );
}

// return (
//   <div className={Styles.container}>
//     <Suspense
//       fallback={
//         <div className={Styles.loadingWrapper}>
//           <LoadingAnimation />
//         </div>
//       }
//     >
//       <Await
//         resolve={packageCandidateDetails}
//         errorElement={
//           <div style={{ height: "calc(100vh - 220px)" }}>
//             <AsyncErrorBoundary />
//           </div>
//         }
//       >
//         {(packageCandidateDetails) => (
//           <Suspense
//             fallback={
//               <div className={Styles.loadingWrapper}>
//                 <LoadingAnimation />
//               </div>
//             }
//           >
//             <Await resolve={packageContractSync}
//               errorElement={
//                 <div style={{ height: "calc(100vh - 220px)" }}>
//                   <AsyncErrorBoundary />
//                 </div>
//               }
//             >
//               {(packageContractSync) => (
//                 <CandidateDetailsLayout
//                   setShowAccept={setShowAccept}
//                   setShowReject={setShowReject}
//                   defaultData={packageCandidateDetails?.response}
//                   trigger={trigger}
//                   setTrigger={setTrigger}
//                   stage={stage}
//                   setStage={setStage}
//                   generateContractMode={generateContractMode}
//                   setGenerateContractMode={setGenerateContractMode}
//                   defaultSync={packageContractSync?.response}
//                 />

//               )}
//             </Await>

//           </Suspense>

//         )}

//       </Await>

//     </Suspense>

//     <div>
//       <ModalWrapper
//         show={showAccept !== 0 || showReject !== 0}
//         forceCenter={showAccept === 2 ? false : true}
//       >
//         {acceptModal || rejectModal}
//       </ModalWrapper>
//     </div>
//   </div>
// )
