/*eslint-disable no-useless-escape */

import { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import InputField from '@Molecule/InputField'

export default function OthersSection(props) {
  const { form, setForm, setTrigger } = props
  const [deductedAttendance, setDeductedAttendance] = useState(form?.others?.deductedAttendance)
  const [others, setOthers] = useState( form?.others?.others)


  useEffect(() => {
    const newForm = form
    newForm.others.deductedAttendance = deductedAttendance
    newForm.others.others = others
    setForm(newForm)
    setTrigger(Math.random())
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
    // eslint-disable-next-line
  }, [form, setForm, deductedAttendance, others])

  return (
    <div className={Styles.container}>
      <h3>Others</h3>

      <div className={Styles.content}>
        <div className={Styles.wrapper}>
          <InputField
            type="desc"
            title="Absen Dipotong Bila"
            value={deductedAttendance || ''}
            onChange={(e) => setDeductedAttendance(e.target.value.replace(/[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/ig, ""))}
            placeholder={"Deskripsi"}
          />
        </div>
        <div className={Styles.wrapper}>
          <InputField
            type="desc"
            title="Keterangan Lainnya"
            value={others || ''}
            onChange={(e) => setOthers(e.target.value.replace(/[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/ig, ""))}
            placeholder={"Deskripsi"}
          />
        </div>
      </div>
    </div>
  )
}