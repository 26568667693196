/* eslint-disable no-unused-vars */
import Styles from "./style.module.scss";
import { useEffect, useState } from "react";

const Resend = ({ onClick, ...props }) => {
  return (
    <button className={Styles.sent} onClick={onClick}>
      Kirim Ulang
    </button>
  );
};

const Send = ({ onClick, ...props }) => {
  return (
    <button className={Styles.send} onClick={onClick}>
      Kirim
    </button>
  );
};

const Disabled = () => {
  return <button disabled>Terkirim</button>;
};

const Declined = () => {
  return <button className={Styles.rejected}>Tidak join</button>
}

const Button = ({ tag, onClick, updatedAt, recruiterId, ...props }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (new Date(updatedAt).getTime() + 60 * 1000 > new Date().getTime()) {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 60000);
    }
  }, [updatedAt]);

  // if (tag === "Invited" && !isDisabled && recruiterId) {
  //   return <Resend onClick={onClick} />;
  // }
  // if (tag === "Invited" && isDisabled && recruiterId) {
  //   return <Disabled />;
  // }
  if (tag === "Invited" && recruiterId) {
    return <Disabled />;
  }
  if (tag === "Declined" && recruiterId) {
    return <Declined />;
  }
  return <Send onClick={onClick} />;
};

export default Button;
