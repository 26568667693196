import Styles from "./style.module.scss";
import ICON from "../../../../../assets/Images/reject.png";

const FailedCreateContractModal = ({ errorMessage, action, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={ICON} alt="" height={100} width={100} />
      </div>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Terjadi Kesalahan</div>
        <div className={Styles.text}>{errorMessage}</div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={action}>
          <div className={Styles.text}>Kembali</div>
        </div>
      </div>
    </div>
  );
};

export default FailedCreateContractModal;
