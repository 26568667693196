import ApprovalListLayout from "@Organism/Manager/Approval/ApprovalList";
import { getApprovals } from "@Services/manager/approval";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const limit = url.searchParams.get("l") || 5;
  const page = url.searchParams.get("p") || 1;
  const search = url.searchParams.get("s") || "";
  const isApproved = url.searchParams.get("i") || "false";

  return defer({
    packageApprovals: getApprovals(page, limit, search, isApproved === 'true' ? true : false),
  });
}

export default function ApprovalList() {
  const { packageApprovals } = useLoaderData()


  return (
    <Suspense fallback={<ApprovalListLayout
      isLoading={true}
    />}>
      <Await
        resolve={packageApprovals}
        errorElement={
          <ApprovalListLayout
            isError
          />
        }
      >
        {(packageApprovals) => (
          <ApprovalListLayout
            defaultData={packageApprovals?.response?.data}
            totalData={packageApprovals?.response?.totalData}
            totalPage={packageApprovals?.response?.totalPage}
          />

        )}

      </Await>
    </Suspense>
  )
}