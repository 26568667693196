import { Menu, MenuItem } from '@mui/material';

export default function MenuAction({ anchorEl, setAnchorEl, open, onClose, options }) {
    const handleClose = () => {
        setAnchorEl(null);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    zIndex: 10,
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                },
                '& .MuiMenuItem-root': {
                    padding: '8px 16px',
                    fontFamily: 'Poppins',
                    color: '#1571DE',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            }}>
            {options?.map((eachO, iO) => {
                return (
                    <MenuItem
                        key={`menuItem-${iO}`}
                        onClick={() => {
                            handleClose();
                            if (eachO?.action) {
                                eachO.action();
                            }
                        }}>
                        {eachO?.title}
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
