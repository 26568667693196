/* eslint-disable */
import UseOutsideClick from "@Hooks/useOutsideClick";
import Pagination from "@Molecule/Pagination";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

const TABLE_HEAD = [
  { id: "name", label: "Client", alignRight: false },
  // { id: "location", label: "Location", alignRight: false },
  { id: "email", label: "Email & Phone Number", alignRight: false },
  // { id: "phone", numeric: true, label: "Phone", alignRight: false },
  {
    id: "quota",
    numeric: true,
    label: "Jumlah Permintaan & Terpenuhi",
    alignRight: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, firstColMinWidth } = props;

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align={headCell.id === "totalManPower" ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0 ? `${Styles.firstHeadCell}` : `${Styles.hideOnMobile}`
            }
            sx={{
              minWidth: index === 0 ? firstColMinWidth : "",
              backgroundColor: "#fff",
            }}
          >
            <p
              className={
                headCell?.id === "totalManPower" ? Styles.floatRight : ""
              }
              style={{ whiteSpace: "nowrap" }}
            >
              {/* <Skeleton
                variant="rectangular"
                width={headCell?.width || 10}
                style={{ backgroundColor: "#EAECF2" }}
                height={headCell?.height || 10}
              /> */}
              {headCell?.label}
            </p>
          </TableCell>
        ))}
        <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <p style={{ display: "flex", justifyContent: "flex-end" }}>
            <Skeleton
              variant="rectangular"
              width={"100%"}
              style={{ backgroundColor: "#EAECF2" }}
              height={16}
            />
          </p>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function LoadingSkeleton({
  totalPage = 0,
  selectedPage = () => { },
  setSelectedPage = () => { },
  limit = 5,
  setLimit = { setValue }
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const location = useLocation();
  const { pathname } = location;


  const [pages, setPages] = useState(`1-${limit}`);
  const [pageList, setPageList] = useState([]);

  // const counter = Array(limit).fill(1);


  const navigate = useNavigate();

  const [selectedClient, setSelectedClient] = useState(null);

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "900px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "250px";
    }
    return width;
  }, [windowSize]);

  useEffect(() => {
    if (selectedClient !== null) {
      if (boxOutsideClick === false) {
        setSelectedClient(null);
      }
    }
  }, [boxOutsideClick, selectedClient]);

  const handleClickRow = (e, type, each) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "open") {
      setSelectedClient(each);
    } else if (type === "navigate" && selectedClient === null) {
      navigate(
        `${pathname}/details?n=${each?.name}&c=${each?.code}&h=${String(
          Math.random()
        ).slice(2)}`
      );
    }
  };

  const getRow = (i) => {
    if (i % 2 === 1) {
      return "even";
    } else {
      return "odd";
    }
  };

  console.log(Array(3)?.fill()?.map(Math.random), 'tess')

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer className={Styles.tableContainer}>
        <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            firstColMinWidth={firstColMinWidth}
          />
          <TableBody>
            {[...Array(+limit)]?.map((each, i) => {
              const row = getRow(i);
              return (
                <TableRow
                  key={i}
                  className={`${i % 2 === 1 ? Styles.evenRow : Styles.oddRow} ${Styles.tableRow
                    }`}
                >
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.firstCell}`}
                  >
                    <p className={Styles.link}>
                      <Skeleton
                        variant="circular"
                        width={36}
                        style={{ backgroundColor: "#EAECF2" }}
                        height={36}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={row === "odd" ? 140 : 160}
                        style={{ backgroundColor: "#EAECF2" }}
                        height={16}
                      />
                    </p>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <p>
                      <Skeleton
                        variant="rectangular"
                        width={row === "odd" ? 97 : 87}
                        style={{ backgroundColor: "#EAECF2" }}
                        height={16}
                      />
                    </p>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={row === "odd" ? 174 : 186}
                      style={{ backgroundColor: "#EAECF2" }}
                      height={16}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={Styles.PaginationContainer}>
        <Pagination
          total={100}
          totalPage={totalPage}
          value={limit}
          setValue={() => { }}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>

      <div className={Styles.AddButtonWrapper}>
        <button
          variant="contained"
          onClick={() => navigate(pathname + "/add-new-client")}
        >
          Add New Client
        </button>
      </div>
    </div>
  );
}
