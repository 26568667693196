import AutocompleteField from "@Molecule/AutocompleteField";
import InputField from "@Molecule/InputField";
import { useCallback, useEffect, useState } from "react";
import Styles from "./style.module.scss";

export default function LastEducationSection({
  form,
  setForm,
  options,
  disableInput,
  errorField,
}) {
  const [educationTemp, setEducationTemp] = useState(form?.education);

  const handleChange = useCallback(
    (e, inputName) => {
      if (inputName === "gpa") {
        const allowedCharacters = /^[0-9.]*$/;
        if (!allowedCharacters.test(e.target.value)) {
          // Jika nilai tidak valid, kita tidak mengubah state
          return;
        }
        setForm({
          ...form,
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        });
      } else {
        setForm({
          ...form,
          [inputName]: !isNaN(e.target.value)
            ? e.target.value
            : e.target.value.toUpperCase(),
        });
      }
    },
    [form, setForm]
  );

  useEffect(() => {
    setEducationTemp(form?.education || "");
  }, [form?.education]);

  useEffect(() => {
    setForm({
      ...form,
      education: educationTemp,
    });

    // eslint-disable-next-line
  }, [educationTemp]);

  return (
    <div className={Styles.container}>
      <h3>Pendidikan Terakhir</h3>
      <div className={Styles.content}>
        <AutocompleteField
          title={"Jenis Pendidikan"}
          placeholder={"Pilih jenis pendidikan"}
          searchOptions={options?.educations}
          value={educationTemp}
          setValue={setEducationTemp}
          iconColor={"#1571DE"}
          required
          disabled={disableInput}
          isError={errorField && !educationTemp}
          errorText={"*Jenis Pendidikan belum diisi"}
        />
        <InputField
          title={"Nama Sekolah/Universitas"}
          placeholder={"Nama Sekolah/Universitas"}
          value={form?.institution}
          onChange={(e) => handleChange(e, "institution")}
          required
          disabled={disableInput}
          isError={errorField && !form?.institution}
          errorText={"*Nama belum diisi"}
        />
        <InputField
          title={"Fakultas/Jurusan"}
          placeholder={"Fakultas/Jurusan"}
          value={form?.faculty}
          onChange={(e) => handleChange(e, "faculty")}
          required
          disabled={disableInput}
          isError={errorField && !form?.faculty}
          errorText={"*Fakultas/Jurusan belum diisi"}
        />
        <InputField
          title={"IPK/Nilai Akhir"}
          placeholder={"0.00"}
          value={form?.gpa}
          onChange={(e) => handleChange(e, "gpa")}
          required
          disabled={disableInput}
          isError={errorField && !form?.gpa}
          errorText={"*IPK/Nilai Akhir belum diisi"}
        />
      </div>
    </div>
  );
}
