/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import moment from "moment";
import ModalWrapper from "@Atom/ModalWrapper";
import InvitationConfirmationModal from "@Molecule/_modal/InvitationConfirmationModal";
import Icon from "@Atom/Icon";
import QRCode from "react-qr-code";
import CandidateIDCard from "@Molecule/CandidateIDCard";
import { fileBaseUrl } from "@Config/api";

export default function InterviewInvitationClient({ defaultData }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmationMode, setShowConfirmationMode] = useState("reject");

  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError]);

  const [isConfirming, setIsConfirming] = useState(false);

  const data = useMemo(() => {
    return {
      candidateName: defaultData?.candidate?.name,
      expectedPosition: defaultData?.candidate?.ExpectedPosition?.name,
      candidatePhoneNumber: defaultData?.candidate?.phoneNumber,
      candidateProfilePicture: defaultData?.candidateDocument?.url || '',
      interviewDetails: {
        method: defaultData?.candidateInvitation?.method,
        date: new Date(defaultData?.candidateInvitation?.date),
        time: defaultData?.candidateInvitation?.time,
        location: defaultData?.candidateInvitation?.via,
        link: defaultData?.candidateInvitation?.via,
        remark: defaultData?.candidateInvitation?.notes,
      },
      roContact: {
        name: defaultData?.recruiter?.name,
        email: defaultData?.recruiter?.email,
        phone: defaultData?.recruiter?.phoneNumber,
      },
    };
  }, [defaultData]);

  const handleReject = () => {
    setShowConfirmationMode("reject");
    setShowConfirmationModal(true);
  };
  const handleReschedule = () => {
    setShowConfirmationMode("reschedule");
    setShowConfirmationModal(true);
  };

  const handleAccept = async () => {
    try {
      // setIsConfirming(true)
      // await responseInvitation({
      //   status: 'Accepted'
      // }, defaultData?.candidateInvitation?.id)
      // setIsConfirming(false)
      setShowConfirmationMode("accept");
      // setIsSuccess(true)
      setShowConfirmationModal(true);
    } catch (err) {
      // setIsConfirming(false)
      setError(err?.response?.data?.error || "Something went wrong");
    }
  };

  return (
    <div className={Styles.container}>
      {defaultData?.candidateInvitation?.remark === "EXPIRED INVITATION" && (
        <div className={Styles.notifWrapper}>
          <div className={Styles.logoWrapper}>
            <img src={Images.DEADLINE_ICON} alt="icon" />
            <p>EXPIRED</p>
          </div>
          <p>Maaf surat undangan interview ini sudah expired</p>
        </div>
      )}
      <div className={Styles.topWrapper}>
        <div className={Styles.header}>
          <div className={Styles.logoWrapper}>
            <div>
              <img src={Images?.INITIAL_LOGO} alt="logo" />
            </div>
            <span>PT Permata Indonesia Sejahtera</span>
          </div>
          <div className={Styles.watermarkWrapper}>
            <p>Powered by</p>
            <img src={Images.KERJA365_LIGHT} alt="watermark" />
          </div>
        </div>
        <div className={Styles.dateWrapper}>
          <span>{moment(new Date())?.format("LL")}</span>
          <div className={Styles.qrWrapper}>
            <QRCode
              style={{
                height: 150,
                width: 150,
                maxWidth: "100%",
              }}
              value={window.location.href}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        <h3>Surat Undangan Interview</h3>
        <div className={Styles.to}>
          <p className={Styles.normal}>Kepada yang terhormat,</p>
          <p className={Styles.bold}>{data?.candidateName}</p>
        </div>
        <div className={Styles.intro}>
          <p className={Styles.normal}>
            Kami mengundang anda untuk interview tahap selanjutnya dengan posisi{" "}
            <span className={Styles.bold}>{data?.expectedPosition}</span>,
            sesuai dengan lamaran yang anda kirim sebelumnya, berikut informasi
            jadwal interview yang akan diadakan:
          </p>
        </div>
        <div className={Styles.details}>
          <div className={Styles.stacked}>
            <span className={Styles.bold}>Tanggal</span>
            <span className={Styles.bold}> :</span>
            <span className={Styles.bold}>
              {moment(new Date(`/${data?.interviewDetails?.date}`))?.format(
                "LL"
              )}
            </span>
          </div>
          <div className={Styles.stacked}>
            <span className={Styles.bold}>Jam</span>
            <span className={Styles.bold}>:</span>
            <span className={Styles.bold}>
              {data?.interviewDetails?.time} WIB
            </span>
          </div>
          {data?.interviewDetails?.method?.toLowerCase() === "offline" ? (
            <div className={Styles.stacked}>
              <span className={Styles.bold}>Tempat</span>
              <span className={Styles.bold}>:</span>
              <span className={Styles.bold}>
                {data?.interviewDetails?.location?.split("::").join(" • ")}
              </span>
            </div>
          ) : (
            <div className={Styles.stacked}>
              <span className={Styles.bold}>Link</span>
              <span className={Styles.bold}>:</span>
              {/* <span className={`${Styles.bold} ${Styles.link}`} onClick={()=> window.open(data?.interviewDetails?.link)}>{data?.interviewDetails?.link}</span> */}
              <a
                className={`${Styles.bold} ${Styles.link}`}
                href={
                  data?.interviewDetails?.link?.includes("http")
                    ? data?.interviewDetails?.link
                    : "https://" + data?.interviewDetails?.link
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.interviewDetails?.link}
              </a>
            </div>
          )}
        </div>
        <div className={Styles.outro}>
          <p className={Styles.normal}>
            Silahkan menemui{" "}
            <span>{defaultData.candidateInvitation.interviewerTitle}</span>{" "}
            <strong>{defaultData.candidateInvitation.interviewerName}</strong>{" "}
            sesuai dengan waktu yang ditentukan dan diharapkan hadir 15 menit
            sebelum interview dimulai
          </p>
        </div>
        <div className={Styles.outro}>
          <p className={Styles.normal}>Sampai berjumpa!</p>
        </div>
        <div className={data.interviewDetails.remark ? "" : Styles.closure}>
          <p className={Styles.normal}>Salam,</p>
          <p className={Styles.normal}>
            Recruitment Officer Permata Indo Sejahtera
          </p>
        </div>
      </div>
      <div className={Styles.qrContainer}>
        <div className={Styles.qrWrapper}>
          <QRCode
            style={{
              height: 150,
              width: 150,
              maxWidth: "100%",
            }}
            value={window.location.href}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.idCard}>
          <CandidateIDCard
            name={data?.candidateName}
            photoProfile={data?.candidateProfilePicture ? fileBaseUrl + data?.candidateProfilePicture : ''}
            position={data?.expectedPosition}
            phoneNumber={data?.candidatePhoneNumber}
          />
        </div>

        <div
          className={`${Styles.RemarksNote} ${data.interviewDetails.remark ? "" : Styles.Hidden
            }`}
        >
          <img src={Images.POST_IT_ICON} alt="post-it" />
          <div className={Styles.RemarksText}>
            <p>Catatan</p>
            <p>{data.interviewDetails.remark}</p>
          </div>
        </div>
      </div>



      <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
        {error || "error placeholder"}*
      </div>
      {!isSuccess && (!defaultData?.candidateInvitation?.status || defaultData?.candidateInvitation?.status?.toLowerCase() === 'resend') && (
        <div className={Styles.actionsWrapper}>
          <button disabled={isConfirming} onClick={handleReject}>
            <span>Belum bisa hadir</span>
            <Icon icon={"cross-circle"} size={20} className={Styles.red} />
          </button>
          <button disabled={isConfirming} onClick={handleAccept}>
            {isConfirming ? "Mengonfirmasi..." : "Ya, saya bisa hadir"}
          </button>
        </div>
      )}

      <div>
        <ModalWrapper
          show={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          type={isSuccess ? "fullscreen" : "normal"}
          forceCenter={isSuccess}
        >
          {showConfirmationModal && (
            <InvitationConfirmationModal
              type={confirmationMode}
              isSuccess={isSuccess}
              setIsSuccess={setIsSuccess}
              setShow={setShowConfirmationModal}
              id={defaultData?.candidateInvitation?.id}
              ordinal={2}
            />
          )}
        </ModalWrapper>
      </div>
    </div>
  );
}
