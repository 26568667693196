import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import SearchBar from "@Atom/SearchBar";

export default function OfferingAddAllowanceModal({
  options,
  selectedOptions,
  onSubmit = () => {},
  onClose = () => {},
  search,
  setSearch,
}) {
  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    setCheckedOptions([]);
  }, []);

  const filteredData = useMemo(() => {
    return options?.filter((el) =>
      el?.toLowerCase()?.includes(search?.toLowerCase())
    );
  }, [options, search]);

  const filteredOptions = useMemo(() => {
    const arr = [];
    for (let i = 0; i < filteredData.length; i++) {
      const option = filteredData[i];
      if (!selectedOptions?.find((obj) => obj === option)) {
        arr.push(option);
      }
    }
    return arr;
  }, [filteredData, selectedOptions]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <h3>Data Tunjangan</h3>
      </div>

      <div className={Styles.searchWrapper}>
        <SearchBar
          placeholder={"Cari Nama Tunjangan"}
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
        />
      </div>
      <div className={Styles.content}>
        {filteredOptions?.map((o, oI) => (
          <div key={oI}>
            <CheckboxJobPosting
              checked={checkedOptions?.find((obj) => obj === o)}
              onChange={() => {
                checkedOptions?.find((obj) => obj === o)
                  ? setCheckedOptions(
                      checkedOptions?.filter((obj) => obj !== o)
                    )
                  : setCheckedOptions([...checkedOptions, o]);
              }}
            />
            <span>{o}</span>
          </div>
        ))}
      </div>
      <div className={Styles.actions}>
        <button onClick={onClose}>Kembali</button>
        <button
          disabled={!checkedOptions?.length}
          onClick={() => onSubmit(checkedOptions)}
        >
          Tambahkan
        </button>
      </div>
    </div>
  );
}
