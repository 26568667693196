import CompletionIndicatorIcons from "@Atom/CompletionIndicatorIcons";
import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import PinMaps from "@Atom/Maps";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Spinner from "@Atom/Spinner";
import StackedProfile from "@Atom/StackedProfile";
import { fileBaseUrl } from "@Config/api";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import useWindowSize from "@Hooks/useWindowSize";
import Stepper from "@Molecule/Stepper";
import Switcher from "@Molecule/Switcher";
import VerificationButtons from "@Molecule/VerificationButtons";
import SecondVerificationModal from "@Molecule/_modal/BusinessSupport/SecondVerificationModal";
import SentContractModal from "@Molecule/_modal/BusinessSupport/SentContractModal";
import NotesRemarkDec from "@Molecule/_modal/NotesRemarkDecline";
import PreviewImageModal from "@Molecule/_modal/PreviewImageModal";
import {
  generateContract,
  getCandidateDetails,
  getContractSync,
  requestApprovalBm,
} from "@Services/support/candidate";
import Images from "@Theme/Images";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import FirstStep from "./First";
import FourthStep from "./Fourth";
import SecondStep from "./Second";
import ThirdStep from "./Third";
import Styles from "./style.module.scss";

// const PDFJS = require("pdfjs-dist/webpack");
const PDFJS = window.pdfjsLib;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry?.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default function CandidateDetailsLayout({
  setShowAccept,
  setShowReject,
  defaultData,
  trigger,
  setTrigger,
  stage,
  setStage,
  defaultGenerateContractMode = false,
  defaultSync,
  generateContractMode,
  setGenerateContractMode,
  contractList,
}) {
  const { id } = useParams();
  const [showSecondVerification, setShowSecondVerification] = useState(false);

  const defaultStoredDataForm = sessionStorage.getItem("dataForm")
    ? JSON.parse(sessionStorage.getItem("dataForm"))
    : null;

  const defaultDataForm =
    defaultStoredDataForm?.candidateId === id ? defaultStoredDataForm : null;

  const [data, setData] = useState(defaultData);

  const [sync, setSync] = useState(defaultSync);

  const [additionals, setAdditionals] = useState([]);

  const [selectedContractId, setSelectedContractId] = useState(
    defaultDataForm?.selectedContractId || null
  );

  const [selectedBM, setSelectedBM] = useState(
    defaultData?.businessManager?.employeeId || ""
  );
  const [selectedContractType, setSelectedContractType] = useState(
    defaultData?.ExpectedPosition?.contractType || ""
  );
  // c_allowances

  useEffect(() => {
    setAdditionals(contractList?.additionalTemplates);
  }, [contractList?.additionalTemplates]);

  const [selectedAdditionalsTemplates, setSelectedAdditionalsTemplates] =
    useState([]);

  useEffect(() => {
    setStage(data?.stage);
  }, [data, setStage]);

  const refetchData = useCallback(async () => {
    if (id) {
      const { response } = await getCandidateDetails(id);
      setData(response);
    }
  }, [id]);

  const refetchSync = useCallback(async () => {
    try {
      if (
        id &&
        selectedAdditionalsTemplates &&
        selectedContractId &&
        selectedBM &&
        selectedContractType
      ) {
        const { response } = await getContractSync(
          id,
          selectedAdditionalsTemplates,
          {
            ...selectedContractId,
            businessManagerID: selectedBM,
            contractType: selectedContractType,
          }
        );
        setSync(response);
      }
    } catch (err) {
      console.log(err, "error on refetch sync");
    }
  }, [
    id,
    selectedAdditionalsTemplates,
    selectedContractId,
    selectedBM,
    selectedContractType,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData, trigger]);

  useEffect(() => {
    refetchSync();
  }, [refetchSync, generateContractMode]);

  if (generateContractMode) {
    return (
      <ContractMode
        data={data}
        sync={sync}
        additionals={additionals}
        setAdditionals={setAdditionals}
        selectedAdditionalsTemplates={selectedAdditionalsTemplates}
        setSelectedAdditionalsTemplates={setSelectedAdditionalsTemplates}
        setGenerateContractMode={setGenerateContractMode}
        setSelectedContractId={setSelectedContractId}
        selectedContractId={selectedContractId}
        contractList={contractList}
        defaultDataForm={defaultDataForm}
        selectedBM={selectedBM}
        setSelectedBM={setSelectedBM}
        selectedContractType={selectedContractType}
        setSelectedContractType={setSelectedContractType}
      />
    );
  }

  return (
    <DetailsMode
      setShowAccept={setShowAccept}
      setShowReject={setShowReject}
      data={data}
      sync={sync}
      showSecondVerification={showSecondVerification}
      setShowSecondVerification={setShowSecondVerification}
      setTrigger={setTrigger}
    />
  );
}

const DetailsMode = ({
  setShowAccept,
  setShowReject,
  data,
  sync,
  showSecondVerification,
  setShowSecondVerification,
  setTrigger,
}) => {
  const [mobileMode, setMobileMode] = useState("details");
  const detailsTemplate = useMemo(() => {
    return [
      {
        title: "Personal",
        details: [
          {
            name: "Application ID",
            value: data?.ContractDocument?.employeeId,
          },
          {
            name: "No. Kartu Keluarga",
            value: data?.CandidateComprehensive?.familyId,
          },
          {
            name: "No. KTP",
            value: data?.CandidateComprehensive?.personId,
          },
          {
            name: "Masa berlaku",
            value: data?.CandidateComprehensive?.personIdPeriod?.includes("-")
              ? moment(
                  new Date(data?.CandidateComprehensive?.personIdPeriod)
                )?.format("DD-MM-YYYY")
              : data?.CandidateComprehensive?.personIdPeriod,
          },
          {
            name: "Nama",
            value: data?.name,
          },
          {
            name: "Tempat lahir",
            value: data?.CandidateComprehensive?.placeOfBirth,
          },
          {
            name: "Tanggal lahir",
            value: data?.CandidateComprehensive?.dateOfBirth
              ? moment(
                  new Date(data?.CandidateComprehensive?.dateOfBirth)
                )?.format("DD-MM-YYYY")
              : "-",
          },
          {
            name: "Jenis kelamin",
            value: data?.CandidateComprehensive?.gender,
          },
          {
            name: "Nama ibu",
            value: data?.CandidateComprehensive?.motherName,
          },
          {
            name: "Agama",
            value: data?.CandidateComprehensive?.religion,
          },
          {
            name: "Status pernikahan",
            value: data?.CandidateComprehensive?.maritalStatus,
          },
          {
            name: "No. Handphone",
            value: data?.CandidateComprehensive?.phoneNumber,
          },
          {
            name: "Email",
            value: data?.CandidateComprehensive?.email,
          },
          {
            name: "Golongan darah",
            value: data?.CandidateComprehensive?.bloodType,
          },
          {
            name: "Tinggi badan",
            value: data?.CandidateComprehensive?.bodyHeight,
          },
          {
            name: "Berat badan",
            value: data?.CandidateComprehensive?.bodyWeight,
          },
        ],
      },
      {
        title: "Alamat KTP",
        details: [
          {
            name: "Alamat",
            value: data?.CandidateComprehensive?.address,
          },
          {
            name: "RT",
            value: data?.CandidateComprehensive?.neighborhood
              ? data?.CandidateComprehensive?.neighborhood?.split("/")[0]
              : "",
          },
          {
            name: "RW",
            value: data?.CandidateComprehensive?.neighborhood
              ? data?.CandidateComprehensive?.neighborhood?.split("/")[1]
              : "",
          },
          {
            name: "Kelurahan",
            value: data?.CandidateComprehensive?.subDistrict,
          },
          {
            name: "Kecamatan",
            value: data?.CandidateComprehensive?.district,
          },
          {
            name: "Kota",
            value: data?.CandidateComprehensive?.city,
          },
          {
            name: "Provinsi",
            value: data?.CandidateComprehensive?.province,
          },
          {
            name: "Kode POS",
            value: data?.CandidateComprehensive?.postal,
          },
        ],
      },
      {
        title: "Alamat Domisili",
        details: [
          {
            name: "Alamat",
            value: data?.CandidateComprehensive?.domicileAddress,
          },
          {
            name: "RT",
            value: data?.CandidateComprehensive?.domicileNeighbourhood
              ? data?.CandidateComprehensive?.domicileNeighbourhood?.split(
                  "/"
                )[0]
              : "",
          },
          {
            name: "RW",
            value: data?.CandidateComprehensive?.domicileNeighbourhood
              ? data?.CandidateComprehensive?.domicileNeighbourhood?.split(
                  "/"
                )[1]
              : "",
          },
          {
            name: "Kelurahan",
            value: data?.CandidateComprehensive?.domicileSubDistrict,
          },
          {
            name: "Kecamatan",
            value: data?.CandidateComprehensive?.domicileDistrict,
          },
          {
            name: "Kota",
            value: data?.CandidateComprehensive?.domicileCity,
          },
          {
            name: "Provinsi",
            value: data?.CandidateComprehensive?.domicileProvince,
          },
          {
            name: "Kode POS",
            value: data?.CandidateComprehensive?.domicilePostal,
          },
        ],
      },
      {
        title: "Pendidikan",
        details: [
          {
            name: "Pendidikan terakhir",
            value: data?.CandidateComprehensive?.lastEducationType,
          },
          {
            name: "Nama Sekolah / Universitas",
            value: data?.CandidateComprehensive?.lastEducationName,
          },
          {
            name: "Fakultas / Jurusan",
            value: data?.CandidateComprehensive?.lastEducationFaculty,
          },
          {
            name: "IPK",
            value: data?.CandidateComprehensive?.lastEducationGrade,
          },
        ],
      },
      {
        title: "Kontak Darurat",
        details: [
          {
            name: "Nama",
            value: data?.CandidateComprehensive?.emergencyContactName,
          },
          {
            name: "Hubungan",
            value: data?.CandidateComprehensive?.emergencyContactRelation,
          },
          {
            name: "Telp / HP",
            value: data?.CandidateComprehensive?.emergencyContactNumber,
          },
        ],
      },
      {
        title: "Kontak Serumah",
        details: [
          {
            name: "Nama",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1]?.name
                : "-",
          },
          {
            name: "Hubungan",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1].relation
                : "-",
          },
          {
            name: "Telp / HP",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1].phoneNumber
                : "-",
          },
        ],
      },
      {
        title: "Kontak Tidak Serumah",
        details: [
          {
            name: "Nama",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2]?.name
                : "-",
          },
          {
            name: "Hubungan",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2].relation
                : "-",
          },
          {
            name: "Telp / HP",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2].phoneNumber
                : "-",
          },
        ],
      },
      {
        title: "Others",
        details: [
          {
            name: "RO code",
            value: data?.recruiterID,
          },
          {
            name: "Nama bank",
            value: data?.CandidateComprehensive?.BankName,
          },
          {
            name: "No. Rekening",
            value: data?.CandidateComprehensive?.BankNumber,
          },
          {
            name: "No. NPWP",
            value: data?.CandidateComprehensive?.taxId,
          },
          {
            name: "Status pajak",
            value: "-",
          },
          {
            name: "Catatan",
            value: "-",
          },
          {
            name: "Eligible RO Incentive",
            value: "-",
          },
        ],
      },
      {
        title: "Sosial Media",
        details: [
          {
            name: "Facebook",
            value: data?.CandidateComprehensive?.facebook || "-",
          },
          {
            name: "Twitter",
            value: data?.CandidateComprehensive?.twitter || "-",
          },
          {
            name: "Instagram",
            value: data?.CandidateComprehensive?.instagram || "-",
          },
          {
            name: "Linkedin",
            value: data?.CandidateComprehensive?.linkedin || "-",
          },
        ],
      },
    ];
  }, [data]);

  const [pdfUrl, setPdfUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loadingPdf, setLoadingPdf] = useState(false);

  // console.log(data, "sync candidate detail kali");

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoadingPdf(true);
      const res = await convertHtmlToPdf(
        data?.ContractDocument?.template,
        "CONTRACT",
        {
          ...data,
          ...data.ContractDocument,
          position: data?.ExpectedPosition?.name,
          image: data?.ContractDocument?.image
            ? fileBaseUrl + data?.ContractDocument?.image
            : "",
          initials: data?.ContractDocument?.initials
            ? fileBaseUrl + data?.ContractDocument?.initials
            : "",
          signature: data?.ContractDocument?.signature
            ? fileBaseUrl + data?.ContractDocument?.signature
            : "",
          contractType: data?.ExpectedPosition?.contractType,
        }
      );
      setPdfUrl(res);
      setLoadingPdf(false);
    };
    if (data?.ContractDocument?.template) {
      getPdfUrl();
    }
  }, [data, data?.ContractDocument?.template, sync]);

  const ref = useRef(null);
  const ref2 = useRef(null);

  const isInViewPort = useIsInViewport(ref);
  const isInViewPort2 = useIsInViewport(ref2);

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  const activeRef = useRef();
  const inactiveRef = useRef();

  const isActiveInViewPort = useIsInViewport(activeRef);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [isInViewPort, isInViewPort2, isActiveInViewPort]);

  const [activeSwitch, setActiveSwitch] = useState("Dokumen");

  const switcherOptions = [
    {
      title: "Dokumen",
      onSwitch: () => {
        setActiveSwitch("Dokumen");
      },
    },
    {
      title: "Kontrak",
      onSwitch: () => {
        setActiveSwitch("Kontrak");
      },
    },
  ];

  const images = useMemo(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter(
        (obj) => !obj?.url?.toLowerCase().includes("pdf-pdf")
      );

      for (let i = 0; i < filtered?.length; i++) {
        arr.push({
          id: filtered[i].id,
          type: filtered[i].type,
          src: filtered[i].url?.includes("http")
            ? filtered[i].url
            : fileBaseUrl + filtered[i].url,
          url: filtered[i].url?.includes("http")
            ? filtered[i].url
            : fileBaseUrl + filtered[i].url,
        });
      }
    }

    return arr;
  }, [data]);

  const [images2, setImages2] = useState([]);

  const allImages = useMemo(() => {
    return images.concat(images2);
  }, [images, images2]);

  useEffect(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter((obj) =>
        obj?.url?.toLowerCase().includes("pdf-pdf")
      );
      for (let i = 0; i < filtered?.length; i++) {
        const each = {
          id: filtered[i].id,
          type: filtered[i].type,
          url: filtered[i].url?.includes("http")
            ? filtered[i].url
            : fileBaseUrl + filtered[i].url,
          // src: innerArr[i]
        };
        const url = filtered[i].url?.includes("http")
          ? filtered[i].url
          : fileBaseUrl + filtered[i].url;
        if (url) {
          const loadDefaultImages = async () => {
            const imagesArr = [];
            if (url === undefined) {
              imagesArr.push(null);
            } else {
              const uri = url;
              var pdf = await PDFJS.getDocument({ url: uri }).promise;
              const canvas = document.createElement("canvas");
              for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                  canvasContext: context,
                  viewport: viewport,
                }).promise;
                imagesArr.push(canvas.toDataURL());
              }
              canvas.remove();
            }
            const first = imagesArr?.length ? imagesArr[0] : "";
            each.src = first;
          };
          loadDefaultImages();
        } else {
          // setImages2([])
        }
        arr.push(each);
      }
    }
    setImages2(arr);
  }, [data]);

  const [images3, setImages3] = useState([]);

  useEffect(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter((obj) =>
        obj?.url?.toLowerCase().includes("pdf-pdf")
      );
      for (let i = 0; i < filtered?.length; i++) {
        const each = {
          id: filtered[i].id,
          type: filtered[i].type,
          images: [],
          // src: innerArr[i]
        };
        const url = filtered[i].url?.includes("http")
          ? filtered[i].url
          : fileBaseUrl + filtered[i].url;
        if (url) {
          const loadDefaultImages = async () => {
            const imagesArr = [];
            if (url === undefined) {
              imagesArr.push(null);
            } else {
              const uri = url;
              var pdf = await PDFJS.getDocument({ url: uri }).promise;
              const canvas = document.createElement("canvas");
              for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                  canvasContext: context,
                  viewport: viewport,
                }).promise;
                imagesArr.push(canvas.toDataURL());
              }
              canvas.remove();
            }
            each.images = imagesArr;
          };
          loadDefaultImages();
        } else {
          // setImages2([])
        }
        arr.push(each);
      }
    }
    setImages3(arr);
  }, [data]);

  const spreadedImages3 = useMemo(() => {
    const arr = [];
    for (let i = 0; i < images3?.length; i++) {
      let each = {
        id: images3[i]?.id,
        type: images3[i].type,
      };
      // const imagesArr = images
      if (images3[i]?.images) {
        for (let j = 0; j < images3[i]?.images?.length; j++) {
          each.src = images3?.images[j];
          arr.push(each);
        }
      }
    }
    return arr;
  }, [images3]);

  useEffect(() => {}, [spreadedImages3]);

  const [translateAmount, setTranslateAmount] = useState(0);

  const [selectedImage, setSelectedImage] = useState(
    allImages?.length ? allImages[0] : null
  );
  const selectedImageIndex = allImages.findIndex(
    (i) => i.id === selectedImage?.id
  );

  const onNextImage = () => {
    if (allImages[selectedImageIndex + 1]) {
      setSelectedImage(allImages[selectedImageIndex + 1]);
      if (!isInViewPort2) {
        setTranslateAmount(translateAmount - 100);
      }
    }
    // else {
    //   setSelectedImage(images[0])
    //   setTranslateAmount(0)
    // }
  };

  const onPreviousImage = () => {
    if (allImages[selectedImageIndex - 1]) {
      setSelectedImage(allImages[selectedImageIndex - 1]);
      if (translateAmount < 0) {
        setTranslateAmount(translateAmount + 100);
      }
    }
    //  else {
    //   setSelectedImage(images[images?.length - 1])
    //   setTranslateAmount(0)
    // }
  };

  let imageAngle = 0;

  const rotateImage = () => {
    imageAngle += 90;
    var div = document.getElementById("image"),
      angle = imageAngle;

    div.style.webkitTransform = "rotate(" + angle + "deg)";
    div.style.mozTransform = "rotate(" + angle + "deg)";
    div.style.msTransform = "rotate(" + angle + "deg)";
    div.style.oTransform = "rotate(" + angle + "deg)";
    div.style.transform = "rotate(" + angle + "deg)";

    // rotated = !rotated;
  };

  const resetRotation = useCallback(() => {
    var div = document.getElementById("image"),
      angle = 0;

    div.style.webkitTransform = "rotate(" + angle + "deg)";
    div.style.mozTransform = "rotate(" + angle + "deg)";
    div.style.msTransform = "rotate(" + angle + "deg)";
    div.style.oTransform = "rotate(" + angle + "deg)";
    div.style.transform = "rotate(" + angle + "deg)";
  }, []);

  useEffect(() => {
    resetRotation();
  }, [resetRotation, selectedImage?.src]);

  const [showImageModal, setShowImageModal] = useState(false);

  // console.log(data);
  const maxLength = 165;
  const [showNotes, setShowNotes] = useState(false);

  return (
    <div className={Styles.container}>
      <div
        className={`${Styles.left} ${
          mobileMode !== "details" ? Styles.hide : ""
        }`}
      >
        <div className={Styles.stickyTop}>
          <div className={Styles.profile} ref={elementRef}>
            <img src={Images.AVA_DEFAULT} alt="" />
            <div>
              <span>{data?.name}</span>
              <span>
                {data?.phoneNumber} • {data?.recruiterID}
              </span>
            </div>
            <button
              onClick={() =>
                mobileMode === "details"
                  ? setMobileMode("documents")
                  : setMobileMode("details")
              }
            >
              <Icon
                icon={
                  mobileMode === "details"
                    ? "chevron-double-right"
                    : "chevron-double-left"
                }
                size={24}
              />
            </button>
          </div>
          <div className={Styles.detailsHeader}>
            <span>Information</span>
            <span>Details</span>
          </div>
        </div>

        <div
          className={`${Styles.detailsContent}  ${
            mobileMode !== "details" ? Styles.hide : ""
          }`}
        >
          {detailsTemplate?.map((perType, i) => (
            <div key={i} className={Styles.perType}>
              <span>{perType?.title}</span>
              <div className={Styles.list}>
                {perType?.details?.map((detail, j) => (
                  <div key={j} className={Styles.perDetail}>
                    <span>{detail?.name}</span>
                    <span>
                      {detail?.value && detail?.value !== "undefined"
                        ? detail?.value
                        : "-"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div ref={ref} style={{ height: "1px", background: "transparent" }} />
          {!isInViewPort && <div className={Styles.gradientback} />}
          {/* {} */}
        </div>
        {data?.remark === null || data?.remark === "" ? null : (
          <div className={Styles.remarkBox}>
            <div className={Styles.titleNotes}>
              <Icon icon={"notes-bs"} size={20} />
              <span className={Styles.catatan}>Catatan:</span>
            </div>
            <div className={Styles.contentNotes}>
              <span className={Styles.textP}>
                {data?.remark
                  ? data?.remark?.length > maxLength
                    ? data?.remark?.substring(0, maxLength) + "..."
                    : data?.remark
                  : "-"}
              </span>
              {data?.remark?.length > maxLength ? (
                <span
                  className={Styles.detailText}
                  onClick={() => setShowNotes(true)}
                >
                  Selengkapnya
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {showImageModal ? (
        <div
          className={`${Styles.previewWrapper} ${Styles.right}`}
          style={{ display: showImageModal ? "block" : "none" }}
        >
          {/* <ModalWrapper show={showImageModal} handleClose={() => setShowImageModal(false)} forceCenter> */}
          <PreviewImageModal
            url={selectedImage?.url}
            onClose={() => setShowImageModal(false)}
          />
          {/* </ModalWrapper> */}
        </div>
      ) : (
        <div
          className={`${Styles.right} ${
            mobileMode === "details" ? Styles.hide : ""
          }`}
        >
          <div
            className={Styles.previewWrapper}
            style={{ display: showImageModal ? "block" : "none" }}
          >
            {/* <ModalWrapper show={showImageModal} handleClose={() => setShowImageModal(false)} forceCenter> */}
            <PreviewImageModal
              url={selectedImage?.url}
              onClose={() => setShowImageModal(false)}
            />
            {/* </ModalWrapper> */}
          </div>
          <div className={Styles.top} style={{ height: `${height}px` }}>
            <div className={Styles.profile}>
              <img src={Images.AVA_DEFAULT} alt="" />
              <div>
                <span>{data?.name}</span>
                <span>
                  {data?.phoneNumber} • {data?.recruiterID}
                </span>
              </div>
              <button
                onClick={() =>
                  mobileMode === "details"
                    ? setMobileMode("documents")
                    : setMobileMode("details")
                }
              >
                <Icon
                  icon={
                    mobileMode === "details"
                      ? "chevron-double-right"
                      : "chevron-double-left"
                  }
                  size={24}
                />
              </button>
            </div>
            <div>
              <VerificationButtons
                verificationDates={["", ""]}
                verificationStatus={[
                  data?.stage !== "REGISTER" ? true : false,
                  data?.stage === "SECOND-VERIFICATION" ||
                  data?.stage === "IOS-SUCCESS"
                    ? true
                    : false,
                  // false,
                ]}
                onVerifyActions={[() => {}, () => {}]}
                setShowAccept={() => {
                  if (!data?.ContractDocument?.signature) {
                    setShowAccept(1);
                  } else {
                    setShowSecondVerification(true);
                  }
                }}
                setShowReject={setShowReject}
                tag={data?.tag}
                data={data?.remark}
                secondVerifyAvailable={
                  data?.ContractDocument?.signature &&
                  data?.stage !== "IOS-SUCCESS"
                    ? true
                    : false
                }
                stage={data?.stage}
              />
            </div>
          </div>
          <div className={Styles.bottom}>
            <div>
              <Switcher
                options={switcherOptions}
                activeSwitchTitle={activeSwitch}
              />
            </div>

            <div className={Styles.main}>
              {activeSwitch === "Dokumen" ? (
                allImages?.length ? (
                  <div className={Styles.docWrapper}>
                    <div className={Styles.mainDoc}>
                      <button
                        onClick={onPreviousImage}
                        className={`${Styles.controlButton} ${Styles.prev}`}
                      >
                        <Icon icon={"arrow-left"} size={24} />
                      </button>
                      <img id="image" src={selectedImage?.src} alt="" />
                      <button
                        onClick={onNextImage}
                        className={`${Styles.controlButton} ${Styles.next}`}
                      >
                        <Icon icon={"arrow-right"} size={24} />
                      </button>
                      <div className={Styles.imageButtons}>
                        <div
                          className={Styles.button}
                          onClick={() => rotateImage()}
                        >
                          <img src={Images.ROTATE} alt="" />
                        </div>
                        <button
                          className={Styles.button}
                          onClick={() => {
                            setShowImageModal(true);
                          }}
                        >
                          <Icon icon={"frame"} size={24} />
                        </button>
                      </div>
                    </div>
                    {selectedImage && (
                      <div className={Styles.type}>{selectedImage?.type}</div>
                    )}
                    <div className={Styles.sliderWrapper}>
                      <div
                        className={Styles.slider}
                        style={{
                          transform: `translateX(${translateAmount}px)`,
                          width: `calc(${translateAmount}px * -1 + 100%)`,
                        }}
                      >
                        {!allImages?.length && (
                          <div>
                            <div ref={activeRef} />
                            <div ref={inactiveRef} />
                          </div>
                        )}
                        {allImages?.map((image, i) => (
                          <div
                            key={i}
                            // className={Styles.thumbnail}
                            className={`${Styles.thumbnail} ${
                              image?.id === selectedImage?.id
                                ? Styles.active
                                : ""
                            }`}
                          >
                            <img
                              src={image?.src}
                              alt=""
                              ref={
                                image?.id === selectedImage?.id
                                  ? activeRef
                                  : inactiveRef
                              }
                              onClick={() => setSelectedImage(image)}
                            />
                          </div>
                        ))}
                        <div ref={ref2} className={Styles.parameter}>
                          p
                        </div>
                        {!isInViewPort2 && (
                          <div className={`${Styles.verticalGradient}`}></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.noDoc}>
                    <span>No Document Available</span>

                    <div ref={activeRef} />
                    <div ref={inactiveRef} />
                    <div ref={ref2} />
                  </div>
                )
              ) : data?.ContractDocument?.signature && pdfUrl ? (
                <div className={Styles.DocumentContainer}>
                  <div className={Styles.pdfWrapper}>
                    <embed
                      src={`${pdfUrl}#navpanes=0`}
                      type="application/pdf"
                    ></embed>
                  </div>
                  <div className={Styles.SignContainer}>
                    <div className={Styles.SignWrapper}>
                      <p>Signature</p>
                      <div className={Styles.Sign}>
                        <img
                          src={fileBaseUrl + data?.ContractDocument?.signature}
                          alt="signature"
                        />
                      </div>
                    </div>
                    <div className={Styles.SignWrapper}>
                      <p>Inital</p>
                      <div className={Styles.Sign}>
                        <img
                          src={fileBaseUrl + data?.ContractDocument?.initials}
                          alt="signature"
                        />
                      </div>
                    </div>
                    <div className={Styles.SignWrapper}>
                      <p>Photo</p>
                      <img
                        src={fileBaseUrl + data?.ContractDocument?.image}
                        alt="profile"
                      />
                    </div>
                    <div className={Styles.SignWrapper}>
                      <p className={Styles.SectionTitle}>Lokasi</p>
                      <PinMaps
                        lat={+data?.ContractDocument?.latitude}
                        lng={+data?.ContractDocument?.longitude}
                        mapHeight={"145px"}
                        mapWidth={"100%"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={Styles.contractWrapper}>
                  <div ref={ref2} />
                  <img src={Images.KONTRAK} alt="" />
                  <h4>Kontrak Belum Ada</h4>
                  <span>
                    Kontrak akan muncul ketika verifikasi kedua dilakukan
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showSecondVerification && (
        <ModalWrapper
          show={showSecondVerification}
          handleClose={() => setShowSecondVerification(false)}
        >
          <SecondVerificationModal
            data={data}
            handleClose={() => setShowSecondVerification(false)}
            setTrigger={setTrigger}
          />
        </ModalWrapper>
      )}

      {showNotes && (
        <NotesRemarkDec
          handleClose={() => setShowNotes(false)}
          handleDone={() => setShowNotes(false)}
          data={data?.remark || "-"}
        />
      )}
    </div>
  );
};

const ContractMode = ({
  data,
  sync,
  selectedAdditionals,
  setSelectedAdditionals,
  additionals,
  setAdditionals,
  selectedAdditionalsTemplates,
  setSelectedAdditionalsTemplates,
  setGenerateContractMode,
  setTriggerSync,
  selectedContractId,
  setSelectedContractId,
  contractList,
  defaultDataForm,
  selectedBM,
  setSelectedBM,
  selectedContractType,
  setSelectedContractType,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [stepsOptions, setStepOptions] = useState([
    {
      id: 1,
      label: "Informasi Kontrak",
      isDone: false,
    },
    {
      id: 2,
      label: "Tunjangan",
      isDone: false,
    },
    {
      id: 3,
      label: "Tanggal Kontrak",
      isDone: false,
    },
    {
      id: 4,
      label: "Kontrak Tambahan",
      isDone: false,
    },
  ]);

  const [pdf, setPdf] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [images, setImages] = useState([]);
  const { width } = useWindowSize();

  const [htmlString, setHtmlString] = useState("");

  const [preview, setPreview] = useState(false);

  const [previewing, setPreviewing] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoadingPdf(true);
      const data = await convertHtmlToPdf(htmlString);
      setPdfUrl(data);
      setLoadingPdf(false);
    };
    if (htmlString) {
      getPdfUrl();
    }
  }, [htmlString]);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      }).promise;
      setPdf(_PDF_DOC);
    } catch (error) {
      alert(error.message);
    }
  }

  const renderPage = useCallback(async () => {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");
    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      // setWidth(viewport.width);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
  }, [pdf]);

  useEffect(() => {
    isMobile && pdf && preview && renderPage();
  }, [pdf, renderPage, preview]);

  useEffect(() => {
    isMobile && pdfUrl && preview && showPdf(pdfUrl);
  }, [pdfUrl, preview]);

  const [currentStep, setCurrentStep] = useState(1);

  const [maxStep, setMaxStep] = useState(1);

  const handleClickStep = (step) => {
    if (maxStep >= step) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("generateStep", currentStep);
  }, [currentStep]);

  const onClickPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onClickNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
    if (maxStep === currentStep) {
      setMaxStep(currentStep + 1);
    }
  }, [currentStep, maxStep]);

  const [form, setForm] = useState({
    vendor: "",
    salesCode: "",
    client: "",
    pks: "",
    position: "",
    jobTitle: "",
    umk: "",
    location: "",
    template: "",
    placement: "",
    salary: "",
    allowances: [],
    contractIncludes: [],
    contractDate: "",
    contractStartDate: "",
    contractEndDate: "",
    remark: "",
  });

  const [values, setValues] = useState(
    defaultDataForm?.candidateContractValues || []
  );

  // console.log(values, 'values');

  const startDate = values?.find(
    (obj) => obj?.code === "{CONTRACT_STARTDATE}"
  )?.value;
  const endDate = values?.find(
    (obj) => obj?.code === "{CONTRACT_ENDDATE}"
  )?.value;

  useEffect(() => {
    if (!form?.contractStartDate || !form?.contractEndDate) {
      setForm((prev) => {
        return {
          ...prev,
          contractStartDate: new Date(startDate),
          contractEndDate: new Date(endDate),
        };
      });
    }
  }, [endDate, form?.contractEndDate, form?.contractStartDate, startDate]);

  const defaultOtherData = sessionStorage.getItem("c_allowances");

  const [allowances, setAllowances] = useState(
    defaultOtherData || defaultDataForm?.candidateAllowances || []
  );

  // useEffect(() => {
  //   sessionStorage.setItem("c_allowances", JSON.stringify(allowances || []));
  // }, [allowances]);

  const [additionalValues, setAdditionalValues] = useState([]);
  const [c2cAdditionals, setC2cAdditionals] = useState([]);

  // const valuesToSet = useMemo(() => {
  //   if (
  //     defaultDataForm &&
  //     defaultDataForm?.candidateContractValues?.length &&
  //     defaultDataForm?.candidateContractValues[0]?.contractTemplateID ===
  //       selectedContractId?.id
  //   ) {
  //     return defaultDataForm?.candidateContractValues;
  //   } else {
  //     return sync?.candidateContractValues;
  //   }
  // }, [defaultDataForm, selectedContractId?.id, sync?.candidateContractValues]);

  // const allowancesToSet = useMemo(() => {
  //   if (
  //     defaultDataForm &&
  //     defaultDataForm?.candidateAllowances?.length &&
  //     defaultDataForm?.candidateAllowances[0]?.contractTemplateID ===
  //       selectedContractId?.id
  //   ) {
  //     return defaultDataForm?.candidateAllowances;
  //   } else {
  //     return sync?.candidateAllowances;
  //   }
  // }, [defaultDataForm, selectedContractId?.id, sync?.candidateAllowances]);

  useEffect(() => {
    if (
      sync?.candidateAllowances !== null ||
      sync?.candidateAllowances?.length > 0
    ) {
      const matched = sync?.candidateAllowances?.every((obj) =>
        allowances?.some((el) => obj?.code === el?.code)
      );
      if (!matched) {
        setAllowances(sync?.candidateAllowances);
      }
    } else {
      setAllowances([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.candidateAllowances]);

  useEffect(() => {
    setAdditionalValues(sync?.additionalContractValues || []);
  }, [sync?.additionalContractValues]);

  useEffect(() => {
    setC2cAdditionals(sync?.c2cAdditionalValues || []);
  }, [sync?.c2cAdditionalValues]);

  useEffect(() => {
    if (
      sync?.candidateContractValues > 0 ||
      sync?.candidateContractValues !== null
    ) {
      const matched = sync?.candidateContractValues?.every((obj) =>
        values?.some((el) => obj?.code === el?.code)
      );
      if (!matched) {
        setValues(sync?.candidateContractValues);
      }
    } else {
      setValues([]);
    }
    // setValues(valuesToSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.candidateContractValues]);

  const [searchTerm, setSearchTerm] = useState("");

  const AdditionalFilterType = useMemo(() => {
    return additionals?.filter(
      // (el) => el?.contractType === data?.jobPost?.contractType
      (el) => el?.contractType === selectedContractType
    );
    // }, [additionals, data?.jobPost?.contractType]);
  }, [additionals, selectedContractType]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const newData = useMemo(() => {
    if (values?.length > 0) {
      return values?.map((item) => {
        if (
          item?.label === "Bulan Tanggal Kontrak ROMAWI" ||
          item?.label === "Posisi" ||
          item?.label === "Jabatan PIC"
        ) {
          return {
            ...item,
            value:
              typeof item.value === "string"
                ? item.value.toUpperCase()
                : item.value,
          };
        } else {
          return {
            ...item,
            value:
              typeof item.value === "string"
                ? toTitleCase(item.value)
                : item.value,
          };
        }
      });
    }
  }, [values]);

  const C2CData = useMemo(() => {
    if (c2cAdditionals?.length > 0) {
      return c2cAdditionals?.map((item) => {
        return {
          ...item,
          params: item?.params?.map((el) => {
            if (
              el?.codeLocal === "{POSITION_SGF}" ||
              el?.codeLocal === "{AUTO_MONTH_ROMAWI}" ||
              el?.codeLocal === "{BM_POSITION}" ||
              el?.codeLocal === "{POSITION}"
            ) {
              return {
                ...el,
                value:
                  typeof el.value === "string"
                    ? el.value.toUpperCase()
                    : el.value,
              };
            } else {
              return {
                ...el,
                value:
                  typeof el.value === "string"
                    ? toTitleCase(el.value)
                    : el.value,
              };
            }
          }),
        };
      });
    }
  }, [c2cAdditionals]);

  const [selectedItemAllowance, setSelectedItemAllowances] = useState([]);

  const filterSalary = useMemo(() => {
    if (values?.length > 0) {
      return values?.find((el) => el?.code === "{SALARY}");
    }
  }, [values]);

  const [valueVariableCost, setValueVariableCost] = useState(
    defaultDataForm?.variableCost || []
  );

  useEffect(() => {
    if (sync?.variableCost !== null) {
      const matched = sync?.variableCost?.every((obj) =>
        valueVariableCost?.some((el) => obj?.label === el?.label)
      );
      if (!matched) {
        setValueVariableCost(sync?.variableCost);
      }
    } else {
      setValueVariableCost([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.variableCost]);

  useEffect(() => {
    if (c2cAdditionals?.length > 0) {
      setC2cAdditionals((prev) =>
        prev?.map((item) => {
          return {
            ...item,
            params: item?.params?.map((el) => {
              if (el?.codeLocal === "{SALARY}") {
                return {
                  ...el,
                  value: Number(
                    filterSalary?.value.split(".").join("")
                  ).toLocaleString("id"),
                };
              } else {
                return el;
              }
            }),
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSalary]);

  // useEffect(() => {
  //   // Avoid modifying valueVariableCost here
  // }, [filterSalary, c2cAdditionals]);

  // useEffect(() => {
  //   if (data?.ExpectedPosition?.contractType === "PKM") {
  //     setValues((prev) =>
  //       prev?.map((el) => {
  //         if (el?.code === "{CONTRACT_ENDDATE}") {
  //           return {
  //             ...el,
  //             value: "30 Desember 2099",
  //           };
  //         } else {
  //           return el;
  //         }
  //       })
  //     );
  //   }
  // }, [data?.ExpectedPosition?.contractType]);

  const content = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            values={newData}
            setValues={setValues}
            sync={sync}
            setSelectedContractId={setSelectedContractId}
            selectedContractId={selectedContractId}
            contractList={contractList}
            selectedBM={selectedBM}
            setSelectedBM={setSelectedBM}
            selectedContractType={selectedContractType}
            setSelectedContractType={setSelectedContractType}
          />
        );
      case 2:
        return (
          <SecondStep
            values={newData}
            setValues={setValues}
            allowances={allowances}
            // allowances={[]}
            setAllowances={setAllowances}
            sync={sync}
            contractList={contractList}
            setSelectedItemAllowances={setSelectedItemAllowances}
            selectedItemAllowance={selectedItemAllowance}
            data={data}
            valueVariableCost={valueVariableCost}
            setValueVariableCost={setValueVariableCost}
          />
        );
      case 3:
        return (
          <FourthStep
            form={form}
            setForm={setForm}
            values={values}
            setValues={setValues}
            contractType={selectedContractType}
            // contractType={data?.jobPost?.contractType}
          />
        );
      case 4:
        return (
          <ThirdStep
            additionals={AdditionalFilterType}
            setAdditionals={setAdditionals}
            selectedAdditionalsTemplates={selectedAdditionalsTemplates}
            setSelectedAdditionalsTemplates={setSelectedAdditionalsTemplates}
            additionalValues={additionalValues}
            setAdditionalValues={setAdditionalValues}
            setC2cAdditionals={setC2cAdditionals}
            c2cAdditionals={c2cAdditionals}
            searchTerm={searchTerm}
            selectedContractType={selectedContractType}
          />
        );
      default:
        return (
          <FirstStep
            form={form}
            setForm={setForm}
            selectedBM={selectedBM}
            setSelectedBM={setSelectedBM}
            selectedContractType={selectedContractType}
            setSelectedContractType={setSelectedContractType}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // setSelectedItemAllowances,
    currentStep,
    values,
    sync,
    allowances,
    setAdditionals,
    selectedAdditionalsTemplates,
    setSelectedAdditionalsTemplates,
    form,
    additionalValues,
    c2cAdditionals,
    additionals,
    searchTerm,
    selectedItemAllowance,
    data,
    valueVariableCost,
    selectedBM,
    setSelectedBM,
    selectedContractType,
    setSelectedContractType,
    // setValueVariableCost,
  ]);

  const { id } = useParams();

  const [showSuccess, setShowSuccess] = useState(false);

  const dataForm = useMemo(() => {
    return {
      candidateId: id,
      selectedContractId: selectedContractId,
      contractTemplateID: selectedContractId?.id,
      candidateAllowances: allowances,
      candidateContractValues: newData,
      // allAdditionalTemplates: additionals,
      additionalContractValues: additionalValues,
      c2cAdditionalValues: C2CData,
      contractSource: selectedContractId?.name,
      source: selectedContractId?.source,
      variableCost: valueVariableCost?.length > 0 ? valueVariableCost : [],
      remark: form?.remark,
      company: "PERMATA INDONESIA",
    };
  }, [
    C2CData,
    newData,
    additionalValues,
    allowances,
    form?.remark,
    id,
    selectedContractId,
    // values,
    valueVariableCost,
  ]);

  useEffect(() => {
    sessionStorage.setItem("dataForm", JSON.stringify(dataForm));
  }, [dataForm]);

  const [errorSubmit, setErrorSubmit] = useState("");

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    let formToSend = {
      contractTemplateID: selectedContractId?.id,
      action: action,
      candidateAllowances: allowances,
      candidateContractValues: newData,
      // allAdditionalTemplates: additionals,
      additionalContractValues: additionalValues,
      c2cAdditionalValues: C2CData,
      contractSource: selectedContractId?.name,
      source: selectedContractId?.source,
      variableCost: valueVariableCost?.length > 0 ? valueVariableCost : null,
      remark: null,
      company: "PERMATA INDONESIA",
      contractType: selectedContractType,
    };

    try {
      if (action === "PREVIEW") {
        setPreviewing(true);
        // setLoadingPdf(true)
        setPreview(true);
        // console.log(formToSend);
        const { response } = await generateContract(id, formToSend);

        // console.log(response?.filledTemplate);
        setHtmlString(response?.filledTemplate);
        setPreviewing(false);
        setErrorSubmit("");
      } else {
        setSaving(true);
        // console.log(formToSend);
        await generateContract(id, formToSend);
        await requestApprovalBm(id, selectedBM);
        setSaving(false);
        setShowSuccess(true);
        setErrorSubmit("");
      }
    } catch (err) {
      const errorText = err?.response?.data?.error;
      setPreviewing(false);
      setSaving(false);
      setErrorSubmit(errorText || "Something went wrong");
      console.log(err, "error while submitting the form");
    }
  };

  const disabledButtonSelectedContractID = useMemo(() => {
    if (selectedContractId === null) {
      return true;
    } else {
      return false;
    }
  }, [selectedContractId]);

  const disabledButtonValues = useMemo(() => {
    if (values?.length === 0 || values === undefined) {
      return true;
    } else {
      for (let i = 0; i < values?.length; i++) {
        if (
          values[i].value === "" ||
          values[i].value === null ||
          values[i].value === undefined ||
          values[i].value === "Invalid Date"
        ) {
          return true;
        }
      }
      return false;
    }
  }, [values]);

  const disabledC2CAdditional = useMemo(() => {
    if (C2CData?.length === 0 || C2CData === undefined) {
      return false;
    } else {
      for (let i = 0; i < C2CData?.length; i++) {
        for (let j = 0; j < C2CData[i]?.params?.length; j++) {
          if (
            C2CData[i].params[j].value === "" ||
            C2CData[i].params[j].value === null ||
            C2CData[i].params[j].value === undefined ||
            C2CData[i].params[j].value === "Invalid Date"
          ) {
            return true;
          }
        }
      }
      return false;
    }
  }, [C2CData]);

  const disabledAllowances = useMemo(() => {
    if (allowances?.length === 0 || !allowances) {
      return false;
    } else {
      for (const elem of allowances) {
        if (
          !elem?.value ||
          elem?.value === "::" ||
          !elem?.value?.split("::")[0]
        ) {
          return true;
        }
      }
      return false;
    }
  }, [allowances]);

  const disablePrevious = useMemo(() => {
    if (currentStep === 1) {
      return true;
    }
    return false;
  }, [currentStep]);

  const disableNext = useMemo(() => {
    switch (currentStep) {
      case 1:
        if (!disabledButtonSelectedContractID) {
          return false;
        } else return true;
      case 2:
        if (!disabledButtonSelectedContractID && !disabledAllowances) {
          return false;
        } else return true;
      case 3:
        if (!disabledButtonSelectedContractID && !disabledButtonValues) {
          return false;
        } else return true;
      case 4:
        return true;
      default:
        return false;
    }
  }, [
    currentStep,
    disabledButtonSelectedContractID,
    disabledButtonValues,
    disabledAllowances,
  ]);

  return (
    <div className={Styles.contractContainer}>
      <div className={Styles.topHeaderContract}>
        <div className={Styles.header}>
          <div className={Styles.left}>
            {/* <img src={Images.AVA_DEFAULT} alt="" />
          <div>
            <span>{data?.name}</span>
            <span>{data?.phoneNumber} • {data?.recruiterID}</span>
          </div> */}
            <StackedProfile
              profilePicture={data?.profilePicture || Images.AVA_DEFAULT}
              title={toTitleCase(data?.name)}
              label={`${data?.phoneNumber} • ${data?.recruiterID}`}
              extraTitle={<CompletionIndicatorIcons times={1} />}
            />
          </div>
          <div className={Styles.right}>
            <button
              onClick={(e) => {
                handleSubmit(e, "PREVIEW");
              }}
              disabled={
                disabledButtonSelectedContractID === true ||
                disabledButtonValues === true ||
                disabledC2CAdditional === true ||
                disabledAllowances === true
              }
              className={`${Styles.button} ${
                disabledButtonSelectedContractID === true ||
                disabledButtonValues === true ||
                disabledC2CAdditional === true ||
                disabledAllowances === true
                  ? Styles.disabledButton
                  : ""
              }`}
            >
              <span className={Styles.hideOnMobile}>Preview Kontrak</span>
              {loadingPdf || previewing ? (
                <Spinner />
              ) : (
                <Icon icon={"eye-visible"} size={20} />
              )}
            </button>
            <button
              onClick={(e) => {
                handleSubmit(e, "SAVE");
              }}
              disabled={
                saving ||
                disabledButtonSelectedContractID === true ||
                disabledButtonValues === true ||
                disabledC2CAdditional === true ||
                disabledAllowances === true
              }
              className={`${Styles.buttonSave} ${
                disabledButtonSelectedContractID === true ||
                disabledButtonValues === true ||
                disabledC2CAdditional === true ||
                disabledAllowances === true ||
                saving
                  ? Styles.disabledButton
                  : ""
              }`}
            >
              <span className={Styles.hideOnMobile}>Minta Persetujuan BM</span>
              {saving ? <Spinner /> : <Icon icon={"send-solid"} size={20} />}
            </button>
          </div>
        </div>

        {errorSubmit ? (
          <div className={Styles.errorSubmit}>
            <span>*{errorSubmit}</span>
          </div>
        ) : null}
      </div>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <div className={Styles.nameClientBox}>
            <Icon icon={"building-office"} size={24} color={"#1571DE"} />
            <div className={Styles.nameClient}>
              <span className={Styles.titleClient}>Klien</span>
              <span className={Styles.nameText}>
                {data?.ExpectedPosition?.OrderScheme?.Client?.name || "-"}
              </span>
            </div>
          </div>
          <div className={Styles.top}>
            <h4>Detail Kontrak</h4>
            <div className={Styles.stepperWrapper}>
              <Stepper
                type="filled"
                onClick={handleClickStep}
                maxStep={maxStep}
                setMaxstep={setMaxStep}
                options={stepsOptions}
                currentStep={currentStep}
              />
            </div>

            <div className={Styles.navs}>
              <button disabled={disablePrevious} onClick={onClickPrevious}>
                Kembali
              </button>
              <button disabled={disableNext} onClick={onClickNext}>
                Lanjut
              </button>
            </div>
          </div>
          {currentStep === 4 && (
            <div className={Styles.searchAdditionalBox}>
              <div className={Styles.searchWrapper}>
                <OutlinedSearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholder={"Cari nama template kontrak"}
                />
              </div>
            </div>
          )}
          <div className={Styles.bottom}>
            <div>{content}</div>
          </div>
        </div>
        <div className={Styles.right}>
          {!pdfUrl ? (
            loadingPdf ? (
              <div className={Styles.loadingWrapper}>
                <LoadingAnimation />
              </div>
            ) : (
              <div className={Styles.contractWrapper}>
                <img src={Images.KONTRAK} alt="" />
                <h4>Kontrak Belum Ada</h4>
                <span>
                  Kontrak akan muncul ketika verifikasi kedua dilakukan
                </span>
              </div>
            )
          ) : (
            <div className={Styles.pdfWrapper}>
              <embed
                src={`${pdfUrl}#navpanes=0`}
                type="application/pdf"
              ></embed>
            </div>
          )}
        </div>
      </div>
      {width <= 768 && preview && (
        <div className={Styles.imagesContainer}>
          <div className={Styles.button}>
            <button onClick={() => setPreview(false)}>
              <Icon icon={"arrow-left"} size={24} />
            </button>
            <span>Preview Contract</span>
          </div>
          {loadingPdf || previewing ? (
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          ) : (
            images?.map((each, i) => <img src={each} key={i} alt="" />)
          )}
        </div>
      )}
      <div style={{ visibility: showSuccess ? "visible" : "hidden" }}>
        <ModalWrapper show={showSuccess} forceCenter>
          <SentContractModal
            onClickBack={() => setGenerateContractMode(false)}
          />
        </ModalWrapper>
      </div>
    </div>
  );
};
