import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@Molecule/Pagination";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Styles from "./styles.module.scss";
import { useMemo, useRef, useState } from "react";
import { fileBaseUrl } from "@Config/api";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import MenuAction from "@Molecule/MenuAction";

export default function ListTableRecruiterCandidate({
  data,
  isLoading,
  setIsLoadingData,
  selectedCandidate,
  setSelectedCandidate,
  setShowModalDetail,
  setShowModalTransfer,
  limit,
  setLimit,
  page,
  setPage,
  pages,
  setPages,
  pageList,
  setPageList,
  totalPage,
  type,
  setType,
  mode,
  setShowModalInfo,
  totalCandidates,
  setShowModalApproval,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const backupRef = useRef();
  const tableHead = useMemo(() => {
    if (mode === "public") {
      return [
        { id: "name", label: "Nama Kandidat", align: "left" },
        { id: "positionName", label: "Posisi terakhir dilamar", align: "left" },
        { id: "clientName", label: "Perusahaan", align: "left" },
        { id: "email", label: "Email", align: "left" },
        { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
        { id: "domicile", label: "Domisili", align: "left" },
        { id: "address", label: "Alamat", align: "left" },
        { id: "action", label: "Aksi", align: "center" },
      ];
    } else {
      return [
        { id: "name", label: "Nama Kandidat", align: "left" },
        { id: "positionName", label: "Posisi terakhir dilamar", align: "left" },
        { id: "clientName", label: "Perusahaan", align: "left" },
        { id: "email", label: "Email", align: "left" },
        { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
        { id: "domicile", label: "Domisili", align: "left" },
        { id: "address", label: "Alamat", align: "left" },
        { id: "action", label: "Alihkan Ke", align: "center" },
      ];
    }
  }, [mode]);
  const optionAction = useMemo(() => {
    let option = [];
    switch (mode) {
      case "private":
        option = [
          {
            title: "Job Posting Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToJobPosting");
            },
          },
          {
            title: "Public",
            action: () => {
              setShowModalApproval(true);
              setType("Public");
            },
          },
          {
            title: "Recruiter Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToAnotherRecruiter");
            },
          },
        ];
        break;
      case "unprocessed":
        option = [
          {
            title: "Job Posting Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToJobPosting");
            },
          },
          {
            title: "Public",
            action: () => {
              setShowModalApproval(true);
              setType("Public");
            },
          },
          {
            title: "Private",
            action: () => {
              setShowModalApproval(true);
              setType("Private");
            },
          },
          {
            title: "Recruiter Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToAnotherRecruiter");
            },
          },
        ];
        break;
      default:
        option = [
          {
            title: "Job Posting Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToJobPosting");
            },
          },
          {
            title: "Public",
            action: () => {
              setShowModalApproval(true);
              setType("Public");
            },
          },
          {
            title: "Recruiter Lain",
            action: () => {
              setShowModalTransfer(true);
              setType("TransferToAnotherRecruiter");
            },
          },
        ];
    }
    return option;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleMenu = (event, row) => {
    if (!Boolean(anchorEl)) {
      setAnchorEl(event.currentTarget);
      setSelectedCandidate(row);
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <div ref={backupRef} className={Styles.tableWrapper}>
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {tableHead?.map((eachH, iH) => (
                <TableCell
                  key={"headCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`
                                        ${Styles.tableHeadCell} 
                                        ${
                                          eachH?.id === "action"
                                            ? Styles.status
                                            : ""
                                        } 
                                        ${iH !== 0 ? Styles.hideOnMobile : ""}
                                    `}
                >
                  {eachH?.id === "name" ? (
                    <div className={Styles.stackedProfile}>
                      <span>No</span>
                      <span>{eachH?.label}</span>
                    </div>
                  ) : (
                    <span>{eachH?.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => (
              <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                {/* Avatar */}
                <TableCell
                  className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                >
                  <div className={Styles.showMobile}>
                    <button
                      className={Styles.stackedProfile}
                      onClick={() => {
                        setShowModalDetail(true);
                        setSelectedCandidate({
                          ...eachD,
                          candidateId: eachD?.id,
                        });
                      }}
                    >
                      <p>{iD + 1 + limit * (page - 1)}</p>

                      <div className={Styles.avatarWrapper}>
                        {eachD?.CandidateDocuments?.length > 0 &&
                        eachD?.CandidateDocuments?.find(
                          (el) =>
                            el?.type?.toLowerCase() === "foto selfie" ||
                            el?.type?.toLowerCase() === "self picture"
                        ) ? (
                          <Avatar
                            alt={eachD?.name}
                            src={
                              fileBaseUrl +
                              eachD?.CandidateDocuments?.find(
                                (el) =>
                                  el?.type?.toLowerCase() === "foto selfie" ||
                                  el?.type?.toLowerCase() === "self picture"
                              )?.url
                            }
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <div className={Styles.profile}>
                          <span>{eachD?.name}</span>
                          {mode === "public" ? (
                            <div className={Styles.positionName}>
                              {`${eachD?.ExpectedPosition?.name || "-"} • 
                                                            ${
                                                              eachD
                                                                ?.ExpectedPosition
                                                                ?.contractType ||
                                                              "-"
                                                            }`}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </TableCell>
                {/* Last Position */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>
                    {eachD?.lastPosition !== "null" &&
                    eachD?.lastPosition !== null
                      ? eachD?.lastPosition
                      : "-"}
                  </span>
                </TableCell>
                {/* Company */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>
                    {eachD?.ExpectedPosition?.OrderScheme?.Client?.name || "-"}
                  </span>
                </TableCell>
                {/* Email */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>{eachD?.email || "-"}</span>
                </TableCell>
                {/* Phone */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>{eachD?.phoneNumber || "-"}</span>
                </TableCell>
                {/* Domicile */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>{eachD?.domicile || "-"}</span>
                </TableCell>
                {/* Address */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                >
                  <span>{eachD?.address || "-"}</span>
                </TableCell>

                {/* Action */}
                <TableCell
                  className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                  align="center"
                >
                  {mode === "public" ? (
                    <div className={Styles.actionButton}>
                      <Button
                        title={"Daftarkan"}
                        type={"primary"}
                        disabled={true}
                        // onClick={() => {
                        //   setSelectedCandidate(eachD);
                        //   setShowModalTransfer(true);
                        //   setType("RegisterToJobPosting");
                        // }}
                      />
                    </div>
                  ) : (
                    <div
                      className={`${Styles.menuButton} ${Styles.expand} `}
                      //   onClick={(event) => handleMenu(event, eachD)}
                    >
                      <div className={Styles.iconMenu}>
                        <Icon icon={"three-dots"} size={20} color={"#E0E0E0"} />
                      </div>
                      <MenuAction
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        open={
                          Boolean(anchorEl) &&
                          selectedCandidate?.id === eachD?.id
                        }
                        onClose={() => {
                          setAnchorEl(null);
                        }}
                        options={optionAction}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!data?.length && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}
      <div className={Styles.paginationWrapper}>
        <Pagination
          total={totalCandidates}
          value={limit}
          setValue={setLimit}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={totalPage}
        />
      </div>
    </div>
  );
}
