import Styles from "./style.module.scss";

const ReinvitationConfirmationModal = ({ action, handleClose, ...props }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconWrapper}>
        <div className={Styles.title}>Kirim Ulang</div>
        <div className={Styles.text}>
          Apa kamu yakin untuk mengirim ulang offering ini?
        </div>
      </div>
      <div className={Styles.buttons}>
        <div className={Styles.secondaryButton} onClick={handleClose}>
          <div className={Styles.text}>Batal</div>
        </div>
        <div className={Styles.primaryButton} onClick={action}>
          <div className={Styles.text}>Ya, Kirim ulang</div>
        </div>
      </div>
    </div>
  );
};

export default ReinvitationConfirmationModal;
