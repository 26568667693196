import { useMemo } from 'react';
import Styles from './style.module.scss'

export default function ViewDetailModal(props) {
  const {
    data,
    equipments,
    personalEquipments,
    allowance,
    bpjs,
    totalCost,
    manfee,
    manfeeMode,
    manfeePercentage,
    getEachManfeeGetter,
    cleanAllowance,
    salary
  } = props;

  let IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  });

  const fixedManfee = useMemo(()=> {
    return manfeeMode === '%' ? Number(manfee?.split('%')[0]) * getEachManfeeGetter(salary, personalEquipments, bpjs, cleanAllowance) /100 : manfee
  }, [manfeeMode, manfee, bpjs, cleanAllowance, getEachManfeeGetter, salary, personalEquipments])
 

  const endTotal = useMemo(() => {
    return Number(totalCost) + Number(equipments) + Number(fixedManfee)
  }, [totalCost, equipments, fixedManfee])


  return (
    <div className={Styles.container}>
      <div className={Styles.topLine} />
      <div className={Styles.header}>
        <h3>{data?.title}</h3>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.stackedInfo}>
          <h4>Total</h4>
          <p>{data?.demand}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Salary</h4>
          <p>{IDR.format(data?.salary) || "no salary"}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Personal work equipment</h4>
          <p>{IDR.format(personalEquipments)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Allowance</h4>
          <p>{IDR.format(allowance || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>BPJS</h4>
          <p>{IDR.format(bpjs || 0)}</p>
        </div>
        <div className={Styles.stackedInfo}>
          <h4>Total Cost</h4>
          <p>{IDR.format(totalCost || 0)}</p>
        </div>

        <div className={Styles.summary}>
          <div className={Styles.stackedSummary}>
            <h4>Sub total</h4>
            <p>{IDR.format(totalCost || 0)}</p>
          </div>
          <div className={Styles.stackedSummary}>
            <h4>Area work equipment</h4>
            <p>{IDR.format(equipments || 0)}</p>
          </div>
          <div className={Styles.stackedSummary}>
            <h4>Management Fee {manfeeMode === '%' ? `(${manfeePercentage}%)` : '(for all order in this area)'}</h4>
            <p>{IDR.format(fixedManfee)}</p>
          </div>

          <div className={Styles.stackedClosure}>
            <h4>Total Biaya</h4>
            <p>{IDR.format(endTotal || 0)}</p>
          </div>
        </div>


      </div>
    </div>
  )
}