/* eslint-disable no-unused-vars */
import moment from "moment";
import Styles from "./style.module.scss";
import { useMemo } from "react";

export default function InformationDataSection({
  data,
  isHC,
  selectedLogs,
  updateLogs,
}) {
  const payroll = useMemo(() => {
    return data?.clientProject?.contacts?.find(
      (el) => el?.workUnit?.toLowerCase() === "payroll"
    );
  }, [data]);
  const invoice = useMemo(() => {
    return data?.clientProject?.contacts?.find(
      (el) => el?.workUnit?.toLowerCase() === "invoice"
    );
  }, [data]);

  const contractData = useMemo(() => {
    // return data?.orderDetail?.Client?.contracts[0]
    return data?.contract;
  }, [data?.contract]);

  // const contactRef = useNav("Tipe Kontrak");
  // const flowInvoiceRef = useNav("Flow Invoice");

  return (
    <div className={`${Styles.container}`}>
      <div className={Styles.Header} id="Tipe Kontrak">
        <h3>Information Data</h3>
      </div>
      <div className={Styles.content}>
        <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Periode Kerjasama</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.stacked}>
              <span>Tanggal mulai</span>
              <span>
                {contractData?.startDate
                  ? moment(new Date(contractData?.startDate)).format("LL")
                  : "-"}
              </span>
            </div>
            <div className={Styles.stacked}>
              <span>Tanggal berakhir</span>
              <span>
                {contractData?.endDate
                  ? moment(new Date(contractData?.endDate)).format("LL")
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark === "Perubahan Tipe Kontrak" &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Tipe Kontrak"
            ) &&
            Styles.borderYellow
          }`}
        >
          <div className={Styles.col}>
            <h4>Tipe Kontrak</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{data?.orderDetail?.contractType}</span>
          </div>
        </div>
        {/* <div className={Styles.row}>
          <div className={Styles.col}>
            <h4>Kontak Person Klien</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.colSection}>
              <h5>Payroll</h5>
              <div className={Styles.stacked}>
                <span>Nama</span>
                <span>{payroll?.name || "-"}</span>
              </div>
              <div className={Styles.stacked}>
                <span>Telp/HP</span>
                <span>
                  {payroll?.phoneNumber ? "+" + payroll?.phoneNumber : "-"}
                </span>
              </div>
              <div className={Styles.stacked}>
                <span>Email</span>
                <span>{payroll?.email || "-"}</span>
              </div>
            </div>
            <div className={Styles.colSection}>
              <h5>Invoice</h5>
              <div className={Styles.stacked}>
                <span>Nama</span>
                <span>{invoice?.name || "-"}</span>
              </div>
              <div className={Styles.stacked}>
                <span>Telp/HP</span>
                <span>
                  {invoice?.phoneNumber ? "+" + invoice?.phoneNumber : "-"}
                </span>
              </div>
              <div className={Styles.stacked}>
                <span>Email</span>
                <span>{invoice?.email || "-"}</span>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark === "Perubahan NPWP Klien" &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find((log) => log?.remark === "Perubahan NPWP Klien") &&
            Styles.borderYellow
          }`}
        >
          <div className={Styles.col}>
            <h4>NPWP Klien</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>{data?.clientProject?.taxNumber || "-"}</span>
          </div>
        </div>

        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark === "Perubahan Tipe Kontrak Kerja" &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Tipe Kontrak Kerja"
            ) &&
            Styles.borderYellow
          }`}
          id="Flow Invoice"
          // ref={flowInvoiceRef}
        >
          <div className={Styles.col}>
            <h4>Tipe Kontrak Kerja</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {data?.orderDetail?.employeementType || "-"}{" "}
              {data?.orderDetail?.employeementType?.toLowerCase() === "pkm"
                ? "(Perjanjian Kerja Mitra)"
                : data?.orderDetail?.employeementType?.toLowerCase() === "pkwt"
                ? "(Perjanjian Kerja Waktu Tertentu)"
                : null}
            </span>
          </div>
        </div>

        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark?.includes("Flow Invoice") &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.filter((log) => log?.remark?.includes("Flow Invoice"))
              ?.length > 0 &&
            Styles.borderYellow
          }`}
        >
          <div className={Styles.col}>
            <h4>Flow Invoice</h4>
          </div>
          <div
            className={`${Styles.col} ${Styles.miniPadding} ${Styles.goingDown}`}
          >
            <div className={Styles.Top}>
              <div className={Styles.colSection}>
                <div className={Styles.stacked}>
                  <span>UP di invoice</span>
                  <span>{data?.orderDetail?.orderInvoiceFlows?.UP || "-"}</span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal pengajuan soft copy invoice</span>
                  <span>
                    {data?.orderDetail?.orderInvoiceFlows
                      ?.TanggalPengajuanSoftCopyInvoice || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal mulai penggajian</span>
                  <span>
                    {data?.orderDetail?.orderInvoiceFlows
                      ?.TanggalMulaiPenggajian || "-"}
                  </span>
                </div>
              </div>
              <div className={Styles.colSection}>
                <div className={Styles.stacked}>
                  <span>Tanggal diterima invoice</span>
                  <span>
                    {data?.orderDetail?.orderInvoiceFlows
                      ?.TanggalDiterimaData || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal hard copy diterima</span>
                  <span>
                    {data?.orderDetail?.orderInvoiceFlows
                      ?.TanggalHardCopyInvoiceDiterima || "-"}
                  </span>
                </div>
                <div className={Styles.stacked}>
                  <span>Tanggal cut off absensi</span>
                  <span>
                    {data?.orderDetail?.orderInvoiceFlows
                      ?.TanggalCutOffAbsensi || "-"}
                  </span>
                </div>
              </div>
            </div>

            <div className={Styles.stacked}>
              <span>Dokumen pendukung invoice</span>
              <span>
                {data?.orderDetail?.orderInvoiceFlows
                  ?.DokumenPendukungInvoice &&
                data?.orderDetail?.orderInvoiceFlows
                  ?.DokumenPendukungInvoice !== "null"
                  ? data?.orderDetail?.orderInvoiceFlows
                      ?.DokumenPendukungInvoice
                  : "-"}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark?.includes("Rekening Penerima Invoice") &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.filter((log) =>
              log?.remark?.includes("Rekening Penerima Invoice")
            )?.length > 0 &&
            Styles.borderYellow
          }`}
          id="Other"
        >
          <div className={Styles.col}>
            <h4>Rekening Penerima Invoice</h4>
          </div>
          <div className={`${Styles.col} ${Styles.miniPadding}`}>
            <div className={Styles.colSection}>
              <div className={Styles.stacked}>
                <span>Perusahaan penerima</span>
                <span>{data?.orderDetail?.bankRecipient || "-"}</span>
              </div>
              <div className={Styles.col}>
                <div className={Styles.stacked}>
                  <span>Bank</span>
                  <span>{data?.orderDetail?.bankName || "-"}</span>
                </div>
                <div className={Styles.stacked}>
                  <span>Nomor rekening</span>
                  <span>{data?.orderDetail?.bankNumber || "-"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark === "Perubahan Absen Dipotong Bila" &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Absen Dipotong Bila"
            ) &&
            Styles.borderYellow
          }`}
        >
          <div className={Styles.col}>
            <h4>Absen Dipotong Bila</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {data?.orderDetail?.orderComprehensives?.KetentuanPotongAbsen ||
                "-"}
            </span>
          </div>
        </div>
        <div
          className={`${Styles.row} ${
            isHC &&
            selectedLogs?.remark === "Perubahan Keterangan Lainnya" &&
            Styles.isActive
          } ${
            isHC &&
            updateLogs?.length > 0 &&
            updateLogs?.find(
              (log) => log?.remark === "Perubahan Keterangan Lainnya"
            ) &&
            Styles.borderYellow
          }`}
        >
          <div className={Styles.col}>
            <h4>Keterangan Lainnya</h4>
          </div>
          <div className={`${Styles.col} ${Styles.normalPadding}`}>
            <span>
              {data?.orderDetail?.orderComprehensives?.KeteranganLainnya || "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
