import Icon from "@Atom/Icon";
import { useCallback, useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Styles from "./decorativeInput.module.scss";

const DinamicDecorativeInput = ({
  type,
  onChange,
  onDelete,
  adornment,
  name,
  value,
  index,
  locationIndex,
  preventDelete,
  isError,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value || "");

  const changeValue = useCallback(
    (targetValue) => {
      const re = /^[0-9\b]+$/;
      let fixedValue;
      if (type === "number") {
        if (targetValue === "" || re.test(targetValue)) {
          setCurrentValue(targetValue);
          fixedValue = targetValue;
        }
      }
      if (type === "string") {
        setCurrentValue(targetValue);
        fixedValue = targetValue;
      }
      if (type === "currency") {
        if (targetValue) {
          const firstParsing = targetValue.replace(/[^\d.]/gi, "");
          const parsedValue = firstParsing
            ?.replaceAll(",", "")
            ?.replaceAll(".", "");
          setCurrentValue(parsedValue);
          fixedValue = parsedValue;
        }
      }
      onChange(name, fixedValue, index, locationIndex);
    },
    [index, locationIndex, name, type, onChange]
  );

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={Styles.wrapper}>
      <div className={`${Styles.container} ${isError && Styles.error}`}>
        <div className={Styles.adornment}>{adornment}</div>
        {type !== "currency" ? (
          <input
            className={Styles.input}
            type="text"
            id="fname"
            name="fname"
            value={currentValue}
            onChange={(e) => changeValue(e?.target?.value)}
          />
        ) : (
          <CurrencyInput
            value={currentValue}
            onChange={(e) => changeValue(e?.target?.value)}
            className={`${Styles.input}`}
            allowDecimals={false}
            decimalSeparator=","
            groupSeparator="."
          />
        )}
      </div>
      {!preventDelete && (
        <button onClick={onDelete}>
          <Icon icon="trash" size={20} />
        </button>
      )}
    </div>
  );
};

export default DinamicDecorativeInput;
