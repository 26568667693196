import LoadingAnimation from "@Atom/LoadingAnimation";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import HumanCapitalContractListLayout from "@Organism/HumanCapital/Contracts/ContractList";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getContractList } from "@Services/hc/contract";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Styles from './styles.module.scss'
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";


export async function loader(route) {
  const url = new URL(route?.request?.url);
  const limit = url.searchParams.get("l") || 10;
  const page = url.searchParams.get("p") || 1;
  const search = url.searchParams.get("n") || "";
  const type = url.searchParams.get("t") || "CONTRACT";

  const packageContractListPromise = getContractList(limit, page, type, search)

  return defer({
    contractList: packageContractListPromise,
  });
}

export default function HumanCapitalContractList({ isUnderMaintenance = false }) {
  const { contractList } = useLoaderData()
  const query = useQuery();
  const defaultLimit = query.get("l") || 10;
  const defaultPage = query.get("p") || 1;
  const defaultType = query.get("t") || "CONTRACT";
  const defaultSearch = query.get("n") || "";

  const [limit, setLimit] = useState(defaultLimit);
  const [page, setPage] = useState(defaultPage);
  const [type, setType] = useState(defaultType);
  const [search, setSearch] = useState(defaultSearch);

  const navigate = useNavigate();

  const debouncedSearch = useDebounce(search, 300);

  const { pathname } = useLocation();


  useEffect(() => {
    navigate(`${pathname}?l=${limit}&p=${page}&t=${type}&n=${debouncedSearch}`);
  }, [debouncedSearch, limit, navigate, page, pathname, type]);

  if (isUnderMaintenance) {
    return <UnderMaintenacePage />
  }

  return (
    <div>
      <div className={Styles.container}>
        <Helmet>
          <title>Kerja 365 | Contracts</title>
        </Helmet>
        <div className={Styles.contentWrapper}>
          <Suspense
            fallback={
              <div className={Styles.loadingWrapper}>
                <LoadingAnimation />
              </div>
            }
          >
            <Await
              resolve={contractList}
              errorElement={
                <div style={{ height: "calc(100vh - 220px)" }}>
                  <AsyncErrorBoundary />
                </div>
              }
            >
              {(contractList) => (
                <HumanCapitalContractListLayout
                  data={contractList}
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  type={type}
                  setType={setType}
                  search={search}
                  setSearch={setSearch}
                  debouncedSearch={debouncedSearch}
                />
              )}

            </Await>

          </Suspense>

        </div>

      </div>

    </div>
  );
}
