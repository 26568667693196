import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { fileBaseUrl } from "@Config/api";
import useQuery from "@Hooks/useQuery";
import Pagination from "@Molecule/Pagination";
import InterviewResultModal from "@Molecule/_modal/InterviewResultModal";
import { getCandidates } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";

export default function ClientInterviewTable({
  searchTerm,
  tag,
  setSelectedCandidateDetail,
  statusQuery,
  // data: source,
  checkedArr,
  setCheckedArr,
  locationFilter,
  positionFilter,
  trigger,
  handleViewInfo = () => {},
  data = [],
  setData = () => {},
  withCheckbox = false,
  disableRowChekbox = (row) => {
    return false;
  },
  withActionColumn = false,
  actionColumnHead = () => <span>Action</span>,
  additionalParams = "",
  actionColumnRow = () => <>Action</>,
  actionColumnWidth,
  type,
  positionIDFilter,
}) {
  const [value, setValue] = useState(5);

  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedResult, setSelectedResult] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // const [data, setData] = useState([]);

  const allIds = useMemo(() => {
    const arr = [];

    for (let i = 0; i < data?.length; i++) {
      arr.push(data[i]?.id);
    }

    return arr;
  }, [data]);

  const isCheckedAll = useMemo(() => {
    if (!data?.length) {
      return false;
    }
    return allIds.every((el) => checkedArr.includes(el));
  }, [allIds, checkedArr, data?.length]);

  const isItemChecked = useCallback(
    (id) => {
      return checkedArr.includes(id);
    },
    [checkedArr]
  );

  const handleCheck = useCallback(
    (id) => {
      const index = checkedArr.indexOf(id);
      if (index === -1) {
        setCheckedArr([...checkedArr, id]);
      } else {
        const newArr = [...checkedArr];
        newArr.splice(index, 1);
        setCheckedArr(newArr);
      }
    },
    [checkedArr, setCheckedArr]
  );

  const handleCheckAll = useCallback(() => {
    const arr = [];
    const dataToCheck = data
      ?.map((obj) => {
        return {
          ...obj,
          exclude: disableRowChekbox(obj),
        };
      })
      ?.filter((obj) => !obj?.exclude);

    for (let i = 0; i < checkedArr.length; i++) {
      const index = dataToCheck.indexOf(checkedArr[i]);
      if (index === -1) {
        arr.push(checkedArr[i]);
      }
    }
    if (!isCheckedAll) {
      for (let i = 0; i < dataToCheck?.length; i++) {
        const index = checkedArr.indexOf(dataToCheck[i]?.id);
        if (index === -1) {
          arr.push(dataToCheck[i]?.id);
        }
      }
    } else {
      for (let i = 0; i < dataToCheck?.length; i++) {
        const index = arr.indexOf(dataToCheck[i]?.id);
        arr.splice(index, 1);
      }
    }
    setCheckedArr(arr);
  }, [data, isCheckedAll, setCheckedArr, disableRowChekbox, checkedArr]);

  const handleOpenInterviewInvitation = (link) => {
    window.open(link);
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [searchTerm]);

  const locationQuery = useMemo(() => {
    return locationFilter?.map((value) => `&location=${value}`).join("");
  }, [locationFilter]);
  const positionQuery = useMemo(() => {
    return positionIDFilter?.map((value) => `&positionID=${value}`).join("");
  }, [positionIDFilter]);

  const query = useQuery();
  const code = query.get("c");

  const [name, setName] = useState(searchTerm);

  const onChange = useCallback(() => {
    setName(searchTerm);
  }, [searchTerm]);

  const debounceChange = useMemo(() => debounce(onChange, 500), [onChange]);

  useEffect(() => {
    debounceChange();
  }, [debounceChange]);

  useEffect(() => {
    const queryParams = `limit=${value}&page=${selectedPage}${locationQuery}${positionQuery}${statusQuery}&status=${tag}&search=${name}${
      additionalParams ? `&${additionalParams}` : ""
    }`;
    const fetchData = async () => {
      try {
        setData([]);
        setIsLoading(true);
        const { response } = await getCandidates(code, queryParams);
        setData(
          response?.data?.map((obj) => {
            const imgUrl = obj?.CandidateDocuments?.find(
              (obj) => obj?.type?.toLowerCase() === "self picture"
            )?.url;
            return {
              ...obj,
              profilePicture: imgUrl
                ? fileBaseUrl + imgUrl
                : Images.AVA_DEFAULT,
            };
          })
        );
        setIsLoading(false);
        setTotalPage(response?.totalPage);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [
    locationQuery,
    code,
    positionQuery,
    name,
    value,
    selectedPage,
    tag,
    statusQuery,
    trigger,
    additionalParams,
    setData,
  ]);

  const TABLE_HEAD = useMemo(() => {
    if (type === "client-invite") {
      return [
        {
          id: "name",
          label: "Nama Kandidat",
          align: "left",
          render: (row) => <span>{row?.name?.toUpperCase()}</span>,
        },
        {
          id: "positionName",
          label: "Posisi",
          align: "left",
          render: (row) => (
            <span>{row?.ExpectedPosition?.name?.toUpperCase()}</span>
          ),
        },
        {
          id: "portal",
          label: "Sumber",
          align: "left",
          render: (row) => <span>{row?.portal?.toUpperCase()}</span>,
        },
        {
          id: "interviewDate",
          label: "Waktu Interview",
          align: "left",
          render: (row) => (
            <span>
              {row?.CandidateInvitations?.date &&
              +row?.CandidateInvitations?.ordinal === 2
                ? moment(new Date(row?.CandidateInvitations?.date)).format(
                    "Do MMMM YYYY"
                  )
                : "-"}
              {`, `}
              {+row?.CandidateInvitations?.ordinal === 2
                ? row?.CandidateInvitations?.time || "-"
                : "-"}
            </span>
          ),
        },
        {
          id: "interviewer",
          label: "Interviewer",
          align: "left",
          render: (row) => (
            <span>
              {`${
                row?.CandidateInvitations?.interviewerTitle?.toUpperCase() ||
                "-"
              } ${
                row?.CandidateInvitations?.interviewerName?.toUpperCase() || ""
              }`}
            </span>
          ),
        },
        { id: "email", label: "Email", align: "left" },
        { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
        {
          id: "domicile",
          label: "Domisili",
          align: "left",
          render: (row) => <span>{row?.domicile?.toUpperCase()}</span>,
        },
        {
          id: "address",
          label: "Alamat",
          align: "left",
          render: (row) => <span>{row?.address?.toUpperCase()}</span>,
        },
        {
          id: "lastEducation",
          label: "Pendidikan",
          align: "left",
          render: (row) => <span>{row?.lastEducation?.toUpperCase()}</span>,
        },
        {
          id: "roEvaluation",
          label: "Hasil Interview RO",
          render: (row) => (
            <button
              className={Styles.checkButton}
              onClick={() => setSelectedResult(row)}
            >
              Cek Detail
            </button>
          ),
        },
        {
          id: "interviewInvitation",
          label: "Undangan Interview",
          render: (row) =>
            row?.CandidateInvitations?.ordinal === "2" && row?.interviewLink ? (
              <button
                className={`${Styles.checkButton} ${
                  row?.status?.toLowerCase().includes("hold") &&
                  Styles.disabledButton
                }`}
                onClick={() => {
                  if (!row?.status?.toLowerCase().includes("hold")) {
                    handleOpenInterviewInvitation(row.interviewLink);
                  }
                }}
              >
                Cek Detail
              </button>
            ) : (
              <span className={Styles.unavailableText}>Belum Tersedia</span>
            ),
        },
      ];
    }
    if (type === "client-reject") {
      return [
        {
          id: "name",
          label: "Nama Kandidat",
          align: "left",
          render: (row) => <span>{row?.name?.toUpperCase()}</span>,
        },
        {
          id: "positionName",
          label: "Posisi",
          align: "left",
          render: (row) => (
            <span>{row?.ExpectedPosition?.name?.toUpperCase()}</span>
          ),
        },
        {
          id: "portal",
          label: "Sumber",
          align: "left",
          render: (row) => <span>{row?.portal?.toUpperCase()}</span>,
        },
        {
          id: "interviewDate",
          label: "Waktu Interview",
          align: "left",
          render: (row) => (
            <span>
              {row?.CandidateInvitations?.date
                ? moment(new Date(row?.CandidateInvitations?.date)).format(
                    "Do MMMM YYYY"
                  )
                : "-"}
              {`, `}
              {row?.CandidateInvitations?.time || "-"}
            </span>
          ),
        },
        {
          id: "interviewer",
          label: "Interviewer",
          align: "left",
          render: (row) => (
            <span>
              {`${
                row?.CandidateInvitations?.interviewerTitle?.toUpperCase() ||
                "-"
              } ${
                row?.CandidateInvitations?.interviewerName?.toUpperCase() || ""
              }`}
            </span>
          ),
        },
        // { id: "location", label: "Lokasi", align: "left" },
        { id: "email", label: "Email", align: "left" },
        { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
        {
          id: "domicile",
          label: "Domisili",
          align: "left",
          render: (row) => <span>{row?.domicile?.toUpperCase()}</span>,
        },
        {
          id: "address",
          label: "Alamat",
          align: "left",
          render: (row) => <span>{row?.address?.toUpperCase()}</span>,
        },
        {
          id: "lastEducation",
          label: "Pendidikan",
          align: "left",
          render: (row) => <span>{row?.lastEducation?.toUpperCase()}</span>,
        },
        // { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
        {
          id: "remark",
          label: "Catatan Undangan",
          align: "left",
          render: (row) => (
            <span>{row?.cancellationNote?.toUpperCase() || "-"}</span>
          ),
        },
        {
          id: "roEvaluation",
          label: "Hasil Interview RO",
          render: (row) => (
            <button
              className={Styles.checkButton}
              onClick={() => setSelectedResult(row)}
            >
              Cek Detail
            </button>
          ),
        },
        {
          id: "interviewInvitation",
          label: "Undangan Interview",
          render: (row) =>
            row?.CandidateInvitations?.ordinal === "2" && row?.interviewLink ? (
              <button
                className={Styles.checkButton}
                onClick={() => handleOpenInterviewInvitation(row.interviewLink)}
              >
                Cek Detail
              </button>
            ) : (
              <span className={Styles.unavailableText}>Belum Tersedia</span>
            ),
        },
      ];
    }
    return [
      { id: "name", label: "Nama Kandidat", align: "left" },
      { id: "position", label: "Posisi", align: "left" },
      { id: "sourcing", label: "Sumber", align: "left" },
      { id: "location", label: "Lokasi", align: "left" },
      { id: "email", label: "Email", align: "left" },
      { id: "phoneNumber", label: "Nomor Handphone", align: "left" },
      { id: "domicile", label: "Domisili", align: "left" },
      { id: "address", label: "Alamat", align: "left" },
      { id: "education", label: "Pendidikan", align: "left" },
      { id: "lastExperience", label: "Pengalaman Terakhir", align: "left" },
    ];
  }, [type]);

  const tableBody = useMemo(() => {
    switch (type) {
      case "client-invite":
        return (
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              const isChecked = isItemChecked(eachD?.id);
              const isCheckboxDisabled = disableRowChekbox(eachD);
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {withCheckbox && (
                        // <Checkbox
                        //   disabled={isCheckboxDisabled}
                        //   onClick={() => handleCheck(eachD?.id)}
                        //   checked={isChecked || false}
                        //   sx={{ margin: 0, padding: 0 }}
                        //   icon={<Icon icon="unchecked-checkbox" size={23} />}
                        //   checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                        // />
                        <CheckboxJobPosting
                          disabled={isCheckboxDisabled}
                          checked={isChecked || false}
                          onChange={() => handleCheck(eachD?.id)}
                        />
                      )}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  {TABLE_HEAD?.slice(1, TABLE_HEAD?.length)?.map(
                    (eachH, iH) => (
                      <TableCell
                        key={iH}
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        {eachH?.render
                          ? eachH?.render(eachD)
                          : eachD[eachH?.id]}
                      </TableCell>
                    )
                  )}
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                  >
                    {actionColumnRow(eachD)}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        );
      case "client-reject":
        return (
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              const isChecked = isItemChecked(eachD?.id);
              const isCheckboxDisabled = disableRowChekbox(eachD);
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {withCheckbox && (
                        // <Checkbox
                        //   disabled={isCheckboxDisabled}
                        //   onClick={() => handleCheck(eachD?.id)}
                        //   checked={isChecked || false}
                        //   sx={{ margin: 0, padding: 0 }}
                        //   icon={<Icon icon="unchecked-checkbox" size={23} />}
                        //   checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                        // />
                        <CheckboxJobPosting
                          disabled={isCheckboxDisabled}
                          checked={isChecked || false}
                          onChange={() => handleCheck(eachD?.id)}
                        />
                      )}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  {TABLE_HEAD?.slice(1, TABLE_HEAD?.length)?.map(
                    (eachH, iH) => (
                      <TableCell
                        key={iH}
                        className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                      >
                        {eachH?.render
                          ? eachH?.render(eachD)
                          : eachD[eachH?.id]}
                      </TableCell>
                    )
                  )}
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                  >
                    {actionColumnRow(eachD)}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        );
      default:
        return (
          <TableBody className={Styles.tableBody}>
            {data?.map((eachD, iD) => {
              const isChecked = isItemChecked(eachD?.id);
              const isCheckboxDisabled = disableRowChekbox(eachD);
              return (
                <TableRow key={`tableRow` + iD} className={Styles.tableRow}>
                  <TableCell
                    className={`${Styles.stickyLeft} ${Styles.tableCell}`}
                  >
                    <div>
                      {withCheckbox && (
                        // <Checkbox
                        //   disabled={isCheckboxDisabled}
                        //   onClick={() => handleCheck(eachD?.id)}
                        //   checked={isChecked || false}
                        //   sx={{ margin: 0, padding: 0 }}
                        //   icon={<Icon icon="unchecked-checkbox" size={23} />}
                        //   checkedIcon={<Icon icon="checked-checkbox" size={23} />}
                        // />
                        <CheckboxJobPosting
                          disabled={isCheckboxDisabled}
                          checked={isChecked || false}
                          onChange={() => handleCheck(eachD?.id)}
                        />
                      )}
                      <button
                        className={Styles.stackedProfile}
                        onClick={() => handleViewInfo(eachD)}
                      >
                        {eachD?.profilePicture ? (
                          <Avatar
                            alt={eachD?.name}
                            src={eachD?.profilePicture}
                            sx={{ width: 36, height: 36 }}
                          />
                        ) : (
                          <Avatar sx={{ width: 36, height: 36 }}>
                            {eachD?.name[0]}
                          </Avatar>
                        )}
                        <span>{eachD?.name?.toUpperCase()}</span>
                      </button>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <div>
                      <span>{eachD?.portal}</span>
                      {eachD?.portal === "365 P" && (
                        <Icon
                          icon="chat"
                          size={20}
                          className={Styles.chatIcon}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.name}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.ExpectedPosition?.City?.name}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.email ? eachD?.email : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.phoneNumber ? eachD?.phoneNumber : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.domicile ? eachD?.domicile : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>{eachD?.address ? eachD?.address : "-"}</span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastEducation ? eachD?.lastEducation : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile}`}
                  >
                    <span>
                      {eachD?.lastPosition ? eachD?.lastPosition : "-"}
                    </span>
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                  >
                    {actionColumnRow(eachD)}
                  </TableCell>
                  <TableCell
                    className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                  >
                    <button onClick={() => setSelectedCandidateDetail(eachD)}>
                      Detail
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        );
    }
  }, [
    TABLE_HEAD,
    actionColumnRow,
    data,
    disableRowChekbox,
    handleCheck,
    handleViewInfo,
    isItemChecked,
    setSelectedCandidateDetail,
    type,
    withCheckbox,
  ]);

  return (
    <div className={Styles.tableWrapper}>
      <TableContainer
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        }`}
      >
        <Table className={Styles.table}>
          <TableHead className={Styles.tableHead}>
            <TableRow className={Styles.tableHeadRow}>
              {TABLE_HEAD?.map((eachH, iH) => (
                <TableCell
                  key={"headCell" + iH}
                  align={eachH?.align}
                  padding="none"
                  className={`${Styles.tableHeadCell} ${
                    iH !== 0 ? Styles.hideOnMobile : ""
                  }`}
                >
                  <div>
                    {eachH?.id === "name" && withCheckbox && (
                      <CheckboxJobPosting
                        onChange={handleCheckAll}
                        checked={isCheckedAll}
                      />
                      // <Checkbox

                      //   onClick={handleCheckAll}
                      //   sx={{ margin: 0, padding: 0 }}
                      //   checked={isCheckedAll}
                      //   icon={<Icon icon="unchecked-all-checkbox" size={23} />}
                      //   checkedIcon={
                      //     <Icon icon="checked-all-checkbox" size={23} />
                      //   }
                      // />
                    )}
                    <span>{eachH?.label}</span>
                  </div>
                </TableCell>
              ))}
              {withActionColumn && (
                <TableCell
                  className={`${Styles.tableHeadCell} ${Styles.hideOnMobile} ${Styles.stickyRight}`}
                >
                  <div
                    className={Styles.customWidth}
                    style={{ width: actionColumnWidth, textAlign: "center" }}
                  >
                    {actionColumnHead()}
                  </div>
                </TableCell>
              )}

              <TableCell className={` ${Styles.showOnMobile}`}>
                <div />
              </TableCell>
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
      {!data?.length && (
        <div className={Styles.emptyWrapper}>
          <TableEmptyHandler
            title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
            desc={
              isLoading
                ? "Data sedang dimuat"
                : "Tidak ada data atau data belum ada"
            }
          />
        </div>
      )}
      <div className={Styles.paginationContainer}>
        <Pagination
          total={data?.length}
          totalPage={totalPage}
          value={value}
          setValue={setValue}
          pages={pages}
          setPages={setPages}
          pageList={pageList}
          setPageList={setPageList}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
      <ModalWrapper
        show={selectedResult}
        handleClose={() => setSelectedResult(null)}
      >
        {selectedResult && (
          <InterviewResultModal
            data={selectedResult}
            onClose={() => setSelectedResult(null)}
          />
        )}
      </ModalWrapper>
    </div>
  );
}
