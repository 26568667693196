import React, { useState } from "react";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Styles from "./style.module.scss";
import Pagination from "@Molecule/Pagination";
import { Link } from "react-router-dom";
import SortSelect from "@Atom/SortSelect";
import { visuallyHidden } from "@mui/utils";

const TABLE_HEAD = [
  { id: "name", label: "Nama Klien", alignRight: false },
  { id: "positions", label: "Positions", alignRight: false },
  {
    id: "request",
    numeric: true,
    label: "Request",
    alignRight: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align={headCell.id === "totalManPower" ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0 ? `${Styles.firstHeadCell}` : `${Styles.hideOnMobile}`
            }
            sx={{
              // minWidth: index === 0 ? firstColMinWidth : "",
              backgroundColor: "#fff",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {/* <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <span>Information</span>
        </TableCell> */}
      </TableRow>
    </TableHead>
  );
}


export default function RecruitmentAreaDetailList({
  search,
  setSearch,
  value,
  setValue,
  pages,
  setPages,
  pageList,
  setPageList,
  selectedPage,
  setSelectedPage,
  data,
  sort,
  setSort,
  sortOption,
}) {

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.inputWrapper}>
        <div className={Styles.searchWrapper}>
          <SearchBar
            placeholder={"Search Company"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className={Styles.sortWrapper}>
          <SortSelect value={sort} setValue={setSort} options={sortOption} />
        </div>
      </div>
      <div className={Styles.tableContainer}>
        <div className={Styles.table}>
          <TableContainer>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                values={data?.data}
              />
              <TableBody>
                {stableSort(data?.data, getComparator(order, orderBy)).map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className={Styles.clientName}>
                        <div>
                          <Icon icon="building" size={20} />
                        </div>
                        <Link
                          to={`/rekrutmen/details?n=${row.name}&c=${row.code}`}
                        >
                          {row.name}
                        </Link>
                      </div>
                    </TableCell>

                    <TableCell>
                      <p className={Styles.cities}>
                        {row?.positions?.map((el, idx) => el.name).join(", ")}
                      </p>
                    </TableCell>
                    <TableCell>{row.request || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={Styles.paginationWrapper}>
          <Pagination
            total={data.totalPage * value}
            totalPage={data.totalPage}
            value={value}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
        </div>
      </div>
    </div>
  );
}
