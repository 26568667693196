import Step1 from "./Step1";
import Step2 from "./Step2";

export default function TableCandidateToRecruiter({
    selectedPosition, setSelectedPosition, selectedRecruiter, setSelectedRecruiter 
}){

    return (
        <>
            {!selectedRecruiter ? 
                <Step1
                    selectedRecruiter={selectedRecruiter}
                    setSelectedRecruiter={setSelectedRecruiter}
                /> :
                <Step2
                    selectedPosition={selectedPosition}
                    setSelectedPosition={setSelectedPosition}
                    selectedRecruiter={selectedRecruiter}
                    setSelectedRecruiter={setSelectedRecruiter}
                />
            }
        </>
    )
}