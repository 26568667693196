/* eslint-disable no-unused-vars */
import StackedProfile from "@Atom/StackedProfile";
import { fileBaseUrl } from "@Config/api";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import ContractTableHeader from "@Molecule/ContractTableHeader";
import CustomTable from "@Molecule/CustomTable";
import MenuListPopup from "@Molecule/MenuListPopup";
import { fetchExpiringContracts, fetchExpiringContractsToContinue } from "@Services/support/contract";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function ContractListLayout() {
  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [data, setData] = useState(null);
  const [expiringData, setExpiringData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandAction, setExpandAction] = useState(null);


  const [activeType, setActiveType] = useState(query?.get('a') || '1')

  const inputRefHeader = useRef(null);
  const inputRefContent = useRef(null);

  useEffect(() => {
    setSearchTerm('')
    setSelectedPage(1)
  }, [activeType])


  const types = [
    {
      label: 'Kontrak Akan Berakhir',
      value: '1'
    },
    {
      label: 'Perpanjang Kontrak',
      value: '2'
    }
  ]

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const fetchData = useCallback(async () => {
    if (query?.get("s")) {
      try {
        setIsLoading(true)
        const { response } = await fetchExpiringContractsToContinue(
          selectedPage,
          limit,
          debouncedSearchTerm
        );
        setData(response);
        setIsLoading(false)

      } catch (err) {
        setIsLoading(false)
        console.log(err, "err");
      }
    } else {
      setData(null)
    }
  }, [debouncedSearchTerm, limit, query, selectedPage]);


  const fetchExpiringData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { response } = await fetchExpiringContracts(
        selectedPage,
        limit,
        debouncedSearchTerm
      );
      setExpiringData(response);
      setIsLoading(false)
    } catch (err) {
      console.log(err, "err");
      setIsLoading(false)
    }
  }, [debouncedSearchTerm, limit, selectedPage]);

  useEffect(() => {
    if (activeType === '1') {
      fetchExpiringData()
    } else {

      fetchData();
    }
  }, [activeType, fetchData, fetchExpiringData]);

  useEffect(() => {
    setSelectedPage(1)
  }, [debouncedSearchTerm])

  useEffect(() => {
    navigate(
      `${pathname}?p=${selectedPage}&l=${limit}&s=${debouncedSearchTerm}&a=${activeType}`
    );
  }, [activeType, debouncedSearchTerm, limit, navigate, pathname, selectedPage]);

  useEffect(() => {
    if (activeType !== 1 && query?.get('s')){
      if (inputRefHeader.current){
        inputRefHeader.current.focus();
      }
      if (inputRefContent.current){
        inputRefContent.current.focus();
      }
    }
  }, [query, activeType]);

  const template = useMemo(() => {
    return {
      data: data?.data?.length
        ? data?.data?.map((obj) => {
          return {
            ...obj,
            id: obj?.employeeId,
            name: obj?.name,
            employeeId: obj?.employeeId,
            profilePicture: obj?.profilePicture
              ? fileBaseUrl + obj?.profilePicture
              : "",
            clientName: obj?.client,
            position: obj?.position,
            contractType: obj?.contract_type,
            startDate: obj?.start_date
              ? obj?.start_date?.replaceAll("/", "-")
              : "",
            endDate: obj?.end_date ? obj?.end_date?.replaceAll("/", "-") : "",
            status: new Date(obj?.end_date) ? 'ACTIVE' : 'INACTIVE',
          };
        })
        : [],
      expiringData: expiringData?.data?.length
        ? expiringData?.data?.map((obj) => {
          return {
            ...obj,
            id: obj?.nrk,
            name: obj?.name,
            employeeId: obj?.employeeId,
            profilePicture: obj?.profilePicture
              ? fileBaseUrl + obj?.profilePicture
              : "",
            clientName: obj?.client,
            position: obj?.position,
            contractType: obj?.contract_type,
            startDate: obj?.start_date
              ? obj?.start_date?.replaceAll("/", "-")
              : "",
            endDate: obj?.end_date ? obj?.end_date?.replaceAll("/", "-") : "",
            status: new Date(obj?.end_date) ? 'ACTIVE' : 'INACTIVE',
            // status:
            //   obj?.status?.toLowerCase() === "aktif" ? "ACTIVE" : "INACTIVE",
          };
        })
        : [],
      columns: activeType === '1'
        ?
        [
          {
            name: "name",
            label: "Nama",
            renderData: (row) => (
              <StackedProfile
                profilePicture={row?.profilePicture}
                title={row?.name}
              // label={row?.employeeId}
              />
            ),
          },
          {
            name: "employeeId",
            label: "NRK",
          },
          {
            name: "clientName",
            label: "Klien",
          },
          {
            name: "position",
            label: "Posisi",
          },
          {
            name: "contractType",
            label: "Tipe Kontrak",
          },
          {
            name: "startDate",
            label: "Tanggal Kontrak",
            renderData: (row) => (
              <div className={Styles.dates}>
                <div>
                  <span>Start</span>
                  <span> : {moment(row?.startDate)?.format('DD-MM-YYYY')}</span>
                </div>
                <div>
                  <span>End</span>
                  <span> : {moment(row?.endDate)?.format('DD-MM-YYYY')}</span>
                </div>
              </div>
            ),
          },
          {
            name: "status",
            label: "Status",
            renderData: (row) => (
              <div
                className={`${Styles.status} ${row?.status === "ACTIVE" ? Styles.active : ""
                  }`}
              >
                {row?.status === "ACTIVE" ? "Active" : "Not Active"}
              </div>
            ),
          },
        ]
        :
        [
          {
            name: "name",
            label: "Nama",
            renderData: (row) => (
              <StackedProfile
                profilePicture={row?.profilePicture}
                title={row?.name}
                label={row?.employeeId}
              />
            ),
          },
          {
            name: "clientName",
            label: "Klien",
          },
          {
            name: "position",
            label: "Posisi",
          },
          {
            name: "contractType",
            label: "Tipe Kontrak",
          },
          {
            name: "startDate",
            label: "Tanggal",
            renderData: (row) => (
              <div className={Styles.dates}>
                <div>
                  <span>Start</span>
                  <span> : {moment(row?.startDate)?.format('DD-MM-YYYY')}</span>
                </div>
                <div>
                  <span>End</span>
                  <span> : {moment(row?.endDate)?.format('DD-MM-YYYY')}</span>
                </div>
              </div>

            ),
          },
          {
            name: "status",
            label: "Status",
            renderData: (row) => (
              <div
                className={`${Styles.status} ${row?.status === "ACTIVE" ? Styles.active : ""
                  }`}
              >
                {row?.status === "ACTIVE" ? "Active" : "Not Active"}
              </div>
            ),
          },
          {
            name: "action",
            label: "Aksi",
            renderData: (row) => (
              <div className={Styles.actionsWrapper}>
                <div
                  className={`${Styles.button} ${Styles.expand}`}
                  onClick={() => setExpandAction(row?.id)}
                >
                  <img src={Images.ELLIPSIS_HORIZONTAL_CIRCLE} alt="" />
                  {expandAction === row?.id && (
                    <div className={Styles.options}>
                      <MenuListPopup
                        withIcon
                        isMultipleSection
                        options={[
                          {
                            title: "Action",
                            options: [
                              {
                                title: "Perpanjang Kontrak Kerja",
                                icon: "extend-contract",
                                iconColor: "#404040",
                                action: () => { 
                                  window.open(`${pathname}/${row?.employeeId}`);
                                },
                                // disabled: true,
                              },
                            ],
                          },
                        ]}
                        onClose={() => setExpandAction(null)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ),
          },
        ]

    };
  }, [activeType, data?.data, expandAction, expiringData?.data]);

  console.log(template?.expiringData, 'ed')


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <ContractTableHeader
          title={"Total Kandidat"}
          amount={activeType === '1' ? expiringData?.total : data?.total}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          types={types}
          activeType={activeType}
          setActiveType={setActiveType}
          inputRef={inputRefHeader}
        />
      </div>
      <div className={Styles.content}>
        <CustomTable
          data={activeType === '1' ? template?.expiringData : template?.data}
          isLoading={isLoading}
          columns={template?.columns}
          value={limit}
          setValue={setLimit}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          totalPage={activeType === '1' ? expiringData?.totalPage : data?.totalPage}
          totalData={activeType === '1' ? expiringData?.total : data?.total}
          isV2
          noContainerBorder
          isContractList={activeType === '2'}
          search={searchTerm}
          setSearch={setSearchTerm}
          stickyTop
          searchQuery={query?.get("s") || ""}
          inputRef={inputRefContent}
        />
      </div>
    </div>
  );
}
