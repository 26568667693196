import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import CodeCard from "./card";
import { useState, useRef } from "react";
import BULLET_LIST from "../../../../../assets/Images/bullet-list.png";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import { createTemplateParameter } from "@Services/hc/contract";
import { useLocation, useNavigate } from "react-router-dom";

const ParameterListModal = ({ setShow = () => {}, parameters, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentState = location.state;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const ref = useRef(null);
  const [tab, setTab] = useState(1);
  const [mode, setMode] = useState(0);

  const [addParameter, setAddParameter] = useState([
    {
      code: "",
      label: "",
    },
  ]);

  const list1 = parameters?.filter((item) => {
    return item.title === "Pihak Pertama";
  });

  const list2 = parameters?.filter((item) => {
    return item.title === "Pihak Kedua/TKO";
  });

  const list3 = parameters?.filter((item) => {
    return item.title === "Informasi BM";
  });

  const list4 = parameters?.filter((item) => {
    return item.title === "Informasi Tanggal & Kontrak";
  });

  const list5 = parameters?.filter((item) => {
    return item.title === "Lainnya";
  });

  const listAdditional = parameters?.filter((item) => {
    return item.type === "Parameter Tambahan";
  });

  const scrollEvent = (e) => {
    setScrollHeight(
      Math.floor(ref.current.scrollHeight - ref.current.clientHeight)
    );
    setScrollPosition(Math.round(e.target.scrollTop));
  };

  const handleChangeInputParam = (key, value, id) => {
    setAddParameter((prev) => [
      ...prev.map((el, idx) => (idx === id ? { ...el, [key]: [value] } : el)),
    ]);
  };

  const handleDeleteParam = (id) => {
    setAddParameter((prev) => prev.filter((el, idx) => idx !== id));
  };
  const handleAddParam = () => {
    setAddParameter((prev) => [...prev, { code: "", label: "" }]);
  };

  const handleCreateParameter = async () => {
    try {
      await createTemplateParameter(addParameter);
      setMode(0);
      setAddParameter([
        {
          code: "",
          label: "",
        },
      ]);
      navigate(".", { state: { ...currentState, refresh: true } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <div className={Styles.container}>
        <div className={Styles.headerWrapper}>
          <div className={Styles.header}>
            <div className={Styles.titleWrapper}>
              <div className={Styles.titleIconWrapper}>
                <img src={BULLET_LIST} alt="" />
              </div>
              <div className={Styles.title}>Parameter List</div>
            </div>
            <button
              onClick={() => setShow(false)}
              className={Styles.iconWrapper}
            >
              <Icon size="24" icon="cross" className={Styles.icon} />
            </button>
          </div>
        </div>
        <div
          className={Styles.body}
          onScroll={scrollEvent}
          ref={ref}
          style={{
            borderTop: scrollPosition > 0 ? "1px solid #ededed" : null,
          }}
        >
          <div className={Styles.tabHead}>
            <div className={Styles.tabWrapper}>
              <div
                className={`${Styles.tabSelector} ${
                  tab === 1 ? Styles.activeTab : ""
                }`}
                onClick={() => setTab(1)}
              >
                <p>Parameter Utama</p>
              </div>
              <div
                className={`${Styles.tabSelector} ${
                  tab === 2 ? Styles.activeTab : ""
                }`}
                onClick={() => setTab(2)}
              >
                <p>Parameter Tambahan</p>
              </div>
            </div>
          </div>
          {tab === 1 && (
            <div className={Styles.contentWrapper}>
              <div className={Styles.content}>
                <div className={Styles.title}>Pihak Pertama</div>
                <div className={Styles.cards}>
                  {list1?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Pihak Kedua/TKO</div>
                <div className={Styles.cards}>
                  {list2?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Informasi BM</div>
                <div className={Styles.cards}>
                  {list3?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Informasi Tanggal & Kontrak</div>
                <div className={Styles.cards}>
                  {list4?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Lainnya</div>
                <div className={Styles.cards}>
                  {list5?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {tab === 2 && mode === 0 && (
            <div className={Styles.contentWrapper}>
              <div className={Styles.addParamWrapper}>
                <p>Tambah Parameter</p>
                <Icon
                  icon={"add-circle"}
                  size={24}
                  onClick={() => setMode(1)}
                />
              </div>
              <div className={Styles.content}>
                <div className={Styles.title}>Nama Parameter</div>
                <div className={Styles.cards}>
                  {listAdditional?.map((item, id) => (
                    <CodeCard text={item?.text} value={item?.value} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {tab === 2 && mode === 1 && (
            <div className={Styles.contentWrapper}>
              <div className={Styles.addParamWrapper}>
                <p>Tambah Parameter</p>
                <Icon icon={"add-circle"} size={24} onClick={handleAddParam} />
              </div>
              <div className={Styles.inputContainer}>
                {addParameter.map((el, idx) => (
                  <div className={Styles.inputWrapper}>
                    <InputField
                      title={"Nama Parameter"}
                      value={el.label}
                      onChange={(e) =>
                        handleChangeInputParam("label", e.target.value, idx)
                      }
                    />
                    <InputField
                      title={"Label"}
                      value={el.code}
                      onChange={(e) =>
                        handleChangeInputParam("code", e.target.value, idx)
                      }
                    />
                    <Icon
                      icon={"delete"}
                      size={24}
                      onClick={() => handleDeleteParam(idx)}
                    />
                  </div>
                ))}
              </div>
              <div className={Styles.inputAction}>
                <div className={Styles.buttonWrapper}>
                  <Button
                    title={"Batal"}
                    type={"secondary-blue"}
                    onClick={() => {
                      setMode(0);
                      setAddParameter([
                        {
                          code: "",
                          label: "",
                        },
                      ]);
                    }}
                  />
                </div>
                <div className={Styles.buttonWrapper}>
                  <Button title={"Simpan"} onClick={handleCreateParameter} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {scrollPosition < scrollHeight && <div className={Styles.blur}></div>}
    </div>
  );
};

export default ParameterListModal;
