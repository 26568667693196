import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import InputField from "@Molecule/InputField";
import Dropdown from "@Atom/DropDown";

const arrayOfMonth = [
  { name: "Januari" },
  { name: "Februari" },
  { name: "Maret" },
  { name: "April" },
  { name: "Mei" },
  { name: "Juni" },
  { name: "Juli" },
  { name: "Agustus" },
  { name: "September" },
  { name: "Oktober" },
  { name: "November" },
  { name: "Desember" },
];

const arrayOfYear = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ name: year });
  }
  return years.reverse();
};

export default function InputWorkExperience({
  data,
  selected,
  setSelected,
  setValue,
  isError = false,
  errorText = 'Please fill the field',
}) {
  const handleChangeExperience = (value, id, name) => {
    setValue((prev) => ({
      ...prev,
      lastPosition: prev.lastPosition.map((el) =>
        el.id === id
          ? { ...el, [name]: !isNaN(value) ? value : value?.toUpperCase() }
          : el
      ),
    }));
  };

  const handleAddExperience = () => {
    const template = {
      id: new Date().getTime(),
      startYear: "",
      endYear: "",
      companyName: "",
      position: "",
      startMonth: "",
      endMonth: "",
      description: "",
    };
    setValue((prev) => ({
      ...prev,
      lastPosition: [...prev.lastPosition, template],
    }));
  };
  const handleDeleteExperience = (id) => {
    setValue((prev) => ({
      ...prev,
      lastPosition: prev.lastPosition.filter((el) => el.id !== id),
    }));
  };
  const handleChangeCheckbox = (checked, id) => {
    if (checked) {
      handleChangeExperience("-", id, "endYear");
      handleChangeExperience("-", id, "endMonth");
    } else {
      handleChangeExperience("", id, "endYear");
      handleChangeExperience("", id, "endMonth");
    }
  };

  return (
    <div className={Styles.Container}>
      <div>
        <p>Pengalaman Kerja</p>
        <p>Wajib Diisi</p>
      </div>
      <div className={Styles.selectWrapper}>
        <div
          className={`${Styles.select} ${
            selected === "exist" ? Styles.selected : ""
          }`}
          onClick={() => setSelected("exist")}
        >
          <p>Memiliki Pengalaman</p>
        </div>
        <div
          className={`${Styles.select} ${
            selected === "not exist" ? Styles.selected : ""
          }`}
          onClick={() => setSelected("not exist")}
        >
          <p>Fresh Graduate</p>
        </div>
      </div>
      <div className={Styles.content}>
        {data.map((el, idx) => (
          <div className={Styles.inputContainer} key={idx}>
            <div className={Styles.inputWrapper}>
              <InputField
                value={el.position}
                title="Posisi"
                placeholder="Contoh: Security"
                onChange={(e) =>
                  handleChangeExperience(e.target.value, el.id, "position")
                }
                // onChange={(e) => {
                //   if (e.target.value.length < 17) {
                //     handleChange({ name: "personId", value: e.target.value });
                //   }
                // }}
                required
                isError={isError && !el?.position}
                errorText="*Posisi belum diisi"
              />
              <InputField
                required
                value={el.companyName}
                title="Nama Perusahaan"
                placeholder="Contoh: Pt Maju Jaya"
                onChange={(e) =>
                  handleChangeExperience(e.target.value, el.id, "companyName")
                }
                isError={isError && !el?.companyName}
                errorText="*Nama perusahaan belum diisi"
                // onChange={(e) => {
                //   if (e.target.value.length < 17) {
                //     handleChange({ name: "personId", value: e.target.value });
                //   }
                // }}
              />
              <div className={Styles.inputDateContainer}>
                <div className={Styles.dateInputWrapper}>
                  <p>
                    Mulai <span className={Styles.required}>*</span>
                  </p>
                  <div className={Styles.inputDate}>
                    <Dropdown
                      placeholder={"Bulan"}
                      options={arrayOfMonth}
                      value={el.startMonth}
                      setValue={(e) =>
                        handleChangeExperience(e, el.id, "startMonth")
                      }
                      autoClose
                      isError={isError && !el?.startMonth}
                      errorText="*Bulan belum diisi"
                      //  iconColor={iconColor}
                    />
                    <Dropdown
                      placeholder={"Tahun"}
                      options={arrayOfYear()}
                      value={el.startYear}
                      setValue={(e) =>
                        handleChangeExperience(e, el.id, "startYear")
                      }
                      autoClose
                      isError={isError && !el?.startYear}
                      errorText="*Tahun belum diisi"
                      //  iconColor={iconColor}
                    />
                  </div>
                </div>
                <div className={Styles.dateInputWrapper}>
                  <p>
                    Berakhir <span className={Styles.required}>*</span>
                  </p>
                  <div className={Styles.inputDate}>
                    <Dropdown
                      placeholder={"Bulan"}
                      options={arrayOfMonth}
                      value={el.endMonth}
                      setValue={(e) =>
                        handleChangeExperience(e, el.id, "endMonth")
                      }
                      disabled={el.endMonth === "-"}
                      disabledNull={false}
                      autoClose
                      isError={isError && !el?.endMonth}
                      errorText="*Bulan belum diisi"
                      //  iconColor={iconColor}
                    />
                    <Dropdown
                      placeholder={"Tahun"}
                      options={arrayOfYear()}
                      value={el.endYear}
                      setValue={(e) =>
                        handleChangeExperience(e, el.id, "endYear")
                      }
                      disabled={el.endMonth === "-"}
                      disabledNull={false}
                      autoClose
                      isError={isError && !el?.endYear}
                      errorText="*Tahun belum diisi"
                      //  iconColor={iconColor}
                    />
                  </div>
                  <div className={Styles.checkBox}>
                    <input
                      type="checkbox"
                      checked={el.endMonth === "-" && el.endYear === "-"}
                      onChange={(e) =>
                        handleChangeCheckbox(e.target.checked, el.id)
                      }
                    />
                    <p>Masih bekerja hingga saat ini</p>
                  </div>
                </div>
              </div>
              <InputField
                required
                title="Deskripsi"
                type="desc"
                value={el.description}
                placeholder="Deskripsi"
                onChange={(e) =>
                  handleChangeExperience(e.target.value, el.id, "description")
                }
                isError={isError && !el?.description}
                errorText="*Deskripsi belum diisi"
                // onChange={(e) => {
                //   if (e.target.value.length < 17) {
                //     handleChange({ name: "personId", value: e.target.value });
                //   }
                // }}
              />
            </div>
            <div
              className={Styles.inputAction}
              onClick={() => handleDeleteExperience(el.id)}
            >
              <Icon icon={"trash"} size={20} />
            </div>
          </div>
        ))}

        <div
          className={`${Styles.addExperienceContainer} ${
            selected === "not exist" ? Styles.hidden : ""
          }`}
          onClick={handleAddExperience}
        >
          <div className={Styles.addExperienceWrapper}>
            <Icon icon={"circle-plus"} size={20} />
            <p>Pengalaman Kerja</p>
          </div>
        </div>
      </div>
      {
        isError && (!data || data?.length === 0)  && <span className={Styles.errorText}>{errorText}</span>
      }
    </div>
  );
}
