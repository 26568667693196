import Styles from "./inputField.module.scss";
import Input from "@Atom/Input";
import InputPhoneNumber from "@Atom/InputPhoneNumber";
import InputPassword from "@Atom/InputPassword";
import Textarea from "@Atom/Textarea";
import InputPhoneNumberVerification from "@Atom/InputPhoneNumberVerification";

const InputField = ({
  deleteOption,
  hideIcon,
  onDelete,
  title,
  value,
  id,
  onChange,
  placeholder,
  disabled,
  type,
  required,
  floatingStartAdornment,
  floatingEndAdornment,
  readOnly,
  isCurrency = false,
  requiredLabel = "*",
  isError = false,
  isErrorV2 = false,
  errorText = "Please fill the field",
  forceUpperCase = false,
  onKeyDown = () => {},
  onInput = () => {},
  maxLength = 1000,
  onClick,
  isVerified,
  top,
  left,
  right,
  startAdornmentDefaultPaddingLeft,
  isAddClient = false,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      {title && (
        <InputTitle
          title={title}
          required={required}
          requiredLabel={requiredLabel}
        />
      )}
      {type === "password" && (
        <InputPassword
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          errorText={errorText}
          isErrorV2={isErrorV2}
        />
      )}
      {type === "phone" && (
        <InputPhoneNumber
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          errorText={errorText}
          isErrorV2={isErrorV2}
          isAddClient={isAddClient}
        />
      )}
      {type === "phoneVerification" && (
        <InputPhoneNumberVerification
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          errorText={errorText}
          onClick={onClick}
          isVerified={isVerified}
          isErrorV2={isErrorV2}
        />
      )}

      {type === "address" && (
        <Textarea
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          hideIcon={hideIcon}
          disabled={disabled}
          icon="area"
          isError={isError}
          errorText={errorText}
          isErrorV2={isErrorV2}
        />
      )}
      {type === "desc" && (
        <Textarea
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          errorText={errorText}
          isErrorV2={isErrorV2}
        />
      )}
      {type === "email" && (
        <Input
          id={id}
          type="email"
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          errorText={errorText}
          isErrorV2={isErrorV2}
        />
      )}
      {type === "tel" && (
        <Input
          id={id}
          type="tel"
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          isErrorV2={isErrorV2}
          errorText={errorText}
        />
      )}
      {type === "number" && (
        <Input
          id={id}
          type="number"
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          isError={isError}
          isErrorV2={isErrorV2}
          errorText={errorText}
          onKeyDown={onKeyDown}
        />
      )}
      {!type && (
        <Input
          id={id}
          deleteOption={deleteOption}
          onDelete={onDelete}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          floatingEndAdornment={floatingEndAdornment}
          floatingStartAdornment={floatingStartAdornment}
          readOnly={readOnly}
          isCurrency={isCurrency}
          isError={isError}
          errorText={errorText}
          onKeyDown={onKeyDown}
          isErrorV2={isErrorV2}
          // onInput={onInput}
          maxLength={maxLength}
          forceUpperCase={forceUpperCase}
          top={top}
          left={left}
          right={right}
          startAdornmentDefaultPaddingLeft={startAdornmentDefaultPaddingLeft}
          // min={16}
          // max={16}
        />
      )}
    </div>
  );
};

export default InputField;

export const InputTitle = ({
  title,
  required = false,
  requiredLabel = "*",
  color = "",
}) => {
  return (
    <div className={Styles.title}>
      <div style={{ color }}>{title}</div>
      {required && <span className={Styles.redStar}>{requiredLabel}</span>}
    </div>
  );
};
