import Styles from "./style.module.scss";
import Input from "@Atom/Input";
import Textarea from "@Atom/Textarea";
import TipsAndTricksThumbnailOption from "@Molecule/TipsAndTricksThumbnailOption";
import { useState } from "react";
import ICAT from "@Assets/Images/irsyad-fajri.png";
import TEMEN_ICAT from "@Assets/Images/temannya-irsyad-fajri.png";

const ArticleForm = () => {
  const [isSelected, setIsSelected] = useState(0);

  return (
    <div className={Styles.fieldWrapper}>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Judul</div>
        <div className={Styles.input}>
          <Input placeholder="Tulis Judul" />
        </div>
      </div>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Judul Deskripsi</div>
        <div className={Styles.input}>
          <Input placeholder="Tulis Judul Deskripsi" />
        </div>
      </div>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>Deskripsi</div>
        <div className={Styles.input}>
          <Textarea placeholder="Tambahkan Deskripsi" />
        </div>
      </div>
      <div className={Styles.field}>
        <div className={Styles.fieldTitle}>
          Pilih atau upload gambar thumbnail
        </div>
        <div className={Styles.thumbnailOptions}>
          <div className={Styles.optionWrapper}>
            <TipsAndTricksThumbnailOption
              isSelected={isSelected === 1}
              image={ICAT}
              onClick={() => setIsSelected(1)}
            />
          </div>
          <div className={Styles.optionWrapper}>
            <TipsAndTricksThumbnailOption
              isSelected={isSelected === 2}
              image={TEMEN_ICAT}
              onClick={() => setIsSelected(2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleForm;
