import Icon from "@Atom/Icon";
import React from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function SelectContractTemplate({ handleClose }) {
  const navigate = useNavigate();

  return (
    <div className={Styles.ModalContainer}>
      <div className={Styles.ModalHeader}>
        <p>Template Kontrak</p>
        <Icon
          icon="cross"
          size={24}
          onClick={() => handleClose()}
          className={Styles.Icon}
        />
      </div>
      <div className={Styles.ModalBody}>
        <p>Pilih jenis kontrak yang akan dijadikan template kontrak</p>
        <div className={Styles.ModalSelectWrapper}>
          <div
            className={Styles.ModalSelection}
            onClick={() => navigate("/new-draft", { state: { type: "PKWT" } })}
          >
            <div className={Styles.IconWrapper}>
              <Icon
                icon="document-text"
                size={40}
                className={Styles.ModalDocumentIcon}
              />
            </div>
            <p className={Styles.IconText}>PKWT</p>
          </div>
          <div
            className={Styles.ModalSelection}
            onClick={() => navigate("/new-draft", { state: { type: "PKM" } })}
          >
            <div className={Styles.IconWrapper}>
              <Icon
                icon="document-text"
                size={40}
                className={Styles.ModalDocumentIcon}
              />
            </div>
            <p className={Styles.IconText}>PKM</p>
          </div>
        </div>
      </div>
    </div>
  );
}
