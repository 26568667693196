import MemberData from './MemberData'
import Styles from './style.module.scss'

export default function NotEmpty({
  form,
  setForm,
  options,
  errorField
}) {

  return (
    <div className={Styles.container}>
      {form?.map((each, i) => <MemberData
        key={i}
        options={options}
        data={each}
        form={form}
        setForm={setForm}
        errorField={errorField} />)}
    </div>
  )
}