import FormEmptyPlaceholder from '@Molecule/_candidate/EmptyPlaceholder/FormEmptyPlaceholder'
import Images from '@Theme/Images'
import { useEffect, useRef, useState } from 'react'
import NotEmpty from './NotEmpty'
import Styles from './style.module.scss'

export default function SecondStep({
  form,
  setForm,
  options,
  errorField
}) {

  // const onAdd = useCallback(() => {
  //   setForm([
  //     ...form,
  //     {
  //       id: Math.random(),
  //       relationship: null,
  //       name: null,
  //       birthPlace: null,
  //       birthDate: null,
  //       nik: null,
  //       gender: null,
  //     }
  //   ])
  // }, [form, setForm])

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef()

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
            <div className={Styles.autoScroll} ref={targetElement} />
      <h3 ref={headerRef}>
        Data Keluarga
        <span className={Styles.redStar}>*</span>
      </h3>
      <div className={Styles.content}>
        <div style={{ position: 'sticky', top: `calc(${headerHeight}px)` }} className={`${Styles.left}`}>
          <img src={Images?.FAMILY_BANNER} alt="banner" className={form?.length ? Styles.none : ''} />
          {/* <div className={Styles.buttonWrapper}>
            <button onClick={onAdd}>
              <Icon icon="add-circle" size={20} />
              <span>Anggota Keluarga</span>
            </button>
          </div> */}
          <div className={Styles.desc}>
            <span>Silahkan isi sesuai jumlah keluarga yang telah anda isi di step 1.</span>
            <span>Contoh: Status K2(kawin 2 anak), anda harus mengisi data diri pasangan anda dan 2 anak anda.</span>
          </div>

        </div>
        <div className={Styles.right}>
          {!form?.length
            ? <FormEmptyPlaceholder
              title={"Belum ada Anggota Keluarga yang Ditambahkan"}
              description={"Tambahkan anggota keluarga anda, klik pada tombol tambah keluarga"}
            />
            : <NotEmpty
              form={form}
              setForm={setForm}
              options={options}
              errorField={errorField}
            />
          }
        </div>
      </div>
    </div>
  )
}