import { getCandidateOfferingData } from "@Services/officer/recruitment";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OnProgress from "./OnProgress";
import Zero from "./Zero";
import Styles from "./style.module.scss";

export default function CandidateFormComponent({
  currentStep,
  setCurrentStep,
  syncData,
  // offeringData,
  // type,
}) {
  // const [data, setData] = useState(offeringData);
  const [data, setData] = useState({});
  const [type, setType] = useState({});
  const { hash } = useParams();

  const [trigger, setTrigger] = useState(null);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)

  const refetch = useCallback(async () => {
    const data = await getCandidateOfferingData(hash);
    setData(data?.response);
    setType(data?.jobPostType)
  }, [hash]);

  useEffect(() => {
    refetch();
  }, [trigger, refetch]);

  return (
    <div className={Styles.container}>
      {currentStep === 0 ? (
        <Zero
          setCurrentStep={setCurrentStep}
          disableClick={data?.status !== "OFFER-INVITE" ? true : false}
          offeringData={data}
          refetch={refetch}
          type={type}
          isPhoneVerified={isPhoneVerified}
          setIsPhoneVerified={setIsPhoneVerified}
        />
      ) : (
        <OnProgress
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          syncData={syncData}
          offeringData={data}
          setTriggerDefault={setTrigger}
          type={type}
          isPhoneVerified={isPhoneVerified}
          setIsPhoneVerified={setIsPhoneVerified}
          hash={hash}
        />
      )}
    </div>
  );
}
