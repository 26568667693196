import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useEffect } from "react";

export default function RemarkSection({
  remark,
  setRemark,
  type,
  existingType,
  client,
}) {
  useEffect(() => {
    sessionStorage.setItem("remarkSection", remark || "");
  }, [remark]);

  const onChangeTextarea = (e) => {
    setRemark(e.target.value);
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"list-box"} size={20} />
      </div>

      <div className={Styles.header}>
        <span>Lainnya</span>
      </div>

      <div className={Styles.textAreaBox}>
        <span>Remark</span>
        <textarea
          type="text"
          name="remark"
          value={remark || ""}
          onChange={onChangeTextarea}
          className={`${Styles.textAreaRemark} ${
            type === "EXISTING" &&
            existingType !== "mws" &&
            !client &&
            Styles.disabled
          }`}
          placeholder="Description"
          disabled={type === "EXISTING" && existingType !== "mws" && !client}
        />
      </div>
    </div>
  );
}
