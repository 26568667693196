/* eslint-disable no-unused-vars */
import CandidateFormComponent from "@Organism/Additional/CandidateForm";
import {
  getCandidateOfferingData,
  getCandidateSync,
} from "@Services/officer/recruitment";
import { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader({ params }) {
  const hash = params.hash;

  // const candidateOfferingPromise = getCandidateOfferingData(hash);
  const candidateSyncPromise = getCandidateSync();

  return defer({
    packageCandidateSync: candidateSyncPromise,
    // packageCandidateOffering: candidateOfferingPromise,
  });
}

export default function CandidateForm() {
  // const { packageCandidateSync, packageCandidateOffering } = useLoaderData();
  const { packageCandidateSync } = useLoaderData();

  const [currentStep, setCurrentStep] = useState(
    +sessionStorage.getItem("candidateStep") || 0
  );

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Candidate Form</title>
      </Helmet>

      <Suspense
        fallback={
          <div className={Styles.loadingWrapper}>
            <LoadingAnimation />
          </div>
        }
      >
        <Await
          resolve={packageCandidateSync}
          errorElement={
            <div style={{ height: "calc(100vh - 220px)" }}>
              <AsyncErrorBoundary />
            </div>
          }
        >
          {(packageCandidateSync) => (
            <CandidateFormComponent
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              syncData={packageCandidateSync?.response}
              // offeringData={packageCandidateOffering?.response}
              // type={packageCandidateOffering?.jobPostType}
            />
            // <Suspense
            //   fallback={
            //     <div className={Styles.loadingWrapper}>
            //       <LoadingAnimation />
            //     </div>
            //   }
            // >
            //   <Await
            //     resolve={packageCandidateOffering}
            //     errorElement={
            //       <div style={{ height: "calc(100vh - 220px)" }}>
            //         <AsyncErrorBoundary />
            //       </div>
            //     }
            //   >
            //     {(packageCandidateOffering) => (
            //       <CandidateFormComponent
            //         currentStep={currentStep}
            //         setCurrentStep={setCurrentStep}
            //         syncData={packageCandidateSync?.response}
            //         offeringData={packageCandidateOffering?.response}
            //         type={packageCandidateOffering?.jobPostType}
            //       />
            //     )}
            //   </Await>
            // </Suspense>
          )}
        </Await>
      </Suspense>
    </div>
  );
}
