import { useEffect, useRef } from 'react';
import FinishedModal from './FinishedModal';
import Styles from './style.module.scss';
import Images from '@Theme/Images'

export default function SeventhStep(props) {
  const {
    onPrevious,
    onSubmit,
    isSuccess,
    setIsSuccess,
    error,
    setError,
    isSubmitting,
    isSubmittingDraft,
    submittingType,
    clearStorage,
    disableSubmit
  } = props;

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error, setError])

  const handleClickBack = () => {
    setError(null)
    onPrevious()
  }

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);



  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <div className={Styles.card}>
        <img src={Images.FINISHED} alt="finished" />
        <h4 className={error && Styles.error}>{error ? error?.code : 'Finish'}</h4>
        {error ? (
          <p className={Styles.error}>
            <span>{error?.message}</span>
          </p>
        ) : (
          <p>You have filled in the order data, click send as FLB & Work Order to continue the order</p>

        )}
      </div>


      <div className={Styles.buttonsWrapper}>
        <div className={Styles.left}>
          <button onClick={handleClickBack} className={Styles.prev}>
            Back
          </button>
        </div>
        <div className={Styles.right}>
          <button
            className={Styles.saveToDraft}
            onClick={(e) => onSubmit(e, 'draft')}
            disabled={isSubmittingDraft || isSubmitting || disableSubmit}
          >{isSubmittingDraft ? 'Saving as Draft...' : 'Save as Draft'}</button>
          <button
            disabled={isSubmittingDraft || isSubmitting || disableSubmit}
            onClick={(e) => onSubmit(e, 'send')}
            className={Styles.submit}
          >
            {isSubmitting ? "Sending..." : "Send FLB"}
          </button>
        </div>

      </div>

      {
        isSuccess && (
          <FinishedModal clearStorage={clearStorage} setShow={setIsSuccess} submittingType={submittingType} />
        )
      }
    </div>
  )
}