/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// import { func } from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./styles.module.scss";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import {
  getCandidateTransitionOfferingData,
  sendCandidateUpdateForm,
  sendOTP,
  sendTransitionForm,
} from "@Services/officer/recruitment";
import moment from "moment";
import FirstStep from "./First";
import SecondStep from "./Second";
import ThirdStep from "./Third";
import FourthStep from "./Fourth";
import FifthStep from "./Fifth";
import SixthStep from "./Sixth";
import SendingModal from "@Molecule/_modal/SendingModal";
import Icon from "@Atom/Icon";
import Stepper from "@Molecule/Stepper";
import Images from "@Theme/Images";
import { useLocation, useNavigate } from "react-router-dom";
import ModalWrapper from "@Atom/ModalWrapper";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import useQuery from "@Hooks/useQuery";
import { imageToPdf } from "@Helpers/convertImageToPdf";
import loading from "@Helpers/debounceLoading";
import { fileBaseUrl } from "@Config/api";
import { makeRandomString } from "@Helpers/makeRandomString";
import LoadingModal from "@Molecule/_modal/LoadingModal";
import { validateEmail } from "@Helpers/validateEmail";

export default function OnProgress({
  currentStep,
  setCurrentStep,
  syncData,
  offeringData,
  setTriggerDefault,
}) {
  const query = useQuery();
  const [trigger, setTrigger] = useState(null);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [loadingPersonId, setLoadingPersonId] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [loadingPhoneVerify, setLoadingPhoneVerify] = useState(false);
  const [tempValue, setTempValue] = useState({});
  const [triggerRenew, setTriggerRenew] = useState("");

  const defaultPersonalData = JSON.parse(
    sessionStorage.getItem("c_personalData_transition")
  );

  const [personalData, setPersonalData] = useState({
    name: defaultPersonalData?.name,
    birthPlace: defaultPersonalData?.birthPlace,
    birthDate: defaultPersonalData?.birthDate,
    gender: defaultPersonalData?.gender,
    religion: defaultPersonalData?.religion,
    bloodType: defaultPersonalData?.bloodType,
    height: defaultPersonalData?.height,
    weight: defaultPersonalData?.weight,
    maritalStatus: defaultPersonalData?.maritalStatus,
    kk: defaultPersonalData?.kk,
    nik: defaultPersonalData?.nik,
    idCardPeriod: defaultPersonalData?.idCardPeriod,
    taxNumber: defaultPersonalData?.taxNumber,
    email: defaultPersonalData?.email,
    phoneNumber: defaultPersonalData?.phoneNumber,
    biologicalMotherName: defaultPersonalData?.biologicalMotherName,
  });

  const defaultAddress = JSON.parse(
    sessionStorage.getItem("c_address_transition")
  );
  const [address, setAddress] = useState({
    idCard: {
      address: defaultAddress?.idCard?.address,
      rt: defaultAddress?.idCard?.rt,
      rw: defaultAddress?.idCard?.rw,
      ward: defaultAddress?.idCard?.ward,
      subdistrict: defaultAddress?.idCard?.subdistrict,
      regency: defaultAddress?.idCard?.regency,
      province: defaultAddress?.idCard?.province,
      postal: defaultAddress?.idCard?.postal,
    },
    domicile: {
      address: defaultAddress?.domicile?.address,
      rt: defaultAddress?.domicile?.rt,
      rw: defaultAddress?.domicile?.rw,
      ward: defaultAddress?.domicile?.ward,
      subdistrict: defaultAddress?.domicile?.subdistrict,
      regency: defaultAddress?.domicile?.regency,
      province: defaultAddress?.domicile?.province,
      postal: defaultAddress?.domicile?.postal,
    },
  });

  const defaultLastEducation = JSON.parse(
    sessionStorage.getItem("c_lastEducation_transition")
  );

  const [lastEducation, setLastEducation] = useState({
    education: defaultLastEducation?.education,
    institution: defaultLastEducation?.institution,
    faculty: defaultLastEducation?.faculty,
    gpa: defaultLastEducation?.gpa,
  });

  const defaultContactInfo = JSON.parse(
    sessionStorage.getItem("c_contactInfo_transition")
  );

  const [contactInfo, setContactInfo] = useState({
    emergencyContact: {
      name: defaultContactInfo?.emergencyContact?.name,
      relationship: defaultContactInfo?.emergencyContact?.relationship,
      phoneNumber: defaultContactInfo?.emergencyContact?.phoneNumber,
      address: defaultContactInfo?.emergencyContact?.address,
    },
    sameHouseFamilyContact: {
      name: defaultContactInfo?.sameHouseFamilyContact?.name,
      relationship: defaultContactInfo?.sameHouseFamilyContact?.relationship,
      phoneNumber: defaultContactInfo?.sameHouseFamilyContact?.phoneNumber,
      address: defaultContactInfo?.sameHouseFamilyContact?.address,
    },
    differentHouseFamilyContact: {
      name: defaultContactInfo?.differentHouseFamilyContact?.name,
      relationship:
        defaultContactInfo?.differentHouseFamilyContact?.relationship,
      phoneNumber: defaultContactInfo?.differentHouseFamilyContact?.phoneNumber,
      address: defaultContactInfo?.differentHouseFamilyContact?.address,
    },
  });

  const defaultOtherData = JSON.parse(
    sessionStorage.getItem("c_otherData_transition")
  );

  const [otherData, setOtherData] = useState({
    bankName: defaultOtherData?.bankName,
    bankAccountNumber: defaultOtherData?.bankAccountNumber,
    roCode: offeringData?.recruiter?.employeeId,
    nearestHealthFacility: defaultOtherData?.nearestHealthFacility,
    fbUsername: defaultOtherData?.fbUsername,
    igUsername: defaultOtherData?.igUsername,
    twitterUsername: defaultOtherData?.twitterUsername,
    linkedinUsername: defaultOtherData?.linkedinUsername,
  });

  const [isDomicileTheSame, setIsDomicileTheSame] = useState(
    sessionStorage.getItem("isDomicileTheSameTransition") === "true"
      ? true
      : false
  );
  const [lifetimeIdCard, setLifetimeIdCard] = useState(
    sessionStorage.getItem("lifetimeIdCardTransition") === "true" ? true : false
  );
  const [noTaxNumber, setNoTaxNumber] = useState(
    sessionStorage.getItem("noTaxNumberTransition") === "true" ? true : false
  );

  const defaultFamilyMembers = JSON.parse(
    sessionStorage.getItem("c_familyMembers_transition")
  );
  const [familyMembers, setFamilyMembers] = useState(
    defaultFamilyMembers || []
  );

  useEffect(() => {
    switch (personalData?.maritalStatus?.toLowerCase()) {
      case "tidak kawin":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
        ]);
        break;
      case "kawin (0 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.gender) ||
              null,
          },
        ]);
        break;
      case "kawin (1 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.gender) ||
              null,
          },
        ]);
        break;
      case "kawin (2 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[3]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[3]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.gender) ||
              null,
          },
        ]);
        break;
      case "kawin (>= 3 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: personalData?.name,
            birthPlace: personalData?.birthPlace,
            birthDate: personalData?.birthDate,
            nik: personalData?.nik,
            gender: personalData?.gender,
            isSelf: true,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[1]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[1]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[2]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[2]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[3]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[3]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[3]?.gender) ||
              null,
          },
          {
            id: Math.random(),
            relationship:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[4]?.relationship) ||
              null,
            name:
              (tempValue?.familyMembers && tempValue?.familyMembers[4]?.name) ||
              null,
            birthPlace:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[4]?.birthPlace) ||
              null,
            birthDate:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[4]?.birthDate) ||
              null,
            nik:
              (tempValue?.familyMembers && tempValue?.familyMembers[4]?.nik) ||
              null,
            gender:
              (tempValue?.familyMembers &&
                tempValue?.familyMembers[4]?.gender) ||
              null,
          },
        ]);
        break;
      default:
        return;
    }
  }, [
    offeringData?.CandidateFamilyMembers,
    personalData?.birthDate,
    personalData?.birthPlace,
    personalData?.gender,
    personalData?.maritalStatus,
    personalData?.name,
    personalData?.nik,
    tempValue?.familyMembers,
  ]);

  const defaultEducationHistory = JSON.parse(
    sessionStorage.getItem("c_educationHistory_transition")
  );
  const [educationHistory, setEducationHistory] = useState(
    defaultEducationHistory || []
  );

  const defaultJobHistory = JSON.parse(
    sessionStorage.getItem("c_jobHistory_transition")
  );
  const [jobHistory, setJobHistory] = useState(defaultJobHistory || []);

  const defaultPerformanceHistory = JSON.parse(
    sessionStorage.getItem("c_performanceHistory_transition")
  );

  const [performanceHistory, setPerformanceHistory] = useState(
    defaultPerformanceHistory || []
  );

  const defaultDocuments = JSON.parse(
    sessionStorage.getItem("c_documents_transition")
  );

  const [documents, setDocuments] = useState(
    defaultDocuments || {
      mandatory: [],
      nonMandatory: [
        {
          title: "Jamsostek",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "Surat Referensi Kerja",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "Surat Nikah",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "Akta Lahir",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "Surat Persetujuan Keluarga",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "SKCK",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
        {
          title: "Surat Keterangan Berbadan Sehat",
          fileType: "jpg",
          file: null,
          preview: null,
          name: null,
        },
      ],
      additional: [],
    }
  );

  const renewDefault = useCallback(() => {
    sessionStorage.setItem("transitionStep", currentStep);
    sessionStorage.setItem(
      "c_personalData_transition",
      JSON.stringify(personalData)
    );
    sessionStorage.setItem("c_address_transition", JSON.stringify(address));
    sessionStorage.setItem(
      "c_lastEducation_transition",
      JSON.stringify(lastEducation)
    );
    sessionStorage.setItem(
      "c_contactInfo_transition",
      JSON.stringify(contactInfo)
    );
    sessionStorage.setItem("c_otherData_transition", JSON.stringify(otherData));
    sessionStorage.setItem(
      "c_familyMembers_transition",
      JSON.stringify(familyMembers)
    );
    sessionStorage.setItem(
      "c_educationHistory_transition",
      JSON.stringify(educationHistory)
    );
    sessionStorage.setItem(
      "c_jobHistory_transition",
      JSON.stringify(jobHistory)
    );
    sessionStorage.setItem(
      "c_performanceHistory_transition",
      JSON.stringify(performanceHistory)
    );
    sessionStorage.setItem("c_documents_transition", JSON.stringify(documents));
    sessionStorage.setItem("isDomicileTheSameTransition", isDomicileTheSame);
    sessionStorage.setItem("lifetimeIdCardTransition", lifetimeIdCard);
    sessionStorage.setItem("noTaxNumberTransition", noTaxNumber);
  }, [
    currentStep,
    personalData,
    address,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    isDomicileTheSame,
    lifetimeIdCard,
    noTaxNumber,
    documents,
  ]);

  useEffect(() => {
    renewDefault();
  }, [renewDefault, triggerRenew]);

  const refetchDocuments = useCallback(() => {
    if (offeringData?.jobPostType?.contractType.toLowerCase() === "pkwt") {
      if (noTaxNumber) {
        let defaultMandatory = [
          {
            title: "CV",
            fileType: "pdf",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Foto Selfie",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Surat Lamaran",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "KTP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Ijazah",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Transkrip Nilai",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Kartu Keluarga",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Rekening",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
        ];
        if (
          tempValue?.documents?.mandatory &&
          tempValue?.documents?.mandatory?.length > 0
        ) {
          defaultMandatory = defaultMandatory.map((value) => {
            const doc = tempValue?.documents?.mandatory?.find(
              (item) =>
                item?.title?.toUpperCase() === value?.title?.toUpperCase()
            );
            if (doc) {
              return {
                ...value,
                preview: doc?.preview || null,
                name: doc?.name || null,
                fileType: doc?.fileType || null,
              };
            } else {
              return value;
            }
          });
        }
        let defaultAdditional = [];
        if (
          tempValue?.documents?.additional &&
          tempValue?.documents?.additional?.length > 0
        ) {
          for (const value of tempValue?.documents?.additional) {
            defaultAdditional.push({
              title: value.title || null,
              preview: value.preview || null,
              name: value.name || null,
              fileType: value.tyfileTypepe || null,
              file: null,
            });
          }
        }
        setDocuments({
          ...documents,
          mandatory: defaultMandatory,
          additional: defaultAdditional,
        });
      } else if (!noTaxNumber) {
        let defaultMandatory = [
          {
            title: "CV",
            fileType: "pdf",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Foto Selfie",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Surat Lamaran",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "KTP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Ijazah",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Transkrip Nilai",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Kartu Keluarga",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Rekening",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "NPWP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
        ];
        if (
          tempValue?.documents?.mandatory &&
          tempValue?.documents?.mandatory?.length > 0
        ) {
          defaultMandatory = defaultMandatory.map((value) => {
            const doc = tempValue?.documents?.mandatory?.find(
              (item) =>
                item?.title?.toUpperCase() === value?.title?.toUpperCase()
            );
            if (doc) {
              return {
                ...value,
                preview: doc?.preview || null,
                name: doc?.name || null,
                fileType: doc?.fileType || null,
              };
            } else {
              return value;
            }
          });
        }
        let defaultAdditional = [];
        if (
          tempValue?.documents?.additional &&
          tempValue?.documents?.additional?.length > 0
        ) {
          for (const value of tempValue?.documents?.additional) {
            defaultAdditional.push({
              title: value.title || null,
              preview: value.preview || null,
              name: value.name || null,
              fileType: value.tyfileTypepe || null,
              file: null,
            });
          }
        }
        setDocuments({
          ...documents,
          mandatory: defaultMandatory,
          additional: defaultAdditional,
        });
      }
    } else if (
      offeringData?.jobPostType?.contractType.toLowerCase() === "pkm"
    ) {
      if (noTaxNumber) {
        let defaultMandatory = [
          {
            title: "CV",
            fileType: "pdf",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Foto Selfie",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "KTP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Kartu Keluarga",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Rekening",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
        ];
        if (
          tempValue?.documents?.mandatory &&
          tempValue?.documents?.mandatory?.length > 0
        ) {
          defaultMandatory = defaultMandatory.map((value) => {
            const doc = tempValue?.documents?.mandatory?.find(
              (item) =>
                item?.title?.toUpperCase() === value?.title?.toUpperCase()
            );
            if (doc) {
              return {
                ...value,
                preview: doc?.preview || null,
                name: doc?.name || null,
                fileType: doc?.fileType || null,
              };
            } else {
              return value;
            }
          });
        }
        let defaultAdditional = [];
        if (
          tempValue?.documents?.additional &&
          tempValue?.documents?.additional?.length > 0
        ) {
          for (const value of tempValue?.documents?.additional) {
            defaultAdditional.push({
              title: value.title || null,
              preview: value.preview || null,
              name: value.name || null,
              fileType: value.tyfileTypepe || null,
              file: null,
            });
          }
        }
        setDocuments({
          ...documents,
          mandatory: defaultMandatory,
          additional: defaultAdditional,
        });
      } else if (!noTaxNumber) {
        let defaultMandatory = [
          {
            title: "CV",
            fileType: "pdf",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Foto Selfie",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "KTP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Kartu Keluarga",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "Rekening",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
          {
            title: "NPWP",
            fileType: "jpg",
            file: null,
            preview: null,
            name: null,
          },
        ];

        if (
          tempValue?.documents?.mandatory &&
          tempValue?.documents?.mandatory?.length > 0
        ) {
          defaultMandatory = defaultMandatory.map((value) => {
            const doc = tempValue?.documents?.mandatory?.find(
              (item) =>
                item?.title?.toUpperCase() === value?.title?.toUpperCase()
            );
            if (doc) {
              return {
                ...value,
                preview: doc?.preview || null,
                name: doc?.name || null,
                fileType: doc?.fileType || null,
              };
            } else {
              return value;
            }
          });
        }
        let defaultAdditional = [];
        if (
          tempValue?.documents?.additional &&
          tempValue?.documents?.additional?.length > 0
        ) {
          for (const value of tempValue?.documents?.additional) {
            defaultAdditional.push({
              title: value.title || null,
              preview: value.preview || null,
              name: value.name || null,
              fileType: value.tyfileTypepe || null,
              file: null,
            });
          }
        }
        setDocuments({
          ...documents,
          mandatory: defaultMandatory,
          additional: defaultAdditional,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxNumber, tempValue]);

  useEffect(() => {
    refetchDocuments();
  }, [refetchDocuments, triggerRenew]);

  const [isDoneCompleting, setIsDoneCompleting] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [error, setError] = useState(null);
  const [errorField, setErrorField] = useState(false);
  const [errorShow, setErrorShow] = useState(false);

  const handleErrorShow = () => {
    setErrorShow(true);
    setTimeout(() => {
      setErrorShow(false);
    }, 5000);
  };

  const isDoneChecker = useMemo(() => {
    return {
      first: () => {
        const newPersonalData = { ...personalData };
        const newOtherData = { ...otherData };

        delete newPersonalData.idCardPeriod;
        delete newPersonalData.taxNumber;

        delete newOtherData.fbUsername;
        delete newOtherData.igUsername;
        delete newOtherData.twitterUsername;
        delete newOtherData.linkedinUsername;

        if (
          !allFilledObjChecker(newPersonalData) ||
          !(personalData?.idCardPeriod || lifetimeIdCard) ||
          !(personalData?.taxNumber || noTaxNumber) ||
          !allFilledObjChecker(address?.idCard) ||
          !(isDomicileTheSame || allFilledObjChecker(address?.domicile)) ||
          !allFilledObjChecker(lastEducation) ||
          !allFilledObjChecker(contactInfo?.emergencyContact) ||
          !allFilledObjChecker(contactInfo?.sameHouseFamilyContact) ||
          !allFilledObjChecker(contactInfo?.differentHouseFamilyContact) ||
          !allFilledObjChecker(newOtherData) ||
          personalData?.nik?.length < 16 ||
          personalData?.kk?.length < 16 ||
          !isPhoneVerified ||
          !isValidated ||
          !validateEmail(personalData?.email)
        ) {
          return false;
        }
        return true;
      },
      second: () => {
        if (familyMembers?.length) {
          if (familyMembers?.length > 1) {
            for (let i = 0; i < familyMembers.length; i++) {
              if (!allFilledObjChecker(familyMembers[i])) {
                return false;
              }
            }
          } else {
            return true;
          }

          return true;
        } else {
          return false;
        }
      },
      third: () => {
        if (educationHistory?.length) {
          for (let i = 0; i < educationHistory.length; i++) {
            if (!allFilledObjChecker(educationHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      },
      fourth: () => {
        if (jobHistory?.length) {
          for (let i = 0; i < jobHistory.length; i++) {
            if (!allFilledObjChecker(jobHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 4) {
            return true;
          } else {
            return false;
          }
        }
      },
      fifth: () => {
        if (performanceHistory?.length) {
          for (let i = 0; i < performanceHistory.length; i++) {
            if (!allFilledObjChecker(performanceHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 5) {
            return true;
          } else {
            return false;
          }
        }
      },
      sixth: () => {
        const filtered = documents?.mandatory?.filter(
          (obj) => obj?.title !== "CV"
        );
        for (let i = 0; i < filtered?.length; i++) {
          const doc = filtered[i];
          if (!doc?.preview) {
            return false;
          }
        }
        const filteredCV = documents?.mandatory?.filter(
          (obj) => obj?.title === "CV"
        );

        for (let i = 0; i < filteredCV?.length; i++) {
          const doc = filteredCV[i];
          if (!doc?.name) {
            return false;
          }
        }
        return true;
      },
    };
  }, [
    personalData,
    lifetimeIdCard,
    noTaxNumber,
    address,
    isDomicileTheSame,
    contactInfo,
    lastEducation,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    isPhoneVerified,
    isValidated,
    currentStep,
  ]);

  useEffect(() => {
    if (isDoneChecker?.sixth() === false) {
      setIsDoneCompleting(false);
    }
  }, [isDoneChecker, isDoneChecker.sixth]);

  // const defaultSteps = JSON.parse(
  //   sessionStorage.getItem("transitionFormSteps")
  // );

  const stepsOptions = useMemo(() => {
    return [
      {
        id: 1,
        label: "Personal Data",
        isDone: isDoneChecker?.first(),
      },
      {
        id: 2,
        label: "Data Keluarga",
        isDone: isDoneChecker?.second(),
      },
      {
        id: 3,
        label: "Data Pendidikan",
        isDone: isDoneChecker?.third(),
      },
      {
        id: 4,
        label: "Data Pekerjaan",
        isDone: isDoneChecker?.fourth(),
      },
      {
        id: 5,
        label: "Data Prestasi",
        isDone: isDoneChecker?.fifth(),
      },
      {
        id: 6,
        label: "Unggah Dokumen",
        isDone: isDoneChecker?.sixth(),
      },
    ];
  }, [isDoneChecker]);

  const handleClickStep = (step) => {
    if (+step === 1 || +currentStep > +step) {
      setCurrentStep(step);
    } else if (+currentStep < +step) {
      if (stepsOptions[+currentStep - 1]?.isDone) {
        setCurrentStep(step);
      }
    }
  };

  const [errorDate, setErrorDate] = useState("");

  const onPrevious = () => {
    setErrorDate("");
    setErrorField(false);
    currentStep > 1 && setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    let checkField = disableNext;
    if (checkField) {
      setErrorField(checkField);
      handleErrorShow();
      setErrorDate("");
    } else {
      setErrorDate("");
      if (currentStep < 6) {
        setErrorField(false);
        setCurrentStep(currentStep + 1);
      } else {
        setErrorField(false);
        setIsDoneCompleting(true);
      }
    }
  };

  useEffect(() => {
    if (currentStep !== 6) {
      setIsDoneCompleting(false);
    }
  }, [currentStep]);

  const clearStorage = () => {
    sessionStorage.removeItem("transitionStep");
    sessionStorage.removeItem("c_personalData_transition");
    sessionStorage.removeItem("c_address_transition");
    sessionStorage.removeItem("c_lastEducation_transition");
    sessionStorage.removeItem("c_contactInfo_transition");
    sessionStorage.removeItem("c_otherData_transition");
    sessionStorage.removeItem("c_familyMembers_transition");
    sessionStorage.removeItem("c_educationHistory_transition");
    sessionStorage.removeItem("c_jobHistory_transition");
    sessionStorage.removeItem("c_performanceHistory_transition");
    sessionStorage.removeItem("isDomicileTheSameTransition");
    sessionStorage.removeItem("lifetimeIdCardTransition");
    sessionStorage.removeItem("noTaxNumberTransition");
  };

  const disableInput = useMemo(() => {
    return personalData?.nik?.length !== 16 || !isValidated || !isPhoneVerified;
  }, [isValidated, personalData?.nik?.length, isPhoneVerified]);

  const disablePhoneVerification = useMemo(() => {
    return personalData.nik?.length !== 16 || !isValidated;
  }, [isValidated, personalData.nik?.length]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const handleSendOTP = useCallback(async () => {
  //   try {
  //     setIsProcessing(true);
  //     setLoadingPhoneVerify(true)
  //     const { response } = await sendOTP("62" + personalData?.phoneNumber);
  //     setIsProcessing(false);
  //     setOpenValidateModal(false);
  //     navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
  //   } catch (error) {
  //     setLoadingPhoneVerify(false)
  //     // setIsProcessing(false);
  //     setError(error?.response?.data?.error || "Something went wrong");
  //     console.log(error);
  //   }
  // }, [
  //   personalData?.phoneNumber,
  //   pathname,
  //   navigate,
  //   // setIsProcessing,
  //   // setOpenValidateModal,
  //   // setError,
  // ]);

  useEffect(() => {
    const handleCheckId = async () => {
      if (personalData.nik?.length === 16) {
        try {
          setLoadingPersonId(true);
          await loading(5000);
          const { response } = await getCandidateTransitionOfferingData(
            personalData.nik,
            offeringData.position.id
          );
          setLoadingPersonId(false);
          if (response?.candidateComprehensives) {
            setOpenValidateModal(true);
            setLoadingPhoneVerify(true);
            const data = await sendOTP(
              response?.candidateComprehensives.phoneNumber
            );

            let tempFamilyMembers = [];
            if (
              response?.candidateFamilyMembers &&
              response?.candidateFamilyMembers.length > 0
            ) {
              const candidateFamily = response?.candidateFamilyMembers;
              for (let index = 3; index < candidateFamily.length; index++) {
                let isSelf = {};
                if (
                  candidateFamily[index].nik ===
                  response?.candidateComprehensives?.personId
                ) {
                  isSelf = { isSelf: true };
                } else {
                  isSelf = {};
                }

                let birthDate = candidateFamily[index].dateOfBirth
                  ? new Date(candidateFamily[index].dateOfBirth)
                  : null;

                tempFamilyMembers.push({
                  ...isSelf,
                  id: candidateFamily[index].id || "",
                  candidateID: candidateFamily[index].candidateID || "",
                  relationship: candidateFamily[index].relation || "",
                  name: candidateFamily[index].name || "",
                  birthPlace: candidateFamily[index].placeOfBirth || "",
                  birthDate: birthDate || "",
                  nik: candidateFamily[index].nik || "",
                  gender: candidateFamily[index].gender || "",
                  address: candidateFamily[index].address || "",
                  createdAt: candidateFamily[index].createdAt || "",
                  updatedAt: candidateFamily[index].updatedAt || "",
                });
              }
            }

            let tempEducationHistory = [];
            if (
              response?.candidateEducations &&
              response?.candidateEducations.length > 0
            ) {
              for (const value of response?.candidateEducations) {
                tempEducationHistory.push({
                  id: value.id || "",
                  candidateID: value.candidateID || "",
                  startingYear: value.startYear || "",
                  finishingYear: value.endYear || "",
                  educationType: value.type || "",
                  institution: value.name || "",
                  faculty: value.mastery || "",
                  certificateNumber: value.sertificateNumber || "",
                  city: value.city || "",
                  createdAt: value.createdAt || "",
                  updatedAt: value.updatedAt || "",
                  CandidateID: value.CandidateID || "",
                });
              }
            }

            let tempJobHistory = [];
            if (
              response?.candidateWorkExperiences &&
              response?.candidateWorkExperiences.length > 0
            ) {
              for (const value of response?.candidateWorkExperiences) {
                tempJobHistory.push({
                  id: value.id || "",
                  candidateID: value.candidateID || "",
                  startYear: value.startYear || "",
                  endYear: value.endYear || "",
                  companyName: value.companyName || "",
                  position: value.position || "",
                  description: value.remark || "",
                  startMonth: value.startMonth || "",
                  endMonth: value.endMonth || "",
                  createdAt: value.createdAt || "",
                  updatedAt: value.updatedAt || "",
                });
              }
            }

            let tempPerformanceHistory = [];
            if (
              response?.candidateAchievements &&
              response?.candidateAchievements.length > 0
            ) {
              for (const value of response?.candidateAchievements) {
                tempPerformanceHistory.push({
                  id: value.id || "",
                  candidateID: value.candidateID || "",
                  year: value.year || "",
                  performance: value.achievement || "",
                  description: value.remark || "",
                  createdAt: value.createdAt || "",
                  updatedAt: value.updatedAt || "",
                });
              }
            }

            let tempDocuments = documents;

            if (
              response?.candidateDocuments &&
              response?.candidateDocuments.length > 0
            ) {
              documents?.mandatory.map((value, index) => {
                const doc = response?.candidateDocuments.find(
                  (item) =>
                    item?.type?.toUpperCase() === value?.title?.toUpperCase()
                );
                if (doc) {
                  tempDocuments.mandatory[index] = {
                    ...value,
                    preview: doc.url ? fileBaseUrl + doc.url : null,
                    name: doc.url ? doc.url : null,
                    fileType: doc.format ? doc.format : null,
                  };
                }
                return false;
              });

              documents?.nonMandatory.map((value, index) => {
                const doc = response?.candidateDocuments.find(
                  (item) =>
                    item?.type?.toUpperCase() === value?.title?.toUpperCase()
                );
                if (doc) {
                  tempDocuments.nonMandatory[index] = {
                    ...value,
                    preview: doc.url ? fileBaseUrl + doc.url : null,
                    name: doc.url ? doc.url : null,
                    fileType: doc.format ? doc.format : null,
                  };
                }
                return false;
              });

              const tempDocAdditional = response?.candidateDocuments.filter(
                (item) => item?.type.toUpperCase().includes(".PDF")
              );

              if (tempDocAdditional && tempDocAdditional.length > 0) {
                let tempAdd = [];
                for (const value of tempDocAdditional) {
                  tempAdd.push({
                    title: value.type
                      ? value.type.replace(/^(.+)\.pdf-.+$/, "$1.pdf")
                      : null,
                    preview: value.url ? fileBaseUrl + value.url : null,
                    name: value.url
                      ? value.url.replace(/^(.+)\.pdf-.+$/, "$1.pdf")
                      : null,
                    fileType: value.format ? value.format : null,
                    file: null,
                  });
                }
                tempDocuments.additional = tempAdd;
              }
            }

            setLifetimeIdCard(
              response?.candidateComprehensives?.personIdPeriod ===
                "SEUMUR HIDUP"
                ? true
                : false
            );
            setNoTaxNumber(
              response?.candidateComprehensives?.taxId === "TIDAK MEMILIKI NPWP"
                ? true
                : false
            );

            setTempValue({
              personalData: {
                id: response?.candidateComprehensives?.id || "",
                candidateID:
                  response?.candidateComprehensives?.candidateID || "",
                name: response?.candidateComprehensives?.candidateName || "",
                birthPlace:
                  response?.candidateComprehensives?.placeOfBirth || "",
                birthDate: response?.candidateComprehensives?.dateOfBirth
                  ? new Date(response?.candidateComprehensives?.dateOfBirth)
                  : "",
                gender: response?.candidateComprehensives?.gender || "",
                religion: response?.candidateComprehensives?.religion || "",
                bloodType: response?.candidateComprehensives?.bloodType || "",
                height: response?.candidateComprehensives?.bodyHeight || "",
                weight: response?.candidateComprehensives?.bodyWeight || "",
                maritalStatus:
                  response?.candidateComprehensives?.maritalStatus || "",
                kk: response?.candidateComprehensives?.familyId || "",
                nik: response?.candidateComprehensives?.personId || "",
                idCardPeriod: response?.candidateComprehensives?.personIdPeriod
                  ? response?.candidateComprehensives?.personIdPeriod ===
                    "SEUMUR HIDUP"
                    ? null
                    : new Date(
                        response?.candidateComprehensives?.personIdPeriod
                      )
                  : null,
                taxNumber: response?.candidateComprehensives?.taxId
                  ? response?.candidateComprehensives?.taxId ===
                    "TIDAK MEMILIKI NPWP"
                    ? ""
                    : response?.candidateComprehensives?.taxId
                  : "",
                email: response?.candidateComprehensives?.email || "",
                phoneNumber:
                  response?.candidateComprehensives?.phoneNumber.startsWith(
                    "62"
                  )
                    ? response?.candidateComprehensives?.phoneNumber.slice(2)
                    : response?.candidateComprehensives?.phoneNumber,
                biologicalMotherName:
                  response?.candidateComprehensives?.motherName || "",
              },
              address: {
                idCard: {
                  address: response?.candidateComprehensives?.address || "",
                  rt: response?.candidateComprehensives?.neighborhood
                    ? response?.candidateComprehensives?.neighborhood?.split(
                        "/"
                      )[0]
                    : "",
                  rw: response?.candidateComprehensives?.neighborhood
                    ? response?.candidateComprehensives?.neighborhood?.split(
                        "/"
                      )[1]
                    : "",
                  ward: response?.candidateComprehensives?.subDistrict || "",
                  subdistrict:
                    response?.candidateComprehensives?.district || "",
                  regency: response?.candidateComprehensives?.city || "",
                  province: response?.candidateComprehensives?.province || "",
                  postal: response?.candidateComprehensives?.postal || "",
                },
                domicile: {
                  address:
                    response?.candidateComprehensives?.domicileAddress || "",
                  rt: response?.candidateComprehensives?.domicileNeighbourhood
                    ? response?.candidateComprehensives?.domicileNeighbourhood?.split(
                        "/"
                      )[0]
                    : "",
                  rw: response?.candidateComprehensives?.domicileNeighbourhood
                    ? response?.candidateComprehensives?.domicileNeighbourhood?.split(
                        "/"
                      )[1]
                    : "",
                  subdistrict:
                    response?.candidateComprehensives?.domicileSubDistrict ||
                    "",
                  ward:
                    response?.candidateComprehensives?.domicileDistrict || "",
                  regency:
                    response?.candidateComprehensives?.domicileCity || "",
                  province:
                    response?.candidateComprehensives?.domicileProvince || "",
                  postal:
                    response?.candidateComprehensives?.domicilePostal || "",
                },
              },
              lastEducation: {
                education:
                  response?.candidateComprehensives?.lastEducationType || "",
                institution:
                  response?.candidateComprehensives?.lastEducationName || "",
                faculty:
                  response?.candidateComprehensives?.lastEducationFaculty || "",
                gpa:
                  response?.candidateComprehensives?.lastEducationGrade || "",
              },
              contactInfo: {
                emergencyContact: {
                  name:
                    response?.candidateComprehensives?.emergencyContactName ||
                    "",
                  relationship:
                    response?.candidateComprehensives
                      ?.emergencyContactRelation || "",
                  address:
                    response?.candidateComprehensives
                      ?.emergencyContactAddress || "",
                  phoneNumber:
                    response?.candidateComprehensives?.emergencyContactNumber?.startsWith(
                      "62"
                    )
                      ? response?.candidateComprehensives?.emergencyContactNumber.slice(
                          2
                        )
                      : response?.candidateComprehensives
                          ?.emergencyContactNumber,
                },
                sameHouseFamilyContact: {
                  name: response?.candidateFamilyMembers[1]?.name || "",
                  relationship:
                    response?.candidateFamilyMembers[1]?.relation || "",
                  address: response?.candidateFamilyMembers[1]?.address || "",
                  phoneNumber:
                    response?.candidateFamilyMembers[1]?.phoneNumber?.startsWith(
                      "62"
                    )
                      ? response?.candidateFamilyMembers[1]?.phoneNumber.slice(
                          2
                        )
                      : response?.candidateFamilyMembers[1]?.phoneNumber,
                },
                differentHouseFamilyContact: {
                  name: response?.candidateFamilyMembers[2]?.name || "",
                  relationship:
                    response?.candidateFamilyMembers[2]?.relation || "",
                  address: response?.candidateFamilyMembers[2]?.address || "",
                  phoneNumber:
                    response?.candidateFamilyMembers[2]?.phoneNumber?.startsWith(
                      "62"
                    )
                      ? response?.candidateFamilyMembers[2]?.phoneNumber.slice(
                          2
                        )
                      : response?.candidateFamilyMembers[2]?.phoneNumber,
                },
              },
              otherData: {
                bankName: response?.candidateComprehensives?.BankName || "",
                bankAccountNumber:
                  response?.candidateComprehensives?.BankNumber || "",
                nearestHealthFacility:
                  response?.candidateComprehensives?.nearestClinic || "",
                fbUsername: response?.candidateComprehensives?.facebook || "",
                igUsername: response?.candidateComprehensives?.instagram || "",
                twitterUsername:
                  response?.candidateComprehensives?.twitter || "",
                linkedinUsername:
                  response?.candidateComprehensives?.linkedin || "",
              },
              createdAt: response?.createdAt || "",
              updatedAt: response?.updatedAt || "",

              familyMembers: tempFamilyMembers,
              educationHistory: tempEducationHistory,
              jobHistory: tempJobHistory,
              performanceHistory: tempPerformanceHistory,
              documents: tempDocuments,
            });

            navigate(`${pathname}?h=${data.response?.hash}&v=true`, {
              replace: true,
            });
            setLoadingPhoneVerify(false);
          } else {
            setIsValidated(true);
          }
        } catch (error) {
          console.log(error);
          setLoadingPersonId(false);
          setIsValidated(true);
          setLoadingPhoneVerify(false);
        }
      }
    };
    if (!isValidated) {
      handleCheckId();
    }

    if (isValidated && personalData.nik.length < 16) {
      setIsValidated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalData.nik]);

  const handleSubmitCheckIdOTP = () => {
    setPersonalData({
      ...personalData,
      ...tempValue.personalData,
      phoneNumber: tempValue.personalData.phoneNumber.startsWith("62")
        ? tempValue?.personalData.phoneNumber.slice(2)
        : tempValue?.personalData.phoneNumber,
    });
    setAddress({
      ...address,
      ...tempValue.address,
    });
    setLastEducation({
      ...lastEducation,
      ...tempValue.lastEducation,
    });
    setContactInfo({
      ...contactInfo,
      ...tempValue.contactInfo,
    });
    setOtherData({
      ...otherData,
      ...tempValue.otherData,
    });
    setFamilyMembers([...tempValue.familyMembers]);
    setEducationHistory([
      // ...educationHistory,
      ...tempValue.educationHistory,
    ]);
    setJobHistory([
      // ...jobHistory,
      ...tempValue.jobHistory,
    ]);
    setPerformanceHistory([
      // ...performanceHistory,
      ...tempValue.performanceHistory,
    ]);
    setDocuments({
      ...documents,
      ...tempValue.documents,
    });
    setOpenValidateModal(false);
    setIsValidated(true);
    setIsPhoneVerified(true);
    navigate(`${pathname}?h=${query.get("h")}&v=false`, {
      replace: true,
    });
    setLoadingPhoneVerify(false);
  };

  useEffect(() => {
    setTriggerRenew(makeRandomString(5));
  }, [personalData, familyMembers, noTaxNumber]);

  const onClickPhoneVerification = useCallback(async () => {
    try {
      setLoadingPhoneVerify(true);
      const { response } = await sendOTP("62" + personalData?.phoneNumber);
      setOpenValidateModal(false);
      navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
      setLoadingPhoneVerify(false);
    } catch (err) {
      const errorMessage = err?.response?.data?.error || "something went wrong";
      setLoadingPhoneVerify(false);
      if (errorMessage?.toLowerCase()?.includes("fill all field")) {
        // setErrorField(true)
        setError("Cek kembali form dan pastikan semua data telah terisi");
      } else {
        setError(errorMessage);
      }
    }
  }, [personalData?.phoneNumber, navigate]);

  const handleSuccessPhoneVerification = () => {
    setLoadingPhoneVerify(false);
    setIsPhoneVerified(true);
    navigate(`${pathname}?h=${query.get("h")}&v=false`, {
      replace: true,
    });
  };

  const handleSend = useCallback(async () => {
    try {
      setIsProcessing(true);
      const formData = new FormData();
      formData?.append("personalData[candidateName]", personalData?.name);
      formData?.append(
        "personalData[recruiterID]",
        offeringData?.recruiter?.employeeId
      );
      formData?.append(
        "personalData[jobPostingID]",
        offeringData?.jobPostType?._id
      );
      formData?.append("personalData[positionID]", offeringData?.position?.id);
      formData?.append("personalData[placeOfBirth]", personalData?.birthPlace);
      formData?.append(
        "personalData[dateOfBirth]",
        personalData?.birthDate
          ? moment(new Date(personalData?.birthDate))?.format("YYYY-MM-DD")
          : ""
      );
      formData?.append("personalData[gender]", personalData?.gender);
      formData?.append("personalData[religion]", personalData?.religion);
      formData?.append("personalData[bloodType]", personalData?.bloodType);
      formData?.append("personalData[bodyHeight]", personalData?.height);
      formData?.append("personalData[bodyWeight]", personalData?.weight);
      formData?.append(
        "personalData[maritalStatus]",
        personalData?.maritalStatus
      );
      formData?.append("personalData[familyId]", personalData?.kk);
      formData?.append("personalData[personId]", personalData?.nik);
      formData?.append(
        "personalData[personIdPeriod]",
        !lifetimeIdCard
          ? personalData?.idCardPeriod
            ? moment(new Date(personalData?.idCardPeriod)).format("YYYY-MM-DD")
            : null
          : "SEUMUR HIDUP"
      );
      formData?.append(
        "personalData[taxId]",
        !noTaxNumber ? personalData?.taxNumber : "TIDAK MEMILIKI NPWP"
      );
      formData?.append("personalData[email]", personalData?.email);
      formData?.append(
        "personalData[phoneNumber]",
        "62" + personalData?.phoneNumber
      );
      formData?.append(
        "personalData[motherName]",
        personalData?.biologicalMotherName
      );
      formData?.append(
        "personalData[address][ktp][address]",
        address?.idCard?.address
      );
      formData?.append(
        "personalData[address][ktp][neighborhood]",
        `${address?.idCard?.rt}/${address?.idCard?.rw}`
      );
      formData?.append(
        "personalData[address][ktp][subDistrict]",
        address?.idCard?.ward
      );
      formData?.append(
        "personalData[address][ktp][district]",
        address?.idCard?.subdistrict
      );
      formData?.append(
        "personalData[address][ktp][city]",
        address?.idCard?.regency
      );
      formData?.append(
        "personalData[address][ktp][province]",
        address?.idCard?.province
      );
      formData?.append(
        "personalData[address][ktp][postal]",
        address?.idCard?.postal
      );

      if (isDomicileTheSame) {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.idCard?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.idCard?.rt}/${address?.idCard?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.idCard?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.idCard?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.idCard?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.idCard?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.idCard?.postal
        );
      } else {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.domicile?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.domicile?.rt}/${address?.domicile?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.domicile?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.domicile?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.domicile?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.domicile?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.domicile?.postal
        );
      }

      formData?.append(
        "personalData[lastEducation][type]",
        lastEducation?.education
      );
      formData?.append(
        "personalData[lastEducation][name]",
        lastEducation?.institution
      );
      formData?.append(
        "personalData[lastEducation][faculty]",
        lastEducation?.faculty
      );
      formData?.append(
        "personalData[lastEducation][grade]",
        lastEducation?.gpa
      );

      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactName]",
        contactInfo?.emergencyContact?.name
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactRelation]",
        contactInfo?.emergencyContact?.relationship
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactNumber]",
        "62" + contactInfo?.emergencyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactAddress]",
        contactInfo?.emergencyContact?.address
      );

      formData?.append(
        "personalData[contact][family1][family1Name]",
        contactInfo?.sameHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family1][family1Relation]",
        contactInfo?.sameHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family1][family1PhoneNumber]",
        "62" + contactInfo?.sameHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family1][family1Address]",
        contactInfo?.sameHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[contact][family2][family2Name]",
        contactInfo?.differentHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family2][family2Relation]",
        contactInfo?.differentHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family2][family2PhoneNumber]",
        "62" + contactInfo?.differentHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family2][family2Address]",
        contactInfo?.differentHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[others][bank][BankName]",
        otherData?.bankName
      );
      formData?.append(
        "personalData[others][bank][BankNumber]",
        otherData?.bankAccountNumber
      );
      formData?.append(
        "personalData[others][nearestClinic]",
        otherData?.nearestHealthFacility
      );
      if (otherData?.fbUsername) {
        formData?.append(
          "personalData[others][socialMedia][facebook]",
          otherData?.fbUsername
        );
      }
      if (otherData?.igUsername) {
        formData?.append(
          "personalData[others][socialMedia][instagram]",
          otherData?.igUsername
        );
      }
      if (otherData?.twitterUsername) {
        formData?.append(
          "personalData[others][socialMedia][twitter]",
          otherData?.twitterUsername
        );
      }
      if (otherData?.linkedinUsername) {
        formData?.append(
          "personalData[others][socialMedia][linkedin]",
          otherData?.linkedinUsername
        );
      }
      if (
        !otherData?.fbUsername &&
        !otherData?.igUsername &&
        !otherData?.twitterUsername &&
        !otherData?.linkedinUsername
      ) {
        formData?.append("personalData[others][socialMedia]", {});
      }

      for (let i = 0; i < familyMembers?.length; i++) {
        const member = familyMembers[i];
        formData?.append(`familyData[${i}][name]`, member?.name);
        formData?.append(
          `familyData[${i}][relation]`,
          member?.relationship || "-"
        );
        formData?.append(`familyData[${i}][placeOfBirth]`, member?.birthPlace);
        formData?.append(
          `familyData[${i}][dateOfBirth]`,
          member?.birthDate
            ? moment(new Date(member?.birthDate)).format("YYYY-MM-DD")
            : ""
        );
        formData?.append(`familyData[${i}][nik]`, member?.nik);
        formData?.append(`familyData[${i}][gender]`, member?.gender);
      }

      for (let i = 0; i < educationHistory?.length; i++) {
        const education = educationHistory[i];
        formData?.append(
          `educationData[${i}][startYear]`,
          education?.startingYear
        );
        formData?.append(
          `educationData[${i}][endYear]`,
          education?.finishingYear
        );
        formData?.append(`educationData[${i}][type]`, education?.educationType);
        formData?.append(`educationData[${i}][name]`, education?.institution);
        formData?.append(`educationData[${i}][mastery]`, education?.faculty);
        formData?.append(
          `educationData[${i}][sertificateNumber]`,
          education?.certificateNumber
        );
        formData?.append(`educationData[${i}][city]`, education?.city);
      }

      for (let i = 0; i < jobHistory?.length; i++) {
        const job = jobHistory[i];
        formData?.append(`workExperienceData[${i}][startYear]`, job?.startYear);
        formData?.append(`workExperienceData[${i}][endYear]`, job?.endYear);
        formData?.append(
          `workExperienceData[${i}][companyName]`,
          job?.companyName
        );
        formData?.append(`workExperienceData[${i}][position]`, job?.position);
        formData?.append(`workExperienceData[${i}][remark]`, job?.description);
      }

      if (!jobHistory?.length) {
        formData?.append(`workExperienceData`, []);
      }

      for (let i = 0; i < performanceHistory?.length; i++) {
        const performance = performanceHistory[i];
        formData?.append(`achievementData[${i}][year]`, performance?.year);
        formData?.append(
          `achievementData[${i}][achievement]`,
          performance?.performance
        );
        formData?.append(
          `achievementData[${i}][remark]`,
          performance?.description
        );
      }

      if (!performanceHistory?.length) {
        formData?.append(`achievementData`, []);
      }

      const files = documents?.mandatory?.filter((doc) => doc?.file);
      const files2 = documents?.nonMandatory?.filter((doc) => doc?.file);
      const files3 = documents?.additional?.filter((doc) => doc?.file);

      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        if (file?.title?.toLowerCase() !== "cv") {
          formData?.append("files", file?.file);
          formData?.append("types", file?.title?.toUpperCase());
        } else {
          const fileExtension = file?.file?.type
            ?.split("/")
            .pop()
            .toLowerCase();

          let validPdf = file?.file;
          if (
            fileExtension === "jpeg" ||
            fileExtension === "jpg" ||
            fileExtension === "png"
          ) {
            validPdf = await imageToPdf(file?.file);
          }
          formData?.append("files", validPdf);
          formData?.append("types", file?.title?.toUpperCase());
        }
      }
      for (let i = 0; i < files2?.length; i++) {
        const file = files2[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }
      for (let i = 0; i < files3?.length; i++) {
        const file = files3[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }

      await sendTransitionForm(formData);
      setIsSent(true);
      // navigate(`${pathname}?h=${query.get("h")}&v=false`, { replace: true });
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
    }
  }, [
    offeringData,
    personalData,
    address,
    isDomicileTheSame,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    lifetimeIdCard,
    noTaxNumber,
  ]);

  const handleSendUpdate = useCallback(async () => {
    try {
      setIsProcessing(true);
      const formData = new FormData();
      formData?.append("personalData[candidateID]", personalData?.candidateID);
      formData?.append("personalData[candidateName]", personalData?.name);
      // formData?.append('personalData[recruiterID]', offeringData?.recruiter?.employeeId);
      // formData?.append("personalData[positionID]", offeringData?.position?.id);
      formData?.append("personalData[placeOfBirth]", personalData?.birthPlace);
      formData?.append(
        "personalData[dateOfBirth]",
        personalData?.birthDate
          ? moment(new Date(personalData?.birthDate))?.format("YYYY-MM-DD")
          : ""
      );
      formData?.append("personalData[gender]", personalData?.gender);
      formData?.append("personalData[religion]", personalData?.religion);
      formData?.append("personalData[bloodType]", personalData?.bloodType);
      formData?.append("personalData[bodyHeight]", personalData?.height);
      formData?.append("personalData[bodyWeight]", personalData?.weight);
      formData?.append(
        "personalData[maritalStatus]",
        personalData?.maritalStatus
      );
      formData?.append("personalData[familyId]", personalData?.kk);
      formData?.append("personalData[personId]", personalData?.nik);
      formData?.append(
        "personalData[personIdPeriod]",
        !lifetimeIdCard
          ? personalData?.idCardPeriod
            ? moment(new Date(personalData?.idCardPeriod)).format("YYYY-MM-DD")
            : null
          : "SEUMUR HIDUP"
      );
      formData?.append(
        "personalData[taxId]",
        !noTaxNumber ? personalData?.taxNumber : "TIDAK MEMILIKI NPWP"
      );
      formData?.append("personalData[email]", personalData?.email);
      formData?.append(
        "personalData[phoneNumber]",
        "62" + personalData?.phoneNumber
      );
      formData?.append(
        "personalData[motherName]",
        personalData?.biologicalMotherName
      );
      formData?.append(
        "personalData[address][ktp][address]",
        address?.idCard?.address
      );
      formData?.append(
        "personalData[address][ktp][neighborhood]",
        `${address?.idCard?.rt}/${address?.idCard?.rw}`
      );
      formData?.append(
        "personalData[address][ktp][subDistrict]",
        address?.idCard?.ward
      );
      formData?.append(
        "personalData[address][ktp][district]",
        address?.idCard?.subdistrict
      );
      formData?.append(
        "personalData[address][ktp][city]",
        address?.idCard?.regency
      );
      formData?.append(
        "personalData[address][ktp][province]",
        address?.idCard?.province
      );
      formData?.append(
        "personalData[address][ktp][postal]",
        address?.idCard?.postal
      );

      if (isDomicileTheSame) {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.idCard?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.idCard?.rt}/${address?.idCard?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.idCard?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.idCard?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.idCard?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.idCard?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.idCard?.postal
        );
      } else {
        formData?.append(
          "personalData[address][domicile][domicileAddress]",
          address?.domicile?.address
        );
        formData?.append(
          "personalData[address][domicile][domicileNeighbourhood]",
          `${address?.domicile?.rt}/${address?.domicile?.rw}`
        );
        formData?.append(
          "personalData[address][domicile][domicileSubDistrict]",
          address?.domicile?.ward
        );
        formData?.append(
          "personalData[address][domicile][domicileDistrict]",
          address?.domicile?.subdistrict
        );
        formData?.append(
          "personalData[address][domicile][domicileCity]",
          address?.domicile?.regency
        );
        formData?.append(
          "personalData[address][domicile][domicileProvince]",
          address?.domicile?.province
        );
        formData?.append(
          "personalData[address][domicile][domicilePostal]",
          address?.domicile?.postal
        );
      }

      formData?.append(
        "personalData[lastEducation][type]",
        lastEducation?.education
      );
      formData?.append(
        "personalData[lastEducation][name]",
        lastEducation?.institution
      );
      formData?.append(
        "personalData[lastEducation][faculty]",
        lastEducation?.faculty
      );
      formData?.append(
        "personalData[lastEducation][grade]",
        lastEducation?.gpa
      );

      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactName]",
        contactInfo?.emergencyContact?.name
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactRelation]",
        contactInfo?.emergencyContact?.relationship
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactNumber]",
        "62" + contactInfo?.emergencyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][emergencyContact][emergencyContactAddress]",
        contactInfo?.emergencyContact?.address
      );

      formData?.append(
        "personalData[contact][family1][family1Name]",
        contactInfo?.sameHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family1][family1Relation]",
        contactInfo?.sameHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family1][family1PhoneNumber]",
        "62" + contactInfo?.sameHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family1][family1Address]",
        contactInfo?.sameHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[contact][family2][family2Name]",
        contactInfo?.differentHouseFamilyContact?.name
      );
      formData?.append(
        "personalData[contact][family2][family2Relation]",
        contactInfo?.differentHouseFamilyContact?.relationship
      );
      formData?.append(
        "personalData[contact][family2][family2PhoneNumber]",
        "62" + contactInfo?.differentHouseFamilyContact?.phoneNumber
      );
      formData?.append(
        "personalData[contact][family2][family2Address]",
        contactInfo?.differentHouseFamilyContact?.address
      );

      formData?.append(
        "personalData[others][bank][BankName]",
        otherData?.bankName
      );
      formData?.append(
        "personalData[others][bank][BankNumber]",
        otherData?.bankAccountNumber
      );
      formData?.append(
        "personalData[others][nearestClinic]",
        otherData?.nearestHealthFacility
      );
      if (otherData?.fbUsername) {
        formData?.append(
          "personalData[others][socialMedia][facebook]",
          otherData?.fbUsername
        );
      }
      if (otherData?.igUsername) {
        formData?.append(
          "personalData[others][socialMedia][instagram]",
          otherData?.igUsername
        );
      }
      if (otherData?.twitterUsername) {
        formData?.append(
          "personalData[others][socialMedia][twitter]",
          otherData?.twitterUsername
        );
      }
      if (otherData?.linkedinUsername) {
        formData?.append(
          "personalData[others][socialMedia][linkedin]",
          otherData?.linkedinUsername
        );
      }
      if (
        !otherData?.fbUsername &&
        !otherData?.igUsername &&
        !otherData?.twitterUsername &&
        !otherData?.linkedinUsername
      ) {
        formData?.append("personalData[others][socialMedia]", {});
      }

      for (let i = 0; i < familyMembers?.length; i++) {
        const member = familyMembers[i];
        formData?.append(`familyData[${i}][name]`, member?.name);
        formData?.append(
          `familyData[${i}][relation]`,
          member?.relationship || "-"
        );
        formData?.append(`familyData[${i}][placeOfBirth]`, member?.birthPlace);
        formData?.append(
          `familyData[${i}][dateOfBirth]`,
          member?.birthDate
            ? moment(new Date(member?.birthDate)).format("YYYY-MM-DD")
            : ""
        );
        formData?.append(`familyData[${i}][nik]`, member?.nik);
        formData?.append(`familyData[${i}][gender]`, member?.gender);
      }

      for (let i = 0; i < educationHistory?.length; i++) {
        const education = educationHistory[i];
        formData?.append(
          `educationData[${i}][startYear]`,
          education?.startingYear
        );
        formData?.append(
          `educationData[${i}][endYear]`,
          education?.finishingYear
        );
        formData?.append(`educationData[${i}][type]`, education?.educationType);
        formData?.append(`educationData[${i}][name]`, education?.institution);
        formData?.append(`educationData[${i}][mastery]`, education?.faculty);
        formData?.append(
          `educationData[${i}][sertificateNumber]`,
          education?.certificateNumber
        );
        formData?.append(`educationData[${i}][city]`, education?.city);
      }

      for (let i = 0; i < jobHistory?.length; i++) {
        const job = jobHistory[i];
        formData?.append(`workExperienceData[${i}][startYear]`, job?.startYear);
        formData?.append(`workExperienceData[${i}][endYear]`, job?.endYear);
        formData?.append(
          `workExperienceData[${i}][companyName]`,
          job?.companyName
        );
        formData?.append(`workExperienceData[${i}][position]`, job?.position);
        formData?.append(`workExperienceData[${i}][remark]`, job?.description);
      }

      if (!jobHistory?.length) {
        formData?.append(`workExperienceData`, []);
      }

      for (let i = 0; i < performanceHistory?.length; i++) {
        const performance = performanceHistory[i];
        formData?.append(`achievementData[${i}][year]`, performance?.year);
        formData?.append(
          `achievementData[${i}][achievement]`,
          performance?.performance
        );
        formData?.append(
          `achievementData[${i}][remark]`,
          performance?.description
        );
      }

      if (!performanceHistory?.length) {
        formData?.append(`achievementData`, []);
      }

      const files = documents?.mandatory?.filter((doc) => doc?.file);
      const files2 = documents?.nonMandatory?.filter((doc) => doc?.file);
      const files3 = documents?.additional?.filter((doc) => doc?.file);

      for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        if (file?.title?.toLowerCase() !== "cv") {
          formData?.append("files", file?.file);
          formData?.append("types", file?.title?.toUpperCase());
        } else {
          const fileExtension = file?.file?.type
            ?.split("/")
            .pop()
            .toLowerCase();

          let validPdf = file?.file;
          if (
            fileExtension === "jpeg" ||
            fileExtension === "jpg" ||
            fileExtension === "png"
          ) {
            validPdf = await imageToPdf(file?.file);
          }
          formData?.append("files", validPdf);
          formData?.append("types", file?.title?.toUpperCase());
        }
      }
      for (let i = 0; i < files2?.length; i++) {
        const file = files2[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }
      for (let i = 0; i < files3?.length; i++) {
        const file = files3[i];
        formData?.append("files", file?.file);
        formData?.append("types", file?.title?.toUpperCase());
      }

      await sendCandidateUpdateForm(formData);
      setIsSent(true);
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong");
    }
  }, [
    personalData,
    address,
    isDomicileTheSame,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    lifetimeIdCard,
    noTaxNumber,
  ]);

  const handleDone = () => {
    setIsProcessing(false);
    setCurrentStep(0);
    clearStorage();
    setTriggerDefault(Math.random());
  };

  const options = useMemo(() => {
    return {
      banks: syncData?.banks,
      cities: syncData?.cities,
      provinces: syncData?.provinces,
      genders: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Gender")
        ?.map((el) => el?.value),
      religions: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Religion")
        ?.map((el) => el?.value),
      bloodTypes: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Blood Type")
        ?.map((el) => el?.value),
      maritalStatus: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Marital Status")
        ?.map((el) => el?.value),
      educations: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Education")
        ?.map((el) => el?.value),
      relationships: syncData?.dropdownOptions
        ?.filter((el) => el?.type === "Family Relationship")
        ?.map((el) => el?.value),
    };
  }, [syncData]);

  const content = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return (
          <FirstStep
            personalData={personalData}
            setPersonalData={setPersonalData}
            address={address}
            setAddress={setAddress}
            lastEducation={lastEducation}
            setLastEducation={setLastEducation}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            otherData={otherData}
            setOtherData={setOtherData}
            trigger={trigger}
            setTrigger={setTrigger}
            lifetimeIdCard={lifetimeIdCard}
            setLifetimeIdCard={setLifetimeIdCard}
            noTaxNumber={noTaxNumber}
            setNoTaxNumber={setNoTaxNumber}
            isDomicileTheSame={isDomicileTheSame}
            setIsDomicileTheSame={setIsDomicileTheSame}
            options={options}
            disableInput={disableInput}
            disablePhoneVerification={disablePhoneVerification}
            loadingPersonId={loadingPersonId}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
            loadingPhoneVerify={loadingPhoneVerify}
            onClickPhoneVerification={onClickPhoneVerification}
            errorField={errorField}
            setErrorDate={setErrorDate}
            errorDate={errorDate}
          />
        );
      case 2:
        return (
          <SecondStep
            form={familyMembers}
            setForm={setFamilyMembers}
            options={options}
            errorField={errorField}
          />
        );
      case 3:
        return (
          <ThirdStep
            form={educationHistory}
            setForm={setEducationHistory}
            options={options}
            errorField={errorField}
          />
        );
      case 4:
        return (
          <FourthStep
            form={jobHistory}
            setForm={setJobHistory}
            errorField={errorField}
          />
        );
      case 5:
        return (
          <FifthStep
            form={performanceHistory}
            setForm={setPerformanceHistory}
            errorField={errorField}
          />
        );
      case 6:
        return (
          <SixthStep
            form={documents}
            setForm={setDocuments}
            isDoneCompleting={isDoneCompleting}
            setIsDoneCompleting={setIsDoneCompleting}
            handleSend={
              personalData?.candidateID ? handleSendUpdate : handleSend
            }
            errorField={errorField}
          />
        );
      default:
        return (
          <FirstStep
            personalData={personalData}
            setPersonalData={setPersonalData}
            address={address}
            setAddress={setAddress}
            lastEducation={lastEducation}
            setLastEducation={setLastEducation}
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
            otherData={otherData}
            setOtherData={setOtherData}
            trigger={trigger}
            setTrigger={setTrigger}
            lifetimeIdCard={lifetimeIdCard}
            setLifetimeIdCard={setLifetimeIdCard}
            noTaxNumber={noTaxNumber}
            setNoTaxNumber={setNoTaxNumber}
            isDomicileTheSame={isDomicileTheSame}
            setIsDomicileTheSame={setIsDomicileTheSame}
            options={options}
            disableInput={disableInput}
            disablePhoneVerification={disablePhoneVerification}
            loadingPersonId={loadingPersonId}
            isPhoneVerified={isPhoneVerified}
            setIsPhoneVerified={setIsPhoneVerified}
            loadingPhoneVerify={loadingPhoneVerify}
            onClickPhoneVerification={onClickPhoneVerification}
            errorField={errorField}
          />
        );
    }
  }, [
    currentStep,
    personalData,
    trigger,
    lifetimeIdCard,
    noTaxNumber,
    address,
    isDomicileTheSame,
    lastEducation,
    contactInfo,
    otherData,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
    documents,
    isDoneCompleting,
    options,
    handleSend,

    handleSendUpdate,
    disableInput,
    disablePhoneVerification,
    isPhoneVerified,
    loadingPersonId,
    loadingPhoneVerify,
    onClickPhoneVerification,
    errorField,
    errorDate,
  ]);

  const isNextButtonAvailable = useMemo(() => {
    if (currentStep === 6 && isDoneCompleting) {
      return false;
    } else if (currentStep === 2) {
      if (familyMembers?.length) {
        return true;
      } else {
        return false;
      }
    } else if (currentStep === 3) {
      if (educationHistory?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [currentStep, familyMembers, educationHistory, isDoneCompleting]);

  const [stepperHeight, setStepperHeight] = useState();
  const stepperWrapperRef = useRef();

  const getHeight = () => {
    const newHeight = stepperWrapperRef?.current?.clientHeight;
    setStepperHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  useEffect(() => {
    getHeight();
  }, []);

  const disableNext = useMemo(() => {
    switch (currentStep) {
      case 1:
        return !isDoneChecker?.first();
      case 2:
        return !isDoneChecker?.second();
      case 3:
        return !isDoneChecker?.third();
      case 4:
        return !isDoneChecker?.fourth();
      case 5:
        return !isDoneChecker?.fifth();
      case 6:
        return !isDoneChecker?.sixth();
      default:
        return !isDoneChecker?.first();
    }
  }, [currentStep, isDoneChecker]);

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper} ref={stepperWrapperRef}>
        <div className={Styles.logoWrapper}>
          <div>
            <img src={Images.INITIAL_LOGO} alt="logo" />
          </div>
          <span>PT Permata Indo Sejahtera</span>
        </div>

        <div className={Styles.stepControllerWrapper}>
          <button onClick={onPrevious}>
            <Icon icon="arrow-left" size={20} />
          </button>
          <div>
            <Stepper
              type="filled"
              onClick={handleClickStep}
              options={stepsOptions}
              currentStep={currentStep}
            />
          </div>

          <button onClick={onNext}>
            <Icon icon="arrow-right" size={20} />
          </button>
        </div>
      </div>
      <div
        className={Styles.mainWrapper}
        style={{ height: `calc(100vh - ${stepperHeight}px)` }}
      >
        <div className={Styles.contentWrapper}>{content}</div>

        {errorShow && (
          <div className={Styles.errorWrapper}>
            <Icon icon={"information-solid"} color={"#ff3e13"} size={20} />
            <span className={Styles.errorText}>
              {"Terdapat kolom yang belum terisi"}
            </span>
          </div>
        )}

        {isNextButtonAvailable && (
          <div
            className={Styles.buttonWrapper}
            style={{
              paddingBottom:
                currentStep !== 1 && currentStep !== 6 ? "80px" : "",
            }}
          >
            <button
              // disabled={disableNext}
              onClick={onNext}
            >
              Selanjutnya
            </button>
          </div>
        )}
      </div>

      {query.get("v") === "true" ? (
        <ModalWrapper show={query.get("v") === "true"} forceCenter>
          <div
            style={{
              visibility: query.get("v") === "true" ? "visible" : "hidden",
            }}
          >
            <OTPConfirmationModal
              setIsDone={setIsSent}
              // handleReset={handleReset}
              // handleSubmit={handleSend}
              handleSubmit={
                openValidateModal
                  ? handleSubmitCheckIdOTP
                  : handleSuccessPhoneVerification
              }
              data={openValidateModal ? tempValue?.personalData : personalData}
              errorApi={error}
              loading={loadingPhoneVerify}
            />
          </div>
        </ModalWrapper>
      ) : (
        loadingPhoneVerify && <LoadingModal />
      )}

      {isProcessing && (
        <SendingModal
          handleDone={handleDone}
          isDone={isSent}
          show={isProcessing}
          error={error}
          setShow={setIsProcessing}
          setError={setError}
        />
      )}
    </div>
  );
}
