import moment from "moment";
import CostEstimationSection from "./CostEstimation";
import InformationDataSection from "./InformationData";
import ManPowerSection from "./ManPower";
import TaxAndBPJSSection from "./TaxAndBPJS";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import ContactPerson from "./ContactPerson";
import ContractLogs from "./ContractLogs";
import useQuery from "@Hooks/useQuery";
import { useMemo } from "react";
// import NavProvider from "../../../../../../../Context/NavContext";
// import ContactSection from "./Contacts";

export default function SchemeDetailContent({
  data,
  qualifications,
  isV2 = false,
  setShowDetailClient,
  isHC = false,
  selectedLogs,
  updateLogs,
}) {
  const query = useQuery();

  const defaultVerified = useMemo(() => {
    let status = "";
    if (new Date() >= new Date(data?.contract?.endDate)) {
      status = "expired";
    } else {
      if (data?.orderDetail?.isVerified || query?.get("v") === "yes") {
        status = "approved";
      }
    }

    return status;
  }, [data, query]);

  return (
    <div className={`${Styles.container} ${isHC && Styles.containerHC}`}>
      <div className={`${Styles.headerWrapper} ${isV2 ? Styles.v2 : ""}`}>
        {isV2 && <div className={Styles.title}>Detail FLB</div>}
        <div className={Styles.wrapperBox}>
          <div className={Styles.headerWrap}>
            <div className={Styles.left}>
              <div className={Styles.imgBox}>
                <img src={Images.PERMATA_LOGO} alt="" />
              </div>
              <span>PT Permata Indo Sejahtera</span>
            </div>

            <div className={Styles.right}>
              <span>Form Layanan Bisnis</span>
              <span>No.{data?.contract?.schemeID || "-"}</span>
            </div>
          </div>
          <div className={Styles.innerWrapper}>
            <div className={`${Styles.header}`}>
              <div className={Styles.stackedInfo}>
                <span>Kepada:</span>
                <div className={Styles.Bold}>
                  {data?.clientFrom?.name || "-"}
                </div>
                <span>{data?.clientFrom?.address || "-"}</span>
              </div>
              <div className={Styles.stackedInfo}>
                <span>Tanggal:</span>
                <span>
                  {data?.dateProject
                    ? moment(new Date(data?.dateProject))?.format("LL")
                    : "-"}
                </span>
              </div>
              <div className={Styles.stackedInfo}>
                <span>Projek:</span>
                <div className={Styles.address}>
                  <div>
                    <span>{data?.clientProject?.name || "-"}</span>

                    {isV2 ? (
                      <div>
                        <div></div>
                        <span
                          onClick={() =>
                            setShowDetailClient(data?.clientProject?.code)
                          }
                        >
                          Lihat Detail
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <span>{data?.clientProject?.address || "-"}</span>
                </div>
              </div>
              <div className={Styles.stackedInfo}>
                <span>Model Kerjasama:</span>
                <span className={Styles.Bold}>
                  {data?.cooperationModels || "-"}
                </span>
              </div>
            </div>
            <img
              src={
                defaultVerified === "approved" || query?.get("v") === "yes"
                  ? Images.APPROVED_LOGO
                  : defaultVerified === "expired" ||
                    query?.get("v") === "expired"
                  ? Images.EXPIRED_LOGO
                  : Images.WAITING_LOGO
              }
              alt=""
            />
          </div>
        </div>
      </div>

      <div className={Styles.content}>
        <ContactPerson data={data} />

        <InformationDataSection
          data={data}
          isHC={isHC}
          selectedLogs={selectedLogs}
          updateLogs={updateLogs}
        />
        <ContractLogs
          data={data}
          isHC={isHC}
          selectedLogs={selectedLogs}
          updateLogs={updateLogs}
        />
        <TaxAndBPJSSection
          data={data}
          selectedLogs={selectedLogs}
          isHC={isHC}
          updateLogs={updateLogs}
        />
        <ManPowerSection
          data={data}
          qualificationList={qualifications}
          selectedLogs={selectedLogs}
          isHC={isHC}
          updateLogs={updateLogs}
        />
        <CostEstimationSection
          data={data}
          selectedLogs={selectedLogs}
          isHC={isHC}
          updateLogs={updateLogs}
        />
      </div>
    </div>
  );
}
