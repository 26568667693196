import JobPostingDetail from "@Organism/Recruiter/JobPosting/JobPostingDetail";

import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getJobPostPerID } from "@Services/officer/jobPosting";

export async function loader({ params }) {
  const id = params.id;
  console.log("id can", id);

  const jobPostDetailPromise = getJobPostPerID(id);

  return defer({
    packageJobPostingDetail: jobPostDetailPromise,
  });
}

export default function JobPostingDetailPage() {
  const { packageJobPostingDetail } = useLoaderData();
  //   return <JobPostingDetail />;
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "calc(100vh - 220px)",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LoadingAnimation />
        </div>
      }
    >
      <Await
        resolve={packageJobPostingDetail}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageJobPostingDetail) => (
          <JobPostingDetail data={packageJobPostingDetail?.response} />
        )}
      </Await>
    </Suspense>
  );
}
