import { API } from "@Config/api";

export function getContractApproval(hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/business-support/contract/${hash}`, {
        headers: {
          // Authorization: sessionStorage.getItem("accessToken"),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function setContractApproval(id, tag, remark) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/business-support/approval-contract/${id}`,
        {
          tag,
          remark
        },
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export async function signContract(id, data) {
  const form = new FormData();

  let signature = await fetch(data.signature)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], "sign.png", { type: "image/png" });
    });
    
  let initials = await fetch(data.initials)
  .then((res) => res.blob())
  .then((blob) => {
    return new File([blob], "initials.png", { type: "image/png" });
  });

  form.append("latitude", data.latitude);
  form.append("longitude", data.longitude);
  form.append("files", signature);
  form.append("files", initials);
  form.append("files", data.image);
  form.append("type[0]", "signature");
  form.append("type[1]", "initials");
  form.append("type[2]", "image");

  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/business-support/sign-contract/${id}`,
        form,
        {
          headers: {
            // Authorization: sessionStorage.getItem("accessToken"),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
